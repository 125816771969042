import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Modal, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../logic/store/Store';
import { CoveringLoader } from '../../../components/LoadingSpinner/CoveringLoader';
import { Button } from '../../../components/Button/Button';
import { Api } from '../../../logic/Api/Api';
import { IManageUserResponse } from '../../../logic/Models/Responses/ManageUsersResponse';
import styled, { useTheme } from 'styled-components';
import { ScaleLoader } from 'react-spinners';
import Form from 'antd/lib/form/Form';
import { dynamicSort } from '../../../logic/Utility/Utils';

export const UserManagementTool = () => 
{
	const userAuthToken = useSelector((state: RootState) => state.auth.userAuthToken);
	const api = useMemo(() => new Api('/api/4', userAuthToken, undefined), [userAuthToken]);
	const theme = useTheme();

	// Version is a hack to reload the user list when we make an edit
	const [version, setVersion] = useState(0);
	const [allUsers, setAllUsers] = useState<IManageUserResponse[]>();

	// A message to disply to the user
	const [message, setMessage] = useState<string>();
	// If a loading spinner should be shown
	const [isUpdating, setIsUpdating] = useState<string>();

	// What the user has typed in to filter the shown users
	const [filterText, setFilterText] = useState('');

	// The selected user for update
	const [selectedUserId, setSelectedUserId] = useState<string>();
	const selectedUser = useMemo(() => (allUsers??[]).find(u => u.UserId === selectedUserId), [selectedUserId, allUsers]);

	// apply the typed in filter and reduce the users shown
	const filteredUsers = useMemo(() => 
	{
		if(!allUsers)
		{
			return [];
		}

		if(!filterText || filterText.length === 0)
		{
			return allUsers;
		}

		return allUsers.filter(u => 
			u.UserId.toLowerCase().includes(filterText.toLowerCase())
			|| u.UserName?.toLowerCase().includes(filterText.toLowerCase())
			|| u.UserEmail?.toLowerCase().includes(filterText.toLowerCase())
			|| u.FoundationId?.toLowerCase().includes(filterText.toLowerCase())
		);

	}, [allUsers, filterText]);

	// Get a list of all users
	useEffect(() => 
	{
		async function exec() 
		{
			setAllUsers([]);
			const response = await api.getAsync<IManageUserResponse[]>('users');
			setAllUsers(response.Data.sort(dynamicSort('UserName')));
		}
		exec();
	}, [version]);


	// Form data
	const [enteredEmail, setEnteredEmail] = useState('');
	const [requiresPassword, setRequiresPassword] = useState<boolean>(false);
	const [enteredPassword, setEnteredPassword] = useState('');

	// Fix button
	const fixUser = useCallback((userId: string) => 
	{
		async function exec()
		{
			await api.getAsync(`users/${userId}/fix`);
			setMessage('Fix queued');
		}
		exec();
	}, [api]);

	// Update Email button
	const attemptUpdate = useCallback(() => 
	{
		if(!selectedUserId || !enteredEmail)
		{
			return;
		}

		async function exec()
		{
			setIsUpdating(selectedUserId);
			try 
			{
				const response = await api.postAsync(`users/${selectedUserId}`, {
					Username: enteredEmail,
					Password: requiresPassword ? enteredPassword : undefined
				});

				// See if this change requires a password
				if(response.ErrorCode === 'required_parameter_missing')
				{
					setRequiresPassword(true);
				}
				else if(response.Success)
				{
					setMessage('Successfully changed the email address for this user.');
					setSelectedUserId(undefined);
					setVersion((prev) => prev+1);
				}
			}
			finally 
			{
				setIsUpdating(undefined);
			}
		}
		exec();
	}, [ selectedUserId, enteredEmail, requiresPassword, enteredPassword ]);


	return <div style={{ width:'100%', position: 'relative', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr max-content max-content', gridColumnGap: 8 }}>
		<h2>
			Filter:
		</h2>
		<div style={{gridColumnStart:2, gridColumnEnd:7}}>
			<Input onChange={(e) => setFilterText(e.currentTarget.value)} placeholder='Search Id, Name, Email or Foundation ID' />
		</div>
		<h2 style={{display:'contents'}}><div>Id</div><div>Name</div><div>Email</div><div>Foundation ID</div><div>Change Email</div><div>Queue Fix</div></h2>
		{
			filteredUsers.map(u => <HoverRow key={u.UserEmail}>
				<div>{u.UserId}</div> <div>{u.UserName}</div> <div>{u.UserEmail}</div> <div>{u.FoundationId}</div>
				<div style={{padding: 4}}><Button variant='dark' onClick={() => 
				{ 
					setRequiresPassword(false);
					setEnteredEmail('');
					setEnteredPassword('');
					setSelectedUserId(u.UserId);

				}}>Update</Button></div>
				<div style={{padding: 4}}><Button variant='dark' onClick={() => fixUser(u.UserId)}>Fix</Button></div>
			</HoverRow>)
		}
		<CoveringLoader duration={10} className={(allUsers === undefined || isUpdating) ? 'loading' : ''}>
			<ScaleLoader color={theme.colors.primary} loading={true} />
		</CoveringLoader>
		<Modal 
			open={!!message} 
			closable={false} 
			onOk={() => setMessage(undefined)}
			cancelButtonProps={{ style: { display: 'none' } }}
		>
			{message}
		</Modal>
		<Modal
			open={!!selectedUserId && !isUpdating}
			closable={false}
			onOk={() => setSelectedUserId(undefined)}
			cancelButtonProps={{ style: { display: 'none' } }}
		>
			<Form>
				<h2>Change the user's email address from<br/>{selectedUser?.UserEmail}<br/>to:</h2>
				<Input type='email' onChange={(e) => setEnteredEmail(e.currentTarget.value)} />
				{ requiresPassword && 
					<>
						<h2>The email address does not already have a password in Cropwise. Please enter one:</h2>
						<Input type='password' onChange={(e) => setEnteredPassword(e.currentTarget.value)} /> 
					</>
				}
				<Button 
					variant='main' 
					disabled={!enteredEmail || (requiresPassword && !enteredPassword)}
					onClick={attemptUpdate}
				>Update Email</Button>
			</Form>
		</Modal>
	</div>;
};

const HoverRow = styled.div`
	display: contents;
	
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	:hover > div {
		border-top: 1px solid black;
		border-bottom: 1px solid black;
	}
`;

