import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store/Store';
import { addPostLoginListener } from './Listeners/PostLoginListener';

/** 
 * A redux listener. There should only ever be one of these in the app.
 */
export const appListener = createListenerMiddleware<RootState, AppDispatch>();

/** A convenience type for the 'listener' parameter of the 'effect' function. */
export type EffectListener = Parameters<Parameters<typeof appListener['startListening']>[0]['effect']>[1];

// Add all listeners.
addPostLoginListener(appListener.startListening);
