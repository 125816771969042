import React from 'react';
import { ReactComponent as CirclePlusIcon } from '../Icons/CirclePlus.svg';
import { ReactComponent as ActionIcon } from '../Icons/Action.svg';
import { StyledDashIcon } from '../DashboardRows';
import { StyledRowCell } from '../../../../components/Table/TableRow';
import { IGrowerResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { Button } from '../../../../components/Button/Button';
import { Tooltip } from 'antd';

interface IComponentProps 
{
	hideData?: boolean;
	disabled?: boolean;
	grower: IGrowerResponse;
	displayEnogenFlow: (growerId: string) => void;
}

export const EnogenContracted = (props: IComponentProps) =>
{
	const { hideData, grower, disabled, displayEnogenFlow } = props;

	const enogenContracted = false; // TODO - currently does not come down on the grower response

	return (
		<Tooltip title={disabled ? 'Select the current crop year to do this' : ''} placement='bottom'>
			<StyledRowCell style={{
				width: '20%',
				cursor: !disabled ? 'pointer' : 'not-allowed',
				opacity: !disabled ? 1.0 : 0.6
			}}>
				<Button variant='light'
					onClick={() => displayEnogenFlow(grower.Id)}
					style={{ backgroundColor: 'white' }}
					disabled={disabled}>
					<div style={{
						width: '100%',
						display: 'flex', 
						alignItems: 'center' }}
					>
						{hideData && <StyledDashIcon />}
						{!hideData && (<>
							<CirclePlusIcon style={{ visibility: !enogenContracted ? 'visible' : 'hidden' }} />
							<ActionIcon style={{ visibility: enogenContracted ? 'visible' : 'hidden' }} />
						</>)}
					</div>
				</Button>
			</StyledRowCell>
		</Tooltip>
	);
};
