import * as amplitude from '@amplitude/analytics-browser';

export const AmplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY; // May not be provided on local instances

export const ApplicationVersion = process.env.REACT_APP_VERSION; // May not be provided on local instances

if (AmplitudeKey && ApplicationVersion) 
{
	// Start tracking
	amplitude.init(AmplitudeKey, undefined, {
		appVersion: ApplicationVersion,
		logLevel: amplitude.Types.LogLevel.Warn,
		defaultTracking: {
			fileDownloads: true,
			formInteractions: true,
			sessions: true,
			pageViews: {
				trackHistoryChanges: 'all'
			}
		}
	});
	const deviceId = amplitude.getDeviceId();
	document.cookie = `session-device=${deviceId};max-age=3000000;path=/;SameSite=Lax`;
}

/**
 * Our naming convention for amplitude is specific and different from the standard names we have to use for
 * other systems (in particular, tracing).  This function will adjust the name to fit the convention for amplitude.
 * 
 * \see https://digitial-product-engineering.atlassian.net/wiki/spaces/GHX/pages/2684158127/Amplitude+Naming+Conventions+and+Guide
 * 
 * @param name The tag name
 */
export function AmpTag(name: string)
{
	return name.replace(/[:._]/g, ' ');
}

export const amplitudeInstance = amplitude;
