import React, { useCallback, useEffect, useRef, useState } from 'react';
import L, { LeafletMouseEvent } from 'leaflet';
import { AppDispatch, RootState } from '../../store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { baseStyle, defaultZoomLevel, hybridLayerParams, maxBoundsContinentalUSParams, satelliteLayerParams, selectedOpacity, streetsLayerParams } from '../Shared/MapCommon';
import turf, { feature, Feature, FeatureCollection, featureCollection, Geometry } from '@turf/turf';
import { get } from 'lodash';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { ZoomTray } from '../ZoomTray';

/**
 * In case a map marker is ever needed, this is a workaround, otherwise it will fail to get
 * the marker icon file properly.
 * Ref: https://github.com/PaulLeCam/react-leaflet/issues/453
 */
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { IFieldInventoryDetails } from '../../../pages/FieldActivities/MaxScript/FieldInventoryView';
import { IMaxScriptOrderData } from '../../../pages/FieldActivities/MaxScript/MaxScriptMain';
import { getSoilData } from '../../store/SoilData/SoilDataSlice';
import { colorToHex, makeDispatch } from '../../Utility/Utils';
import { ISoilDataResponse } from '../../Models/Responses/SoilDataResponse';
import { MaxScriptButtonTray } from './MaxScriptButtonTray';
import { IFieldSoilData } from '../PopUp/MapPopUp';
import { useTheme } from 'styled-components';
 
const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow
});
 
L.Marker.prototype.options.icon = DefaultIcon;
/**
 * end map marker solution
 */

interface IMaxScriptMapProps extends PropsFromRedux
{
	maxScriptData: {[key: string]: IMaxScriptOrderData;};
	selectedGrower: IGrowerResponse;
	selectedCropId: string;
	selectedFieldId: string;
	selectField: (fieldId: string) => void;
}

const MaxScriptMapComponent = (props: IMaxScriptMapProps) =>
{
	const {
		maxScriptData,
		IsLoadingSoilData,
		selectedGrower,
		selectedCropId,
		selectedFieldId,
		MapboxToken,
		selectField,
		DownloadSoilMap,
	} = props;

	const theme = useTheme();

	const [maxScriptFields, setmaxScriptFields] = useState<IFieldInventoryDetails[]>(undefined);
	const [fieldSoilData, setFieldSoilData] = useState<{ [key: string] : FeatureCollection<Geometry> }>({});
	const [showSoilMapButton, setShowSoilMapButton] = useState<boolean>(true);

	useEffect(() =>
	{
		if (maxScriptData)
		{
			setmaxScriptFields(Object.keys(maxScriptData).flatMap(c => maxScriptData[c].CropPlanFields));
		}
	},[maxScriptData]);

	// Reference to the map control
	const mapRef = useRef<L.DrawMap | undefined>(undefined);

	// TODO: For future popups: Reference to the div of any popup
	const [popupRef, setPopupRef] = useState<HTMLDivElement | undefined>(undefined);

	// Store the element the map will be rendered into
	const [mapContainer, setMapContainer] = useState<HTMLDivElement>();

	// Store the geojson data layers we will use to represent everything.
	const [fieldLayer] = useState(L.geoJSON());

	// Soil zone layer
	const [soilDataLayer] = useState(L.geoJSON());

	// Base Layers for the map
	const hybridLayer = L.tileLayer((hybridLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), hybridLayerParams[1] as {attribution: string});
	const streetsLayer = L.tileLayer((streetsLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), streetsLayerParams[1] as {attribution: string});
	const satelliteLayer = L.tileLayer((satelliteLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), satelliteLayerParams[1] as {attribution: string});

	useEffect(() =>
	{
		// Event to catch a resize
		window.dispatchEvent(new Event('resize'));
	},[]);

	// Create a layer object to pass to the layer control on the map
	const baseMaps = {
		'Hybrid': hybridLayer,
		'Streets': streetsLayer,
		'Satellite': satelliteLayer
	};

	// Continental US bounds
	const maxBoundsContinentalUS: L.LatLngBounds =  L.latLngBounds(
		L.latLng(maxBoundsContinentalUSParams[0][0], maxBoundsContinentalUSParams[0][1]), //Southwest
		L.latLng(maxBoundsContinentalUSParams[1][0], maxBoundsContinentalUSParams[1][1])  //Northeast
	);
	
	// Create the default map styler that will apply color to the rendered vectors.
	const styler = ((feature: Feature<Geometry, IFieldInventoryDetails>) =>
	{
		if (selectedCropId)
		{
			return {
				...baseStyle(theme),
				fillColor: feature.properties?.HybridColor ? feature.properties?.HybridColor : theme.colors.mediumGrey,
				fillOpacity: 0.8
			};
		}
		return {
			...baseStyle(theme),
			fillColor: feature.properties?.CropColor ? feature.properties?.CropColor : theme.colors.mediumGrey,
			fillOpacity: 0.8
		};
	}) as L.StyleFunction<any>;

	// Create the styler for the SoilData Layer
	const soilDataStyler = ((feature: Feature<Geometry>) =>
	{
		if (feature.properties)
		{
			const color = feature.properties.color;
			const hexColor = colorToHex(color);
			return {
				...baseStyle(theme),
				fillColor: hexColor,
				fillOpacity: selectedOpacity,
			};
		}

		return {
			...baseStyle(theme),
			color: theme.colors.blueLM,
			fillColor: theme.colors.transparentWhite
		};
	}) as L.StyleFunction<any>;

	fieldLayer.setStyle(styler);

	// When the container is rendered, save the element so we can install the map there
	const installMaxScriptMap = useCallback((ref: HTMLDivElement) =>
	{
		if (ref)
		{
			// On refreshes, if the mapRef exists, it can throw an error about the map already having been initialized
			// Removing it before we re-initialize it will fix that
			if (mapRef.current)
			{
				mapRef.current.remove();
			}

			if (!selectedGrower || !selectedGrower.ReferencePoint)
			{
				mapRef.current = L.map(ref, {
					zoomControl: false,
					layers: [hybridLayer], // default to the Streets layer
				}).fitBounds(maxBoundsContinentalUS);
			}
			else
			{
				const growerReferencePoint = selectedGrower.ReferencePoint.coordinates as turf.Position;
				mapRef.current = L.map(ref, {
					zoomControl: false,
					zoom: defaultZoomLevel,
					center: [growerReferencePoint[1], growerReferencePoint[0]],
					layers: [hybridLayer] // default to the Streets layer
				});
			}

			// Layer control - defaults to topright
			L.control.layers(baseMaps).addTo(mapRef.current);

			// Zoom control
			L.control.zoom({ position: 'bottomright' }).addTo(mapRef.current);

			setMapContainer(ref);
		}
	}, [setMapContainer]);

	useEffect(() =>
	{
		if (!mapContainer)
		{
			return;
		}

		fieldLayer.options = { pmIgnore: true };
		fieldLayer.addTo(mapRef.current);
		fieldLayer.setStyle(styler);

		soilDataLayer.options = { pmIgnore: true };
		soilDataLayer.addTo(mapRef.current);
		soilDataLayer.setStyle(soilDataStyler);

	}, [mapContainer, mapRef.current]);

	useEffect(() =>
	{
		// When the selectedFieldId changes, make sure to flip the soil button back
		if (!showSoilMapButton)
		{
			setShowSoilMapButton(true);
		}
	}, [selectedFieldId, selectedCropId]);

	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		if (!showSoilMapButton)
		{
			return;
		}	

		// Only displays on crop plan list and field list views
		if (maxScriptFields?.length > 0)
		{
			// Clear the layers just in case
			fieldLayer.clearLayers();
			soilDataLayer.clearLayers();

			// Get all of the boundaries
			const fieldBoundaries: Feature<Geometry, IFieldInventoryDetails>[] = [];

			for (let i = 0, len = maxScriptFields.length; i < len; i++)
			{
				
				const field = maxScriptFields[i];

				if (selectedCropId)
				{
					if (field.CropId !== selectedCropId)
					{
						continue;
					}
				}
				
				if (field.Boundary)
				{
					fieldBoundaries.push(feature(field.Boundary, field));
				}
			}
			if (fieldBoundaries.length > 0)
			{
				const featureLayer = fieldLayer.addData(featureCollection(fieldBoundaries)).bindTooltip(function (layer: L.Layer)
				{
					const name = get(layer, 'feature.properties.Name', '');
					const acres = Number(get(layer, 'feature.properties.Acres', '')).toFixed(1);
					return name + '<br>' + 'Acres: ' + acres;
				}) as L.GeoJSON<any>;

				// Hook into the user clicking on this feature and potentially bubble up the interaction
				featureLayer.on('click', (l: LeafletMouseEvent) =>
				{
					const clickedFeature = l.propagatedFrom?.feature as Feature<Geometry, IFieldResponse>;
					const fieldId = clickedFeature.properties.Id;
					selectField(fieldId);
				});
				
				fieldLayer.setStyle(styler);

				// Center based on what set of fields the user is viewing
				if (selectedCropId)
				{
					onCenterSelected();
				}
				else
				{
					onCenterAll();
				}

				return () =>
				{
					featureLayer.off('click');
				};
			}
		}
	}, [selectedGrower, maxScriptFields, selectedCropId, selectedFieldId, mapRef.current, showSoilMapButton]);

	/**
	 * Zooms to all fields in the Plan
	 */
	const onCenterAll = useCallback(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// Padding will scoot the map over a bit to give space for the overlay on the left
		if (fieldLayer.getLayers().length > 0)
		{
			mapRef.current.fitBounds(fieldLayer.getBounds(), { paddingTopLeft: [10, 0] });
		}
		
	}, [mapRef.current, fieldLayer]);

	/**
	 * Zooms to the fields belonging to a CropPlan on the Plan or to a specific selected field
	 */
	const onCenterSelected = useCallback(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// If we're already looking at a specific field, zoom to it
		if (selectedFieldId)
		{
			const selectedFieldBoundaries: Feature<Geometry, IFieldInventoryDetails>[] = [];
			const selectedField = maxScriptFields.find(f => f.Id === selectedFieldId);
			selectedFieldBoundaries.push(feature(selectedField.Boundary, selectedField));
			const collection = featureCollection(selectedFieldBoundaries);
			const group = L.geoJSON(collection);
			// Padding will scoot the map over a bit to give space for the overlay on the left
			mapRef.current.fitBounds(group.getBounds(), { paddingTopLeft: [10, 0] });
		}
		else if (selectedCropId)
		{
			fieldLayer.clearLayers();

			// Get all of the boundaries that belong to the specific crop plan
			const planFieldBoundaries: Feature<Geometry, IFieldInventoryDetails>[] = [];
			const cropPlanFields: IFieldInventoryDetails[] = maxScriptFields.filter(f => f.CropId === selectedCropId);

			cropPlanFields.forEach(field =>
			{
				if (field && field.Boundary)
				{
					planFieldBoundaries.push(feature(field.Boundary, field));
				}
			});

			if (planFieldBoundaries.length > 0)
			{
				const collection = featureCollection(planFieldBoundaries);
				fieldLayer.addData(collection);
				fieldLayer.setStyle(styler);
				mapRef.current.fitBounds(fieldLayer.getBounds(), { paddingTopLeft: [10, 0] });
			}
		}

	}, [maxScriptFields, selectedCropId, selectedFieldId, mapRef.current]);

	// Load the soil data onto the currently viewed field
	const onLoadSoilMap = async () =>
	{
		// If we have not already downloaded this field
		if (!fieldSoilData[selectedFieldId])
		{
			// Pass the field ID directly to the getSoilData function
			const soilDataResponse = await DownloadSoilMap(selectedFieldId);
			if (soilDataResponse)
			{
				if (fieldSoilData[selectedFieldId])
				{
					fieldSoilData[selectedFieldId] = (soilDataResponse.payload as ISoilDataResponse).data;
					setFieldSoilData({ ...fieldSoilData });
				}
				else
				{
					setFieldSoilData({ ...fieldSoilData, [selectedFieldId]: (soilDataResponse.payload as ISoilDataResponse).data });
				}
			}
		}
		else
		{
			setFieldSoilData({ ...fieldSoilData });
		}

		setShowSoilMapButton(false);
	};

	// Display Soil Data on the soil data layer
	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// Don't show soil zones unless the button has been toggled
		if (showSoilMapButton)
		{
			return;
		}

		soilDataLayer.clearLayers();

		if (!IsLoadingSoilData && fieldSoilData[selectedFieldId])
		{
			// Load the field's soil data boundaries
			const soilDataBoundaries: FeatureCollection<Geometry> = fieldSoilData[selectedFieldId];
			if (soilDataBoundaries)
			{
				if (soilDataLayer.getLayers().length === 0)
				{
					soilDataLayer.addData(soilDataBoundaries) as L.GeoJSON<any>;
				}
			}

			soilDataLayer.setStyle(soilDataStyler);

			return () =>
			{
				soilDataLayer.off('click');
			};
		}
	}, [mapRef.current, fieldSoilData, IsLoadingSoilData, selectedFieldId, showSoilMapButton]);

	const getFieldSoilData = (): IFieldSoilData[] =>
	{
		const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);
		const field = allFields.find(f => f.Id === selectedFieldId);
		const allFieldSoilData: IFieldSoilData[] = [];
		if (!showSoilMapButton && selectedFieldId && fieldSoilData[selectedFieldId])
		{
			const soilData = [ ...fieldSoilData[selectedFieldId].features ];
			soilData.forEach(sd =>
			{
				const area = Number.parseFloat(sd.properties.area);
				const percentOfTotal = ((area / field.Area ) * 100).toFixed(1);
				const singleSoilSectionData: IFieldSoilData =
				{
					Acres: sd.properties.area ? sd.properties.area.toFixed(1) : '0',
					Color: colorToHex(sd.properties.color),
					MapUnit: sd.properties.mukey,
					OverallNccpi: sd.properties.nccpi ? sd.properties.nccpi.Overall.toFixed(2) : 'none',
					PercentOfTotal: percentOfTotal,
					SoilType: sd.properties.soil_type,
				};

				allFieldSoilData.push(singleSoilSectionData);
			});
		}

		return allFieldSoilData;
	};

	return (
		<div id='maxScriptMapId' ref={installMaxScriptMap} style={{ width: 'calc(100% - 70px)', height: '100%' }}>
			<MaxScriptButtonTray
				mapRef={mapRef}
				displayTray={!!selectedFieldId}
				fieldSoilData={getFieldSoilData()}
				showSoilMapButton={showSoilMapButton}
				showFieldBoundary={() => setShowSoilMapButton(true)}
				showSoilZone={onLoadSoilMap}
			/>
			<ZoomTray
				mapRef={mapRef}
				onCenterAll={onCenterAll}
				onCenterSelected={onCenterSelected}
				displayTools={selectedGrower.Id ? true : false}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	selectedGrowerId: state.ui.SelectedGrowerId,
	IsLoadingSoilData: state.soilData.isLoading,
	MapboxToken: state.config.MapboxAccessToken,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		DownloadSoilMap: (fieldId: string) => dispatch(getSoilData(fieldId))
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const MaxScriptMap = connector(MaxScriptMapComponent);
