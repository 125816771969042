import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ColorMatchBar } from '../../../components/ColorMatchBar/ColorMatchBar';
import { Button } from '../../../components/Button/Button';
import { IOrderPlanProducts } from '../../../logic/Models/Responses/OrderPlanResponse';
import { CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { CalculateBags, RoundAcres, RoundWithPrecision } from '../../../logic/Utility/CalculationUtilities';
import { checkContrast, dynamicSort } from '../../../logic/Utility/Utils';
import { ReactComponent as CornIcon } from '../Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from '../Icons/SoyIcon.svg';
import { ReactComponent as AlertIcon } from '../Icons/AlertIcon.svg';
import { ICropPlanDetails } from './MaxScriptMain';
import { IFieldInventoryDetails } from './FieldInventoryView';
import { CompetitorSeedsModal } from './Competitor Seeds/CompetitorSeedsModal';
import { ICompetitorSeedItem } from './Competitor Seeds/CompetitorRow';
import { ICompetitorObject } from '../../../logic/Models/Responses/CompetitorResponse';
import { themeGet } from '@styled-system/theme-get';

const ProductInventoryContainer = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

const StyledCornIcon = styled(CornIcon)<{ strokecolor: string }>`
	path {
		stroke: ${props => props.strokecolor};
		fill: white;
	}
`;

const StyledSoyIcon = styled(SoyIcon)<{ strokecolor: string }>`
	path {
		stroke: ${props => props.strokecolor};
		fill: white;
	}
`;

const IconBox = styled.div<{ fillcolor: string }>`
	display: flex;
	padding: 5px;
	background-color: ${props => props.fillcolor};
	border: 1px solid;
	border-color: ${themeGet('colors.darkGrey')};
	border-radius: 3.5px;
	margin-right: 10px;
`;

const CompetitorSeedsModalButton = styled(Button)`
	width: 150px;
	position: fixed;
	bottom: 20px;
`;

interface IProductInventoryProps
{
	competitorBrandsAndProducts: ICompetitorObject[]; // competitor brands and products from the redux store
	cropAcreageCounts: ICropPlanDetails;
	cropFillColor: string;
	selectedCropFieldInventory: IFieldInventoryDetails[];
	selectedCropId: string;
	selectedCropName: string;
	selectedCropProductInventory: IOrderPlanProducts[];
	saveCompetitors: (newCompetitors: ICompetitorSeedItem[], deletedCompetitors: ICompetitorSeedItem[]) => void;
}

export const MaxScriptProductInventory = (props: IProductInventoryProps) =>
{
	const {
		competitorBrandsAndProducts,
		cropAcreageCounts,
		cropFillColor,
		selectedCropFieldInventory,
		selectedCropId,
		selectedCropName,
		selectedCropProductInventory,
		saveCompetitors,
	} = props;

	const theme = useTheme();

	const [remainingCropAcres, setRemainingCropAcres] = useState<number>(0);
	const [showCompetitorSeedsModal, setShowCompetitorSeedsModal] = useState<boolean>(false);

	const externalProductsExist: boolean = selectedCropProductInventory?.some(p => p.IsExternal);

	useEffect(() =>
	{
		if (selectedCropFieldInventory)
		{
			const hybridInventories = selectedCropFieldInventory.flatMap(inv => inv.Inventory).filter(inventory => inventory);
			const remainingCropAcresCalcd = hybridInventories.reduce((prev, current) => 
				RoundWithPrecision(prev - current.Acres, 1, 1), cropAcreageCounts.TotalFieldAcreage);
			
			setRemainingCropAcres(RoundAcres(remainingCropAcresCalcd));
		}
	},[selectedCropFieldInventory, cropAcreageCounts]);

	const calcProductUsage = useCallback((hybridId: string, isExternal: boolean = false): number =>
	{
		let totalAppliedBagsForHybrid = 0;
		let totalAcresForHybrid = 0;
		let totalRate = 0;
		// Determine how many bags are being used for the specified Hybrid
		if (selectedCropFieldInventory)
		{
			// Bags per field = Acres * seeding rate / seeds per bag
			const specifiedHybridFields = selectedCropFieldInventory.flatMap(f => f.Inventory);
			const seedsPerBag = CropConfig()[selectedCropId].seedsPerBag;
			specifiedHybridFields.forEach(hybridField =>
			{
				if (!isExternal)
				{
					if (hybridField && hybridField.HybridId === hybridId && hybridField.Acres > 0)
					{
						// Bags used
						totalAppliedBagsForHybrid += CalculateBags(hybridField.Acres, hybridField.Rate, seedsPerBag);
						totalAcresForHybrid += hybridField.Acres;
						totalRate += hybridField.Rate * hybridField.Acres;
					}
				}
				else
				{
					if (hybridField && hybridField.HybridId === hybridId && hybridField.Acres > 0)
					{
						totalAcresForHybrid += hybridField.Acres;
					}
				}
			});

			if (!isExternal)
			{
				const avgWeightedRate = totalRate > 0 && totalAcresForHybrid > 0 ? RoundWithPrecision(totalRate / totalAcresForHybrid, 1, 1) : 0;
				totalAcresForHybrid = RoundWithPrecision(totalAcresForHybrid, 1, 1);
				totalAppliedBagsForHybrid = CalculateBags(totalAcresForHybrid, avgWeightedRate, seedsPerBag);
			}
			else
			{
				totalAcresForHybrid = RoundWithPrecision(totalAcresForHybrid, 1, 1);
			}
		}
		if (!isExternal)
		{
			return totalAppliedBagsForHybrid;
		}
		else
		{
			return totalAcresForHybrid;
		}
	}, [selectedCropFieldInventory]);

	// Return the appropriate icon for the type of crop currently being viewed.
	const determineIcon = () =>
	{
		if (selectedCropName.toLocaleLowerCase() === 'corn')
		{
			return (
				<StyledCornIcon strokecolor={theme.colors.darkGrey} height={30} width={30} />
			);
		}
		else
		{
			return (
				<StyledSoyIcon strokecolor={theme.colors.darkGrey} height={30} width={30} />
			);
		}
	};

	const openCompetitorSeedsModal = () =>
	{
		setShowCompetitorSeedsModal(true);
	};

	const closeCompetitorSeedsModal = () =>
	{
		setShowCompetitorSeedsModal(false);
	};

	const getTitle = (product: IOrderPlanProducts): string =>
	{
		let title = '';
		if (product.IsExternal)
		{
			title += `${product.BrandName}`;
			if (product.HybridName)
			{
				title += `-${product.HybridName}`;
			}
			if (product.Rm)
			{
				title += `-${product.Rm}`;
			}
		}
		else
		{
			title = product.HybridName;
		}

		return title;
	};

	const saveCompetitorsAndCloseModal = (newCompetitors: ICompetitorSeedItem[], deletedCompetitors: ICompetitorSeedItem[]) =>
	{
		// Save
		saveCompetitors(newCompetitors, deletedCompetitors);
		// Close the modal
		setShowCompetitorSeedsModal(false);
	};

	const showCompetitorModal = () =>
	{
		if (showCompetitorSeedsModal)
		{
			return (
				<CompetitorSeedsModal
					competitorBrandsAndProducts={competitorBrandsAndProducts}
					cropId={selectedCropId}
					productInventory={selectedCropProductInventory}
					visible={showCompetitorSeedsModal}
					onCancel={closeCompetitorSeedsModal}
					saveCompetitors={saveCompetitorsAndCloseModal}
				/>
			);
		}
		else
		{
			return (
				<></>
			);
		}
	};

	return (
		<ProductInventoryContainer className='MaxScript_ProductInventoryContainer'>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<IconBox fillcolor={cropFillColor}>
					{determineIcon()}
				</IconBox>
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal }}>
						{selectedCropName}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', fontSize: theme.fontSizes.small }}>
						<div>{cropAcreageCounts.TotalFieldAcreage + ' acres'}</div>
						<div style={{ marginLeft: 'auto', color: theme.colors.brightOrange }}>
							{
								Math.abs(cropAcreageCounts.TotalFieldAcreage - remainingCropAcres) <= cropAcreageCounts.TotalFieldAcreage ?
									`(${remainingCropAcres} remaining)`
									:
									<div>
										<AlertIcon style={{ position: 'relative', top: 3, marginRight: 5 }} />
										{`${RoundAcres(Math.abs(remainingCropAcres))} acres over`}
									</div>
							}
						</div>
					</div>
				</div>
			</div>
			<div style={{ marginTop: 20, marginBottom: 80, overflowY: 'auto', }}>
				<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.small, paddingBottom: 10 }}>
					Product Inventory
				</div>
				<div>
					{
						selectedCropProductInventory && selectedCropProductInventory.sort(dynamicSort('Rm')).filter(p => !p.IsExternal).map(product =>
						{
							const appliedBags = calcProductUsage(product.HybridId);
							const errorOverage = appliedBags !== 0 && (appliedBags > product.Bags);
							return (
								<div
									className={'ProductInventory-SyngentaProducts-ColorBar-Container'}
									key={product.IsExternal ? `${product.BrandName}-${product.HybridName}-${product.Rm}`
										: `${product.HybridName}-${product.HybridId}`}
									style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}
								>
									<ColorMatchBar
										containerClassName={`ColorBar-${product.HybridId}-${product.Rm}`}
										clickable={false}
										containerMargin={'5px'}
										title={getTitle(product)}
										fontColor={checkContrast(product.Color)}
										fontWeight={theme.fontWeights.bold}
										showOuterBorder={true}
										color={product.Color}
										maxValue={product.Bags} // Total inventory purchase
										value={appliedBags} // How much inventory is applied to fields
										showDetails={false}
									/>
									<div className={`ColorBar-values-text-${product.HybridId}`} style={{ display: 'flex', flexDirection: 'row' }}>
										<div className={`ColorBar-totalbags-${product.HybridId}`}>{`${product.Bags} pbs`}</div>
										<div className={`ColorBar-remaining-${product.HybridId}`} style={{ marginLeft: 'auto' }}>
											{
												!errorOverage ?
													`${product.Bags - appliedBags} remaining`
													:
													<div>
														<AlertIcon style={{ position: 'relative', top: 3, marginRight: 5 }} />
														{`${Math.abs(product.Bags - appliedBags)} units over`}
													</div>
											}
										</div>
									</div>
								</div>
							);
						})
					}
					{
						externalProductsExist && 
						<div>
							<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.small, paddingBottom: 10, paddingTop: 20 }}>
								Competitor Acres
							</div>
							{
								selectedCropProductInventory && 
								selectedCropProductInventory.sort(dynamicSort('HybridId')).filter(p => p.IsExternal).map(product =>
								{
									const appliedAcres = calcProductUsage(product.HybridId, true);
									return (
										<div
											className={'ProductInventory-External-ColorBar-Container'}
											key={`${product.BrandName}-${product.HybridName}-${product.Rm}`}
											style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}
										>
											<ColorMatchBar
												containerClassName={`ColorBar-${product.BrandName}-${product.HybridName}-${product.Rm}`}
												clickable={false}
												containerMargin={'5px'}
												title={getTitle(product)}
												fontColor={checkContrast(product.Color)}
												fontWeight={theme.fontWeights.bold}
												showOuterBorder={true}
												color={product.Color}
												maxValue={product.Acres} // Total inventory purchase
												value={product.Acres} // How much inventory is applied to fields
												showDetails={false}
											/>
											<div 
												className={`ColorBar-values-text-${product.BrandName}-${product.HybridName}-${product.Rm}`}
												style={{ display: 'flex', flexDirection: 'row' }}
											>
												<div
													className={`ColorBar-totalbags-${product.BrandName}-${product.HybridName}-${product.Rm}`}
												>{`${appliedAcres} acres`}</div>
											</div>
										</div>
									);
								})
							}
						</div>
					}
				</div>
			</div>
			<CompetitorSeedsModalButton variant='outlined' onClick={openCompetitorSeedsModal}>+ Add Competitor</CompetitorSeedsModalButton>
			{showCompetitorModal()}
		</ProductInventoryContainer>
	);
};