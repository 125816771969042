'use strict';

import React, { useCallback, useReducer, useState } from 'react';
import { Progress, Tooltip } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../logic/store/Store';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Button } from '../../../components/Button/Button';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTransfer } from './useTransfer';
import { useIndividualTransferValidation } from './useIndividualTransferValidation';
import { useViableCrossReferences } from './useViableCrossReferences';
import { useTransferAllValidation } from './useTransferAllValidation';
import { SelectionComponent } from './SelectionComponent';
import { useSelectionState } from './useSelectionState';
import { useTheme } from 'styled-components';


const mapStateToProps = (state: RootState) => ({
	userAuthToken: state.auth.userAuthToken,
	UserId: state.userInfo.UserId,
	ManageableUsers: state.auth.manageableUsers
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type SelectionState =  ReturnType<typeof useSelectionState>;

export interface ICrossReferencedGrower
{ 
	sourceGrowerId: string;
	destGrowerId: string;
	foundationId: string;
	valid: boolean; 
	reason: string
}

/**
 * Present tools to transfer grower fields from one account to another, or even one grower to another in the same account
 */
const DataTransferTroubleshootingTool = (props: PropsFromRedux) => 
{
	const theme = useTheme();
	
	const [loading, loader] = useReducer((state:{count:number,progress:number,expected:number}, action: 'inc_load'|'dec_load'|'reset_progress'|'inc_progress'|{type:'add_expected',count:number}) => 
	{
		if(action === 'inc_load')
		{
			return {
				...state,
				count: state.count + 1
			};
		}
		else if(action === 'dec_load')
		{
			return {
				...state,
				progress: state.count === 1 ? 0 : state.progress,
				expected: state.count === 1 ? 0 : state.expected,
				count: state.count - 1
			};			
		}
		else if(action === 'reset_progress')
		{	
			return {
				...state,
				progress: 0
			};			
		}
		else if(action === 'inc_progress')
		{
			return {
				...state,
				progress: state.progress + 1
			};
		}
		else if(action.type === 'add_expected')
		{
			return {
				...state,
				expected: state.expected + action.count,
			};
		}
		
		return state;
	}, {
		count: 0,
		progress: 0,
		expected: 0
	});
	const startLoading = useCallback(() => loader('inc_load'), [loader]);
	const endLoading = useCallback(() => loader('dec_load'), [loader]);
	
	const incrementProgress = useCallback((reset?:boolean) => reset ? loader('reset_progress') : loader('inc_progress'), [loader]);
	const addExpected = useCallback((items: number) => loader({type:'add_expected', count:items}), [loader]);

	const isLoading = loading.count > 0;

	const [transferAll, setTransferAll] = useState(false);

	const source = useSelectionState(startLoading, endLoading, true, incrementProgress, addExpected);
	const dest = useSelectionState(startLoading, endLoading, false, incrementProgress, addExpected);

	const { potentialPairings, crossReferencePairings } = useViableCrossReferences(source, dest);

	const { blockingTransfer, warningTransfer } = useIndividualTransferValidation(transferAll, source, dest);
	const blockingTransferAllGrowers = useTransferAllValidation(transferAll, potentialPairings, crossReferencePairings, source, dest);

	const onCheck = (e: CheckboxChangeEvent) => 
	{
		setTransferAll(e.target.checked);
	};
	
	const transfer = useTransfer(source, dest, transferAll, crossReferencePairings, startLoading, endLoading, dest.refresh, incrementProgress, addExpected);

	return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', flexGrow: 1, width: '100%', overflow: 'hidden', position: 'relative'}}>
		<div style={{flexGrow:3, flexBasis: 0, padding: 16, display:'flex', flexDirection: 'column', alignItems: 'stretch'}}>
			<h3>Source</h3>
			<SelectionComponent isLoading={isLoading} state={source} highlight={transferAll && crossReferencePairings}/>
		</div>
		<div style={{flexGrow:1, flexBasis: 120, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding:16 }}>
			<Checkbox onChange={onCheck} style={{marginBottom: 16, fontSize: theme.fontSizes.normal}} >Transfer all matching growers</Checkbox>
			<Tooltip 
				title={blockingTransferAllGrowers || blockingTransfer || warningTransfer}
			>
				<div>
					<Button
						onClick={transfer} 
						variant='reversed' 
						disabled={isLoading || Boolean(blockingTransfer) || Boolean(blockingTransferAllGrowers)} 
						style={{minWidth: 120, width: '100%', pointerEvents: (blockingTransfer || blockingTransferAllGrowers) ? 'none' : 'inherit'}}>Transfer &gt;&gt;</Button>
				</div>
			</Tooltip>
		</div>
		<div style={{flexGrow:3, flexBasis: 0, padding: 16, display:'flex', flexDirection: 'column', alignItems: 'stretch'}}>
			<h3>Destination</h3>
			<SelectionComponent isLoading={isLoading} state={dest} highlight={transferAll && crossReferencePairings}/>
		</div>
		<LoadingSpinner loading={isLoading} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>
			Loading&nbsp; 
			{(loading.progress || loading.expected) ? loading.progress : ''}
			{loading.expected ? ' / ' + loading.expected : ''}
			...</LoadingSpinner>
	</div>;
};

export const DataTransferTroubleshootingToolComponent = connector(DataTransferTroubleshootingTool);


