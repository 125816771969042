import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

/**
 * A full-element wrapper with an animated fade in and out.
 * Adds a delay to try to avoid showing for short load events.
 */

export const CoveringLoader = styled.div<{ duration?: number }>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background-color: ${themeGet('colors.transparentBlack')};
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity ${(state) => state.duration ?? 300}ms ease-in-out ${(state) => state.duration ?? 300}ms;
	pointer-events: none;	

	&.loading {
		opacity: 1;
		pointer-events: auto;	
	}
`;
