import { themeGet } from '@styled-system/theme-get';
import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ListChildComponentProps, VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export type TableRowType<T> = {
	items: ReactNode[];
	expandedSection?: ReactNode,
	data: T & { Id: string }
};
interface ITableRowsProps extends React.HTMLAttributes<HTMLDivElement>
{
	rows: any[];
	renderComponent: (propsToChildren: ListChildComponentProps) => ReactElement;
	// must provide height or width if a disable sizing is true
	height?: number;
	width?: number;
	itemSize: (index: number) => number;
	columnSpacing?: string;
	hideExpand?: boolean;
	initialExpanded?: { [key: string]: boolean };
	label?: string;
}
export const StyledTableRows = styled.div``;

export const RowLabel = styled.div`
	padding-left: 36px;
	margin-bottom: 1.5%;
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.normal')};
	color: ${themeGet('colors.darkGrey')};
`;

export const TableRows = (props: ITableRowsProps) =>
{
	const { rows = [], label, height: heightFromProps, width: widthFromProps, itemSize, renderComponent, className, style } = props;
	const ref = useRef(null);

	// once we get the ref of the list, force reset rendering to recalculate height
	useEffect(() =>
	{
		if (ref && ref.current?.resetAfterIndex)
		{
			ref.current?.resetAfterIndex(0, false);
		}
	}, [ref, rows]);

	return <StyledTableRows className={className} style={style}>
		{label && <RowLabel>{label}</RowLabel>}
		<AutoSizer>
			{({ width, height }) => (
				<List
					ref={ref}
					width={width || widthFromProps}
					height={height || heightFromProps}
					itemData={{ rows, ref }}
					itemSize={itemSize}
					itemCount={rows.length}
				>
					{renderComponent}
				</List>
			)}
		</AutoSizer>
	</StyledTableRows>;
};
