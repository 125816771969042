import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';

/**
 * Widget for showing the availability of a series
 */
export const SeriesAvailability = styled.div<{ disabled?: boolean }>`
	min-width: 40px;
	height: 100%;
	background-color: ${props => props.disabled ? props.theme.colors.disabledGrey : props.theme.colors.primary};
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	path, circle.fill {
		fill: ${themeGet('colors.white')};
		stroke: ${themeGet('colors.white')};
	}

	circle {
		stroke: ${themeGet('colors.white')};
	}
`;

/**
 * Widget for showing the availability of a specific hybrid
 */
export const HybridAvailability = styled.div`
	width: 40px;
	height: 100%;
	background-color: ${themeGet('colors.white')};
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
