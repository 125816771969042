import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IUpdateFromSource, IUpdateHybrid, IUpdateSeedAssignments } from '../../Models/Requests/SourceUpdateTypes';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IUpdateSeedAssignmentRequest extends IUpdateSeedAssignments
{
	FieldId: string;
	FieldYear: string;
}

export const updateSeedAssignmentsForField = createTracedAsyncThunk<object, IUpdateSeedAssignmentRequest, { dispatch: AppDispatch, state: RootState }>(
	'update/seedassignmentforfield',
	async (context, request: IUpdateSeedAssignmentRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const { FieldId, FieldYear } = request;
			
			const response = await api.putAsync<object>(`fields/${FieldId}/years/${FieldYear}/assignments`, request);
			if (response.ErrorCode === null && response.Success) 
			{
				// 10/18/2022 - this function actually returns a FieldYearProjection but we do not currently care about the return data, only that it was a success
				return true;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IBulkUpdateFieldsRequest extends IUpdateFromSource
{
	Fields: IBulkUpdateField[];
}

export interface IBulkUpdateField
{
	FieldId: string;
	PlantingDate: string;
	Hybrids: IUpdateHybrid[];
}

/**
 * Field Confirmation -- Setting Assignments, Planting Date, and Crop as actuals
 */
export const confirmBulkFields = createTracedAsyncThunk<object, IBulkUpdateFieldsRequest, { dispatch: AppDispatch, state: RootState }>(
	'confirm/field',
	async (context, request: IBulkUpdateFieldsRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const { ui, crops } = thunkAPI.getState() as RootState;
			const cropYear = ui.SelectedYear ? ui.SelectedYear : crops.CropYear;
			
			const response = await api.putAsync<object>(`years/${cropYear}/fields`, request);
			if (response.ErrorCode === null && response.Success)
			{
				// 10/18/2022 - this function actually returns a FieldYearProjection but we do not currently care about the return data, only that it was a success
				return true;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);