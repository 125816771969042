import {  } from '@reduxjs/toolkit';
import { clearRankings } from '../Plans/ProductGamePlanSlice';
import { getSeedsForGrower } from '../Seeds/SeedsActions';
import { clearSeeds } from '../Seeds/SeedsSlice';
import { AppDispatch, RootState } from '../Store';
import { setSelectedGrowerId, clearSelectedPlanIdState } from './UISlice';
import { clearState as ClearFieldPlanState } from '../Plans/FieldPlan/FieldPlanSlice';
import { clearState as ClearProductPlanState } from '../Plans/ProductGamePlanSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

/**
 * Clears the seeds and their rankings from a previous grower on selecting a new grower, and pulls the newly selected grower's seeds 
 */
export const selectGrowerAndPullSeeds = createTracedAsyncThunk<null, string, { dispatch: AppDispatch; state: RootState; }>(
	'selectGrower/pullSeeds',
	async (context, request, thunkAPI) =>
	{
		try
		{
			// Set the Grower ID
			thunkAPI.dispatch(setSelectedGrowerId(request));

			// Clear the current seeds
			thunkAPI.dispatch(clearSeeds());

			// Clear the current rankings
			thunkAPI.dispatch(clearRankings());

			// Now call the seed endpoint
			thunkAPI.dispatch(getSeedsForGrower());

			// Clear any plan data
			thunkAPI.dispatch(ClearFieldPlanState());
			thunkAPI.dispatch(clearSelectedPlanIdState());
			thunkAPI.dispatch(ClearProductPlanState());

			return null;
		}
		catch (e)
		{
			return thunkAPI.rejectWithValue(e);
		}
	}
);