import { ICustomTreatmentResponse } from '../../../../logic/Models/Responses/CustomTreatmentResponse';
import { IMaterialAvailabilityResponse } from '../../../../logic/Models/Responses/SeedResponse';
import { CropConfig, ICropConfig } from '../../../../logic/store/Seeds/CropsSlice';


export interface ISelectableTreatmentAvailability
{
	PackageType: string;
	Availabililty: string;
}

export interface ISelectableTreatment
{
	Selected: boolean;
	CustomTreatmentId?: string;
	CustomIsDeleted?: boolean;
	Treatment: string;
	PackagingAvailability: ISelectableTreatmentAvailability[];
}

export const calculateTotalBags = (quantityQB: number, quantityPB: number, quantityTB: number, quantityTO: number, cropId: string): number =>
{
	const cropConfig: ICropConfig = CropConfig()[cropId];
	const { bagsPerToteBag, cubitsPerBag } = cropConfig;

	const paperBags = quantityPB ?? 0;
	const cubitsAsBags = (quantityQB ?? 0) * cubitsPerBag;
	const toteBagsAsBags = (quantityTO ?? 0) * (bagsPerToteBag ?? 1);
	const trubulkAsBags = quantityTB ?? 0;
	const totalBags = paperBags + cubitsAsBags + toteBagsAsBags + trubulkAsBags;

	return totalBags;
};

export const setupTreatments = (seedTreatments: IMaterialAvailabilityResponse[], customTreatments?: ICustomTreatmentResponse[]) : ISelectableTreatment[] =>
{
	const selectableTreatments: ISelectableTreatment[] = [];

	if (seedTreatments && seedTreatments.length > 0)
	{
		seedTreatments.forEach(st =>
		{
			// Loop through non-custom treatments
			const index = selectableTreatments.findIndex(select => select.Treatment === st.Treatment);
			if (index > -1)
			{
				// Already exists -- check if we need to add packaging
				if (selectableTreatments[index]?.PackagingAvailability.findIndex(pa => pa.PackageType === st.Packaging) === -1)
				{
					selectableTreatments[index].PackagingAvailability.push({ PackageType: st.Packaging, Availabililty: st.Availability });
				}
			}
			else
			{
				// Doesn't exist -- add a new item
				const newTreatment: ISelectableTreatment =
					{
						Selected: false,
						Treatment: st.Treatment,
						PackagingAvailability: [{ PackageType: st.Packaging, Availabililty: st.Availability }]
					};
				selectableTreatments.push(newTreatment);
			}
		});
	}

	// Loop through custom treatments
	if (customTreatments && customTreatments.length > 0)
	{
		customTreatments.forEach(ct =>
		{
			// If there is an Untreated Packaging Availability, use it instead of the default 'Green'
			const untreatedIndex = selectableTreatments.findIndex(select => select.Treatment.toLocaleLowerCase() === 'untreated');
			const index = selectableTreatments.findIndex(select => select.CustomTreatmentId && select.CustomTreatmentId === ct.Id);
			if (index === -1)
			{
				// Doesn't exist -- add a new item
				const newTreatment: ISelectableTreatment =
					{
						Selected: false,
						Treatment: ct.Name,
						CustomIsDeleted: ct.IsDeleted,
						CustomTreatmentId: ct.Id,
						PackagingAvailability: untreatedIndex > -1 ?
							selectableTreatments[untreatedIndex].PackagingAvailability 
							: 
							[
								{ PackageType: 'PB', Availabililty: 'Green' },
								{ PackageType: 'QB', Availabililty: 'Green' },
								{ PackageType: 'TO', Availabililty: 'Green' },
								{ PackageType: 'TB', Availabililty: 'Green' }
							]
					};
				selectableTreatments.push(newTreatment);
			}
		});		
	}

	return selectableTreatments;
};