import React from 'react';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as RightCaretIcon } from '../Icons/RightCaret.svg';

interface IGrowerListCardProps
{
	grower: IGrowerResponse;
	onSelectGrower: (selectedGrowerId: string) => void;
}

const GrowerListCardContainer = styled.div`
	display: flex;
	width: 99%;
	border: 1px solid ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	margin-top: 10px;
	padding: 15px;
	cursor: pointer;
`;

/**
 * Will display when no Grower is currently selected but the user has navigated to Field Activities from the Header
 */
export const GrowerListCard = (props: IGrowerListCardProps) =>
{
	const { grower, onSelectGrower } = props;

	const theme = useTheme();

	// Set decimal places to 2, but don't show any 0.00's
	const totalAcres: string = grower.TotalAcresFarmed !== undefined ?
		(grower.TotalAcresFarmed !== 0 ? grower.TotalAcresFarmed.toFixed(1) : '0') : '';

	return (
		<GrowerListCardContainer className={`GrowerListCard_${grower.Id}`} onClick={() => onSelectGrower(grower.Id)}>
			<div style={{
				maxWidth: '80%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}}>
				<div className='GrowerListCard_GrowerName' style={{ fontSize: theme.fontSizes.small, fontWeight: theme.fontWeights.bold }}>{grower.Name}</div>
			</div>
			<div style={{
				marginLeft: 'auto',
				width: totalAcres ? '' : '20%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end'
			}}>
				<div className='GrowerListCard_Area' style={{ paddingRight: 5, fontSize: theme.fontSizes.extraSmall, color: theme.colors.mediumGrey }}>
					{totalAcres}
				</div>
				<RightCaretIcon style={{ position: 'relative', top:-1 }} />
			</div>
		</GrowerListCardContainer>
	);
};