import { Api } from '../../../Api/Api';
import { ICropPlanCreateRequest, ICropPlanUpdateRequest } from '../../../Models/Requests/FieldPlanRequests';
import { ICropPlanAgronomicAttributes, ICropPlanFullResponse, ICropPlanSelectedProduct, IFieldPlanResponse } from '../../../Models/Responses/FieldPlanResponse';
import { dynamicSort, sleepOnIt } from '../../../Utility/Utils';
import { AppDispatch, RootState } from '../../Store';
import { setSelectedPlanId } from '../../UI/UISlice';
import { updateFieldPlanProgress } from './FieldPlanSlice';
import { FieldPlanCropPlanColorsList } from '../../../Utility/Colors';
import { createTracedAsyncThunk } from '../../../../tracing/trace';

export const createFieldPlan = createTracedAsyncThunk<IFieldPlanResponse, null, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/create',
	// Nothing needs to be passed here so set the request to null
	async (context, request: null, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { ui, auth, crops } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.postAsync<IFieldPlanResponse>(
				`growers/${ui.SelectedGrowerId}/years/${crops.CropYear}/fieldplans`, '');

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getFieldPlan = createTracedAsyncThunk<IFieldPlanResponse, { planId: string }, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/get',
	async (context, request: {planId: string}, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<IFieldPlanResponse>(`fieldplans/${request.planId}`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

// Polls the Get Field Plan endpoint without trigger the loading spinner so that the Crop Plans status can be checked
export const pollGetFieldPlan = createTracedAsyncThunk<IFieldPlanResponse, null, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/poll',
	async (context, request: null, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth, fieldplan } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			let response = await api.getAsync<IFieldPlanResponse>(`fieldplans/${fieldplan.currentFieldPlan?.Id}`);

			let allPlansComplete = false;
			let retryCounter: number = 0;
			const retryLimit = 2000;
			do 
			{
				// In case the user navigates away from the page, check the current state, if one of these is cleared
				// cancel the poll
				const currentState = thunkAPI.getState();
				const currentFieldPlanState = currentState.fieldplan;
				const currentSelectedPlanId = currentState.ui.SelectedPlanId;
				if (!currentFieldPlanState.currentFieldPlan || !currentSelectedPlanId)
				{
					return undefined;
				}

				// If we've hit the ceiling of retries, stop.
				if (retryCounter >= retryLimit)
				{
					allPlansComplete = true;
				}
				else
				{
					// wait half a second for each look
					await sleepOnIt(1000);
					response = await api.getAsync<IFieldPlanResponse>(`fieldplans/${currentFieldPlanState.currentFieldPlan?.Id}`);
					if (response.ErrorCode === null && response.Success)
					{
						thunkAPI.dispatch(updateFieldPlanProgress(response.Data));
						if (!response.Data.CropPlans.some(cp => cp.Status === 'Pending' || cp.Status === 'Processing'))
						{
							allPlansComplete = true;
							retryCounter = retryLimit;
						}
						else
						{
							retryCounter++;
						}
					}
				}
			} while(!allPlansComplete);

			if (response.ErrorCode === null && response.Success) 
			{
				if (fieldplan && fieldplan.selectedCropPlanId)
				{
					await thunkAPI.dispatch(getCropPlan({ cropPlanId: fieldplan.selectedCropPlanId, blocking: true }));
				}
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IUpdateFieldPlanRequest
{
	FieldPlanId?: string;
	Name: string;
}

export const updateFieldPlan = createTracedAsyncThunk<IFieldPlanResponse, IUpdateFieldPlanRequest, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/updatefieldplan',
	async (context, request: IUpdateFieldPlanRequest, thunkAPI) =>
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth, fieldplan } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const fieldPlanId = request.FieldPlanId ? request.FieldPlanId : fieldplan.currentFieldPlan.Id;
			const response = await api.putAsync<IFieldPlanResponse>(`fieldplans/${fieldPlanId}?returnUpdatedPlan=true`, request);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const createCropPlan = createTracedAsyncThunk<ICropPlanFullResponse, ICropPlanCreateRequest, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/create',
	async (context, request: ICropPlanCreateRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth, fieldplan } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.postAsync<ICropPlanFullResponse>(`fieldplans/${fieldplan.currentFieldPlan.Id}/cropplans`, request);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const createFieldPlanWithCropPlan = createTracedAsyncThunk<IFieldPlanResponse, ICropPlanCreateRequest, { dispatch: AppDispatch, state: RootState }>(
	'fieldplanwithcropplan/create',
	async (context, request: ICropPlanCreateRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { fieldplan } = state;

			const cropColorList = FieldPlanCropPlanColorsList[`${request.CropId}_${request.BrandApplication}`];

			if (fieldplan && fieldplan.currentFieldPlan)
			{
				// Existing Field Plan - Just adding a new Crop Plan
				const similarPlans = 
					fieldplan.currentFieldPlan.CropPlans.filter(cp => cp.BrandApplication === request.BrandApplication && cp.CropId === request.CropId);
				
				if (similarPlans && similarPlans.length > 0)
				{
					const unusedColors = cropColorList.filter(color => !similarPlans.map(p => p.Color).includes(color));
					if (unusedColors.length > 0)
					{
						request.Color = unusedColors[0];
					}
					else
					{
						// We have reached the maximum colors so start at the top again
						request.Color = cropColorList[(similarPlans.length) % cropColorList.length];
					}
				}
				else
				{
					// First Crop Plan of it's kind
					request.Color = cropColorList[0];
				}

				// Create the Crop Plan
				await thunkAPI.dispatch(createCropPlan(request));
				// Set the selected Plan ID
				thunkAPI.dispatch(setSelectedPlanId(undefined));
				thunkAPI.dispatch(setSelectedPlanId(fieldplan.currentFieldPlan.Id));
			}
			else
			{
				// Brand new FieldPlan Flow

				// Create the Field Plan
				await thunkAPI.dispatch(createFieldPlan());

				// Get a fresh color for the type of plan
				request.Color = cropColorList[0];

				// Create the Crop Plan
				await thunkAPI.dispatch(createCropPlan(request));

				const innerState = thunkAPI.getState();
				const { fieldplan } = innerState;

				// Set the selected Plan ID
				thunkAPI.dispatch(setSelectedPlanId(fieldplan.currentFieldPlan.Id));
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const updateCropPlanFields = createTracedAsyncThunk<ICropPlanFullResponse, { cropPlanId: string, fieldIds: string[] },  { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/editfields',
	async (context, request, thunkAPI) => 
	{
		try 
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.putAsync<ICropPlanFullResponse>(`cropplans/${request.cropPlanId}/fields`, request.fieldIds);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		catch(e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getCropPlan = createTracedAsyncThunk<ICropPlanFullResponse, { cropPlanId: string, blocking: boolean }, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/get',
	async (context, request: { cropPlanId: string }, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth, grower, ui } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<ICropPlanFullResponse>(`cropplans/${request.cropPlanId}`);

			if (response.ErrorCode === null && response.Success)
			{
				// Sort the fields by name
				const allGrowerFields = grower.Growers.find(g => g.Id === ui.SelectedGrowerId).Farms.flatMap(fa => fa.Fields);
				if (response.Data.Fields && response.Data.Fields.length > 0)
				{
					response.Data.Fields.forEach(f =>
					{
						const foundField = allGrowerFields.find(gf => gf.Id === f.FieldId);
						if (foundField)
						{
							f.FieldName = foundField.Name;
						}
					});

					response.Data.Fields.sort(dynamicSort('FieldName'));
				}

				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export const getCropPlanAgronomicAttributes = createTracedAsyncThunk<ICropPlanAgronomicAttributes, { cropPlanId: string, blocking: boolean }, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/get/agronomics',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<ICropPlanAgronomicAttributes>(`cropplans/${request.cropPlanId}/products/attributes`);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const convertProductPlanToFieldPlan = createTracedAsyncThunk<IFieldPlanResponse, { productPlanId: string }, { dispatch: AppDispatch, state: RootState }>(
	'convertProductPlanToFieldPlan/create',
	// Nothing needs to be passed here so set the request to null
	async (context, request, thunkAPI) => 
	{
		try
		{
			// call the endpoint
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.postAsync<IFieldPlanResponse>(`productplans/${request.productPlanId}/fieldplans`, undefined);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export interface ICropPlanUpdateRequestWithCropPlanId
{
	CropPlanId: string;
	UpdateRequest: ICropPlanUpdateRequest;
	StoreResponse?: boolean;
}

export const updateCropPlan = createTracedAsyncThunk<ICropPlanFullResponse, ICropPlanUpdateRequestWithCropPlanId, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/update',
	async (context, request: ICropPlanUpdateRequestWithCropPlanId, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.putAsync<ICropPlanFullResponse>(`cropplans/${request.CropPlanId}`, request.UpdateRequest);
			// Explicitly check for an undefined which is returned on a 204 (no change in content)
			if(response === undefined) 
			{
				return undefined;
			}
			else if (!response.ErrorCode && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IDeleteCropPlanRequest
{
	ConfirmLastCropPlan: boolean;
	CropPlanId: string;
}

export const deleteCropPlan = createTracedAsyncThunk<undefined, IDeleteCropPlanRequest, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/delete',
	async (context, request: IDeleteCropPlanRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { CropPlanId, ConfirmLastCropPlan } = request;
			const { auth, fieldplan } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);

			// Always undefined if successful because it returns a 204 no content
			const response = await api.deleteAsync(`cropplans/${CropPlanId}?confirmLastCropPlan=${ConfirmLastCropPlan}`);

			if (response && response.ErrorCode && !response.Success)
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}

			if (!ConfirmLastCropPlan)
			{
				await thunkAPI.dispatch(getFieldPlan({ planId: fieldplan.currentFieldPlan.Id }));
			}

			return undefined;
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IAssignProductsToCropPlan
{
	CropPlanId: string;
	Products: ICropPlanSelectedProduct[];
}

export const assignProductsToCropPlan = createTracedAsyncThunk<ICropPlanFullResponse, IAssignProductsToCropPlan, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/assignProducts',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.putAsync<ICropPlanFullResponse>(`cropplans/${request.CropPlanId}/products`, request.Products);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export interface IRunCropPlanRequest
{
	CropPlanId: string;
	WaitForPoll?: boolean;
}

export const runCropPlan = createTracedAsyncThunk<ICropPlanFullResponse, IRunCropPlanRequest, { dispatch: AppDispatch, state: RootState }>(
	'fieldplan/cropplan/run',
	(async (currentContext, request: IRunCropPlanRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, currentContext);
			const response = await api.putAsync<ICropPlanFullResponse>(`cropplans/${request.CropPlanId}/status`, 'Processing');

			if (response.ErrorCode === null && response.Success) 
			{
				if (request.WaitForPoll)
				{
					// If we're running a plan because someone changed some product selections and we're still IN the plan...wait
					await thunkAPI.dispatch(pollGetFieldPlan());
				}
				else
				{
					// We're not in a current plan so don't bother waiting
					thunkAPI.dispatch(pollGetFieldPlan());
				}
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	})
);