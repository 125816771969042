import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

type PortalPopUpProps = {
  children: React.ReactNode;
  className?: string;
  reference: HTMLDivElement | undefined;
  style?: React.CSSProperties;
};

export const PortalPopUp = (props: PortalPopUpProps) =>
{
	// When we are about to render, we remove any existing content.  Unlike render,
	// createPortal will keep the existing content.
	useEffect(()=>
	{
		if(props.reference)
		{
			const placeholders = props.reference.getElementsByClassName('placeholder');
			for(const ph of placeholders as any)
			{
				props.reference.removeChild(ph);
			}
		}
	},[props.reference]);

	if (!props.reference)
	{
		return <></>;
	}

	return ReactDOM.createPortal(
		<div className={props.className} style={{ ...props.style }}>
			{props.children}
		</div>, props.reference);
};
