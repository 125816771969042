export const theme = {
	fonts: {
		base: 'Roboto',
		heading: 'Roboto Condensed',
	},
	fontSizes: {
		tiny: '10px',
		extraSmall: '12px',
		small: '14px',
		normal: '16px',
		large: '18px',
		xLarge: '24px'
	},
	fontWeights: {
		normal: 400,
		bold: 700,
	},
	lineHeights: {
		base: 1.5,
		heading: 1.1,
	},
	colors: {
		/*
		** Primary and Secondary theme colors -- When changing themes these are the colors that will likely change between
		** different themes
		*/
		primary: '#C45513',
		secondary: '#ED965D',
		secondaryRGBA35: 'rgba(237,150,93,.35)',

		enogenGreen70: '#0C612C',
		enogenGreen40: '#5EBB7F',
		enogenGreen40RGBA35: 'rgba(94,187,127,.35)',
		darkGrey: '#121212',
		backgroundLM: '#F9F9F9',
		mediumGrey: '#717171',
		neutralGrey: '#696F88',
		lightGrey: '#A0A0A0',
		lightestGrey: '#E7E7E7',
		ghxNeutral60: '#9EA2A2',
		ghxOrangeLM: '#C45513',
		ghmOrangeDM: '#ED965D',
		brightOrange: '#FF785A',		
		transparentWhite: 'rgba(255, 255, 255, 0.08)',
		transparentBlack: 'rgba(26, 25, 25, 0.7)',
		transparentGrey: 'rgba(60, 60, 60, 0.4)',
		purple: '#462779',
		purpleLM: '#8354D6',
		greenLM: '#14803C',
		blue: '#003B69', // Also used for Golden Advantage Approved Pending Repayment
		blueLM: '#0071CD',
		soyBlue: '#005693',
		ghLightBlue: '#49A2D9',
		pinkLM: '#BB485D',
		redLM: '#A90608',
		redDM: '#CF6679',
		red70: '#9C2628',
		disabledGrey: '#EEEEEE',
		white: '#FFFFFF',
		availabilityGreen: '#40B777',
		availabilityYellow: '#E99921',
		availabilityRed: '#EB4B4B',		
		orderGreen: '#8AC540',
		yellowGA: '#C17E19', // Golden Advantage Pending
		redGA: '#CF3537', // Golden Advantage Rejected
		lightBlueGA: '#0092E4', // Golden Advantage Approved
	},
	borders: {
		none: 'none',
		thin: '2px solid',
		normal: '3px solid'
	},
	radii: {
		base: '4px',
	},
	space: [
		0,
		'0.125rem', // 2px
		'0.25rem', // 4px
		'0.5rem', // 8px
		'1rem', // 16px
		'2rem', // 32px
		'4rem', // 64px
		'8rem', // 128px
		'16rem', // 256px
		'32rem', // 512px
	],
};