import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Container = styled.div`
	margin-bottom: 20px;
	width: 100%;
`;

const GreyBackgroundBar = styled.div<{ clickable?: boolean, showOuterBorder: boolean, thin: boolean, outerBorderColor: string }>`
	height: ${props => props.thin ? 'auto' : '40px'};
	width: 100%;
	background-color: ${themeGet('colors.lightestGrey')};
	border: ${props => props.showOuterBorder ? props.outerBorderColor ? `1px solid ${props.outerBorderColor}` : `1px solid ${props.theme.colors.darkGrey}` : 'none' };
	border-radius: 5px;
	cursor: ${props => props.clickable ? 'pointer' : 'default'};
`;

const ColorBar = styled.div<{ color: string }>`
	height: 100%;
	border: none;
	background-color: ${props => props.color};
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
`;

const DetailSection = styled.div`
	width: 100%;
	border: thin solid ${themeGet('colors.lightGrey')};
	border-radius: 5px;
	margin: 10px 0px;
	padding: 15px 10px;
`;

const DetailRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 2px 0px;
`;

interface IColorMatchBarProps extends React.HTMLAttributes<HTMLDivElement>
{
	clickable?: boolean; // whether or not the bar has a cursor as a pointer (true) or not (false)
	color: string;
	containerClassName?: string;
	containerMargin?: string;
	detailNamesAndValues?: [string, string][];
	fontColor?: string;
	fontWeight?: number;
	height?: number;
	maxValue: number;
	outerBorderColor?: string;
	showDetails: boolean;
	showOuterBorder?: boolean;
	showValue?: boolean;
	title: string;
	value: number;
	variant?: 'thin';
}

/** @component */
export const ColorMatchBar = (props: IColorMatchBarProps) =>
{
	const {
		clickable,
		color,
		containerClassName,
		containerMargin,
		detailNamesAndValues,
		fontColor,
		fontWeight,
		height,
		maxValue,
		outerBorderColor,
		showDetails,
		showOuterBorder,
		showValue,
		title,
		value,
		variant,
		onClick,
	} = props;

	const percent = value/maxValue *100;

	return (
		<Container style={{ marginBottom: containerMargin ? containerMargin : '20px' }} className={containerClassName}>
			<GreyBackgroundBar
				clickable={clickable}
				showOuterBorder={showOuterBorder}
				outerBorderColor={outerBorderColor}
				className={`ColorMatchBar_${title}_Button`}
				onClick={onClick}
				thin={variant && variant === 'thin' ? true : false}
			>
				<ColorBar
					color={color}
					style={{
						width: '100%',
						fontWeight: fontWeight ? fontWeight : 'normal',
						color: fontColor ? fontColor : 'white',
						borderTopRightRadius: 5,
						borderBottomRightRadius: 5,
						backgroundImage: `linear-gradient(to right, ${color}CD ${percent}%, white ${percent}% 100%)`,
					}}>
					<span
						style={{
							backgroundColor: color,
							paddingLeft: 5,
							height: height,
							paddingRight: 5,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
						className='Title'
					>
						{title}
					</span>
					{showValue && <span className='Value'>{value === maxValue ? '' : value}</span>}
				</ColorBar>
			</GreyBackgroundBar>
			{showDetails && detailNamesAndValues?.length > 0 ?
				<DetailSection>
					{detailNamesAndValues.map((nameAndValue, index) => 
						<DetailRow key={`DetailRow_${nameAndValue[0]}_${index + 1}}`}>
							<span className={`Name_${index + 1}`}>{nameAndValue[0]}</span>
							<span className={`Value_${index + 1}`} style={{ fontWeight: 'bold' }}>{nameAndValue[1]}</span>
						</DetailRow>
					)}
				</DetailSection> : <></>
			}
		</Container>
	);
};