'use strict';
import { useMemo } from 'react';
import { SelectionState } from './DataTransferTroubleshootingTool';

/**
 * Can the user use the individual grower transfer functionality, or why not.
 * 
 * Returns a message for any blocking issue as well as a potential warning that should not block the function.
 * @param transferAll Is transferAll active
 * @param source The source grower state to copy from
 * @param dest The destination grower state to copy to
 * @returns 
 */
export function useIndividualTransferValidation(transferAll: boolean, source: SelectionState, dest: SelectionState) 
{
	const blockingTransfer = useMemo(() => 
	{
		if (transferAll) 
		{
			return undefined;
		}

		if (!source.user) 
		{
			return 'Select a source user';
		}

		if (!source.selectedGrower) 
		{
			return 'Select a source grower';
		}

		if (!dest.user) 
		{
			return 'Select a destination user';
		}

		if (!dest.selectedGrower) 
		{
			return 'Select a destination grower';
		}

		if (!source.selectedGrower.Farms.length) 
		{
			return 'No farms to transfer on the source grower';
		}

		const dupe = source.selectedGrower.Farms.find(sfm => dest.selectedGrower.Farms?.some(dfm => sfm.Name === dfm.Name));
		if (dupe) 
		{
			return `Farm '${dupe.Name}' exists in the destination already.`;
		}

		return undefined;
	}, [transferAll, source.user, source.selectedGrower, dest.user, dest.selectedGrower]);

	const warningTransfer = useMemo(() => 
	{
		if (blockingTransfer) 
		{
			return undefined;
		}

		if (transferAll) 
		{
			return undefined;
		}

		if (source.selectedGrower.FoundationId != dest.selectedGrower.FoundationId) 
		{
			return `Source grower ${source.selectedGrower.Name} (${source.selectedGrower.FoundationId}) has a different Foundation ID from destination grower ${dest.selectedGrower.Name} (${dest.selectedGrower.FoundationId})`;
		}
	}, [transferAll, blockingTransfer, source.selectedGrower, dest.selectedGrower]);
	return { blockingTransfer, warningTransfer };
}
