import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IOrderRequest } from '../../Models/Requests/OrderRequest';
import { IOrderResponse } from '../../Models/Responses/OrderResponse';
import { IOrderPlanResponse } from '../../Models/Responses/OrderPlanResponse';
import { getPlans } from './PlanThunks';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { getFarms } from './FarmThunks';

export const getGrowerOrdersForYear = createTracedAsyncThunk<IOrderResponse[], IOrderRequest, { dispatch: AppDispatch, state: RootState }>(
	'cropedge/orders/get',
	async (context, request: IOrderRequest, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { ui, auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<IOrderResponse[]>(`growers/${ui.SelectedGrowerId}/years/${request.year}/orders`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getOrderPlanProductsAndAssignments = createTracedAsyncThunk<IOrderPlanResponse, { year: string,  blocking?: boolean }, { dispatch: AppDispatch, state: RootState }>(
	'orderplanproductsandassignments/get',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { ui, auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<IOrderPlanResponse>(
				`growers/${ui.SelectedGrowerId}/years/${request.year}/orderplan`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

/**
 * Attempt to import an order in case there was an error or missing data
 */
export const importOrderPlanData = createTracedAsyncThunk<object, { orderId: string, year: string }, { dispatch: AppDispatch, state: RootState }>(
	'orderplandataimport/get',
	async (context, request: { orderId: string, year: string }, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { ui, auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.postAsync<IOrderResponse>(`orders/${request.orderId}`, null);

			if (response.ErrorCode === null && response.Success) 
			{
				// Call the order plan endpoint to attempt to get the order plan again
				await thunkAPI.dispatch(getOrderPlanProductsAndAssignments({ year: request.year }));

				// Pull fields again
				// Re-pull the farm field tree
				await thunkAPI.dispatch(getFarms({ growerId: ui.SelectedGrowerId, successMessage: 'Successfully updated crops across fields.' }));

				// We don't really care what the return is right now, only that it was successful - 10/14/2022
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);
/**
 * Attempt to import an order that did not exist in Eluminate or was not created with Eluminate
 */
export const importGrowerOrderData = createTracedAsyncThunk<object, { year: string}, { dispatch: AppDispatch, state: RootState }>(
	'growerorderdataimport/get',
	async (context, request: { year: string}, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { ui, auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
		
			const response = await api.postAsync<string>(`growers/${ui.SelectedGrowerId}/years/${request.year}/orders`, null);

			if (response.ErrorCode === null && response.Success) 
			{
				// Call the plans endpoint to refresh the plans list
				thunkAPI.dispatch(getPlans({ cropYear: request.year }));
				// We don't really care what the return is right now, only that it was successful - 10/14/2022
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const clearGrowerOrderData = createTracedAsyncThunk<object, { year: string}, { dispatch: AppDispatch, state: RootState }>(
	'growerorderdataimport/delete',
	async (context, request: { year: string}, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { ui, auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
		
			const response = await api.deleteAsync<string>(`growers/${ui.SelectedGrowerId}/years/${request.year}/orders`, null);

			if (response.ErrorCode === null && response.Success) 
			{
				// Call the plans endpoint to refresh the plans list
				thunkAPI.dispatch(getPlans({ cropYear: request.year }));
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);