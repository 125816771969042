import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { UserPermission } from '../logic/Models/Responses/UserPermissions';
import { RootState } from '../logic/store/Store';
import { hasEffectivePermission } from '../logic/store/User/AuthSlice';
import { useNavigationEvent } from '../logic/Utility/useAmplitude';
import { EventStructureTags } from '../tracing/EventTagNames';
import { useScopedSession } from '../tracing/session';

export const Footer = (props: {}) =>
{
	const nowDate = new Date();
	const nowYear = nowDate.getUTCFullYear();
	
	const session = useScopedSession(Footer.name, {
		[EventStructureTags.PageContext]: 'footer',
		[EventStructureTags.PageUrl]: window.location.toString()
	});
	const trackEventCopyright = useNavigationEvent(session, 'copyright', 'http://www.syngenta-us.com/copyright.aspx');
	const trackEventUserAgreement = useNavigationEvent(session, 'user_agreement', 'http://www.syngenta-us.com/legal/useragreement.html');
	const trackEventPrivacyPolicy = useNavigationEvent(session, 'privacy_policy','http://www.syngenta-us.com/legal/privacypolicy.html');
	const trackCookiePolicy = useNavigationEvent(session, 'cookie_policy','http://www.syngenta-us.com/legal/cookiepolicy.html');

	const isGhx = useSelector<RootState>(state => hasEffectivePermission(state, UserPermission.CanSeePricing));	
	const theme = useTheme();

	return (
		<footer style={{font:'Roboto', fontWeight: 700, fontSize: 12}}>
			<section style={{
				backgroundColor: theme.colors.darkGrey,
				color:'white',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				height: 58,
				alignItems: 'center',
				paddingLeft: '5vw',
				paddingRight: '5vw'
			}}
			>
				<div style={{flex: 1}}>
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='http://www.syngenta-us.com/copyright.aspx'
						onClick={() => trackEventCopyright()}
						style={{color:'white'}}
					>Copyright {nowYear} Syngenta</a>
				</div>
				{
					isGhx && <div style={{flex: 1}}>
						Rooted in Genetics, Agronomy and Service
					</div>
				}
				<div style={{display: 'flex'}}>
					<div style={{paddingRight: 5}}>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='http://www.syngenta-us.com/legal/useragreement.html'
							onClick={() => trackEventUserAgreement()}
							style={{color: 'white'}}
						>User Agreement</a>
					</div>
					<div style={{paddingRight: 5}}>
						|
					</div>
					<div style={{paddingRight: 5}}>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='http://www.syngenta-us.com/legal/privacypolicy.html'
							onClick={() => trackEventPrivacyPolicy()}
							style={{color: 'white'}}
						>Privacy Policy</a>
					</div>
					<div style={{paddingRight: 5}}>
						|
					</div>
					<div>
						<a
							target='_blank'
							rel='noopener noreferrer'
							onClick={() => trackCookiePolicy()}
							href='http://www.syngenta-us.com/legal/cookiepolicy.html'
							style={{color: 'white'}}
						>Cookie Policy</a>
					</div>
				</div>				
			</section>
		</footer>
	);
};