import React, { useCallback } from 'react';
import L from 'leaflet';
import { MapCustomControl } from '../MapCustomControl';
import { IconButton } from '../../../components/IconButton/IconButton';
import { ReactComponent as Paint } from '../Icons/Paint.svg';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { IZoneData } from '../../../pages/FieldActivities/VRS/VRSMain';

const MapControlButton = styled.div`
	background-color: ${themeGet('colors.white')};
	height: 33px;
	width: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	border: 2px solid rgba(0,0,0,0.3);
	margin-left: auto;
	&:hover {
		background-color: ${themeGet('colors.primary')};
	}
`;

export const MapWidgetPanel = styled.div`
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	height: 33px;
	padding: 0;
	border: 1px solid transparent;
	color: #000000;
	background-color: #f2f4f6;
	border-color: #f0f3f7;
	margin-right: 5px;
	align-items: center;
	padding: 5px;
`;

interface IVRSDrawingTrayProps
{
	mapRef: React.MutableRefObject<L.Map | undefined>;
	onEnablePaint: () => void;
	onStartPainting: (zone: number) => void;
	paintingEnabled: boolean;
	display: boolean;
	zones: IZoneData[];
	selectedZone: number;
}

export const VRSPaintTray = (props: IVRSDrawingTrayProps) =>
{
	const { mapRef, display, onEnablePaint, onStartPainting, selectedZone, paintingEnabled, zones } = props;

	const theme = useTheme();

	const border = useCallback((zoneName: number) =>
	{
		const showOutline = selectedZone === zoneName;
		return showOutline ? `3px solid ${theme.colors.secondary}` : '2px solid black';
	},[selectedZone]);

	const addCustomControl = (control: React.ReactElement, position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined) =>
	{
		return (
			<MapCustomControl map={mapRef.current} position={position} show={display}>
				<div className='PaintingTray_ControlContainer' style={{ display: 'flex', flexDirection: 'row' }}>
					{
						paintingEnabled && !!zones &&
						<MapWidgetPanel className='PaintingTray_MapWidgetPanel'>
							{
								/* Iterate through existing zones */
								zones.map(zone =>
								{
									return (
										<IconButton
											key={zone.ZoneName}
											tooltip={`Zone ${zone.ZoneName}`}
											placement='bottom'
											onClick={() => onStartPainting(zone.ZoneName)}
											style={{
												background: zone.Color,
												width: 24,
												height: 24,
												borderRadius: 4,
												border: border(zone.ZoneName),
												margin: 0,
												padding: 0,
												marginLeft: 2,
												marginRight: 2,
											}}
										>
											{/* Use a display-none icon so the button will still display, but without an SVG */}
											<Paint height={22} width={22} style={{ display: 'none' }} />
										</IconButton>
									);
								})
							}
						</MapWidgetPanel>
					}
					<MapControlButton className='PaintingTray_MainControlButton'>
						{control}
					</MapControlButton>
				</div>
			</MapCustomControl>
		);
	};

	return (
		<div>
			{
				addCustomControl(
					<IconButton
						tooltip='Paint'
						placement={paintingEnabled ? 'top' : 'left'}
						onClick={() => onEnablePaint()}
					>
						<Paint height={22} width={22} style={{ position: 'relative' }} />
					</IconButton>,
					'topright'
				)
			}
		</div>
	);
};