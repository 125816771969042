import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Checkbox as AntdCheckbox } from 'antd';

/** @component */
export const Checkbox = styled(AntdCheckbox)<{ size?: string }>`
	.ant-checkbox .ant-checkbox-inner {
		background: transparent;
		outline: none;
		border: 1px solid ${themeGet('colors.lightGrey')};
		width: ${props => props.size? props.size : '16px'};
		height: ${props => props.size? props.size : '16px'}
	}
	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: ${themeGet('colors.primary')};
		border: 1px solid ${themeGet('colors.primary')};
	}
	.ant-checkbox-indeterminate .ant-checkbox-inner::after {
		background-color: ${themeGet('colors.primary')};
		border: 1px solid ${themeGet('colors.primary')};
	}
	/* Unchecked border hover */
	.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-focused .ant-checkbox-inner {
		border: 1px solid ${themeGet('colors.secondary')};
	}
	/* Background hover for checked checkbox */
	.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
	.ant-checkbox-checked:hover .ant-checkbox-inner,
	.ant-checkbox-checked-focused .ant-checkbox-inner {
		background-color: ${themeGet('colors.secondary')};
	}
	/* Border hover for checked checkbox */
	.ant-checkbox-wrapper:hover,
	.ant-checkbox-checked::after {
		border: 1px solid ${themeGet('colors.secondary')};
	}
	/* Background hover for indeterminate checkbox */
	.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
	.ant-checkbox-indeterminate:hover .ant-checkbox-inner::after {
		border: 1px solid ${themeGet('colors.secondary')};
		background-color: ${themeGet('colors.secondary')};
	}
`;