import { createSlice,  } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import LogRocket from 'logrocket';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IStartRecordingResponse
{
	cardId: string;
}

export const startLogrocketRecording = createTracedAsyncThunk<IStartRecordingResponse, string, { state: RootState }>(
	'recording/start',
	async (context, cardId: string, thunkAPI) => 
	{
		try
		{
			const { recording, userInfo } = thunkAPI.getState();

			if (!recording.isRecording)
			{
				// Start recording
				LogRocket.init('zqn1nh/e-luminate', {
					release: cardId
				});

				// Identify the user
				LogRocket.identify(userInfo.UserId, {
					email: userInfo.UserEmail,
					name: userInfo.UserName,
					foundationId: userInfo.FoundationId
				});

				LogRocket.track('session-info', {
					permissions: userInfo.Permissions.join(','),
					cardId: cardId
				});

				return {
					cardId
				};
			}
			else
			{
				return thunkAPI.rejectWithValue('Cannot start LogRocket recording, existing recording is in progress.');
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

interface IRecordingState
{
	isRecording: boolean;
	cardId: string;
}

const initialState: IRecordingState = {
	isRecording: false,
	cardId: undefined
};

export const recordingSlice = createSlice({
	name: 'recording',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;
			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(startLogrocketRecording.fulfilled, (state, { payload }) =>
		{
			state.isRecording = true;
			state.cardId = payload.cardId;
		});
	}
});

export const { clearState } = recordingSlice.actions;