import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import { IconButton } from '../../../components/IconButton/IconButton';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { InputNumber } from 'antd';
import { IDiscountReasonCode, IDiscountReasonCodeExtended } from '../../../logic/Models/Responses/DiscountReasonCodeResponse';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { IPlanCropDiscount } from '../../../logic/Models/Responses/CompetitiveAllowanceResponse';

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
`;

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: ${themeGet('fontSizes.normal')};
	margin-right: -2px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;
export interface ICropLevelDiscountRowProps
{
	reasonCodes: IDiscountReasonCodeExtended[]; // discount reason codes for the crop
	cropLevelDiscountItem: IPlanCropDiscount;
	cropId: string;
	index: number;
	isReadOnly: boolean;
	deleteRow: (cropId: string, index: number) => void;
	updateRow: (cropId: string, index: number, updatedDiscount: number, originalDiscount: number, reasonCode?: string) => void;
}

export const CropLevelDiscountRow = (props: ICropLevelDiscountRowProps) =>
{
	const { cropId, cropLevelDiscountItem, index, isReadOnly, reasonCodes, deleteRow, updateRow } = props;

	const theme = useTheme();

	const [reasonOptions, setReasonOptions] = useState<IOptionItem[]>([]);

	useEffect(() =>
	{
		if (reasonCodes)
		{
			const createReasonCodeOptions = reasonCodes.map(reasonItem =>
			{
				const brandOption: IOptionItem =
					{
						label: reasonItem.Code,
						value: reasonItem.Id,
						disabled: reasonItem.Selected
					};
		
				return brandOption;
			});
		
			setReasonOptions(createReasonCodeOptions);
		}
	},[reasonCodes]);

	const onDelete = () =>
	{
		deleteRow(cropId, index);
	};

	const onChangeReason = (value: string) =>
	{
		if (value === cropLevelDiscountItem.DiscountReasonCode)
		{
			// Don't update if there is no change
			return;
		}

		updateRow(cropId, index, cropLevelDiscountItem.PerAcreDiscount, cropLevelDiscountItem.PerAcreDiscount, value);

		// TODO: probably remove reason code from the existing list to restrict users to only be allowed as many
		// rows as there are reason codes
	};

	const onChangeDiscount = (value: number) =>
	{
		if (value === cropLevelDiscountItem.PerAcreDiscount)
		{
			// Don't update if there is no change
			return;
		}

		if (value < 0)
		{
			value = 0;
		}
		updateRow(cropId, index, value, cropLevelDiscountItem.PerAcreDiscount, cropLevelDiscountItem.DiscountReasonCode);
	};

	const showDiscountValueErorrBorder = (): boolean =>
	{
		if (cropLevelDiscountItem.PerAcreDiscount === 0 || cropLevelDiscountItem === undefined)
		{
			return true;
		}
		if (!cropLevelDiscountItem.DiscountReasonCode || cropLevelDiscountItem.DiscountReasonCode === '')
		{
			return true;
		}

		return false;
	};

	const showDiscountReasonErrorBorder = (): boolean =>
	{
		if (!cropLevelDiscountItem.DiscountReasonCode || cropLevelDiscountItem.DiscountReasonCode === '')
		{
			if (cropLevelDiscountItem.PerAcreDiscount === 0 || cropLevelDiscountItem === undefined)
			{
				return true;
			}
		}

		return false;
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: 5,
				marginBottom: 2,
				paddingRight: 15,
				paddingLeft: 25,
				paddingTop: 5,
				paddingBottom: 7,
				borderStyle: 'solid',
				borderRadius: 4,
				borderWidth: 1,
				borderColor: theme.colors.lightGrey,
				width: '100%'
			}}>
			<div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
				<div style={{ fontSize: theme.fontSizes.small, fontWeight: theme.fontWeights.bold }}>Type</div>
				<div style={{ fontSize: theme.fontSizes.small }}>Discount $/Acre</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
				<div style={{ marginRight: 5, fontSize: theme.fontSizes.small, fontWeight: theme.fontWeights.bold }}>
					Discount
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 5 }}>{ isReadOnly ? '' : '$'}</div>
					{
						isReadOnly ?
							<div>{cropLevelDiscountItem.PerAcreDiscount !== undefined ? 
								Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cropLevelDiscountItem.PerAcreDiscount)
								: '$0.00'}</div>
							:
							<NumberInput
								style={{
									width: 70,
									fontSize: theme.fontSizes.small,
									top: 1,
									borderColor: showDiscountValueErorrBorder() 
										? theme.colors.availabilityRed : theme.colors.lightestGrey,
								}}
								className='CropLevel_Discount'
								value={cropLevelDiscountItem.PerAcreDiscount !== undefined ? cropLevelDiscountItem.PerAcreDiscount : ''}
								step={0.05}
								min={0}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
									onChangeDiscount(evt.currentTarget.value ? Number(evt.currentTarget.value) : cropLevelDiscountItem.PerAcreDiscount)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => 
									onChangeDiscount(evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value) : cropLevelDiscountItem.PerAcreDiscount)}
							/>
					}
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
				<div style={{ marginRight: 5, fontSize: theme.fontSizes.small, fontWeight: theme.fontWeights.bold }}>
					Reason Code
				</div>
				{
					isReadOnly ?
						<div>{reasonCodes.find(c => c.Id === cropLevelDiscountItem.DiscountReasonCode)?.Code ?? 'None'}</div>
						:
						<Dropdown
							className='CropLevel_ReasonCode'
							variant='outlinednarrownopad'
							options={reasonOptions}
							onSelect={onChangeReason}
							selected={cropLevelDiscountItem.DiscountReasonCode}
							containerWidth={'55%'}
							titleFontSize={theme.fontSizes.extraSmall}
							relativePosition={true}
							placeholder={'Select a Reason'}
							showErrorBorder={showDiscountReasonErrorBorder()}
							errorBorderColor={theme.colors.availabilityRed}
						/>
				}
			</div>
			<div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
				{
					isReadOnly ? 
						<></>
						:
						<StyledIconButton className='CropLevel_DeleteButton' onClick={onDelete}
							tooltip='Delete'
							placement='right'
							fillcolor={theme.colors.darkGrey}
							hoverbgcolor={theme.colors.lightestGrey}
						>
							<DeleteIcon />
						</StyledIconButton>
				}
			</div>
		</div>
	);
};