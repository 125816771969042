import { Dropdown, MenuProps } from 'antd';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Button, IButtonProps } from '../Button/Button';

type MultiActionButtonProps = PropsWithChildren<{
	buttonProps?: Omit<IButtonProps, 'children'>;
	menuProps: MenuProps;
}>;

export const MultiActionButton = (props: MultiActionButtonProps) =>
{
	const [menuOpen, setMenuOpen] = useState(false);
	const onMenuClick = useCallback((info: Parameters<MenuProps['onClick']>[0]) =>
	{
		setMenuOpen(false);
		props.menuProps.onClick?.(info);
	}, [props.menuProps.onClick]);

	return (
		<Dropdown
			menu={{ ...props.menuProps, onClick: onMenuClick }}
			trigger={['click']}
			disabled={props.menuProps.disabled || props.buttonProps?.disabled}
			onOpenChange={setMenuOpen}
			open={menuOpen}
		>
			<Button {...props.buttonProps}>
				{props.children}
			</Button>
		</Dropdown>
	);
};
