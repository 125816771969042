import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';

/** @component */
export const StyledCollapse = styled(Collapse)`
	border: none;
    overflow-y: auto;
`;

export const StyledPanel = styled(Collapse.Panel)`
	background-color: rgb(231, 231, 231);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
	&.ant-collapse-item {
		border: none;
	}
	&.ant-collapse-item-active {
		border: none;
	}
	.ant-collapse-content {
		border: none
	}
`;