import React, { CSSProperties, forwardRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown as AntDropdown, DropDownProps, MenuProps, Tooltip } from 'antd';
import { ReactComponent as CaretDownIcon } from './Icons/CaretDownIcon.svg';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { IOptionItem } from './IOptionItem';

type DropdownVariant = 'transparent' | 'dark' | 'light' | 'grey' | 'outlined' | 'outlinednarrow' | 'outlinednarrownopad';
interface IDropdownProps extends React.HTMLAttributes<DropDownProps>
{
	// an array of either objects or strings 
	options: (string | IOptionItem)[];
	disabled?: boolean;
	placeholder?: string | undefined;
	selected?: string | undefined;
	onSelect: (item: any) => void;
	variant?: DropdownVariant;
	titleFontSize?: string;
	className?: string;
	containerWidth?: string;
	relativePosition?: boolean; // whether or not to make the dropdown relative to the container
	showErrorBorder?: boolean;
	errorBorderColor?: string;
	menuStyle?: React.CSSProperties;
}


const getSelectColor = (variant: DropdownVariant) => ({
	grey: 'darkGrey',
	transparent: 'darkGrey',
	dark: 'white',
	light: 'darkGrey',
	outlined: 'darkGrey',
	outlinednarrow: 'darkGrey',
	outlinednarrownopad: 'darkGrey'
}[variant]);
const getSelectBgColor = (variant: DropdownVariant) => ({
	grey: 'backgroundLM',
	transparent: 'transparent',
	dark: 'darkGrey',
	light: 'white',
	outlined: 'white',
	outlinednarrow: 'white',
	outlinednarrownopad: 'white'
}[variant]);
const getSelectBorderColor = (variant: DropdownVariant) => (
	{ grey: 'lightestGrey', outlined: 'lightestGrey', outlinednarrow: 'lightestGrey', outlinednarrownopad: 'lightestGrey' })[variant];
const StyledDropdown = styled(AntDropdown) <{ variant: DropdownVariant, titleFontSize?: string; disabled?: boolean }>`
	border-radius: 4px;
	padding: 10px 16px;
	padding-left: ${props => props.variant === 'outlinednarrow' || props.variant === 'outlinednarrownopad' ? '5px' : '16px'};
	border-color: ${props => props.theme.colors[getSelectBorderColor(props.variant)]};
	border-width: ${props => props.variant === 'outlined' || props.variant === 'outlinednarrow' || props.variant === 'outlinednarrownopad' ? '1px' : 'none'};
	border-style: ${props => props.variant === 'outlined' || props.variant === 'outlinednarrow' || props.variant === 'outlinednarrownopad' ? 'solid' : 'none'};
	color: ${props => props.theme.colors[getSelectColor(props.variant)]};
	background-color: ${props => props.theme.colors[getSelectBgColor(props.variant)] || 'transparent'};
	cursor: ${props => !props.disabled ? 'pointer' : 'not-allowed'};
	opacity: ${props => !props.disabled ? 1 : 0.6};
	position: relative;
	height: ${props => props.variant === 'outlinednarrow' || props.variant === 'outlinednarrownopad'  ? '30px' : 'auto'};

	.DropDownTitle {
		margin-right: 12px;
		padding-right: 10px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-size: ${props => props.titleFontSize}
	}

	.DropDownIcon {
		position: absolute;
		right: 10px;
		height: 100%;
		top: 0;
		align-items: center;
		justify-content: center;
		display: flex;
	}
`;

const StyledIcon = styled(CaretDownIcon) <{ variant: DropdownVariant }>`
	path {
		fill: ${props => props.theme.colors[getSelectColor(props.variant)]};
	}
`;

const getValue = (option: string | IOptionItem) =>
{
	if (typeof option === 'string')
	{
		return option;
	}
	return option?.value;
};

const getLabel = (option: string | IOptionItem) =>
{
	if (typeof option === 'string')
	{
		return option;
	}
	return option?.label;
};

const lookupItem = (selected: String, items: (string | IOptionItem)[]) =>
{
	return items.find(item => (typeof item === 'string' && item === selected) || (typeof item === 'object' && item.value === selected));
};

/**
 * Dropdown component for grower page
 */
/** @component */
export const Dropdown = (props: IDropdownProps) =>
{
	const {
		disabled,
		onSelect,
		options,
		placeholder,
		selected,
		variant = 'transparent',
		titleFontSize,
		className,
		containerWidth,
		relativePosition: position,
		showErrorBorder,
		errorBorderColor,
		menuStyle,
	} = props;
	const [menuItems, setMenuItems] = useState<ItemType[]>([]);
	const firstValue = options.length ? options[0] : undefined;
	const selectedItem = selected || !placeholder && getValue(firstValue);

	const selectItem = useCallback((antdMenuItem) => 
	{
		const value = antdMenuItem.key as string;
		onSelect(value);
	}, [onSelect]);

	useEffect(() =>
	{
		const dropdownMenuItems: MenuProps['items'] = options.map((option) =>
		{
			const optionValue = getValue(option);
			const optionLabel = getLabel(option);
			const className = selectedItem === optionValue ? 'selected' : '';

			return (
				{
					key: optionValue,
					label: <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{typeof option === 'object' && option.icon}<div style={{ fontWeight: className === 'selected' ? 'bold' : 'inherit' }}>{optionLabel}</div></div>,
					className: className,
					disabled: typeof option !== 'string' && option.disabled
				}
			);
		});
		setMenuItems(dropdownMenuItems);
	},[options, selectedItem, className]);

	const selectedLabel = getLabel(lookupItem(selectedItem, options));

	const StyledDropdownWithRef = forwardRef((props, ref) =>
		<div
			ref={ref as any}
			style={{
				width: containerWidth ? containerWidth : '100%',
				paddingLeft: variant === 'outlinednarrownopad' ? 0 : variant === 'outlinednarrow' ? 10 : 0,
				position: position ? 'relative' : 'inherit',
				borderWidth: showErrorBorder ? 1 : 0,
				borderColor: showErrorBorder ? errorBorderColor : 'white',
				borderStyle: showErrorBorder ? 'solid' : 'none'
			}}>
			<StyledDropdown
				className={className}
				variant={variant}
				titleFontSize={titleFontSize}
				autoAdjustOverflow
				menu={{
					items: menuItems,
					className: className ? `Dropdown_StyledMenu_${className}` : 'Dropdown_StyledMenu',
					onClick: selectItem,
					style: menuStyle ? menuStyle : { flexGrow: 1 }
				}}
				trigger={['click']}
				disabled={disabled}
				getPopupContainer={(triggerNode: HTMLElement) =>
					triggerNode?.parentNode as HTMLElement || document.body
				}
			>
				<div>
					<div 
						className='DropDownTitle'
						style={{ 
							position:  variant === 'outlinednarrow' || variant === 'outlinednarrownopad' ? 'relative' : 'inherit',
							top:  variant === 'outlinednarrow' || variant === 'outlinednarrownopad' ? -5 : 0
						}}
					>
						{selectedLabel || placeholder}</div>
					<div className='DropDownIcon'>
						<StyledIcon variant={variant} />
					</div>
				</div>
			</StyledDropdown>
		</div>
	);
	StyledDropdownWithRef.displayName = 'DropdownContent';
	return (
		<Tooltip overlay={selectedLabel}>
			<StyledDropdownWithRef />
		</Tooltip>
	);
};