import { uniqBy } from 'lodash';
import { IProductIdentifier, IHybridIdentifier, ISeriesIdentifier, AvailabilityPreference, ITraitIdentifier } from './IProductReference';


export function aggregateHybridToSeries(availableHybridsForCropBrand: IHybridIdentifier[]) 
{
	return uniqBy(availableHybridsForCropBrand, h => h.SeriesId)
		.map<ISeriesIdentifier>(h => ({
			CropId: h.CropId,
			SeriesId: h.SeriesId,
			SeriesName: h.SeriesName,
			RelativeMaturity: h.RelativeMaturity,
			Hybrids: availableHybridsForCropBrand.filter(ah => ah.SeriesId === h.SeriesId),
			Availability: AvailabilityPreference[availableHybridsForCropBrand.filter(ah => ah.SeriesId === h.SeriesId).reduce((bestAvailability, ah) => Math.max(bestAvailability, AvailabilityPreference.indexOf(ah.Availability)), 0)]
		}));
}

export function aggregateHybridToTrait(availableHybridsForCropBrand: IHybridIdentifier[]) 
{
	return uniqBy(availableHybridsForCropBrand, h => h.TraitId)
		.map<ITraitIdentifier>(h => ({
			TraitId: h.TraitId,
			TraitName: h.TraitName,
			TraitFullName: h.TraitFullName
		}));
}

export function aggregateProductToHybrid(availableProductsForCropBrand: IProductIdentifier[]) 
{
	return uniqBy(availableProductsForCropBrand, p => p.HybridId)
		.map<IHybridIdentifier>(p => 
		{	
			const products = availableProductsForCropBrand.filter(ap => ap.HybridId === p.HybridId);
			return ({
				CropId: p.CropId,
				HybridId: p.HybridId,
				HybridName: p.HybridName,
				TraitId: p.TraitId,
				TraitName: p.TraitName,
				TraitFullName: p.TraitFullName,
				SeriesId: p.SeriesId,
				SeriesName: p.SeriesName,
				RelativeMaturity: p.RelativeMaturity,
				Products: products,
				Availability: AvailabilityPreference[products.reduce((bestAvailability, ah) => Math.max(bestAvailability, AvailabilityPreference.indexOf(ah.Availability)), 0)]
			});
		});
}
