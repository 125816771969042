import styled from 'styled-components';
import { Layout } from 'antd';
import { themeGet } from '@styled-system/theme-get';
const { Sider } = Layout;

export const StyledSideBar = styled(Sider)`
	background-color: ${themeGet('colors.darkGrey')};
	position: fixed;
	z-index: 999;
	height: calc(100% - 58px); // Calculate the height of the header and subtract from 100% height
	.ant-layout-sider-trigger {
		bottom: auto;
		background-color: ${themeGet('colors.transparentWhite')};
	}
	.ant-layout-sider-children {
		padding-top: 60px;
	}
	.ant-menu {
		background-color: ${themeGet('colors.darkGrey')};
		color: ${themeGet('colors.lightestGrey')};
	}
	.ant-menu-item:active {
		background-color: ${themeGet('colors.darkGrey')};
	}
	.ant-menu-item:hover {
		color: white;
		background-color: ${themeGet('colors.transparentWhite')};
	}
	.ant-menu-item .ant-menu-item-icon {
		min-width: 25px;
	}
	.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
		border: none;
	}
	.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
		width: 100%;
	}
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: ${themeGet('colors.darkGrey')};
		color: ${themeGet('colors.secondary')};
		border-left: 3px solid;
	}
	.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
		color: ${themeGet('colors.secondary')};
	}
	.ant-menu-item a {
		color: ${themeGet('colors.lightestGrey')};
	}
	.ant-menu-inline .ant-menu-item {
		padding: 8px 14px !important;
	}
	.ant-menu-inline-collapsed > .ant-menu-item {
		padding: 11px 21px !important;
	}
	.ant-menu-inline .ant-menu-item::after {
		border: none;
	}
	.Drawermenu > .ant-menu-item {
		display: flex;
		align-items: center;
		height: 50px;
	}
	.Drawermenu .ant-menu-item-selected > svg > path {
		fill: ${themeGet('colors.secondary')};
	}
	.Drawermenu .ant-menu-item > span {
		font-weight: 700;
	}
`;