import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import ReactJson from 'react-json-view';
import { connect, ConnectedProps } from 'react-redux';
import { Api } from '../../logic/Api/Api';
import { RootState } from '../../logic/store/Store';
import { Console } from 'node:console';

/**
 * Present tools to inspect orders that have been sent
 */
const GamePlansTroubleshootingTool = (props: PropsFromRedux) => 
{
	const [enteredGrowerIdSearchTerm, setEnteredGrowerIdSearchTerm] = useState<string>('');
	const [enteredYearSearchTerm, setEnteredYearSearchTerm] = useState<number>();
	const [growerIdSearchTerm, setGrowerIdSearchTerm] = useState<string>('');
	const [yearSearchTerm, setYearSearchTerm] = useState<number>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [orderData, setOrderData] = useState<object>({});
	const [AvoidFetchOnFirstRender, setAvoidFetchOnFirstRender] = useState<boolean>(true);
	
	// When a new search term is committed, fetch the order from the server
	useEffect(()=>
	{
		const execute = async () => 
		{
			const api = new Api('/api/4', props.userAuthToken, undefined);
			try
			{
				const response = await api.getAsync<object>(`/users/${props.userId}/growers/${encodeURIComponent(growerIdSearchTerm)}/years/${yearSearchTerm}/plans`);	
				setOrderData(response);
			}
			catch(err)
			{
				setOrderData(err);
			}
			setIsLoading(false);
		};
		
		if (AvoidFetchOnFirstRender)
		{
			setAvoidFetchOnFirstRender(false);
		}
		else
		{
			setIsLoading(true);
			execute();
		}
	},
	[growerIdSearchTerm, yearSearchTerm, props.userAuthToken]);

	function searchEvent() : void
	{
		setGrowerIdSearchTerm(enteredGrowerIdSearchTerm);
		setYearSearchTerm(enteredYearSearchTerm);
	}

	return (
		<Form style={{display:'flex', flexDirection:'column', alignItems:'stretch', height: '100%', position: 'relative'}}>
			<h1>Plans</h1>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
				<Form.Item label="Grower's ID" style={{width: 400, marginRight: 12}}>
					<Input disabled={isLoading} value={enteredGrowerIdSearchTerm} onChange={(e) => 
						setEnteredGrowerIdSearchTerm(e.currentTarget.value)} placeholder='GrowerId' />
				</Form.Item>
				<Form.Item label="Year">
					<InputNumber disabled={isLoading} placeholder='1234' value={enteredYearSearchTerm} onChange={(e) => 
						setEnteredYearSearchTerm(e)} maxLength={4} />
				</Form.Item>
				<Button style={{marginLeft: 12}} onClick={searchEvent}>Search</Button>
			</div>
			<ReactJson style={{flexGrow: 1, flexShrink: 1, overflow: 'auto', padding: 4, border: '1px #cccccc solid'}} src={orderData} />
		</Form>
	);
};

const mapStateToProps = (state: RootState) => ({
	userAuthToken: state.auth.userAuthToken,
	userId: state.userInfo.UserId,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const GamePlansTroubleshootingToolComponent = connector(GamePlansTroubleshootingTool);
