import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as TrashCan } from '../../../../assets/images/Trashcan.svg';
import { ReactComponent as Alert } from '../../Icons/AlertIcon.svg';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { ClipLoader } from 'react-spinners';
import { Tooltip } from 'antd';

/**
 * Card displayed on a Crop Plan overview; has the ability to delete specific crop plans or show a spinner if their recommendation is in progress.
 */

const ButtonContainer = styled.div`
	width: 100%;
	height: 78px;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	padding: 0px;
	margin-top: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

	.ActionIcon_Container {
		display: flex;
		justify-content: space-between;
		min-width: 42;
		flex-direction: column;
		padding: 12px 0 12px 0;

		>*:only-child {
			margin-top: auto;
		}
	}

`;

const MainCardContainer = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	width: 90%;

	:hover, :focus {
		background-color: ${themeGet('colors.lightestGrey')};
	}
`;

const ColorTab = styled.div<{ color: string }>`
	min-width: 15px;
	background-color: ${props => props.color};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;
`;

const TitleText = styled.div`
	width: 90%;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: flex-end;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: -5px;
`;

const DisplayTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	font-size: 11px;
	height: 50%;
	align-items: flex-start;
	padding-top: 3px;
`;

const DisplayNameText = styled.div`
	margin-right: 3px;
	font-size: 12px;
	font-weight: normal;
`;

const DisplayValueText = styled.div`
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 12px;
`;

interface IColorTabButtonProps {
	className?: string;
	disabled?: boolean;
	displayNamesAndValues: [string, string][];
	status: 'Processing' | 'Pending' | 'Success' | 'Error';
	message: string | undefined;
	icon?: React.ReactNode;
	selected?: boolean;
	selectedOutlineColor?: string;
	tabColor: string;
	title: string;
	onClick: () => void;
	onDelete: () => void;
	onRerun: () => void;
}

export const CropPlanOverviewCard = (props: IColorTabButtonProps) =>
{
	const {
		className,
		displayNamesAndValues,
		status,
		icon,
		selected,
		selectedOutlineColor,
		tabColor,
		title,
		message,
		onClick,
		onDelete,
		onRerun
	} = props;

	const theme = useTheme();

	const selectedButtonOutline = (selected: boolean) => { return selected ? `6px solid ${selectedOutlineColor}` : 'none'; };
	const disabled = status !== 'Success';

	return (
		<ButtonContainer
			style={{ height: 100, outline: selectedButtonOutline(selected) }}
			className={className ? className : `ColorTabButton_${title}_${tabColor}`}
		>
			<div style={{ display: 'flex', height: '100%', paddingRight: 10 }}>
				<Tooltip title={disabled && 'Not available at this time'}>
					<MainCardContainer className={`MainCard_Container ${disabled && 'disabled'}`} onClick={onClick}>
						<ColorTab style={{ minWidth: 15 }} className='ColorTab' color={ tabColor ?? '#ffffff' } />
						<TextContainer>
							<TitleText style={{ fontSize: 16, fontFamily: theme.fonts.base }} className='Title'>
								{
									icon && icon
								}
								<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{title}</div>
							</TitleText>
							<DisplayTextContainer className='DisplayTextContainer'>
								{displayNamesAndValues.map((nameValueTuple, index) => 
									<div key={`DisplayName_${index + 1}`} style={{ display: 'flex', flexDirection: 'row', width: '50%', alignItems: 'center' }}>
										<DisplayNameText
											style={{  }}
											className={`DisplayNameTitle_${index + 1}`}
										>
											{nameValueTuple[0]}:
										</DisplayNameText>
										<DisplayValueText
											style={{  }}
											className={`DisplayValue_${index + 1}`}
										>
											{nameValueTuple[1]}
										</DisplayValueText>
									</div>
								)}
							</DisplayTextContainer>
						</TextContainer>
					</MainCardContainer>
				</Tooltip>
				<div className={'ActionIcon_Container'}>
					{ 
						(status === 'Pending' || status === 'Processing') ?
							<Tooltip title={message}>
								<div> 
									<ClipLoader size={34} color={theme.colors.primary} loading={true} /> 
								</div>
							</Tooltip>
							:
							(status === 'Error') ?
								<Tooltip title={<div>Error running your plan: {message}<br/>Click to retry.</div>}>
									<IconButton 
										placement='right'
										className={'CropPlan_Delete'}
										onClick={onRerun}
										fillcolor={theme.colors.availabilityRed}
										hoverbgcolor={theme.colors.lightestGrey}
									>
										<Alert
											height={20}
											width={20}
										/>
									</IconButton>
								</Tooltip>
								:
								<></>
					}
					<IconButton
						tooltip='Delete'
						placement='right'
						className={'CropPlan_Delete'}
						onClick={onDelete}
						fillcolor={theme.colors.neutralGrey}
						hoverbgcolor={theme.colors.lightestGrey}
					>
						<TrashCan
							height={20}
							width={20}
						/>
					</IconButton>
				</div>
			</div>
		</ButtonContainer>
	);
};