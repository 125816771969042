import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { IMaxscript } from './IMaxscript';


interface IMaxscriptRequest {
	sellerId : string;
	growerId : string;
	year : string;
}

export const getMaxscript = createTracedAsyncThunk<IMaxscript, IMaxscriptRequest, { dispatch: AppDispatch, state: RootState }>(
	'maxscript/get',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth } = state;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<IMaxscript>(`users/${request.sellerId}/growers/${request.growerId}/years/${request.year}/maxscript?updateFromCropedge=true`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);