import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

type Variants = 'outlined' | 'dark' | 'light';
interface IInputProps extends React.HTMLAttributes<HTMLInputElement>
{
	label?: ReactNode;
	labelVariant?: Variants;
	variant?: Variants;
}

const getColor = (variant: string) => ({ outlined: 'darkGrey', dark: 'white', light: 'darkGrey' }[variant]);
const getBgColor = (variant: string) => ({ outlined: 'white', dark: 'darkGrey', light: 'white' }[variant]);
const getHoverBgColor = (variant: string) => ({ outlined: 'lightestGrey', dark: 'darkGrey', light: 'lightestGrey' })[variant];
const getHoverColor = (variant: string) => ({ dark: 'lightestGrey' })[variant];
const getDisabledBgColor = (variant: string) => ({ outlined: 'white', dark: 'lightestGrey', light: 'lightestGrey' })[variant];

const StyledInputContainer = styled.div<IInputProps>`
	display: flex;
	color: ${props => props.theme.colors[getBgColor(props.labelVariant)]};
	flex-direction: column;
	padding-top: ${props => props.label ? '15px' : ''};
`;
const StyledInput = styled.input<IInputProps>`	
	color: ${props => props.theme.colors[getColor(props.variant)]};
	background-color: ${props => props.theme.colors[getBgColor(props.variant)] || 'transparent'};
	border-color:  ${props => props.theme.colors[getColor(props.variant)]};
	border-radius: 4px;
	padding-left: 5px;
	line-height: 2.5em;
	font-size: 16px;
	&:focus, 
	&:active {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		color: ${props => props.theme.colors[getHoverColor(props.variant)]};
		background-color: ${props => props.theme.colors[getHoverBgColor(props.variant)]};
		cursor: pointer;
	}
	&:disabled {
		background-color: ${props => props.theme.colors[getDisabledBgColor(props.variant)]};
		border-color: ${themeGet('colors.lightestGrey')};
		color: ${themeGet('colors.lightGrey')};
	}
	// Autocomplete styles
	&:-webkit-autofill, 
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		appearance: none;
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: ${themeGet('colors.lightGrey')};
	}
	&:-webkit-autofill:hover {
		-webkit-text-fill-color: ${props => props.theme.colors[getHoverColor(props.variant)]};
	}

	// Font styles for autocomplete
    &:-webkit-autofill::first-line {
      font-family: inherit;
      font-size: 16px;
    }
	&[type=number]::-webkit-inner-spin-button,
	&[type=number]::-webkit-outer-spin-button {
		margin: 0;
		-webkit-appearance: none;
	}
`;

/**
 * Input component
 */

/** @component */
export const Input = (props: IInputProps & any) =>
{
	const { label, labelVariant = 'light', variant = 'dark', ...rest } = props;

	return (
		<StyledInputContainer labelVariant={labelVariant}>
			{label}
			<StyledInput variant={variant} {...rest} />
		</StyledInputContainer>
	);
};