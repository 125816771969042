
				var addMethods = require("../../.yarn/$$virtual/workerize-loader-virtual-4544124e2d/0/cache/workerize-loader-npm-2.0.2-6ea88ec3ea-e0e842271a.zip/node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["setAccessToken","setLabels","log","flush"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "[fullhash].worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "[fullhash].worker.js");
					addMethods(w, methods)
					
					return w
				}
			