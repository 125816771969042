import { useCallback, useState } from 'react';

/**
 * Basic hook for load incrementing / decrementing.  
 */
export const useLoadingCounter = () => 
{
	const [isLoading, setIsLoading] = useState(0);
	const startLoading = useCallback(() => setIsLoading(prev => prev + 1), []);
	const endLoading = useCallback(() => setIsLoading(prev => prev - 1), []);
	return { isLoading: isLoading > 0, startLoading, endLoading };
};
