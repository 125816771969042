import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { createFieldPlanWithCropPlan, updateCropPlan, assignProductsToCropPlan, runCropPlan } from '../../../logic/store/Plans/FieldPlan/FieldPlanThunks';
import styled, { useTheme } from 'styled-components';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { ConnectedSeedList } from './SeedList/SeedList';
import { IHybridResponse, ISeedResponse } from '../../../logic/Models/Responses/SeedResponse';
import { ISeriesWithHybrid } from '../../../logic/store/Seeds/SeedsActions';
import { SeedListBrandApplication } from '../../../logic/Models/Responses/ProductGamePlan';
import _, { uniq } from 'lodash';
import { IncrementNumberInput } from '../../../components/IncrementNumberInput/IncrementNumberInput';
import { Button } from '../../../components/Button/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { cropBrandAssociations } from '../ProductGamePlan/CropBrandAssociation';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { FieldPlanAgronomicsModal } from './FieldPlanAgronomicsModal/FieldPlanAgronomicsModal';
import { ITrait } from '../ProductGamePlan/Editor/PlanProductEditor';
import { IFieldPlanAgronomicsSettings } from './FieldPlan';
import { ICropPlanSelectedProduct } from '../../../logic/Models/Responses/FieldPlanResponse';
import { themeGet } from '@styled-system/theme-get';
import { useHistory } from 'react-router-dom';
import { cornId } from '../../../logic/store/Seeds/CropsSlice';
import { ICropPlanCreateRequest } from '../../../logic/Models/Requests/FieldPlanRequests';
import { ReactComponent as InfoIcon } from '../../../assets/images/AvailabilityIcons/Info.svg';
import { Popover, Tooltip } from 'antd';
import { useCropPlanTrackingState } from './useCropPlanTrackingState';
import { useAmplitudePassthrough } from '../../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../../tracing/session';
import { EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames } from '../../../tracing/EventNames';

const AgronomicsContainer = styled.div`
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 20px;
`;

const Pill = styled.div`
	background-color: #F0F0F0;
	color: ${themeGet('colors.mediumGrey')};
	font-size: ${themeGet('fontSizes.tiny')};
	border-radius: 14px;
	padding: 4px 16px 4px 16px;
	margin-right: 5px;
	height: 25px;
`;

const StyledInfoIcon = styled(InfoIcon)`
	path, circle.fill {
		fill: ${themeGet('colors.darkGrey')};
		stroke: ${themeGet('colors.darkGrey')};
	}
`;

interface IAgronomicsSeedSelectionProps
{
	clearPlanData: () => void;
	returnToCropPlanList: () => void;
}

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);

	return {
		FieldPlan: state.fieldplan.currentFieldPlan,
		Seeds: state.seeds.seeds,
		SelectedFieldIds: state.ui.SelectedFields,
		SelectedCropPlan: state.fieldplan.selectedCropPlanId 
			? state.fieldplan.fullCropPlanData.find(p => p.Id === state.fieldplan.selectedCropPlanId) 
			: undefined,
		SelectedGrower,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		CreateFieldPlanWithCropPlan: makeDispatch(dispatch, createFieldPlanWithCropPlan),
		UpdateCropPlanSettings: makeDispatch(dispatch, updateCropPlan),
		AssignProductsToCropPlan: makeDispatch(dispatch, assignProductsToCropPlan),
		RunCropPlan: makeDispatch(dispatch, runCropPlan)
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * The Agronomics & Seed Selection page 
 */
const AgronomicsSeedSelection = (props: IAgronomicsSeedSelectionProps & PropsFromRedux) =>
{
	const {
		clearPlanData,
		returnToCropPlanList,
		FieldPlan,
		SelectedGrower,
		Seeds,
		SelectedFieldIds,
		SelectedCropPlan,
		CreateFieldPlanWithCropPlan,
		UpdateCropPlanSettings,
		AssignProductsToCropPlan,
		RunCropPlan
	} = props;

	const theme = useTheme();

	const trackingData = useCropPlanTrackingState();
	
	const [defaultMinMaxRm, setDefaultMinMaxRm] = useState<[number,number]>([0,200]);
	const [cropSelection, setCropSelection] = useState<string>(`${cornId}_Any`);
	const [underlyingSelectedSeriesIds, setSelectedSeriesIds] = useState<string[]>([]);
	const [underlyingSelectedHybrids, setSelectedHybrids] = useState<ICropPlanSelectedProduct[]>([]);

	const getFieldAcres = (): string =>
	{
		let totalAcres = '0';

		if (SelectedGrower && SelectedGrower.Farms && SelectedGrower.Farms.length > 0 && SelectedFieldIds && SelectedFieldIds.length > 0)
		{
			const allFields = SelectedGrower.Farms.flatMap(fa => fa.Fields);

			totalAcres = allFields.map(field => SelectedFieldIds.includes(field.Id) ? field.Area : 0).reduce((a1, a2) => (a1 ?? 0) + (a2 ?? 0), 0).toFixed(1);
		}

		return totalAcres;
	};

	// TODO: refactor into a different model, we do not need zip code and yield with this setup
	const defaultCurrentSettings: IFieldPlanAgronomicsSettings =
	{
		acres: Number.parseFloat(getFieldAcres()),
		irrigated: false,
		fungicide: false,
		isFilterApplied: true, // filter is always applied in this setting
		performance: 5,
		rmMax: defaultMinMaxRm[1],
		rmMin: defaultMinMaxRm[0],
		seedsRequired: 2,
		selectedTraits: [],
		targetYield: 250,
		topEndYield: 5,
		zip: '21227'
	};
	
	const [filteredHybridList, setFilteredHybridList] = useState<IHybridResponse[]>([]);
	const [filteredSeriesList, setFilteredSeriesList] = useState<ISeriesWithHybrid[]>([]);
	const [isAgronomicInputsModalOpen, setIsAgronomicInputsModalOpen] = useState(false);
	const [cropOptions, setCropOptions] = useState<IOptionItem[]>([]);
	const [uniqueTraits, setUniqueTraits] = useState<ITrait[]>([]);
	const [currentSettings, setCurrentSettings] = useState<IFieldPlanAgronomicsSettings>(defaultCurrentSettings);
	const history = useHistory();

	// Make sure that the 'selected' seeds only include seeds that are visible (accounting for filters, etc)
	const selectedSeriesIds = useMemo(() => 
		underlyingSelectedSeriesIds.filter(s => !filteredSeriesList.length 
			|| filteredSeriesList.find(fs => fs.seriesId.toLowerCase() === s.toLowerCase())), 
	[underlyingSelectedSeriesIds, filteredSeriesList]);

	const selectedHybrids = useMemo(() => 
		underlyingSelectedHybrids.filter(h => !filteredHybridList.length 
			|| (filteredSeriesList.find(fs => fs.hybrids.find(fh => fh.Id.toLowerCase() === h.HybridId.toLowerCase()))
			&& filteredHybridList.find(fh => fh.Id.toLowerCase() === h.HybridId.toLowerCase()))), 
	[underlyingSelectedHybrids, filteredHybridList]);


	const cancelFieldPlanCreate = useCallback(() =>
	{
		if (FieldPlan)
		{
			// We must be in an Add Crop Plan state so return to the Crop Plan Overview
			returnToCropPlanList();
		}
		else
		{
			clearPlanData();
			history.push('/fieldactivities');
		}
		
	}, [history]);

	// If a plan is loaded, apply the config from it
	useEffect(() => 
	{
		if(!SelectedCropPlan || !cropOptions?.length || !filteredHybridList.length || !uniqueTraits?.length)
		{
			return;
		}

		setCropSelection(`${SelectedCropPlan.CropId}_${SelectedCropPlan.Settings.BrandApplication ?? 'Any'}`);
		setCurrentSettings((prev) => ({
			...prev,
			fungicide: SelectedCropPlan.Settings.Fungicide,
			irrigated: SelectedCropPlan.DefaultIrrigation ?? false,
			seedsRequired: SelectedCropPlan.MaximumPortfolioSize,
			rmMin: SelectedCropPlan.Settings.RmMin,
			rmMax: SelectedCropPlan.Settings.RmMax,
			selectedTraits: SelectedCropPlan.Settings.Traits,
			
			topEndYield: SelectedCropPlan.Settings.TopEndYield ?? 0,
			performance: SelectedCropPlan.Settings.ConsistencyPerf ?? 0,

			acres: Number.parseFloat(getFieldAcres()),
		}));
		
		setSelectedSeriesIds(uniq(SelectedCropPlan.SelectedProducts
			.map(p => filteredHybridList.find(h => h.Id === p.HybridId))
			.filter(p => Boolean(p))
			.map(p => p!.SeriesId)));
			
		setSelectedHybrids(SelectedCropPlan.SelectedProducts);
		
	}, [SelectedCropPlan, cropOptions?.length, filteredHybridList?.length, uniqueTraits?.length, 
		setSelectedSeriesIds, setSelectedHybrids, setCropSelection, setCurrentSettings]);

	useEffect(() =>
	{
		if (cropBrandAssociations)
		{
			const createCropOptions = cropBrandAssociations.map(cropItem =>
			{
				const cropOption: IOptionItem =
				{
					label: cropItem.title,
					value: `${cropItem.cropId}_${cropItem.brandApplication}`
				};
	
				return cropOption;
			});
	
			setCropOptions(createCropOptions);
		}
		
	}, [cropBrandAssociations]);

	const useFilteredSeriesList = (seeds: ISeedResponse[], cropId: string, brandApplication: SeedListBrandApplication) =>
	{
		if (!seeds)
		{
			return;
		}
		
		const hybrids = seeds
			.filter(seedLevel => seedLevel.CropId === cropId)
			.flatMap(seedLevel => seedLevel.BrandApplications)
			.filter(brandLevel => brandLevel.BrandApplication === brandApplication)
			.flatMap(brandLevel => brandLevel.Hybrids);

		const allSeriesHybrids = Object.values(hybrids.reduce<{ [key: string]: ISeriesWithHybrid; }>((collection, cur) =>
		{
			if (!collection[cur.SeriesId])
			{
				collection[cur.SeriesId] = {
					seriesId: cur.SeriesId,
					seriesName: cur.SeriesName,
					availability: 'Red',
					brandName: cur.BrandName,
					relativeMaturity: cur.RelativeMaturity,
					hybrids: []
				};
			}

			const availabilityRanking = ['None' , 'Red' , 'Yellow', 'Green'];
			if(availabilityRanking.indexOf(cur.Availability) > availabilityRanking.indexOf(collection[cur.SeriesId].availability))
			{
				collection[cur.SeriesId].availability = cur.Availability;
			}

			collection[cur.SeriesId].hybrids.push(cur);
			return collection;
		}, {}));

		// If there are any green hybrids, the series is considered green overall
		const greenAvailHybrids = _.sortBy(allSeriesHybrids.filter(s => s.hybrids.some(h => h.Availability === 'Green')), s=> s.seriesName.toLowerCase());
		// If there are no green hybrids, but there is a yellow, the series is considered yellow overall
		const yellowAvailHybrids = _.sortBy(allSeriesHybrids.filter(s => 
			!s.hybrids.some(h => h.Availability === 'Green') && s.hybrids.some(h => h.Availability === 'Yellow')), s=> s.seriesName.toLowerCase());
		// If there are only red hybrids, the series is considered red overall
		const redAvailHybrids = _.sortBy(allSeriesHybrids.filter(s => !s.hybrids.some(h => h.Availability === 'Green') && !s.hybrids.some(h => h.Availability === 'Yellow') && s.hybrids.some(h => h.Availability === 'Red')), s=> s.seriesName.toLowerCase());
		// Now sort each set of availbility by RM
		const availibilitySortedHybrids = greenAvailHybrids.sort((a,b) => Number(a.relativeMaturity) > Number(b.relativeMaturity) ? 1 : -1)
			.concat(yellowAvailHybrids.sort((a,b) => Number(a.relativeMaturity) > Number(b.relativeMaturity) ? 1 : -1)
				.concat(redAvailHybrids.sort((a,b) => Number(a.relativeMaturity) > Number(b.relativeMaturity) ? 1 : -1)));

		setFilteredSeriesList([..._.cloneDeep(availibilitySortedHybrids)]);
	};

	useEffect(() =>
	{
		// This sets the filtered hierarchy of series for the current crop selection only, for the 'able to add' section
		useFilteredSeriesList(Seeds, cropSelection?.split('_')[0], cropSelection?.split('_')[1] as SeedListBrandApplication);

		// This is the un-filtered list of hybrids for the current crop/brand association
		const filteredHybrids = (Seeds || [])
			.filter(seedLevel => seedLevel.CropId === cropSelection?.split('_')[0])
			.flatMap(seedLevel => seedLevel.BrandApplications)
			.filter(brandLevel => brandLevel.BrandApplication === cropSelection?.split('_')[1] as SeedListBrandApplication)
			.flatMap(brandLevel => brandLevel.Hybrids);

		setFilteredHybridList(filteredHybrids);
		
	},[Seeds, cropSelection]);

	// If currentSettings changes, update the seed list to filter any RM and/or Traits
	useEffect(() =>
	{
		if (!Seeds)
		{
			return;
		}

		const cropId = cropSelection?.split('_')[0];
		const brandApplication = cropSelection?.split('_')[1] as SeedListBrandApplication;

		if ((!cropId || !filteredHybridList) && filteredHybridList[0].CropId !== cropId)
		{
			setCurrentSettings((prev) => ({...prev, selectedTraits: []}));
			return;
		}

		const currentSelectedTraits = currentSettings.selectedTraits;

		// If there are no current selected traits, just show all traits
		if (!currentSelectedTraits || currentSelectedTraits.length === 0)
		{
			let filteredSeedsByRm: ISeedResponse[] = _.cloneDeep(Seeds);
			const cropLevelIndex = filteredSeedsByRm.findIndex(s => s.CropId === cropId);

			if (cropLevelIndex > -1)
			{
				const cropLevel = filteredSeedsByRm[cropLevelIndex];

				if (cropLevel && cropLevel.BrandApplications.length > 1)
				{
					// This is a corn crop and could be enogen or corn
					const brandLevelIndex = cropLevel.BrandApplications.findIndex(b => b.BrandApplication === brandApplication);
					if (brandLevelIndex > -1)
					{
						const brandLevel = cropLevel.BrandApplications[brandLevelIndex];

						// Now filter the Hybrids by RM
						brandLevel.Hybrids = brandLevel.Hybrids.filter(h => 
							Number.parseFloat(h.RelativeMaturity) <= currentSettings.rmMax && Number.parseFloat(h.RelativeMaturity) >= currentSettings.rmMin);

						cropLevel.BrandApplications = [brandLevel];
						filteredSeedsByRm = [cropLevel];
					}
				}
				else
				{
					// Crop must be soybean
					const brandLevel = cropLevel.BrandApplications[0];

					// Now filter the Hybrids by RM
					brandLevel.Hybrids = brandLevel.Hybrids.filter(h => 
						Number.parseFloat(h.RelativeMaturity) <= currentSettings.rmMax && Number.parseFloat(h.RelativeMaturity) >= currentSettings.rmMin);

					cropLevel.BrandApplications = [brandLevel];
					filteredSeedsByRm = [cropLevel];
				}
			}

			// This sets the filtered hierarchy of series for the current crop selection only, for the 'able to add' section
			useFilteredSeriesList(filteredSeedsByRm, cropId, brandApplication);
		}
		else
		{
			let filteredSeedsByTrait: ISeedResponse[] = _.cloneDeep(Seeds);
			const cropLevelIndex = filteredSeedsByTrait.findIndex(s => s.CropId === cropId);

			if (cropLevelIndex > -1)
			{
				const cropLevel = filteredSeedsByTrait[cropLevelIndex];

				if (cropLevel && cropLevel.BrandApplications.length > 1)
				{
					const brandLevelIndex = cropLevel.BrandApplications.findIndex(b => b.BrandApplication === brandApplication);
					if (brandLevelIndex > -1)
					{
						const brandLevel = cropLevel.BrandApplications[brandLevelIndex];
						brandLevel.Hybrids = brandLevel.Hybrids.filter(h => currentSelectedTraits.includes(h.TraitId));

						// Now filter the Hybrids by RM
						brandLevel.Hybrids = brandLevel.Hybrids.filter(h => 
							Number.parseFloat(h.RelativeMaturity) <= currentSettings.rmMax && Number.parseFloat(h.RelativeMaturity) >= currentSettings.rmMin);

						cropLevel.BrandApplications = [brandLevel];
						filteredSeedsByTrait = [cropLevel];
					}
				}
				else
				{
					// Crop must be soybean
					const brandLevel = cropLevel.BrandApplications[0];
					brandLevel.Hybrids = brandLevel.Hybrids.filter(h => currentSelectedTraits.includes(h.TraitId));

					// Now filter the Hybrids by RM
					brandLevel.Hybrids = brandLevel.Hybrids.filter(h => 
						Number.parseFloat(h.RelativeMaturity) <= currentSettings.rmMax && Number.parseFloat(h.RelativeMaturity) >= currentSettings.rmMin);

					cropLevel.BrandApplications = [brandLevel];
					filteredSeedsByTrait = [cropLevel];
				}
			}

			// This sets the filtered hierarchy of series for the current crop selection only, for the 'able to add' section
			useFilteredSeriesList(filteredSeedsByTrait, cropId, brandApplication);
		}
	},[currentSettings]);

	useEffect(() =>
	{
		if (filteredHybridList && filteredHybridList.length > 0)
		{
			const brand = cropSelection?.split('_')[1];
			const selectedCropId = cropSelection?.split('_')[0];

			if (filteredHybridList[0].CropId !== selectedCropId)
			{
				setUniqueTraits([]);
				return;
			}
			// If we have Enogen and we're switching from Corn to Enogen, the crop Id's will match, but the brand will not
			else if (brand.toLowerCase() === 'enogen' && (filteredHybridList[0].CropId === selectedCropId
				&& filteredHybridList[0].BrandName.toLowerCase() !== brand?.toLowerCase()))
			{
				setUniqueTraits([]);
				return;
			}

			if (uniqueTraits && uniqueTraits.length === 0)
			{
				// All traits, this will contain duplicates
				const allTraits: ITrait[] = filteredHybridList.map(h =>
				{
					return { TraitName: h.TraitName, TraitId: h.TraitId, Selected: false };
				});

				// Get only the unique traits, we do not want any duplicates
				const newUniqueTraits = _.uniqBy(allTraits, t => t.TraitId);
			
				// TODO: 07/07/2022 - ELM - Change this to use a set of trait names from the client config in the future
				const primaryTraitsNames = ['D', 'V', 'DV']; // the names we want to float to the top of the array
				const primaryTraits: ITrait[] = []; // an array to keep the full ITrait objects for the traits we want to float to the top
				newUniqueTraits.sort(dynamicSort('TraitName')); // sort the unique traits by TraitName

				// Now find the ITrait objects we're looking for and put them into the primary traits array
				newUniqueTraits.forEach(at =>
				{
					if (primaryTraitsNames.includes(at.TraitName))
					{
						primaryTraits.push(at);
					}
				});

				// Filter out the traits so that we don't create duplicates
				let filteredUniqueTraits = newUniqueTraits.filter(at => !primaryTraitsNames.includes(at.TraitName));
				filteredUniqueTraits = filteredUniqueTraits.sort(dynamicSort('TraitName', 'descending'));
			
				// Stick the ITrait objects we found to the front of the sorted array
				filteredUniqueTraits.unshift(...primaryTraits);

				setUniqueTraits(filteredUniqueTraits);
			}

			// Get the Min/Max RM from the hybrid list
			let minRm: number = undefined;
			let maxRm: number = undefined;
			filteredHybridList.forEach((h, index) =>
			{
				const hybridRm = Number.parseFloat(h.RelativeMaturity);
				if (index === 0)
				{
				// If we're on the first element, initialize the min and max to the Rm.
					minRm = hybridRm;
					maxRm = hybridRm;
				}
				if (hybridRm < minRm)
				{
					minRm = hybridRm;
				}
				if (hybridRm > maxRm)
				{
					maxRm = hybridRm;
				}
			});

			setDefaultMinMaxRm([minRm, maxRm]);

			// If there is a loaded crop plan already, use the user-chosen values if they exist
			// and are for the correct (current) crop
			if(SelectedCropPlan?.CropId === selectedCropId 
				&& SelectedCropPlan.Settings.RmMax !== undefined
				&& SelectedCropPlan.Settings.RmMin !== undefined)
			{
				maxRm = SelectedCropPlan.Settings.RmMax;
				minRm = SelectedCropPlan.Settings.RmMin;
			}

			setCurrentSettings((prev) => ({
				...prev, 
				rmMax: maxRm, 
				rmMin: minRm
			}));
		}
	}, [filteredHybridList, cropSelection, SelectedCropPlan?.CropId]);

	// When selecting a single series row from the seed list, it will also select a hybrid and/or a treatment if applicable
	const selectSingleSeries = (selected: boolean, seriesId: string, hybridId: string, treatment?: string) =>
	{
		if (selected)
		{
			const foundSeriesItem = filteredSeriesList.find(fs => fs.seriesId === seriesId);
			
			if (selectedSeriesIds.includes(seriesId))
			{
				if (foundSeriesItem.hybrids.length > 1)
				{
					const seriesHybridIds = foundSeriesItem.hybrids.map(h => h.Id);
					// Clear out any other hybrids from the series as only one series hybrid can be selected at a time
					const newSelectedHybrids = selectedHybrids.filter(h => !seriesHybridIds.includes(h.HybridId));
					setSelectedHybrids([...newSelectedHybrids, { HybridId: hybridId, Treatment: treatment }]);
				}
				else
				{
					setSelectedHybrids([...selectedHybrids, { HybridId: hybridId, Treatment: treatment }]);
				}
			}
			else
			{
				setSelectedSeriesIds([...selectedSeriesIds, seriesId]);
				setSelectedHybrids([...selectedHybrids, { HybridId: hybridId, Treatment: treatment }]);
			}
		}
		else
		{
			setSelectedSeriesIds([...selectedSeriesIds.filter(s => s !== seriesId)]);
			setSelectedHybrids([...selectedHybrids.filter(h => h.HybridId !== hybridId)]);
		}
		
	};

	const session = useScopedSession(AgronomicsSeedSelection.name, trackingData, {
		[EventStructureTags.PageContext]: 'agronomic_seed_selection',
		[EventStructureTags.PageUrl]: window.location.toString()
	});


	// When selecting all visible/filtered series with the select all button
	const selectAllSeries = useAmplitudePassthrough(session, EventFieldPlanNames.SelectAllSeries, (selectAll: boolean, seriesIds: string[], hybrids: ICropPlanSelectedProduct[]) =>
	{
		if (selectAll)
		{
			setSelectedSeriesIds([...seriesIds]);
			setSelectedHybrids([...hybrids]);
		}
		else
		{
			setSelectedSeriesIds([]);
			setSelectedHybrids([]);
		}
	}, [setSelectedSeriesIds, setSelectedHybrids]);


	const selectCrop = useAmplitudePassthrough(session, EventFieldPlanNames.SelectCrop, (value: string) =>
	{
		setCropSelection(value);
		
		// Clear the series and hybrid selections
		setSelectedSeriesIds([]);
		setSelectedHybrids([]);

		// Reset the current settings
		setCurrentSettings(defaultCurrentSettings);
	}, [setCropSelection, setSelectedSeriesIds, setSelectedHybrids, setCurrentSettings, defaultCurrentSettings]);

	const updateSeedsRequired = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeSeedsRequiredCount, (value: number) =>
	{
		setCurrentSettings({...currentSettings, seedsRequired: value});
	}, [currentSettings]);

	const selectTraits = useAmplitudePassthrough(session, EventFieldPlanNames.SelectTrait, (value: string[]) =>
	{
		setCurrentSettings({...currentSettings, selectedTraits: [...value]});
	}, [currentSettings]);

	// This function exists specifically for the slider & min/max initialization that can update both values in quick succession that can lead to
	// data being out of date if just using the single functions
	const updateBothRmMaxAndMin = (value: [number,number]) =>
	{
		setCurrentSettings({...currentSettings, rmMax: value[1], rmMin: value[0]});
	};

	const updateRmMax = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeRelativeMaturity, (value: number) =>
	{
		if (value > defaultMinMaxRm[1])
		{
			value = defaultMinMaxRm[1]; // Do not allow the user to type above the actual max
		}
		setCurrentSettings({...currentSettings, rmMax: value});
	}, [defaultMinMaxRm, currentSettings]);

	const updateRmMin = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeRelativeMaturity, (value: number) =>
	{
		if (value < defaultMinMaxRm[0])
		{
			value = defaultMinMaxRm[0]; // Do not allow the user to type below the actual min
		}
		setCurrentSettings({...currentSettings, rmMin: value});
	}, [defaultMinMaxRm, currentSettings]);

	const updateIrrigation = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeIrrigation, () =>	
	{
		setCurrentSettings({...currentSettings, irrigated: !currentSettings.irrigated});
	}, [currentSettings]);

	const updateFungicide = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeFungicide, () =>
	{
		setCurrentSettings({...currentSettings, fungicide: !currentSettings.fungicide});
	}, [currentSettings]);

	const updateConsistency = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeConsistency, (value: number) =>
	{
		setCurrentSettings({...currentSettings, performance: value});
	}, [currentSettings]);

	const updateTopEndYield = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeTopEndYield, (value: number) =>
	{
		setCurrentSettings({...currentSettings, topEndYield: value});
	}, [currentSettings]);

	const clearFilters = useAmplitudePassthrough(session, EventFieldPlanNames.ClearFilters, () =>
	{
		setCurrentSettings({...defaultCurrentSettings, isFilterApplied: false});
		setIsAgronomicInputsModalOpen(false);
	}, [defaultCurrentSettings]);

	const createFieldPlan = useAmplitudePassthrough(session, EventFieldPlanNames.CreateFieldPlan, () =>
	{
		// Create the crop plan request
		const request: ICropPlanCreateRequest =
		{
			CropId: cropSelection.split('_')[0],
			DefaultIrrigation: currentSettings.irrigated,
			BrandApplication: cropSelection.split('_')[1],
			Fungicide: currentSettings.fungicide,
			RmMax: currentSettings.rmMax,
			RmMin: currentSettings.rmMin,
			ConsistencyPerf: currentSettings.performance,
			TopEndYield: currentSettings.topEndYield,
			Traits: currentSettings.selectedTraits,
			FieldIds: SelectedFieldIds,
			Products: selectedHybrids,
			RunImmediately: true,
			MaximumPortfolioSize: currentSettings.seedsRequired,
		};

		CreateFieldPlanWithCropPlan(request);
	},[currentSettings, SelectedFieldIds, selectedHybrids, cropSelection]);

	// If there is a selected crop plan then edits are saved back to it
	const updateCropPlan = useAmplitudePassthrough(session, EventFieldPlanNames.UpdateCropPlan, async () => 
	{
		if(!SelectedCropPlan)
		{
			return;
		}

		// currentSettings goes to the edit plan endpoint
		await UpdateCropPlanSettings({
			CropPlanId: SelectedCropPlan.Id,
			UpdateRequest: {
				DefaultIrrigation: currentSettings.irrigated,
				BrandApplication: cropSelection.split('_')[1],
				Fungicide: currentSettings.fungicide,
				RmMax: currentSettings.rmMax,
				RmMin: currentSettings.rmMin,
				ConsistencyPerf: currentSettings.performance,
				TopEndYield: currentSettings.topEndYield,
				Traits: currentSettings.selectedTraits,
				IsFilterApplied: currentSettings.isFilterApplied,
				MaximumPortfolioSize: currentSettings.seedsRequired,

				// These items shouldn't change here
				Algorithm: SelectedCropPlan.Algorithm,
				Name: SelectedCropPlan.Name
			}
		});

		// selectedHybrids goes to the plan update products endpoint
		await AssignProductsToCropPlan({
			CropPlanId: SelectedCropPlan.Id,
			Products: selectedHybrids
		});	
		
		// And automatically kick this off to re-run
		RunCropPlan({ CropPlanId: SelectedCropPlan.Id, WaitForPoll: true });
		returnToCropPlanList();
	},
	[currentSettings, selectedHybrids, SelectedCropPlan, returnToCropPlanList]);

	// Determine if this is a new plan or an edit of an existing one.
	const apply = useCallback(() => 
	{
		if(SelectedCropPlan)
		{
			// We will update the existing crop plan
			updateCropPlan();
		}
		else
		{
			// New field plan with an initial crop plan
			createFieldPlan();
		}
	}, [createFieldPlan, updateCropPlan, SelectedCropPlan?.Id, selectedHybrids]);

	const popOverContent = (
		<div style={{ fontWeight: theme.fontWeights.bold }}>
			Max of 10, min of 2.
		</div>
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' }}>
			<AgronomicsContainer>
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
					<div style={{ display: 'flex', flexDirection: 'row', width: '53%' }}>
						<div>Fields:</div>
						<div style={{ fontWeight: theme.fontWeights.bold, marginLeft: 3 }}>{SelectedFieldIds?.length}</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', width: '43%' }}>
						<div>Acres:</div>
						<div style={{ fontWeight: theme.fontWeights.bold, marginLeft: 3 }}>{getFieldAcres()}</div>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
					<div style={{ fontWeight: theme.fontWeights.bold, width: '40%' }}>
							Crop
						<Dropdown
							variant='outlined'
							options={cropOptions}
							onSelect={selectCrop}
							selected={cropSelection}
							disabled={Boolean(SelectedCropPlan) /* You can't change crop on an existing plan */}
							className='Crop_Dropdown'
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }} className='SeedsRequired_IncrementInput'>
						<IncrementNumberInput
							interval={1}
							max={10}
							min={1}
							onChange={updateSeedsRequired}
							value={currentSettings.seedsRequired}
							title={'Seeds Required'}
							boldTitle={true}
						/>
						<div style={{ position: 'relative', top: -16, left: -51 }}>
							<Popover content={popOverContent} trigger='click'>
								<StyledInfoIcon className='infoIcon' style={{ cursor: 'pointer' }} />
							</Popover>
						</div>
					</div>
				</div>
				<div>
					<div
						className='AgronomicInputsButtonSection'
						style={{
							marginTop: 7,
							marginBottom: 7,
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							alignItems: 'center',
							rowGap: 10
						}}
					>
						<Button
							disabled={false}
							className='AgronomicInputs_Button'
							style={{ height: 40, minWidth: 160, marginRight: 15 }}
							variant='outlined'
							onClick={() => setIsAgronomicInputsModalOpen(true)}
						>
							Agronomic Inputs
						</Button>
						{
							Seeds && currentSettings && currentSettings.irrigated &&
							<Pill>Irrigation</Pill>
						}
						{
							Seeds && currentSettings && currentSettings.fungicide &&
							<Pill>Fungicide</Pill>
						}
						{
							Seeds && currentSettings &&
							<Pill>{`RM: ${currentSettings.rmMin}-${currentSettings.rmMax}`}</Pill>
						}
						{
							(Seeds && currentSettings && currentSettings.selectedTraits.length > 0) &&
							(uniqueTraits && uniqueTraits.length === currentSettings.selectedTraits.length) ?
								<Pill>
									All
								</Pill>
								:
								uniqueTraits && currentSettings.selectedTraits.map(t =>
								{
									return (
										<Pill key={t}>
											{uniqueTraits!.find(ut => ut.TraitId === t)?.TraitName ?? 'Missing Trait!'}
										</Pill>
									);
								})
						}
					</div>
				</div>
			</AgronomicsContainer>
			<ConnectedSeedList
				cropName={'Corn'}
				seriesList={filteredSeriesList}
				selectAllSeries={selectAllSeries}
				selectSingleSeries={selectSingleSeries}
				selectedHybrids={selectedHybrids}
				selectedSeriesIds={selectedSeriesIds}
			/>
			<div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, padding: '10px 20px 10px 20px' }}>
				<Button
					disabled={false}
					className='AgronomicInputs_Button'
					style={{ height: 40, minWidth: 160, marginRight: 15 }}
					variant='outlined'
					onClick={cancelFieldPlanCreate}
				>
					Cancel
				</Button>
				<Tooltip title={selectedSeriesIds.length < currentSettings.seedsRequired ? 'Please select at least ' + currentSettings.seedsRequired+ ' seeds' : ''}
					placement={'top'}
				>
					<div>
						<Button
							disabled={selectedSeriesIds.length < currentSettings.seedsRequired}
							className='AgronomicInputs_Button'
							style={{ height: 40, minWidth: 160, marginLeft: 'auto' }}
							variant='dark'
							onClick={apply}
						>
							Apply to Fields
						</Button>
					</div>
				</Tooltip>
			</div>
			<FieldPlanAgronomicsModal
				brandApplication={cropSelection?.split('_')[1]}
				cropName={cropOptions.find(co => co.value === cropSelection)?.label.toString()}
				isOpen={isAgronomicInputsModalOpen}
				currentSettings={currentSettings}
				defaultMax={defaultMinMaxRm[1]}
				defaultMin={defaultMinMaxRm[0]}
				uniqueTraits={uniqueTraits}
				clearFilter={clearFilters}
				closeModal={() => setIsAgronomicInputsModalOpen(false)}
				updateConsistency={updateConsistency}
				updateFungicide={updateFungicide}
				updateIrrigation={updateIrrigation}
				updateMaxAndMinRm={updateBothRmMaxAndMin}
				updateMaxRm={updateRmMax}
				updateMinRm={updateRmMin}
				updateSelectedTraits={selectTraits}
				updateTopEndYield={updateTopEndYield}
			/>
		</div>
	);
};

export const ConnectedAgronomicsSeedSelection = connector(AgronomicsSeedSelection);