import React, { useCallback, useRef, useEffect, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Checkbox } from '../Checkbox/Checkbox';
import { ReactComponent as CaretDownIcon } from './Icons/CaretDownIcon.svg';
import { ReactComponent as CaretUpIcon } from './Icons/CaretUpIcon.svg';
import { ReactComponent as EllipsesIcon } from './Icons/EllipsesIcon.svg';
import { BoolDictionary } from '../../logic/store/UI/UISlice';
import { Popover } from 'antd';
import { IconButton } from '../IconButton/IconButton';
import { ReactComponent as PrescriptionIcon } from '../../assets/images/Prescription.svg';

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
`;

export interface ICheckboxSection
{
	options: ICheckboxChildOption[];
	header: string;
	headerId: string;
	isChecked: boolean;
	isExpanded: boolean;
	showFieldHealthButton: boolean;
}

export interface ICheckboxChildOption
{
	option: string;
	optionId: string;
	isChecked: boolean;
	enabledForFieldHealth: boolean; // Means the Field has a current crop of Corn and a Planting Date
	extraItems?: React.ReactNode | undefined;
}

interface ICheckboxExpanderProps
{
	checkboxSections: ICheckboxSection[];
	onChecked: (checkedFields: BoolDictionary) => void;
	onDelete?: (req: { headerId: string; optionId: string; }) => void;
	onEdit?:(req: { headerId: string; optionId: string }) => void;
	onExpand: (headerIndex: number) => void;
	outerContainerStyle?: React.CSSProperties;
	includeIconButton: boolean;
	iconButtonFunction?: (headerId: string) => void;
}

const StyledSelectAllCheckbox = styled(Checkbox)`
	position: fixed;
	width: 333px;
	padding: 12px;
	background-color: ${themeGet('colors.white')};
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.small')};
	z-index: 998;
`;

const StyledCheckbox = styled(Checkbox)`
	padding: 12px;
	span {
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 200px;
	}
`;

const StyledExpandableCheckbox = styled(Checkbox)`
	display: inline-flex;
	padding: 12px;
	overflow: hidden;
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.small')};
	span {
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 200px;
	}
	svg > path {
		fill: ${themeGet('colors.darkGrey')};
	}
	.ant-checkbox {
		height: 16px;
	}
`;

const ExpandedContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 24px;
	.ant-checkbox-wrapper {
		margin: 0;
 	}
 `;

const ExpanderMainContainer = styled.div`
	height: 100%;
	> div:nth-child(2) {
		margin-top: 15% !important;
	}
 `;

const getSelectAllState = (sections: ICheckboxSection[] = []): boolean =>
	sections.every(
		section => section.options.every(option => option.isChecked)
	);

const getSectionIndeterminate = (section: ICheckboxSection) =>
{
	const checkedLength = section.options.filter(option => option.isChecked).length;
	return checkedLength && checkedLength < section.options.length;
};

const getSectionChecked = (section: ICheckboxSection) =>
{
	const checkedLength = section.options.filter(option => option.isChecked).length;
	return checkedLength && checkedLength === section.options.length;
};

const getAllIndeterminate = (sections: ICheckboxSection[]) =>
{
	const indeterminateLength = sections.filter(section => getSectionIndeterminate(section)).length;
	if (indeterminateLength > 0)
	{
		return true;
	}
	const checkedLength = sections.filter(section => getSectionChecked(section)).length;
	return checkedLength && checkedLength < sections.length;
};

const getArrowIcon = (isExpanded: boolean) =>
{
	return isExpanded ? <CaretUpIcon /> : <CaretDownIcon />;
};

// Render an individual popover component so that it's popoverOpen variable does not conflict with another row's,
// opening all of the popovers at once
const ExpanderPopover = ({ onDelete, onEdit, headerId, optionId}) =>
{
	// Whether the popover is open or not
	const [popoverOpen, setPopoverOpen] = useState(false);

	const handlePopoverOpenChange = (newOpen: boolean) =>
	{
		setPopoverOpen(newOpen);
	};

	const popoverDelete = ({ headerId: farmId, optionId: fieldId }: { headerId: string; optionId: string; }) =>
	{
		onDelete({ headerId: farmId, optionId: fieldId });
		setPopoverOpen(false); // close the popover
	};

	const popoverEdit = ({ headerId: farmId, optionId: fieldId }: { headerId: string; optionId: string; }) =>
	{
		onEdit({ headerId: farmId, optionId: fieldId });
		setPopoverOpen(false); // close the popover
	};

	return (
		<Popover
			title=''
			trigger='click'
			placement='right'
			open={popoverOpen}
			onOpenChange={handlePopoverOpenChange}
			content=
				{
					<div>
						<div style={{ cursor: 'pointer' }} onClick={() => popoverDelete({ headerId, optionId })}>
							Delete
						</div>
						<div style={{ cursor: 'pointer', marginTop: 10 }} onClick={() => popoverEdit({ headerId, optionId })}>
							Edit
						</div>
					</div>
				}
		>
			<div style={{ padding: '5px 10px', cursor: 'pointer' }}>
				<EllipsesIcon />
			</div>
		</Popover>
	);
};

const RenderComponent = ({ data, index, style }) =>
{
	const headerIndex = index;
	const section: ICheckboxSection = data.items[index];
	const { onSelectSection, onExpand, onSelectOption, onDelete, includeIconButton, iconButtonFunction, onEdit } = data;
	const { options, header, headerId, isExpanded, showFieldHealthButton } = section;

	const theme = useTheme();

	return (
		<div key={`${header}-${headerIndex}`} style={style} >
			<div style={{ marginTop: '8px', display: 'flex', backgroundColor: theme.colors.backgroundLM }}>
				<StyledExpandableCheckbox
					className='Checkbox_Expander_Section'
					checked={getSectionChecked(section)}
					indeterminate={getSectionIndeterminate(section)}
					onChange={(e) => onSelectSection(e, headerIndex)}
				>
					{header}
				</StyledExpandableCheckbox>
				{
					includeIconButton &&
					<div style={{ marginLeft: 'auto', display: 'flex', position: 'relative', top: 6 }}>
						<StyledIconButton
							className='CropHealthPDF-button'
							tooltip={showFieldHealthButton ? 'Crop Health PDF' 
								: 'To print Crop Health, this Farm must contain at least one Field that is Corn and has a planting date for the current Field Year.'}
							showDisabledTooltip={true}
							disabled={!showFieldHealthButton}
							placement='top'
							hoverbgcolor={theme.colors.lightestGrey}
							onClick={() => iconButtonFunction(headerId)}
						>
							<PrescriptionIcon style={{ display: 'flex' }} />
						</StyledIconButton>
					</div>
				}
				<div
					style={{
						marginLeft: !includeIconButton ? 'auto' : 'inherit',
						marginRight: '12px',
						cursor: 'pointer',
						alignItems: 'center',
						justifyContent: 'flex-end',
						display: 'inline-flex',
						paddingLeft: 20,
					}}
					onClick={() =>
					{
						onExpand(headerIndex);
					}}>
					{getArrowIcon(isExpanded)}
				</div>
			</div>
			<ExpandedContainer className='Checkbox_Expander_Contents'>
				{isExpanded && options.map(({ isChecked: isOptionChecked, option, optionId, extraItems }, optionIndex) =>
				{
					return (
						<div key={`${headerId}-${headerIndex}-${optionId}-${optionIndex}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<StyledCheckbox
								className='Checkbox_Expander_ChildCheckbox'
								key={option}
								checked={isOptionChecked}
								onChange={(e) => onSelectOption(e, optionId)}
							>
								{option}
							</StyledCheckbox>
							<div style={{flexGrow: 1}} />
							{
								extraItems && <div style={{marginRight:12, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{extraItems}</div>
							}
							{
								(onDelete || onEdit) && <div style={{ marginRight: '12px', alignItems: 'center', justifyContent: 'flex-end', display: 'inline-flex' }}>
									<ExpanderPopover onDelete={onDelete} onEdit={onEdit} headerId={headerId} optionId={optionId} />
								</div>
							}
						</div>
					);
				})}
			</ExpandedContainer>
		</div>
	);
};

const checkBoxHeight = 46;
export const CheckboxExpander = (props: ICheckboxExpanderProps) => 
{
	const {
		checkboxSections,
		includeIconButton,
		outerContainerStyle,
		onChecked,
		onDelete,
		onEdit,
		onExpand,
		iconButtonFunction,		
	} = props;
	const areAllSelected = getSelectAllState(checkboxSections);
	const ref = useRef(null);

	const onSelectOption = useCallback((e, optionId) => 
	{
		const checked = e.target.checked;
		onChecked({ [optionId]: checked });
	}, [onChecked]);

	const onSelectSection = useCallback((e, sectionInd) =>
	{
		const checked = e.target.checked;
		onChecked(
			checkboxSections[sectionInd].options.reduce((selectedIds, option) => 
			{
				selectedIds[option.optionId] = checked;
				return selectedIds;
			}, {})
		);
	}, [onChecked, checkboxSections]);

	const onSelectAll = useCallback((e) =>
	{
		const checked = e.target.checked;
		onChecked(
			checkboxSections.flatMap(section => section.options).reduce(
				(selectedIds, option) =>
				{
					selectedIds[option.optionId] = checked;
					return selectedIds;
				}, {}
			)
		);
	}, [onChecked, checkboxSections]);

	const onDeleteOption = onDelete ? useCallback((props: { headerId: string; optionId: string; }) =>
	{
		onDelete(props);
	}, [onDelete]) : undefined;

	const onEditOption = onEdit ? useCallback((props: { headerId: string; optionId: string; }) =>
	{
		onEdit(props);
	}, [onEdit]) : undefined;

	const onExpandSection = useCallback((index) =>
	{
		if (ref && ref.current?.resetAfterIndex)
		{
			ref.current?.resetAfterIndex(index, false);
		}
		onExpand(index);
	}, [onExpand]);

	useEffect(() =>
	{
		if (ref && ref.current?.resetAfterIndex)
		{
			ref.current?.resetAfterIndex(0, false);
		}
	}, [checkboxSections]);

	return (
		<ExpanderMainContainer className='Checkbox_Expander_Main_Container' style={outerContainerStyle}>
			{/* Select All */}
			<StyledSelectAllCheckbox
				className='Checkbox_Expander_SelectAll'
				indeterminate={getAllIndeterminate(checkboxSections)}
				checked={areAllSelected}
				onChange={onSelectAll}
			>
				Select All
			</StyledSelectAllCheckbox>
			<AutoSizer>
				{({ width, height }) => (
					<List
						ref={ref}
						itemCount={checkboxSections.length}
						itemSize={(index) => checkboxSections[index].isExpanded ?
							checkboxSections[index].options?.length * checkBoxHeight + checkBoxHeight + 8 :
							checkBoxHeight + 8}
						width={width}
						height={height}
						itemData={{ 
							items: 
							checkboxSections,
							includeIconButton,
							onSelectSection,
							onExpand: onExpandSection,
							onEdit: onEditOption,
							onSelectOption,
							onDelete: onDeleteOption,
							iconButtonFunction,
						}}>
						{RenderComponent}
					</List>
				)}
			</AutoSizer>
		</ExpanderMainContainer >
	);
};