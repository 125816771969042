import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import ReactJson from 'react-json-view';
import { connect, ConnectedProps } from 'react-redux';
import { Api } from '../../logic/Api/Api';
import { RootState } from '../../logic/store/Store';

const GrowerHierarchyTroubleshootingTool = (props: PropsFromRedux) => 
{
	const [enteredUsername, setEnteredUsername] = useState<string>('');
	const [enteredGrower, setEnteredGrower] = useState<string>('');
	const [usernameSearchTerm, setUsernameSearchTerm] = useState<string>(undefined);
	const [growerSearchTerm, setGrowerSearchTerm] = useState<string>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userData, setUserData] = useState<object>();

	// When a new search term is committed, fetch the order from the server
	useEffect(()=>
	{
		if(!usernameSearchTerm)
		{
			return;
		}

		const execute = async () => 
		{
			const api = new Api('/api/4', props.userAuthToken, undefined);
			try
			{
				let reqUrl = `/supporttools/info/user/${encodeURIComponent(usernameSearchTerm)}/growerList`;
				if(growerSearchTerm)
				{
					reqUrl = reqUrl + `?growerName=${encodeURIComponent(growerSearchTerm)}`;
				}
				const response = await api.getAsync<object>(reqUrl);	
				setUserData(response);
			}
			catch(err)
			{
				setUserData(err);
			}
			setIsLoading(false);
		};
		setIsLoading(true);
		execute();
	},
	[usernameSearchTerm, growerSearchTerm, props.userAuthToken]);

	const updateSearchTerms = () =>
	{
		setUsernameSearchTerm(enteredUsername);
		setGrowerSearchTerm(enteredGrower);
	};

	return (
		<Form style={{display:'flex', flexDirection:'column', alignItems:'stretch', height: '100%', position: 'relative'}}>
			<h1>Grower Hierarchy</h1>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
				<Form.Item label="Username" style={{width: 400, marginRight: 12}}>
					<Input disabled={isLoading} value={enteredUsername} onChange={(e) => setEnteredUsername(e.currentTarget.value)} placeholder='User Email Address' />
				</Form.Item>
				<Form.Item label="Grower Name" style={{width: 400, marginRight: 12}}>
					<Input
						disabled={isLoading}
						value={enteredGrower}
						onChange={(e) => setEnteredGrower(e.currentTarget.value)}
						placeholder='(Optional) Full or Partial Grower Name'
					/>
				</Form.Item>
				<Button onClick={() => updateSearchTerms()}>Search</Button>
			</div>
			<ReactJson style={{flexGrow: 1, flexShrink: 1, overflow: 'auto', padding: 4, border: '1px #cccccc solid'}} src={userData} />
		</Form>
	);
};

const mapStateToProps = (state: RootState) => ({
	userAuthToken: state.auth.userAuthToken,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const GrowerHierarchyTroubleshootingToolComponent = connector(GrowerHierarchyTroubleshootingTool);