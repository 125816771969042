import React, { ReactNode, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { ThemeName } from '../../logic/store/UI/UISlice';
import { useUrlTheme } from '../../logic/Utility/useUrlTheme';
import { useScopedSession } from '../../tracing/session';
import { useLoadingCounter } from '../../logic/Utility/useLoadingCounter';
import { CoveringLoader } from '../../components/LoadingSpinner/CoveringLoader';
import { ScaleLoader } from 'react-spinners';
import { ReactComponent as CoreLogo } from '../../assets/images/GH-GHX-Logos-Orange.svg';
import { ReactComponent as EnogenCoreLogo } from '../../assets/images/EluminateEnogenLogos.svg';
import { useGetJdConnectInvitation } from './useGetJdConnectInvitation';
import { Api } from '../../logic/Api/Api';
import { Button } from '../../components/Button/Button';
import * as queryString from 'query-string';

export interface IGetJdConnectInviteResponse {
	SellerId: string;
    SellerName: string;
    GrowerId: string;
    GrowerName: string;
    IsGhx: boolean;
    IsEnogenTheme: boolean;
    Token: string;
    CreatedDate: string;
    Status: string;
    GrowerInvitationLink: string;
    JdAuthorizationLink: string;
}

export const Invite = (props: {
	changeTheme: (name: ThemeName) => void;
}) => 
{
	// Make sure our themeing is correct per the url
	const [isEnogen, setIsEnogen] = useState(false);
	useUrlTheme(setIsEnogen, props.changeTheme);
	const theme = useTheme();
	const session = useScopedSession('jdconnect-invite');
	const [api] = useState(new Api('api', null, session));

	const [message, setMessage] = useState<ReactNode>();

	// Grab the invitation from the url
	const parsedQueryString = queryString.parse(location.search);
	const invitationCode = (typeof (parsedQueryString.code) === 'string' && parsedQueryString.code.length) ? parsedQueryString.code : '';

	// When loading > 0 a blocking spinner will show
	const { isLoading, startLoading, endLoading } = useLoadingCounter();
	
	// Invitation Data
	const jDConnectInvitation: IGetJdConnectInviteResponse = useGetJdConnectInvitation(
		invitationCode,
		api,
		setMessage, 
		startLoading,
		endLoading);

	const themedText: string = useMemo(() =>
	{
		return isEnogen ? 'Eluminate' : 'GHX Fields';
	},[isEnogen]);

	return <>
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				backgroundColor: theme.colors.disabledGrey,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: theme.colors.white,
					padding: 20,
					marginLeft: 25,
					marginRight: 25,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: theme.colors.lightGrey,
					borderStyle: 'solid',
					height: !message ? '68vh' : 'auto',
					overflowY: 'auto',
					width: !message ? 'auto' : '70vw',
				}}
			>
				<div style={{width:'100%', display: 'flex', flexDirection:'column', alignItems: 'center', marginBottom: 10 }}>
					{
						isEnogen ?
							<EnogenCoreLogo  width={'100%'} />
							:
							<CoreLogo  width={'100%'} />
					}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column',  width: '100%', height: '100%' }}>
					{
						!message ?
							<div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
								<div style={{ fontSize: theme.fontSizes.xLarge, fontWeight: theme.fontWeights.bold, textAlign: 'left', marginBottom: 8}}>
									{`${themedText} Account Connection Invitation`}
								</div>
								<div
									style={{
										borderRadius: 10,
										borderWidth: 1,
										borderColor: theme.colors.lightestGrey,
										borderStyle: 'solid',
										padding: 10,
										marginBottom: 8,
									}}
								>
									{`Authorizing ${themedText} to import boundaries and operational data from your My John Deere account`}
								</div>
								<div
									style={{
										borderRadius: 10,
										borderWidth: 1,
										borderColor: theme.colors.lightestGrey,
										borderStyle: 'solid',
										marginBottom: 8,
										padding: 10,
									}}
								>
									<div style={{ width: '100%', fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large }}>
										Invitation Details:
									</div>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<div style={{ fontWeight: theme.fontWeights.bold, marginRight: 3 }}>{'Reseller:'}</div>
											<div>{jDConnectInvitation?.SellerName}</div>
										</div>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<div style={{ fontWeight: theme.fontWeights.bold, marginRight: 3 }}>{'Farmer:'}</div>
											<div>{jDConnectInvitation?.GrowerName}</div>
										</div>
									</div>
								</div>
								<div
									style={{
										borderRadius: 10,
										borderWidth: 1,
										borderColor: theme.colors.lightestGrey,
										borderStyle: 'solid',
										padding: 10,
										marginBottom: 8,
										fontStyle: 'italic',
										color: theme.colors.lightGrey,
									}}
								>
									{'Disclaimer: You can disconnect this connection within My John Deere at any time. You will be given an opportunity to select specific farms and fields for data sharing.'}
								</div>
								<div style={{ marginTop: 'auto', width: '100%' }}>
									<Button
										variant='main'
										style={{ width: 200 }}
										onClick={() => window.location.assign(jDConnectInvitation?.JdAuthorizationLink)}
										disabled={!!message}
									>
										Connect to My John Deere
									</Button>
								</div>
							</div>
							:
							<div
								style={{
									borderRadius: 10,
									borderWidth: 1,
									borderColor: theme.colors.lightestGrey,
									borderStyle: 'solid',
									padding: 10,
									marginBottom: 8,
									marginTop: 15,
									textAlign: 'center',
									fontSize: theme.fontSizes.large,
								}}
							>
								{message}
							</div>
					}
				</div>
			</div>
		</div>
		<CoveringLoader duration={10} className={isLoading ? 'loading' : ''}>
			<ScaleLoader color={theme.colors.primary} loading={true} />
		</CoveringLoader>
	</>;
};