import React from 'react';
import L from 'leaflet';
import { MapCustomControl } from '../MapCustomControl';
import { IconButton } from '../../../components/IconButton/IconButton';
import { ReactComponent as ExportPdf } from '../../../assets/images/DownloadPdf.svg';
import { ReactComponent as ExportFile } from '../../../assets/images/ExportVRS.svg';
import { ReactComponent as ZoneManagement } from '../../../assets/images/ZoneManagementIcon.svg';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const MapControlButton = styled.div`
	background-color: ${themeGet('colors.secondary')};
	height: 47px;
	width: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	border: 2px solid rgba(0,0,0,0.3);
	&:hover {
		background-color: ${themeGet('colors.primary')};
	}
`;

interface IExportTrayProps
{
	mapRef: React.MutableRefObject<L.Map | undefined>;
	onExportPlanFile: () => void;
	onDownloadFieldPdf: () => void;
	onZoneManagement: () => void;
	display: boolean;
	displayZoneManagement: boolean;
}

export const ExportTray = (props: IExportTrayProps) =>
{
	const { mapRef, display, displayZoneManagement, onExportPlanFile, onDownloadFieldPdf, onZoneManagement } = props;

	const addCustomControl = (control: React.ReactElement, position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined) =>
	{
		return (
			<MapCustomControl map={mapRef.current} position={position} show={display}>
				<MapControlButton>
					{control}
				</MapControlButton>
			</MapCustomControl>
		);
	};

	return (
		<div>
			{
				addCustomControl(
					<IconButton
						tooltip='Download PDF'
						placement='left'
						onClick={() => onDownloadFieldPdf()}
						fillcolor='auto' // purposefully incorrect so as to not have a fillcolor
					>
						<ExportPdf height={40} width={40} style={{ position: 'relative' }} />
					</IconButton>,
					'topright'
				)
			},
			{
				addCustomControl(
					<IconButton
						tooltip='Export Field Planting Plan'
						placement='left'
						onClick={() => onExportPlanFile()}
						fillcolor='auto' // purposefully incorrect so as to not have a fillcolor
					>
						<ExportFile height={40} width={40} style={{ position: 'relative' }} />
					</IconButton>,
					'topright'
				)
			}
			{
				displayZoneManagement && addCustomControl(
					<IconButton
						tooltip='Zone Management'
						placement='left'
						onClick={() => onZoneManagement()}
						fillcolor='auto' // purposefully incorrect so as to not have a fillcolor
					>
						<ZoneManagement height={28} width={40} style={{ position: 'relative' }} />
					</IconButton>,
					'topright'
				)
			}
		</div>
	);
};