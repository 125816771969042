import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import styled, { useTheme } from 'styled-components';
import { getCropEdgeSsoLaunchUrl } from '../../../logic/store/User/UserInfoSlice';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';

const CropEdgeViewContainer = styled.div`
	display: flex;
	width: 100%;
	height: 94%;
	padding-right: 5%;
	padding-left: 5%;
	flex-direction: column;
	justify-content: center;
`;

export interface ICropEdgeViewProps extends PropsFromRedux
{
	orderName: string;
}

const CropEdgeViewComponent = (props: ICropEdgeViewProps) =>
{
	const {
		orderName,
		IsLoadingUserInfo,
		LoginLink,
		launchCropEdgeSso,
	} = props;

	const theme = useTheme();

	const launchCropEdgeSsoForCurrentUser = () =>
	{
		if (IsLoadingUserInfo)
		{
			return;
		}

		launchCropEdgeSso();
	};

	useEffect(() =>
	{
		launchCropEdgeSsoForCurrentUser();
	},[]);

	const backToPlanList = () =>
	{
		// Do things before going back to the Plan List?
	};

	return (
		<div style={{ width: '100%', height: '100%', zIndex: 998, paddingLeft: 70, backgroundColor: theme.colors.backgroundLM }}>
			<div
				className='backTo_PlanList_Container'
				style={{
					display: 'flex',
					height: 52,
					padding: '10px 25px',
					alignItems: 'center',
				}}
			>
				<Link to='/fieldactivities/recall' className='linkTo_PlanList' onClick={backToPlanList}>
					<LeftCaret style={{ marginRight: '20px' }} />
					<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>Proposal List</span>
				</Link>
				<Ellipse style={{ margin: '0 10px' }} />
				<span style={{
					fontWeight: theme.fontWeights.bold,
					fontSize: theme.fontSizes.large,
					fontFamily: theme.fonts.heading,
					width: '60%'
				}}>
					Order {orderName}
				</span>
			</div>
			<CropEdgeViewContainer className='CropEdgeView_Container' >
				{ LoginLink && <iframe id='CropEdgeView' src={LoginLink} style={{ height: '90%' }} /> }
			</CropEdgeViewContainer>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	LoginLink: state.userInfo.CropEdgeLinkUrl,
	IsLoadingUserInfo: state.userInfo.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
	launchCropEdgeSso: () => dispatch(getCropEdgeSsoLaunchUrl()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const CropEdgeView = connector(CropEdgeViewComponent);