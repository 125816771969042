import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { canManageAccounts, hasEffectivePermission, getCurrentActingUser, isManagingAccount, setManagedUser } from '../../../logic/store/User/AuthSlice';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { ReactComponent as Arrow } from '../Icons/Arrow.svg';
import { Select } from 'antd';
import { uniqBy } from 'lodash';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { themeGet } from '@styled-system/theme-get';
import { ThemeName } from '../../../logic/store/UI/UISlice';

const StyledArrow = styled(Arrow)`
	position: absolute;
	top: 50px;
	left: 100px;
`;

const StyledSelect = styled(Select)`
	width: 100%;
	.ant-select-selector {
		border-radius: 4px !important;
		background-color: ${themeGet('colors.darkGrey')} !important;
		color: white;
		font-family: 'Roboto';
		font-size: ${themeGet('fontSizes.small')};
		font-weight: ${themeGet('fontWeights.bold')};
	}

	svg path {
		fill: white;
		stroke: white;
		stroke-width: 100;
	}
`;

const mapStateToProps = (state: RootState) => ({
	canManageAccounts: canManageAccounts(state),
	isManagingAccount: isManagingAccount(state),
	accounts: state.auth.manageableUsers,
	currentActingUser: getCurrentActingUser(state),
	self: state.userInfo,
	hasGrowers: (state.grower.Growers?.length > 0),
	isLoading: state.ui.isLoading || state.grower.isLoading || state.userInfo.isLoading || state.auth.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		setManagedUser: makeDispatch(dispatch, setManagedUser)
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IManageAccountsDropdownProps extends PropsFromRedux
{
	changeTheme: (name: ThemeName) => void;
}

/**
 * Component for listing accounts that the currently logged in user can manage.  
 * Provides simple search functionality to handle large lists.
 * Includes an overlay of an 'arrow' to highlight its placement if the user has to make a choice.
 */
const ManageAccountDropdown = (props: IManageAccountsDropdownProps) => 
{
	const { self, accounts, currentActingUser, setManagedUser, hasGrowers, isManagingAccount, canManageAccounts } = props;

	// When an advisor is actually selected, propogate that choice upward via props.
	const selectSeedAdvisor = useCallback((id: string) => 
	{
		const matchingAccount = accounts.find(acct => acct.UserId === id);
		setManagedUser(matchingAccount);
	}, [accounts]);

	// Create a simplified list of the available users with some formatting.
	// Prepends the logged in user to the top of the list so the top item is 'manage myself'
	const options = useMemo(() => 
	{
		const options = [
			...uniqBy(accounts, a => a.UserId).map(acct => ({
				value: acct.UserId,
				label: `${(acct.UserName?.length > 0 ? acct.UserName : acct.UserEmail)}${acct.FoundationId?.length > 0 ? (` (${acct.FoundationId})`) : ''}`
			}))].sort(dynamicSort('label'));
		return options;
	}, [self.UserId, self.UserName, self.UserEmail, self.FoundationId, accounts]);

	return <div style={{ width: '300px', position: 'relative' }}>
		<StyledSelect
			className='ManageAccountDropdown'
			showSearch
			value={currentActingUser.UserId}
			options={options}
			placeholder='Select Seed Advisor'
			optionFilterProp='label'
			onSelect={selectSeedAdvisor}
		/>
		{
			/* If this user can manage accounts but isn't, and has no growers of their own, show the helper arrow */
			(!isManagingAccount && canManageAccounts && !hasGrowers)
			&& <StyledArrow />
		}
	</div>;
};

export const ConnectedManageAccountDropdown = connector(ManageAccountDropdown);