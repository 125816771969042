import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { ISoilDataResponse } from '../../Models/Responses/SoilDataResponse';
import { Feature, FeatureCollection, Geometry } from '@turf/turf';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { Polygon, MultiPolygon } from '@turf/turf';
import { Api } from '../../Api/Api';

export interface ISoilZone {
	Mukey:                  string;
	Boundary:               Polygon | MultiPolygon;
	Area:                   number;
	Color:                  string;
	CationExchangeCapacity: number;
	ComponentName:          string;
	ElectricalConductivity: number;
	pH:                     number;
	Slope:                  number;
	SoilType:               string;
	SoilTexture:            string;
	OrganicMatter:          number;
	CornEstimatedYield:     number;
	SoybeanEstimatedYield:  number;
	NccpiValues:            INccpiValue[];
	Drainage:               string;
}

export interface INccpiValue {
	CropName:        CropName;
	CropId?:         string;
	Value:           number;
	EstimatedYield?: number;
}

export enum CropName {
	Corn = 'Corn',
	Cotton = 'Cotton',
	Overall = 'Overall',
	SmallGrains = 'Small grains',
	Soybean = 'Soybean',
}


/**
 * Converts an array of ISoilZone objects to a GeoJSON FeatureCollection
 * @param soilZones Array of ISoilZone objects
 * @returns GeoJSON FeatureCollection
 */
function convertSoilZonesToFeatureCollection(soilZones: ISoilZone[]): FeatureCollection<Geometry>
{
	const features: Feature<Geometry>[] = soilZones.map(zone =>
	{
		// Find the Overall NCCPI value
		const overallNccpi = zone.NccpiValues.find(n => n.CropName === CropName.Overall);
		
		return {
			type: 'Feature' as const,
			geometry: zone.Boundary,
			properties: {
				area: zone.Area,
				color: zone.Color,
				mukey: zone.Mukey,
				nccpi: {
					Overall: overallNccpi ? overallNccpi.Value : 0
				},
				soil_type: zone.ComponentName,
				soil_texture: zone.SoilTexture,
			}
		};
	});
	
	return {
		type: 'FeatureCollection' as const,
		features
	};
}

export const getSoilData = createTracedAsyncThunk<ISoilDataResponse, string, { dispatch: AppDispatch, state: RootState }>(
	'soilData/get',
	async (context, fieldId: string, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState() as RootState;
			
			// Create an instance of the Api class
			const api = new Api('/api/6', state.auth.userAuthToken, context);
			
			// Call the new API endpoint with the field ID
			const response = await api.getAsync<ISoilZone[]>(`fields/${fieldId}/soilZones`);
			
			if (!response.Success)
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage || 'Failed to fetch soil data');
			}
			
			// Convert the ISoilZone[] to a FeatureCollection<Geometry>
			const featureCollection = convertSoilZonesToFeatureCollection(response.Data);
			
			// Return in the format expected by the existing code
			return {
				data: featureCollection,
				success: true,
				error: null,
				errorCode: 0,
				nameSpace: null,
				message: null
			};
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISoilDataState
{
	isLoading: boolean;
	isError: boolean;
	errorMessage: string;
}

export const initialState: ISoilDataState = {
	isLoading: false,
	isError: false,
	errorMessage: undefined,
};

export const SoilDataSlice = createSlice({
	name: 'soildata',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getSoilData.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSoilData.fulfilled, (state) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSoilData.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Soil Data Boundaries. Please try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the Soil Data Boundaries. Please try again.';
			}
		});
	}
});

export const { clearState, clearError } = SoilDataSlice.actions;
