
export function downloadBlob(data: Blob, name: string) 
{
	if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1) 
	{ //iOS Chrome
		const typedBlob = new Blob([data], { type: 'application/pdf' });
		const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
		window.open(link);
	}
	else 
	{
		// Store this file as a hidden 'a' element and then automatically click it.
		const link = document.createElement('a');
		link.href = (window.URL || window['webkitURL']).createObjectURL(data);

		// The renderer always adds ".pdf" to the filename. If there's more than that, use it. Otherwise fall back on the session name.
		link.download = name;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}
