import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as LeftCaret } from '../../Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../../assets/images/Ellipse.svg';
import { ReactComponent as GreenIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as RedIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as YellowIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as ResetIcon } from '../../../../assets/images/Reset.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/images/Save.svg';
import { Button } from '../../../../components/Button/Button';
import { ICropBrandAssociation } from '../../ProductGamePlan/CropBrandAssociation';
import { CropTabBar } from '../../ProductGamePlan/CropTabBar';
import { IGrowerResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { IShipFromAddress } from '../../../../logic/Models/Responses/ShipFromAddress';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../../components/Dropdown/IOptionItem';
import { Tooltip } from 'antd';

const ShoppingCartHeaderContainer = styled.div`
	display: flex;
	width: 100%;
	height: 94%;
	padding-right: 5%;
	padding-left: 5%;
	flex-direction: column;
`;

const StyledDropdown = styled(Dropdown)`
	.ant-dropdown-menu-item {
		//width: 100px;
	}
`;

export interface IShoppingCartHeaderProps
{
	createType: string;
	cropTabs: ICropBrandAssociation[];
	currentRecommendation: ICropBrandAssociation;
	isGHX: boolean;
	selectedGrower: IGrowerResponse;
	addresses: IShipFromAddress[];
	selectedAddress: string;
	disableSaveAndConvert: boolean;
	disabledSaveTooltip: string;
	setCurrentRecommendation: (value: ICropBrandAssociation) => void;
	saveOrSend: (send: boolean) => void;
	setOpenResetModal: (value: boolean) => void;
	selectAddress: (value: string) => void;
}

export const ShoppingCartHeader = (props: IShoppingCartHeaderProps) =>
{
	const {
		addresses,
		createType,
		cropTabs,
		currentRecommendation,
		isGHX,
		selectedGrower,
		selectedAddress,
		disableSaveAndConvert,
		disabledSaveTooltip,
		setCurrentRecommendation,
		saveOrSend,
		setOpenResetModal,
		selectAddress,
	} = props;

	const theme = useTheme();

	const [addressOptions, setAddressOption] = useState<IOptionItem[]>([]);

	const backToPlanList = () =>
	{
		// Do things before going back to the Plan List?
	};

	const convertToTitleCase = (value: string):string =>
	{
		if (value)
		{
			return value.toLowerCase().replace(/\b\S/g, function(t) { return t.toUpperCase(); });
		}
		else
		{
			return '';
		}
	};

	useEffect(() =>
	{
		if (isGHX && addresses && addresses.length > 0)
		{
			const createAddressOptions = addresses.map(address =>
			{
				let addressOption: IOptionItem;
				if (address.City) //if address is not null then render it
				{
					const cityStateZip = `${convertToTitleCase(address.City)} ${address.StateCode?.toUpperCase()}, ${convertToTitleCase(address.PostalCode)}`;

					addressOption =
					{
						label: <div>
							<div>{convertToTitleCase(address.Name)}</div>
							<div>{convertToTitleCase(address.Street)}</div>
							<div>{cityStateZip}</div>
						</div>,
						value: address.Id
					};
				}
				else
				{
					addressOption = 
					{
						label: <div>{convertToTitleCase(address.Name)}</div>,
						value: address.Id
					};
				}
				return addressOption;
			});

			// If we have a length of 2, we have an address and Syngenta Direct
			// If we have a length of 1, we have just Syngenta Direct
			// In these cases we can just default to the address (length of 2), or Syngenta Direct (length of 1)
			// But if we have more than 2, we want the user to choose something before attempting to send
			if (createAddressOptions.length == 2 || createAddressOptions.length == 1)
			{
				selectAddress(createAddressOptions[0].value);
			}
			
			setAddressOption(createAddressOptions);
		}
		
	}, [addresses]);

	const onSelectAddress = (value: string) =>
	{
		selectAddress(value);
	};

	return (
		<div className='ShoppingCartHeader'>
			<div
				className='backTo_GrowerList_Container'
				style={{
					display: 'flex',
					height: 52,
					padding: '10px 25px',
					alignItems: 'center',
				}}
			>
				<Link to='/fieldactivities/recall' className='linkTo_PlanList' onClick={backToPlanList}>
					<LeftCaret style={{ marginRight: '20px' }} />
					<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>Proposal List</span>
				</Link>
				<Ellipse style={{ margin: '0 10px' }} />
				<span style={{
					fontWeight: theme.fontWeights.bold,
					fontSize: theme.fontSizes.large,
					fontFamily: theme.fonts.heading,
					width: '60%'
				}}>
					Create {createType}
				</span>
			</div>
			<ShoppingCartHeaderContainer>
				<div style={{ display: 'flex', alignItems: 'start', height: isGHX ? 90 : 'auto' }}>
					<div
						className='ShoppingCart_Title'
						style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large, fontFamily: theme.fonts.heading, }}
					>
						{selectedGrower?.Name} {createType}
					</div>
					{isGHX && <div style={{ width: 250, marginLeft: 20, marginTop: 4 }}>
						<div style={{ fontWeight: theme.fontWeights.bold }}>Ship From</div>
						<StyledDropdown
							variant='light'
							options={addressOptions}
							onSelect={onSelectAddress}
							selected={selectedAddress}
							placeholder='Choose a hub'
							className='ShipFrom_Dropdown'
						/>
					</div>}
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto', width: '50%' }}>
						<Button
							className='ShoppingCart_Reset_Button'
							style={{ marginRight: '1%', minWidth: 144 }}
							variant='outlined'
							onClick={() => setOpenResetModal(true)}
						>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>
								<ResetIcon style={{ position: 'relative', top: -1 }} fill={theme.colors.darkGrey}/>
								<span style={{ marginLeft: 'auto' }}>Reset Changes</span>
							</div>
						</Button>
						<Tooltip title={disableSaveAndConvert ? `${disabledSaveTooltip} to save.` : ''} placement='bottom'>
							<span style={{ marginRight: '1%', cursor: disableSaveAndConvert ? 'not-allowed' : 'pointer' }}>
								<Button
									className='ShoppingCart_Save_Button'
									style={{ minWidth: 144 }}
									variant='outlined'
									onClick={() => saveOrSend(false)}
									disabled={disableSaveAndConvert}
								>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>
										<SaveIcon style={{ position: 'relative', top: -1, opacity: disableSaveAndConvert ? 0.4 : 1 }} />
										<span style={{ marginLeft: 'auto' }}>Save Changes</span>
									</div>
								</Button>
							</span>
						</Tooltip>
						<Tooltip title={(!selectedAddress && isGHX) ? 'Please select a hub.' : 
							(disableSaveAndConvert ? 
								`${disabledSaveTooltip} to create ${createType === 'Order' ? 'an' : 'a'} ${createType.toLowerCase()}.` : '')} placement='bottom'>
							<span style={{ cursor: (!selectedAddress && isGHX) || disableSaveAndConvert ? 'not-allowed' : 'pointer' }}>
								<Button
									className='ShoppingCart_Send_Button'
									style={{ marginRight: '1%', width: 138 }}
									variant='reversed'
									onClick={() => saveOrSend(true)}
									disabled={(!selectedAddress && isGHX) || disableSaveAndConvert}
								>
									{`Convert to ${createType}`}
								</Button>
							</span>
						</Tooltip>
					</div>
				</div>
				<div style={{ marginTop: 30 }}>
					<div
						style={{
							alignSelf: 'center',
							fontWeight: theme.fontWeights.bold,
							fontSize: theme.fontSizes.large,
							fontFamily: theme.fonts.heading,
							marginBottom: 10
						}}
					>Seed List</div>
					<div
						className='ShoppingCart_Crop_Tabs'
						style={{
							display: 'flex',
							flexDirection: 'row',
							borderBottom: '1px solid ' + theme.colors.lightGrey
						}}
					>
						{
							(cropTabs && cropTabs.length > 0) && cropTabs?.map(cba => <CropTabBar
								tabWidth={'150px'}
								key={cba.cropId + cba.brandApplication}
								label={cba.title}
								selected={cba.cropId.toLowerCase() === currentRecommendation.cropId.toLowerCase() &&
									cba.brandApplication == currentRecommendation.brandApplication}
								onClick={() => setCurrentRecommendation(cba)} />)
						}
						<div
							style={{
								fontSize: theme.fontSizes.small,
								fontWeight: theme.fontWeights.bold,
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginLeft: 'auto'
							}}>
							<span style={{ marginRight: 10 }}>Availability:</span>
							<div style={{ fontSize: theme.fontSizes.extraSmall, display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
								<div style={{ marginRight: 15 }}>
									<GreenIcon style={{ fill: theme.colors.availabilityGreen, position: 'relative', top: 1 }} />
									<span style={{ marginLeft: 5 }}>Available</span>
								</div>
								<div style={{ marginRight: 15 }}>
									<YellowIcon style={{ fill: theme.colors.availabilityYellow, position: 'relative', top: 2 }} />
									<span style={{ marginLeft: 5 }}>Limited</span>
								</div>
								<div>
									<RedIcon style={{ fill: theme.colors.availabilityRed, position: 'relative', top: 1 }} />
									<span style={{ marginLeft: 5 }}>Backorder</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ShoppingCartHeaderContainer>
		</div>
	);
};