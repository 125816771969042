import React, { useCallback, useEffect, useState } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { ReactComponent as SortArrows } from './Icons/SortArrows.svg';
import { SortDirection } from '../../logic/Utility/Utils';

export type SortType = SortDirection | 'none';
type SortDictionary = { [id: string]: SortType };
export interface ITableHeaderProps extends React.HTMLAttributes<HTMLDivElement>
{
	headers: string[];
	// sortActions: sort method when clicking a column. hides sort arrows for any headers not present
	sortActions?: { [key: string]: (type: SortType) => void };
	initialSorts?: { [key: string]: SortType };
	// defaultSort: sort order to override none when no columns have sort direction
	defaultSort?: { headerName: string; direction: SortDirection };
	columnSpacing?: string;
	headerHeight?: string;
	backgroundColor?: string;
	textColor?: string;
	defaultArrowColor?: string; // change the default sort arrow color
}

// none > descending > ascending > none
const getNextSortOrder = (sort: SortType = 'none', reverse?: boolean): SortType =>
	((reverse ? {
		none: 'ascending',
		descending: 'none',
		ascending: 'descending'
	} : {
		none: 'descending',
		descending: 'ascending',
		ascending: 'none'
	})[sort]) as SortType;

interface IStyledHeaderProps
{
	columnSpacing?: string;
	headerHeight?: string;
	backgroundColor?: string;
	color?: string;
}

const StyledHeader = styled.div<IStyledHeaderProps>`
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: ${props => props.columnSpacing};
	justify-items: center;
	color: ${props => props.color ? props.color : themeGet('colors.darkGrey') };
	background-color: ${props => props.backgroundColor ? props.backgroundColor : themeGet('colors.lightestGrey') };
	box-shadow: 0 0 3px 0px ${themeGet('colors.lightGrey')};
	border-radius: 6px;
	height: ${props => props.headerHeight ? props.headerHeight : '57px'};
	padding-left: 36px;
	padding-right: 26px;
	margin: .3% .3%;
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.extraSmall')};
	align-items: center;
	overflow: auto;
	::-webkit-scrollbar {
		display: none;
	}
	&.padding-small {
		padding: 0 1.5%;
	}
`;

const HeaderContainer = styled.div`
    grid-auto-flow: column;
    display: inline-grid;
    align-items: center;
	text-align: center;
`;

const StyledHeaderCell = styled.div`
	display: inline-grid;
    justify-items: center;
	display: inline-grid;
	position: relative;
    grid-auto-flow: column;
	height: 100%;
    align-items: center;
	width: 80%;
`;

const StyledSortArrows = styled(SortArrows) <{ sort: SortType, rightspacing: string, defaultarrowcolor?: string }>`
	justify-self: end;
	#upArrow {
		fill: ${props => props.sort === 'ascending' ? 
		themeGet('colors.primary') 
		: 
		props.defaultarrowcolor ? props.defaultarrowcolor : themeGet('colors.darkGrey')};
	}
	#downArrow {
		fill: ${props => props.sort === 'descending' ? 
		themeGet('colors.primary') 
		:
		props.defaultarrowcolor ? props.defaultarrowcolor : themeGet('colors.darkGrey')};
	}
	@media (hover: none) {
		right: ${props => props.rightspacing};
	}
`;

export const TableHeader = (props: ITableHeaderProps) =>
{
	const {
		className,
		columnSpacing,
		defaultSort,
		headers = [],
		initialSorts = {},
		sortActions = {},
		headerHeight,
		backgroundColor,
		textColor,
		defaultArrowColor,
	} = props;
	const headersLength = headers.length;
	const initialKeys = Object.keys(initialSorts);
	const [columnSorts, setColumnSorts] = useState<SortDictionary>({
		...initialSorts
	});
	const [hoverSort, setHoverSort] = useState<string>();
	const [primarySort, setPrimarySort] = useState<string>(initialKeys.length ? initialKeys[0] : '');

	const onColumnClick = useCallback((key) =>
	{
		if (!sortActions[key])
		{
			return;
		}
		const defaultSortExists = !!defaultSort;
		const newSortOrder = getNextSortOrder(columnSorts[key], defaultSortExists && defaultSort.direction === 'ascending');
		if (newSortOrder === 'none' && defaultSortExists)
		{
			const { direction, headerName } = defaultSort;
			setColumnSorts({
				[headerName]: direction
			});
			setPrimarySort(headerName);
		}
		else
		{
			setColumnSorts({
				[key]: newSortOrder
			});
			setPrimarySort(key);
		}

	}, [columnSorts, sortActions]);

	useEffect(() =>
	{
		const sortAction = sortActions[primarySort];
		if (sortAction)
		{
			sortAction(columnSorts[primarySort]);
		}
	}, [columnSorts]);

	return (
		<StyledHeader 
			columnSpacing={columnSpacing}
			headerHeight={headerHeight}
			className={className}
			backgroundColor={backgroundColor}
			color={textColor}
		>
			{headers.map((header, index) =>
			{
				const isSortVisible = sortActions[header] && [primarySort, hoverSort].indexOf(header) > -1;
				const justifySelf = index ? 'center' : 'start';
				return <StyledHeaderCell id={`table-header-cell-${index}`} key={`column-${index}`}
					style={{ justifySelf }}
					onClick={() => onColumnClick(header)}
					onMouseOver={() => setHoverSort(header)}
					onMouseOut={() => setHoverSort('')} >
					<HeaderContainer style={{ justifySelf }}>{header}</HeaderContainer>
					<StyledSortArrows
						style={{
							visibility: isSortVisible ? 'visible' : 'hidden',
							display: isSortVisible === undefined ? 'none' : 'block'
						}}
						sort={columnSorts[header]}
						rightspacing={`-${headersLength * 3}px`}
						defaultarrowcolor={defaultArrowColor}
					/>
				</StyledHeaderCell>;
			})}
		</StyledHeader>
	);
};