import { useEffect, useMemo, useState } from 'react';
import { RootState } from '../../../logic/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { clone, cloneDeep, groupBy, keyBy, orderBy, uniq } from 'lodash';
import { setDerivedData } from '../../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import { ITreatment } from '../ProductGamePlan/Editor/SeriesProductRow';
import { AvailabilityEnum, IProductIdentifier } from './Logic/IProductReference';
import { ICropPlanFullResponse, ICropPlanSelectedProduct } from '../../../logic/Models/Responses/FieldPlanResponse';
import { CropConfig, soyId } from '../../../logic/store/Seeds/CropsSlice';
import { FieldPlanProductColorsList } from '../../../logic/Utility/Colors';
import { getSeedsForGrower } from '../../../logic/store/Seeds/SeedsActions';
import { getCustomTreatments } from '../../../logic/store/User/CustomTreatmentsActions';
import { getTreatmentPricing } from '../../../logic/store/Plans/ProductGamePlanActions';
import { CalculateBags, RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { useStable } from '../../../logic/Utility/useStable';

/**
 * Compare if two products are the same (by hybrid and treatment)
 */
export function matchProductIdentity(a: IProductIdentifier, b: IProductIdentifier): boolean 
{
	return a.HybridId === b.HybridId
		&& a.CustomTreatmentId === b.CustomTreatmentId
		&& a.TreatmentName === b.TreatmentName;
}

/**
 * Compare if a product selection matches a product identifier.
 */
export function matchAssignmentToProduct(selected: { HybridId?: string, Treatment?: string}, product: IProductIdentifier, defaultTreatment: string = ''): boolean
{
	return selected.HybridId === product.HybridId 
		&& ((product.CustomTreatmentId !== undefined && product.CustomTreatmentId === selected.Treatment) 
			|| product.TreatmentName === (selected.Treatment ?? defaultTreatment));
}

const emptyArray = [];
/**
 * This hook tracks crop plan data and assigns derived data -- the summary list, available products, which default products to use, etc.
 */
export const useUpdateFieldPlanProductDerivedData = () => 
{
	const dispatch = useDispatch();
	const cropPlan: ICropPlanFullResponse | undefined = useSelector((state: RootState) => state.fieldplan.fullCropPlanData?.find(plan => plan.Id === state.fieldplan.selectedCropPlanId));
	const cropId = cropPlan?.CropId;
	const brandApplication = cropPlan?.Settings?.BrandApplication;

	// Fetch all data we need to build our state if we don't have it already
	const seeds = useSelector((state: RootState) => state.seeds.allSeeds);
	const isLoadingSeeds = useSelector((state: RootState) => state.seeds.isLoading);
	useEffect(() => 
	{
		// Don't download seeds twice if we're already in the process of loading them
		if(!seeds?.length && !isLoadingSeeds)
		{
			dispatch(getSeedsForGrower());
		}
	}, [seeds?.length, dispatch]);

	const treatments = useStable(useSelector((state: RootState) => state.productGamePlan.treatments));
	useEffect(() => 
	{
		if(!Object.entries(treatments).flatMap(t => t[1]).length)
		{
			dispatch(getTreatmentPricing());
		}
	}, [dispatch, treatments]);

	// We only need to fetch in the case that this is soybean and we don't already have the custom treatments loaded.
	const customTreatments = useSelector((state: RootState) => cropPlan?.CropId === soyId ? state.customTreatments.treatments : emptyArray);
	useEffect(() => 
	{
		if(customTreatments === undefined)
		{
			dispatch(getCustomTreatments());
		}
	}, [customTreatments, dispatch]);

	// TODO: When we add treatment selection, this should pay attention to a user's actually chosen default treatment.
	// For now this is a bugfix for the acre calculation
	const defaultTreatmentName = useMemo(() => 
	{
		const cropTreatments = treatments[cropId] as (ITreatment[] | undefined);
		if(!cropTreatments?.length)
		{
			return '';
		}
		return cropTreatments[0].Name;
	}, [cropId, treatments]);

	// calculate all available products
	// Get a list of all products in consideration at the 'top' level (crop and brand)
	const products = useMemo(() => (!cropId || !brandApplication || !treatments[cropId] || !seeds) ? [] : seeds.find(group => group.CropId.toLowerCase() === cropId.toLowerCase())
		?.BrandApplications.find(ba => ba.BrandApplication.toLowerCase() === brandApplication.toLowerCase())
		?.Hybrids.flatMap(hybrid => 
		{
			const cropTreatments = clone(treatments[cropId] as ITreatment[] | undefined);
			cropTreatments.push({ Name: '', Price: 0, CustomTreatmentId: undefined, IsActive: true });

			// Cross-combine with available treatments
			const productsWithBuiltInTreatments = cropTreatments
				.filter(t => t.IsActive)
				.map<IProductIdentifier>(t => ({
					CropId: cropId,
					SeriesId: hybrid.SeriesId,
					SeriesName: hybrid.SeriesName,
					HybridId: hybrid.Id,
					HybridName: hybrid.Name,
					TraitId: hybrid.TraitId,
					TraitName: hybrid.TraitName,
					TraitFullName: hybrid.TraitFullName,
					TreatmentName: t.Name,
					CustomTreatmentId: undefined,
					Availability: hybrid.Availability as AvailabilityEnum,
					RelativeMaturity: parseFloat(hybrid.RelativeMaturity)
				}));

			const productsWithCustomTreatments = (customTreatments ?? emptyArray).filter(ct => !ct.IsDeleted).map<IProductIdentifier>(ct => ({
				CropId: cropId,
				SeriesId: hybrid.SeriesId,
				SeriesName: hybrid.SeriesName,
				HybridId: hybrid.Id,
				HybridName: hybrid.Name,
				TraitId: hybrid.TraitId,
				TraitName: hybrid.TraitName,
				TraitFullName: hybrid.TraitFullName,
				TreatmentName: ct.Name,
				CustomTreatmentId: ct.Id,
				Availability: hybrid.Availability as AvailabilityEnum,
				RelativeMaturity: parseFloat(hybrid.RelativeMaturity)
			}));

			return [...productsWithBuiltInTreatments, ...productsWithCustomTreatments];
		}) ?? [],
	[seeds, cropId, brandApplication, treatments, customTreatments]);

	// This is a map of series to the product that should be used if the series is selected.
	const recommendedProducts = useMemo(() => 
	{
		if (!cropPlan) 
		{
			return {};
		}

		return keyBy(cropPlan.SelectedProducts.map<IProductIdentifier | undefined>(
			// Map to our loaded products 
			// TODO: Most expensive & available. 99% of the time this is fine
			// because the user only picks one version through the UI anyway.
			sp => products.find(p => matchAssignmentToProduct(sp, p))
			// And key by series
		).filter(p => p !== undefined) as IProductIdentifier[], p => p.SeriesId);
	}, [cropPlan, products]);

	// Create the summary (aggregate totals) of the current seed set.
	const productSummary = useMemo(() => 
	{
		if (!cropPlan || !products.length) 
		{
			return [];
		}

		// We need to combine:
		// Products that are assigned to fields already
		const productsWithAssignments = cropPlan.Fields.flatMap(f => f.SeedAssignments);

		// The most expensive product (TODO!) for any series portfolio that doesn't already have one.
		const alreadyAssignedSeries = uniq(productsWithAssignments.map(sa => products.find(p => matchAssignmentToProduct(sa, p, defaultTreatmentName)).SeriesId));
		const recommendedSeriesNotAssigned = cropPlan.Portfolio.filter(seed => !alreadyAssignedSeries.includes(seed.SeriesId));
		const productsForUnrecommendedSeries = recommendedSeriesNotAssigned.map(series => recommendedProducts[series.SeriesId]);

		// Combine the products recommended per series and the products that are specifically assigned.
		const allRelevantProductAssignments = [
			...productsWithAssignments.filter(p => p.HybridId).map(pa => ({
				product: products.find(p => matchAssignmentToProduct(pa, p)),
				area: pa.AppliedAcres,
				rate: pa.Rate
			})),
			...productsForUnrecommendedSeries.map(p => ({
				product: p,
				area: 0,
				rate: 0
			}))
		].filter(p => p.product);

		const groupedProducts = groupBy(allRelevantProductAssignments, p => `${p.product!.HybridId}-${p.product!.TreatmentName?.length ? p.product!.TreatmentName : defaultTreatmentName}-${p.product!.CustomTreatmentId}`);

		return Object.values(groupedProducts).map(ap => ({
			product: ap[0].product!,
			color: '',
			area: RoundAcres(ap.reduce((prev, cur) => cur.area + prev, 0)),
			quantity: ap.reduce((prev, cur) => (cur.rate * cur.area) + prev, 0)
		}));

	}, [cropPlan, products, recommendedProducts, defaultTreatmentName]);

	// We store overall summary and update it instead of using the calculated version above so that we can persist certain things,
	// like the assigned color.
	const [summary, setSummary] = useState<typeof productSummary>([]);
	useEffect(() => 
	{
		setSummary([]);
	}, [cropId]);
	let changedSummary = false;
	let updatedSummary = cloneDeep(summary);

	// Compare our just-calculated summary data to our stored one to see if we need to update it
	const priorLength = updatedSummary.length;
	const cropColorList = FieldPlanProductColorsList[cropId];
	for (const productSummarized of productSummary) 
	{
		const unusedColors = cropColorList.filter(color => !updatedSummary.map(p => p.color).includes(color));
		const existingSummary = updatedSummary.find(s => matchProductIdentity(s.product, productSummarized.product));
		const calculatedQuantity = (!productSummarized.area) ? 0 : CalculateBags(productSummarized.quantity, CropConfig()[cropId].seedsPerBag);
		if (!existingSummary) 
		{
			// Not in our list yet, create a new entry for it
			const nextAvailableColor = unusedColors.length ? unusedColors[0] : cropColorList[(updatedSummary.length) % cropColorList.length];
			updatedSummary.push(
				{
					product: productSummarized.product,
					color: nextAvailableColor,
					area: productSummarized.area,
					quantity: calculatedQuantity
				}
			);
			changedSummary = true;
		}
		else if (existingSummary.area !== productSummarized.area || existingSummary.quantity !== calculatedQuantity) 
		{
			// Update the area on the existing entry
			existingSummary.area = productSummarized.area;
			existingSummary.quantity = calculatedQuantity;
			changedSummary = true;
		}
	}

	// We also need to remove anything that no longer has assignments
	updatedSummary = updatedSummary.filter((summary) => productSummary.find(ps => ps.product.HybridId === summary.product.HybridId
		&& ps.product.CustomTreatmentId === summary.product.CustomTreatmentId
		&& ps.product.TreatmentName === summary.product.TreatmentName)
	);
	
	if (updatedSummary.length != priorLength || changedSummary) 
	{
		// Something changed, store the result.
		setSummary(orderBy(updatedSummary, s => -s.area));
	}

	const derivedData = useMemo(() => ({
		products,
		recommendedProducts,
		summary
	}), [products, recommendedProducts, summary]);

	useEffect(() => 
	{
		// When something changes, update the redux store.
		dispatch(setDerivedData(derivedData));
	}, [derivedData, dispatch]);

	return derivedData;
};
