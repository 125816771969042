import { useMemo } from 'react';
import { RootState } from '../../../logic/store/Store';
import { useSelector } from 'react-redux';
import { EventCropPlanTags, EventGrowerTags, EventPlanTags, EventSelectionTags, EventTags } from '../../../tracing/EventTagNames';

export function useProductGamePlanTrackingState() 
{
	const selectedGrower = useSelector((state: RootState) => state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId));
	const selectedProductPlan = useSelector((state: RootState) => state.productGamePlan.selectedGamePlanId);

	const trackingState = useMemo<EventTags>(() => ({

		[EventSelectionTags.GrowerId]: selectedGrower?.Id,
		[EventGrowerTags.GrowerName]: selectedGrower?.Name,
		[EventGrowerTags.GrowerSalesforceId]: selectedGrower?.SalesforceId,
		[EventGrowerTags.GrowerFoundationId]: selectedGrower?.FoundationId,

		[EventSelectionTags.PlanId]: selectedProductPlan,
		[EventPlanTags.PlanType]: 'Acre',

	}), [selectedProductPlan, selectedGrower]);
	return trackingState;
}
