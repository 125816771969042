import { themeGet } from '@styled-system/theme-get';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { Input } from 'antd';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { IEnogenContractResponse } from '../../../logic/Models/Responses/EnogenContractResponse';
import { IResendForSigningRequest, ResendDeliveryType } from '../../../logic/store/Grower/EnogenContractThunk';

export interface IEnogenContractResendModalProps
{
	resendContract: IEnogenContractResponse;
	visible: boolean;
	onCancel: () => void;
	onSave: (request: IResendForSigningRequest) => void;
}

const InputTextField = styled(Input)`
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
		box-shadow: 0 0 1pt 1pt white;
		outline: none;
	}
	::selection {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
`;

export const EnogenContractResendModal = (props: IEnogenContractResendModalProps) =>
{
	const { resendContract, visible, onCancel, onSave } = props;

	const theme = useTheme();

	const [contactType, setContactType] = useState<string>(undefined);
	const [contactValue, setContactValue] = useState<string>(undefined);

	const options: IOptionItem[] =
	[
		{
			value: ResendDeliveryType.Email,
			label: 'Email'
		},
		{
			value: ResendDeliveryType.Phone,
			label: 'Phone'
		}
	];

	useEffect(() =>
	{
		setContactType(undefined);
		setContactValue(undefined);
	},[resendContract]);

	useEffect(() =>
	{
		if (!resendContract)
		{
			return;
		}

		let currentContactType = contactType;
		if (!currentContactType)
		{
			currentContactType = resendContract?.GrowerDetails.Email ? ResendDeliveryType.Email : ResendDeliveryType.Phone;
			setContactType(currentContactType);
		}

		if (currentContactType === ResendDeliveryType.Email)
		{
			setContactValue(resendContract?.GrowerDetails?.Email ?? '');
		}
		else
		{
			setContactValue(resendContract?.GrowerDetails?.Phone ?? '');
		}
	},[contactType, resendContract]);

	/**
	 * Checks if a string is a valid email
	 * Returns true for success and false for failure
	 */
	const validateEmail = (): boolean =>
	{
		if (contactValue)
		{
			const regex: RegExp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
			return regex.test(contactValue);
		}
		else
		{
			return false;
		}
	};

	/**
	 * Checks if a phone is 10 or 11 characters removing any extraneous -'s or .'s
	 * Returns true for success and false for failure
	 */
	const validatePhone = (): boolean =>
	{
		if (contactValue)
		{
			return contactValue.match(/\d/g)?.length===10 || contactValue.match(/\d/g)?.length===11;
		}
		else
		{
			return false;
		}
	};

	const inputBorderColor = (): string =>
	{
		if (contactType === ResendDeliveryType.Email)
		{
			return validateEmail() ? theme.colors.lightestGrey : theme.colors.availabilityRed;
		}
		else
		{
			return validatePhone() ? theme.colors.lightestGrey : theme.colors.availabilityRed;
		}
	};

	const resendDisabled: boolean = useMemo(() =>
	{
		let isDisabled = false;
		if (!resendContract)
		{
			return isDisabled;
		}

		if (contactType === ResendDeliveryType.Email)
		{
			isDisabled = !validateEmail();
		}

		if (contactType === ResendDeliveryType.Phone)
		{
			isDisabled = !validatePhone();
		}

		return isDisabled;
	},[contactType, contactValue, validateEmail]);

	const sendNotification = () =>
	{
		const request: IResendForSigningRequest = 
		{
			DeliveryType: contactType,
			Phone: contactType === ResendDeliveryType.Phone ? contactValue : '',
			Email: contactType === ResendDeliveryType.Email ? contactValue : '',
		};
		onSave(request);
	};

	return (
		<StyledModal
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>{'Resend for Signing'}</div>
			}
			open={visible}
			width={750}
			closable={true}
			onCancel={onCancel}
			destroyOnClose={true}
		>
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: -30 }}>
				<span>{'This contract will be sent to the following people to be signed'}</span>
				<div className='ResendModal_TableContainer' style={{ marginTop: 10, marginBottom: 30 }}>
					<div className='ResendModal_Header' style={{ display: 'flex', flexDirection: 'row', fontWeight: theme.fontWeights.bold }}>
						<div style={{ width: '10%' }}>
								Role
						</div>
						<div style={{ width: '25%' }}>
								Name
						</div>
						<div style={{ width: '30%' }}>
								Method of Contact
						</div>
						<div>
								Delivery Choice
						</div>
					</div>
					<div className='ResendModal_GrowerRowContainer' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div style={{ width: '10%' }}>
							Grower
						</div>
						<div style={{ width: '25%' }}>
							{`${resendContract?.GrowerDetails?.FirstName} ${resendContract?.GrowerDetails?.LastName}`}
						</div>
						<div style={{ width: '30%' }}>
							<InputTextField
								style={{
									fontSize: theme.fontSizes.small,
									width: '95%',
									borderColor: inputBorderColor()
								}}
								value={contactValue}
								onChange={(evt: React.FocusEvent<HTMLInputElement>) => setContactValue(evt.currentTarget.value)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => setContactValue((evt.target as HTMLInputElement).value)}
							/>
						</div>
						<div style={{ width: '35%' }}>
							<Dropdown
								variant='outlinednarrownopad'
								options={options}
								onSelect={setContactType}
								selected={contactType}
								className='ContactType_Dropdown'
							/>
						</div>
					</div>
				</div>
				<div className='ReplantOrLoss_ButtonContainer' style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
					<Button variant='outlined' width={'120px'} style={{ marginRight: 10 }} onClick={onCancel}>Cancel</Button>
					<Button
						variant='main'
						onClick={sendNotification}
						style={{ paddingLeft: 15, paddingRight: 15 }}
						disabled={resendDisabled}
					>
						Resend Notification
					</Button>
				</div>
			</div>
		</StyledModal>
	);
};