import worker from 'workerize-loader!./worker';

export const logDispatchWorker = worker(); // Attach an event listener to receive messages from your worker

// Receive errors from the woker and display them
logDispatchWorker.addEventListener('message', (message) => 
{
	if (typeof message.data == 'object' && message.data.type === 'log') 
	{
		console.error('New error from the background log dispatch worker:', { message: message.data.message, data: message.data.data });
	}
});
