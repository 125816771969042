import { ProductPlanCropType, SeedListBrandApplication } from '../../../logic/Models/Responses/ProductGamePlan';
import { cornId, soyId } from '../../../logic/store/Seeds/CropsSlice';

export interface ICropBrandAssociation
{
	cropId: string;
	cropName: string;
	brandApplication: SeedListBrandApplication;
	cropType: ProductPlanCropType;
	title: string;
	ordinal: number;
}

/**
 * The supported crop+brand combinations with a title for each.
 * 0 = GH/NK corn, 1 = GH/NK soybean, 2 = Enogen corn
 */
export const cropBrandAssociations: ICropBrandAssociation[] = [
	{
		cropId: cornId,
		cropName: 'Corn',
		brandApplication: 'Any',
		cropType: 'GH,NK',
		title: 'Corn',
		ordinal: 0,
	},
	{
		cropId: soyId,
		cropName: 'Soybean',
		cropType: 'GH,NK',
		brandApplication: 'Any',
		title: 'Soybeans',
		ordinal: 1,
	},
	{
		cropId: cornId,
		cropName: 'Corn',
		cropType: 'Enogen',
		brandApplication: 'Enogen',
		title: 'Enogen',
		ordinal: 2,
	},
];
