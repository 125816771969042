import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ScaleLoader } from 'react-spinners';

export type Variants = 'dark' | 'light';
interface ILoadingSpinnerProps extends React.HTMLAttributes<HTMLDivElement>
{
	children: ReactNode;
	loading: boolean;
	variant?: Variants;
}

const backGroundVariant = (variant: Variants) => ({
	dark: themeGet('colors.darkGrey'),
	light: themeGet('colors.white')
})[variant];

const colorVariant = (variant: Variants) => ({
	dark: themeGet('colors.white'),
	light: themeGet('colors.darkGrey')
})[variant];

export const StyledLoadingContainer = styled.div <{ variant: Variants }>`
	margin: 5px;
	width: 450px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${props => backGroundVariant(props.variant)};
	color: ${props => colorVariant(props.variant)};
	border-radius: 15px;
	padding: 50px;
	min-height: 150px;
`;

export const StyledSpinnerSections = styled.div`
	margin: 4px;
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
	color: white;

	.ant-progress-text {
		color: white;
	}
`;

/**
 * Spinner for the loading
 */

/** @component */
export const LoadingSpinner = (props: ILoadingSpinnerProps) =>
{
	const { children, loading, variant = 'dark' } = props;

	const theme = useTheme();

	if (!loading) return null;

	return (
		<StyledLoadingContainer variant={variant} style={props.style}>
			<StyledSpinnerSections>
				{children}
			</StyledSpinnerSections>
			<StyledSpinnerSections>
				<ScaleLoader color={theme.colors.primary} loading={loading} />
			</StyledSpinnerSections>
		</StyledLoadingContainer>
	);
};

