'use strict';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Api } from '../../../logic/Api/Api';
import { RootState } from '../../../logic/store/Store';
import { IFarmResponse } from '../../../logic/Models/Responses/FarmResponse';
import { SelectionState, ICrossReferencedGrower } from './DataTransferTroubleshootingTool';
import { globalSession } from '../../../tracing/session';

/**
 * Utility hook for transferring grower data
 * @param source The source grower state to copy from
 * @param dest The destination grower state to copy to
 * @param transferAll Whether to copy all growers or just a selected one
 * @param crossReferencePairings A matching of growers in source to growers in dest to copy in case of transferAll
 * @param startLoading Method to call when data is starting to load
 * @param endLoading Method to call when data completes loading
 * @returns 
 */
export const useTransfer = (source: SelectionState, dest: SelectionState, transferAll: boolean, crossReferencePairings: Record<string, ICrossReferencedGrower>, startLoading: () => unknown, endLoading: () => unknown, onCompleted: () => unknown, incrementProgress: (reset?:boolean) => unknown, addExpected: (count:number) => unknown) => 
{
	const userAuthToken = useSelector((state: RootState) => state.auth.userAuthToken);
	const userId = useSelector((state: RootState) => state.userInfo.UserId);
	const api = useMemo(() => new Api('/api/4', userAuthToken, undefined), [userAuthToken]);

	const innerExecute = useCallback(async function (fromGrowerId: string, toGrowerId: string) 
	{
		try 
		{
			await api.putAsync<IFarmResponse[]>('/supporttools/copy/farms', {
				authorizedUserId: userId,
				fromGrowerIds: [fromGrowerId],
				toGrowerId: toGrowerId
			});
		}
		catch (err) 
		{
			globalSession.error(err);
		}
	}, [api, userId]);

	// transfer just the one grower's data
	const executeSingle = useCallback(async function (fromGrowerId: string, toGrowerId: string) 
	{
		startLoading();
		
		try
		{
			await innerExecute(fromGrowerId, toGrowerId);
		}
		finally 
		{
			onCompleted();
			endLoading();
		}
	}, [innerExecute, startLoading, endLoading]);

	const executeAll = useCallback(async function () 
	{
		startLoading();
		incrementProgress(true);
		addExpected(Object.values(crossReferencePairings).length);

		try 
		{
			for (const item of Object.values(crossReferencePairings)) 
			{
				await innerExecute(item.sourceGrowerId, item.destGrowerId);
				incrementProgress(false);
			}
		}
		catch (err) 
		{
			globalSession.error(err);
		}

		finally 
		{
			onCompleted();
			endLoading();
		}
	}, [crossReferencePairings, innerExecute, startLoading, endLoading, incrementProgress, addExpected]);

	const transfer = useCallback(() => 
	{

		if (transferAll) 
		{
			executeAll();
		}

		else 
		{
			executeSingle(source.selectedGrower.Id, dest.selectedGrower.Id);
		}
	}, [transferAll, executeSingle, executeAll, source.selectedGrower?.Id, dest.selectedGrower?.Id]);

	return transfer;
};
