import {  } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { IProductGamePlanDetails } from '../../Models/Responses/ProductGamePlan';
import { getCurrentActingUser } from '../User/AuthSlice';
import { saveProductPlan } from './ProductGamePlanUpdateActions';
import { cornId, soyId } from '../Seeds/CropsSlice';
import { ITreatment } from '../../../pages/FieldActivities/ProductGamePlan/Editor/SeriesProductRow';
import { IPlanDownloadRequest } from '../../Models/Requests/PlanRequest';
import { IFieldlessAllocationResponse, IFieldlessAllocationItemResponse, IFieldlessRankingSeed } from '../../Models/Responses/FieldlessSeedRankingResponse';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { downloadPlanPdf } from '../Grower/PlanThunks';

interface ICreateProductGamePlanRequest
{
	// cover page config
	coverConfig: IPlanDownloadRequest;
	// Set to true if a user tries to print the PDF before saving the plan
	printPDF: boolean;
}

/**
 * Create a new product plan with default data
 */
export const createProductGamePlan = createTracedAsyncThunk<string, ICreateProductGamePlanRequest, { dispatch: AppDispatch; state: RootState; }>(
	'productGamePlan/create',
	async (context, request: ICreateProductGamePlanRequest, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const { auth, crops, ui } = currentState;
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.postAsync<string>(
				`growers/${ui.SelectedGrowerId}/years/${crops.CropYear}/productplans`, {}
			);

			if (response.ErrorCode === null && response.Success)
			{
				const parts = response.Data.split('/');

				const planId = parts[parts.length - 1];

				// Update the plan Id for the request.
				request.coverConfig.PlanId = planId;

				await thunkAPI.dispatch(saveProductPlan({ request: request.coverConfig, printPDF: false }));

				if (request.printPDF)
				{
					await thunkAPI.dispatch(downloadPlanPdf(request.coverConfig));
				}

				return planId;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

/**
 * Download a specific identified product plan
 */
export const getProductGamePlan = 
createTracedAsyncThunk<IProductGamePlanDetails & { defaultTreatmentName: string; growerZip: string; }, { planId: string; defaultTreatmentName: string; }, { dispatch: AppDispatch; state: RootState; }>(
	'productGamePlan/get',
	async (context, { planId, defaultTreatmentName }, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const selectedGrowerId = currentState.ui.SelectedGrowerId;
			let growerZip = '';
			if (selectedGrowerId)
			{
				const selectedGrower = currentState.grower.Growers.find(g => g.Id === selectedGrowerId);
				growerZip = selectedGrower?.Zip;
			}
			const api = new Api('/api/6', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<IProductGamePlanDetails>(`productplans/${planId}`);

			if (response.ErrorCode === null && response.Success)
			{
				return { ...response.Data, defaultTreatmentName, growerZip };
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ITreatmentDictionary
{
	[cornId]: ITreatment[];
	[soyId]: ITreatment[];
}

export const getTreatmentPricing = createTracedAsyncThunk<ITreatmentDictionary, null, { dispatch: AppDispatch; state: RootState; }>(
	'treatmentPricing/get',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const currentState = thunkAPI.getState();
			const api = new Api('/api/6', currentState.auth.userAuthToken, context);
			const response = await api.getAsync<ITreatmentDictionary>('pricing/treatments');

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IFieldlessSeedRankingsRequest
{
	acres: number;
	cropAssociation: string;
	consistencyPerf: number;
	cropId: string;
	enogenOnly: boolean;
	fungicide: boolean;
	irrigated: boolean;
	isFilterApplied: boolean;
	rmMax: number;
	rmMin: number;
	seedsRequested?: number;
	selectedTraits: string[];
	topEndYield: number;
	zipCode: string;
}

export const getFieldlessSeedAllocations = createTracedAsyncThunk<IFieldlessAllocationResponse, IFieldlessSeedRankingsRequest, { dispatch: AppDispatch; state: RootState; }>(
	'fieldlessallocations/get',
	async (context, request: IFieldlessSeedRankingsRequest, thunkAPI) =>
	{
		try
		{
			const { 
				acres,
				cropId,
				enogenOnly,
				irrigated,
				seedsRequested,
				fungicide,
				isFilterApplied,
				rmMax,
				rmMin,
				selectedTraits,
				topEndYield,
				consistencyPerf,
				zipCode
			} = request;
			const currentState = thunkAPI.getState();
			const currentUser = getCurrentActingUser(currentState);
			const api = new Api('/api/6', currentState.auth.userAuthToken, context);
			const rankingRequest = 
			{
				'Acres': acres,
				'ConsistencyPerf': consistencyPerf,
				'EnogenOnly': enogenOnly,
				'Fungicide': fungicide,
				'Irrigated': irrigated,
				'IsFilterApplied': isFilterApplied,
				'RmMax': rmMax,
				'RmMin': rmMin,
				'SelectedTraits': selectedTraits,
				'TopEndYield': topEndYield,
				'TotalRequestedSeeds': seedsRequested,
			};
			const response = await api.postAsync<IFieldlessAllocationItemResponse[]>(
				`users/${currentUser.UserId}/crops/${cropId}/zipCodes/${zipCode}/recommendation`, rankingRequest);

			if (response.ErrorCode === null && response.Success)
			{
				return {
					IsEnogen: enogenOnly,
					Items: response.Data
				};
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getFieldlessSeedRankings = createTracedAsyncThunk<{ cropAssociation: string, rankings: IFieldlessRankingSeed[] }, IFieldlessSeedRankingsRequest, { dispatch: AppDispatch; state: RootState; }>(
	'fieldlessrankings/get',
	async (context, request: IFieldlessSeedRankingsRequest, thunkAPI) =>
	{
		try
		{
			const {
				acres,
				cropId,
				cropAssociation,
				enogenOnly,
				irrigated,
				isFilterApplied,
				fungicide,
				rmMax,
				rmMin,
				selectedTraits,
				topEndYield,
				consistencyPerf,
				zipCode
			} = request;
			const currentState = thunkAPI.getState();
			const currentUser = getCurrentActingUser(currentState);
			const api = new Api('/api/6', currentState.auth.userAuthToken, context);
			const rankingRequest = 
			{
				'Acres': acres,
				'ConsistencyPerf': consistencyPerf,
				'EnogenOnly': enogenOnly,
				'Fungicide': fungicide,
				'Irrigated': irrigated,
				'IsFilterApplied': isFilterApplied,
				'RmMax': rmMax,
				'RmMin': rmMin,
				'SelectedTraits': selectedTraits,
				'TopEndYield': topEndYield,
			};
			const response = await api.postAsync<IFieldlessRankingSeed[]>(
				`users/${currentUser.UserId}/crops/${cropId}/zipCodes/${zipCode}/filterallseeds`, rankingRequest);

			if (response.ErrorCode === null && response.Success)
			{
				return { cropAssociation: cropAssociation, rankings: response.Data };
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);