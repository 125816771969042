import { Card, Form, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/Button/Button';
import { IInvitationTemplateProjection } from '../../logic/Models/Responses/UserAdmin/IInvitationTemplateProjection';
import { createEmailTemplate, deleteEmailTemplate, saveEmailTemplate } from '../../logic/store/UserAdmin/UserAdminThunks';

const { TextArea } = Input;

export function EmailTemplateView(props: {invitationTemplates: IInvitationTemplateProjection[] | undefined})
{
	const { invitationTemplates } = props;
	const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	// Safely find the selected template only if invitationTemplates is defined
	const selectedTemplate = invitationTemplates.find(i => i.Id === selectedTemplateId);

	// Update form fields when a new template is selected
	useEffect(() => 
	{
		if(selectedTemplate)
		{
			form.setFieldsValue({
				Name: selectedTemplate.Name,
				Subject: selectedTemplate.Subject,
				Content: selectedTemplate.Content
			});
		}
	}, [form, selectedTemplate]);

	// Callback for saving form data (i.e., updating the selected template)
	const onFinish = useCallback((values: Partial<IInvitationTemplateProjection>) => 
	{
		if(!selectedTemplate)
		{
			return;
		}

		// Construct the updated template object
		const updatedValues = {
			Id: selectedTemplate.Id,
			Name: values.Name ?? selectedTemplate.Name,
			Subject: values.Subject ?? selectedTemplate.Subject,
			Content: values.Content ?? selectedTemplate.Content
		};
		
		// Dispatch save action to Redux store
		dispatch(saveEmailTemplate(updatedValues));
	}, [form, selectedTemplate]);

	// Create a brand new template
	const addNew = useCallback(async () => 
	{	
		// Dispatch create action to Redux store
		const newEntry = await dispatch(createEmailTemplate({
			Name: 'New Template',
			Subject: '',
			Content: ''
		})) as any;

		// Automatically select the newly created template
		if (newEntry?.payload?.Id) 
		{
			setSelectedTemplateId(newEntry.payload.Id);
		}
	}, []);

	
	// Delete the currently selected template
	const deleteExisting = useCallback(async () => 
	{
		if(selectedTemplateId)
		{
			await dispatch(deleteEmailTemplate(selectedTemplateId));
			setSelectedTemplateId(undefined); // Clear selection
		}
	}, [selectedTemplateId]);
	
	return <React.Fragment>
		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', flexGrow: 1, height: '100%'}}>
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 0, flexBasis: 250, padding: 16}}>
				<h3>Templates:</h3>
				{/* list here */}
				<div style={{flexGrow:1, overflow: 'auto', marginTop: 8}}>
					{invitationTemplates?.map(it => <Card 
						hoverable 
						size='small' 
						key={it.Id} 
						style={{
							marginTop: 8, 
							width: '100%', 
							color: selectedTemplateId === it.Id ? 'white' : 'black', 
							backgroundColor: selectedTemplateId === it.Id ? 'black' : 'white'
						}}
						onClick={() => setSelectedTemplateId(it.Id)}
					>
						{it.Name}
					</Card>)}
				</div>
				<div  style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-end', marginTop: 16}}>
					<Button fullWidth variant='outlined' onClick={addNew}>
						Add New
					</Button>
				</div>
			</div>
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 1, flexBasis: 0, padding: 16}}>
				{/* content here */}
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow:1, overflow: 'auto', marginTop: 8}}>
					{selectedTemplate && <Form 
						form={form}
						key={selectedTemplateId} 
						labelAlign='left' 
						labelCol={{ span: 4 }} 
						wrapperCol={{ span: 20 }}
						onFinish={onFinish}
						style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}
					>
						<Form.Item 
							name='Name'
							label='Name'
							style={{ marginTop: 8}} >
							<Input 
								placeholder='Name'/>
						</Form.Item>
						<Form.Item 
							name='Subject'
							label='Subject'
							style={{ marginTop: 8}}>
							<Input 
								placeholder='Subject' />
						</Form.Item>
						<Form.Item 
							name='Content'
							label='Content (html)' 
							style={{marginTop: 8}}>
							<TextArea placeholder='Email Content' 
								style={{minHeight: 400}} />
						</Form.Item>
						<div style={{flexGrow: 1}} />
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-end', marginTop: 16}}>
							<Button type='button' onClick={deleteExisting} variant='outlined' style={{minWidth: 250}}>Delete</Button>
							<Button variant='main' type='submit' style={{minWidth: 250, marginLeft: 8}}>Save Changes</Button>
						</div>
					</Form>
					}
				</div>
			</div>
		</div>
	</React.Fragment>;
}