import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import { AutoComplete } from 'antd';

type Variants = 'outlined' | 'dark' | 'light';
interface IAutoCompleteProps
{
	label?: ReactNode;
	labelVariant?: Variants;
	variant?: Variants;
}

const getColor = (variant: string) => ({ outlined: 'darkGrey', dark: 'white', light: 'darkGrey' }[variant]);
const getBgColor = (variant: string) => ({ outlined: 'white', dark: 'darkGrey', light: 'white' }[variant]);

const StyledAutoCompleteContainer = styled.div<IAutoCompleteProps>`
	display: flex;
	color: ${props => props.theme.colors[getBgColor(props.labelVariant)]};
	flex-direction: column;
	padding-top: ${props => props.label ? '15px' : ''};
`;
const StyledAutoCompleteBase = styled(AutoComplete)<IAutoCompleteProps>`	
	color: ${props => props.theme.colors[getColor(props.variant)]};
	background-color: ${props => props.theme.colors[getBgColor(props.variant)] || 'transparent'};
	--antd-wave-shadow-color: none;
	font-size: 16px;
	::selection {
		background: none !important;
	}
	&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color:  ${props => props.theme.colors[getColor(props.variant)]};
		border-width: 2px;
		border-right-width: 2px !important;
		border-style: solid;
		border-radius: 4px;
		--antd-wave-shadow-color: none;
		box-shadow: none;
	}
	&.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color:  ${props => props.theme.colors[getColor(props.variant)]};
		border-width: 2px;
		border-style: solid;
		border-radius: 4px;
	}
	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color:  ${props => props.theme.colors[getColor(props.variant)]};
		border-width: 2px;
		border-style: solid;
		border-radius: 4px;
	}
	&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
		height: 2.9em;
		font-weight: bold;
	}
	&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 2.9em;
		font-weight: bold;
	}
	&.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 2.8em;
		color: ${props => props.theme.colors.mediumGrey};
		position: relative;
		left: -6px;
	}
	&.ant-select-single .ant-select-selector .ant-select-selection-search {
		top: -2px;
		left: 4px;
	}
`;

/**
 * Input component
 */

/** @component */
export const StyledAutoComplete = (props: IAutoCompleteProps & any) =>
{
	const { label, labelVariant = 'light', variant = 'dark', ...rest } = props;

	const theme = useTheme();

	return (
		<StyledAutoCompleteContainer labelVariant={labelVariant}>
			{label}
			<StyledAutoCompleteBase
				variant={variant}
				style={{ marginBottom:10 }}
				dropdownStyle={{ border: `1px solid ${theme.colors.lightGrey}` }}
				{...rest}
			/>
		</StyledAutoCompleteContainer>
	);
};