import React, { useCallback, useMemo } from 'react';
import { Form, Input, Select } from 'antd';
import { useState } from 'react';
import { Button } from '../../../components/Button/Button';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { merge, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IRoleProjection } from '../../../logic/Models/Responses/UserAdmin/IRoleProjection';
import { IOrganizationProjection } from '../../../logic/Models/Responses/UserAdmin/IOrganizationProjection';
import { ICreateUserRequest } from '../../../logic/Models/Requests/UserAdmin/ICreateUserRequest';
import { IUserProjection } from '../../../logic/Models/Responses/UserAdmin/IUserProjection';
import { IAccessControlProjection } from '../../../logic/Models/Responses/UserAdmin/IAccessControlProjection';
import { selectIsLoading, selectEmailSearchResult } from '../../../logic/store/UserAdmin/UserAdminSelectors';
import { searchForEmail } from '../../../logic/store/UserAdmin/UserAdminThunks';
const { Option } = Select;
const { TextArea } = Input;

/**
 * A two-stage form that first lets the admin determine if a user already exists, then
 * pre-fills data and lets the admin edit it before creation.
 */
export const CreateUserForm = (props: {
	users: IUserProjection[],
	accessControls: IAccessControlProjection[],
	roles: IRoleProjection[], 
	organizations: IOrganizationProjection[], 
	createUser: (u: ICreateUserRequest) => unknown,
	switchToUser: (id: string) => unknown
}) => 
{
	const { users, accessControls, roles, organizations, createUser, switchToUser } = props;
	const isLoading = useSelector(selectIsLoading);
	
	// Get a short list of emails that are _not_ available because they are in use!
	const claimedEmails = useMemo(() => uniq(users.map(u => u.Username.toLowerCase())), [users]);

	// Get the list of access controls (salesforce hierarchy) that aren't associated to an existing
	// account already.
	const accessControlsNotAlreadyAssigned = useMemo(
		() => accessControls.filter(ac => ac.Email && !claimedEmails.includes(ac.Email.toLowerCase())),
		[claimedEmails, accessControls]);

	const [searchNeedle, setSearchNeedle] = useState<string>();
	// If the user types in a search term it is applied to the access controls that
	// are not already in use
	const filteredAccessControls = useMemo(() => 
	{
		if(!searchNeedle?.length)
		{
			return accessControlsNotAlreadyAssigned;
		}
		const needle = searchNeedle.toLowerCase();
		return accessControlsNotAlreadyAssigned.filter(ac => 
			ac.Email?.toLowerCase().includes(needle)
			|| ac.FoundationId?.toLowerCase().includes(needle)
			|| ac.LevelName?.toLowerCase().includes(needle)
			|| ac.Name?.toLowerCase().includes(needle)
			|| ac.NodeExternalId.toLowerCase().includes(needle)
			|| ac.OrganizationName?.toLowerCase().includes(needle)
			|| ac.ParentName?.toLowerCase().includes(needle)
			|| ac.OrgLevel?.toLowerCase().includes(needle)
			|| ac.Title?.toLowerCase().includes(needle)
			|| ac.UserExternalId.toLowerCase().includes(needle)
		);
	}, [accessControlsNotAlreadyAssigned, searchNeedle]);

	// When creating a new user, this is any prefilled data
	const [userBasis, setUserBasis] = useState<ICreateUserRequest>();

	const saveNewUser = useCallback((d: Partial<ICreateUserRequest>) => 
	{
		const blankUserBasis: ICreateUserRequest = {
			Company: '',
			FoundationId: '',
			Name: '',
			Notes: '',
			OrganizationId: '',
			RoleId: '',
			Username: ''
		}; 
		createUser(merge(blankUserBasis, userBasis ?? blankUserBasis, d));
	}, [createUser, userBasis]);

	const searchResult = useSelector(selectEmailSearchResult);

	const dispatch = useDispatch();
	const emailSearch = useCallback((email) =>
	{
		if (!createUserSearchForm.getFieldsError().some(({ errors }) => errors.length))
		{
			dispatch(searchForEmail(email));
		}
	}, [dispatch]);

	const [createUserSearchForm] = Form.useForm();

	return <div style={{position:'relative', display:'flex', flexDirection:'row', alignItems:'stretch',  margin: -14}}>
		{!userBasis && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', padding: 8, flexGrow: 1, flexBasis: 0}}>
			
			<div style={{display:'flex', flexDirection: 'column', flexGrow:1}}>
				{/* Let the admin type in an email to search for any already existing account */}
				<h3>Create a Manual user</h3>
				<Form form={createUserSearchForm}>
					<Form.Item validateFirst name='Email' label='Email' rules={[
						{
							type: 'email',
							message: 'The input is not valid E-mail!',
						},
						{
							required: true,
							message: 'Please input your E-mail!',
						},
					]}>
						<Input.Search type='email' onSearch={emailSearch} />
					</Form.Item>
				</Form>
				{
					(typeof searchResult.success === 'boolean' && searchResult.existingUser) && <div>
						There is already a user with that email address. 
						<a href='#' onClick={() => switchToUser(searchResult.existingUser!.Id)}>View User {searchResult.existingUser.Name}</a>
					</div>
				}
				{
					(typeof searchResult.success === 'boolean' && searchResult.success) && <div style={{display:'flex', flexDirection:'row', border: '1px solid black', borderRadius:4, padding:8}}>
						<div style={{flexGrow:1}}>
							<div>The address {searchResult.email} is available for use.</div>
							<div>{searchResult.cropwise && <>There is an account in cropwise for {searchResult.cropwise} that will be automatically linked.</>}</div>
						</div>
						<Button variant='dark' style={{marginLeft: 8, width:120, height:50}} onClick={() => setUserBasis({
							Username: searchResult.email!,
							Name: searchResult.cropwise ?? '',
							Company: '',
							FoundationId: '',
							Notes: '',
							OrganizationId: '',
							RoleId: ''
						})}>
							Create
						</Button>
					</div>
				}
			</div>
			{/* Also let the admin search through the salesforce hierarchy to quickly create an account */}
			<h3 style={{marginTop:16, marginBottom:0}}>Import from Salesforce</h3>
			<h6>(Already existing GHX Fields accounts will be excluded)</h6>
			<Form.Item hasFeedback>
				<Input.Search placeholder='Search text - Sales Territory, Name, Email, FID, etc' onSearch={setSearchNeedle} />
			</Form.Item>
			<div style={{overflow: 'auto', maxHeight: '50vh', flexGrow: 1, scrollbarGutter: 'stable', marginRight: -18}}>
				<table width='100%'>
					{
						(searchNeedle?.length ?? 0) > 0 && filteredAccessControls.slice(0, 50).map(ac => <React.Fragment  key={ac.NodeExternalId+ac.UserExternalId}>
							<tr>
								<td style={{backgroundColor:'black', color:'white', padding: 8}}>{ac.Title}</td>
								<td style={{backgroundColor:'black', color:'white', padding: 8}}>{ac.OrganizationName} &gt; {ac.ParentName} &gt; {ac.LevelName}</td>
								<td style={{backgroundColor:'black', color:'white', padding: 8}}>{ac.UserExternalId}</td>
								<td style={{textAlign:'right', padding: 8}} rowSpan={2}><Button variant='dark' style={{marginLeft: 8, width:120, height:50}} onClick={() => 
								{
									setUserBasis({
										Username: ac.Email ?? '',
										Company: ac.LevelName ?? '',
										Name: ac.Name ?? '',
										FoundationId: ac.FoundationId ?? '',
										Notes: ac.Title ?? '',
										OrganizationId: ac.OrganizationId,
										RoleId: ''
									});
								}}>Import</Button></td>
							</tr>
							<tr>
								<td style={{padding:8,paddingBottom: 16}}>{ac.Email}</td>
								<td style={{padding:8,paddingBottom: 16}}>{ac.Name}</td>
								<td style={{padding:8,paddingBottom: 16}}>{ac.FoundationId}</td>  
							</tr>
						</React.Fragment>)
					}
					{
						((searchNeedle?.length ?? 0) > 0 && filteredAccessControls.length == 0) && 
							<h3>No results match that search.</h3>
					}
				</table>
			</div>
		</div>
		}
		{/* Once the user has picked a basis for the new user, they can edit it before submitting. */}
		{userBasis && 
			<Form 
				scrollToFirstError
				onFinish={saveNewUser}
				labelCol={{ span: 4 }}
				initialValues={userBasis}
				style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', padding: 8, flexGrow: 1, flexBasis: 0}}>
				<Form.Item name='Username' label='Email'
					required>
					{userBasis.Username}
				</Form.Item>
				<Form.Item 
					name='Name'
					label='Name'
					required
					rules={[
						{
							required: true,
							message: 'Please input a name',
						},
					]}
				>
					<Input required />
				</Form.Item>
				<Form.Item 
					name='Company'
					label='Company'
				>
					<Input />
				</Form.Item>
				<Form.Item 
					name='FoundationId'
					label={<div>Foundation ID<div style={{fontSize: 10, lineHeight: '8pt'}}>(SAP ID)</div></div>}
				>
					<Input  />
				</Form.Item>
				<Form.Item
					name='RoleId'
					label='Role'
					required
					rules={[
						{
							required: true,
							message: 'Please select a role',
						},
					]}
				>
					<Select placeholder={'Pick the user\'s role'}>
						{
							roles.map(r => <Option key={r.Id} value={r.Id}>{ r.Name }</Option>)
						}
					</Select>
				</Form.Item>
				<Form.Item
					name='OrganizationId'
					label='Organization'
					required
					rules={[
						{
							required: true,
							message: 'Please select an organization',
						},
					]}
				>
					<Select placeholder={'Pick the user\'s organization'}>
						{
							organizations.map(o => <Option key={o.Id} value={o.Id}>{ o.Name }</Option>)
						}
					</Select>
				</Form.Item>
				<Form.Item
					name='Notes'
					label='Notes'
				>
					<TextArea rows={8}/>
				</Form.Item>
				<Button variant='dark'>Create</Button>
			</Form>
		}
		<LoadingSpinner style={{position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)'}} loading={isLoading}>
			Loading...
		</LoadingSpinner>
	</div>;
};