import { sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { StyledCollapse, StyledPanel } from '../../../components/StyledCollapse/StyledCollapse';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { ReactComponent as Conflict } from '../../../assets/images/Conflict.svg';
import { ReactComponent as ClipIcon } from '../../../assets/images/Clip.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import { ReactComponent as RestoreIcon } from '../../../assets/images/RestoreFromTrash.svg';
import { ReactComponent as ReverseIcon } from '../../../assets/images/ReverseIcon.svg';
import { IconButton } from '../../../components/IconButton/IconButton';
import { BulkImportFeatureCollection } from '../../../logic/store/BulkImport/BulkImportThunks';
import { area, convertArea, FeatureCollection, featureCollection } from '@turf/turf';
import { FieldThumbnail } from '../../../logic/Map/FieldThumbnail';
import { RoundAcres, RoundWithPrecision } from '../../../logic/Utility/CalculationUtilities';
import { clipField, IBulkImportedFarm, IBulkImportedField, skipField, unClipField, unSkipField } from '../../../logic/store/BulkImport/BulkImportSlice';
import { Collapse } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { Footer } from '../FieldListContainer';
import { StyledCaret } from '../../../components/Table/TableRow';
import { themeGet } from '@styled-system/theme-get';

const FarmHeader = (props: { farm: IBulkImportedFarm }) =>
{
	const { farm } = props;
	const hasConflicts = useMemo(() => Object.values(farm.Fields).some(field => field.currentConflicts.length > 0), [farm]);
	return (
		<div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center' }}>
			<div style={{ flexShrink: 0, width: 24, height: 24, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', marginLeft: 6, marginRight: 4, cursor: 'pointer' }}>
				<div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: farm.color }} />
			</div>
			{farm.Name} ({Object.keys(farm.Fields).length} fields)
			{hasConflicts && <Conflict width={20} height={20}  style={{ position: 'absolute', left: -8, top: -8 }} />}
		</div> 
	);
};

const CollapseNoPadding = styled(Collapse)`
	> .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
		padding: 0px;
		.ant-collapse-header-text {
			width: 100%;
		}
	}
	.ant-collapse-content-box {
		padding-left: 0px;
		padding-right: 0px;
	}
`;

interface IFieldRowProps {
	color: string;
	clipField: (fieldId: string, clipFieldId: string) => unknown;
	field: IBulkImportedField;
	geometry: BulkImportFeatureCollection | undefined;
	onClickField: (() => unknown) | undefined;
	skipField: () => unknown;
	unClipField: () => unknown;
	unSkipField: () => unknown;
	onHover: () => unknown;
	isHovered: boolean;
	scrollTo: boolean;
}

const HoverDiv = styled.div`
	align-items: center; 
	display: flex;
	flex-direction: row;

	&:hover {
		background-color: ${themeGet('colors.secondaryRGBA35')} !important;
	}
`;

const FieldRow = (props: IFieldRowProps) =>
{
	const { clipField, color, geometry, field, onClickField, skipField, unSkipField, onHover, isHovered, scrollTo } = props;
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [ swappedIntersections, setSwappedIntersections] = useState<string[]>([]);
	const isSwapped = (fieldId: string) => swappedIntersections.includes(fieldId);
	const swap = (fieldId: string) => setSwappedIntersections( isSwapped(fieldId)
		? swappedIntersections.filter(id => id !== fieldId )
		: [...swappedIntersections, fieldId]);

	const acres = useMemo(() => geometry ? convertArea(area(geometry), 'meters', 'acres') : 0, [geometry]);

	const isActive = !field.skip;
	const hasConflicts = useMemo(() => isActive && (field.currentConflicts?.length ?? 0) > 0, [field, isActive]);
	const onTrashCan = useCallback(() =>
	{
		setOpen(false);
		if (field.skip)
		{
			unSkipField();
		}
		else
		{
			skipField();
		}
	}, [skipField, unSkipField, field.skip]);
	const onClip = useCallback(() =>
	{
		setOpen(prev => !prev);
	}, []);

	const clip = useCallback(() =>
	{
		for(const clipFieldId of field.currentConflicts)
		{
			if(isSwapped(clipFieldId))
			{
				clipField(clipFieldId, field.Id);
			}
			else
			{
				clipField(field.Id, clipFieldId);
			}
		}
		setOpen(false);
	}, [clipField, swappedIntersections]);
	const cancel = useCallback(() =>
	{
		setSwappedIntersections([]);
		setOpen(false);
	}, []);

	
	const [itemRef, setItemRef] = useState<HTMLDivElement | null>(null);
	useEffect(() => 
	{
		if (itemRef && scrollTo) 
		{
			if(itemRef.offsetParent)
			{
				itemRef.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
			else // Unfortunately if the element is in a collapsed panel we have to wait for it to be exposes
			{
				setTimeout( () =>
					itemRef.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					}), 500);
			}
		}
	}, [scrollTo, itemRef]);

	return (
		<CollapseNoPadding ghost activeKey={open ? field.Id : undefined}>
			<Collapse.Panel style={{
				padding: 0,
			}} showArrow={false} key={field.Id} 
			header={
				<HoverDiv style={{ 
					backgroundColor: (isActive && !isHovered) ? 'unset' : field.skip ? theme.colors.lightestGrey : 'rgba(237,150,93,.35)',
					color: isActive ? theme.colors.darkGrey : theme.colors.mediumGrey,
				}}
				onMouseOver={onHover}
				>
					<div 
						style={{ position: 'relative' }}
						onClick={onClickField}
						ref={setItemRef}
					>
						{geometry && (
							<FieldThumbnail
								geojson={geometry}
								style={{ fill: isActive ? color : theme.colors.mediumGrey, width: 34, height: 34 }}
								imageStyle={{ padding: 5 }}
							/>
						)}
						{hasConflicts && <Conflict width={20} height={20} style={{ position: 'absolute', left: -8, top: -8 }} />}
					</div>
					<div
						onClick={onClickField}
						style={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
						title={field.Name}
					>
						{field.Name}
					</div>
					<div 
						style={{ flexShrink: 0, margin: 8, fontWeight: 'normal' }}
						onClick={onClickField}
					>{RoundAcres(acres)} Ac.</div>
					<IconButton className='clip-button'
						tooltip='Clip'
						placement='right'
						onClick={onClip}
						style={{ flexShrink: 0, visibility: !hasConflicts ? 'hidden' : 'visible' }}
						fillcolor={open ? theme.colors.primary : theme.colors.darkGrey}
						hoverbgcolor={theme.colors.lightestGrey}>
						<ClipIcon />
					</IconButton>
					{isActive ? <IconButton className='delete-button'
						tooltip='Remove'
						placement='right'
						onClick={onTrashCan}
						style={{ flexShrink: 0 }}
						fillcolor={theme.colors.darkGrey}
						hoverbgcolor={theme.colors.lightestGrey}>
						<DeleteIcon />
					</IconButton> : <IconButton className='delete-button'
						tooltip='Restore'
						placement='right'
						onClick={onTrashCan}
						style={{ flexShrink: 0 }}
						fillcolor={'#9EA2A2'}
						hoverbgcolor={theme.colors.lightestGrey}>
						<RestoreIcon />
					</IconButton>}
				</HoverDiv>
			}>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -16}}>
					{
						field.currentConflicts.map((fid, idx) => 
						{
							const onSwap = () => swap(fid);
							return <div key={fid} style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'stretch',
								paddingTop: 8,
								paddingBottom: 8,
								borderTop: idx ? theme.borders.thin : 'none',
								borderTopColor: theme.colors.lightestGrey
							}}>
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'stretch',
									flexGrow: 1
								}}>
									<div style={{ fontSize: 10, fontWeight: 400 }}>
										Acres removed from:
									</div>
									<div>{isSwapped(fid) ? fid : field.Id}</div>
									<div style={{ fontSize: 10, fontWeight: 400, marginTop: 4 }}>
										Acres remain on:
									</div>
									<div>{isSwapped(fid) ? field.Id : fid}</div>

								</div>
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center'
								}}>
									<IconButton className='clip-button'
										tooltip='Reverse Field Clipping'
										placement='right'
										onClick={onSwap}
										style={{ flexShrink: 0 }}
										outsidedHoverBackground={true}
										fillcolor={theme.colors.primary}
										hoverbgcolor={theme.colors.lightestGrey}>
										<ReverseIcon />
									</IconButton>
								</div>
							</div>;
						})
					}
				</div>

				<Footer style={{ 
					backgroundColor: 'unset', 
					borderTopWidth: 0, 
					display: 'flex', 
					zIndex: 1,
					height: 'auto',
					paddingBottom: 16,
					borderBottom: theme.borders.thin,
					borderBottomColor: theme.colors.lightestGrey 
				}}>
					<Button
						style={{ width:100, height:32, padding: 0, fontSize: 14, fontWeight: 700 }}
						variant='outlined'
						className='bulkFieldEditButton'
						onClick={cancel}
					>
						Cancel
					</Button>
					<Button
						style={{ width:100, height:32, marginLeft: 10, marginRight: 10, padding: 0, fontSize: 14, fontWeight: 700 }}
						variant='dark'
						className='bulkEditNamesButton'
						onClick={clip}
					>
						Clip Field
					</Button>
				</Footer>
			</Collapse.Panel>
		</CollapseNoPadding>
	);
};

interface IFarmFieldTreeProps extends PropsFromRedux {
	zoomToField: (geometry: FeatureCollection) => unknown;
	setHoverFarmFieldId: (fieldId: string) => unknown;
	hoverFarmFieldId: string | undefined;
	scrollTo: string | undefined;
}

const FarmFieldTreeComponent = (props: IFarmFieldTreeProps) =>
{
	const { clipField, farms, Geometry, skipField, unClipField, unSkipField, zoomToField, setHoverFarmFieldId, hoverFarmFieldId, scrollTo } = props;
	const [ farmOpenKeys, setFarmOpenKeys ] = useState<string[]>([]);

	const originalConflicts = useMemo(() => 
	{
		if (!farms)
		{
			return [];
		}

		return Object.values(farms)
			.filter(farm => Object.values(farm.Fields).some(field => (field.Overlaps?.length ?? 0) > 0))
			.map(f => f.Id);
	}, [farms]);

	useEffect(() => 
	{
		setFarmOpenKeys(originalConflicts);
	}, [JSON.stringify(originalConflicts)]);


	useEffect(() => 
	{
		if(scrollTo)
		{
			const [farmId] = scrollTo.split(':');
			if(!farmOpenKeys.includes(farmId))
			{
				setFarmOpenKeys([...farmOpenKeys, farmId]);
			}
		}
	}, [scrollTo]);


	if (!farms)
	{
		return <></>;
	}
	else
	{
		return <StyledCollapse 
			onChange={(key: string | string[]) => setFarmOpenKeys(key as string[])}
			activeKey={farmOpenKeys}
			expandIcon={(props) => 
			{
				return <div><StyledCaret className='RowExpander' expanded={props.isActive ? 'true' : 'false'} /></div>;
			}} expandIconPosition='end'>{
				Object.values(farms).map(farm =>
				{
					return (
						<StyledPanel
							header={<FarmHeader farm={farm} />}
							key={farm.Id}
						>
							{sortBy(Object.values(farm.Fields)
								.filter(field => field.originalGeometry), field => -field.Overlaps.length, field => field.Name)
								.map(field =>
								{
									const skip = () => skipField(farm.Id, field.Id);
									const unSkip = () => unSkipField(farm.Id, field.Id);
									const clip = (fieldId: string, clipFieldId: string) => clipField(farm.Id, fieldId, clipFieldId);
									const unClip = () => unClipField(farm.Id, field.Id);
									const hover = () => 
									{
										setHoverFarmFieldId(`${farm.Id}:${field.Id}`);
									};
									const fieldGeometry = featureCollection([field.geometry ?? field.originalGeometry!]);
									const zoom = fieldGeometry ? () => zoomToField(fieldGeometry) : undefined;
									return <FieldRow
										clipField={clip}
										color={farm.color}
										geometry={fieldGeometry}
										field={field}
										key={field.Id}
										onClickField={zoom}
										skipField={skip}
										unClipField={unClip}
										unSkipField={unSkip}
										onHover={hover}
										isHovered={hoverFarmFieldId === `${farm.Id}:${field.Id}`}
										scrollTo={scrollTo === `${farm.Id}:${field.Id}`}
									/>;
								})}
						</StyledPanel>
					);
				})
			}</StyledCollapse>;
	}
};

const mapStateToProps = (state: RootState) =>
{
	return {
		Geometry: state.bulkImport.geometry,
		farms: state.bulkImport.farms,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		clipField: (FarmId: string, FieldId: string, ClipFieldId: string) => dispatch(clipField({ FarmId, FieldId, ClipFieldId })),
		unClipField: (FarmId: string, FieldId: string) => dispatch(unClipField({ FarmId, FieldId })),
		skipField: (FarmId: string, FieldId: string) => dispatch(skipField({ FarmId, FieldId })),
		unSkipField: (FarmId: string, FieldId: string) => dispatch(unSkipField({ FarmId, FieldId })),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const BulkImportFarmFieldTree = connector(FarmFieldTreeComponent);
