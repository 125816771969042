import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { IAccessControlProjection } from '../../Models/Responses/UserAdmin/IAccessControlProjection';
import { IUserProjection } from '../../Models/Responses/UserAdmin/IUserProjection';
import { stableStringSort } from '../../Utility/Utils';
import { RootState } from '../Store';
import memoize from 'memoizee';


export const selectIsLoading = createSelector((state: RootState) => state.userAdmin.loadingCount, (loadingCount) => loadingCount > 0);

export const selectEditingUser = createSelector( (state: RootState) => state.userAdmin.users, (state:RootState) => state.userAdmin.editingUserId,
	// Find the user that matches our selected id	
	(users, id) => users.find(u => u.Id === id ));

export const selectDistinctCompanies = createSelector( (state: RootState) => state.userAdmin.users,
	// Extract all unique companies then sort them
	(users) => uniq(users.map(u => u.Company)
		.filter(c => !!c)).sort(stableStringSort) as string[] );

export const selectDistinctDomains = createSelector( (state: RootState) => state.userAdmin.users,
	// Extract the domain after the '@' symbol
	(users) => uniq(users
		.map(u => u.Username)
		.filter(email => email?.includes('@'))
		.map(email => email.split('@')[1].toLowerCase()))
		.sort(stableStringSort) as string[]);

export const selectDistinctLevels = createSelector( (state: RootState) => state.userAdmin.accessControls,
	// We only look at the parent names here because the lowest level (company names of sellers) is too verbose
	( ac ) => uniq(ac.map(u => u.ParentName)
		.filter(c => c?.length)).sort(stableStringSort) as string[]);


export const selectEmailSearchResult = createSelector( (state: RootState) => state.userAdmin.emailSearchResult, 
	// Find the user that matches our selected id	
	(result) => result);

const accessControlsLookup = memoize((accessControls: IAccessControlProjection[]) => accessControls.reduce((prev, cur) => 
{
	const email = cur.Email?.toLowerCase();
	const fid = cur.FoundationId;

	if(!email)
	{
		// This really shouldn't happen, but if it does, we don't want to crash
		return prev;
	}

	if(!fid?.length)
	{
		return {
			byEmail: {
				...prev.byEmail,
				[email]: prev.byEmail[email] ? prev.byEmail[email].concat(cur) : [cur]
			},
			byFoundationId: prev.byFoundationId
		};
	}

	return {
		byEmail: {
			...prev.byEmail,
			[email]: prev.byEmail[email] ? prev.byEmail[email].concat(cur) : [cur]
		},
		byFoundationId: {
			...prev.byFoundationId,
			[fid]: prev.byFoundationId[fid] ? prev.byFoundationId[fid].concat(cur) : [cur]
		}
	};
}, { byEmail:{} as Record<string, IAccessControlProjection[]>, byFoundationId: {} as Record<string, IAccessControlProjection[]> } ));

export const accessControlsForUserByEmail = memoize((accessControls: IAccessControlProjection[], u: IUserProjection) => 
{
	const lookup = accessControlsLookup(accessControls);
	return lookup.byEmail[u.Username.toLowerCase()] ?? [];
});

export const accessControlsForUserByFoundationId = memoize((accessControls: IAccessControlProjection[], u: IUserProjection) => 
{
	const lookup = accessControlsLookup(accessControls);
	return u.FoundationId?.length ? lookup.byFoundationId[u.FoundationId] ?? [] : [];
});

export const accessControlsForUser = memoize((accessControls: IAccessControlProjection[], u: IUserProjection) => 
{
	const lookup = accessControlsLookup(accessControls);
	const byEmail = lookup.byEmail[u.Username.toLowerCase()] ?? [];
	const byFoundationId = u.FoundationId?.length ? lookup.byFoundationId[u.FoundationId] ?? [] : [];

	// Return the union of the two arrays without duplicates
	return [...byEmail, ...byFoundationId.filter(ac => !byEmail.includes(ac))];
});

export const accessControlLabel = memoize((acs: IAccessControlProjection[]) =>
{
	if (!acs.length || !acs[0]) 
	{
		return '';
	}

	if (acs.length > 1) 
	{
		return acs.map(ac => `${ac.ParentName} / ${ac.LevelName}`).sort(stableStringSort)[0];
	}

	return `${acs[0].ParentName} / ${acs[0].LevelName}`;
});
