
export interface IUpdateFromSource
{
	Type: AssignmentType; // Enum
	SourceId?: string;
	SourceType: AssignmentSource; // Enum
	SourceName: string;
}

export interface IUpdateSeedAssignments extends IUpdateFromSource
{
	Hybrids: IUpdateHybrid[];
}

export interface IUpdateCrop extends IUpdateFromSource
{
	CropId: string;
}

export interface IUpdateMultipleCrop extends IUpdateCrop
{
	FieldIds: string[];
}

export interface IUpdateHybrid
{
	AppliedAcres?: number;
	Brand?: string; // External seed brand name
	CropId?: string; // Crop Id for the external seed
	CustomTreatmentId?: string;
	Id?: string; // The E-luminate hybrid Id
	IsExternal: boolean; // Whether this is an external seed or not.
	IsSyngenta: boolean; // Whether this is a syngenta seed or not.
	Name?: string; // External seed hybrid name
	Rate?: number;
	Rm?: number; // External seed Relative Maturity value
	Treatment?: string;
}

export interface IUpdateTargetYield extends IUpdateFromSource
{
	TargetYield: number;
}

export enum AssignmentType
{
	Recommended,    // Accepted GamePlans
	Quoted,         // Quote generated from quote page 
	Prescribed,     // From an Order plan 
	Actual,         // From a monitor or entered in workorder 
	Predicted,      // From predictive algorithm, source, or user's guess 
	Unknown = -1
}

export enum AssignmentSource
{
	FieldGamePlan,
	OrderGamePlan,
	ProductGamePlan,
	Manual,
	AsPlanted,
	Yield,
	Model,
	Unknown = -1
}