import { area, feature, Feature, featureCollection, FeatureCollection, Geometry, LineString, multiPolygon, MultiPolygon, Polygon, Position } from '@turf/turf';
import { globalSession } from '../../../tracing/session';
import { IdentifiedGeometry } from '../../Utility/Geometry/IdentifiedGeometry';
import { split } from '../../Utility/Geometry/split';
import { SupportedGeometryFormats } from '../../Utility/Geometry/SupportedGeometryFormats';
import { IZoneFeatureCollection } from './ManagementZoneSlice';

/** Split management zones with a linestring. */
export const splitZones = (zones: IZoneFeatureCollection, splitLine: LineString) => 
{
	const splitted = split(
		zones.features.map((poly, idx) =>
		{
			return {
				id: String(poly.properties?.ZoneName ?? `autogenerated-${idx}`),
				geometry: poly.geometry
			} as IdentifiedGeometry<Polygon>;
		}),
		splitLine,
		SupportedGeometryFormats.GeoJSON
	);

	const irregularFeatures = featureCollection(splitted.map((item) => feature(item.geometry, { zone: Number(item.id.split('/')[0]) })));
	
	const resultsByZone = normalizeSplitZoneData(irregularFeatures);

	return resultsByZone;
};

/**
 * There can be multiple polygons per zone returned from split, so we need to get them all together into a multipolygon feature collection
 *  */
const normalizeSplitZoneData = (irregularFeatures: FeatureCollection<Polygon, { zone: number }>) =>
{
	const consolidatedByZone: { [key: string]: { polygons: Position[][][], zone: number } } = {};
	irregularFeatures.features.forEach((polygon: Feature<Polygon, { zone: number }>) =>
	{
		if (!polygon.geometry)
		{
			return;
		}

		const zone = polygon.properties?.zone;

		if (!consolidatedByZone[zone])
		{
			consolidatedByZone[zone] = {
				polygons: [],
				zone: polygon.properties?.zone
			};
		}
		
		if (polygon.geometry.type === 'Polygon') 
		{
			consolidatedByZone[zone].polygons.push(polygon.geometry.coordinates);
		}
		else 
		{
			throw new Error(`Unexpected geometry in cluster: ${(polygon.geometry as any).type}`);
		}
	});

	const polysByZone = Object.values(consolidatedByZone);

	if (!polysByZone)
	{
		globalSession.error('No consolidated polygons to normalize.');
	}

	const normalizedCluster: FeatureCollection<MultiPolygon, { zone: number }> = featureCollection(polysByZone.map((zonePolys) =>
		multiPolygon(zonePolys.polygons, { zone: zonePolys.zone })));
	
	return normalizedCluster;
};