import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { IAuthResponse, IUserAuthResponse } from '../../Models/Responses/UserAuthResponse';
import { IManageUserResponse } from '../../Models/Responses/ManageUsersResponse';
import { IShipFromAddress } from '../../Models/Responses/ShipFromAddress';
import { getCurrentActingUser } from './AuthSlice';
import { Api } from '../../Api/Api';
import { downloadGrowerFieldHealthPdf } from './FieldHealthThunks';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { IDiscountReasonCode } from '../../Models/Responses/DiscountReasonCodeResponse';
import { getDiscountReasonCodes } from '../CompetitiveAllowance/CompetitiveAllowanceThunks';

export const getProductAnalyzerLaunchUrl = createTracedAsyncThunk<string, null, { dispatch: AppDispatch, state: RootState }>(
	'userInfo/productAnalyzerLaunchUrl/get',
	async (context, request: {}, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<string>('engageToken', request);

			if (response.ErrorCode === null && response.Success)
			{
				// Create an invisible iframe the "login" with the SSO token, and then open
				// the origin url which will actually display the page that the user is meant to be logged in to!
				const ssoUrl: URL = new URL(response.Data);
				const actualLaunchUrl = ssoUrl.origin;
				const iframe = document.createElement('iframe');
				document.body.appendChild(iframe);
				iframe.src = ssoUrl.href;
				iframe.onload = () =>
				{
					window.open(actualLaunchUrl, '_blank');
				};

				return actualLaunchUrl;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e) 
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const redirectToGhxCoreSso = createTracedAsyncThunk<string, null, { dispatch: AppDispatch, state: RootState }>(
	'userInfo/ghxCoreSso/get',
	async (context, request: {}, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);

			// Get any selection state that we may want to transmit
			const state = thunkAPI.getState();
			const selectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
			const selectedUser = state.auth.selectedUser;

			const response = await api.postAsync<{ 'Token': string, 'Url': string}>('sso', {
				'Destination': 'core',
				'State': {
					'grower': selectedGrower?.FoundationId,
					'seller': selectedUser?.FoundationId
				}
			}, request);
			
			if(response.Success)
			{
				window.location.assign(response.Data.Url);
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e) 
		{
			return thunkAPI.rejectWithValue(e.message);
		}

	}
);

interface ISsoExchangeTokenRequest
{
	Destination: string;
	Link?: string;
	State?: ISsoState;
}

interface ISsoState
{
	grower?: string;
	seller?: string;
	salesrep?: string;
	nonce?: string;
	order?: string;
}

export const redirectToGhxLogisticsSso = createTracedAsyncThunk<string, { salesforceOrderId?: string, isFromMaxScript?: boolean }, { dispatch: AppDispatch, state: RootState }>(
	'userInfo/ghxlogisticsSso/get',
	async (context, request, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);

			const tokenRequest: ISsoExchangeTokenRequest =
			{
				Destination: 'logistics'
			};

			// Get the seller which is the currently managed/selected user
			// and pass their foundation Id to the request State object
			const { auth, userInfo, grower, ui } = thunkAPI.getState();
			tokenRequest.State =
			{
				seller: auth.selectedUser? auth.selectedUser.FoundationId : userInfo.FoundationId,
				grower: grower.Growers.find(g => g.Id === ui.SelectedGrowerId)?.FoundationId
			};

			if (request.salesforceOrderId)
			{
				tokenRequest.Link = `/EditOrder?SalesOrderId=${request.salesforceOrderId}&IsFromMaxScript=${Boolean(request.isFromMaxScript)}`;
				tokenRequest.State.order = request.salesforceOrderId;
			}

			const response = await api.postAsync<{'Token': string, 'Url': string}>('sso',
				tokenRequest);
			
			if(response.Success)
			{
				window.location.assign(response.Data.Url);
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e) 
		{
			return thunkAPI.rejectWithValue(e.message);
		}

	}
);

export const getCropEdgeSsoLaunchUrl = createTracedAsyncThunk<string, null, { dispatch: AppDispatch, state: RootState }>(
	'userInfo/cropEdgeSsoLaunchUrl/get',
	async (context, request: {}, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<string>('cropedgesso', request);

			if (response.ErrorCode === null && response.Success)
			{
				// Create an invisible iframe the "login" with the SSO token, and then open
				// the origin url which will actually display the page that the user is meant to be logged in to!
				const ssoUrl: URL = new URL(response.Data);
				const actualLaunchUrl = decodeURIComponent(response.Data.split('return_url=')[1]);
				const iframe = document.createElement('iframe');
				document.body.appendChild(iframe);
				iframe.src = ssoUrl.href;
				// 06/17/22 - ELM - Currently the cropedge url does not allow framing and throws an error
				iframe.onload = () =>
				{
					window.open(actualLaunchUrl, '_blank');
				};
				iframe.onerror = () =>
				{
					window.open(actualLaunchUrl, '_blank');
				};

				return actualLaunchUrl;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e) 
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const getShipFromAddresses = createTracedAsyncThunk<IShipFromAddress[], null, { dispatch: AppDispatch, state: RootState }>(
	'userInfo/getShipFromAddresses',
	async (context, request: {}, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const state = thunkAPI.getState();
			const currentActingUser = getCurrentActingUser(state);
			const response = await api.getAsync<IShipFromAddress[]>(`users/${currentActingUser.UserId}/shipfrom`, request);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e) 
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IUserInfoState extends IManageUserResponse
{
	isError: boolean;
	isLoading: boolean;
	errorMessage: string;
	ShipFromAddresses: IShipFromAddress[];
	CropEdgeLinkUrl: string;
	/** Did the user login through sso. */
	SsoLogin: boolean;
	DiscountReasonCodes: {[key: string]: IDiscountReasonCode[]};
}

export const initialState: IUserInfoState = {
	UserName: undefined,
	UserEmail: undefined,
	UserId: undefined,
	FoundationId: undefined,
	Permissions: [],
	isLoading: false,
	isError: false,
	errorMessage: undefined,
	ShipFromAddresses: [],
	CropEdgeLinkUrl: undefined,
	SsoLogin: false,
	DiscountReasonCodes: {},
	OrganizationalUnitName: undefined,
};

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: initialState,
	reducers: {
		setUserInfo: (state, action: PayloadAction<IUserAuthResponse & { 
			ssoLogin?: boolean;
		}>) =>
		{
			state.UserEmail = action.payload?.UserEmail;
			state.UserName = action.payload?.UserName;
			state.UserId = action.payload?.UserId;
			state.FoundationId = action.payload?.FoundationId;
			state.Permissions = action.payload?.Permissions;
			state.SsoLogin = !!action.payload?.ssoLogin;
			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getProductAnalyzerLaunchUrl.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getProductAnalyzerLaunchUrl.fulfilled, (state, action) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getProductAnalyzerLaunchUrl.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;

			if (action.payload)
			{
				state.errorMessage = 'There was an error launching the product analyzer.';
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});

		builder.addCase(getCropEdgeSsoLaunchUrl.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCropEdgeSsoLaunchUrl.fulfilled, (state, action) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;

			state.CropEdgeLinkUrl = action.payload;
		});
		builder.addCase(getCropEdgeSsoLaunchUrl.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;

			if (action.payload)
			{
				state.errorMessage = 'There was an error launching the CropEdge.';
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});

		builder.addCase(getShipFromAddresses.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getShipFromAddresses.fulfilled, (state, { payload }: PayloadAction<IShipFromAddress[]>) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;

			state.ShipFromAddresses = payload;
		});
		builder.addCase(getShipFromAddresses.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was an error retrieving the Ship From Address list. Please try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});

		/**
		* Grower Crop/Field Health PDF
		*/
		builder.addCase(downloadGrowerFieldHealthPdf.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadGrowerFieldHealthPdf.fulfilled, (state, { payload }: PayloadAction<Blob>) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(downloadGrowerFieldHealthPdf.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to download the Grower Field Health PDF. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem downloading the Grower Field Health PDF. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem downloading the Grower Field Health PDF. Please refresh and try again.';
			}
		});

		builder.addCase(redirectToGhxCoreSso.pending, (state, action) => 
		{
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(redirectToGhxCoreSso.rejected, (state, action) => 
		{
			state.isLoading = false;
			state.isError = true;

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was an error retrieving the SSO url. Please try again later.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});
		// We don't handle redirectToGhxCoreSso.fulfilled as it is a redirect and we just want to leave
		// the spinner up until the browser navigates away.

		builder.addCase(redirectToGhxLogisticsSso.pending, (state, action) => 
		{
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(redirectToGhxLogisticsSso.rejected, (state, action) => 
		{
			state.isLoading = false;
			state.isError = true;
	
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was an error retrieving the Logistics SSO url. Please try again later.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});
		// We don't handle redirectToGhxLogisticsSso.fulfilled as it is a redirect and we just want to leave
		// the spinner up until the browser navigates away.

		/**
		* Competitive Allowance Discount Reason Codes
		*/
		builder.addCase(getDiscountReasonCodes.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getDiscountReasonCodes.fulfilled, (state, { payload }: PayloadAction<{[key: string]: IDiscountReasonCode[]}>) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
			state.DiscountReasonCodes = payload;
		});
		builder.addCase(getDiscountReasonCodes.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the discount reason codes. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the discount reason codes. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the discount reason codes. Please refresh and try again.';
			}
		});
	}
});

export const { clearError } = userInfoSlice.actions;
