import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import ReactJson from 'react-json-view';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../logic/store/Store';
import { Api } from '../../logic/Api/Api';

/**
 * Present tools to inspect orders that have been sent
 */
const SoilDataTroubleShootingTool = (props: PropsFromRedux) => 
{
	const [enteredSearchTerm, setEnteredSearchTerm] = useState<string>('');
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [soilData, setSoilData] = useState<object>([]);

	// When a new search term is committed, fetch the order from the server
	useEffect(()=>
	{
		if(!searchTerm)
		{
			return;
		}

		const execute = async () => 
		{
			
			const api = new Api('/api/4', props.userAuthToken, undefined);
			try
			{
				const response = await api.getAsync<object>(`/supporttools/info/field/${searchTerm}/checkfieldsoildata`);	
				setSoilData(response);
			}
			catch(err)
			{
				setSoilData(err);
			}
			setIsLoading(false);
		};
		setIsLoading(true);
		execute();
	},
	[searchTerm, props.userAuthToken]);

	return (
		<Form style={{display:'flex', flexDirection:'column', alignItems:'stretch', height: '100%', position: 'relative'}}>
			<h1>NRCS Soil Data</h1>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
				<Form.Item label="Search term" style={{width: 500, marginRight: 12}}>
					<Input
						disabled={isLoading}
						value={enteredSearchTerm}
						onChange={(e) => setEnteredSearchTerm(e.currentTarget.value)}
						placeholder='Field Id'
					/>
				</Form.Item>
				<Button onClick={() => setSearchTerm(enteredSearchTerm)}>Search</Button>
			</div>
			<ReactJson style={{flexGrow: 1, flexShrink: 1, overflow: 'auto', padding: 4, border: '1px #cccccc solid'}} src={soilData} />
		</Form>
	);
};

const mapStateToProps = (state: RootState) => ({
	userAuthToken: state.auth.userAuthToken,
	UserId: state.userInfo.UserId
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const SoilDataTroubleShootingToolComponent = connector(SoilDataTroubleShootingTool);
