import React, { useEffect, useState } from 'react';
import { Api } from '../../logic/Api/Api';
import { IGetJdConnectInviteResponse } from './Invite';

/**
 * Fetch an invitation's details from the server
 */
export function useGetJdConnectInvitation(
	invitationCode: string, 
	api: Api, 
	setMessage: (message:React.ReactNode) => unknown, 
	startLoading: () => void, 
	endLoading: () => void) 
{
	const [invitationResponse, setInvitationResponse] = useState<IGetJdConnectInviteResponse>();
	useEffect(() => 
	{
		startLoading();
		async function exec() 
		{
			try 
			{
				const response = await api.getAsync<IGetJdConnectInviteResponse>(`6/jd-connect/invitations/${invitationCode}`);
				if (response.Success) 
				{
					setInvitationResponse(response.Data);
				}
				else if (response.ErrorCode === 'invitation_notactive') 
				{
					setMessage(<div>This invitation has already been claimed</div>);
				}
				else 
				{
					setMessage(<div>This invitation is not valid. If you have questions please reach out to your <a href="https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate">support specialist.</a></div>);
				}
			}
			catch (err) 
			{
				setMessage(<>An unknown error occurred. Please try again later.</>);
			}
			finally 
			{
				endLoading();
			}
		}
		exec();
	}, [invitationCode, api, setMessage, startLoading, endLoading]);

	return invitationResponse;
}
