import React from 'react';
import { useTheme } from 'styled-components';
import { CalculateBags } from '../../../../logic/Utility/CalculationUtilities';

/**
 * Donut panel product row
 */

interface IProductRowProps
{
	acres: number;
	color: string;
	hybridId: string;
	hybridName: string;
	selected: boolean;
	traitFullName: string;
	treatment?: string | undefined;
	units: number;
	selectRow: (hybridId: string) => void;
}

export const DonutProductRow = (props: IProductRowProps) =>
{
	const {
		acres,
		color,
		hybridId,
		hybridName,
		selected,
		traitFullName,
		treatment,
		units,
		selectRow,
	} = props;

	const theme = useTheme();

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				marginBottom: 5,
				padding: 5,
				justifyContent: 'space-between',
				backgroundColor: selected ? theme.colors.lightestGrey : 'inherit',
				borderRadius: 4,
				cursor: 'pointer',
				boxSizing: 'border-box',
			}}
			onClick={() => selectRow(hybridId)}
		>
			<div className={'HybridNameAndTraitIdentity'} style={{ fontWeight: theme.fontWeights.bold, display: 'flex', flexDirection: 'column' }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<div
						className={'HybridColorDot'}
						style={{ position: 'relative', top: -1, height: 10, width: 10, backgroundColor: color, borderRadius: '50%', marginRight: 3 }}
					/>
					<div className={'HybridName'}>{hybridName}</div>
				</div>
				<div className={'HybridTraitIdentity'} style={{ fontSize: theme.fontSizes.tiny}} >{traitFullName}</div>
				<div className={'HybridTreatment'} style={{ fontSize: theme.fontSizes.tiny, color: theme.colors.mediumGrey}} >{treatment}</div>
			</div>
			<div className={'HybridAcresAndUnits'} style={{ display: 'flex', flexDirection: 'row', width: '50%'}}>
				<div
					className={'HybridAcres'}
					style={{
						display: 'flex',
						flexDirection: 'column',
						fontSize: theme.fontSizes.extraSmall,
						alignItems: 'flex-end',
						width: '75%'
					}}
				>
					<div style={{ width: 36, marginRight: 5, textAlign: 'right' }}>Acres:</div>
					<div style={{ width: 36, marginRight: 5, textAlign: 'right' }}>Units:</div>
				</div>
				<div className={'HybridUnits'} style={{ display: 'flex', flexDirection: 'column', fontSize: theme.fontSizes.extraSmall, width: '25%' }}>
					<div style={{ fontWeight: theme.fontWeights.bold, textAlign: 'left' }}>{acres?.toLocaleString('en-US', {maximumFractionDigits:1})}</div>
					<div style={{ fontWeight: theme.fontWeights.bold, textAlign: 'left' }}>{typeof units === 'number' ? CalculateBags(units).toLocaleString('en-US') : ''}</div>
				</div>
			</div>
		</div>
	);
};