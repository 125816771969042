import React, { useEffect } from 'react';
import * as queryString from 'query-string';
import { ThemeName } from '../store/UI/UISlice';

export function useUrlTheme(
	setIsEnogenLogin: (isEnogen:boolean) => unknown, 
	changeTheme: (name: ThemeName) => void) 
{
	// Get any relevant parameters from the url
	const parsedQueryString = queryString.parse(location.search);

	useEffect(() => 
	{
		if (location.origin.includes('eluminate.solutions')
			|| (typeof (parsedQueryString.loginstyle) === 'string' && parsedQueryString.loginstyle.length && parsedQueryString.loginstyle.toLowerCase() === 'enogen')) 
		{
			// Set the state var that will swap the background and logo images
			setIsEnogenLogin(true);

			// Set the theme file
			changeTheme(ThemeName.Enogen);
		}

		else 
		{
			// Default is GHX theme
			setIsEnogenLogin(false);
			changeTheme(ThemeName.GHX);
		}
	}, [parsedQueryString.loginstyle, location]);
}
