import React from 'react';
import { ColorMatchBar } from '../../../components/ColorMatchBar/ColorMatchBar';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { checkContrast } from '../../../logic/Utility/Utils';
import { IExtendedOrderPlanFieldItem } from './FieldInventoryView';
import { IncrementNumberInputDebounced } from '../../../components/IncrementNumberInputDebounced/IncrementNumberInputDebounced';
import { RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { useTheme } from 'styled-components';
export interface IFieldInventoryItemProps
{
	remainingAcres: number;
	agronomicSelection: string;
	fieldInventoryItem: IExtendedOrderPlanFieldItem;
	onChangeSeedSelection: (hybridId: string, checked: boolean) => void;
	updateFieldHybridAcres: (hybridId: string, value: number) => void;
	updateFieldHybridRate: (hybridId: string, value: number) => void;
}

export const FieldInventoryItem = (props: IFieldInventoryItemProps) =>
{
	const { agronomicSelection, remainingAcres, fieldInventoryItem, onChangeSeedSelection, updateFieldHybridAcres, updateFieldHybridRate } = props;

	const theme = useTheme();

	const getTitle = (): string =>
	{
		let title = '';
		if (fieldInventoryItem.IsExternal)
		{
			title += `${fieldInventoryItem.HybridBrand}`;
			if (fieldInventoryItem.HybridName)
			{
				title += `-${fieldInventoryItem.HybridName}`;
			}
			if (fieldInventoryItem.RelativeMaturity)
			{
				title += `-${fieldInventoryItem.RelativeMaturity}`;
			}
		}
		else
		{
			title = fieldInventoryItem.HybridName;
		}

		return title;
	};

	const changeSeedSelection = (checked: boolean) =>
	{
		onChangeSeedSelection(fieldInventoryItem.HybridId, checked);
		if(checked)
		{
			// If we are adding this product and there are unassigned acres, assume they go to this new product
			updateFieldHybridAcres(fieldInventoryItem.HybridId, Math.max(0, remainingAcres));
		}
	};

	const changeAcres = (value: number) =>
	{
		if (value === 0 || value < 0)
		{
			return;
		}
		updateFieldHybridAcres(fieldInventoryItem.HybridId, value);
	};

	const changeRate = (value: number) =>
	{
		if (value === 0 || value < 0)
		{
			return;
		}
		updateFieldHybridRate(fieldInventoryItem.HybridId, value);
	};

	return (
		<div style={{ display: 'flex' }}>
			<Checkbox checked={fieldInventoryItem.Selected} size={'23px'} onChange={e => changeSeedSelection(e.target.checked)} />
			<div style={{ display: 'flex', flexDirection: 'column', width: '85%' }}>
				<div style={{ width: '100%', marginLeft: 10, marginTop: 2.75 }}>
					<ColorMatchBar
						containerClassName={fieldInventoryItem.IsExternal 
							? `FieldInventory_ColorBar-${fieldInventoryItem.HybridBrand}-${fieldInventoryItem.HybridName}-${fieldInventoryItem.RelativeMaturity}`
							: `FieldInventory_ColorBar-${fieldInventoryItem.HybridId}`}
						clickable={true}
						variant='thin'
						containerMargin={'5px'}
						title={getTitle()}
						fontColor={checkContrast(fieldInventoryItem.Color)}
						fontWeight={theme.fontWeights.bold}
						showOuterBorder={true}
						color={fieldInventoryItem.Color}
						maxValue={1}
						value={agronomicSelection !== 'Match Strength' ? fieldInventoryItem.AttributeRatings ? fieldInventoryItem.AttributeRatings[agronomicSelection] : 0 : fieldInventoryItem.MatchStrength}
						showDetails={false}
						onClick={() => onChangeSeedSelection(fieldInventoryItem.HybridId, !fieldInventoryItem.Selected)}
					/>
				</div>
				{
					fieldInventoryItem.Selected &&
					<div style={{ marginBottom: 10 }}>
						<div>
							<IncrementNumberInputDebounced
								interval={500}
								width={'50%'}
								max={fieldInventoryItem.MaxPopulationRate}
								min={fieldInventoryItem.MinPopulationRate}
								onChange={changeRate}
								value={fieldInventoryItem.Rate}
								title={'Planting Rate'}
								orientation={'vertical'}
							/>
						</div>
						<div style={{ marginTop: 10 }}>
							<IncrementNumberInputDebounced
								interval={5}
								width={'50%'}
								precision={1}
								max={RoundAcres(fieldInventoryItem.MaxFieldAcreage + (fieldInventoryItem.MaxFieldAcreage * .10))}
								min={0.1}
								onChange={changeAcres}
								roundValue={RoundAcres}
								value={fieldInventoryItem.Acres}
								title={'Acres'}
								orientation={'vertical'}
							/>
						</div>
					</div>
				}
			</div>
		</div>
	);
};