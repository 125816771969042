import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

export const StyledContainer = styled.div`
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const StyledScrollableTableRows = styled.div`
	padding-bottom: 2%;
`;

interface IContainerProps
{
	header: ReactNode;
	children: ReactNode;
	scrollStyle?: CSSProperties;
}
export type TableContainerProps = IContainerProps;
export const TableContainer = (props: TableContainerProps) =>
{
	const { header, children, scrollStyle = {} } = props;
	return (
		<StyledContainer id='table-container'>
			{header}
			<StyledScrollableTableRows id='scrollable-table-rows' style={{ ...scrollStyle }}>
				{children}
			</StyledScrollableTableRows>
		</StyledContainer>
	);
};