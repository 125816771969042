import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as Pencil } from './Icons/Pencil.svg';
import { EditableInputDisplay } from '../../../../components/EditableInputDisplay/EditableInputDisplay';

/**
 * Card displayed on a Crop Plan specific view (product inventory); has the ability to re-name the Crop Plan with a Pencil icon.
 */

const ButtonContainer = styled.div`
	width: 100%;
	height: auto;
	//min-height: 78px;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	padding: 0px;
	margin-top: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

const MainCardContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
`;

const ColorTab = styled.div<{ color: string }>`
	min-width: 15px;
	background-color: ${props => props.color};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;
	padding-right: 10px;
	padding-bottom: 5px;
`;

const TitleText = styled.div`
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	margin-left: -5px;
	width: 100%;
`;

const DisplayTextContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 11px;
	height: 50%;
	align-items: flex-start;
	padding-top: 3px;
`;

const DisplayNameText = styled.div`
	margin-right: 3px;
	font-size: 12px;
	font-weight: normal;
`;

const DisplayValueText = styled.div`
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 12px;
`;

interface IColorTabButtonProps {
	className?: string;
	title: string;
	tabColor: string;
	disabled?: boolean;
	displayNamesAndValues: [string, string][];
	icon?: React.ReactNode;
	onClick: () => void;
	saveCropPlanName: (value: string) => void;
	selected?: boolean;
	selectedOutlineColor?: string;
}

export const CropPlanCard = (props: IColorTabButtonProps) =>
{
	const {
		className,
		displayNamesAndValues,
		selected,
		selectedOutlineColor,
		tabColor,
		title,
		icon,
		onClick,
		saveCropPlanName,
	} = props;

	const theme = useTheme();

	const selectedButtonOutline = (selected: boolean) => { return selected ? `6px solid ${selectedOutlineColor}` : 'none'; };

	const validateCropPlanName = (value: string): boolean =>
	{
		return true;
	};

	const onSaveCropPlanName = (value: string) =>
	{
		saveCropPlanName(value.trim());
	};

	return (
		<ButtonContainer
			style={{ outline: selectedButtonOutline(selected) }}
			className={className ? className : `ColorTabButton_${title}_${tabColor}`}
		>
			<MainCardContainer className={'MainCard_Container'} onClick={() => onClick()}>
				<ColorTab style={{ minWidth: 15 }} className='ColorTab' color={ tabColor ?? '#ffffff' } />
				<TextContainer>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<TitleText style={{ fontFamily: theme.fonts.base }} className='Title'>
							{
								icon && icon
							}
							<EditableInputDisplay
								className={'CropPlanNameEditable'}
								description={''}
								editButtonTooltip={'Edit Name'}
								editButtonHoverBgColor={theme.colors.lightestGrey}
								editIcon={<Pencil />}
								editIconButtonContainerStyle={{ marginLeft: 'auto' }}
								editIconButtonStyle={{ position: 'relative', top: -2, left: 10 }}
								inputType='text'
								inputValue={title}
								mainContainerWidth={'100%'}
								validationTooltip={'Crop Plan Name is not valid.'}
								validateInput={validateCropPlanName}
								saveInput={onSaveCropPlanName}
								width={'100%'}
							/>
						</TitleText>
					</div>
					<DisplayTextContainer className='DisplayTextContainer'>
						{displayNamesAndValues.map((nameValueTuple, index) => 
							<div
								key={`DisplayName_${index + 1}`}
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: index === 0 ? '50%' : 'inherit',
									alignItems: 'center',
									marginLeft: index === 1 ? 'auto' : 'inherit' }}
							>
								<DisplayNameText
									className={`DisplayNameTitle_${index + 1}`}
								>
									{nameValueTuple[0]}:
								</DisplayNameText>
								<DisplayValueText
									className={`DisplayValue_${index + 1}`}
								>
									{nameValueTuple[1]}
								</DisplayValueText>
							</div>
						)}
					</DisplayTextContainer>
				</TextContainer>
			</MainCardContainer>
		</ButtonContainer>
	);
};