import React, { useCallback } from 'react';
import { ListChildComponentProps } from 'react-window';
import { Link } from 'react-router-dom';
import { StyledContainer } from '../../../components/Table/TableContainer';
import { TableHeader } from '../../../components/Table/TableHeader';
import { StyledRowCell, TableRow } from '../../../components/Table/TableRow';
import { ReactComponent as CatalogIcon } from './Icons/Catalog.svg';
import { ReactComponent as CirclePlusIcon } from './Icons/CirclePlus.svg';
import { ReactComponent as GamePlanIcon } from './Icons/GamePlan.svg';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import {
	Acres,
	getExpandedSpacing,
	getHeaderSpacing,
	IDashboardTableProps,
	keysToHeaders,
	StyledDashboardScrollableTableRows,
	StyledDashboardTableRows
} from './DashboardRows';
import { NameSegment } from './Segments/NameSegmentComponent';
import { EnogenContracted } from './Segments/EnogenContracted';
import { ExpandedPlan } from './Segments/ExpandedPlan';
import { BoolDictionary } from '../../../logic/store/UI/UISlice';
import { Tooltip } from 'antd';

interface IProductGamePlanProps extends IGrowerResponse
{
	SetSelectedGrowerId: (growerId: string) => void;
	ClearSelectedProductPlanId: () => void;
	cropYear: number;
}
export const ProductGamePlan = React.memo<IProductGamePlanProps>(
	function ProductGamePlan(props: IProductGamePlanProps)
	{
		const { Id, SeasonData, cropYear, ClearSelectedProductPlanId, SetSelectedGrowerId } = props;
		const currentSeason = SeasonData.find((season) => season.SeasonYear === cropYear);
		const hasPlans = currentSeason?.GamePlanSummary?.filter(plan => plan.PlanType === 'ProductGamePlan').length;
		return <Tooltip title={!hasPlans ? 'Create Acre Proposal' : ''}>
			<StyledRowCell style={{ margin: 0, height: '100%' }}>
				<Link className='ProductGamePlanLink' to='/fieldactivities/productplan' onClick={() =>
				{
					SetSelectedGrowerId(Id);
					ClearSelectedProductPlanId();
				}} style={{ display: 'contents', }}>
					<CirclePlusIcon style={{ visibility: !hasPlans ? 'visible' : 'hidden' }} />
				</Link>
				<Link className='ProductGamePlanLink' to='/fieldactivities/recall' onClick={() => SetSelectedGrowerId(Id)} style={{ display: 'contents', }}>
					<CatalogIcon style={{ visibility: hasPlans ? 'visible' : 'hidden' }} />
				</Link>
			</StyledRowCell>
		</Tooltip >;
	}
);
interface IGamePlanSummaryProps extends IGrowerResponse
{
	cropYear: number;
	SetSelectedGrowerId: (growerId: string) => void;
}
export const FieldGamePlan =
	React.memo<IGamePlanSummaryProps>(
		function GamePlan(props: IGamePlanSummaryProps)
		{
			const { SeasonData, Id, cropYear, SetSelectedGrowerId } = props;
			const currentSeason = SeasonData.find((season) => season.SeasonYear === cropYear);
			const hasPlans = currentSeason?.GamePlanSummary?.filter(plan => plan.PlanType === 'FieldGamePlan').length;
			return <Tooltip title={!hasPlans ? 'Create Field Proposal' : ''}>
				<StyledRowCell className='GamePlanIcon' style={{ margin: 0, height: '100%' }}>
					<Link className='FieldGamePlanLink'
						to={hasPlans ? '/fieldactivities/recall' : '/fieldactivities'}
						onClick={() => SetSelectedGrowerId(Id)} style={{ display: 'contents', }}>
						<CirclePlusIcon style={{ visibility: !hasPlans ? 'visible' : 'hidden' }} />
						<GamePlanIcon style={{ visibility: hasPlans ? 'visible' : 'hidden' }} />
					</Link>
				</StyledRowCell>
			</Tooltip >;
		}
	);

export interface IPlanDashboardTableProps extends IDashboardTableProps
{
	displayEnogenFlow: (selectedGrowerId: string) => void;
	userCanSeeEnogen: boolean;
}

export const PlanDashboardTable = (props: IPlanDashboardTableProps) =>
{
	const {
		DashboardSort,
		DashboardTableExpanded,
		growers,
		cropYear,
		currentCropYear,
		userCanSeeEnogen,
		userIsGrower,
		onSort,
		SetExpanded,
		ClearSelectedProductPlanId,
		SetSelectedGrowerId,
		displayEnogenFlow
	} = props;

	const columns = [
		'Name / Segment',
		'Accepted Acres / Mapped Acres',
		'Acre Proposal',
		'Field Proposal',
	];

	if (userCanSeeEnogen)
	{
		columns.push('Enogen Contracted');
	}

	// Plan-specific components
	const headerSpacing = getHeaderSpacing(columns);
	const expandedSpacing = getExpandedSpacing(columns);

	const RenderTableRow = useCallback(({ data, index, style }: ListChildComponentProps<{ rows: IGrowerResponse; ref: React.RefObject<any> }>) =>
	{
		const { rows, ref } = data;
		const grower = rows[index];

		const onExpand = (idEntry: BoolDictionary) =>
		{
			if (ref && ref.current?.resetAfterIndex)
			{
				ref.current?.resetAfterIndex(index, false);
			}
			SetExpanded(idEntry);
		};

		const rowItems = [
			<NameSegment key={`plan-cell-name-${grower.Name}-${index}`} {...grower} DashboardTableExpanded={DashboardTableExpanded} userIsGrower={userIsGrower} />,
			<Acres key={`plan-cell-acres-${grower.Name}-${index}`} {...grower} cropYear={cropYear} />,
			<ProductGamePlan key={`plan-cell-prodgameplan-${grower.Name}-${index}`} {...grower}
				ClearSelectedProductPlanId={ClearSelectedProductPlanId}
				SetSelectedGrowerId={SetSelectedGrowerId} cropYear={cropYear} />,
			<FieldGamePlan key={`plan-cell-gameplan-${grower.Name}-${index}`} {...grower} SetSelectedGrowerId={SetSelectedGrowerId} cropYear={cropYear} />,
		];

		if (userCanSeeEnogen)
		{
			rowItems.push(<EnogenContracted key={`plan-cell-enogen-${grower.Name}-${index}`} grower={grower} displayEnogenFlow={displayEnogenFlow} disabled={cropYear < currentCropYear} />);
		}

		return (
			<div key={`tableRow-${index}`}
				style={{ marginTop: '10px', ...style }}>
				<TableRow
					Id={grower.Id}
					items={rowItems}
					expanded={DashboardTableExpanded[grower.Id]}
					columnSpacing={headerSpacing}
					setExpanded={onExpand}
					expandedSection={
						<ExpandedPlan
							Grower={grower}
							currentTab={'Plan'}
							expandedSpacing={expandedSpacing}
							SetSelectedGrowerId={SetSelectedGrowerId}
							cropYear={cropYear}
							currentCropYear={currentCropYear}
							userIsGrower={userIsGrower}
						/>
					}
				/>
			</div>
		);
	}, [DashboardTableExpanded, cropYear, growers]);

	return (
		<StyledContainer>
			<TableHeader headers={columns}
				columnSpacing={headerSpacing}
				initialSorts={{ [keysToHeaders[DashboardSort.field]]: DashboardSort.sortDirection }}
				sortActions={{
					'Name / Segment': (order) => onSort(order, 'Name / Segment'),
					'Accepted Acres / Mapped Acres': (order) => onSort(order, 'Accepted Acres / Mapped Acres')
				}}
				defaultSort={{ headerName: 'Name / Segment', direction: 'ascending' }} />
			<StyledDashboardScrollableTableRows>
				<StyledDashboardTableRows rows={growers}
					renderComponent={RenderTableRow}
					itemSize={(index) => DashboardTableExpanded[growers[index].Id] ? 265 : 85} />
			</StyledDashboardScrollableTableRows>
		</StyledContainer >
	);
};
