import { useCallback, useState } from 'react';
import { Api } from '../../../logic/Api/Api';
import { BulkImportHeaderResponse } from '../../../logic/store/BulkImport/BulkImportThunks';
import { sleepOnIt } from '../../../logic/Utility/Utils';

/**
 * Custom hook to handle the import of an organization.
 * @param param - Object containing authToken, userId, destinationGrowerId, and onSuccess callback.
 * @returns Object containing importOrg function, isPublishing state, error state, and progress state.
 */
export function useImportOrganization(param: {
	authToken: string;
	userId: string;
	destinationGrowerId: string;
	onSuccess: () => unknown;
}) 
{
	const { authToken, userId, destinationGrowerId, onSuccess } = param;
	const [isPublishing, setIsPublishing] = useState(false);
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState<string>();

	/**
	 * Function to import an organization.
	 * @param sourceOrganizationId - The ID of the source organization to import.
	 */
	const importOrg = useCallback((sourceOrganizationId: string) => 
	{
		const api = new Api('/api/6', authToken, undefined);
		setIsPublishing(true);
		setError(undefined);

		async function importOrgAsync() 
		{
			try 
			{
				// Start the session
				const responseStart = await api.putAsync<string>(
					`users/${userId}/growers/${destinationGrowerId}/cropwiseimport`,
					{
						SourceUserId: userId,
						SourceOrganizationId: sourceOrganizationId
					}
				);

				if (!responseStart.Success) 
				{
					setError(responseStart.ErrorMessage);
					return;
				}

				// Publish the data
				const responsePublish = await api.putAsync<BulkImportHeaderResponse>(
					`users/${userId}/cropwiseimport/published`,
					undefined
				);

				if (!responsePublish.Success) 
				{
					setError(responsePublish.ErrorMessage);
					return;
				}

				// Poll until complete or errored
				let status = responsePublish.Data.Status;
				while (status != 'Completed') 
				{
					await sleepOnIt(500);
					const responsePoll = await api.getAsync<BulkImportHeaderResponse>(
						`users/${userId}/cropwiseimport`
					);

					if (!responsePoll.Success) 
					{
						setError(responsePublish.ErrorMessage);
						return;
					}

					const data = responsePoll.Data;

					if (data.Status === 'Error') 
					{
						setError(data.Error);
						return;
					}

					status = data.Status;
					setProgress(data.Progress);
				}

				onSuccess();
			}
			catch (e) 
			{
				setError('Error importing the organization. Please try again later.');
			}

			finally 
			{
				setIsPublishing(false);
			}
		}

		importOrgAsync();
	}, [authToken, destinationGrowerId, onSuccess, userId]);

	// Return the importOrg function and states
	return { importOrg, isPublishing, error, progress };
}
