import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { IFieldResponse } from '../../../../logic/Models/Responses/FieldResponse';
import { FieldThumbnail } from '../../../../logic/Map/FieldThumbnail';
import { MultiPolygon, Polygon } from '@turf/turf';
import { ICropPlanFieldSeedAssignment } from '../../../../logic/Models/Responses/FieldPlanResponse';
import { RoundAcres } from '../../../../logic/Utility/CalculationUtilities';

/**
 * Card displayed on a Field Inventory View for a Crop Plan
 */

const CardContainer = styled.div`
	width: 100%;
	//height: 115px;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	padding: 0px;
	margin-top: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

const MainCardContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 10px;
	margin-right: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
`;

const TitleText = styled.div`
	font-size: 16px;
	font-weight: bold;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 10px;
	width: 100%;
`;

const DisplayTextContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 11px;
	height: 50%;
	align-items: flex-start;
	padding-top: 3px;
`;

export interface IFieldInfo extends IFieldResponse
{
	FarmName: string;
	FillColor: string; // This is the color of the highest most acreage product on the field
	PredictiveYield: number;
	RemainingAcres: number;
	SeedAssignments: ICropPlanFieldSeedAssignment[];
}

interface IFieldInfoCardProps {
	fieldInfo: IFieldInfo;
	showRemainingAcresError: boolean;
}

export const FieldInfoCard = (props: IFieldInfoCardProps) =>
{
	const {
		fieldInfo,
		showRemainingAcresError,
	} = props;

	const theme = useTheme();

	return (
		<CardContainer>
			<MainCardContainer className={'MainCard_Container'}>
				<TextContainer>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div style={{ borderRadius: 4, borderColor: theme.colors.lightestGrey, borderStyle: 'solid', borderWidth: 1 }}>
							<FieldThumbnail
								geojson={fieldInfo.Boundary.type === 'MultiPolygon' ? 
									fieldInfo.Boundary as MultiPolygon : fieldInfo.Boundary as Polygon}
								style={{ fill: fieldInfo.FillColor, width: 50, height: 50 }}
								imageStyle={{ padding: 5 }}
							/>
						</div>
						<TitleText style={{ display: 'flex', flexDirection: 'column', fontSize: 16, fontFamily: theme.fonts.base }} className='Title'>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
									{fieldInfo.Name}
								</div>
								<div
									style={{
										fontSize: theme.fontSizes.tiny,
										fontWeight: theme.fontWeights.normal,
										color: theme.colors.lightGrey,
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
									}}
								>
									{fieldInfo.FarmName}
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex', flexDirection: 'row', fontSize: theme.fontSizes.extraSmall }}>
									<div style={{ fontWeight: theme.fontWeights.normal }}>Acres:</div>
									<div style={{ marginLeft: 3 }}>{RoundAcres(fieldInfo.Area).toLocaleString('en-US', {maximumFractionDigits:1})}</div>
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', fontSize: theme.fontSizes.extraSmall }}>
									<div style={{ fontWeight: theme.fontWeights.normal }}>Remaining:</div>
									<div style={{ marginLeft: 3 }}>{fieldInfo.RemainingAcres?.toLocaleString('en-US', {maximumFractionDigits:1})}</div>
								</div>
							</div>
						</TitleText>
					</div>
					{
						showRemainingAcresError &&
							<div
								style={{
									textAlign: 'right',
									fontSize: theme.fontSizes.extraSmall,
									color: theme.colors.availabilityRed,
									fontWeight: theme.fontWeights.bold,
									marginTop: -7
								}}>No changes were saved.<br />Fields cannot have more product than acreage.<br />Update your assignments to save them.</div>
					}
					<DisplayTextContainer className='DisplayTextContainer'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', flexDirection: 'row', fontSize: theme.fontSizes.extraSmall, fontWeight: theme.fontWeights.normal }}>
								<div>Predictive Yield:</div>
								<div style={{ marginLeft: 3 }}>{fieldInfo.PredictiveYield?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
							</div>
						</div>
					</DisplayTextContainer>
				</TextContainer>
			</MainCardContainer>
		</CardContainer>
	);
};