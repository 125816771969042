import React from 'react';
import { Button, ButtonProps, Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import { ReactComponent as PersonIcon } from '../../assets/images/PersonLogo.svg';
import { ReactComponent as MagnifyingGlassIcon } from '../../assets/images/MagnifyingGlass.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/FilterIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/PlusIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

type Variant = 'profile' | 'magnifyingGlass' | 'filter' | 'plus' | 'close';
interface IIconButtonProps extends React.HTMLAttributes<HTMLButtonElement>
{
	// use EITHER defined variant OR pass in icon as a child component
	variant?: Variant;

	containerStyle?: React.CSSProperties;
	color?: string;
	fillcolor?: string;
	hoverbgcolor?: string;
	tooltip?: string;
	showDisabledTooltip?: boolean;

	outsidedHoverBackground?: boolean;
}

const IconMap: { [key in Variant]: React.ReactNode } = {
	profile: <PersonIcon />,
	magnifyingGlass: <MagnifyingGlassIcon style={{ position: 'relative', top: -3, left: 1 }}/>,
	filter: <FilterIcon style={{ position: 'relative', top: -2 }} />,
	plus: <PlusIcon style={{ position: 'relative', top: -3 }} />,
	close: <CloseIcon />
};

const StyledContainer = styled.div`
	.outsided-bg {
		display: none;
	}
	:hover>.outsided-bg {
		display: block;
	}
`;

const StyledButton = styled(Button) <IIconButtonProps & Partial<ButtonProps> & Partial<TooltipPropsWithTitle>>`
	:hover, :focus {
		background-color: ${props => props.hoverbgcolor};
	}
	display: flex;
	justify-content: center;
	align-items: center;
	svg > path {
		fill: ${props => props.fillcolor ?? themeGet('colors.darkGrey')};
	}
	&:disabled {
		a > svg > path {
			fill: ${themeGet('colors.lightestGrey')};
		}
		svg > path {
			fill: ${themeGet('colors.lightestGrey')};
		}
	}
`;

/**
 * @component A button with a clickable icon.
 */
export const IconButton = (props: IIconButtonProps & Partial<TooltipPropsWithTitle> & Partial<ButtonProps>) =>
{
	const {
		children,
		containerStyle,
		fillcolor,
		disabled,
		hoverbgcolor = 'transparent',
		placement,
		tooltip,
		variant,
		showDisabledTooltip,
		outsidedHoverBackground,
		...rest
	} = props;

	const theme = useTheme();
	
	const IconBtn = <StyledButton
		{...rest}
		disabled={disabled}
		type='text'
		fillcolor={fillcolor ? fillcolor : theme.colors.darkGrey }
		hoverbgcolor={ outsidedHoverBackground ? 'transparent' : hoverbgcolor}
		icon={IconMap[variant] || children}
	/>;

	return (<>
		{
			disabled ?
				showDisabledTooltip ?
					<Tooltip title={tooltip} placement={placement} mouseLeaveDelay={0}>
						<span style={{ cursor: 'not-allowed' }}>{IconBtn}</span>
					</Tooltip>
					:
					IconBtn
				:
				<StyledContainer style={containerStyle ? containerStyle : { position: 'relative'}}>
					{outsidedHoverBackground && <div 
						className='outsided-bg'
						style={{ 
							backgroundColor: hoverbgcolor, 
							left: -8,
							top: -8,
							width: 'calc(100% + 16px)', 
							height: 'calc(100% + 16px)', 
							position: 'absolute',
							borderRadius: '50%' }} /> }
					<Tooltip title={tooltip} placement={placement} mouseLeaveDelay={0}>
						{IconBtn}
					</Tooltip>
				</StyledContainer>
		}
	</>);
};