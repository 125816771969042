// 404s and such
export class NetError
{
	public readonly message: string;
	public readonly code: number;

	public constructor(
		message: string,
		code: number,
	)
	{
		this.message = message;
		this.code = code;
	}
}