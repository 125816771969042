import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IFarmResponse } from '../../Models/Responses/FarmResponse';
import { saveField, ISaveFieldRequest } from './FieldThunks';
import { Geometry } from '@turf/turf';
import { DrawingInteractionMode, MapInteractionMode } from '../UI/UISlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IFarmRequest
{
	growerId: string;
	successMessage?: string;
}

export interface IFarms
{
	Farms: IFarmResponse[];
	RequestedGrowerId: string;
}

export const getFarms = createTracedAsyncThunk<IFarms, IFarmRequest, { dispatch: AppDispatch, state: RootState }>(
	'farms/get',
	async (context, request: IFarmRequest, thunkAPI) => 
	{
		try
		{
			const { auth, crops } = thunkAPI.getState();
			const api = new Api('/api/6', auth.userAuthToken, context);
			const response = await api.getAsync<IFarmResponse[]>(`growers/${request.growerId}/years/${crops.CropYear}/farm-profile`);

			if (response.ErrorCode === null && response.Success) 
			{
				const farmResponse: IFarms = {
					Farms: response.Data,
					RequestedGrowerId: request.growerId
				};
				return farmResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export interface ISaveFarmRequest
{
	Name: string;
	FieldName: string;
	FieldArea: number;
	FieldBoundary: Geometry;
	SelectedGrowerId: string;
	MapMode: MapInteractionMode;
	DrawingInteractionMode: DrawingInteractionMode;
}

interface IBaseFarm
{
	RequestedGrowerId: string;
}
export interface IFarm extends IBaseFarm
{
	Farm: IFarmResponse;
}

export const saveFarm = createTracedAsyncThunk<IFarm, ISaveFarmRequest, { dispatch: AppDispatch, state: RootState }>(
	'farm/save',
	async (context, request: ISaveFarmRequest, thunkAPI) => 
	{
		try
		{
			const { SelectedGrowerId, FieldArea, FieldBoundary, FieldName, MapMode, DrawingInteractionMode } = request;

			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.postAsync<IFarmResponse>(
				`growers/${SelectedGrowerId}/farms`, request);

			if (response.ErrorCode === null && response.Success) 
			{
				const farmsResponse: IFarm = {
					Farm: response.Data,
					RequestedGrowerId: SelectedGrowerId
				};

				try
				{
					const fieldRequest: ISaveFieldRequest = {
						Name: FieldName,
						FarmId: response.Data.Id,
						Area: FieldArea,
						Boundary: FieldBoundary,
						SelectedGrowerId: SelectedGrowerId,
						MapMode: MapMode,
						DrawingInteractionMode: DrawingInteractionMode
					};
					thunkAPI.dispatch(saveField(fieldRequest));
				}
				catch (e)
				{
					console.log(e);
				}
				return farmsResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IMultiDeleteFarmRequest
{
	farmIds: string[];
}

export interface IFarms extends IBaseFarm
{
	Farms: IFarmResponse[];
	SuccessMessage?: string;
}

export const deleteMultipleFarms = createTracedAsyncThunk<IFarms, IMultiDeleteFarmRequest, { dispatch: AppDispatch, state: RootState }>(
	'farms/delete',
	async (context, request: IMultiDeleteFarmRequest, thunkAPI) =>
	{
		try
		{
			const { auth, ui } = thunkAPI.getState();
			const { SelectedGrowerId } = ui;
			const api = new Api('/api/6', auth.userAuthToken, context);

			const { farmIds } = request;
			const response = await api.deleteAsync<IFarmResponse[]>(`growers/${SelectedGrowerId}/farms`, { FarmIds: farmIds });

			if (response.ErrorCode === null && response.Success)
			{
				return {
					Farms: response.Data,
					RequestedGrowerId: SelectedGrowerId
				};
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IEditRequest
{
	Id: string;
	EditedName: string;
}

export const renameFarm = createTracedAsyncThunk<IFarmResponse, IEditRequest, { dispatch: AppDispatch, state: RootState }>(
	'farm/rename',
	async (context, request: IEditRequest, thunkAPI) =>
	{
		try
		{
			const { auth } = thunkAPI.getState();
			const api = new Api('/api/6', auth.userAuthToken, context);

			const { Id, EditedName } = request;
			const response = await api.putAsync<IFarmResponse>(
				`farms/${Id}/rename`, { Name: EditedName }
			);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);



