import React from 'react';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/Trashcan.svg';
import { IconButton } from '../../../../components/IconButton/IconButton';
import styled, { useTheme } from 'styled-components';
import { ICompetitorSeedItem } from './CompetitorRow';
import { themeGet } from '@styled-system/theme-get';

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
	path {
		fill: ${themeGet('colors.darkGrey')};
	}
`;
export interface IDeletedCompetitorRowProps
{
	competitorSeed: ICompetitorSeedItem;
	index: number;
	unDeleteRow: (index: number) => void;
}

export const DeletedCompetitorSeedRow = (props: IDeletedCompetitorRowProps) =>
{
	const { competitorSeed, index, unDeleteRow } = props;

	const theme = useTheme();

	const onUnDelete = () =>
	{
		unDeleteRow(index);
	};

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			padding: 10,
			height: 50,
			color: theme.colors.redLM,
			alignItems: 'center',
			borderWidth: 1,
			borderColor: theme.colors.lightestGrey,
			borderRadius: 4,
			borderStyle: 'solid',
			marginRight: 5,
			marginBottom: 2
		}}>
			<div>
				<span style={{ fontWeight: theme.fontWeights.bold }}>Brand: </span>{competitorSeed.Brand}
			</div>
			{
				competitorSeed.Product && 
				<div style={{ marginLeft: 5 }}>
					<span style={{ fontWeight: theme.fontWeights.bold }}>Product: </span>{competitorSeed.Product}
				</div>
			}
			{
				competitorSeed.RM &&
				<div style={{ marginLeft: 5 }}>
					<span style={{ fontWeight: theme.fontWeights.bold }}>RM: </span>{competitorSeed.RM}
				</div>
			}
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto' }}>
				<StyledIconButton className='unDelete-button' onClick={onUnDelete}
					tooltip='Un-Delete'
					placement='right'
					fillcolor={theme.colors.redLM}
					hoverbgcolor={theme.colors.lightestGrey}>
					<DeleteIcon />
				</StyledIconButton>
			</div>
		</div>
	);
};