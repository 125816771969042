import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { FileUploadMain } from './FileUpload/FileUploadMain';
import { StewardshipMain } from './EnogenContracts/Stewardship';
import { Dashboard } from './Dashboard';
import { ThemeName } from '../../logic/store/UI/UISlice';

interface IDashboardRouterProps extends PropsFromRedux
{
	changeTheme: (name: ThemeName) => void;
}

const DashboardRouterComponent = (props: IDashboardRouterProps) =>
{
	const { SelectedEnogenContractId, SelectedGrowerId, changeTheme } = props;

	return (
		<div style={{ height: '100%' }}>
			<Switch>
				<Route path='/dashboard/fileupload'>
					{
						!SelectedGrowerId
							? <Redirect from={'/dashboard/fileupload'} to={'/dashboard'} />
							: <FileUploadMain />
					}
				</Route>
				<Route path='/dashboard/contract/stewardship'>
					{
						!SelectedEnogenContractId
							? <Redirect from={'/dashboard/contract/stewardship'} to={'/dashboard'} />
							: <StewardshipMain />
					}
				</Route>
				<Route path={['*']} >
					<Dashboard changeTheme={changeTheme} />
				</Route>
			</Switch>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	SelectedGrowerId: state.ui.SelectedGrowerId,
	SelectedEnogenContractId: state.ui.SelectedEnogenContractId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const DashboardRouter = connector(DashboardRouterComponent);


