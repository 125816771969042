import React, { useEffect, useState } from 'react';
import { Api } from '../../logic/Api/Api';
import { IRegistrationQueryResponse } from './Register';

/**
 * Fetch an invitation's details from the server
 */
export function useGetInvitation(
	invitationCode: string, 
	form, 
	api: Api, 
	setMessage: (message:React.ReactNode) => unknown, 
	startLoading: () => void, 
	endLoading: () => void) 
{
	const [cropwiseUserExists, setCropwiseUserExists] = useState<boolean>();
	useEffect(() => 
	{
		startLoading();
		async function exec() 
		{
			try 
			{
				const response = await api.getAsync<IRegistrationQueryResponse>(`5/registration/${invitationCode}`);
				if (response.Success) 
				{
					form.setFieldValue('email', response.Data.Username);
					form.setFieldValue('name', response.Data.Name);
					setCropwiseUserExists(response.Data.CropwiseUserExists);
				}
				else if (response.ErrorCode === 'user_already_registered') 
				{
					setMessage(<div>This invitation has already been claimed.You can <a href="/login">login here</a>. If you have forgotten your password, you can reset it on the login page.</div>);
				}

				else 
				{
					setMessage(<div>This invitation is not valid. If you have questions please reach out to your <a href="https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate">support specialist.</a></div>);
				}
			}
			catch (err) 
			{
				setMessage(<>An unknown error occurred. Please try again later.</>);
			}
			finally 
			{
				endLoading();
			}
		}
		exec();
	}, [invitationCode, api, form, setMessage, startLoading, endLoading]);

	return cropwiseUserExists;
}
