import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import { ReactComponent as CornIcon } from '../../FieldActivities/FieldPlan/Icons/CornCircleIcon.svg';
import { ReactComponent as SoyIcon } from '../../FieldActivities/FieldPlan/Icons/SoybeanCircleIcon.svg';
import { IHybridResponse } from '../../../logic/Models/Responses/SeedResponse';
import { cornId } from '../../../logic/store/Seeds/CropsSlice';

export interface IPpgSelectedSeedRowProps
{
	seed: IHybridResponse;
	deleteSeed: (selectedSeedIdToDelete: string) => void;
}

export const PpgSelectedSeedRow = (props: IPpgSelectedSeedRowProps) =>
{
	const { seed, deleteSeed } = props;

	const theme = useTheme();

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ display: 'flex', flexDirection: 'row', width: '75%', alignItems: 'center' }}>
					<div style={{ display: 'flex', flexDirection: 'row', width: '70%', alignItems: 'center' }}>
						<div>
							{ seed.CropId === cornId ? 
								<CornIcon width={22} style={{ position: 'relative', top: 3 }} /> 
								: 
								<SoyIcon width={22} style={{ position: 'relative', top: 3 }} />
							}
						</div>
						<div style={{ marginLeft: 5, fontWeight: theme.fontWeights.bold }}>
							{seed.Name}
						</div>
					</div>
					<div style={{ marginLeft: 10, fontWeight: theme.fontWeights.bold }}>
						RM:
					</div>
					<div style={{ marginLeft: 5 }}>
						{seed.RelativeMaturity}
					</div>
				</div>
				{/* {`Name: ${seed.Name} RM: ${seed.RelativeMaturity}`} */}
				<DeleteIcon
					style={{ fill: theme.colors.darkGrey, cursor: 'pointer', marginLeft: 20, position: 'relative', top: -2 }}
					width={24}
					onClick={() => deleteSeed(seed.Id)}
				/>
			</div>
		</div>
	);
};