'use strict';
import { useMemo } from 'react';
import { SelectionState, ICrossReferencedGrower } from './DataTransferTroubleshootingTool';

/**
 * Determine which growers match from source to dest by foundation Id, and why they can or cannoy be transfered
 * @param source The source grower state to copy from
 * @param dest The destination grower state to copy to
 */
export function useViableCrossReferences(source: SelectionState, dest: SelectionState) 
{
	const potentialPairings = useMemo(() => 
	{
		return source.growers
			?.map(sg => ([sg.Id, dest.growers?.find(dg => sg.FoundationId == dg.FoundationId)] as const))
			.filter(pair => pair[1]) ?? [];
	}, [source.growers, dest.growers]);

	const crossReferencePairings: Record<string, ICrossReferencedGrower> = useMemo(() => 
	{
		if (!potentialPairings?.length) 
		{
			return undefined;
		}

		const crossReference = Object.fromEntries(potentialPairings.map(([sourceGrowerId, destGrower]) => 
		{
			const foundationId = destGrower.FoundationId;
			if (!source.selectedGrower?.Farms?.length) 
			{
				return [foundationId, { sourceGrowerId, destGrowerId: destGrower.Id, foundationId, valid: false, reason: 'No farms in source grower' }];
			}

			const dupe = source.selectedGrower?.Farms?.find(sfm => dest.selectedGrower?.Farms?.some(dfm => sfm.Name === dfm.Name));
			if (dupe) 
			{
				return [foundationId, { sourceGrowerId, destGrowerId: destGrower.Id, foundationId, valid: false, reason: `Account Farm '${dupe.Name}' exists in the destination already.` }];
			}

			return [foundationId, { sourceGrowerId, destGrowerId: destGrower.Id, foundationId, valid: true, reason: '' }];
		}));

		return crossReference;
	}, [potentialPairings, source.selectedGrower?.Farms, dest.selectedGrower?.Farms]);
	return { potentialPairings, crossReferencePairings };
}
