import { sumBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { setFarmKey, setFieldKey } from '../../../logic/store/BulkImport/BulkImportSlice';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { makeDispatch } from '../../../logic/Utility/Utils';
import { ReactComponent as InfoIcon } from '../../../assets/images/AvailabilityIcons/Info.svg';
import { ReactComponent as Conflict } from '../../../assets/images/Conflict.svg';
import { ReactComponent as ClipIcon } from '../../../assets/images/Clip.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import Modal from 'antd/lib/modal/Modal';
import Tooltip from 'antd/es/tooltip';
import { themeGet } from '@styled-system/theme-get';

interface IColumnSelectProps extends PropsFromRedux
{
	columnNames: string[];
	selectNextConflict: () => unknown;
}

const Row = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.infoIcon {
		margin-left: 4px;
		
		path, circle.fill {
			fill: ${themeGet('colors.darkGrey')};
		}
	}
`;

const LegendModal = (props: { onCancel: () => unknown, open: boolean }) =>
{
	const { onCancel, open } = props;
	return (
		<Modal closable footer={null} onCancel={onCancel} open={open} title='Resolving Field Conflicts'>
			<div style={{ display: 'grid', gridTemplateColumns: '30px auto' }}>
				<ClipIcon />
				<div><b>Clip Field</b>: Overlapping acres will be clipped from the selected field</div>
				<DeleteIcon style={{ fill: 'black' }} />
				<div><b>Remove Field</b>: The field will not be imported and will be removed from the list</div>
			</div>
		</Modal>
	);
};

const ColumnSelectComponent = (props: IColumnSelectProps) =>
{
	const {
		columnNames,
		FarmKey,
		farms,
		FieldKey,
		setFarmKey,
		setFieldKey,
		selectNextConflict,
	} = props;

	const fields = useMemo(() => farms ? Object.values(farms).flatMap(farm => Object.values(farm.Fields)) : [], [farms]);
	const numberOfConflicts = useMemo(() => sumBy(fields, field => field.currentConflicts.length), [fields]);
	const [modalOpen, setModalOpen] = useState(false);

	const hasConflicts = numberOfConflicts > 0;
	return <div>
		<Row>
			Farm:
			<Dropdown options={columnNames} onSelect={setFarmKey} selected={FarmKey} />
		</Row>
		<Row>
			Field:
			<Dropdown options={columnNames} onSelect={setFieldKey} selected={FieldKey} />
		</Row>
		<Row style={{ fontSize: 14, fontWeight: 700, fontFamily:'Roboto Condensed' }}>
			<div>Imported Field(s)</div>
		</Row>
		<Row style={{ fontSize: 12, fontWeight: 700, marginTop: 4, marginBottom: 8 }}>
			<div style={{ flexGrow: 1 }}>Fields: {fields.length}</div>
			<Tooltip title={hasConflicts ? 'Click this to select the next conflict' : undefined}>
				<div style={{ 
					textDecoration: hasConflicts ? 'underline' : undefined, 
					cursor: hasConflicts ? 'pointer' : undefined,
					display: 'flex', 
					alignItems:'center', 
				}} 
				onClick={selectNextConflict}>
					{numberOfConflicts > 0 && <Conflict width={20} height={20} />}
					Conflicts: {numberOfConflicts}
				</div>
			</Tooltip>
			<InfoIcon style={{ width: 24, height: 24, cursor:'pointer'}} className='infoIcon' onClick={() => setModalOpen(true)} />
		</Row>
		<LegendModal onCancel={() => setModalOpen(false)} open={modalOpen} />
	</div>;
};

const mapStateToProps = (state: RootState) =>
{
	return {
		FarmKey: state.bulkImport.farmKey,
		farms: state.bulkImport.farms,
		FieldKey: state.bulkImport.fieldKey,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		setFarmKey: makeDispatch(dispatch, setFarmKey),
		setFieldKey: makeDispatch(dispatch, setFieldKey),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const BulkImportColumnSelect = connector(ColumnSelectComponent);
