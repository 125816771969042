import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

interface IPermissibleProps
{
	exact?: boolean;
	path: string | string[];
	children?: JSX.Element;
	permissiable: boolean; // Pass the "haspermission" boolean here
}

/**
 * Will redirect a user back to the dashboard if they do not have the permission to view this page
 * @param props 
 * @returns 
 */
export const PermissibleRoute = (props: IPermissibleProps) =>
{
	const { children, permissiable } = props;
	const location = useLocation();

	return (
		<Route
			render={(otherProps) =>
				permissiable ? (children)
					:
					(
						<Redirect
							to={{ pathname: '/dashboard' + window.location.search, state: { from: location } }}
						/>
					)
			}
		/>
	);
};