import React, { useCallback, useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { FieldPlanMap } from '../../../logic/Map/FieldPlanMap/FieldPlanMap';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { ConnectedAgronomicsSeedSelection } from './AgronomicsSeedSelection';
import { IRecommendationOptions } from '../../../logic/store/Plans/ProductGamePlanSlice';
import { clearFullCropPlanData, clearState as clearFieldPlanState, setHighlightHybridId } from '../../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import { ConnectedCropPlanOverview } from './CropPlanOverview';
import { ConnectedProductInventory } from './ProductInventory';
import { ConnectedManageFields } from './ManageFields';
import { clearSelectedFields, clearSelectedPlanIdState, setFieldIsSelected } from '../../../logic/store/UI/UISlice';
import { setSelectedCropPlanId } from '../../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import { ConnectedFieldInventoryView } from './FieldInventory';
import { makeDispatch } from '../../../logic/Utility/Utils';
import { useAmplitudePassthrough, useDynamicEvent } from '../../../logic/Utility/useAmplitude';
import { useCropPlanTrackingState } from './useCropPlanTrackingState';
import { useScopedSession } from '../../../tracing/session';
import { EventSelectionTags, EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames, EventNavigationNames } from '../../../tracing/EventNames';

const FieldPlanMainContainer = styled.div`
	min-width: 382px;
	max-width: 382px;
	background-color: ${themeGet('colors.white')};
	display: flex;
	flex-direction: column;
`;

const FieldPlanInnerContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const CrumbContainer = styled.div`
	align-items: center;
	background-color: #F3F4F6;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 35px;
	min-height: 35px;
	padding-left: 17px;
	padding-right: 25px;
`;

interface IInnerSvgProps
{
	fillinner?: string;
}
const StyledEllipse = styled(Ellipse)<IInnerSvgProps>`
	.circleInner {
		fill: ${props => props.fillinner ? props.fillinner : props.theme.colors.darkGrey};
	}
`;

export interface IFieldPlanAgronomicsSettings extends IRecommendationOptions
{

}

interface IFieldPlanComponentProps
{
}

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	const SelectedCropPlan = state.fieldplan.selectedCropPlanId ? 
		state.fieldplan.fullCropPlanData && state.fieldplan.fullCropPlanData.length > 0 ?
			state.fieldplan.fullCropPlanData.find(cp => cp.Id === state.fieldplan.selectedCropPlanId) : undefined
		: undefined;

	return {
		SelectedCropPlan,
		SelectedCropPlanId: state.fieldplan.selectedCropPlanId,
		SelectedFieldIds: state.ui.SelectedFields,
		SelectedFieldPlan: state.fieldplan.currentFieldPlan,
		SelectedGrower,
		SelectedPlanId: state.ui.SelectedPlanId,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		ClearFieldPlanState: makeDispatch(dispatch, clearFieldPlanState),
		ClearSelectedFieldIds: makeDispatch(dispatch, clearSelectedFields),
		ClearSelectedPlanId: makeDispatch(dispatch, clearSelectedPlanIdState),
		SelectCropPlanId: makeDispatch(dispatch, setSelectedCropPlanId),
		SetHighlightedHybridId: makeDispatch(dispatch, setHighlightHybridId),
		SetSelectedFieldIds: makeDispatch(dispatch, setFieldIsSelected),
		ClearFullCropPlanData: makeDispatch(dispatch, clearFullCropPlanData),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export enum FieldPlanViewState
{
	CreateCropPlan, // Creating a Crop Plan View
	CropPlanOverview, // The List of all CropPlans
	ManageFields, // The Field Listing where a user can change what crop is assigned to a field
	ProductInventory, // The Full listing of products for a selected Crop Plan
}

interface IFieldPlanViewState
{
	view: FieldPlanViewState.CreateCropPlan
	| FieldPlanViewState.CropPlanOverview
	| FieldPlanViewState.ManageFields 
	| FieldPlanViewState.ProductInventory
}

type IFieldPlanViewMode = IFieldPlanViewState;

/**
 * The Field Plan page 
 */
const FieldPlan = (props: IFieldPlanComponentProps & PropsFromRedux) =>
{
	const {
		SelectedCropPlan,
		SelectedCropPlanId,
		SelectedFieldIds,
		SelectedFieldPlan,
		SelectedGrower,
		SelectedPlanId,
		ClearFieldPlanState,
		ClearFullCropPlanData,
		ClearSelectedFieldIds,
		ClearSelectedPlanId,
		SelectCropPlanId,
		SetHighlightedHybridId,
		SetSelectedFieldIds,
	} = props;

	const theme = useTheme();
	const history = useHistory();

	// Start in the agronomics page if we have selected fields set already, otherwise the pick-fields page
	const [currentViewState, setCurrentViewState] = useState<IFieldPlanViewMode>({view: SelectedFieldIds?.length ? FieldPlanViewState.CreateCropPlan : FieldPlanViewState.ManageFields});
	const [showFieldInventory, setShowFieldInventory] = useState<boolean>(false);
	const [selectedFieldId, setSelectedFieldId] = useState<string>();
	const [fieldArrayPosition, setFieldArrayPosition] = useState<string>('');
	
	const trackingData = useCropPlanTrackingState();
	const session = useScopedSession(FieldPlan.name, trackingData, {
		[EventStructureTags.PageContext]: 'field_plan',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	const trackViewFieldPlan = useDynamicEvent(session);
	// Register a view of this page
	useEffect(() => 
	{
		if(SelectedFieldPlan?.Id)
		{
			trackViewFieldPlan(EventFieldPlanNames.ViewFieldPlan, {
				[EventSelectionTags.PlanId]: SelectedFieldPlan.Id
			});
		}
	}, [SelectedFieldPlan?.Id, trackViewFieldPlan]);

	// Navigate to the overview crop plan list
	const gotoCropPlanList = useAmplitudePassthrough(session, EventNavigationNames.To, () => 
	{
		setCurrentViewState({ view: FieldPlanViewState.CropPlanOverview });
		setShowFieldInventory(false);
		setSelectedFieldId(undefined);
		SelectCropPlanId(undefined);
		ClearFullCropPlanData();
		ClearSelectedFieldIds();
	}, [ClearSelectedFieldIds, SelectCropPlanId], {
		[EventStructureTags.DestinationName]: 'crop_plan_list'
	});

	// Navigate to the agronomic settings page
	const gotoAgronomicSettings = useAmplitudePassthrough(session, EventNavigationNames.To, () =>
	{
		setCurrentViewState({ view: FieldPlanViewState.CreateCropPlan });
		setShowFieldInventory(false);
		setSelectedFieldId(undefined);
	}, [], {
		[EventStructureTags.DestinationName]: 'agronomic_settings'
	});

	// Navigate to the manage fields page
	const gotoManageFields =  useAmplitudePassthrough(session, EventNavigationNames.To,() => 
	{
		setShowFieldInventory(false);
		setSelectedFieldId(undefined);
		ClearSelectedFieldIds();
		setCurrentViewState({ view : FieldPlanViewState.ManageFields });
	}, [], {
		[EventStructureTags.DestinationName]: 'manage_fields'
	});

	// Navigate back to Crop Plan View
	const gotoSelectedCropPlanView = useAmplitudePassthrough(session, EventNavigationNames.To, () =>
	{
		setCurrentViewState({ view: FieldPlanViewState.ProductInventory });
	},[], {
		[EventStructureTags.DestinationName]: 'product_inventory'
	});

	// Navigate to the recall page
	const returnToRecallPage = useAmplitudePassthrough(session, EventNavigationNames.Back, () =>
	{
		clearPlanData();
		history.push('/fieldactivities/recall');
	}, [history], {
		[EventStructureTags.DestinationUrl]: '/fieldactivities/recall',
		[EventStructureTags.DestinationName]: 'recall'
	});

	const selectCropPlan = useAmplitudePassthrough(session, EventFieldPlanNames.SelectCropPlan, (planId: string) =>
	{
		if (currentViewState.view === FieldPlanViewState.CreateCropPlan)
		{
			return;
		}

		if(!SelectedGrower || !SelectedFieldPlan)
		{
			return;
		}
		
		const plan = SelectedFieldPlan.CropPlans.find(cropPlan => cropPlan.Id === planId);
		if(!plan)
		{
			return;
		}

		SelectCropPlanId(planId);
		
		// If a plan is selected, make sure its fields are selected too.
		const selectedFieldsTotal = {
			// Start by assuming every is deselected
			...Object.fromEntries(SelectedGrower.Farms.flatMap(fm => fm.Fields).map(f => [f.Id, false])),
			// Apply any checked field setting them to true
			...Object.fromEntries(plan.FieldIds.map(id => [id, true]))
		};
		SetSelectedFieldIds(selectedFieldsTotal);

		if(!SelectedFieldPlan.CropPlans.find(cropPlan => cropPlan.Id === planId)?.FieldIds.length)
		{
			// If there are no fields assigned to this plan, force the user to add fields first.
			setCurrentViewState({view: FieldPlanViewState.ManageFields});
		}
		else
		{
			setCurrentViewState({view: FieldPlanViewState.ProductInventory});
		}
	},[SelectedFieldPlan]);

	const selectField = (fieldId: string | undefined) =>
	{
		if (currentViewState.view !== FieldPlanViewState.ProductInventory || !SelectedCropPlan)
		{
			return;
		}

		const fieldIds = SelectedCropPlan.Fields.map(f => f.FieldId);
		const currentFieldIndex = fieldIds.findIndex(f => f === fieldId);

		setFieldArrayPosition(`Field ${currentFieldIndex + 1} out of ${fieldIds.length}`);

		setSelectedFieldId(fieldId);

		// Turn off any hybrid-field highlighting from a selection on the Product Inventory panel
		SetHighlightedHybridId(undefined);

		// Tell the Field Inventory Panel to display
		setShowFieldInventory(true);
	};

	const toggleFieldInventoryPanel = useAmplitudePassthrough(session, EventFieldPlanNames.SelectCropPlan, () =>
	{
		if (!SelectedCropPlan)
		{
			return;
		}

		const fieldIds = SelectedCropPlan.Fields.map(f => f.FieldId);
		if (fieldIds && fieldIds.length > 0)
		{
			// If we're about to toggle closed, clear the selected field
			if (showFieldInventory)
			{
				selectField(undefined);
			}
			else
			{
				SetHighlightedHybridId(undefined); // Turn off any hybrid highlight
				selectField(fieldIds[0]);
			}
			
			setShowFieldInventory(!showFieldInventory);
		}

	},[showFieldInventory, SelectedCropPlan, selectField]);

	useEffect(() =>
	{
		if (SelectedPlanId)
		{
			gotoCropPlanList();
		}
	},[SelectedPlanId]);

	// On navigating away we should clear the plan states
	const clearPlanData = () =>
	{
		setShowFieldInventory(false);
		setSelectedFieldId(undefined);
		ClearFieldPlanState();
		ClearSelectedPlanId();
	};

	const onAdvanceFromFields = useCallback(async () => 
	{
		// For a new plan we flow into the agronomic settings
		if(!SelectedCropPlanId)
		{
			gotoAgronomicSettings();
		}
		// Otherwise we wait for the polling to finish here
		else 
		{
			gotoCropPlanList();
		}
	}, [SelectedCropPlanId, gotoCropPlanList, gotoAgronomicSettings]);

	// Determine the crumb trail based on the view
	const determineCrumbTrail = () =>
	{
		switch(currentViewState.view)
		{
			case FieldPlanViewState.CreateCropPlan :
				return (
					<CrumbContainer className='FieldPlan_CrumbtrailContainer'>
						<Link to='/fieldactivities' className='linkTo_FieldList' onClick={clearPlanData}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>{SelectedGrower.Name}</span>
						</Link>
						<div onClick={gotoCropPlanList} className='linkTo_CropPlanOverview' style={{ cursor: 'pointer' }}>
							<StyledEllipse style={{ margin: '2px 10px' }} fillinner={ theme.colors.lightGrey } />
							<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>Proposal</span>
						</div>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								style={{ fontWeight: theme.fontWeights.bold }}
							>
								{'Select Seed'}
							</span>
						</div>
					</CrumbContainer>
				);
			case FieldPlanViewState.CropPlanOverview :
				return (
					<CrumbContainer className='FieldPlan_CrumbtrailContainer'>
						<Link to='/fieldactivities' className='linkTo_FieldList' onClick={clearPlanData}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>{SelectedGrower.Name}</span>
						</Link>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								onClick={gotoCropPlanList}
								style={{ color: theme.colors.darkGrey, fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal }}
							>
								{'Field Proposal'}
							</span>
						</div>
					</CrumbContainer>
				);
			case FieldPlanViewState.ProductInventory :
				return (
					<CrumbContainer className='FieldPlan_CrumbtrailContainer'>
						<Link to='/fieldactivities' className='linkTo_FieldList' onClick={clearPlanData}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>{SelectedGrower.Name}</span>
						</Link>
						<div onClick={gotoCropPlanList} className='linkTo_CropPlanOverview' style={{ cursor: 'pointer' }}>
							<StyledEllipse style={{ margin: '2px 10px' }} fillinner={ theme.colors.lightGrey } />
							<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>Proposal</span>
						</div>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								style={{ fontWeight: theme.fontWeights.bold }}
							>
								{'Crop Plan'}
							</span>
						</div>
					</CrumbContainer>
				);
			case FieldPlanViewState.ManageFields :
				return (
					<CrumbContainer className='FieldPlan_CrumbtrailContainer'>
						<div onClick={gotoCropPlanList} className='linkTo_CropPlanOverview' style={{ cursor: 'pointer' }}>
							<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
							<span style={{ color: theme.colors.lightGrey, fontFamily: theme.fonts.heading }}>{SelectedGrower.Name}</span>
						</div>
						<div>
							<Ellipse style={{ margin: '2px 10px' }}/>
							<span
								style={{ fontWeight: theme.fontWeights.bold }}
							>
								{ SelectedCropPlanId ? 'Manage Fields' : 'Add Plan' }
							</span>
						</div>
					</CrumbContainer>
				);
		}
	};

	// Determine what view state to display
	const determineView = () =>
	{
		switch(currentViewState.view)
		{
			case FieldPlanViewState.CreateCropPlan :
				return (
					<ConnectedAgronomicsSeedSelection
						returnToCropPlanList={SelectedCropPlan ? gotoSelectedCropPlanView : gotoCropPlanList}
						clearPlanData={clearPlanData}
					/>
				);
			case FieldPlanViewState.CropPlanOverview :
				return (
					<ConnectedCropPlanOverview
						selectCropPlan={selectCropPlan}
						addNewCropPlan={gotoManageFields}
					/>
				);
			case FieldPlanViewState.ManageFields :
				return (
					<ConnectedManageFields
						onAdvance={onAdvanceFromFields}
						onCancel={SelectedFieldPlan ? SelectedCropPlan ? gotoSelectedCropPlanView : gotoCropPlanList : returnToRecallPage }
					/>
				);
			case FieldPlanViewState.ProductInventory :
				return (
					<ConnectedProductInventory
						returnToCropPlanList={gotoCropPlanList}
						toggleFieldInventory={toggleFieldInventoryPanel}
						editFields={gotoManageFields}
						editProducts={gotoAgronomicSettings}
					/>
				);
		}
	};

	const onNextField = useAmplitudePassthrough(session, EventFieldPlanNames.NextField, () =>
	{
		if (SelectedCropPlan)
		{
			const fieldIds = SelectedCropPlan.Fields.map(f => f.FieldId);

			if (fieldIds.length > 0)
			{
				const currentFieldIndex = fieldIds.findIndex(f => f === selectedFieldId);
				if ((currentFieldIndex + 1) < fieldIds.length)
				{
					selectField(fieldIds[currentFieldIndex + 1]);
				}
				else
				{
					selectField(fieldIds[0]);
				}
			}
		}
	},[SelectedCropPlan, selectedFieldId]);

	const onPreviousField = useAmplitudePassthrough(session, EventFieldPlanNames.PreviousField, () =>
	{
		if (SelectedCropPlan)
		{
			const fieldIds = SelectedCropPlan.Fields.map(f => f.FieldId);

			if (fieldIds.length > 0)
			{
				const currentFieldIndex = fieldIds.findIndex(f => f === selectedFieldId);
				if ((currentFieldIndex - 1) >= 0)
				{
					selectField(fieldIds[currentFieldIndex - 1]);
				}
				else
				{
					selectField(fieldIds[fieldIds.length - 1]);
				}
			}
		}
	},[SelectedCropPlan, selectedFieldId]);

	return (
		<div style={{ height: '100%' }}>
			<div style={{ width: 'calc(100% - 70px)', height: '100%', display: 'flex', marginLeft: 70 }}>
				<FieldPlanMainContainer className='FieldPlan_MainContainer'>
					<FieldPlanInnerContainer className='FieldPlan_InnerContainer'>
						{determineCrumbTrail()}
						{determineView()}
					</FieldPlanInnerContainer>
				</FieldPlanMainContainer>
				{
					showFieldInventory &&
					<ConnectedFieldInventoryView
						close={toggleFieldInventoryPanel}
						onNext={onNextField}
						onPrevious={onPreviousField}
						fieldNumberInfo={fieldArrayPosition}
						selectedFieldId={selectedFieldId}
					/>
				}
				<FieldPlanMap
					currentViewState={currentViewState.view}
					selectedGrower={SelectedGrower}
					selectedCropPlanId={SelectedCropPlanId}
					selectedFieldId={selectedFieldId}
					selectField={selectField}
				/>
			</div>
		</div>
	);
};

export const ConnectedFieldPlan = connector(FieldPlan);


