import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';
import { IFileListingResponse, IFileUploadUrlResponse } from '../../Models/Responses/FileResponses';
import { getUploadedFilesForUser, uploadFileToUrl, getUploadUrlForFile, getDownloadUrlForFile } from './FileThunks'; 

export interface IFileUploadTracker
{
	ErrorMessage?: string; // If there was an error, the error message
	File: RcFile; // The file itself
	FileUploadUrlResponse?: IFileUploadUrlResponse; // The upload url for the file
	IsDone: boolean; // Is done uploading - IsDone would be true and IsLoading would be false
	IsError: boolean; // Is done uploading but there was an error
	IsLoading: boolean; // is currently uploading or not
}

export interface IFileState
{
	errorMessage: string;
	FileListing: IFileListingResponse[];
	FileUploadList: IFileUploadTracker[];
	isError: boolean;
	isLoadingFileListing: boolean;
}

export const initialState: IFileState = {
	errorMessage: undefined,
	FileListing: [], // Previously uploaded files returned from an api call
	FileUploadList: [], // File List being to be uploaded or uploaded during this session
	isError: false,
	isLoadingFileListing: false,
};

export const fileSlice = createSlice({
	name: 'file',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
		addFileToList: (state, { payload }: PayloadAction<RcFile>) =>
		{
			if (payload.name && state.FileUploadList.findIndex(f => f.File.name === payload.name) === -1)
			{
				state.FileUploadList.push({
					File: payload,
					IsDone: false,
					IsError: false,
					IsLoading: false,
				});
			}

			return state;
		},
		clearFileUploadList: (state) =>
		{
			state.FileUploadList = [];

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		/**
		* Machine Data - File Listing - aka previously uploaded files that are able to be re-downloaded
		*/
		builder.addCase(getUploadedFilesForUser.pending, (state) =>
		{
			state.isLoadingFileListing = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUploadedFilesForUser.fulfilled, (state, { payload }: PayloadAction<IFileListingResponse[]>) =>
		{
			state.FileListing = payload;
			state.isLoadingFileListing = false;			
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUploadedFilesForUser.rejected, (state, action) =>
		{
			state.isLoadingFileListing = false;
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to retrieve the list of uploaded files. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the list of uploaded files. Please try again.';
			}
		});


		/**
		* Machine Data - Retrieve a File Upload Url for a single file
		*/
		builder.addCase(getUploadUrlForFile.pending, (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUploadUrlForFile.fulfilled, (state, action) =>
		{
			if (state.FileUploadList.find(f => f.File.name === action.meta.arg.FileName))
			{
				state.FileUploadList.find(f => f.File.name === action.meta.arg.FileName).FileUploadUrlResponse = action.payload;
			}
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUploadUrlForFile.rejected, (state, action) =>
		{
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to retrieve the url to upload a file. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the url to upload a file. Please try again.';
			}
		});


		/**
		* Machine Data - Send a File to an Upload Url
		*/
		builder.addCase(uploadFileToUrl.pending, (state, action) =>
		{
			state.FileUploadList.find(f => f.File.name === action.meta.arg.name).IsLoading = true;
			state.FileUploadList.find(f => f.File.name === action.meta.arg.name).IsError = false;
			state.FileUploadList.find(f => f.File.name === action.meta.arg.name).ErrorMessage = undefined;
		});
		builder.addCase(uploadFileToUrl.fulfilled, (state, { payload }: PayloadAction<string>) =>
		{
			state.FileUploadList.find(f => f.File.name === payload).IsLoading = false;
			state.FileUploadList.find(f => f.File.name === payload).IsDone = true;
			state.FileUploadList.find(f => f.File.name === payload).IsError = false;
			state.FileUploadList.find(f => f.File.name === payload).ErrorMessage = undefined;
		});
		builder.addCase(uploadFileToUrl.rejected, (state, action) =>
		{
			if (state.FileListing && state.FileListing.length > 0 && !action?.error?.message.toLowerCase().includes('abort'))
			{
				state.FileUploadList.find(f => f.File.name === action.meta.arg.name).IsLoading = false;
				state.FileUploadList.find(f => f.File.name === action.meta.arg.name).IsDone = true;
				state.FileUploadList.find(f => f.File.name === action.meta.arg.name).IsError = true;
			}

			if (action && action.payload)
			{
				const payload = action.payload as {error: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.FileUploadList.find(f => f.File.name === action.meta.arg.name).ErrorMessage = errorMessage;
				}
				else
				{
					state.FileUploadList
						.find(f => f.File.name === action.meta.arg.name).ErrorMessage = 'There was a problem contacting the server to upload a file. Please try again.';
				}
			}
			else if (action?.error?.message.toLowerCase().includes('abort'))
			{
				// uploads were cancelled
			}
			else
			{
				state.FileUploadList.find(f => f.File.name === action.meta.arg.name).ErrorMessage = 'There was a problem trying to upload a file. Please try again.';
			}
		});

		/**
		* Machine Data - Retrieve a File Download Url for a single file
		*/
		builder.addCase(getDownloadUrlForFile.pending, (state) =>
		{
			state.isLoadingFileListing = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getDownloadUrlForFile.fulfilled, (state, action) =>
		{
			state.isLoadingFileListing = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getDownloadUrlForFile.rejected, (state, action) =>
		{
			state.isLoadingFileListing = false;
			state.isError = true;
			if (action.payload)
			{
				const payload = action.payload as {error: string, growerId: string};
				const errorMessage = payload.error;
				if (errorMessage)
				{
					state.errorMessage = errorMessage;
				}
				else
				{
					state.errorMessage = 'There was a problem contacting the server to retrieve the url to upload a file. Please try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the url to upload a file. Please try again.';
			}
		});
	},
});

export const {
	addFileToList,
	clearError,
	clearFileUploadList,
	clearState,
} = fileSlice.actions;