import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { InputNumber, InputNumberProps } from 'antd';
import { useDebounce } from '../../logic/Utility/useDebounce';

const IncrementNumberInputMainContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
`;

const IncrementNumberInputInnerContainer = styled.div`
	display: flex;
	align-items: center;
	padding-top: 5px;
`;

const NumberInputButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	color: ${themeGet('colors.white')};
	font-weight: bold;
	font-size: ${themeGet('fontSizes.xLarge')};
	cursor: pointer;
	user-select: none;
`;

const NumberInput = styled(InputNumber)`
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	margin-left: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;

interface IIncrementNumberInputProps extends InputNumberProps {
	interval: number;
	max: number;
	min: number;
	title: string;
	value: number;
	width?: string;
	orientation: 'horizontal' | 'vertical';
	onChange: (value: number) => void;
	roundValue?: (acres: number) => number;
}

export const IncrementNumberInputDebounced = (props: IIncrementNumberInputProps) =>
{
	const { interval, min, max, orientation, title, value, width, onChange, roundValue, ...rest } = props;
	const [savedValue, setSavedValue] = useState<number>(value);

	const theme = useTheme();

	useEffect(() =>
	{
		setSavedValue(value);
	},[value]);

	const debouncedValue = useDebounce(savedValue, 500);

	useEffect(() =>
	{
		if (debouncedValue)
		{
			onChange(debouncedValue);
		}
	},[debouncedValue] // Only call effect if debounced value changes
	);

	const increment = () =>
	{
		let incrementedValue = savedValue + interval > max ? max : savedValue + interval;
		incrementedValue = roundValue ? roundValue(incrementedValue) : incrementedValue;
		setSavedValue(incrementedValue);
	};

	const decrement = () =>
	{
		let decrementedValue = savedValue - interval < min ? min : savedValue - interval;
		decrementedValue = roundValue ? roundValue(decrementedValue) : decrementedValue;
		setSavedValue(decrementedValue);
	};

	const change = (newValue: number) =>
	{
		if (newValue > max)
		{
			newValue = max;
		}
		if (newValue < min)
		{
			newValue = min;
		}
		setSavedValue(newValue);
	};

	return (
		<IncrementNumberInputMainContainer
			className='IncrementNumberInput_MainContainer'
			style={{
				flexDirection: orientation === 'horizontal' ? 'row' : 'column',
				alignItems: orientation === 'horizontal' ? 'center' : 'inherit',
				paddingLeft: orientation === 'horizontal' ? 3 : 20,
				paddingRight: orientation === 'horizontal' ? 0 : 20,
			}}
		>
			<div style={{ width: 140, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: theme.fontWeights.bold }}>{title}</div>
			<IncrementNumberInputInnerContainer className='IncrementNumberInput_InnerContainer'>
				<NumberInputButton
					className='IncrementNumberInput_Decrement_Button'
					style={{ backgroundColor: 'black' }}
					onClick={decrement}
				>
					—
				</NumberInputButton>
				<NumberInput
					type='number'
					style={{ width: width ? width : 60 }}
					className='IncrementNumberInput'
					min={min}
					max={max}
					value={savedValue}
					onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
						change(evt.currentTarget.value ? Number(evt.currentTarget.value) : value)}
					onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
						change(evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value) : value)}
					{...rest}
				/>
				<NumberInputButton
					className='IncrementNumberInput_Increment_Button'
					style={{ backgroundColor: theme.colors.primary }}
					onClick={increment}
				>
					+
				</NumberInputButton>
			</IncrementNumberInputInnerContainer>
		</IncrementNumberInputMainContainer>
	);
};