import React from 'react';
import { TableRowType } from '../../../../components/Table/TableRows';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/Trashcan.svg';
import { StyledRowCell } from '../../../../components/Table/TableRow';
import { IconButton } from '../../../../components/IconButton/IconButton';
import styled, { useTheme } from 'styled-components';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Button } from '../../../../components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../../assets/images/PlusIcon.svg';
import { InputNumber, Popover } from 'antd';
import { IFieldRow, IUpdateFieldRowParams } from './FieldShoppingCart';
import { ISelectableTreatment } from '../Shared/QuoteUtils';
import { dynamicSort } from '../../../../logic/Utility/Utils';
import { themeGet } from '@styled-system/theme-get';

const StyledButtonCell = styled(StyledRowCell)`
	height: 100%;
	margin-left: auto;
	margin-right: 0;
	padding-left: 5%;
	@media (hover: none) {
		padding-left: 15%;
	}
	border-left: 1px solid;
	border-left-color: ${themeGet('colors.lightestGrey')};
	svg {
		position: inherit;
	}
`;
const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
	path {
		fill: ${themeGet('colors.darkGrey')};
	}
`;

const StyledInputNumber = styled(InputNumber)`
	width: 70px;
	.ant-input-number-input {
		padding: 0;
		text-align: center;
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	input
	{
		font-family: 'Roboto';
		font-size: 12px;
		height: 14px;
		border-bottom: 1px black solid;
	}
`;

interface IButtonCellProps extends IFieldRow
{
	onClickDelete: ( farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment ) => void;
	onDeleteParentRow?: (farmId: string, fieldId: string, hybridId: string) => void;
}
const ButtonCell = (props: IButtonCellProps) =>
{
	const { Id, FarmId, HybridId, Treatments, onClickDelete, onDeleteParentRow } = props;

	const theme = useTheme();

	const treatment = Treatments[0];

	const onDelete = () =>
	{
		if (!onDeleteParentRow)
		{
			onClickDelete(FarmId, Id, HybridId, treatment);
		}
		else
		{
			onDeleteParentRow(FarmId, Id, HybridId);
		}
	};

	return (
		<StyledButtonCell className='product-quote-button-section'>
			<StyledIconButton className='delete-button' onClick={onDelete}
				tooltip='Delete'
				placement='bottom'
				color={theme.colors.redLM}
				hoverbgcolor={theme.colors.lightestGrey}>
				<DeleteIcon />
			</StyledIconButton>
		</StyledButtonCell>
	);
};

export interface ISeedTreatmentBlockProps
{
	fieldRowItem: IFieldRow;
	keyPrefix: string;
	addNewItem: ( farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment ) => void;
	removeItem: ( farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment ) => void;
}

const StyledCheckboxRow = styled.div`
	margin-top: 12px;
	margin-bottom: 12px;

	label {
		font-family: ${themeGet('fonts.base')};
		font-size: ${themeGet('fontSizes.extraSmall')};
		font-weight: ${themeGet('fontWeights.normal')};
	}
`;

const SeedTreatmentBlock = (props: ISeedTreatmentBlockProps) =>
{
	const { fieldRowItem, keyPrefix, addNewItem, removeItem } = props;

	const theme = useTheme();

	function toggleSelection(treatment: ISelectableTreatment, checked: boolean)
	{
		if (checked)
		{
			treatment.Selected = true;
			addNewItem(fieldRowItem.FarmId, fieldRowItem.Id, fieldRowItem.HybridId, treatment);
		}
		else
		{
			removeItem(fieldRowItem.FarmId, fieldRowItem.Id, fieldRowItem.HybridId, treatment);
		}
	}

	const isChildRow = fieldRowItem.Treatments && fieldRowItem.Treatments.length === 1;

	function createCheckboxRows(props: ISelectableTreatment[]): JSX.Element[]
	{
		const rows = props.sort(dynamicSort('Treatment')).map(st =>
		{
			return (
				<StyledCheckboxRow
					key={st.Treatment + fieldRowItem.Id}
					className={`AddHybridTreatmentRow-${fieldRowItem.Id}`}
				>
					<Checkbox
						style={{ display: !(st.CustomIsDeleted && !st.Selected) ? 'flex' : 'none' }}
						checked={st.Selected}
						onChange={(e) => toggleSelection(st, e.target.checked)}
					>
						{st.Treatment}
					</Checkbox>
				</StyledCheckboxRow>
			);
		});

		return rows;
	}

	return (
		isChildRow ?
			<div>{fieldRowItem.Treatments ? fieldRowItem.Treatments[0].Treatment : 'Unavailable'}</div>
			:
			<Popover
				key={`${keyPrefix}-treatmentpopover`}
				className={`${keyPrefix}-treatmentpopover`}
				placement='bottom'
				trigger='click'
				content={createCheckboxRows(fieldRowItem.Treatments)}>
				<Button style={{
					width: '90%',
					height: 32,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}} variant='outlined' key={`${keyPrefix}-treatmentaddbutton`} className={`${keyPrefix}-treatmentaddbutton`}>
					<span style={{ paddingLeft: 9 }}>Treatment(s)</span>
					<span style={{ paddingRight: 9, display: 'flex', flexDirection: 'row', alignItems: 'center' }}><PlusIcon fill={theme.colors.darkGrey} /></span>
				</Button>
			</Popover>
	);
};

interface IFieldHybridRows 
{
	data: IFieldRow[];
	isChildRow: boolean;
	onClickDelete: ( farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment ) => void;
	onDeleteParentRow?: (farmId: string, fieldId: string, hybridId: string) => void;
	addNewTreatmentItem: ( farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment ) => void;
	removeTreatmentItem: ( farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment ) => void;
	updateValues: (params: IUpdateFieldRowParams) => void;
}

export const FieldHybridRows = (props: IFieldHybridRows): TableRowType<IFieldRow>[] =>
{
	const { data, isChildRow, onClickDelete, onDeleteParentRow, addNewTreatmentItem, removeTreatmentItem, updateValues } = props;

	return data.map((item: IFieldRow, index) =>
	{
		const keyPrefix = `fieldquote-cell-${item.HybridName}-${item.Id}-${index}`;

		const updateAndSave = (update: Partial<IFieldRow>) =>
		{
			const parameters: IUpdateFieldRowParams = 
			{
				BrandName: item.BrandName,
				CropId: item.CropId,
				CustomTreatmentId: item.Treatments[0].CustomTreatmentId ? item.Treatments[0].CustomTreatmentId : undefined,
				FarmId: item.FarmId,
				FieldId: item.Id,
				HybridId: item.HybridId,
				TreatmentName: item.Treatments[0].Treatment,
				UpdateItem: update
			};
			updateValues(parameters);
		};

		const isParentRow = item.Treatments.length > 1;

		const isSingleRow = !isParentRow && !isChildRow;

		return {
			data: item,
			items: [
				<div key={`${keyPrefix}-fieldname`} style={{ visibility: !isParentRow && !isSingleRow ? 'hidden' : 'visible' }}>
					<span style={{ fontWeight: 700, fontSize: 14 }}>{item.FieldName}</span>
				</div>,
				<div key={`${keyPrefix}-hybrid`} style={{ paddingLeft: 15, visibility: !isParentRow && !isSingleRow ? 'hidden' : 'visible' }}>
					{item.HybridName}
				</div>,
				<div key={`${keyPrefix}-seedTreatment`} className={`${keyPrefix}-seedTreatment`} style={{ paddingLeft: 15 }}>
					<SeedTreatmentBlock
						keyPrefix={`${keyPrefix}-seedTreatment`}
						fieldRowItem={item}
						addNewItem={addNewTreatmentItem}
						removeItem={removeTreatmentItem} />
				</div>,
				<span key={`${keyPrefix}-rate`} style={{ textAlign: 'center', visibility: isParentRow ? 'hidden' : 'visible' }}>
					<StyledInputNumber
						type='number'
						className={`${keyPrefix}-rate`}
						min={0}
						value={item.Rate ? item.Rate : 0}
						bordered={false}
						onPressEnter={(evt) => updateAndSave({ Rate: (evt.target as HTMLInputElement).value ?
							Number((evt.target as HTMLInputElement).value) : item.Rate })}
						onBlur={(evt) => updateAndSave({ Rate: evt.currentTarget.value ?
							Number(evt.currentTarget.value) : item.Rate })}
					/>
				</span>,
				<span key={`${keyPrefix}-appliedacres`} style={{ textAlign: 'center', visibility: isParentRow ? 'hidden' : 'visible' }}>
					<StyledInputNumber
						type='number'
						className={`${keyPrefix}-appliedacres`}
						min={0}
						value={item.AppliedAcres ? Math.round(item.AppliedAcres * 10) / 10 : 0}
						bordered={false}
						onPressEnter={(evt) => updateAndSave({ AppliedAcres: (evt.target as HTMLInputElement).value ?
							Number((evt.target as HTMLInputElement).value) : Math.round(item.AppliedAcres * 10) / 10 })}
						onBlur={(evt) => updateAndSave({ AppliedAcres: evt.currentTarget.value ?
							Number(evt.currentTarget.value) : Math.round(item.AppliedAcres * 10) / 10 })}
					/>
				</span>,
				<div key={`${keyPrefix}-bagsneeded`} style={{ textAlign: 'center', visibility: isParentRow ? 'hidden' : 'visible' }}>
					{item.BagsNeeded ? item.BagsNeeded : 0}
				</div>,
				<ButtonCell key={`${keyPrefix}-buttons`} {...item} onClickDelete={onClickDelete} onDeleteParentRow={onDeleteParentRow} />,
			]
		};
	});
};