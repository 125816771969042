import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

interface ICropTabBarProps
{ 
	selected?: boolean;
	tabWidth: string;
}

/**
 * Tab elements for the crops on the product plan page
 */
const StyledTabBar = styled.div<ICropTabBarProps> `
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: ${props => props.tabWidth};
	margin-right: 8px;
	font-size: ${props => props.selected ? themeGet('fontSizes.large') : themeGet('fontSizes.small')};
	font-weight: ${themeGet('fontWeights.bold')};
	font-family: ${themeGet('fonts.heading')};
	cursor: pointer;

	.TabBarIndicator {
		background-color: ${props => props.selected ? themeGet('colors.darkGrey') : themeGet('colors.lightestGrey')};
		height: 8px;
		border-radius: 4px 4px 0px 0px;
		width: 100%;
		margin-top: ${props => props.selected ? 'none' : '6px' }
	}
`;

export const CropTabBar = (props: { selected?: boolean; label: string; onClick: ()=>any; tabWidth: string; }) => 
	<StyledTabBar tabWidth={props.tabWidth} selected={props.selected} onClick={props.onClick}>
		{props.label}
		<div className='TabBarIndicator' />
	</StyledTabBar>;
