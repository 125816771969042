import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { Api } from '../../Api/Api';
import { IAttributesResponse } from '../../Models/Responses/AttributeResponse';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const getAttributes = createTracedAsyncThunk<IAttributesResponse[], null, { dispatch: AppDispatch, state: RootState }>(
	'get/attributes',
	async (context, request: null, thunkAPI) =>
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const { crops } = thunkAPI.getState();
			const response = await api.getAsync<IAttributesResponse[]>(`years/${crops.CropYear}/attributes`);

			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IAttributeState
{
	isError: boolean;
	isLoading: boolean;
	errorMessage: string;
	Attributes: IAttributesResponse[];
}

export const initialState: IAttributeState = {
	isError: false,
	isLoading: false,
	errorMessage: undefined,
	Attributes: []
};

export const attributesSlice = createSlice({
	name: 'attributes',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state.Attributes = [];
			state.isError = false;
			state.isLoading = false;
			state.errorMessage = undefined;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getAttributes.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getAttributes.fulfilled, (state, { payload }: PayloadAction<IAttributesResponse[]>) =>
		{
			state.isLoading = false;
			state.Attributes = payload;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getAttributes.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			state.Attributes = [];
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Seed Attributes. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of Seed Attributes. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Seed Attributes. Please refresh and try again.';
			}
		});
	},
});

export const { clearState, clearError } = attributesSlice.actions;