export type AvailabilityEnum = 'Red' | 'Yellow' | 'Green' | 'None';
export const AvailabilityPreference: AvailabilityEnum[] = ['None', 'Red', 'Yellow', 'Green'];

export interface IProductReference {
	HybridId: string;
	Treatment: string;
}

export interface ITreatmentIdentifier {
	IsCustom: boolean,
	Name: string;
	Id: string;
}
interface ISeriesBase {
	CropId: string;

	SeriesId: string;
	SeriesName: string;
	Availability: AvailabilityEnum;
	RelativeMaturity: number;

}

export interface ISeriesIdentifier extends ISeriesBase {
	Hybrids: IHybridIdentifier[];
}

export interface ITraitIdentifier {
	TraitId: string;
	TraitName: string;
	TraitFullName: string;
}

interface IHybridBase  extends ISeriesBase, ITraitIdentifier {
	HybridId: string;
	HybridName: string;
	Availability: AvailabilityEnum;
}

export interface IHybridIdentifier extends IHybridBase {
	Products: IProductIdentifier[];
}

export interface IProductIdentifier extends IHybridBase {
	TreatmentName: string;
	CustomTreatmentId: string | undefined;
	Availability: AvailabilityEnum;
}

export interface IProductMap {
	products: IProductIdentifier[];
	hybrids: IHybridIdentifier[];
	series: ISeriesIdentifier[];
	treatments: ITreatmentIdentifier[];
	traits: ITraitIdentifier[];
}