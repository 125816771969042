import React, { useEffect, useState } from 'react';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { Button } from '../../../components/Button/Button';
import { IPlantingPlanEquipmentResponse } from '../../Models/Responses/PlantingPlanEquipmentResponse';
import styled, { useTheme } from 'styled-components';
import { Tooltip } from 'antd';

const StyledButtonContainerSpan = styled.span`
	&.is-disabled {
		pointer-events: none;
		opacity: 0.3;
	}
`;

interface IExportModalProps
{
	equipment: IPlantingPlanEquipmentResponse;
	displayModal: boolean;
	cancelModal: () => void;
	exportFile: (format: string, monitor: string) => void;
}

export const ExportModal = (props: IExportModalProps) =>
{
	const { displayModal, equipment, cancelModal, exportFile } = props;

	const theme = useTheme();

	const [brandOptions, setBrandOptions] = useState<IOptionItem[]>([]);
	const [brandSelection, setBrandSelection] = useState<string>(undefined);
	const [monitorOptions, setMonitorOptions] = useState<IOptionItem[]>([]);
	const [monitorSelection, setMonitorSelection] = useState<string>(undefined);
	const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

	useEffect(() =>
	{
		if (equipment)
		{
			const createBrandOptions = equipment.brands.map(brandIItem =>
			{
				const brandOption: IOptionItem =
				{
					label: brandIItem.label,
					value: brandIItem.format
				};
	
				return brandOption;
			});
	
			setBrandOptions(createBrandOptions);
		}
		
	}, [equipment]);

	useEffect(() =>
	{
		if (brandSelection)
		{
			const createMonitorOptions = equipment.monitors.filter(m => m.format === brandSelection).map(monitor =>
			{
				const monitorOption: IOptionItem =
				{
					label: monitor.label ? monitor.label : monitor.monitor,
					value: monitor.monitor
				};
	
				return monitorOption;
			});
	
			setMonitorOptions(createMonitorOptions);
		}
	},[brandSelection]);

	const selectBrand = (value: string) =>
	{
		setBrandSelection(value);
		setMonitorSelection(undefined);
		setSaveDisabled(true);
	};

	useEffect(() =>
	{
		if (monitorSelection)
		{
			// Enable the save button
			setSaveDisabled(false);
		}
	}, [monitorSelection]);

	const cancel = () =>
	{
		setBrandSelection(undefined);
		setMonitorSelection(undefined);
		cancelModal();
	};

	const onExport = () =>
	{
		exportFile(brandSelection, monitorSelection);
		setBrandSelection(undefined);
		setMonitorSelection(undefined);
		setSaveDisabled(true);
		cancelModal();
	};

	return (
		<StyledModal onCancel={cancel} open={displayModal} title='Export VRS'>
			<div style={{ display: 'flex', flexDirection:'column' }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
					<div style={{ fontWeight: theme.fontWeights.bold }}>Monitor Brand</div>
					<Dropdown
						variant='light'
						options={brandOptions}
						onSelect={selectBrand}
						selected={brandSelection}
						placeholder='Select a Brand'
						className='Brand_Dropdown'
						menuStyle={{ maxHeight: 250, overflow: 'auto' }}
					/>
					<div style={{ fontWeight: theme.fontWeights.bold }}>Monitor Model</div>
					<Dropdown
						variant='light'
						options={monitorOptions}
						onSelect={(item: string) => setMonitorSelection(item)}
						selected={monitorSelection}
						placeholder='Select a Model'
						className='Model_Dropdown'
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginLeft: 'auto',
						marginTop: 'auto',
						paddingTop: 10,
						paddingBottom: 10
					}}
				>
					<Button className='ExportModal_Cancel_Button'
						style={{ height: 'auto', width: 75, marginRight: 15 }}
						variant='light'
						onClick={cancel}
					>
						Cancel
					</Button>
					<Tooltip title={saveDisabled ? 'Please select both a brand and a model to export.' : ''}>
						<StyledButtonContainerSpan
							className={saveDisabled ? 'ExportModal_Export_Button_Container is-disabled' : 'ExportModal_Export_Button_Container'}
							style={{ cursor: saveDisabled ? 'not-allowed' : 'pointer' }}
						>
							<Button
								className='ExportModal_Export_Button'
								style={{ height: 'auto', color: 'white', backgroundColor: theme.colors.primary, width: 75 }}
								variant='light'
								onClick={onExport}
							>
								Export
							</Button>
						</StyledButtonContainerSpan>
					</Tooltip>
				</div>
			</div>
		</StyledModal>
	);
};