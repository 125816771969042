import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { InputNumber } from 'antd';

const IncrementNumberInputMainContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
`;

const IncrementNumberInputInnerContainer = styled.div`
	display: flex;
	align-items: center;
	padding-top: 5px;
`;

const NumberInputButton = styled.button<{backgroundColor: string}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	color: ${themeGet('colors.white')};
	font-weight: bold;
	font-size: ${themeGet('fontSizes.xLarge')};
	cursor: pointer;
	user-select: none;
	background-color: ${(t) => t.backgroundColor};
	border-color: transparent;

	&:disabled {
		opacity: 0.5;
	}
`;

const NumberInput = styled(InputNumber)`
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	margin-left: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;

interface IIncrementNumberInputProps {
	interval: number;
	max: number;
	min: number;
	onChange: (value: number) => void;
	title: string;
	value: number;
	width?: string;
	boldTitle?: boolean;
	disabled?: boolean;
}

export const IncrementNumberInput = (props: IIncrementNumberInputProps) =>
{
	const { interval, min, max, onChange, title, value, width, boldTitle, disabled } = props;

	const theme = useTheme();

	const increment = () =>
	{
		onChange(value + interval > max ? max : value + interval);
	};

	const decrement = () =>
	{
		onChange(value - interval < min ? min : value - interval);
	};

	const change = (newValue: number) =>
	{
		if (newValue > max)
		{
			newValue = max;
		}
		if (newValue < min)
		{
			newValue = min;
		}
		onChange(newValue);
	};

	return (
		<IncrementNumberInputMainContainer className='IncrementNumberInput_MainContainer'>
			<div 
				style={{
					width: 140,
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					fontWeight: boldTitle ? theme.fontWeights.bold : theme.fontWeights.normal
				}}
			>
				{title}
			</div>
			<IncrementNumberInputInnerContainer className='IncrementNumberInput_InnerContainer'>
				<NumberInputButton
					className='IncrementNumberInput_Decrement_Button'
					backgroundColor='black'
					onClick={decrement}
					disabled={disabled}
				>
					—
				</NumberInputButton>
				<NumberInput
					type='number'
					style={{ width: width ? width : 60 }}
					className='IncrementNumberInput'
					min={min}
					max={max}
					value={value}
					disabled={disabled}
					onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
						change(evt.currentTarget.value ? Number(evt.currentTarget.value) : value)}
					onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
						change(evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value) : value)}
				/>
				<NumberInputButton
					className='IncrementNumberInput_Increment_Button'
					backgroundColor={theme.colors.primary}
					onClick={increment}
					disabled={disabled}
				>
					+
				</NumberInputButton>
			</IncrementNumberInputInnerContainer>
		</IncrementNumberInputMainContainer>
	);
};