import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IconButton } from '../IconButton/IconButton';
import { Input, InputNumber } from 'antd';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as Checkmark } from '../../assets/images/Checkmark.svg';
import { ReactComponent as Xmark } from '../../assets/images/Close.svg';
import { ReactComponent as Pencil } from '../../assets/images/Pencil.svg';

const InputTextField = styled(Input)`
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
		box-shadow: 0 0 1pt 1pt white;
		outline: none;
	}
	::selection {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
`;

const InputNumberField = styled(InputNumber)`
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	margin-left: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
	}
`;

interface IEditableInputDisplayProps
{
	className?: string;
	description?: string;
	disableEdit?: boolean;
	editButtonHoverBgColor?: string;
	editButtonTooltip?: string;
	editIcon?: React.ReactNode;
	editIconButtonContainerStyle?: React.CSSProperties;
	editIconButtonStyle?: React.CSSProperties;
	inputId?: string;
	inputType: 'number' | 'text';
	inputValue: string;
	inputWidth?: string | number;
	mainContainerWidth?: string | number;
	validationTooltip?: string;
	width?: string | number;
	validateInput: (value: string) => boolean;
	saveInput: (value: string, id?: string) => void;
}

export const EditableInputDisplay = (props: IEditableInputDisplayProps) =>
{
	const {
		className,
		description,
		disableEdit,
		editButtonHoverBgColor,
		editButtonTooltip,
		editIcon,
		editIconButtonContainerStyle,
		editIconButtonStyle,
		inputId,
		inputType,
		inputValue,
		inputWidth,
		mainContainerWidth,
		validationTooltip,
		width,
		validateInput,
		saveInput,
	} = props;

	const [editedValue, setEditedValue] = useState(inputValue);
	const [showEditable, setShowEditable] = useState(false);
	const [isValid, setIsValid] = useState(validateInput(inputValue));

	const theme = useTheme();

	// Make sure the input is valid before allowing a save
	const checkInput = (value: string) =>
	{
		// If invalid - disable save button and show red
		if (validateInput(value))
		{
			setIsValid(true);
			setEditedValue(value);
		}
		else
		{
			setEditedValue(value);
			setIsValid(false);
		}
	};

	const onCancel = () =>
	{
		setEditedValue(inputValue);
		setIsValid(true);
		setShowEditable(false);
	};

	const onSave = () =>
	{
		saveInput(editedValue, inputId);
		setShowEditable(false);
	};

	const toggleEditable = (): JSX.Element => 
	{
		// If show editable version - display the input field and the x and check buttons
		if (showEditable)
		{
			return (
				<div
					className={className ? `Editable_Container_${className}` : 'Editable_Container'}
					style={{ display: 'flex', marginLeft: 5, alignItems: 'center', width: width ? width : 'auto' }}>
					{
						inputType === 'number' ?
							<InputNumberField
								className={className ? `Editable_Input_${className}` : 'Editable_Input'}
								status={isValid ? '' : 'error'}
								value={editedValue}
								width={inputWidth ? inputWidth : '100px'}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
									checkInput(evt.currentTarget.value ? evt.currentTarget.value : editedValue)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
									checkInput(evt.target && (evt.target as HTMLInputElement).value ? (evt.target as HTMLInputElement).value : editedValue)}
							/>
							:
							<InputTextField
								className={className ? `Editable_Input_${className}` : 'Editable_Input'}
								status={isValid ? '' : 'error'}
								defaultValue={editedValue}
								type={'text'}
								maxLength={50}
								width={inputWidth ? inputWidth : '100px'}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
									checkInput(evt.currentTarget.value ? evt.currentTarget.value : editedValue)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
									checkInput(evt.target && (evt.target as HTMLInputElement).value ? (evt.target as HTMLInputElement).value : editedValue)}
							/>
					}
					<IconButton
						tooltip='Cancel'
						placement='top'
						className={className ? `Editable_Cancel_${className}` : 'Editable_Cancel'}
						onClick={() => onCancel()}
					>
						<Xmark />
					</IconButton>
					<IconButton
						tooltip={isValid ? 'Save' : validationTooltip ? validationTooltip : 'Invalid entry.'}
						placement='top'
						className={className ? `Editable_Save_${className}` : 'Editable_Save'}
						disabled={!isValid}
						showDisabledTooltip={true}
						onClick={() => onSave()}
					>
						<Checkmark />
					</IconButton>
				</div>
			);
		}
		// else - show only the display text and the pencil button
		else 
		{
			return (
				<div
					className={className ? `Editable_DisplayContainer_${className}` : 'Editable_DisplayContainer'}
					style={{ display: 'flex', alignItems: 'center', marginLeft: 5, width: width ? width : 'auto' }}
				>
					{inputValue}
					{
						!disableEdit &&
						<IconButton
							containerStyle={editIconButtonContainerStyle}
							hoverbgcolor={editButtonHoverBgColor}
							fillcolor={'auto'}
							style={editIconButtonStyle ? editIconButtonStyle : { marginLeft: 10, width: 16 }}
							tooltip={editButtonTooltip ? editButtonTooltip : 'Edit'}
							placement='top'
							className={className ? `Editable_Pencil_${className}` : 'Editable_Pencil'}
							onClick={() => setShowEditable(true)}
						>
							{
								editIcon ? editIcon : <Pencil />
							}
						</IconButton>
					}
				</div>
			);
		}
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', fontWeight: theme.fontWeights.bold, width: mainContainerWidth ? mainContainerWidth : 'auto' }}>
			{
				description ?
					<>{description} : {toggleEditable()}</>
					:
					toggleEditable()
			}
		</div>
	);
};