import { useMemo } from 'react';
import { RootState } from '../../../logic/store/Store';
import { useSelector } from 'react-redux';
import { EventCropPlanTags, EventGrowerTags, EventPlanTags, EventSelectionTags, EventTags } from '../../../tracing/EventTagNames';

export function useCropPlanTrackingState() 
{
	const selectedGrower = useSelector((state: RootState) => state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId));
	const selectedFieldPlan = useSelector((state: RootState) => state.fieldplan.currentFieldPlan);
	const selectedCropPlan = useSelector((state: RootState) => state.fieldplan.selectedCropPlanId ?
		state.fieldplan.fullCropPlanData && state.fieldplan.fullCropPlanData.length > 0 ?
			state.fieldplan.fullCropPlanData.find(cp => cp.Id === state.fieldplan.selectedCropPlanId) : undefined
		: undefined);

	const trackingState = useMemo<EventTags>(() => ({

		[EventSelectionTags.GrowerId]: selectedGrower?.Id,
		[EventGrowerTags.GrowerName]: selectedGrower?.Name,
		[EventGrowerTags.GrowerSalesforceId]: selectedGrower?.SalesforceId,
		[EventGrowerTags.GrowerFoundationId]: selectedGrower?.FoundationId,

		[EventSelectionTags.PlanId]: selectedFieldPlan?.Id,
		[EventPlanTags.PlanName]: selectedFieldPlan?.Name,
		[EventPlanTags.PlanType]: 'Field',
		[EventPlanTags.PlanDownloaded]: String(Boolean(selectedFieldPlan?.Downloaded)),
		[EventPlanTags.PlanYear]: selectedFieldPlan?.Year.toString(),
		[EventPlanTags.PlanStatus]: selectedFieldPlan?.Status,

		[EventSelectionTags.CropPlanId]: selectedCropPlan?.Id,
		[EventCropPlanTags.CropPlanName]: selectedCropPlan?.Name,
		[EventCropPlanTags.CropPlanCropId]: selectedCropPlan?.CropId,
		[EventCropPlanTags.CropPlanStatus]: selectedCropPlan?.Status,
		[EventCropPlanTags.CropPlanProductCount]: selectedCropPlan?.SelectedProducts?.length.toString(),
		[EventCropPlanTags.CropPlanFieldCount]: selectedCropPlan?.Fields?.length.toString(),
		[EventCropPlanTags.CropPlanCount]: selectedFieldPlan?.CropPlans.length.toString(),
	}), [selectedCropPlan, selectedFieldPlan, selectedGrower]);
	return trackingState;
}
