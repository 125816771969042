import React, { ReactNode, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { ThemeName } from '../../logic/store/UI/UISlice';
import { useUrlTheme } from '../../logic/Utility/useUrlTheme';
import { ReactComponent as CoreLogo } from '../../assets/images/GH-GHX-Logos-Orange.svg';
import { ReactComponent as EnogenCoreLogo } from '../../assets/images/EluminateEnogenLogos.svg';

export interface IGetJdConnectInviteResponse {
	SellerId: string;
    SellerName: string;
    GrowerId: string;
    GrowerName: string;
    IsGhx: boolean;
    IsEnogenTheme: boolean;
    Token: string;
    CreatedDate: string;
    Status: string;
    GrowerInvitationLink: string;
    JdAuthorizationLink: string;
}

// Render component for the invite form
export const JdConnectComplete = (props: {
	changeTheme: (name: ThemeName) => void;
}) => 
{
	// Make sure our themeing is correct per the url
	const [isEnogen, setIsEnogen] = useState(false);
	useUrlTheme(setIsEnogen, props.changeTheme);
	const theme = useTheme();

	return <>
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				backgroundColor: theme.colors.disabledGrey,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: theme.colors.white,
					padding: 20,
					marginLeft: 25,
					marginRight: 25,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: theme.colors.lightGrey,
					borderStyle: 'solid',
					height: '50vh',
					overflowY: 'auto',
				}}
			>
				<div style={{width:'100%', display: 'flex', flexDirection:'column', alignItems: 'center', marginBottom: 10 }}>
					{
						isEnogen ?
							<EnogenCoreLogo  width={'100%'} />
							:
							<CoreLogo  width={'100%'} />
					}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column',  width: '100%', height: '100%' }}>
					<div style={{ marginTop: 15, marginBottom: 10, fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.xLarge }}>Thank You!</div>
					<div style={{ marginBottom: 15 }}>Thank you for connecting your My John Deere account and selecting your preferred fields.</div>
					<div style={{ marginBottom: 15 }}>Your data will support our ability to service you more effectively and enable customized recommendations tailored to your unique needs.</div>
					<div style={{ marginBottom: 15 }}>Remember, you can disconnect this connection from within My John Deere at any time if you need to.</div>
					<div style={{ marginBottom: 15 }}>You can now close this tab or navigate away.</div>
				</div>
			</div>
		</div>
	</>;
};