import React from 'react';
import { cornId, soyId } from '../../../logic/store/Seeds/CropsSlice';
import styled from 'styled-components';
import { ReactComponent as CornIcon } from './Icons/CornCircleIcon.svg';
import { ReactComponent as SoyIcon } from './Icons/SoybeanCircleIcon.svg';
import { themeGet } from '@styled-system/theme-get';

/**
 * Utility to return the icon for a crop by crop Id, optionally overriding the colors
 */
export function iconForCrop(cropId: string | undefined, fillColor: string | undefined, strokecolor?: string | undefined) 
{
	switch (cropId) 
	{
		case cornId:
			return <StyledCornIcon fill={fillColor ?? `${themeGet('colors.primary')}` } />;
		case soyId:
			return <StyledSoyIcon fill={fillColor ?? `${themeGet('colors.primary')}` } />;
		default:
			return undefined;
	}
}

export const StyledCornIcon = styled(CornIcon)`
	cursor: pointer;
`;

export const StyledSoyIcon = styled(SoyIcon)`
	cursor: pointer;
`;
