import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as ErrorIcon } from './Icons/Error.svg';

type InfoPanelVariant = 'error' | 'info' | 'success';

interface IInfoPanelProps
{
	children: ReactNode;
	variant?: InfoPanelVariant;

	style?: React.CSSProperties;
}

const getBgColor = (variant: InfoPanelVariant) => ({ error: 'redDM', info: 'lightGrey', success: 'greenLM' }[variant]);

const StyledInfoPanelContainer = styled.div<{ variant: InfoPanelVariant }>`
	display: flex;
	flex-direction: row;
	background-color: ${props => props.theme.colors[getBgColor(props.variant)] || 'transparent'};
	color: ${themeGet('colors.white')};
	align-items: center;
	border-radius: 6px;
	padding: 10px 16px;
`;

/**
 * Info panel
 */

/** @component */
export const InfoPanel = (props: IInfoPanelProps) =>
{
	const { children, variant = 'info', style } = props;

	return (
		<StyledInfoPanelContainer variant={variant} style={style}>
			<div id='icon-section' style={{
				marginRight: '10px',
				display: 'flex'
			}}>
				{variant === 'error' && <ErrorIcon />}
			</div>
			{children}
		</StyledInfoPanelContainer>
	);
};