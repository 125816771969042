import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

interface ITabButtonProps {
	/** Button label */
	children: ReactNode;
	/** Whether the tab button is currently selected */
	selected?: boolean;
	width?: string | undefined;
}

/**
 * @component A tab button.
 */
export const TabButton = styled.button<ITabButtonProps>`
	width: ${props => props.width ?? ''};
	height: 45px;
	color: ${themeGet('colors.darkGrey')};
	background-color: transparent;
	text-align: center;
	font-family: ${themeGet('fonts.base')};
	font-size: ${themeGet('fontSizes.small')};
	font-weight: 700;
	margin: 0px 10px;
	border: ${themeGet('borders.none')};
	border-bottom: ${props => props.selected ? themeGet('borders.normal') : themeGet('borders.none')};
	border-bottom-color: ${themeGet('colors.primary')};
	outline: none;
	cursor: pointer;

	:disabled {
		color: ${themeGet('colors.lightGrey')};
		cursor: default;
	}
`;