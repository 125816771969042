import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

/**
 * Container for the Cropwise Import page
 */
export const CropwiseImportContainer = styled.div`
	height: 100%;
	width: calc(100% - 70px);
	margin-left: 70px;
	display: flex;
	flex-direction: row;
	.section {
		padding: 16px 0;
		border-top: none;
		font-weight: ${themeGet('fontWeights.bold')};
		border-bottom: thin solid ${themeGet('colors.lightestGrey')};
		&.fieldSection {
			display: flex;
			flex-direction: column;
			border-bottom: none;
			flex-grow: 1;
		}
		&.groupSection {
			min-height: 100px;
			display: flex;
			flex-direction: column;
		}
	}
	.header {
		padding: 32px 0;
	}
`;
