import { AllGeoJSON } from '@turf/turf';
import { stringify } from 'wkt';
import { SupportedGeometryFormats } from './SupportedGeometryFormats';


export const convertGeometry = (geometry: AllGeoJSON, format: SupportedGeometryFormats) =>
{
	if (format == SupportedGeometryFormats.GeoJSON)
	{
		return geometry;
	}

	if (format == SupportedGeometryFormats.Wkt)
	{
		return stringify(geometry);
	}

	throw new Error(`Unsupported geometry format requested: ${format}`);
};
