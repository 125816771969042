import React from 'react';
import { TableRowType } from '../../../components/Table/TableRows';
import { ReactComponent as CornIcon } from '../Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from '../Icons/SoyIcon.svg';
import { ReactComponent as UnknownCrop } from '../Icons/PlantIcon.svg';
import styled, { useTheme } from 'styled-components';
import { cornId, soyId, unknownId } from '../../../logic/store/Seeds/CropsSlice';

interface IManageFieldsRowProps
{
	data: IManageFieldsRowItem[];
	addFieldToCropList: (fieldId: string, cropId: string) => void;
}

export interface IManageFieldsRowItem
{
	Id: string; // Field Id
	FieldName: string;
	FarmId: string;
	FarmName: string;
	CropId: string;
}

export const StyledCornIcon = styled(CornIcon)<{ strokecolor: string }>`
	cursor: pointer;
	path {
			stroke: ${props => props.strokecolor};
		}
`;

export const StyledSoyIcon = styled(SoyIcon)<{ strokecolor: string }>`
	cursor: pointer;
	path {
			stroke: ${props => props.strokecolor};
		}
`;

export const StyledUnknownCrop = styled(UnknownCrop)<{ strokecolor: string }>`
	cursor: pointer;
	path {
			stroke: ${props => props.strokecolor};
		}
`;

export const ManageFieldsRows = (props: IManageFieldsRowProps): TableRowType<IManageFieldsRowItem>[]  =>
{
	const { data, addFieldToCropList } = props;

	const theme = useTheme();

	return data.map((item: IManageFieldsRowItem, index) =>
	{
		const keyPrefix = `managefields-cell-${item.Id}-${index}`;

		const determineCropIcon = (cropId: string) =>
		{
			const isUnknown = !cropId ? true : cropId === unknownId;
			const isSoy = cropId === soyId;
			const isCorn = cropId === cornId;

			// Turn the icons to the orange color:secondary when the field has the specific crop assigned to it
			return (
				<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
					<StyledCornIcon
						height={26}
						width={26}
						strokecolor={isCorn ? theme.colors.secondary : theme.colors.lightGrey}
						onClick={() => addFieldToCropList(item.Id, cornId)}
					/>
					<StyledSoyIcon
						strokecolor={isSoy ? theme.colors.secondary : theme.colors.lightGrey}
						onClick={() => addFieldToCropList(item.Id, soyId)}
					/>
					<StyledUnknownCrop
						strokecolor={isUnknown ? theme.colors.secondary : theme.colors.lightGrey}
						onClick={() => addFieldToCropList(item.Id, unknownId)}
					/>
				</div>
			);
		};

		return {
			data: item,
			items: [
				<div
					key={`${keyPrefix}-fieldName`}
					className={`${keyPrefix}-fieldName`}
					style={{ 
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap'
					}}
				>
					{item.FieldName}
				</div>,
				<div
					key={`${keyPrefix}-farmName`}
					className={`${keyPrefix}-farmName`}
					style={{ 
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						paddingLeft: 10,
						paddingRight: 10,
					}}
				>
					{item.FarmName}
				</div>,
				<div
					key={`${keyPrefix}-crop`}
					className={`${keyPrefix}-crop`}
				>
					{determineCropIcon(item.CropId)}
				</div>,
			]
		};
	});
};