
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { PpgPickSeedRow } from './PpgPickSeedRow';
import { PpgSelectedSeedRow } from './PpgSelectedSeedRow';
import { getSeedsForUser } from '../../../logic/store/Seeds/SeedsActions';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { IHybridResponse } from '../../../logic/Models/Responses/SeedResponse';
import { cornId, soyId } from '../../../logic/store/Seeds/CropsSlice';
import { downloadPpgPdf } from '../../../logic/store/PPG/PpgThunks';
import _ from 'lodash';
import { Button } from '../../../components/Button/Button';

const PpgMainContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
`;
const PpgInnerContainer = styled.div`
	height: 65%;
	width: 100%;
	display: flex;
	flex-direction: row;
`;

const mapStateToProps = (state: RootState) => 
{
	return {
		AllUserSeeds: state.seeds.userSeeds,
		IsLoadingSeeds: state.seeds.isLoading,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		GetUserSeeds: makeDispatch(dispatch, getSeedsForUser),
		DownloadPpgPdf: makeDispatch(dispatch, downloadPpgPdf),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IPpgProps extends PropsFromRedux
{
}

const PpgMainComponent = (props: IPpgProps) =>
{
	const { AllUserSeeds, IsLoadingSeeds, GetUserSeeds, DownloadPpgPdf } = props;
	const [allHybridsList, setHybridsList] = useState<IHybridResponse[]>([]);
	const [selectedSeeds, setSelectedSeeds] = useState<IHybridResponse[]>([]);
	const maxSelectedSeeds: number = 8;

	const soyHybridList = useMemo(() => allHybridsList?.filter(h => h.CropId === soyId).sort(dynamicSort('RelativeMaturity')), [allHybridsList]);
	const soySelectedHybrids = useMemo(() => selectedSeeds?.filter(ss => ss.CropId === soyId), [selectedSeeds]);
	const cornHybridList = useMemo(() => allHybridsList?.filter(h => h.CropId === cornId).sort(dynamicSort('RelativeMaturity')), [allHybridsList]);
	const cornSelectedHybrids = useMemo(() => selectedSeeds?.filter(ss => ss.CropId === cornId), [selectedSeeds]);
	const disableSubmitButton = useMemo(() => selectedSeeds.length === 0, [selectedSeeds]);

	const theme = useTheme();

	useEffect(() => 
	{
		if (!IsLoadingSeeds && !AllUserSeeds)
		{
			GetUserSeeds();
		}
		else if (!IsLoadingSeeds && AllUserSeeds)
		{
			// Assign the hybrid lists to the state
			const allUserHybrids: IHybridResponse[] = _.cloneDeep(AllUserSeeds).flatMap(s => s.BrandApplications.flatMap(b => b.Hybrids));
			setHybridsList(allUserHybrids);
		}
	}, [IsLoadingSeeds, AllUserSeeds]);

	const selectSeed = (selectedSeedId: string) =>
	{
		const foundSeed = allHybridsList.find(s => s.Id === selectedSeedId);
		setSelectedSeeds([...selectedSeeds, foundSeed]);
	};

	const deleteSelectedseed = (selectedSeedIdToDelete: string) =>
	{
		let updatedSelectedSeeds = selectedSeeds;
		updatedSelectedSeeds = selectedSeeds.filter(s => s.Id !== selectedSeedIdToDelete);
		setSelectedSeeds([...updatedSelectedSeeds]);
	};

	const onSubmit = useCallback(() =>
	{
		const selectedHybridIds = selectedSeeds.map(s => s.Id);
		DownloadPpgPdf({HybridIds: selectedHybridIds});
	},[DownloadPpgPdf, selectedSeeds]);

	return (
		<PpgMainContainer className='PpgMainContainer'>
			<div
				style={{
					fontWeight: theme.fontWeights.bold,
					fontSize: theme.fontSizes.xLarge,
					fontFamily: theme.fonts.heading,
				}}>Create a Product Placement Guide</div>
			<div
				style={{
					marginBottom: 15,
					fontFamily: theme.fonts.heading,
				}}>Please select the products you want to include in the guide. Up to 8 products per crop.</div>
			{
				IsLoadingSeeds && allHybridsList?.length > 0 ?
					<div>Spinner Here</div>
					:
					<PpgInnerContainer>
						<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
							<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large }}>
								{`Corn (${selectedSeeds?.filter(s => s.CropId === cornId).length} of ${maxSelectedSeeds})`}
							</div>							
							<PpgPickSeedRow 
								seedList={cornHybridList}
								selectSeed={selectSeed}
								selectedSeeds={cornSelectedHybrids && cornSelectedHybrids.length > 0 ? cornSelectedHybrids : []}
								disableAllSeeds={cornSelectedHybrids && cornSelectedHybrids.length === maxSelectedSeeds}
							/>
							<div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
								{
									cornSelectedHybrids.filter(s => s.CropId === cornId).map(s =>
									{
										return(
											<PpgSelectedSeedRow key={s.Id} seed={s} deleteSeed={deleteSelectedseed} />
										);
									})
								}
							</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
							<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large }}>
								{`Soybean (${soySelectedHybrids?.filter(s => s.CropId === soyId).length} of ${maxSelectedSeeds})`}
							</div>
							<PpgPickSeedRow
								seedList={soyHybridList}
								selectSeed={selectSeed}
								selectedSeeds={soySelectedHybrids && soySelectedHybrids.length > 0 ? soySelectedHybrids : []}
								disableAllSeeds={soySelectedHybrids && soySelectedHybrids.length === maxSelectedSeeds}
							/>
							<div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
								{
									soySelectedHybrids.filter(s => s.CropId === soyId).map(s =>
									{
										return(
											<PpgSelectedSeedRow key={s.Id} seed={s} deleteSeed={deleteSelectedseed} />
										);
									})
								}
							</div>
						</div>
					</PpgInnerContainer>
			}
			<Button
				onClick={onSubmit}
				disabled={disableSubmitButton}
				variant='main'
				height={'40px'}
				width={'125px'}
				style={{ marginLeft: 'auto', marginTop: 'auto' }}
			>Download</Button>
		</PpgMainContainer>
	);
};

export const PpgMain = connector(PpgMainComponent);
