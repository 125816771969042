import React, { useCallback, useEffect } from 'react';
import { Form, Input, Select, Tooltip, Upload, UploadProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useMemo, useState } from 'react';
import { Button } from '../../components/Button/Button';
import { ReactComponent as CircledCheckGreen } from '../../assets/images/CircledCheckGreen.svg';
import { ReactComponent as ConflictIcon } from '../../assets/images/Conflict.svg';
import { ReactComponent as EyeIcon } from '../../assets/images/Eye.svg';
import { ReactComponent as PencilIcon } from '../../assets/images/Pencil.svg';
import { ReactComponent as XIcon } from '../../assets/images/Xmark.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/Upload.svg';
import { IconButton } from '../../components/IconButton/IconButton';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import styled, { useTheme } from 'styled-components';
import { EditUserForm } from './Components/EditUserForm';
import { CreateUserForm } from './Components/CreateUserForm';
import { EditRoleForm } from './Components/EditRoleForm';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../logic/store/Store';
import { IUserProjection } from '../../logic/Models/Responses/UserAdmin/IUserProjection';
import { IUpdateUserRequest } from '../../logic/Models/Requests/UserAdmin/IUpdateUserRequest';
import { ICreateUserRequest } from '../../logic/Models/Requests/UserAdmin/ICreateUserRequest';
import { dynamicSort, stableStringSort } from '../../logic/Utility/Utils';
import {
	selectIsLoading,
	selectEditingUser,
	selectDistinctCompanies,
	selectDistinctLevels,
	selectDistinctDomains,
	accessControlLabel,
	accessControlsForUser
} from '../../logic/store/UserAdmin/UserAdminSelectors';
import { setEditingUserId } from '../../logic/store/UserAdmin/UserAdminSlice';
import { 
	saveUser, 
	createUser, 
	exportUsers,
	toggleActivation, 
	getUsers, 
	getRoles, 
	getOrganizations, 
	getAccessControls, 
	getEmailTemplates, 
	userFileImport,
	IUserFileImportRequest,
	getPermissions,
	updateRole,
	createRole,
	deleteRole,
} from '../../logic/store/UserAdmin/UserAdminThunks';
import { CoveringLoader } from '../../components/LoadingSpinner/CoveringLoader';
import { RcFile } from 'antd/lib/upload';
import { IRoleProjection } from '../../logic/Models/Responses/UserAdmin/IRoleProjection';
import { EmailTemplateView } from './EmailTemplateView';
import { cloneDeep } from 'lodash';
import { UsersTable, RolesTable } from './VirtualTable';
import moment from 'moment';

const StyledUserAdminViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	height: 100%;
`;
const StyledRoleAdminViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	height: 100%;
`;
const StyledEmailTemplateAdminViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	height: 100%;
`;


const { Option } = Select;
const { Dragger } = Upload;

export enum UserAdminViewState
{
	Users, // Viewing the user list
	EmailTemplates, // Viewing the Email Templates
	Roles, // Viewing the Roles list
}

interface IUserAdminViewState
{
	view: UserAdminViewState.Users
	| UserAdminViewState.EmailTemplates
	| UserAdminViewState.Roles
}

type IUserAdminViewMode = IUserAdminViewState;


/**
 * The page level component for User Admin.
 * 
 * Manages the User listing directly, as well as a the create and edit user modals.
 * In the future will allow editing invitation templates and roles.
 */
export const UserAdminPage = () => 
{
	const theme = useTheme();
	const dispatch = useDispatch();
	
	const isLoading = useSelector(selectIsLoading);
	const loadingCount = useSelector((state: RootState) => state.userAdmin.loadingCount);

	const users = useSelector((state: RootState) => state.userAdmin.users);

	const roles = useSelector((state: RootState) => state.userAdmin.roles);
	const permissions = useSelector((state: RootState) => state.userAdmin.permissions);
	const organizations = useSelector((state: RootState) => state.userAdmin.organizations);
	const accessControls = useSelector((state: RootState) => state.userAdmin.accessControls);
	const invitationTemplates = useSelector((state: RootState) => state.userAdmin.invitationTemplates);

	const userEdit = useSelector(selectEditingUser);
	const distinctCompanies = useSelector(selectDistinctCompanies);
	const distinctLevels = useSelector(selectDistinctLevels);
	const distinctDomains = useSelector(selectDistinctDomains);

	// Default view state
	const [currentviewState, setCurrentViewState] = useState<IUserAdminViewMode>({view: UserAdminViewState.Users });
	const [showCreateUser, setShowCreateUser] = useState(false);
	const [searchNeedle, setSearchNeedle] = useState<string>();
	const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
	const [selectedRoleId, setSelectedRoleId] = useState<string>(undefined);
	const [showEditRoleModal, setShowEditRoleModal] = useState<boolean>(false);
	const [showUnderstandDeleteModal, setShowUnderstandDeleteModal] = useState<boolean>(false);
	const [showReplacementRoleDeleteModal, setShowReplacementRoleDeleteModal] = useState<boolean>(false);
	const [isDeleteButtonDisabled, setIsDeletedButtonDisabled] = useState<boolean>(true);

	// If the user has typed in a filter, apply it to the list of users
	const filteredUsers = useMemo(() => 
	{
		if(!searchNeedle?.length)
		{
			return users;
		}

		const needle = searchNeedle.toLowerCase().trim();
		return users.filter(u =>
			u.AccessControlOrganizationalUnit?.toLowerCase().includes(needle)
			|| u.Company?.toLowerCase().includes(needle)
			|| u.FoundationId?.toLowerCase().includes(needle)
			|| u.Name?.toLowerCase().includes(needle)
			|| u.Notes?.toLowerCase().includes(needle)
			|| u.RoleName.toLowerCase().includes(needle)
			|| u.Username.toLowerCase().includes(needle)
			|| accessControlLabel(accessControlsForUser(accessControls, u)).toLowerCase().includes(needle)
		);
	}, [searchNeedle, users, accessControls]);

	// If the user has typed in a filter, apply it to the list of roles
	const filteredRoles = useMemo(() => 
	{
		if(!searchNeedle?.length)
		{
			return roles;
		}

		const needle = searchNeedle.toLowerCase();
		return roles.filter(u =>
			u.Name?.toLowerCase().includes(needle)
			|| u.Description?.toLowerCase().includes(needle)
		);
	}, [searchNeedle, roles]);

	const setUserIdEditAction = useCallback((id: string | undefined) => 
	{
		dispatch(setEditingUserId(id));
	}, [dispatch]);

	const saveUserAction = useCallback((params: {id: string, update: IUpdateUserRequest}) => 
	{
		dispatch(saveUser(params));
	},[dispatch]);
	
	const createUserAction = useCallback((data: ICreateUserRequest) => 
	{
		dispatch(createUser(data));
	}, [dispatch]);

	const toggleActivationAction = useCallback((user: IUserProjection) => 
	{
		dispatch(toggleActivation(user));
	}, [dispatch]);

	// Initially fetch all the lists of data
	useEffect(() => 
	{
		dispatch(getUsers());
		dispatch(getRoles());
		dispatch(getPermissions());
		dispatch(getOrganizations());
		dispatch(getAccessControls());
		dispatch(getEmailTemplates());
	}, [dispatch]);
	
	// Build the columns for the user table
	const userColumns = useMemo<ColumnsType<IUserProjection>>(() => [
		{
			title: 'Name',
			dataIndex: '',
			sorter: (a, b) => stableStringSort(a.Name, b.Name),
			defaultSortOrder: 'ascend',
			sortDirections: ['descend', 'ascend'],
			width: '15%',
			filters: distinctCompanies.map<ColumnFilterItem>(c => ({
				text: c,
				value: c
			})),
			onFilter: (value: string, record) => record.Company === value,
			render: function NameCell(a, record, idx) 
			{ 
				return <div style={{display:'flex', flexDirection:'column'}}>
					<h4>{record.Name}</h4>
					<h5>{record.Company}</h5>
				</div>; 
			}
		},
		{
			title: 'Email',
			dataIndex: 'Username',
			sorter: (a, b) => stableStringSort(a.Username, b.Username),
			sortDirections: ['descend', 'ascend'],
			width: '20%',
			filters: distinctDomains.map<ColumnFilterItem>(c => ({
				text: c,
				value: c
			})),
			onFilter: (value: string, record) => record.Username.toLowerCase().endsWith(value.toLowerCase())
		},
		{
			title: 'Foundation ID',
			dataIndex: 'FoundationId',
			sorter: (a, b) => stableStringSort(a.FoundationId, b.FoundationId),
			sortDirections: ['descend', 'ascend'],
			width: '10%'
		},
		{
			title: 'Role',
			dataIndex: 'RoleName',
			sorter: (a, b) => stableStringSort(a.RoleName, b.RoleName),
			sortDirections: ['descend', 'ascend'],
			width: '15%',
			filters: roles.map<ColumnFilterItem>(r => ({
				text: r.Name,
				value: r.Id
			})),
			onFilter:  (value: string, record) => record.RoleId === value
		},
		{
			title: 'Level',
			dataIndex: '',
			sorter: (a, b) => stableStringSort(accessControlLabel(accessControlsForUser(accessControls, a)), accessControlLabel(accessControlsForUser(accessControls, b))),
			sortDirections: ['descend', 'ascend'],
			width: '15%',
			filters: distinctLevels.map<ColumnFilterItem>(r => ({
				text: r,
				value: r
			})),
			onFilter:  (value: string, record) => accessControlLabel(accessControlsForUser(accessControls, record)).toLowerCase().includes(value.toLowerCase()),
			render: function LevelCell(a, record, idx) 
			{
				const acs = accessControlsForUser(accessControls, record);
				let prefix = <></>;

				// There are a number of possible concerns here:

				// If there isn't a foundation Id, we definitely need to have an entry that matches by email
				if(!record.FoundationId?.length && !acs.length)
				{
					prefix = <Tooltip title='This email does not exist in the hierarchy'><ConflictIcon /></Tooltip>;
				}

				// Did we just not find any matches but there is a foundation id?
				else if(record.FoundationId?.length && !acs.some(ac => ac.FoundationId?.length && ac.FoundationId === record.FoundationId))
				{
					prefix = <Tooltip title='This Foundation ID does not exist in the hierarchy'><ConflictIcon /></Tooltip>;
				}

				// Are there nodes that match the email but not the foundation id?
				else if(record.FoundationId?.length && acs.some(ac => ac.FoundationId?.length && ac.FoundationId !== record.FoundationId))
				{
					prefix = <Tooltip title='There are conflicting Foundation IDs for this email'><ConflictIcon /></Tooltip>;
				}

				// Do we not have a foundation ID but our only record does?
				else if(!record.FoundationId?.length && acs.length === 1 && acs[0].FoundationId?.length)
				{
					prefix = <Tooltip title='This account does not have a Foundation ID but the hierarchy does'><ConflictIcon /></ Tooltip>;
				}

				// Are there nodes that match the foundation id but not the email?
				else if(acs.some(ac => ac.Email?.length && ac.Email.toLowerCase() !== record.Username.toLowerCase()))
				{
					prefix = <Tooltip title='There are conflicting email addresses for this Foundation Id'><EyeIcon /></Tooltip>;
				}

				// Are there multiple nodes that match?
				else if(acs.length > 1)
				{
					prefix = <Tooltip title='Multiple levels found for this email'><EyeIcon /></Tooltip>;
				}

				// Are there multiple nodes that match the email?
				return <div>{prefix} {accessControlLabel(acs)}</div>;
			}
		},
		{
			title: 'Last Login',
			dataIndex: 'LastLoginSuccess',
			sorter: (a, b) => stableStringSort(a.LastLoginSuccess, b.LastLoginSuccess),
			sortDirections: ['descend', 'ascend'],
			width: '15%',
			render: function DateCell(a, record, idx) 
			{
				return record.LastLoginSuccess ? new Date(record.LastLoginSuccess).toLocaleString() : ''; 
			}
		},
		{
			title: 'Active',
			dataIndex: '',
			render: function CommandCell(a, record, idx) 
			{ 
				return <div style={{display:'flex', flexDirection:'row'}}>
					<IconButton onClick={() => setUserIdEditAction(record.Id)}>
						<PencilIcon />
					</IconButton>
					<IconButton onClick={() => setUserIdEditAction(record.Id)}>
						{!record.IsDeactivated 
							? <CircledCheckGreen /> 
							: <XIcon />}
					</IconButton>
				</div>;
			},
			width: '10%',
			filters: [
				{
					text: 'Active',
					value: true
				},
				{
					text: 'Inactive',
					value: false
				}
			],
			defaultFilteredValue: [true], // Add this line to default to Active filter
			onFilter:  (value, record) => Boolean((value === undefined || value === null || 
				(!value && !!record.IsDeactivated) || (value && !record.IsDeactivated)))
		}
	], [distinctCompanies, distinctDomains, roles, distinctLevels, accessControls, setUserIdEditAction]);

	const editRole = useCallback((roleId: string) =>
	{
		// Open the role modal for the specifically selected role
		setSelectedRoleId(roleId);
		setShowEditRoleModal(true);
	},[setSelectedRoleId, setShowEditRoleModal]);

	// Build the columns for the role table
	const roleColumns = useMemo<ColumnsType<IRoleProjection>>(() => [
		{
			title: 'Name',
			dataIndex: 'Name',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => stableStringSort(a.Name, b.Name),
			sortDirections: ['descend', 'ascend'],
			width: '30%',
			render: function NameCell(a, record, idx) 
			{ 
				return(
					<div
						style={{
							display:'flex',
							flexDirection:'column',
							fontWeight: theme.fontWeights.bold,
							cursor: 'pointer',
						}}
						onClick={() => editRole(record.Id)}>
						<h4>{record.Name}</h4>
					</div>
				); 
			}
		},
		{
			title: 'Description',
			dataIndex: 'Description',
			sorter: (a, b) => stableStringSort(a.Description, b.Description),
			sortDirections: ['descend', 'ascend'],
			width: '60%',
		},
		{
			title: 'Actions',
			dataIndex: '',
			render: function CommandCell(a, record, idx) 
			{ 
				return <div style={{display:'flex', flexDirection:'row'}}>
					<Button variant='dark' onClick={() => onDeleteRole(record.Id)}>
						Delete
					</Button>
				</div>;
			},
			width: '10%',
		}
	], [roles]);

	const onCancel = useCallback(() => 
	{
		setUserIdEditAction(undefined);
		setShowCreateUser(false);
	}, [setUserIdEditAction]);

	const switchToUser = useCallback((id: string) => 
	{
		setUserIdEditAction(id);
		setShowCreateUser(false);
	}, [setUserIdEditAction]);

	const downloadExport = useCallback(() => 
	{
		dispatch(exportUsers());
	}, [dispatch]);

	const importCSV = useCallback((importFormRequest: IUserFileImportRequest ) => 
	{
		setImportModalOpen(false);
		dispatch(userFileImport(importFormRequest));		
	}, [dispatch]);

	
	/**
	 * Types exported from RC files to allow us to use the customRequest in AntDesign's uploader...
	 */
	interface IUploadProgressEvent extends Partial<ProgressEvent> {
		percent?: number;
	}

	type UploadRequestMethod = 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';
	type BeforeUploadFileType = File | Blob | boolean | string;
	type UploadRequestHeader = Record<string, string>;

	interface IUploadRequestError extends Error {
		status?: number;
		method?: UploadRequestMethod;
		url?: string;
	}

	interface IUploadRequestOption<T = any> {
		onProgress?: (event: IUploadProgressEvent) => void;
		onError?: (event: IUploadRequestError | ProgressEvent, body?: T) => void;
		onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
		data?: Record<string, unknown>;
		filename?: string;
		file: Exclude<BeforeUploadFileType, File | boolean> | RcFile;
		withCredentials?: boolean;
		action: string;
		headers?: UploadRequestHeader;
		method: UploadRequestMethod;
	}
	/**
	 * End RC Types
	 */

	// To keep the upload component from throwing an error since we are uploading in a different manner
	// The types here are a nightmare, ant-design is not properly exporting the type for the customRequest
	const dummyRequest = (options: IUploadRequestOption<any>) =>
	{
		setTimeout(() => { options.onSuccess('ok');}, 0);
	};

	const uploadProps: UploadProps =
	{
		showUploadList: true,
		multiple: false,
		customRequest: dummyRequest,
		maxCount: 1
	};

	const viewSelection = () =>
	{
		switch(currentviewState.view)
		{
			case UserAdminViewState.Users:
				return (
					<StyledUserAdminViewContainer className='UserAdmin_ViewContainer'>
						<div style={{ padding: 8, display: 'flex', flexDirection:'row' }}>
							<div style={{ display:'flex', flexDirection:'column', width:'50%' }}>
								{/* Our search here will filter the user list in the table */}
								<h3>Search:</h3>
								<Input.Search
									allowClear
									enterButton
									defaultValue={''} 
									loading={isLoading}
									onSearch={setSearchNeedle}
								/>
							</div>
							<div style={{display:'flex', flexDirection:'row', minWidth: 100, height: '100%', marginLeft: 'auto', alignItems: 'center'}}>
								{/* Let the user create a new user via the modal below */}
								<Button variant='dark' onClick={() => setShowCreateUser(true)}>
									Add User
								</Button>
								<Button variant='dark' onClick={downloadExport} style={{marginLeft: 4}}>
									Export All
								</Button>
								<Button variant='dark' onClick={() => setImportModalOpen(true)} style={{marginLeft: 4}}>
									Import/Update
								</Button>
							</div>
						</div>
						{/* Our data table; could become 'virtual' in the future to avoid paging */}
						<UsersTable columns={userColumns} dataSource={filteredUsers} />
					</StyledUserAdminViewContainer>
				);
			case UserAdminViewState.Roles:
				return (
					<StyledRoleAdminViewContainer className='RoleAdmin_ViewContainer'>
						<div style={{ padding: 8, display: 'flex', flexDirection:'row' }}>
							<div style={{ display:'flex', flexDirection:'column', width:'50%' }}>
								{/* Our search here will filter the user list in the table */}
								<h3>Search:</h3>
								<Input.Search
									allowClear
									enterButton
									defaultValue={''} 
									loading={isLoading}
									onSearch={setSearchNeedle}
								/>
							</div>
							<div style={{display:'flex', flexDirection:'row', minWidth: 100, height: '100%', marginLeft: 'auto', alignItems: 'center'}}>
								{/* Let the user create a new user via the modal below */}
								<Button variant='dark' onClick={() => setShowEditRoleModal(true)}>
									Add Role
								</Button>
							</div>
						</div>
						<div style={{overflowY: 'auto', flexGrow:1}}>
							{/* Our data table; could become 'virtual' in the future to avoid paging */}
							<RolesTable columns={roleColumns} dataSource={filteredRoles} />
						</div>
					</StyledRoleAdminViewContainer>
				);
			case UserAdminViewState.EmailTemplates:
				return (
					<StyledEmailTemplateAdminViewContainer className='EmailTemplatesAdmin_ViewContainer'>
						<EmailTemplateView invitationTemplates={invitationTemplates} />
					</StyledEmailTemplateAdminViewContainer>
				);
		}
	};

	const switchViewState = (newViewState: UserAdminViewState) =>
	{
		setSearchNeedle('');
		setCurrentViewState({ view: newViewState });
	};

	const closeRoleModal = useCallback(() =>
	{
		setSelectedRoleId(undefined);
		setShowEditRoleModal(false);
	},[setSelectedRoleId, setShowEditRoleModal]);

	const createOrEditRoleAction = useCallback((name: string, description: string, permissions?: string[]) =>
	{
		if (selectedRoleId)
		{
			// Editing an existing role
			dispatch(updateRole({ Name: name, Description: description, RoleId: selectedRoleId, Permissions: permissions ?? undefined }));
			closeRoleModal();
		}
		else
		{
			// Creating a new role
			dispatch(createRole({ Name: name, Description: description, Permissions: permissions ?? undefined }));
			closeRoleModal();
		}
	},[createRole, updateRole, selectedRoleId, closeRoleModal]);

	const onCancelDelete = useCallback(() =>
	{
		setShowUnderstandDeleteModal(false);
		setShowReplacementRoleDeleteModal(false);
		setSelectedRoleId(undefined);
		setIsDeletedButtonDisabled(true);
	},[setShowUnderstandDeleteModal, setShowReplacementRoleDeleteModal, setSelectedRoleId]);

	const onDeleteRole = useCallback((roleId: string) =>
	{
		setSelectedRoleId(roleId);
		setShowUnderstandDeleteModal(true);
	},[setSelectedRoleId,setShowUnderstandDeleteModal]);

	const understandDelete = useCallback(() =>
	{
		setShowUnderstandDeleteModal(false);
		setShowReplacementRoleDeleteModal(true);
	},[setShowUnderstandDeleteModal,setShowReplacementRoleDeleteModal]);

	const deleteAction = useCallback((deleteItem: {ReplacementRoleId: string}) =>
	{
		dispatch(deleteRole({ roleIdToDelete: selectedRoleId, replacementId: deleteItem.ReplacementRoleId }));
		setSelectedRoleId(undefined);
		setShowReplacementRoleDeleteModal(false);
		setIsDeletedButtonDisabled(true);
	},[deleteRole, selectedRoleId, setSelectedRoleId, setShowReplacementRoleDeleteModal]);

	const enableDeleteButton = (value: string) =>
	{
		if (value)
		{
			setIsDeletedButtonDisabled(false);
		}
	};

	return (
		<div
			className='UserAdmin_InnerContainer'
			style={{
				display: 'flex',
				flexDirection:'column',
				justifyContent: 'flex-start',
				height: '100%',
			}}
		>
			<h1 style={{padding: 8, paddingTop: 16, margin: 0}}>User Admin</h1>
			<div style={{ display: 'flex', flexDirection: 'row', padding: 8 }}>
				{/* Will probably need to update these onclick functions to do a new pull for each panel */}
				<Button
					style={{width:200, flexGrow:1}}
					variant={currentviewState.view === UserAdminViewState.Users ? 'dark' : 'outlined'}
					onClick={() => switchViewState(UserAdminViewState.Users)}
				>Users</Button>
				<Button
					style={{width:200, flexGrow:1}}
					variant={currentviewState.view === UserAdminViewState.EmailTemplates ? 'dark' : 'outlined'}
					onClick={() => switchViewState(UserAdminViewState.EmailTemplates)}
				>Email Templates</Button>
				<Button
					style={{width:200, flexGrow:1}}
					variant={currentviewState.view === UserAdminViewState.Roles ? 'dark' : 'outlined'}
					onClick={() => switchViewState(UserAdminViewState.Roles)}
				>Roles</Button>
			</div>

			{viewSelection()}
			
			{/* Display a loading spinner when the data is loading */ }
			<CoveringLoader className={isLoading ? 'loading' : ''} style={{ zIndex: 9999 }}>
				<LoadingSpinner 
					loading={isLoading} 
					style={{position:'absolute', top:200, left: '50%', transform:'translateX(-50%)'}}>
					Loading... ({loadingCount} remain)
				</LoadingSpinner>
			</CoveringLoader>

			{/* The modal for editing an existing user */}
			<StyledModal title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>Edit User {userEdit?.Username} {userEdit?.IsDeactivated && `(Deactivated ${moment.parseZone(userEdit.IsDeactivated).format('MM/DD/YYYY')})`}</div>
			}
			open={!!userEdit}
			width={'85%'}
			closable={true}
			onCancel={onCancel}
			destroyOnClose={true}
			>
				{ !!userEdit && 
					<EditUserForm 
						roles={cloneDeep(roles).sort(dynamicSort('Name'))} 
						invitationTemplates={invitationTemplates}
						organizations={organizations} 
						userEdit={userEdit} 
						accessControls={accessControlsForUser(accessControls, userEdit)}
						toggleActivation={toggleActivationAction}
						saveUser={saveUserAction}
					/>
				}
			</StyledModal>

			{/* The modal for creating a new user */}
			<StyledModal title={<div style={{
				fontWeight: theme.fontWeights.bold,
				fontFamily: theme.fonts.heading,
				fontSize: theme.fontSizes.xLarge,
				paddingTop: 10,
			}}>Create User</div>}
			open={showCreateUser}
			width={'85%'}
			closable={true}
			onCancel={onCancel}
			destroyOnClose={true}
			>
				<CreateUserForm 
					users={users} 
					accessControls={accessControls} 
					createUser={createUserAction} 
					switchToUser={switchToUser} 
					organizations={organizations} 
					roles={cloneDeep(roles).sort(dynamicSort('Name'))}
				/>
			</StyledModal>
		
			{/* The modal for importing a user csv */}
			<StyledModal 
				bodyStyle={{paddingTop: 0}}
				title={<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
				}}>Import User CSV</div>}
				open={importModalOpen}
				closable={true}
				onCancel={() => setImportModalOpen(false)}
				destroyOnClose={true}
			>
				<Form scrollToFirstError onFinish={importCSV}>
					<Form.Item 
						name='UploadFile'
						label='File'
						valuePropName='UploadFile'
						required
						rules={[
							{
								required: true,
								message: 'Please choose a file.'
							}
						]}
						className='ImportFileUploadDragger' 
						style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, fontWeight: theme.fontWeights.bold }}
					>				
						<Dragger style={{height:'70%'}} {...uploadProps}>
							<p>
								<UploadIcon />
							</p>
							<div style={{ fontSize: theme.fontSizes.xLarge, fontWeight: theme.fontWeights.bold }}>Click or drag a .csv file to this area to upload</div>
						</Dragger>
					</Form.Item>			
					<Form.Item
						name='RoleId'
						label='Role'
						required
						rules={[
							{
								required: true,
								message: 'Please select a role.'
							}
						]}
						style={{fontWeight: theme.fontWeights.bold, marginTop: 30}}
					>
						<Select placeholder='Pick the role for the imported users:'>
							{
								roles.map(r => <Option key={r.Id} value={r.Id}>{ r.Name }</Option>)
							}
						</Select>
					</Form.Item>
					<Form.Item
						name='SalesHierarchyOrganizationId'
						label='Organization'
						style={{fontWeight: theme.fontWeights.bold, marginTop: 5}}
					>
						<Select placeholder='(Optional) Pick the Organization for the imported users:'>
							{
								organizations.map(o => <Option key={o.Id} value={o.Id}>{ o.Name }</Option>)
							}
						</Select>
					</Form.Item>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Button style={{ marginLeft: 'auto', width: 150 }} variant='dark'>Upload</Button>
					</div>
				</Form>
			</StyledModal>

			{/* The modal for editing a role */}
			<StyledModal title={<div style={{
				fontWeight: theme.fontWeights.bold,
				fontFamily: theme.fonts.heading,
				fontSize: theme.fontSizes.xLarge,
				paddingTop: 10,
			}}>{selectedRoleId ? 'Edit Role' : 'Create Role'}</div>}
			open={showEditRoleModal}
			width={'50%'}
			height={'75vh'}
			bodyStyle={{ display: 'flex' }}
			closable={true}
			onCancel={closeRoleModal}
			destroyOnClose={true}
			>
				<EditRoleForm 
					selectedRole={selectedRoleId ? roles.find(r => r.Id === selectedRoleId) : undefined} 
					permissions={cloneDeep(permissions).sort(dynamicSort('Name'))}
					createOrEditRole={createOrEditRoleAction}
				/>
			</StyledModal>

			{/* The modal for deleting a role with 'I Understand' button */}
			<StyledModal title={<div style={{
				fontWeight: theme.fontWeights.bold,
				fontFamily: theme.fonts.heading,
				fontSize: theme.fontSizes.xLarge,
			}}>{`Attempting to delete role: ${roles.find(r => r.Id === selectedRoleId)?.Name}`}</div>}
			open={showUnderstandDeleteModal}
			bodyStyle={{ display: 'flex' }}
			closable={true}
			onCancel={onCancelDelete}
			destroyOnClose={true}
			>
				<div style={{ width: '100%', marginTop: -20 }}>
					<span style={{ color: theme.colors.availabilityRed, fontWeight: theme.fontWeights.bold }}>
						{`Warning, you are about to delete the role ${roles.find(r => r.Id === selectedRoleId)?.Name}. This action is permanent and can not be undone.`}
					</span>
					<Button
						style={{ marginLeft: 'auto', width: '100%', marginTop: 15 }}
						variant='dark'
						onClick={() => understandDelete()}
					>
						I Understand
					</Button>
				</div>
			</StyledModal>

			{/* The modal for deleting a role with selecting a replacement role */}
			<StyledModal title={<div style={{
				fontWeight: theme.fontWeights.bold,
				fontFamily: theme.fonts.heading,
				fontSize: theme.fontSizes.xLarge,
			}}>{`Attempting to delete role: ${roles.find(r => r.Id === selectedRoleId)?.Name}`}</div>}
			open={showReplacementRoleDeleteModal}
			bodyStyle={{ display: 'flex' }}
			closable={true}
			onCancel={onCancelDelete}
			destroyOnClose={true}
			>
				<Form scrollToFirstError onFinish={deleteAction} style={{ width: '100%', marginTop: -20 }}>
					<span>
						{'After this role is deleted, any existing users assigned this role will need a new role. Please select a new role from the list below:'}
					</span>
					<Form.Item
						name='ReplacementRoleId'
						label='Replacement Role'
						required
						style={{ fontWeight: theme.fontWeights.bold, marginTop: 15 }}
					>
						<Select placeholder='Please choose a role' onChange={enableDeleteButton}>
							{
								cloneDeep(roles).filter(r => r.Id !== selectedRoleId)
									.sort(dynamicSort('Name')).map(r => <Option key={r.Id} value={r.Id}>{ r.Name }</Option>)
							}
						</Select>
					</Form.Item>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Button
							disabled={isDeleteButtonDisabled}
							style={{ marginLeft: 'auto', width: '100%' }}
							variant='dark'
						>
							Delete Role
						</Button>
					</div>
				</Form>
			</StyledModal>
		</div>
	);
};
