import styled from 'styled-components';

/**
 * Styled component for the radio list
 */
export const RadioList = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto; 
	scrollbar-gutter: stable;
	flex-grow: 1;
	align-items: stretch; 
	justify-content: start;

	label {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-top: 8px;
		padding-bottom: 8px;
		margin-right: 0;
	
		>span.ant-radio {
			top: initial;
			margin-left: 8px;
		}

		>span:nth-child(2) {
			flex-grow: 1;
			padding-right: 0;
		}
	}

	label:nth-child(even) {
		background-color: #F3F4F6;
	}

	div.label_container {
		display: flex;
		flex-direction: row;
		align-items: stretch;

		div.name_label {
			flex-basis: 0;
			flex-grow: 1;
		}

		div.acre_label {
			overflow: auto;
			flex-basis: 60px;
			flex-grow: 0;
		}
	}
`;
