import React from 'react';
import styled from 'styled-components';
import { IPlantingPlanSummaryCropPlan } from '../../../logic/Models/Responses/PlantingPlanSummaryResponse';
import { ColorTabButton } from '../../../components/ColorTabButton/ColorTabButton';

const TabButtonContainer = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
`;

interface ICropPlanOverviewProps
{
	cropPlans: IPlantingPlanSummaryCropPlan[];
	selectCropPlan: (planId: string) => void;
}

export const CropPlanOverview = (props: ICropPlanOverviewProps) =>
{
	const { cropPlans, selectCropPlan } = props;

	return (
		<TabButtonContainer className='VRS_TabButtonContainer'>
			{cropPlans.map(cp =>
				<ColorTabButton
					addCard={false}
					key={cp.CropPlanId}
					className={`CropPlanTabButton_${cp.CropPlanId}`}
					title={cp.CropPlanName}
					tabColor={cp.Color}
					displayNamesAndValues={[['Fields', cp.CropPlanFieldCount.toString()], ['Acres', cp.CropPlanArea.toFixed(1) ]]}
					onClick={() => selectCropPlan(cp.CropPlanId)}
					variant='reg'
				/>
			)}
		</TabButtonContainer>
	);
};