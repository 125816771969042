// https://digitial-product-engineering.atlassian.net/wiki/spaces/GHX/pages/2699592038/Amplitude+User+and+Group+Properties

export enum EventUserTags {
	UserFoundationId = 'user.fsap_id',
	UserCropwiseId = 'user.cropwise_id',
	UserGhxFieldsId = 'user.ghx_fields_id',
	UserEmail = 'user.email',
	UserName = 'user.name',
	ManagedUserFoundationId = 'seller.fsap_id',
	ManagedUserGhxFieldsId = 'seller.ghx_fields_id',
	ManagedUserEmail = 'seller.email',
	ManagedUserName = 'seller.name',
	ManagedUserPermissions = 'seller.permissions',


	SalesforceContactId = 'user.salesforce_contact_id',
	SalesGroup = 'user.sales_group',
	SalesforceAccountId = 'user.salesforce_account_id',
	SalesforceUserId = 'user.salesforce_user_id',
	SalesDivision = 'user.sales_division',
	CommercialUnit = 'user.commercial_unit',
	SalesOffice = 'user.sales_office',
}

export enum EventGrowerTags {
	GrowerName = 'grower.name',
	GrowerSalesforceId = 'grower.salesforce_account_id',
	GrowerFoundationId = 'grower.fsap_id',
}

export enum EventPlanTags {
	PlanName = 'plan.name',
	PlanType = 'plan.type',
	PlanDownloaded = 'plan.downloaded',
	PlanYear = 'plan.year',
	PlanStatus = 'plan.status',
	PlanOrderId = 'plan.orderid',
}

export enum EventSeedTags {
	HybridId = 'hybrid.id'
}

export enum EventCropPlanTags {
	CropPlanName = 'crop_plan.name',
	CropPlanCropId = 'crop_plan.crop_id',
	CropPlanStatus = 'crop_plan.status',
	CropPlanProductCount = 'crop_plan.product_count',
	CropPlanFieldCount = 'crop_plan.field_count',
	CropPlanCount = 'crop_plan.count',
}

export enum EventStructureTags {
	Scope = 'scope',
	TraceId = 'TraceId',
	SpanId = 'SpanId',
	PageName = 'page.name',
	PageContext = 'page.context',
	PageUrl = 'page.url',
	DestinationUrl = 'destination.url',
	DestinationName = 'destination.name',
}

export enum EventErrorTags
{
	ErrorCode = 'error.type',
	ErrorMessage = 'error.message',
}

export enum EventHttpTags
{
	HttpRequestMethod = 'http.request.method',
	HttpEndpoint = 'http.endpoint',
	HttpUrl = 'url.full',
	HttpScheme = 'url.scheme',
	HttpResponseStatusCode = 'http.response.status_code',
	ServerAddress = 'server.address',
	ServerPort = 'server.port'
}

export enum EventSelectionTags {
	GrowerId = 'selection.grower.ghx_fields_id',
	PlanId = 'selection.plan.id',
	Year = 'selection.year',
	CropPlanId = 'selection.crop_plan.id'
}

export enum EventWeatherTags {
	ViewTab = 'selection.weather.tab',
}

export type EventTagNames = (
	EventUserTags 
	| EventStructureTags 
	| EventErrorTags 
	| EventHttpTags
	| EventSelectionTags
	| EventGrowerTags
	| EventPlanTags
	| EventSeedTags
	| EventCropPlanTags
	| EventWeatherTags);

export type EventTags = Partial<Record<EventTagNames, string>>;
