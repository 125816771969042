import { createTracedAsyncThunk } from '../../../tracing/trace';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';

export const requestPasswordReset = createTracedAsyncThunk<boolean, { Email: string }, { dispatch: AppDispatch, state: RootState }>(
	'user/requestpasswordreset',
	// Nothing needs to be passed here so set the request to null
	async (context, request: { Email: string }, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const api = new Api('/api/6', undefined, context);
			const response = await api.postUnauthorizedAsync<boolean>('authentication/passwordrecovery', request);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);