import React, { ReactNode } from 'react';
import { Progress } from 'antd';
import { Variants, StyledLoadingContainer, StyledSpinnerSections } from './LoadingSpinner';
import { useTheme } from 'styled-components';

interface IProgressSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
	variant?: Variants;
	percent: number;
}

export const ProgressSpinner = (props: IProgressSpinnerProps) => 
{
	const { children, percent, variant = 'dark' } = props;

	const theme = useTheme();

	return (
		<StyledLoadingContainer variant={variant}>
			<StyledSpinnerSections>
				{children}
			</StyledSpinnerSections>
			<StyledSpinnerSections>
				<Progress
					style={{ margin: 8 }}
					strokeColor={{ '0%': theme.colors.secondary, '100%': theme.colors.primary }}
					percent={percent}
					size="default"
					status="active" />
			</StyledSpinnerSections>
		</StyledLoadingContainer>
	);
};
