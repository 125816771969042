import React from 'react';
import { Tooltip } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { ColorTabButton } from '../../../components/ColorTabButton/ColorTabButton';
import { IZoneEditingState } from '../../../logic/store/ManagementZones/ManagementZoneSlice';
import { dynamicSort } from '../../../logic/Utility/Utils';

const ZoneManagementContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	padding-bottom: 20px;
	height: 88%;
`;

const TitleText = styled.div`
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: flex-end;
`;

interface IZoneManagementProps
{
	addNewZone: () => void;
	selectedZone: number | undefined;
	zoneEditingData: IZoneEditingState;
	selectZone: (zoneName: number) => void;
	cancelZoneManagement: () => void;
	saveZoneManagement: () => void;
	saveZoneManagementDisabled: boolean;
	addNewZoneSave: () => void;
	addNewZoneCancel: () => void;
	updateNewZoneYield: (value: number) => void;
	newZoneYield: number;
	disableAddNewZoneButton: boolean;
	isOldPlan: boolean;
}

export const ZoneManagement = (props: IZoneManagementProps) =>
{
	const {
		addNewZone,
		addNewZoneSave,
		addNewZoneCancel,
		updateNewZoneYield,
		newZoneYield,
		selectedZone,
		zoneEditingData,
		cancelZoneManagement,
		saveZoneManagement,
		selectZone,
		disableAddNewZoneButton,
		saveZoneManagementDisabled,
		isOldPlan,
	} = props;

	const theme = useTheme();

	return (
		<ZoneManagementContainer>
			<div style={{ display: 'flex', flexDirection: 'column', height: '94%', paddingBottom: 20 }}>
				<TitleText className="TitleText">Soil Zones</TitleText>
				<div style={{ display: 'flex', width: '100%', marginTop: 'auto', flexDirection: 'row', justifyContent: 'flex-end', marginLeft: 5, paddingBottom: 10 }}>
					<Tooltip title={disableAddNewZoneButton ? isOldPlan ? 'Plan\'s year is older than the current year.' : 'Complete new zone before adding another' : ''} placement='top'>
						<span style={{ cursor: disableAddNewZoneButton ? 'not-allowed' : 'pointer' }}>
							<Button
								width={'120px'}
								className='ZoneManagement-addZone-button'
								variant='outlined'
								style={{ marginRight: '15px' }}
								onClick={() => addNewZone()}
								disabled={disableAddNewZoneButton}
							>+ Add Zone</Button>
						</span>
					</Tooltip>
				</div>
				<div style={{ height: '100%', overflowY: 'auto', paddingRight: 10, paddingLeft: 10 }}>
					{
						Object.values(zoneEditingData.zones).sort(dynamicSort('ZoneName')).map(zone =>
							<ColorTabButton
								addCard={zone.AddedZone}
								addCardValue={newZoneYield}
								updateCardValue={updateNewZoneYield}
								onSaveCard={addNewZoneSave}
								onCancelCard={addNewZoneCancel}
								inputName={zone.AddedZone ? zone.CropName : ''}
								key={zone.ZoneName}
								selected={selectedZone === zone.ZoneName}
								selectedOutlineColor={theme.colors.secondary}
								className={`ZoneManagementTabButton_${zone.ZoneName}`}
								title={`Zone ${zone.ZoneName}`}
								tabColor={zone.Color}
								displayNamesAndValues={[[zone.CropName, Math.round(zone.TargetYield).toString() + ' bu'], ['Acres', zone.Acres.toFixed(1) + ' ac' ]]}
								onClick={() => selectZone(zone.ZoneName)}
								variant={zone.AddedZone ? 'reg' : 'med'}
							/>
						)
					}
				</div>
			</div>
			<div style={{ display: 'flex', width: '100%', marginTop: 'auto', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 10 }}>
				<Button
					width={'100px'}
					className='ZoneManagement-cancelZoneManagement-button'
					variant='outlined'
					style={{ marginRight: '15px' }}
					onClick={() => cancelZoneManagement()}
				>Cancel</Button>
				<Tooltip title={saveZoneManagementDisabled ? 'No current changes to save.' : ''} placement='top'>
					<span style={{ cursor: saveZoneManagementDisabled ? 'not-allowed' : 'pointer' }}>
						<Button
							width={'100px'}
							className='ZoneManagement-saveZoneManagement-button'
							variant='dark'
							style={{ marginRight: '15px' }}
							onClick={() => saveZoneManagement()}
							disabled={saveZoneManagementDisabled}
						>Save</Button>
					</span>
				</Tooltip>
			</div>
		</ZoneManagementContainer>
	);
};