import { context, createContextKey, ROOT_CONTEXT, Span, SpanKind, SpanStatusCode } from '@opentelemetry/api';
import { events } from '@opentelemetry/api-events';
import { jwtDecode } from 'jwt-decode';
import { EventErrorTags, EventStructureTags, EventTagNames, EventTags, EventUserTags } from './EventTagNames';
import { setTraceBearerToken, tracer } from './trace';

// Import the background log dispatcher
import { logDispatchWorker } from './LogDispatchWorker';
import { amplitudeInstance, AmpTag } from './Amplitude';
import { useStable } from '../logic/Utility/useStable';
import { useContext, useMemo } from 'react';
import React from 'react';

export interface ISession {
	addTags(tags: EventTags);
	setTag(key: EventTagNames, value: string) ;

	log(level: 'debug'|'info'|'warn'|'error'|'crit', message: string, attributes?: EventTags);
	
	debug(message: string, attributes?: EventTags) ;

	info(message: string, attributes?: EventTags) ;
	warn(message: string, attributes?: EventTags) ;

	error(message: string, attributes?: EventTags);
	
	critical(message: string, attributes?: EventTags) ;
	event(name: string, extraTags?:EventTags) ;

	trace<T>(
		name: string,
		kind: SpanKind,
		action:(tracedSession: ITracedSession)=>Promise<T>);

	scope(name: string, tags?: EventTags) : IScopedSession;
}

export interface IRootSession extends ISession {
	initializeSession(accessToken: string, userId: string, foundationId: string, email: string, name: string);
}

export interface IScopedSession extends ISession
{
	traceId() : string | undefined;

	spanId() : string | undefined;
}

export interface ITracedSession extends IScopedSession
{

	setSuccess();

	setError(code: string, message?: string);
}

class Session implements IScopedSession, IRootSession
{
	private static readonly contextKey = createContextKey('frontend-service');
	private readonly _amplitude = amplitudeInstance;
	private readonly _context = context.active();
	private readonly _eventLogger = events.getEventLogger('event logger');
	private readonly _span: Span | undefined;
	private _tags: EventTags = {};

	public traceId() 
	{
		return this._span?.spanContext()?.traceId;
	}

	public spanId() 
	{
		return this._span?.spanContext()?.spanId;
	}

	private buildTags(extraTags?:EventTags, nameFixer?: (name:string)=>string) 
	{
		const tags = {
			...this._tags, 
			...extraTags,
			TraceId: this._span?.spanContext().traceId, 
			SpanId: this._span?.spanContext().spanId 
		};

		if(!nameFixer)
		{
			return tags;
		}

		return Object.fromEntries(Object.entries(tags).map(([k,v]) => ([nameFixer(k), v])));
	} 

	public constructor(span?:Span)
	{
		this._span = span;
	}

	public initializeSession(accessToken: string, userId: string, foundationId: string, email: string, name: string)
	{
		if(!accessToken)
		{
			return;	
		}

		const decoded = jwtDecode(accessToken);
		const cwId = decoded.sub;
		this._amplitude.setUserId(cwId);

		const identity = new this._amplitude.Identify();
		identity.set(AmpTag(EventUserTags.UserEmail), email);
		identity.set(AmpTag(EventUserTags.UserCropwiseId), cwId);
		identity.set(AmpTag(EventUserTags.UserName), name);
		identity.set(AmpTag(EventUserTags.UserFoundationId), foundationId);
		identity.set(AmpTag(EventUserTags.UserGhxFieldsId), userId);
		this._amplitude.identify(identity);
		document.cookie = `session-user=${userId};max-age=3000000;path=/;SameSite=Lax`;

		// Let our tracer know the access token
		setTraceBearerToken(accessToken);
		// Let our logger know the access token and set labels on the stream to identify it
		logDispatchWorker.setAccessToken(accessToken);
		logDispatchWorker.setLabels({
			[EventUserTags.UserCropwiseId]: cwId,
			[EventUserTags.UserFoundationId]: foundationId,
			[EventUserTags.UserEmail]: email
		});

		// Additionally track as tags the useful information
		// This will be added to traces, logs, and analytics
		this.setTag(EventUserTags.UserGhxFieldsId, userId);
		this.setTag(EventUserTags.UserFoundationId, foundationId);
		this.setTag(EventUserTags.UserEmail, email);
		this.setTag(EventUserTags.UserName, name);
	}

	public setTag(key: EventTagNames, value: string) 
	{
		const prior = this._tags[key];
		this._tags[key] = value;
		// If we are tracking a span, these attributes apply to it
		this._span?.setAttribute(key, value);
		return prior;
	}

	public addTags(tags: EventTags)
	{
		this._tags = {...this._tags, ...tags};
		// If we are tracking a span, these attributes apply to it
		this._span?.setAttributes(tags);
	}

	public log(level: 'debug'|'info'|'warn'|'error'|'crit', message: string, attributes?: EventTags)
	{
		if(typeof message !== 'string')
		{
			message = String(message);
		}
		const tags = this.buildTags(attributes);
		logDispatchWorker.log(new Date(), message, {
			level,
			...tags
		});
	}
	
	public debug(message: string, attributes?: EventTags) 
	{
		console.debug(message, attributes);
		this.log('debug', message, attributes);
	}

	public info(message: string, attributes?: EventTags) 
	{
		console.info(message, attributes);
		this.log('info', message, attributes);
	}

	public warn(message: string, attributes?: EventTags) 
	{
		console.warn(message, attributes);
		this.log('warn', message, attributes);
	}

	public error(message: string, attributes?: EventTags) 
	{
		console.error(message, attributes);
		this.log('error', message, attributes);
	}
	
	public critical(message: string, attributes?: EventTags) 
	{
		console.error(`***${message}***`, attributes);
		this.log('crit', message, attributes);
	}
	
	public event(name: string, extraTags?:EventTags) 
	{
		const amplitudeTags = this.buildTags(extraTags, AmpTag);
		this._amplitude.track(name, amplitudeTags);

		const tracerTags = this.buildTags(extraTags);
		this._eventLogger.emit({
			name,
			attributes: tracerTags
		});
	}

	public scope(name: string, tags?: EventTags, span?: Span) 
	{
		const scope = new Session(span);
		scope.addTags(this._tags);
		if(tags)
		{
			scope.addTags(tags);
		}
		scope.setTag(EventStructureTags.Scope, name);
		return scope;
	}

	public async trace<T>(
		name: string, 
		kind: SpanKind,
		action:(scopedSession: ITracedSession)=>Promise<T>
	)
	{
		if(!tracer)
		{			
			const scope = new Session();
			scope.setTag(EventStructureTags.Scope, name);
			try 
			{
				return await action(scope);
			}
			catch(err)
			{
				scope.error(String(err));
				throw err;
			}
		}

		const subContext = this._context == ROOT_CONTEXT ? this._context.setValue(Session.contextKey, name) : this._context;
		return await context.with(subContext, async () => 
		{
			// Don't "build" tags as it is redundant
			return await tracer.startActiveSpan(name, {
				kind,
				attributes: this._tags,
			}, subContext, async (span) => 
			{
				const scope = this.scope(name, undefined, span);
				try 
				{
					return await action(scope);
				}
				catch(err)
				{
					// But this is definitely an error
					span.setStatus({ code: SpanStatusCode.ERROR, message: String(err) });
					scope.error(String(err));
					throw err;
				}
				finally
				{
					span.end();
				}
			});
		});
	}

	public setSuccess()
	{
		this._span?.setStatus({code: SpanStatusCode.OK});
	}

	public setError(code: string, message?: string)
	{
		this.setTag(EventErrorTags.ErrorCode, code);
		this._span?.setStatus({code: SpanStatusCode.OK, message });
	}
}

export const globalSession : IRootSession = new Session();
const SessionContext = React.createContext(globalSession as ISession);

export const useSession = () => useContext(SessionContext);

export const useScopedSession = (name: string, ...additionalTags: EventTags[]) => 
{
	const mergedTags = additionalTags.reduce((prev, cur) => ({...prev, ...cur}), {});
	const stableTags = useStable(mergedTags);
	const parentSession = useSession();
	const scopedSession = useMemo(() => parentSession.scope(name, stableTags), [name, parentSession, stableTags]);
	return scopedSession;
};