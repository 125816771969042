import React from 'react';
import { Modal } from 'antd';
import { Button } from '../../components/Button/Button';
import { useTheme } from 'styled-components';

interface IModalProps
{
	visible: boolean;
	onCancel: () => void;
	onDelete: () => void;
}

export const ConfirmDeleteModal = (props: IModalProps) =>
{
	const { visible, onCancel, onDelete } = props;

	const theme = useTheme();

	const CancelAndDeleteFooter = (
		<div>
			<Button
				variant='light'
				onClick={() => onCancel()}
				className='cancelButton'
			>
				Cancel
			</Button>
			<Button
				variant='light'
				style={{ marginLeft: 24, color: theme.colors.redLM }}
				onClick={() => onDelete()}
				className='deleteButton'
			>
				Delete
			</Button>
		</div>
	);

	return (
		<Modal
			title='Confirm Field Deletion'
			closable={false}
			footer={CancelAndDeleteFooter}
			open={visible}
			className='confirmDeleteModal'
			style={{
				position: 'absolute',
				top: '40%',
				left: '40%',
			}}
		>
			Would you like to delete all selected fields?
		</Modal>
	);
};