import React, { useState, useEffect } from 'react';
import { ISeriesWithHybrid } from '../../../../logic/store/Seeds/SeedsActions';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as GreenAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/images/AvailabilityIcons/Info.svg';
import { Modal } from 'antd';
import { SeriesWithHybridRow } from './SeriesWithHybridRow';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { ICropPlanSelectedProduct } from '../../../../logic/Models/Responses/FieldPlanResponse';

export interface ISeedFilter
{
	minRM: number;
	maxRM: number;
}

const ProductListContainer = styled.div`
	display: flex;
	flex-direction: column;
	transition: min-width 0.3s linear, visibility 0s 0.3s linear;
	z-index: 99;
	flex: 1 1 auto;
	overflow: hidden;
`;

const ProductListContent = styled.div`
	display: flex;
	flex-direction: column;
	transition: visibility 0s 0.3s linear;
	flex: 1 1 auto;
	overflow: hidden;
`;

const ProductListHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 5px 20px 5px 20px;
	padding: 12px 0px;
	font-family: ${themeGet('fonts.heading')};
	font-size: ${themeGet('fontSizes.small')};
	font-weight: ${themeGet('fontWeights.bold')};
	border-top: 1px solid ${themeGet('colors.lightestGrey')};
	
	.infoIcon {
		margin-left: 4px;
		
		path, circle.fill {
			fill: ${themeGet('colors.darkGrey')};
			stroke: ${themeGet('colors.darkGrey')};
		}
	}

	circle {
		stroke: ${themeGet('colors.darkGrey')};
	}
`;

const ProductListSeries = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	margin: 0px 10px 0px 20px;
	padding-right: 20px;
`;

interface IPlanProductListProps
{
	selectAllSeries: (selectAll: boolean, seriesIds: string[], hybrids: ICropPlanSelectedProduct[]) => void;
	selectSingleSeries: (selected: boolean, seriesId: string, hybridId: string, treatment?: string) => void;
	cropName: string;
	seriesList: ISeriesWithHybrid[];
	selectedSeriesIds: string[];
	selectedHybrids: ICropPlanSelectedProduct[];
}

const AvailabilityLegend = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	padding-right: 10px;
`;

const StyledSelectAllCheckbox = styled(Checkbox)`
	padding: 12px;
	background-color: ${themeGet('colors.white')};
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.small')};
	font-family: ${themeGet('fonts.base')};
	z-index: 998;
`;

/**
 * The list of selected products from within the field plan editing section
 */
function SeedList(props: IPlanProductListProps & PropsFromRedux)
{
	const {
		seriesList,
		selectedSeriesIds,
		selectedHybrids,
		selectAllSeries,
		selectSingleSeries,
		IsLoadingSeeds,
	} = props;

	const theme = useTheme();

	const [showAvailability, setShowAvailability] = useState<boolean>(false);
	const [allSeriesChecked, setAllSeriesChecked] = useState(false);
	const [allSeriesIndeterminate, setAllSeriesIndeterminate] = useState(false);

	useEffect(() =>
	{
		if (seriesList.length !== 0)
		{
			setAllSeriesChecked(selectedSeriesIds.length === seriesList.length);
		}
		
		setAllSeriesIndeterminate(selectedSeriesIds.length !== 0 && selectedSeriesIds.length < seriesList.length);
	},[selectedSeriesIds, seriesList]);

	const selectHybridForSeries = (seriesId: string, hybridId: string) =>
	{
		selectSingleSeries(selectedSeriesIds.includes(seriesId), seriesId, hybridId, '');
	};

	// Selects all visible/filtered series options
	const selectSeriesAll = () =>
	{
		if (!allSeriesChecked)
		{
			// TODO: Fix all of this to use properly selected hybrids instead of the top most hybrid option
			// and a proper option for treatment
			if (seriesList && seriesList.length > 0)
			{
				const filteredSeriesIds: string[] = seriesList.map(s => s.seriesId);
				const filteredHybrids: ICropPlanSelectedProduct[] = seriesList.map(s =>
				{
					const topMostHybrid = s.hybrids[0];
					return ({ HybridId: topMostHybrid.Id, Treatment: '' });
				});
				
				selectAllSeries(true, filteredSeriesIds, filteredHybrids);
				setAllSeriesChecked(true);
			}
		}
		else
		{
			// De-select them all
			selectAllSeries(false, [], []);
		}
	};

	return (
		<ProductListContainer className='ProductList'>
			<Modal
				width={225}
				title='Status Legend'
				open={showAvailability}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				onOk={() => setShowAvailability(false)}
				onCancel={() => setShowAvailability(false)}
			>
				<AvailabilityLegend className='AvailabilityLegend'>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<GreenAvailabilityIcon style={{ fill: 'rgb(160, 160, 160)', position: 'relative', top: -1, marginRight: 2 }} />
							/
							<GreenAvailabilityIcon style={{ fill: theme.colors.availabilityGreen, marginLeft: 2, marginRight: 5, position: 'relative', top: -1 }} />
							Available
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<YellowAvailabilityIcon style={{ fill: 'rgb(160, 160, 160)', marginRight: 2 }} />
							/
							<YellowAvailabilityIcon style={{ fill: theme.colors.availabilityYellow, marginLeft: 2, marginRight: 5 }} />
							Limited Availability
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<RedAvailabilityIcon style={{ fill: 'rgb(160, 160, 160)', position: 'relative', top: -1, marginRight: 2 }} />
							/
							<RedAvailabilityIcon style={{ fill: theme.colors.availabilityRed, marginLeft: 2, marginRight: 5, position: 'relative', top: -1 }} />
							Unavailable
						</div>
					</div>
				</AvailabilityLegend>
			</Modal>
			<ProductListContent className='ProductListContent'>
				<ProductListHeader className='ProductListHeader'>
					<div>Seed List</div>
					<InfoIcon className='infoIcon' onClick={() => setShowAvailability(true)} />
					<div style={{ marginLeft: 'auto', width: '36%' }}>
						<StyledSelectAllCheckbox
							className='FieldPlan-SeedList-SelectAll'
							indeterminate={allSeriesIndeterminate}
							checked={allSeriesChecked}
							onChange={() => selectSeriesAll()}
						>
							Select All
						</StyledSelectAllCheckbox>
					</div>
				</ProductListHeader>
				{
					IsLoadingSeeds ?
						<div style={{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
							<ScaleLoader color={theme.colors.primary} loading={IsLoadingSeeds} /> 
						</div>
						: !seriesList.length && !IsLoadingSeeds ?
							<div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								There are no seeds available.
							</div>
							:
							!seriesList.length ?
								<div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
									There are no seeds available within the chosen filter.
								</div>
								:
								<ProductListSeries className='ProductListSeriesContainer'>
									<div className='ProductListSeriesInner'>
										{seriesList.map((series) => (
											<SeriesWithHybridRow
												key={series.seriesId}
												selected={selectedSeriesIds.includes(series.seriesId)}
												selectHybridForSeries={selectHybridForSeries}
												series={series}
												selectedHybrids={selectedHybrids}
												selectSingleSeries={selectSingleSeries}
											/>
										))}
									</div>
								</ProductListSeries>
				}
			</ProductListContent>
		</ProductListContainer>
	);
}

const mapStateToProps = (state: RootState) => ({
	CustomTreatments: state.customTreatments.treatments,
	IsLoadingSeeds: state.seeds.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
	
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const ConnectedSeedList = connector(SeedList);
