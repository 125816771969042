import React, { ReactNode } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';

const ClearButton = styled.button`
	background-color: transparent !important;
	border: none;
	outline-style: none;
	:focus, :hover {
		background-color: transparent !important;
	}
`;

const TabBar = styled.div<IDropdownTabProps>`
	background-color: ${props => props.selected ? themeGet('colors.darkGrey') : themeGet('colors.lightestGrey')};
	height: 12px;
	border-radius: 4px 4px 0px 0px;
`;

interface IDropdownTabProps 
{
	label: string;
	disabled?: boolean;
	selected?: boolean;
	onClick?: () => void;
	containerStyle?: React.CSSProperties;
	children?: ReactNode;
	buttonClassName?: string;
}

/**
 * @component Selectable tab with an embedded dropdown
 */
export const DropdownTab = (props: IDropdownTabProps) =>
{
	const { children, ...rest } = props;
	const { buttonClassName, label, disabled, selected = false, onClick, containerStyle } = rest;

	const theme = useTheme();

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '27vw', alignSelf: 'flex-end', ...containerStyle }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '16px', fontWeight: theme.fontWeights.bold }}>
				<ClearButton
					className={buttonClassName}
					onClick={disabled ? null : onClick}
					style={{
						flexGrow: 1,
						color: selected ? theme.colors.darkGrey : theme.colors.mediumGrey,
						fontSize: selected ? theme.fontSizes.large : theme.fontSizes.small,
						textAlign: 'left',
						fontWeight: theme.fontWeights.bold,
						cursor: 'pointer'
					}}
				>
					{label}
				</ClearButton>
				{children}
			</div>
			<TabBar disabled={disabled} {...rest} />
		</div>
	);
};