import React, { useEffect, useCallback } from 'react';
import { FieldListActionDrawerButton } from './FieldListActionDrawerButton';
import styled, { useTheme } from 'styled-components';
import { IFieldResponse } from '../../logic/Models/Responses/FieldResponse';
import { IGrowerResponse } from '../../logic/Models/Responses/GrowerResponse';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { clearGamePlanLaunchUrl } from '../../logic/store/Grower/GrowerSlice';
import { makeDispatch } from '../../logic/Utility/Utils';
import { authSelector, getCurrentActingUser } from '../../logic/store/User/AuthSlice';
import { IFrameModal } from '../../components/IFrameModal/IFrameModal';
import { displayEnogenFlow, hideEnogenFlow, currentEnogenFlowUrl,
	displayAgcriclimeFlow, hideAgriclimeFlow, currentAgriclimeFlowUrl } from '../../logic/store/UI/UISlice';
import { useHistory } from 'react-router-dom';
import { UserPermission } from '../../logic/Models/Responses/UserPermissions';
import { hasEffectivePermission } from '../../logic/store/User/AuthSlice';
import { downloadCsiPdf } from '../../logic/store/CSI/CsiThunks';
import { useAmplitudePassthrough } from '../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../tracing/session';
import { EventStructureTags } from '../../tracing/EventTagNames';
import { EventRecallNames } from '../../tracing/EventNames';

const FieldListActionDrawerContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

interface IFieldListActionDrawerProps
{
	selectedGrower: IGrowerResponse;
	selectedFields: IFieldResponse[];
	visible: boolean;
	display: boolean;
}

const BaseFieldListActionDrawer = (props: IFieldListActionDrawerProps & PropsFromRedux) =>
{
	const {
		display,
		selectedFields,
		selectedGrower,
		visible,
		AgriclimeBaseUrl,
		AgriclimeFlowUrl,
		Authentication,		
		EnogenFlowUrl,
		EnogenRootUrl,
		IsLoadingPlanLaunch,
		SelectedFieldIds,
		UserCanConfirmFields,
		UserId,
		ClearLaunchUrl,
		DisplayAgriclimeFlow,
		DisplayEnogenFlow,
		DownloadCsiPdf,
		HideAgriclimeFlow,
		HideEnogenFlow,
	} = props;

	const theme = useTheme();

	const history = useHistory();

	const session = useScopedSession(FieldListActionDrawer.name, {}, {
		[EventStructureTags.PageContext]: 'fieldlistactivities',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	useEffect(() =>
	{
		if (!IsLoadingPlanLaunch && selectedGrower?.GamePlanLaunchUrl)
		{
			// Clean the url so that on a return it will not continually launch the url over and over
			ClearLaunchUrl(selectedGrower.Id);

			// And redirect to the launch URL
			window.location.href = selectedGrower.GamePlanLaunchUrl;
		}
	}, [selectedGrower?.GamePlanLaunchUrl]);

	const launchFieldGamePlan = useCallback(() =>
	{
		history.push('/fieldactivities/fieldplan');
	}, [history]);

	const launchConfirmationWorkflow = useCallback(() =>
	{
		history.push('/fieldactivities/fieldconfirmation');
	},[history]);

	const displayEnogenFlowOnClick = useCallback(() =>
	{
		DisplayEnogenFlow({
			accessToken: Authentication,
			apiBaseUrl: document.location.host,
			enogenFlowBaseUrl: EnogenRootUrl,
			selectedGrowerId: selectedGrower.Id,
			userId: UserId
		});
	}, [Authentication, document.location.host, selectedGrower, UserId]);

	const displayAgriclimeFlowOnClick = useCallback(() =>
	{
		DisplayAgriclimeFlow({
			baseUrl: AgriclimeBaseUrl,
			growerId: selectedGrower.Id,
			userId: UserId,
			accessToken: Authentication
		});
	}, [selectedGrower, UserId, Authentication]);

	const downloadCropStressIndexPdf = useAmplitudePassthrough(session, EventRecallNames.DownloadCSIPdf, () =>
	{
		DownloadCsiPdf({ FieldIds: SelectedFieldIds });
	},[SelectedFieldIds, DownloadCsiPdf]);

	return (
		<FieldListActionDrawerContainer
			className='fieldListActionDrawer'
			style={{
				width: visible ? 175 : 0,
				padding: visible ? '57px 20px' : 0,
				visibility: visible ? 'visible' : 'hidden',
				display: display ? 'flex' : 'none',
				borderLeft: visible ? `1px solid ${theme.colors.lightestGrey}` : 'none'
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', fontSize: 12, marginBottom: 35 }}>
				<div style={{ fontWeight: 700 }}>
					{`${selectedFields.length} field${selectedFields.length > 1 ? 's' : ''} selected`}
				</div>
				<div>
					{`${selectedFields.map(field => field.Area).reduce((a1, a2) => (a1 ?? 0) + (a2 ?? 0), 0).toFixed(1)} acres`}
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<FieldListActionDrawerButton
					variant='gameplan'
					onClick={launchFieldGamePlan}
					className='runGameplanActionButton'
				/>
				{/* <FieldListActionDrawerButton
					variant='agriclime'
					onClick={displayAgriclimeFlowOnClick}
					className='agriclimeActionButton'
				/> */}
				<FieldListActionDrawerButton
					variant='enogen'
					onClick={displayEnogenFlowOnClick}
					className='enogenContractActionButton'
				/>
				{
					UserCanConfirmFields &&
					<FieldListActionDrawerButton
						variant='confirmation'
						onClick={launchConfirmationWorkflow}
						className='launchFieldConfirmationActionButton'
					/>
				}
				<FieldListActionDrawerButton
					variant='cropstressindex'
					onClick={downloadCropStressIndexPdf}
					className='cropstressindexActionButton'
				/>
			</div>
			<IFrameModal title="Enogen Contracting" width={1200} open={!!EnogenFlowUrl}
				onCancel={() => HideEnogenFlow()}
				footer={null}>
				<iframe src={EnogenFlowUrl} />
			</IFrameModal>
			<IFrameModal title="AgriClime" width={1200} open={!!AgriclimeFlowUrl}
				onCancel={() => HideAgriclimeFlow()}
				footer={null}>
				<iframe src={AgriclimeFlowUrl} />
			</IFrameModal>
		</FieldListActionDrawerContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	AgriclimeBaseUrl: state.config.AgriclimeUrl,
	AgriclimeFlowUrl: currentAgriclimeFlowUrl(state),
	AllGrowers: state.grower.Growers,
	Authentication: authSelector(state)?.userAuthToken,
	EnogenFlowUrl: currentEnogenFlowUrl(state),
	EnogenRootUrl: state.config.MapCentricUrl,
	ErrorMessage: state.grower.errorMessage,
	IsError: state.grower.isError,
	IsLoadingPlanLaunch: state.grower.isLoadingPlanLaunch,
	Recording: state.recording,
	SelectedFieldIds: state.ui.SelectedFields,
	UserCanConfirmFields: hasEffectivePermission(state, UserPermission.CanConfirmfields, true),
	UserId: getCurrentActingUser(state).UserId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearLaunchUrl: (growerId: string) => dispatch(clearGamePlanLaunchUrl(growerId)),
		DisplayAgriclimeFlow: makeDispatch(dispatch, displayAgcriclimeFlow),
		DisplayEnogenFlow: makeDispatch(dispatch, displayEnogenFlow),
		DownloadCsiPdf: makeDispatch(dispatch, downloadCsiPdf),
		HideAgriclimeFlow: makeDispatch(dispatch, hideAgriclimeFlow),
		HideEnogenFlow: makeDispatch(dispatch, hideEnogenFlow),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FieldListActionDrawer = connector(BaseFieldListActionDrawer);