import { roundOff } from './Utils';

//Convert Celcius to Farenheit with .5 degree rounding
export const convertCelciusToFahrenheit = (celcius: number) =>
{
	const farenheit = celcius * (9/5) + 32;

	return roundOff(farenheit, 1);
};

export const convertMillimetersToInches = (millimeters: number) =>
{
	const inches = millimeters / 25.4 ;

	return roundOff(inches, 3);
};

export const convertKilometersToMiles = (kilometers: number) =>
{
	const miles = kilometers / 1.609;
	return roundOff(kilometers, 1);
};

export const getMeanFarenheitTemperatureFromCelsius = (celcius1: number, celcius2: number) =>
{
	const meanFarenheit = convertCelciusToFahrenheit((celcius1 + celcius2)/2);
	return roundOff(meanFarenheit, 1);
};