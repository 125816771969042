import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IGrowerListRequest } from '../../Models/Requests/GrowerRequest';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { getCurrentActingUser } from '../User/AuthSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const getGrowers = createTracedAsyncThunk<IGrowerResponse[], IGrowerListRequest, { dispatch: AppDispatch, state: RootState }>(
	'grower/get',
	async (context, request: IGrowerListRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const queryParamList : string[] = [];
			
			if (request.GrowerYears?.length)
			{
				queryParamList.push(`jsonGrowerYears=[${request.GrowerYears.toString()}]`);
			}
			// Keeping this to track the original requests
			if (request.UpdateFromCropEdge)
			{
				queryParamList.push(`updateFromCropEdge=${request.UpdateFromCropEdge}`);
			}
			if (request.ForceSlowUpdateFromCropEdge)
			{
				queryParamList.push('forceSlowUpdateFromCropEdge=true');
			}
			
			const queryParams = queryParamList.length > 0 ? '?' + queryParamList.join('&') : '';
			const response = await api.getAsync<IGrowerResponse[]>(`users/${request.UserId}/growers${queryParams}`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IGrowerUpdateRequest
{
	GrowerId: string;
	RequestBody: IGrowerUpdateRequestBody;
}

export interface IGrowerUpdateRequestBody
{
	LanguagePreference: string;
}

export const updateGrowerInfo = createTracedAsyncThunk<IGrowerResponse, IGrowerUpdateRequest, { dispatch: AppDispatch, state: RootState }>(
	'grower/update',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.postAsync<IGrowerResponse>(
				`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/growers/${request.GrowerId}`, request.RequestBody);
	
			if (response.ErrorCode === null && response.Success)
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);
