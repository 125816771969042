import styled from 'styled-components';

/**
 * Styled component for the the list header
 */
export const ListHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	padding: 2px;
	background-color: black;
	color: white;
	overflow: auto;
	scrollbar-gutter: stable both-edges;
	height: 28px;
	flex-grow: 0;
	flex-shrink: 0;

	> div:nth-child(1) {
	 	flex-basis: 0;
		flex-grow: 1;
	}

	> div:nth-child(2) {
		flex-basis: 60px;
		flex-grow: 0;
	}
`;
