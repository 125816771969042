import _, { head } from 'lodash';
import { pathJoin } from '../Utility/Utils';
import { IApi } from './IApi';
import { ApiResponse } from './ApiResponse';
import { NetError } from './NetError';
import { SpanKind } from '@opentelemetry/api';
import { globalSession, IScopedSession } from '../../tracing/session';
import { EventHttpTags, EventTagNames } from '../../tracing/EventTagNames';
import { persistor } from '../store/Store';

let masqueradeAs : string | undefined;

export const setMasquerade = (target: string) => 
{
	masqueradeAs = target;
};

export class Api implements IApi
{
	private static readonly _host: string = process.env.REACT_APP_API_HOST;
	private readonly _baseUrl: string;

	private readonly _token: string;
	private readonly _context: IScopedSession | undefined;

	public constructor(baseUrl: string, token: string, initialContext: IScopedSession)
	{
		// Prep the base url so we can always just concat it with the endpoint later
		this._baseUrl = _.trim(baseUrl.trim(), '/') + '/';
		this._token = token ? token : undefined;
		this._context = initialContext;
	}

	// Convenience methods for particular requests
	public async postAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'POST',
		}, this.authenticatedHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}
	
	public async patchAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'PATCH',
		}, this.authenticatedHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	public async getAsync<T>(endpoint: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			method: 'GET',
		}, this.authenticatedHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	public async deleteAsync<T>(endpoint: any, data?: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: data ? JSON.stringify(data) : '',
			method: 'DELETE',
		}, this.authenticatedHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	public async putAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'PUT',
		}, this.authenticatedHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	/**
	 * Sends a POST with FormData
	 * @param formData FormData object to pass along with the data 
	 * @param endpoint provided full url or partial url to send the data
	 * @param useOnlyEndpoint if true, will override using the window location and base url appended to the endpoint and will only
	 * use the provided endpoint to send the data
	 * @param noJsonData if true, will override attempting to return json.data and will return only the response
	 * @param authenticated if true, will pass the properly authenticated headers
	 * @returns 
	 */
	public async uploadFormDataAsync(
		formData: FormData,
		endpoint: string,
		useOnlyEndpoint: boolean = false,
		noJsonData: boolean = false,
		signal: AbortSignal = undefined,
		authenticated: boolean = false
	): Promise<any>
	{
		const data: RequestInit = {
			method: 'POST',
			body: formData,
			signal: signal
		};

		if(authenticated)
		{
			data.headers = this.authenticatedHeadersAndCredsWithMultiPartFormData().headers; 
		}

		const url = pathJoin(process.env.REACT_APP_API_HOST as string, this._baseUrl, endpoint);
		let response: Response;
		if (useOnlyEndpoint)
		{
			response = await fetch(endpoint, data);
		}
		else
		{
			response = await fetch(url, data);
		}

		this.checkForHttpSuccess(response);
		
		if (noJsonData)
		{
			return response;
		}

		const json = await response.json() as ApiResponse<any>;
		return json.Data;
	}

	/**
	 * Download a binary file from the API endpoint, and attempt to parse the filename.
	 */
	public async getFileAsync(endpoint: string, config?: RequestInit)
	{
		const merged = Object.assign({
			method: 'GET',
		}, this.authenticatedHeadersAndCreds(), config);

		return await this.downloadFile(endpoint, merged);
	}

	/**
	 * This addition is to handle endpoints that stream back a file of some kind.  It will submit as a POST with data
	 */
	public async postGetFileAsync(endpoint: string, data?: any, config?: RequestInit): Promise<{ name: string; data: Blob }>
	{
		const merged = Object.assign({
			body: data ? JSON.stringify(data) : undefined,
			method: 'POST',
		}, this.authenticatedHeadersAndCreds(), config);

		return await this.downloadFile(endpoint, merged);
	}

	/**
	 * Internal operation of getting a filestream from a remote endpoint.
	 */
	private async downloadFile(endpoint: string, merged: Partial<RequestInit>)
	{
		let response: Response = new Response();
		try
		{
			response = await this.sendAsync(endpoint, merged);
		}
		catch (e)
		{
			this._context.error(e);
			throw e;
		}

		this.checkForHttpSuccess(response);

		// If a file name was included, use it
		// This block is for PDF filenames which are structured a bit differently than VRS filenames
		const disposition = response.headers.get('content-disposition');
		let matches = /"([^"]*)"/.exec(disposition);
		let filename = (matches != null && matches[1] ? matches[1] : null);


		if(!filename)
		{
			// Test for a VRS filename structure
			const test: RegExp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			matches = test.exec(disposition);
			filename = (matches != null && matches[1] ? matches[1] : null);
		}

		// If we still can't find a filename, return a null response
		if(!filename)
		{
			return null;
		}

		return {
			name: filename,
			data: await response.blob()
		};
	}

	public async getUnauthorizedAsync<T>(endpoint: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			method: 'GET',
		}, this.standardHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	public async postUnauthorizedAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'POST',
		}, this.standardHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	// This addition is to handle endpoints that stream back a file of some kind.
	public async postUnauthorizedGetFileAsync(endpoint: string, data: any, config?: RequestInit)
		: Promise<{ name: string; data: Blob }>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'POST',
		}, this.standardHeadersAndCreds(), config);

		let response: Response = new Response();
		try
		{
			response = await this.sendAsync(endpoint, merged);
		}
		catch (e)
		{
			this._context.error(e);
			throw e;
		}

		this.checkForHttpSuccess(response);

		// If a file name was included, use it
		const disposition = response.headers.get('content-disposition');
		const matches = /"([^"]*)"/.exec(disposition);
		const filename = (matches != null && matches[1] ? matches[1] : null);

		return {
			name: filename,
			data: await response.blob()
		};
	}

	public async putUnauthorizedAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'PUT',
		}, this.standardHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	public async deleteUnauthorizedAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<ApiResponse<T>>
	{
		const merged = Object.assign({
			body: JSON.stringify(data),
			method: 'DELETE',
		}, this.standardHeadersAndCreds(), config);

		return this.fetchApiData<T>(endpoint, merged);
	}

	// Return the authenticated headers for an http call
	private authenticatedHeadersAndCreds(): Partial<RequestInit>
	{
		const headers =  new Headers({
			'Authorization': `Bearer ${this._token}`,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		});

		if(masqueradeAs)
		{
			headers.append('x-masquerade-as', masqueradeAs);
		}

		return {
			headers
		};
	}

	private authenticatedHeadersAndCredsWithMultiPartFormData(): Partial<RequestInit>
	{
		return {
			headers: new Headers({
				'Authorization': `Bearer ${this._token}`,
				'Accept': 'application/json',
			}),
		};
	}

	private standardHeadersAndCreds(): Partial<RequestInit>
	{
		return {
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
		};
	}

	public async fetchApiData<T>(endpoint: string, config?: RequestInit): Promise<ApiResponse<T>>
	{
		let response: Response = new Response();
		try
		{
			response = await this.sendAsync(endpoint, config);
		}
		catch (e)
		{
			this._context.error(e);
		}

		this.checkForHttpSuccess(response);

		const json = await this.deserializeAsync<T>(response);

		return json;
	}

	// No processing, just send the request.
	public async sendAsync(endpoint: string, config?: RequestInit): Promise<Response>
	{
		const merged: RequestInit = {};
		_.merge(merged, config);
		const path = pathJoin(Api._host, this._baseUrl, endpoint);
		const uri = new URL(path);
		const context = this._context ?? globalSession;

		return await context.trace(config.method ?? 'GET', SpanKind.CLIENT, async (scopedSession) => 
		{
			scopedSession.addTags({
				[EventHttpTags.HttpRequestMethod]: merged.method ?? 'GET',
				[EventHttpTags.HttpEndpoint]: endpoint,
				[EventHttpTags.HttpUrl]: path,
				[EventHttpTags.HttpScheme]: uri.protocol,
				[EventHttpTags.ServerAddress]: uri.host,
				[EventHttpTags.ServerPort]: uri.port,
			});

			if(scopedSession.traceId())
			{
				// We are tracing, so set this span and construct a header that will let the backend associate
				// its traces as well.
				(merged.headers as Headers)
					.append('traceparent', `00-${scopedSession.traceId()}-${scopedSession.spanId()}-01`);
			}
				
			const result = await fetch(path, merged);
			scopedSession.setTag(EventHttpTags.HttpResponseStatusCode, result.status.toString());

			if(result.status >= 200 && result.status < 300)
			{
				// Definitely OK (from a transport perspective)!
				scopedSession.setSuccess();
			}
			else if(result.status >= 400)
			{
				// Definitely an error!
				scopedSession.setError(result.statusText, result.statusText);
				scopedSession.error('Http status error from endpoint.');
			}
			return result;
		});	
	}

	private checkForHttpSuccess(response: Response): Response
	{
		// Accept Server Errors of 500/503 so that we can read the actual error message
		if (response.status !== 200 && response.status !== 204 && response.status !== 500 && response.status !== 503)
		{
			throw new NetError(`There was a network error with code: ${response.status}`, response.status);
		}
		return response;
	}

	private async deserializeAsync<T>(response: Response): Promise<ApiResponse<T>>
	{
		if (response.status === 204)
		{
			return undefined as ApiResponse<T>;
		}

		return await response.json() as ApiResponse<T>;
	}
}