import { isEqual } from 'lodash';
import { useRef } from 'react';

/**
 * A hook to take a complex value and 'stabalize it'.
 * This is not the most performant method but is a better version than comparing JSON.stringify.
 * Will not work on function references.
 * 
 * @returns A stable reference (the same reference if the context is the same, or a new reference if any context is different)
 */
export const useStable = <T>(data: T) => 
{
	const prior = useRef(data);
	if (prior.current === data || isEqual(prior.current, data)) 
	{
		return prior.current;
	}
	prior.current = data;
	return data;
};
