import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components/Button/Button';
import { StyledModal } from '../../../../components/StyledModal/StyledModal';
import { StyledSwitch } from '../../../../components/StyledSwitch/StyledSwitch';
import { RangeInputSlider } from '../../../../components/RangeInputSlider/RangeInputSlider';
import { DescriptiveSlider } from '../../../../components/DescriptiveSlider/DescriptiveSlider';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { IFieldPlanAgronomicsSettings } from '../FieldPlan';
import styled, { useTheme } from 'styled-components';
import { InputNumber } from 'antd';
import { themeGet } from '@styled-system/theme-get';
import _ from 'lodash';
import { ITrait } from '../../ProductGamePlan/Editor/PlanProductEditor';

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: 14px;
	margin-right: -2px;
	border: thin solid #E7E7E7;
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: white;
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;

const StyledSelectAllCheckbox = styled(Checkbox)`
	width: 333px;
	padding: 12px;
	background-color: ${themeGet('colors.white')};
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.small')};
	z-index: 998;
`;

interface IAgronomicsModalProps
{
	brandApplication: string;
	cropName: string;
	currentSettings: IFieldPlanAgronomicsSettings;
	defaultMax: number;
	defaultMin: number;
	isOpen: boolean;
	uniqueTraits: ITrait[];
	clearFilter: () => void;
	closeModal: () => void;
	updateConsistency: (value: number) => void;
	updateFungicide: () => void;
	updateIrrigation: () => void;
	updateMaxRm: (value: number) => void;
	updateMaxAndMinRm: (value: [number,number]) => void;
	updateMinRm: (value: number) => void;
	updateSelectedTraits: (value: string[]) => void;
	updateTopEndYield: (value: number) => void;
}

export function FieldPlanAgronomicsModal(props: IAgronomicsModalProps)
{
	const {
		brandApplication,
		cropName,
		currentSettings,
		defaultMax,
		defaultMin,
		isOpen,
		uniqueTraits,
		clearFilter,
		closeModal,
		updateConsistency,
		updateFungicide,
		updateIrrigation,
		updateMaxRm,
		updateMaxAndMinRm,
		updateMinRm,
		updateSelectedTraits,
		updateTopEndYield,
	} = props;

	const theme = useTheme();

	const [allTraitsChecked, setAllTraitsChecked] = useState(false);
	const [allTraitsIndeterminate, setAllTraitsIndeterminate] = useState(false);
	const [currentTraits, setCurrentTraits] = useState<ITrait[]>([]);

	useEffect(() =>
	{
		// Initialize the selected traits on opening the modal
		if (uniqueTraits && uniqueTraits.length > 0)
		{
			const incomingUniqueTraits = _.cloneDeep(uniqueTraits);
			const currentSelectedTraits = currentSettings.selectedTraits;
			incomingUniqueTraits.forEach(t =>
			{
				if (currentSelectedTraits && currentSelectedTraits.length > 0)
				{
					if (currentSelectedTraits.includes(t.TraitId))
					{
						t.Selected = true;
					}
					else
					{
						t.Selected = false;
					}
				}
			});
			
			setCurrentTraits([...incomingUniqueTraits]);
			setAllTraitsChecked(incomingUniqueTraits.every(ut => ut.Selected));
			setAllTraitsIndeterminate(
				incomingUniqueTraits.filter(ut => ut.Selected).length !== 0 && incomingUniqueTraits.filter(ut => ut.Selected).length < incomingUniqueTraits.length);
		}
	}, [uniqueTraits, cropName, currentSettings]);

	const selectAllTraits = () =>
	{
		const currentUniqueTraits = currentTraits;
		const selected = currentUniqueTraits[0].Selected;
		currentUniqueTraits.forEach(ut => 
		{
			ut.Selected = !selected;
		});

		// Add to the selected trait list
		const selectedTraitIds: string[] = [];
		currentUniqueTraits.forEach(ct =>
		{
			if (ct.Selected)
			{
				selectedTraitIds.push(ct.TraitId);
			}
		});		
		updateSelectedTraits(selectedTraitIds);

		setCurrentTraits([...currentUniqueTraits]);
		setAllTraitsChecked(currentUniqueTraits.every(ut => ut.Selected));
		setAllTraitsIndeterminate(
			currentUniqueTraits.filter(ut => ut.Selected).length !== 0 && currentUniqueTraits.filter(ut => ut.Selected).length < currentUniqueTraits.length);
	};

	const selectSpecificTrait = (traitId: string) =>
	{
		const currentUniqueTraits = currentTraits;
		const foundIndex = currentUniqueTraits.findIndex(ut => ut.TraitId === traitId);
		currentUniqueTraits[foundIndex].Selected = !currentUniqueTraits[foundIndex].Selected;
		
		// Add to the selected trait list
		const selectedTraitIds: string[] = [];
		currentUniqueTraits.forEach(ct =>
		{
			if (ct.Selected)
			{
				selectedTraitIds.push(ct.TraitId);
			}
		});		
		updateSelectedTraits(selectedTraitIds);

		setCurrentTraits([...currentUniqueTraits]);
		setAllTraitsChecked(currentUniqueTraits.every(ut => ut.Selected));
		setAllTraitsIndeterminate(
			currentUniqueTraits.filter(ut => ut.Selected).length !== 0 && currentUniqueTraits.filter(ut => ut.Selected).length < currentUniqueTraits.length);
	};

	return (
		<StyledModal
			open={isOpen}
			destroyOnClose={true}
			onCancel={() => closeModal()}
			closable={true}
			title={<div style={{ fontSize: theme.fontSizes.xLarge, fontWeight: theme.fontWeights.bold, paddingTop: 10 }}>{`Agronomics Input - ${cropName}`}</div>}
			bodyStyle={{ paddingTop: 5 }}
			width={ 720 }
		>
			<div>
				<span>Select the <b>agronomic inputs</b> for your corn products. We will <b>filter our product list</b> based on your preferences. If you wish to see the <b>complete list</b>, select 'Clear Filters'.</span>
				<div style={{ marginTop: 25 }}>
					<div
						style={{
							fontSize: theme.fontSizes.normal,
							fontWeight: theme.fontWeights.bold,
							marginBottom: 20
						}}
					>Relative Maturity</div>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 27 }}>
						<div>
							<NumberInput
								className={'AgModal-RelativeMaturity-MinValue'}
								type='number'
								style={{ height: 20, width: 70 }}
								value={currentSettings.rmMin}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) => 
									updateMinRm(evt.currentTarget.value ? Number(evt.currentTarget.value) : currentSettings.rmMin)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => 
									updateMinRm(evt.target && (evt.target as HTMLInputElement).value
										? Number((evt.target as HTMLInputElement).value)
										: currentSettings.rmMin)}
							/>
						</div>
						<div style={{ width: '57%'}} className={'AgModal-'}>
							<RangeInputSlider
								min={defaultMin}
								max={defaultMax}
								stepValue={cropName && cropName.toLowerCase().includes('soy') ? 0.1 : 1}
								sliderValue={[currentSettings.rmMin, currentSettings.rmMax]}
								onChange={(value) => updateMaxAndMinRm(value)}
							/>
						</div>
						<div>
							<NumberInput
								className={'AgModal-RelativeMaturity-MaxValue'}
								type='number'
								style={{ height: 20, width: 70 }}
								value={currentSettings.rmMax}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) => 
									updateMaxRm(evt.currentTarget.value ? Number(evt.currentTarget.value) : currentSettings.rmMax)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => 
									updateMaxRm(evt.target && (evt.target as HTMLInputElement).value
										? Number((evt.target as HTMLInputElement).value)
										: currentSettings.rmMax)}
							/>
						</div>
					</div>
				</div>
				<div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
					<div
						style={{
							fontSize: theme.fontSizes.normal,
							fontWeight: theme.fontWeights.bold,
						}}
					>Traits</div>
					<div style={{ marginLeft: 20 }}>
						<StyledSelectAllCheckbox
							className='AgModal-Traits-SelectAll'
							indeterminate={allTraitsIndeterminate}
							checked={allTraitsChecked}
							onChange={() => selectAllTraits()}
						>
							Select All
						</StyledSelectAllCheckbox>
					</div>	
				</div>
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{
						currentTraits.map(ut =>
						{
							return (
								<div
									key={ut.TraitId}
									onClick={() => selectSpecificTrait(ut.TraitId)}
									style={{
										minWidth: 30,
										marginRight: 20,
										marginBottom: 15,
										paddingTop: 5,
										paddingBottom: 5,
										paddingRight: 20,
										paddingLeft: 20,
										backgroundColor: ut.Selected ? theme.colors.secondary : theme.colors.disabledGrey,
										fontWeight: theme.fontWeights.bold,
										borderRadius: 10,
										textAlign: 'center',
										cursor: 'pointer',
									}}
								>
									{ut.TraitName}
								</div>
							);
						})
					}
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 25 }}>
					<div style={{ display: 'flex', alignItems: 'center', width: '40%' }} className='AgModal-Irrigation-Switch'>
						<StyledSwitch
							checked={currentSettings.irrigated}
							flexDirection={'row'}
							checkedLabel={'Irrigated'}
							uncheckedLabel={'Non-Irrigated'}
							onChange={updateIrrigation}
							title={'Irrigation'}
						/>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', width: '50%' }} className='AgModal-Fungicide-Switch'>
						<StyledSwitch
							checked={currentSettings.fungicide}
							flexDirection={'row'}
							checkedLabel={'Yes'}
							uncheckedLabel={'No'}
							onChange={updateFungicide}
							title={'Fungicide'}
						/>
					</div>
				</div>
				{
					(cropName && !cropName.toLowerCase().includes('soy') && brandApplication.toLowerCase() === 'any') && 
					<div style={{ marginTop: 10, marginBottom: 35 }}>
						<div
							style={{
								fontSize: theme.fontSizes.normal,
								fontWeight: theme.fontWeights.bold,
								marginBottom: 20
							}}
						>What is a higher priority for this operation?</div>
						<DescriptiveSlider
							highEndDescription={'High priority'}
							leftDescription={'Consistent performance'}
							rightBarColor={theme.colors.ghLightBlue}
							lowEndDescription={'Not a priority'}
							leftBarColor={theme.colors.secondary}
							min={0}
							max={10}
							stepValue={1}
							sliderValue={currentSettings.performance}
							onChange={updateConsistency}
						/>
						<DescriptiveSlider
							highEndDescription={'High priority'}
							leftDescription={'Top end yield'}
							rightBarColor={theme.colors.ghLightBlue}
							lowEndDescription={'Not a priority'}
							leftBarColor={theme.colors.secondary}
							min={0}
							max={10}
							stepValue={1}
							sliderValue={currentSettings.topEndYield}
							onChange={updateTopEndYield}
						/>
					</div>
				}
				<div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						className='AgModal-ClearFilters-Button'
						variant='outlined'
						style={{ marginRight: 15, width: 150 }}
						onClick={clearFilter}
					>
						Clear Filters
					</Button>
					<Button
						style={{ width: 150 }}
						className='AgModal-Done-Button'
						variant='dark'
						onClick={closeModal}
					>
						Done
					</Button>
				</div>
			</div>
		</StyledModal>
	);
}