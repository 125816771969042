import { useEffect, useState } from 'react';
import { Api } from '../../../logic/Api/Api';
import { ICropwiseOrganization } from './ICropwiseOrganization';

/**
 * Fetch cropwise organizations for a specific user
 */
export function useCropwiseOrganizations(
	authToken: string,
	userId: string) 
{
	const [loading, setLoading] = useState(1);
	const [orgs, setOrgs] = useState<ICropwiseOrganization[] | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	// Fetch organizations from the API
	useEffect(() => 
	{
		setLoading(l => l + 1);
		setError(undefined);
		setOrgs(undefined);

		const api = new Api('/api/6', authToken, undefined);
		async function fetchOrgs() 
		{
			try 
			{
				const response = await api.getAsync<ICropwiseOrganization[]>(
					`users/${userId}/cropwise-organizations?showAll=true`
				);

				setOrgs(response.Data);
			}
			catch (e) 
			{
				setError('Failed to fetch organizations');
			}

			finally 
			{
				setLoading(l => l - 1);
			}
		}
		fetchOrgs();
	}, [authToken, userId]);

	const isLoading = loading > 0;

	// We start the loading at 1 now so that the spinner is immediately up
	useEffect(() => setLoading(l => l - 1), []);

	return { orgs, error, isLoading };
}
