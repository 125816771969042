import { themeGet } from '@styled-system/theme-get';
import { Select } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { IHybridResponse } from '../../../logic/Models/Responses/SeedResponse';

const StyledSelect = styled(Select)`
	width: 100%;
	.ant-select-selector {
		border-radius: 4px !important;
		border-color: ${themeGet('colors.darkGrey')} !important;
		//background-color: ${themeGet('colors.darkGrey')} !important;
		//color: white;
		font-family: 'Roboto';
		font-size: ${themeGet('fontSizes.small')};
		font-weight: ${themeGet('fontWeights.bold')};
	}

	svg path {
		fill: ${themeGet('colors.darkGrey')};
		stroke: ${themeGet('colors.darkGrey')};
		stroke-width: 100;
	}
`;

export interface IPpgPickSeedRowProps
{
	disableAllSeeds: boolean;
	seedList:  IHybridResponse[];
	selectedSeeds: IHybridResponse[];
	selectSeed: (value: string) => void;
}

export const PpgPickSeedRow = (props: IPpgPickSeedRowProps) =>
{
	const { disableAllSeeds, seedList, selectedSeeds, selectSeed } = props;

	// Calculate what agronomic attributes are available for selection.
	const seedOptions = useMemo(() => 
	{
		const options:IOptionItem[] = [];

		if (seedList && seedList.length > 0)
		{
			seedList.forEach(seed =>
			{
				options.push(
					{
						label: `${seed.Name} RM: ${seed.RelativeMaturity}`,
						value: seed.Id,
						disabled: disableAllSeeds ? true : selectedSeeds && selectedSeeds.length ? selectedSeeds.findIndex(s => s.Id === seed.Id) > -1 : false
					}
				);
			});
		}

		return options;
	}, [seedList, selectedSeeds, disableAllSeeds]);

	const select = (item: string) =>
	{
		selectSeed(item);
	};

	return (
		<div style={{ width: '100%', marginBottom: 10 }}>
			<div style={{ width: '90%' }}>
				<StyledSelect
					showSearch
					optionFilterProp='label'
					options={seedOptions}
					onSelect={select}
					value={null} // Keeps the value cleared after a selection
					placeholder={'Select a Seed'}
				/>
			</div>
		</div>
	);
};