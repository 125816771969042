import React, { useCallback, useState } from 'react';
import { ReactComponent as FieldActivities } from './Icons/FieldActivities.svg';
import { ReactComponent as ProductGamePlan } from './Icons/ProductGamePlan.svg';
import { ReactComponent as Recall } from './Icons/Recall.svg';
import { StyledSideBar } from './StyledSideBar';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { MapInteractionMode, toggleDrawingTools, setMapInteractionMode, IInteractionMode, setShowMapSearch, clearSelectedPlanIdState } from '../../logic/store/UI/UISlice';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { clearSelectedProductPlanId } from '../../logic/store/Plans/ProductGamePlanSlice';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { makeDispatch } from '../../logic/Utility/Utils';
import { clearState as clearFieldPlanState } from '../../logic/store/Plans/FieldPlan/FieldPlanSlice';

interface ISideMenuBarProps extends PropsFromRedux
{

}

const SideMenuBarBase = (props: ISideMenuBarProps) =>
{
	const { changeInteractionMode, displayDrawingTools, clearProductPlan, SetShowMapSearch, ClearSelectedPlanId, ClearFieldPlanState } = props;
	const [collapsed, setCollapsed] = useState<boolean>(true);

	const location = useLocation();
	const { pathname } = location;

	const matchRoute = ((pathname: string): string[] =>
	{
		if (pathname.indexOf('productgameplan') > -1)
		{
			return ['productgameplan'];
		}
		// Quote pages productquote & fieldquote
		else if (pathname.indexOf('quote') > -1 || pathname.indexOf('VRS') > -1 || pathname.indexOf('competitiveallowance') > -1)
		{
			return ['/fieldactivities/recall'];
		}
		else if (pathname.indexOf('fieldplan') > -1)
		{
			return ['/fieldactivities'];
		}
		else if (pathname.indexOf('fieldconfirmation') > -1)
		{
			return ['/fieldactivities'];
		}
		else
			return [pathname];
	});

	const toggleCollapsed = useCallback(() =>
	{
		setCollapsed(!collapsed);
	}, [collapsed]);

	const onMenuClick = () =>
	{
		displayDrawingTools(false);
		SetShowMapSearch(false);
		changeInteractionMode({ mode: MapInteractionMode.Selection });
	};

	const clearPlanData = () =>
	{
		clearProductPlan();
		ClearSelectedPlanId();
		ClearFieldPlanState();
	};

	const sideMenuItems: ItemType[] =
	[
		{
			key: '/fieldactivities',
			icon: <FieldActivities />,
			label: <Link to='/fieldactivities' onClick={clearPlanData}>Fields</Link>
		},
		{
			key: '/fieldactivities/recall',
			icon: <Recall />,
			label: <Link to='/fieldactivities/recall' onClick={clearPlanData}>Proposal List</Link>
		},
		{
			key: '/fieldactivities/productplan',
			icon: <ProductGamePlan />,
			label: <Link to='/fieldactivities/productplan' onClick={clearPlanData}>Acre Proposal</Link>
		},
	];

	return (
		<StyledSideBar width={251} collapsedWidth={70} collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
			<Menu
				className='Drawermenu'
				items={sideMenuItems}
				mode='inline'
				defaultSelectedKeys={matchRoute(pathname)}
				selectedKeys={matchRoute(pathname)}
				onClick={() => onMenuClick()}
			/>
		</StyledSideBar>
	);
};

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
	clearProductPlan: () => dispatch(clearSelectedProductPlanId()),
	displayDrawingTools: (display: boolean) => dispatch(toggleDrawingTools(display)),
	changeInteractionMode: (mode: IInteractionMode) => dispatch(setMapInteractionMode(mode)),
	SetShowMapSearch: ((value: boolean) => dispatch(setShowMapSearch(value))),
	ClearSelectedPlanId: makeDispatch(dispatch, clearSelectedPlanIdState),
	ClearFieldPlanState: makeDispatch(dispatch, clearFieldPlanState),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const SideMenuBar = connector(SideMenuBarBase);