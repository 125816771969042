import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Slider, SliderSingleProps } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';

const DescriptiveSliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
`;

const SliderContainerParent = styled.div`
	display: flex;
	padding-left: 20px;
	padding-right: 40px;
`;

interface ISliderBarColors {
	leftBarColor: string;
	rightBarColor: string;
}

const StyledSlider = styled(Slider)<ISliderBarColors & (SliderSingleProps | SliderRangeProps) & React.RefAttributes<unknown>>`
	padding: 0px;
	margin: 0px 15px;

	.ant-slider-rail {
		height: 5px;
		background-color: ${props => props.rightBarColor};
		border-radius: 5px;
	}

	:hover .ant-slider-rail {
		background-color: ${props => props.rightBarColor};
	}

	.ant-slider-track {
		height: 5px;
		background-color: ${props => props.leftBarColor};
		border-radius: 5px;
	}

	:hover .ant-slider-track {
		background-color: ${props => props.leftBarColor};
	}

	.ant-slider-handle:focus {
		box-shadow: 0 0 0 5px ${themeGet('colors.transparentGrey')};
	}
`;

interface IDescriptiveSliderProps {
	highEndDescription: string;
	leftBarColor: string;
	leftDescription: string;
	lowEndDescription: string;
	max: number;
	min: number;
	rightBarColor: string;
	sliderValue: number;
	stepValue: number;
	onChange: (value: number) => void;
}

export const DescriptiveSlider = (props: IDescriptiveSliderProps) =>
{
	const {
		highEndDescription,
		leftBarColor,
		leftDescription,
		lowEndDescription,
		max,
		min,
		rightBarColor,
		sliderValue,
		stepValue,
		onChange,
	} = props;

	const theme = useTheme();

	const change = (value: number) =>
	{
		onChange(value);
	};

	return (
		<DescriptiveSliderContainer>
			<SliderContainerParent>
				<div
					style={{
						width: '30%',
						fontSize: theme.fontSizes.small,
						display: 'flex',
						justifyContent: 'right',
						position: 'relative',
						top: -7,
					}}>{leftDescription}</div>
				<div style={{ display: 'flex', flexDirection: 'column', width: '70%'}}>
					<StyledSlider
						className='DescriptiveSlider'
						min={min}
						max={max}
						leftBarColor={leftBarColor}
						rightBarColor={rightBarColor}
						value={sliderValue}
						onChange={(value: number) => change(value)}
						step={stepValue}
						style={{ width: '100%' }}
						//trackStyle={{ backgroundColor: 'transparent' }}
						handleStyle={{ backgroundColor: theme.colors.darkGrey, border: 'none', height: 15, width: 15 }}
					/>
					<div style={{ display: 'flex', flexDirection: 'row', position: 'relative', left: 15 }}>
						<div style={{ fontSize: theme.fontSizes.tiny }}>{lowEndDescription}</div>
						<div style={{ fontSize: theme.fontSizes.tiny, marginLeft: 'auto' }}>{highEndDescription}</div>
					</div>
				</div>
			</SliderContainerParent>
		</DescriptiveSliderContainer>
	);
};