import React, { useCallback } from 'react';
import { InputNumber, Tooltip, Popover } from 'antd';
import styled, { useTheme } from 'styled-components';
import { IProductLine } from '../../../../logic/Models/Requests/ProductPlanRequest';
import { IUpdateProductParams, IUpdateYieldActionProps } from '../../../../logic/store/Plans/ProductGamePlanUpdateActions';
import { CropConfig, ICropConfig, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { IHybridResponse } from '../../../../logic/Models/Responses/SeedResponse';
import { HybridAvailability } from '../SeriesAvailability';
import { ReactComponent as GreenAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as DeleteXButton } from '../../../../assets/images/Xmark.svg';
import { Button } from '../../../../components/Button/Button';
import { themeGet } from '@styled-system/theme-get';
import { ITreatment } from './SeriesProductRow';
import { RoundYieldTarget } from '../../../../logic/Utility/CalculationUtilities';
import { IScopedSession } from '../../../../tracing/session';
import { useAmplitudePassthrough } from '../../../../logic/Utility/useAmplitude';
import { EventAcreProposalNames } from '../../../../tracing/EventNames';

const StyledProductRow = styled.div<{ isLast: boolean }>`
	display: flex;
	flex-direction: row;
	width: 100%;
	min-height: 50px;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	align-items: stretch;
	border-bottom-left-radius: ${p => (p.isLast) ? 5 : 0}px;
	border-bottom-right-radius: ${p => (p.isLast) ? 5 : 0}px;
	margin-bottom: ${p => (p.isLast) ? 8 : 0}px;
	
	.FixedArea {
		display: flex;
		flex-direction: row;
		flex-grow: 0;
		flex-shrink: 0;
		align-items: stretch;

		>div {
			font-size: ${themeGet('fontSizes.extraSmall')};
			font-family: ${themeGet('fonts.base')};
			color: ${themeGet('colors.darkGrey')};
		}

		.HybridName {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-weight: ${themeGet('fontWeights.bold')};
			padding-left: 12px;
			width: 160px;
		}
	}

	.FlexArea {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		align-items: center;
		flex-wrap: wrap;

		.ant-input-number-handler-wrap
		{
			display: none;
		}

		.ant-alert-error {
			padding: 2px 8px;
		}

		>div {
			font-size: ${themeGet('fontSizes.extraSmall')};
			font-family: ${themeGet('fonts.base')};
			color: ${themeGet('colors.darkGrey')};
			flex-grow: 0;
			flex-shrink: 0;
			width: 120px;
			flex-grow: 1;
			display:flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}

	.ant-input-number-input {
		padding: 0;
		text-align: center;
	}

	.ant-input-number.ant-input-number-borderless
	{
		width: 50px;

		input {
			font-family: 'Roboto';
			font-size: 12px;
			height: 14px;
			border-bottom: 1px black solid;
		}
	}

	.HybridSeedingRate .ant-input-number.ant-input-number-borderless
	{
		width: 70px;
	}
`;


interface IHybridProductRowProps
{
	allowPricing: boolean;
	cropId: string;
	hybrid: IHybridResponse;	
	isRateLoading: boolean;
	isSingleRow: boolean;
	planId: string;
	plantingRateError?: string;
	product: IProductLine;
	rate: { [yieldEnvironment: number]: number };
	session: IScopedSession;
	yieldError: boolean;
	getRates: () => void;
	onUpdate: (props: IUpdateProductParams) => void;
	onUpdateYield: (props: IUpdateYieldActionProps) => void;
	removeProduct: (props: { planId: string; productId: string; treatment?: ITreatment; key: string }) => void;
}
const getClassKey = (product: IProductLine): string =>
{
	const { Treatment, HybridId } = product;
	return Treatment ? [HybridId, Treatment.Name].join('-') : HybridId;
};

/**
 * Represents a sub-row for a specific hybrid on the main editing area of the product plan
 */
export function HybridProductRow(props: IHybridProductRowProps): JSX.Element
{
	const { 
		allowPricing,
		cropId,
		hybrid,
		isRateLoading,
		isSingleRow,
		planId,
		product,
		session,
		yieldError,
		getRates,
		onUpdate,
		onUpdateYield,
		removeProduct,
	} = props;
	// Extract the unique identifier for this line
	const productKey = product.Key;
	const cropConfig = CropConfig()[cropId];

	const updateAndSave = useAmplitudePassthrough(session, EventAcreProposalNames.UpdatetHybridValues, (update: Partial<IProductLine>) => 
	{
		// Only fire if something has changed
		for(const key in update)
		{
			if(update[key] !== product[key])
			{
				if (!product.YieldTarget)
				{
					update.YieldTarget = cropConfig.minYield;
				}
				onUpdate({
					cropId,
					planId,
					productKey,
					update
				});
				return;
			}
		}
	}, [product, onUpdate, cropId, planId, productKey]);

	const updateYieldAndSave = useCallback((yieldTarget: number) => 
	{
		// Make sure yieldTarget is actually a number
		yieldTarget = yieldTarget ? yieldTarget : 0;
		let validValue = RoundYieldTarget(yieldTarget);
		if (yieldTarget > cropConfig.maxYield)
		{
			validValue = cropConfig.maxYield;
		}
		else if (yieldTarget < cropConfig.minYield)
		{
			validValue = cropConfig.minYield;
		}
		if(validValue != product.YieldTarget)
		{
			onUpdateYield({
				cropId,
				planId,
				productKey,
				yieldTarget: validValue,
				seriesName: hybrid.SeriesName
			});
		}
	}, [cropConfig.maxYield, cropConfig.minYield, product.YieldTarget, cropId, planId, productKey, hybrid.SeriesName, onUpdateYield]);

	const refreshRates = useCallback(() =>
	{
		if (!isRateLoading && yieldError)
		{
			getRates();
		}
	}, [getRates, yieldError, isRateLoading]);

	const updateQuantityAndSaveIfNeeded = useCallback((newValue: number) => 
	{
		// Only trigger an update if the value has effectively changed
		// This prevents a recalculation of the input acres when the user tabs across
		if (newValue !== Math.ceil(product.Quantity ?? 0))
		{
			// If the user is only entering bags or acres and there is not yet a target yield, set the minimum
			if (!product.YieldTarget)
			{
				updateAndSave({ Quantity: newValue, YieldTarget: cropConfig.minYield });
			}
			else
			{
				updateAndSave({ Quantity: newValue });
			}
		}
	}, [product.Quantity, product.YieldTarget, cropConfig.minYield, updateAndSave]);

	let costPerAcre: number | string;
	const isSoy = cropId === soyId;
	if (!isSoy)
	{
		// Price per bag -- corn treatment price is always 0
		costPerAcre = product.PriceRate + (product.Treatment?.Price ?? 0);
	}
	else
	{
		// Price per acre
		costPerAcre = product.TotalPrice / product.AppliedArea;
	}

	return <StyledProductRow className={`HybridContainer HybridContainer-${getClassKey(product)}`} isLast={false}>
		<div className='FixedArea'>
			{hybrid && <>
				<AvailabilitySection hybrid={hybrid} />
				<div className={`HybridName ${getClassKey(product)}`}>{product.Treatment ? product.Treatment.Name : hybrid.Name}</div>
			</>}
		</div>
		<div className='FlexArea'>
			{cropConfig.hasYieldTarget && <YieldTargetSection
				{...props}
				cropConfig={cropConfig}
				refreshRates={refreshRates}
				updateYieldAndSave={updateYieldAndSave} />}
			{allowPricing && <div className={`HybridCostPerAcre-${getClassKey(product)}`}>
				{!product.PriceRate || (cropConfig.priceUnits !== 'ac' && (!product.SeedingRate || !cropConfig.seedsPerBag || !product.AppliedArea)) ?
					<span>---</span> :
					<>Cost/Ac: ${costPerAcre.toFixed(2)}</>}
			</div>}
			<div className={`HybridArea-${getClassKey(product)}`}>
				<InputNumber
					type='number'
					min={0}
					value={product.AppliedArea ? Math.round(product.AppliedArea * 10) / 10 : Math.round(product.AppliedArea * 10) / 10}
					bordered={false}
					onPressEnter={(evt) => updateAndSave({ AppliedArea: (evt.target as HTMLInputElement).value ?
						Number((evt.target as HTMLInputElement).value) : Math.round(product.AppliedArea * 10) / 10 })}
					onBlur={(evt) => updateAndSave({ AppliedArea: evt.currentTarget.value ?
						Number(evt.currentTarget.value) : Math.round(product.AppliedArea * 10) / 10 })}
				/>
				Acres
			</div>
			<div className={`HybridQuantity-${getClassKey(product)}`}>
				<InputNumber
					type='number'
					min={0}
					value={product.Quantity ? Math.ceil(product.Quantity) : product.Quantity}
					bordered={false}
					onPressEnter={(evt) => updateQuantityAndSaveIfNeeded((evt.target as HTMLInputElement).value ?
						Number((evt.target as HTMLInputElement).value) : product.Quantity)}
					onBlur={(evt) => updateQuantityAndSaveIfNeeded(evt.currentTarget.value ?
						Number(evt.currentTarget.value) : product.Quantity)}
				/>
				Bags
			</div>
			{cropConfig.canEditSeedingRate && <SeedingRateSection {...props}
				cropConfig={cropConfig}
				updateAndSave={updateAndSave} />}
			{!cropConfig.canEditSeedingRate && <div className={`HybridSeedingRate ${getClassKey(product)}`}>
				Seeds/Ac: {product.SeedingRate ? product.SeedingRate.toFixed(0) : product.SeedingRate}
			</div>}
			{allowPricing && <div className={`HybridTotalPrice-${getClassKey(product)}`}>
				$ Total: ${product.TotalPrice ? product.TotalPrice.toFixed(2) : 0}
			</div>}
			<div style={{ width: 10}}>
				{
					!isSingleRow &&
					<DeleteXButton
						style={{ cursor: 'pointer' }}
						onClick={() => removeProduct({ planId: planId, productId: product.HybridId, treatment: product.Treatment, key: productKey })}
					/>
				}
			</div>
		</div>
	</StyledProductRow>;
}

export const AvailabilitySection = (props: { hybrid: IHybridResponse }) =>
{
	const { hybrid } = props;	

	const theme = useTheme();

	return <HybridAvailability>
		{hybrid.Availability == 'Green' ? <GreenAvailabilityIcon style={{ fill: theme.colors.availabilityGreen }} />
			: hybrid.Availability === 'Yellow' ? <YellowAvailabilityIcon style={{ fill: theme.colors.availabilityYellow }} />
				: hybrid.Availability === 'Red' ? <RedAvailabilityIcon style={{ fill: theme.colors.availabilityRed }} />
					: <React.Fragment />
		}
	</HybridAvailability>;
};

interface IYieldTargetProps
{
	cropConfig: ICropConfig;
	yieldError: boolean;
	isRateLoading: boolean;
	plantingRateError?: string;
	rate: { [yieldTargetEnvironment: number]: number; };
	product: IProductLine;
	hybrid: IHybridResponse;
	updateYieldAndSave: (yieldTarget: number) => void;
	refreshRates: () => void;
}
const YieldTargetSection = (props: IYieldTargetProps) =>
{
	const { yieldError, isRateLoading, plantingRateError, rate, cropConfig, product, hybrid, refreshRates, updateYieldAndSave } = props;
	const YieldInput = () => <InputNumber
		type="number"
		min={cropConfig.minYield}
		max={cropConfig.maxYield}
		value={product.YieldTarget ? product.YieldTarget.toFixed(0) : product.YieldTarget}
		bordered={false}
		onPressEnter={(evt) => updateYieldAndSave((evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value) : product.YieldTarget)}
		onBlur={(evt) => updateYieldAndSave(evt.currentTarget.value ? Number(evt.currentTarget.value) : product.YieldTarget)} />;

	const isError = (yieldError || !rate) && !isRateLoading;
	return <div className={`HybridYieldTarget-${getClassKey(product)}`}>
		{isError && <div
			style={{ border: '1px solid red', padding: '0 10px' }}>
			<Popover trigger='click'
				title={!rate ? `No rates found for ${hybrid.Name}` : 'Unable to retrieve yield rates'}
				content={<div>
					<span>{plantingRateError}</span>
					{yieldError && <Button variant='outlined' onClick={() => refreshRates()}>Refresh</Button>}
				</div>}>
				{YieldInput()} Target Yield
			</Popover>
		</div>}
		{!isError && <>
			{YieldInput()} Target Yield
		</>}
	</div>;
};

interface ISeedRateProps
{
	product: IProductLine;
	cropConfig: ICropConfig;
	updateAndSave: (props: { SeedingRate: number; }) => void;
}
const SeedingRateSection = (props: ISeedRateProps) =>
{
	const { product, cropConfig, updateAndSave } = props;
	const title = product.SeedingRate < cropConfig.minSeedingRate ? `Please enter seeding rate higher than ${cropConfig.minSeedingRate}` :
		(product.SeedingRate > cropConfig.maxSeedingRate ? `Please enter seeding rate lower than ${cropConfig.maxSeedingRate}` : '');
	return <div className={`HybridSeedingRate ${getClassKey(product)}`}>
		<span>Seeds/Ac:</span>
		<Tooltip title={title}>
			<InputNumber
				type='number'
				min={cropConfig.minSeedingRate}
				max={cropConfig.maxSeedingRate}
				value={product.SeedingRate ? product.SeedingRate.toFixed(0) : product.SeedingRate}
				bordered={false}
				onPressEnter={(evt) => updateAndSave({ SeedingRate: (evt.target as HTMLInputElement).value ?
					Number((evt.target as HTMLInputElement).value) : product.SeedingRate })}
				onBlur={(evt) => updateAndSave({ SeedingRate: evt.currentTarget.value ?
					Number(evt.currentTarget.value) : product.SeedingRate })}
			/>
		</Tooltip>
	</div>;
};