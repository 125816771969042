import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ZoomTray } from '../ZoomTray';
import L, { LatLng, Layer, LeafletMouseEvent } from 'leaflet';
import { AppDispatch, RootState } from '../../store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { baseStyle, defaultZoomLevel, hybridLayerParams, maxBoundsContinentalUSParams, satelliteLayerParams, streetsLayerParams } from '../Shared/MapCommon';
import turf, { Feature, featureCollection, Geometry, geometryCollection, Polygon } from '@turf/turf';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { setShowMapSearch } from '../../store/UI/UISlice';
import { get } from 'lodash';
import { BulkImportFeatureProperties } from '../../store/BulkImport/BulkImportThunks';
import { IBulkImportedFarm } from '../../store/BulkImport/BulkImportSlice';
import { useTheme } from 'styled-components';
import { globalSession } from '../../../tracing/session';

interface IBulkImportMapProps extends PropsFromRedux
{
	setMapRef: (map: L.DrawMap) => unknown;
	hoverFarmFieldId: string | undefined;

	setHover: (farmFieldId: string) => unknown;
	onSelect: (farmFieldId: string) => unknown;
	focusField: string | undefined;
}

const BulkImportMapComponent = (props: IBulkImportMapProps) =>
{
	const {
		farms,
		DisplayMapSearch,
		MapboxToken,
		SelectedGrower,
		SelectedGrowerId,
		setMapRef,
		SetShowMapSearch,
		hoverFarmFieldId: hoverId,
		setHover,
		onSelect
	} = props;

	const theme = useTheme();

	const farmNames = useMemo(() =>
	{
		if (farms)
		{
			return Object.keys(farms);
		}
	}, [farms]);

	// Reference to the map control
	const mapRef = useRef<L.DrawMap | undefined>(undefined);

	// Store the element the map will be rendered into
	const [mapContainer, setMapContainer] = useState<HTMLDivElement>();

	// Store the geojson data layer we will use to represent everything.
	const [geoJsonLayer] = useState(L.geoJSON());

	// Base Layers for the map
	const hybridLayer = L.tileLayer((hybridLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), hybridLayerParams[1] as {attribution: string});
	const streetsLayer = L.tileLayer((streetsLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), streetsLayerParams[1] as {attribution: string});
	const satelliteLayer = L.tileLayer((satelliteLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), satelliteLayerParams[1] as {attribution: string});

	// Create a layer object to pass to the layer control on the map
	const baseMaps = {
		'Hybrid': hybridLayer,
		'Streets': streetsLayer,
		'Satellite': satelliteLayer
	};

	// Continental US bounds
	const maxBoundsContinentalUS: L.LatLngBounds =  L.latLngBounds(
		L.latLng(maxBoundsContinentalUSParams[0][0], maxBoundsContinentalUSParams[0][1]), //Southwest
		L.latLng(maxBoundsContinentalUSParams[1][0], maxBoundsContinentalUSParams[1][1])  //Northeast
	);

	// Create the default map styler that will apply color to the rendered vectors.
	const styler = useCallback(((feature: Feature<Geometry, BulkImportFeatureProperties>) =>
	{
		if (farms)
		{
			const farm = farms[feature.properties['oi:farm-key']];
			const style = {
				...baseStyle(theme),
				weight: 1,
				fillColor: farm.color,
			};
			const field = farm.Fields[feature.properties['oi:field-key']];
			if ((field?.currentConflicts.length ?? 0) > 0)
			{
				style.color = 'red';
			}
			if(`${farm.Id}:${field.Id}` === hoverId)
			{
				style.fillOpacity = 1;
				style.weight = 3;
			}
			return style;
		}
		else
		{
			return { ...baseStyle(theme), weight: 1 };
		}
	}) as L.StyleFunction<any>, [farmNames, farms, hoverId]);

	// When the container is rendered, save the element so we can install the map there
	const installMap = useCallback((ref: HTMLDivElement) =>
	{
		if (ref)
		{
			// On refreshes, if the mapRef exists, it can throw an error about the map already having been initialized
			// Removing it before we re-initialize it will fix that
			if (mapRef.current)
			{
				mapRef.current.remove();
			}

			if (!SelectedGrower?.ReferencePoint)
			{
				mapRef.current = L.map(ref, {
					zoomControl: false,
					layers: [hybridLayer], // default to the Streets layer
				}).fitBounds(maxBoundsContinentalUS);
			}
			else
			{
				const growerReferencePoint = SelectedGrower.ReferencePoint.coordinates as turf.Position;
				mapRef.current = L.map(ref, {
					zoomControl: false,
					zoom: defaultZoomLevel,
					center: [growerReferencePoint[1], growerReferencePoint[0]],
					layers: [hybridLayer] // default to the Streets layer
				});
			}

			// Layer control - defaults to topright
			L.control.layers(baseMaps).addTo(mapRef.current);

			// Zoom control
			L.control.zoom({ position: 'bottomright' }).addTo(mapRef.current);

			setMapContainer(ref);
			setMapRef(mapRef.current);
		}
	}, [setMapContainer, setMapRef]);

	const toggleLocationSearch = useCallback(() =>
	{
		SetShowMapSearch(!DisplayMapSearch);
	}, [mapRef.current, DisplayMapSearch, SetShowMapSearch]);

	const geocoder = new MapboxGeocoder({
		accessToken: MapboxToken,
		types: 'address,region,place,postcode,locality,neighborhood',
		countries: 'us'
	});

	const centerMap = useCallback(() =>
	{
		if (farms && mapRef.current)
		{
			const unskippedGeom = Object.values(farms).flatMap(farm => Object.values(farm.Fields)).filter(field => field.geometry && !field.skip).map(field => field.geometry!);
			if(unskippedGeom?.length)
			{
				mapRef.current.fitBounds(L.geoJSON(featureCollection(unskippedGeom)).getBounds());
			}
		}
	}, [farms]);

	useEffect(() =>
	{
		if (farms && Object.values(farms).flatMap(farm => Object.values(farm.Fields)).some(f => f.geometry ?? f.originalGeometry))
		{
			const unskippedGeomCollection = featureCollection(Object.values(farms).flatMap(farm => Object.values(farm.Fields))
				.filter(field => (field.originalGeometry) && !field.skip)
				.map(field => field.geometry ?? field.originalGeometry!));
			
			geoJsonLayer.addData(unskippedGeomCollection).bindTooltip(function (layer: L.Layer)
			{
				const farmName = get(layer, 'feature.properties.oi:farm-key', '');
				const fieldName = get(layer, 'feature.properties.oi:field-key', '');
				return `${fieldName} (${farmName})`;
			}, {
				// Sticky helps when there are several polygons spread apart.
				// Without sticky the tooltip will appear in the center of _one_ of the polygons randomly.
				// With it, it appears next to the mouse cursor.
				sticky: true,
			}) as L.GeoJSON<any>;
			
			if (mapRef.current)
			{
				if (!mapRef.current.hasLayer(geoJsonLayer))
				{
					mapRef.current.fitBounds(L.geoJSON(unskippedGeomCollection).getBounds());
				}
				geoJsonLayer.addTo(mapRef.current);
			}
		}
		return () =>
		{
			geoJsonLayer.clearLayers();
		};
	}, [geoJsonLayer, farms]);

	useEffect(() => 
	{
		if(geoJsonLayer)
		{
			geoJsonLayer.setStyle(styler);
		}
	}, [geoJsonLayer, styler]);

	// On click we will select the field under the cursor or, if it is already selected, cycle through other polygons
	// at the same point.
	useEffect(() => 
	{	
		if(!geoJsonLayer || !onSelect || !farms)
		{
			return;
		}

		geoJsonLayer.addEventListener('click', (event: LeafletMouseEvent) => 
		{
			const point = event.latlng;
			const fieldsAtPoint = findFieldsAtPoint(geoJsonLayer, point);

			if(!fieldsAtPoint.length)
			{
				return;
			}

			const fieldIds = fieldsAtPoint.map(({farmName, fieldName}) => `${farmName}:${fieldName}`);
			const selectedIndex = hoverId ? fieldIds.indexOf(hoverId) : -1;
			const nextLayer = fieldIds[selectedIndex <= 0 ? fieldIds.length - 1 : selectedIndex - 1];
			onSelect(nextLayer);
			setHover(nextLayer);

			// Update the tooltip
			const content = buildTooltipForPoint(point, nextLayer, geoJsonLayer, farms);
			geoJsonLayer.setTooltipContent(content);
			// Reopen otherwise it closes for some reason (probably a re-render)
			geoJsonLayer.openTooltip(point);
		}, null);

		return () =>
		{
			geoJsonLayer.removeEventListener('click');
		};
	}, [geoJsonLayer, onSelect, setHover, hoverId]);

	// On mouseover set our hover state
	useEffect(() => 
	{	
		if(!geoJsonLayer || !onSelect)
		{
			return;
		}

		geoJsonLayer.addEventListener('mouseover', (event: LeafletMouseEvent) => 
		{
			const clickedLayer = event.propagatedFrom;
			const farmName = get(clickedLayer, 'feature.properties.oi:farm-key', '');
			const fieldName = get(clickedLayer, 'feature.properties.oi:field-key', '');
			if(farmName?.length && fieldName?.length)
			{
				setHover(`${farmName}:${fieldName}`);
			}
		}, null);

		return () =>
		{
			geoJsonLayer.removeEventListener('mouseover');
		};
	}, [geoJsonLayer, setHover]);


	// On mousemove we update the tooltip for the precise location of the mouse
	useEffect(() => 
	{	
		if(!geoJsonLayer || !farms)
		{
			return;
		}

		geoJsonLayer.addEventListener('mousemove', (event: LeafletMouseEvent) => 
		{
			const point = event.latlng;
			const content = buildTooltipForPoint(point, hoverId, geoJsonLayer, farms);
			geoJsonLayer.setTooltipContent(content);
			geoJsonLayer.openTooltip(point);
		}, null);

		return () =>
		{
			geoJsonLayer.removeEventListener('mousemove');
		};
	}, [geoJsonLayer, farms, hoverId]);

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<div id='mapId' ref={installMap} style={{ width: '100%', height: '100%' }} />
			<div id='geocoder' style={{ position: 'absolute', top: 73, left: 'calc(100vw - 307px)', zIndex: 998, display: !DisplayMapSearch ? 'none' : 'inherit' }} />
			<ZoomTray
				mapRef={mapRef}
				onCenterAll={centerMap}
				onCenterSelected={undefined}
				toggleLocationSearch={toggleLocationSearch}
				displayTools={SelectedGrowerId ? true : false}
				geocoder={geocoder}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	return {
		farms: state.bulkImport.farms,
		Geometry: state.bulkImport.geometry,
		DisplayMapSearch: state.ui.ShowMapSearch,
		MapboxToken: state.config.MapboxAccessToken,
		SelectedGrowerId: state.ui.SelectedGrowerId,
		SelectedGrower,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		SetShowMapSearch: ((value: boolean) => dispatch(setShowMapSearch(value))),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const BulkImportMap = connector(BulkImportMapComponent);

// Tooltip help functions:

/**
 * Given a latlng, find all the fields that intersect at that location in bottom->top order
 */
function findFieldsAtPoint(geoJsonLayer: L.GeoJSON<any>, point: L.LatLng) 
{
	const fieldsAtPoint: {farmName: string, fieldName: string}[] = [];
	// get all features at this point
	geoJsonLayer.eachLayer(function (memberLayer: Layer) 
	{
		try 
		{
			if (isLatLngInsidePolygon(point, memberLayer as L.Polygon)) 
			{
				const farmName = get(memberLayer, 'feature.properties.oi:farm-key', '');
				const fieldName = get(memberLayer, 'feature.properties.oi:field-key', '');
				fieldsAtPoint.push({farmName, fieldName});
			}
		}
		catch (err) 
		{
			// just ignore the error, the geometry logic failing shouldn't break the app.
			globalSession.error(err);
		}
	});
	return fieldsAtPoint;
}

/**
 * Construct an 'aggregate' tooltip that includes all fields at this point, highlighting the hovered one
 */
function buildTooltipForPoint(point: L.LatLng, hoverId: string | undefined, geoJsonLayer: L.GeoJSON<any>, farms: { [farmId: string]: IBulkImportedFarm; }) 
{
	const fieldsAtPoint = findFieldsAtPoint(geoJsonLayer, point);
	const tooltipItems = fieldsAtPoint.map(({farmName, fieldName}) => 
	{
		const farm = farms[farmName];
		return formatFieldForTooltip(fieldName, farmName, farm, hoverId);
	});
	
	// Reverse the items to show 'top -> bottom'
	const content = '<div style="display:flex;flex-direction:column;align-items:stretch">' 
		+ tooltipItems.reverse().join('\n') 
		+ '</div>';
	return content;
}

/**
 * Given a specific field, create a tooltip row for it. It will include a swatch for the farm color and be bolded
 * if this is the currently hovered field.
 * 
 * Sadly it has to be inline HTML.  This could be refactored to a React Portal if we do additional logic with it.
 */
function formatFieldForTooltip(fieldName: string, farmName: string, farm: IBulkImportedFarm, hoverId: string | undefined) 
{
	return `<div style="display:flex;flex-direction:row;align-items:center;flex-shrink:0;font-weight:${`${farmName}:${fieldName}` === hoverId ? 'bold':'normal'}">
				<div style="margin-left:2px;margin-right:8px">${fieldName}</div>
				<div>(</div>
				<div style='width:12px;height:12px;border-radius:50%;background-color:${farm.color};margin-left:2px;margin-right:2px'>&nbsp;</div>
				<div style="margin-left:2px;margin-right:2px">${farmName}</div>
				<div>)</div>
			</div>`;
}

/**
 *  Basic raycasting point-in-multipolygon test
 */
function isLatLngInsidePolygon(latLng: LatLng, poly: L.Polygon) 
{
	const x = latLng.lat, y = latLng.lng;
	
	let inside = false;
	for(const polygon of (poly.getLatLngs() as LatLng[][][]))
	{
		for(const ring of polygon)
		{
			const polyPoints = ring;
			for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) 
			{
		
				const xi = polyPoints[i].lat, yi = polyPoints[i].lng;
				const xj = polyPoints[j].lat, yj = polyPoints[j].lng;
		
				const intersect = ((yi > y) !== (yj > y))
						&& (x < ((xj - xi) * (y - yi) / (yj - yi) + xi));
				if (intersect) 
				{
					inside = !inside;  
				}
			}
		}
	}

	return inside;
}