import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { IFileUploadTracker } from '../../../logic/store/MachineFileData/FileSlice';

export interface IFileUploadModalProps
{
	fileUploadList: IFileUploadTracker[];
	visible: boolean;
	onCancel: (done: boolean) => void;
}

export const FileUploadModal = (props: IFileUploadModalProps) =>
{
	const { fileUploadList, visible, onCancel } = props;

	const theme = useTheme();

	const fileStatusRow = (fileTracker: IFileUploadTracker) =>
	{
		let status = 'Processing';
		if (fileTracker.IsDone)
		{
			if (fileTracker.IsError)
			{
				status = 'Error';
			}
			else
			{
				status = 'Complete';
			}
		}
		else if (fileTracker.IsLoading)
		{
			status = 'Uploading';
		}

		return (
			<div
				key={fileTracker.File.uid}
				style={{
					display: 'flex',
					flexDirection: 'row',
					border: `1px solid ${theme.colors.lightGrey}`,
					width: '100%',
					padding: 10,
					marginBottom: 10
				}}>
				<div>{fileTracker.File.name}</div>
				<div style={{ marginLeft: 'auto' }}>
					{status}
				</div>
			</div>
		);
	};

	return (
		<StyledModal
			onCancel={() => fileUploadList && fileUploadList.every(f => f.IsDone) ? onCancel(true) : onCancel(false)}
			open={visible && fileUploadList && fileUploadList.length > 0}
			width={655}
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>Uploading File(s)</div>
			}
		>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<span style={{ marginBottom: 20, marginTop: -20 }}>
					Please wait while we upload your file(s). 
					If you cancel the upload or close this modal, all the information will be lost and you'll have to start the upload again
				</span>
				{
					fileUploadList &&
					fileUploadList.map(f =>
					{
						return (
							fileStatusRow(f)
						);
					})
				}
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
				{
					fileUploadList && fileUploadList.every(f => f.IsDone) ?
						<Button
							className='cancel-button'
							variant='outlined'
							onClick={() => onCancel(true)}
							width={'100px'}
						>Done</Button>
						:
						<Button
							className='cancel-button'
							variant='outlined'
							onClick={() => onCancel(false)}
							width={'100px'}
						>Cancel</Button>
				}
			</div>
			
		</StyledModal>
	);
};