import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import ReactJson from 'react-json-view';
import { connect, ConnectedProps } from 'react-redux';
import { Api } from '../../logic/Api/Api';
import { RootState } from '../../logic/store/Store';

/**
 * Present tools to inspect a user's growers in Crop Edge
 */
const GrowerAssociationTroubleshootingTool = (props: PropsFromRedux) => 
{
	const [enteredSearchEmail, setEnteredSearchEmail] = useState<string>('');
	const [enteredSearchYear, setEnteredSearchYear] = useState<string>('');
	const [searchEmail, setSearchEmail] = useState<string>('');
	const [searchYear, setSearchYear] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userData, setUserData] = useState<object>({});

	// When a new search term is committed, fetch the order from the server
	useEffect(()=>
	{
		if (!searchEmail || !searchYear)
		{
			return;
		}

		const execute = async () => 
		{
			const api = new Api('/api/4', props.userAuthToken, undefined);
			try
			{
				const response = await api.getAsync<object>(
					`/supporttools/info/user/${encodeURIComponent(searchEmail)}/years/${searchYear}/cropedgegrowers`);	
				setUserData(response);
			}
			catch(err)
			{
				setUserData(err);
			}
			setIsLoading(false);
		};
		setIsLoading(true);
		execute();
	},
	[searchEmail, searchYear, props.userAuthToken]);

	return (
		<Form style={{display:'flex', flexDirection:'column', alignItems:'stretch', height: '100%', position: 'relative'}}>
			<h1>Grower Association</h1>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
				<Form.Item label="User Email" style={{width: 400, marginRight: 12}}>
					<Input disabled={isLoading} value={enteredSearchEmail} onChange={(e) => setEnteredSearchEmail(e.currentTarget.value)} placeholder='User Email Address' />
				</Form.Item>
				<Form.Item label="Sales Year" style={{width: 200, marginRight: 12}}>
					<Input disabled={isLoading} value={enteredSearchYear} onChange={(e) => setEnteredSearchYear(e.currentTarget.value)} placeholder='Year' />
				</Form.Item>
				<Button onClick={() => { setSearchEmail(enteredSearchEmail); setSearchYear(enteredSearchYear); }}>Search</Button>
			</div>
			<ReactJson style={{flexGrow: 1, flexShrink: 1, overflow: 'auto', padding: 4, border: '1px #cccccc solid'}} src={userData} />
		</Form>
	);
};

const mapStateToProps = (state: RootState) => ({
	userAuthToken: state.auth.userAuthToken,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const GrowerAssociationTroubleshootingToolComponent = connector(GrowerAssociationTroubleshootingTool);
