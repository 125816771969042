import { useMemo } from 'react';
import { RootState } from '../../../logic/store/Store';
import { useSelector } from 'react-redux';
import { EventGrowerTags, EventPlanTags, EventSelectionTags, EventTags } from '../../../tracing/EventTagNames';

export function useMaxScriptTrackingState() 
{
	const selectedGrower = useSelector((state: RootState) => state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId));
	const orderId = useSelector((state: RootState) => state.ui.SelectedPlanId);
	const selectedOrder = selectedGrower.Plans.find(p => p.Id === orderId && p.Type === 'Order');

	const trackingState = useMemo<EventTags>(() => ({

		[EventSelectionTags.GrowerId]: selectedGrower?.Id,
		[EventGrowerTags.GrowerName]: selectedGrower?.Name,
		[EventGrowerTags.GrowerSalesforceId]: selectedGrower?.SalesforceId,
		[EventGrowerTags.GrowerFoundationId]: selectedGrower?.FoundationId,

		[EventSelectionTags.PlanId]: selectedOrder?.Id,
		[EventPlanTags.PlanName]: selectedOrder?.Name,
		[EventPlanTags.PlanType]: 'Maxscript',
		[EventPlanTags.PlanDownloaded]: String(Boolean(selectedOrder?.Downloaded)),
		[EventPlanTags.PlanStatus]: selectedOrder?.Status,
		[EventPlanTags.PlanOrderId]: selectedOrder?.SalesforceOrderId,

	}), [selectedGrower]);
	return trackingState;
}
