import L from 'leaflet';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { BulkImportMap } from '../../../logic/Map/BulkImportMap/BulkImportMap';
import { useTheme } from 'styled-components';
import { getCurrentActingUser } from '../../../logic/store/User/AuthSlice';
import { ScaleLoader } from 'react-spinners';
import { CoveringLoader } from '../../../components/LoadingSpinner/CoveringLoader';
import { ConnectedBackToFieldList } from '../BackToFieldList';
import { Alert, Radio, Input } from 'antd';
import { sortBy, debounce } from 'lodash';
import { H3 } from './H3';
import { HR } from './HR';
import { Msg } from './Msg';
import { ListHeader } from './ListHeader';
import { RadioList } from './RadioList';
import { CropwiseImportContainer } from './CropwiseImportContainer';
import { useCropwiseOrganizations } from './useCropwiseOrganizations';
import { ProgressSpinner } from '../../../components/LoadingSpinner/ProgressSpinner';
import { useImportOrganization } from './useImportOrganization';

const { Search } = Input;

const CropwiseImportComponent = (props: PropsFromRedux) =>
{
	const {
		AuthToken,
		SelectedUserId,
		SelectedGrower
	} = props;
	const history = useHistory();
	const theme = useTheme();
	
	const mapRef = useRef<L.DrawMap | undefined>(undefined);
	const setMapRef = useCallback((map: L.DrawMap) => { mapRef.current = map; }, []);

	const cancelCropwiseImportSession = useCallback(() =>
	{
		history.push('/fieldactivities');
	}, [history]);

	const [selectedSourceOrganizationId, setSelectedSourceOrganizationId] = useState<string | undefined>(undefined);
	const [searchTerm, setSearchTerm] = useState<string>('');
	
	const {
		orgs, error, isLoading
	} = useCropwiseOrganizations(AuthToken, SelectedUserId);

	const {
		error: publishError,
		importOrg,
		isPublishing,
		progress
	} = useImportOrganization({ authToken: AuthToken, userId: SelectedUserId, destinationGrowerId: SelectedGrower.Id, onSuccess: cancelCropwiseImportSession });

	const onImport = useCallback(() => importOrg(selectedSourceOrganizationId), [selectedSourceOrganizationId, importOrg]);

	// Filter organizations based on search term and other criteria
	const availableOrgs = useMemo(() => orgs?.filter(o => !o.EluminateId && o.TotalArea > 0), [orgs]);
	const filteredOrgs = useMemo(() => sortBy(
		availableOrgs?.filter(o => !searchTerm.length || o.Name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())),
		o => o.Name.toLocaleLowerCase()),
	[availableOrgs, searchTerm]);

	// Automatically select an organization if it matches the selected grower's name
	useEffect(() => 
	{		
		if(!availableOrgs)
		{
			return;
		}
		
		const idx = availableOrgs.findIndex(o => o.Name.trim().toLocaleLowerCase() === SelectedGrower.Name.trim().toLocaleLowerCase());
		if(idx >= 0)
		{
			setSelectedSourceOrganizationId(prev => prev ?? availableOrgs[idx].BaseId);
		}
	}, [SelectedGrower.Name, availableOrgs]);

	// Handle search input changes
	const handleSearch = useCallback((value: string) => 
	{
		setSearchTerm(value);
	}, []);

	const debouncedSearch = useMemo(() => debounce(handleSearch, 400), [handleSearch]);

	const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
	{
		debouncedSearch(e.target.value);
	}, [debouncedSearch]);

	const onSearchBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => 
	{
		handleSearch(e.target.value);
	}, [handleSearch]);

	return <CropwiseImportContainer>
		<div style={{ display: 'flex', flexDirection: 'column', maxWidth: 382, minWidth: 382, position: 'relative' }}>
			<ConnectedBackToFieldList />
			<H3>Import Cropwise Base</H3>
			<HR />
			<H3>Select an Organization to Import</H3>
			<div style={{margin: 16, marginBottom: 0}}>
				<Search 
					placeholder='Search by name' 
					onSearch={handleSearch} 
					onChange={onSearchChange} 
					onBlur={onSearchBlur} 
				/>
			</div>
			<div
				style={{
					margin: 16, 
					marginBottom: 0,
					flexGrow:1,
					display:'flex',
					flexDirection: 'column',
					overflow: 'hidden',
					alignItems: 'stretch'}}>
				{
					error && <Alert type='error' showIcon={true} description={error} style={{marginTop: 32, marginBottom: 32}} />
				}
				{
					!error && <>
						<ListHeader 
							style={{
							}}
						>
							<div>Organization</div>
							<div>Acres</div>
						</ListHeader>
						{
							availableOrgs?.length === 0 && <Msg>No organizations available to import</Msg>
						}
						{
							(availableOrgs?.length > 0 && filteredOrgs?.length === 0) && <Msg>No organizations match the search</Msg>
						}
						<RadioList className='sectionInner'
						>
							{
								filteredOrgs && filteredOrgs.map(o => <Radio 
									checked={selectedSourceOrganizationId === o.BaseId}
									onClick={() => setSelectedSourceOrganizationId(o.BaseId)}
									key={o.BaseId} 
									style={{width: '100%'}}
								>
									<div className='label_container'>
										<div className='name_label'>{o.Name}</div>
										<div className='acre_label'>{o.TotalArea.toFixed(0)}</div>
									</div>
								</Radio>)
							}
						</RadioList>
					</>
				}
				
			</div>
			{
				publishError && <Alert type='error' showIcon={true} description={publishError} style={{marginTop: 32, marginBottom: 32}} />
			}
			<div style={{display: 'flex', flexDirection: 'column', padding: 10, paddingRight: 20, marginTop: 6 }}>
				<div style={{marginLeft: 'auto', flexDirection: 'row'}}>
					<Button
						style={{ width:100, height:32, padding: 0, fontSize: 14, fontWeight: 700 }}
						variant='outlined'
						className='bulkFieldEditButton'
						onClick={cancelCropwiseImportSession}
					>
						Cancel
					</Button>
					<Button
						style={{ width:100, height:32, padding: 0, fontSize: 14, fontWeight: 700, marginLeft: 10 }}
						variant='main'
						className='bulkEditNamesButton'
						disabled= {!selectedSourceOrganizationId}
						onClick={onImport}
					>
						Select
					</Button>
				</div>
			</div>
			<CoveringLoader className={(isLoading || isPublishing) ? 'loading' : ''}  style={{backgroundColor: '#ddda'}}>
				{isLoading && <ScaleLoader color={theme.colors.primary} loading={isLoading} /> }
				{isPublishing && <ProgressSpinner percent={progress}>{progress == 0 ? 'Fetching data from Cropwise...' : 'Importing...'}</ProgressSpinner>}
			</CoveringLoader>
		</div>
		<BulkImportMap setMapRef={setMapRef} setHover={undefined} hoverFarmFieldId={undefined} onSelect={undefined} focusField={undefined} />
	</CropwiseImportContainer>
	;
};

/**
 * Map state to props for the component
 */
const mapStateToProps = (state: RootState) =>
{
	return {
		AuthToken: state.auth.userAuthToken,
		SelectedGrower: state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId),
		SelectedUserId: getCurrentActingUser(state).UserId,
	};
};

/**
 * Map dispatch to props for the component
 */
const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * Export the connected component
 */
export const CropwiseImport = connector(CropwiseImportComponent);
