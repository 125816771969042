import React, { useRef, useState } from 'react';
import { StyledAutoComplete } from '../../../../components/AutoComplete/StyledAutoComplete';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/Trashcan.svg';
import { IconButton } from '../../../../components/IconButton/IconButton';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { InputNumber } from 'antd';
import { ICompetitorObject } from '../../../../logic/Models/Responses/CompetitorResponse';
import { dynamicSort } from '../../../../logic/Utility/Utils';
import { cornId, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { RoundWithPrecision } from '../../../../logic/Utility/CalculationUtilities';

export interface ICompetitorSeedItem
{
	Brand: string; // The Brand Name
	Product: string; // The Product Name
	HybridId?: string; // If this is an existing product, it will have a hybrid id like 'Brand_Product_RM'
	RM?: number; // The Relative Maturity of the Product
	IsNew: boolean; // Whether or not this is a pre-existing competitor seed from the order plan, meaning it is assigned to at least one field
	HasError: boolean; // Whether or not there is an error with this item
}

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
`;

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: ${themeGet('fontSizes.normal')};
	margin-right: -2px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;
export interface ICompetitorRowProps
{
	competitorBrandsAndProducts: ICompetitorObject[]; // competitor brands and products from the redux store
	competitorSeed: ICompetitorSeedItem;
	cropId: string;
	cropRmMax: number;
	cropRmMin: number;
	index: number;
	addRefToParentList: (ref: React.MutableRefObject<HTMLDivElement>, index: number) => void;
	deleteRow: (index: number) => void;
	updateRow: (index: number, brand: string, product: string, rm?: number) => void;
}

export const CompetitorSeedRow = (props: ICompetitorRowProps) =>
{
	const { competitorBrandsAndProducts, competitorSeed, cropId, cropRmMax, cropRmMin, index, addRefToParentList, deleteRow, updateRow } = props;

	const theme = useTheme();

	const [brandOptions, setBrandOptions] = useState<{ key: string, value: string }[]>([]);
	const [productOpbtions, setProductOptions] = useState<{ key: string, value: string }[]>([]);

	const onDelete = () =>
	{
		deleteRow(index);
	};

	const onChangeBrandName = (value: string) =>
	{
		updateRow(index, value, competitorSeed.Product, competitorSeed.RM);

		// Update the product auto-complete list if this brand exists in the auto-complete list already
		if (competitorBrandsAndProducts.length > 0 && competitorBrandsAndProducts.find(b => b.BrandName.toLowerCase() === value.toLowerCase()))
		{
			setProductOptions(competitorBrandsAndProducts
				.find(b => b.BrandName.toLowerCase() === value.toLowerCase())?.Products
				.sort(dynamicSort('ProductName')).map(p => ({ key: p.ProductName, value: p.ProductName})));
		}
	};

	const onSearchBrand = (searchTerm: string) =>
	{
		setBrandOptions(competitorBrandsAndProducts?.filter(b => b.BrandName.toLowerCase().startsWith(searchTerm.toLowerCase()))
			.sort(dynamicSort('BrandName')).map(b => ({ key: b.BrandName, value: b.BrandName })));
	};

	const onChangeProductName = (value: string) =>
	{
		updateRow(index, competitorSeed.Brand, value, competitorSeed.RM);
		const foundProduct = competitorBrandsAndProducts
			.find(b => b.BrandName.toLowerCase() === competitorSeed.Brand.toLowerCase())?.Products.find(p => p.ProductName.toLowerCase() === value.toLowerCase());
		if (foundProduct && foundProduct.RelativeMaturity)
		{
			onChangeRm(Number.parseFloat(foundProduct.RelativeMaturity));
		}
	};

	const onSearchProducts = (searchTerm: string) =>
	{
		if (competitorSeed.Brand)
		{
			setProductOptions(
				competitorBrandsAndProducts
					.find(b => b.BrandName.toLowerCase() === competitorSeed.Brand.toLowerCase())?.Products
					.filter(p => p.ProductName.toLowerCase().includes(searchTerm.toLowerCase()))
					.sort(dynamicSort('ProductName')).map(p => ({ key: p.ProductName, value: p.ProductName})));
		}
		else
		{
			setProductOptions(competitorBrandsAndProducts?.flatMap(cp => cp.Products)
				.filter(p => p.ProductName.toLowerCase().includes(searchTerm.toLowerCase()))
				.sort(dynamicSort('ProductName')).map(p => ({ key: p.ProductName, value: p.ProductName})));
		}
	};

	const onChangeRm = (value: number) =>
	{
		// No decimals allowed if this is a corn RM -- truncate the decimal
		if (cropId == cornId)
		{
			value = Math.floor(value);
		}
		else
		{
			// Only one decimal place for soybean
			value = RoundWithPrecision(value, 1, 1);
		}

		if (value < cropRmMin)
		{
			value = cropRmMin;
		}
		if (value > cropRmMax)
		{
			value = cropRmMax;
		}
		updateRow(index, competitorSeed.Brand, competitorSeed.Product, value);
	};

	const ref = useRef<HTMLDivElement>();
	addRefToParentList(ref, index);

	return (
		<div
			ref={ref}
			style={{
				display: 'flex',
				flexDirection: 'row',
				borderWidth: competitorSeed.HasError ? 4 : 1,
				padding: 10,
				borderColor: competitorSeed.HasError ? theme.colors.redDM : theme.colors.lightestGrey,
				borderRadius: 4, 
				borderStyle: 'solid',
				marginRight: 5,
				marginBottom: 2
			}}>
			<StyledAutoComplete
				className='Brand_AutoComplete'
				popupClassName='Brand_AutoComplete_Dropdown'
				options={brandOptions}
				style={{ marginRight: 10, width: 225, fontSize: theme.fontSizes.normal }}
				placeholder='Enter Brand...'
				variant={'outlined'}
				defaultValue={competitorSeed.Brand}
				value={competitorSeed.Brand}
				onChange={(value: string) => onChangeBrandName(value)}
				onSelect={(value: string) => onChangeBrandName(value)}
				onSearch={(value: string) => onSearchBrand(value)}
			/>
			<StyledAutoComplete
				className='Product_AutoComplete'
				popupClassName='Product_AutoComplete_Dropdown'
				options={productOpbtions}
				style={{ marginRight: 20, width: 225, fontSize: theme.fontSizes.normal }}
				placeholder='Enter Product...'
				variant={'outlined'}
				defaultValue={competitorSeed.Product}
				value={competitorSeed.Product}
				onChange={(value: string) => onChangeProductName(value)}
				onSelect={(value: string) => onChangeProductName(value)}
				onSearch={(value: string) => onSearchProducts(value)}
			/>
			<div style={{ marginRight: 20, borderLeftWidth: 2, borderRightWidth: 0, borderColor: theme.colors.lightestGrey, borderStyle: 'solid' }} />
			<div style={{ display: 'flex', alignItems: 'center', marginRight: 25 }}>
				<div style={{ marginRight: 5, fontSize: theme.fontSizes.large }}>RM</div>
				<NumberInput
					style={{ width: 55 }}
					className='RelativeMaturity'
					value={competitorSeed.RM !== undefined ? competitorSeed.RM : ''}
					step={cropId === soyId ? 0.1 : 1}
					status={!competitorSeed.RM ? 'error' : undefined}
					min={cropRmMin}
					max={cropRmMax}
					onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
						onChangeRm(evt.currentTarget.value ? Number(evt.currentTarget.value) : competitorSeed.RM)}
					onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => 
						onChangeRm(evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value) : competitorSeed.RM)}
					onChange={onChangeRm}
				/>
			</div>
			<div style={{ marginRight: 10, borderLeftWidth: 2, borderRightWidth: 0, borderColor: theme.colors.lightestGrey, borderStyle: 'solid' }} />
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
				<StyledIconButton className='delete-button' onClick={onDelete}
					tooltip='Delete'
					placement='right'
					fillcolor={theme.colors.darkGrey}
					hoverbgcolor={theme.colors.lightestGrey}>
					<DeleteIcon />
				</StyledIconButton>
			</div>
		</div>
	);
};