import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { SortType, TableHeader } from '../../../components/Table/TableHeader';
import { TableRow } from '../../../components/Table/TableRow';
import { TableRowType } from '../../../components/Table/TableRows';
import { IFarmResponse } from '../../../logic/Models/Responses/FarmResponse';
import { UISort } from '../../../logic/store/UI/UISlice';
import { dynamicSort, SortDirection } from '../../../logic/Utility/Utils';
import { IManageFieldsRowItem, ManageFieldsRows } from './ManageFieldsRow';
import { ReactComponent as CornIcon } from '../Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from '../Icons/SoyIcon.svg';
import { ReactComponent as UnknownCrop } from '../Icons/PlantIcon.svg';
import { cornId, soyId, unknownId } from '../../../logic/store/Seeds/CropsSlice';
import { IUpdateFieldCropRequest } from '../../../logic/store/Grower/FieldCropThunks';
import { AssignmentSource, AssignmentType, IUpdateMultipleCrop } from '../../../logic/Models/Requests/SourceUpdateTypes';
import { ConvertCropNameToId } from '../../../logic/Utility/CalculationUtilities';
import { themeGet } from '@styled-system/theme-get';

const ManageFieldsContainer = styled.div`
	padding-left: 17px;
	padding-right: 25px;
	padding-top: 20px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

const ButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-left: auto;
	margin-top: auto;
	padding-top: 10px;
	padding-bottom: 10px;
`;

const StyledTableHeader = styled(TableHeader)`
	position: sticky;
	padding-left: 16px;
	top: 0;	
`;

const StyledTableRow = styled(TableRow)`
	padding: 0 1.5%;
	height: 32px;
`;

const CropAcreTotal = styled.div`
	display: flex;
	align-items: center;
	font-weight: 800;
`;

const StyledCornIcon = styled(CornIcon)`
	width: 20px;
	height: 20px;
	margin-right: 8px;
	path {
		stroke: ${themeGet('colors.secondary')};
	}
`;

const StyledSoyIcon = styled(SoyIcon)`
	width: 20px;
	height: 20px;
	margin-right: 8px;
	path {
		stroke: ${themeGet('colors.blueLM')};
	}
`;

const StyledUnknownIcon = styled(UnknownCrop)`
	width: 20px;
	height: 20px;
	margin-right: 8px;
	path {
		stroke: ${themeGet('colors.lightGrey')};
	}
`;

interface IManageFieldsViewProps
{
	farmAndFields: IFarmResponse[];
	fieldYear: string;
	growerId: string;
	returnToCropPlanList: () => void;
	bulkUpdateCropForFields: (request: IUpdateFieldCropRequest) => any;
}

export const ManageFieldsView = (props: IManageFieldsViewProps) =>
{
	const { farmAndFields, fieldYear, growerId, bulkUpdateCropForFields, returnToCropPlanList } = props;

	const theme = useTheme();

	const [farmAndFieldRows, setFarmAndFieldRows] = useState<IManageFieldsRowItem[]>([]);
	const [fieldSort, setFieldSort] = useState<UISort<IManageFieldsRowItem>>({ field: 'FieldName', sortDirection: 'ascending' });
	const [cornAcresTotal, setCornAcresTotal] = useState<number>(0);
	const [soyAcresTotal, setSoyAcresTotal] = useState<number>(0);
	const [unknownAcresTotal, setUnknownAcresTotal] = useState<number>(0);
	const [changedFieldCropList, setChangedFieldCropList] = useState<{[key: string]: string[]}>({}); // Key is CropId with a list of FieldIds

	const headers = ['Field Name', 'Farm', 'Crop'];
	const headerSpacing = '39% 28% 33%';
	const rowSpacing = '33% 33% 33%';

	const headersToKeys: { [key: string]: keyof IManageFieldsRowItem | undefined; } =
	{
		'Field Name': 'FieldName',
		'Farm': 'FarmName'
	};
	type ManageFieldsRowItemKeys = keyof IManageFieldsRowItem;
	const keysToHeaders: Partial<{ [key in ManageFieldsRowItemKeys]: string }> =
	{
		'FieldName': 'Field Name',
		'FarmName': 'Farm'
	};

	useEffect(() =>
	{
		if (farmAndFieldRows)
		{
			const sortFields = dynamicSort(fieldSort.field, fieldSort.sortDirection as SortDirection);
			const sortedFarmsAndFields = [...farmAndFieldRows];
			if (fieldSort.sortDirection !== 'none') 
			{
				sortedFarmsAndFields.sort(sortFields);
			}
			setFarmAndFieldRows(sortedFarmsAndFields);
		}
		
	}, [fieldSort]);


	useEffect(() => 
	{
		let cornAcres = 0, soyAcres = 0, unknownAcres = 0;

		if (farmAndFields)
		{
			const farmAndFieldItems: IManageFieldsRowItem[] = [];
			farmAndFields.forEach(farm =>
			{
				farm.Fields.forEach(field =>
				{
					let cropId:string = null;
					switch(field.CurrentCrop?.toLocaleLowerCase())
					{
						case 'corn':
							cropId = cornId;
							break;
						case 'soybean':
							cropId = soyId;
							break;
						default: // This is the unknown crop case
							cropId = unknownId;
							break;
					}

					const fieldRowItem: IManageFieldsRowItem = 
					{
						Id: field.Id,
						FieldName: field.Name,
						FarmId: farm.Id,
						FarmName: farm.Name,
						CropId: cropId,
					};
					farmAndFieldItems.push(fieldRowItem);

					// Tabulate acreage totals
					if (field.CurrentCrop?.toLowerCase() === 'corn')
						cornAcres += field.Area;
					else if (field.CurrentCrop?.toLowerCase() === 'soybean')
						soyAcres += field.Area;
					else
						unknownAcres += field.Area;
				});
			});

			setFarmAndFieldRows(farmAndFieldItems);
			setFieldSort({...fieldSort});
		}

		setCornAcresTotal(cornAcres);
		setSoyAcresTotal(soyAcres);
		setUnknownAcresTotal(unknownAcres);
	}, [farmAndFields]);

	const addFieldToCropList = (fieldId: string, cropId: string) =>
	{
		if (farmAndFieldRows)
		{
			const chosenFieldIndex = farmAndFieldRows.findIndex(f => f.Id === fieldId);
			const chosenRowField = farmAndFieldRows[chosenFieldIndex];
			const allFields = farmAndFields.flatMap(farm => farm.Fields);
			const actualField = allFields.find(f => f.Id === fieldId);
			const actualFieldCrop = ConvertCropNameToId(actualField.CurrentCrop);
	
			const fieldsToUpdate = changedFieldCropList;
	
			// If we are changing the crop for a specific field, add it to the dictionary
			// We don't need to add a field if it is already the current crop
			if (chosenRowField.CropId !== cropId)
			{
				// If this field's crop value is being changed back to the actual field value, remove it from the list
				if (actualFieldCrop === cropId)
				{
					fieldsToUpdate[chosenRowField.CropId] = fieldsToUpdate[chosenRowField.CropId].filter(Id => Id !== fieldId);
					chosenRowField.CropId = cropId;
					setChangedFieldCropList({...fieldsToUpdate});
					setFarmAndFieldRows(farmAndFieldRows);
				}
				else
				{
					// Add the field to the dictionary
					chosenRowField.CropId = cropId;
					fieldsToUpdate[cropId] ? fieldsToUpdate[cropId].push(fieldId) : fieldsToUpdate[cropId] = [fieldId];
					setChangedFieldCropList({...fieldsToUpdate});
					setFarmAndFieldRows(farmAndFieldRows);
				}
			}
		}
	};

	const onSort = (sort: SortType, header: string) =>
	{
		setFieldSort({ field: headersToKeys[header], sortDirection: sort });
	};

	const onBulkUpdate = () =>
	{
		const bulkUpdateCrop = Object.keys(changedFieldCropList).map(cropId =>
		{
			const updateCrop: IUpdateMultipleCrop =
			{
				FieldIds: changedFieldCropList[cropId],
				CropId: cropId,
				Type: AssignmentType.Prescribed,
				SourceType: AssignmentSource.Manual,
				SourceName: 'MaxScript',
			};

			return updateCrop;
		});
		const request: IUpdateFieldCropRequest =
		{
			BulkUpdateCrop: bulkUpdateCrop,
			FieldYear: fieldYear,
			RequestedGrowerId: growerId,			
		};
		setChangedFieldCropList({});
		bulkUpdateCropForFields(request);
	};

	const isSaveButtonDisabled = useCallback((): boolean =>
	{
		let disabled = true;

		if (Object.values(changedFieldCropList).flatMap(fieldList => fieldList).length > 0)
		{
			disabled = false;
		}

		return disabled;
	}, [changedFieldCropList]);

	return (
		<ManageFieldsContainer className='MaxScript_TabButtonContainer'>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
				<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal }}>Field List</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', width:'75%', marginBottom: 6 }}>
				<CropAcreTotal><StyledCornIcon />{Math.round(cornAcresTotal)} ac</CropAcreTotal>
				<CropAcreTotal><StyledSoyIcon />{Math.round(soyAcresTotal)} ac</CropAcreTotal>
				<CropAcreTotal><StyledUnknownIcon />{Math.round(unknownAcresTotal)} ac</CropAcreTotal>
			</div>
			<div style={{ overflowY: 'auto' }}>
				<StyledTableHeader
					headers={headers}
					columnSpacing={headerSpacing}
					headerHeight={'24px'}
					backgroundColor={'black'}
					textColor={'white'}
					initialSorts={{ [keysToHeaders[fieldSort.field]]: fieldSort.sortDirection }}
					sortActions={{
						'Field Name': (order) => onSort(order, 'Field Name'),
						'Farm': (order) => onSort(order, 'Farm')
					}}
					defaultSort={{ headerName: 'Field Name', direction: 'ascending' }}
					defaultArrowColor={theme.colors.white}
				/>
				<div style={{ paddingTop: 10 }}>
					{
						!!farmAndFieldRows &&
						ManageFieldsRows({
							data: farmAndFieldRows,
							addFieldToCropList: addFieldToCropList,
						}).map(
							(row: TableRowType<IManageFieldsRowItem>) =>
								<StyledTableRow
									key={`managedFields-id-${row.data.Id}`}
									className={`managedFields-id-${row.data.Id}`}
									Id={row.data.Id}
									items={row.items}
									columnSpacing={rowSpacing}
									hideExpand
								/>
						)}
				</div>
			</div>
			<ButtonRow>
				<Button
					style={{ height: 30, width: 100, marginRight: 15 }}
					variant='outlined'
					onClick={returnToCropPlanList}
				>
					Cancel
				</Button>
				<Button
					style={{ height: 30, width: 126 }}
					variant='dark'
					onClick={() => onBulkUpdate()}
					disabled={isSaveButtonDisabled()}
				>
					Save & Update
				</Button>
			</ButtonRow>
		</ManageFieldsContainer>
	);
};