import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as GrowerLandscape } from './Icons/GrowerLandscape.svg';
import { themeGet } from '@styled-system/theme-get';
import { IconButton } from '../../components/IconButton/IconButton';
import { DropdownTab } from '../../components/DropdownTab/DropdownTab';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { SortType } from '../../components/Table/TableHeader';
import { headersToKeys } from '../Dashboard/DashboardTable/DashboardRows';
import { PlanDashboardTable } from '../Dashboard/DashboardTable/PlanDashboardTable';
import { ExecuteDashboardTable } from '../Dashboard/DashboardTable/ExecuteDashboardTable';
import { getGrowers } from '../../logic/store/Grower/GrowerThunks';
import { InfoPanel } from '../../components/InfoPanel/InfoPanel';
import { featureFlagSelector, Features } from '../../logic/store/User/ConfigSlice';
import {
	BoolDictionary,
	clearExpandedState,
	currentEnogenFlowUrl,
	setDashboardSort,
	setDashboardExpanded,
	UISort,
	displayEnogenFlow,
	hideEnogenFlow,
	ThemeName,
	setSelectedEnogenContractId,
	setSelectedDashboardTab,
	setSelectedYear,
	clearSelectedYearState,
} from '../../logic/store/UI/UISlice';
import { dynamicSort, makeDispatch, SortDirection } from '../../logic/Utility/Utils';
import { authSelector, canManageAccounts, hasEffectivePermission, getCurrentActingUser, getManageableUsers, isManagingAccount } from '../../logic/store/User/AuthSlice';
import { ConnectedManageAccountDropdown } from './ManageAccounts/ManageAccountDropdown';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { GrowerFilterModal, GrowerSegmentNames, IGrowerFilter } from './GrowerFilterModal';
import { IGrowerResponse } from '../../logic/Models/Responses/GrowerResponse';
import { clearSelectedProductPlanId } from '../../logic/store/Plans/ProductGamePlanSlice';
import { IFrameModal } from '../../components/IFrameModal/IFrameModal';
import { downloadGrowerFieldHealthPdf } from '../../logic/store/User/FieldHealthThunks';
import { getFeatureFlag } from '../../logic/store/User/ConfigSlice';
import { selectGrowerAndPullSeeds } from '../../logic/store/UI/UIThunks';
import { UserPermission } from '../../logic/Models/Responses/UserPermissions';
import { getSellerEnogenContracts, IResendForSigningRequest, resendForSigning } from '../../logic/store/Grower/EnogenContractThunk';
import { EnogenDashboardTable } from './DashboardTable/EnogenDashboardTable';
import { IGrowerWithContractDetails } from '../../logic/Models/Responses/EnogenContractResponse';
import { EnogenContractResendModal } from './DashboardTable/EnogenContractResendModal';
import { setCropYear } from '../../logic/store/Seeds/CropsSlice';
import { updateGrowerInfo } from '../../logic/store/Grower/GrowerThunks';
import { createInvitationAndGetQrCodeWithLink } from '../../logic/store/Grower/JdConnectThunks';
import { JdConnectInvitationModal } from './DashboardTable/JdConnectInvitationModal';
import { clearJdConnectValues } from '../../logic/store/Grower/GrowerSlice';

const StyledGrowerContainer = styled.div`
	display: flex;
	height: 100%;
	padding: 2.5% 5%;
	margin: 0 auto;
	flex-direction: column;
`;

const NoGrowersSection = styled.div`
	display: flex;
	flex-direction: column;
	margin: 100px auto;
	align-items: center;
`;

const DashboardBody = styled.div`
	margin-top: 15px;
	overflow: hidden;
	height: 95%;
`;

const StyledBoldSections = styled.div`
	font-weight: ${themeGet('fontWeights.bold')};
	text-align: center;
`;

const StyledIconButton = styled(IconButton)`
	width: 44px;
	height: 44px;
	border-radius: 22px;
	padding-top: 8px;

	:hover {
		background-color: ${themeGet('colors.lightestGrey')} !important;
	}
`;

const GrowerInputContainer = styled.div`
	height: 40px;
	width: 25vw;
	margin-right: 1%;
	background-color: ${themeGet('colors.disabledGrey')} !important;
	border: none;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const GrowerSearchInput = styled.input`
	height: 100%;
	flex-grow: 30;
	padding-left: 16px;
	background-color: inherit;
	border: none;
	border-radius: 4px;
	outline: none;
	color: ${themeGet('colors.darkGrey')};
	font-size: ${themeGet('fontSizes.small')};
`;

const ClearGrowerTextButton = styled.button`
	flex-grow: 1;
	border: none;
	color: ${themeGet('colors.darkGrey')};
	text-decoration: underline;
	font-size: ${themeGet('fontSizes.extraSmall')};
	font-weight: ${themeGet('fontWeights.bold')};
	cursor: pointer;
`;

const MinifiedSearchText = styled.div`
	color: ${themeGet('colors.lightGrey')};
	border: 1px solid ${themeGet('colors.lightGrey')};
	border-radius: 10px;
    padding: 5px 15px;
    margin-right: 10px;
	cursor: pointer;
`;

const StyledGrowerLandscape = styled(GrowerLandscape)`width: 100%;`;

const getInitialSelectedTab = (year: string, userPreSelectedTab: TabType): TabType =>
{
	return userPreSelectedTab;

	// 2024.08.07 - ELM - Leaving this here in case we ever want this date insanity back...which
	// we weren't even using to begin with...
	
	/* const today = new Date();
	let tab: TabType = 'Plan';	// Plan by default
	const yearNum = Number.parseInt(year);

	// August 1, 20n-1 to December 31, 20n-1 = Plan
	const aug1stLastCropYear = new Date(yearNum - 1, 7, 1);
	const dec31stLastCropYear = new Date(yearNum - 1, 11, 31);
	if (today > aug1stLastCropYear && today < dec31stLastCropYear)
	{
		tab = 'Plan';
	} */
	/*
	// January 1, 20n - March 31, 20n = Order
	const jan1stThisCropYear = new Date(yearNum, 0, 1);
	const mar31stThisCropYear = new Date(yearNum, 2, 31);
	if (today > jan1stThisCropYear && today < mar31stThisCropYear)
	{
		tab = 'Order';
	}

	// April 1, 20n - July 31, 20n = Execute
	const apr1stThisCropYear = new Date(yearNum, 3, 1);
	const jul31stThisCropYear = new Date(yearNum, 6, 31);
	if (today > apr1stThisCropYear && today < jul31stThisCropYear)
	{
		tab = 'Execute';
	}
	*/
	//return tab;
};

const getInitialSelectedYears = (year: string, userPreSelectedYear: string, userPreSelectedTab: TabType): ITabYears =>
{
	const today = new Date();
	const years: ITabYears = // Given crop year for all tabs by default
	{
		Plan: year,
		Execute: year,
		Enogen: year,
	};

	// If there is a pre-selected year for Enogen, set it
	if (userPreSelectedYear)
	{
		if (userPreSelectedTab === 'Enogen')
		{
			years.Enogen = userPreSelectedYear;
		}
	}

	const yearNum = Number.parseInt(year);

	// August 1, 20n-1 to December 31, 20n-1 = Plan is n, Order is n, Execute is n-1
	const aug1stLastCropYear = new Date(yearNum - 1, 7, 1);
	const dec31stLastCropYear = new Date(yearNum - 1, 11, 31);
	if (today > aug1stLastCropYear && today < dec31stLastCropYear)
	{
		years.Execute = (yearNum - 1).toString();
	}

	// January 1, 20n - March 31, 20n = Plan is n, Order is n, Execute is n-1
	const jan1stThisCropYear = new Date(yearNum, 0, 1);
	const mar31stThisCropYear = new Date(yearNum, 2, 31);
	if (today > jan1stThisCropYear && today < mar31stThisCropYear)
	{
		years.Execute = (yearNum - 1).toString();
	}

	// April 1, 20n - July 31, 20n = Plan is n, Order is n, Execute is n
	const apr1stThisCropYear = new Date(yearNum, 3, 1);
	const jul31stThisCropYear = new Date(yearNum, 6, 31);
	if (today > apr1stThisCropYear && today < jul31stThisCropYear)
	{
		// Don't need to do anything, use default values
	}

	return years;
};

export type TabType = 'Plan' | 'Execute' | 'Enogen';

interface ITabYears
{
	Plan: string;
	Execute: string;
	Enogen: string;
}

interface IDashboardProps extends PropsFromRedux, RouteComponentProps
{
	changeTheme: (name: ThemeName) => void;
}

const DashboardComponent = (props: IDashboardProps) =>
{
	// Props and input
	const {
		AuthenticationToken,
		CanManageAccounts,
		CurrentActingUser,
		CurrentCropYear,
		OrganizationalCropYears,
		CurrentCropYearError,
		CurrentTheme,
		EnogenContracts,
		DashboardSort,
		DashboardTableExpanded,
		EnogenFlowUrl,
		EnogenRootUrl,
		FeatureFlags,
		GrowerError,
		Growers,
		IsCurrentCropYearError,
		IsCurrentCropYearLoading,
		IsGrowerError,
		IsGrowerLoading,
		IsLoadingEnogenContracts,
		IsManagingAccount,
		UiDashboardSelectedTab,
		UiDashboardSelectedYear,
		UserCanCreateGrowers,
		UserCanSeeEnogen,
		UserCanSeeEnogenTheme,
		UserId,
		UserIsGrower,
		UserName,
		changeTheme,
		ClearExpandedState,
		ClearSelectedProductPlanId,
		DisplayEnogenFlow,
		DownloadGrowerCropHealth,
		GetEnogenContracts,
		GetManageableUsers,
		HideEnogenFlowFrame,
		SetDashboardExpanded,
		SetDashboardSort,
		SetSelectedGrowerId,
		SetSelectedEnogenContractId,
		SendContractForResigning,
		SetSelectedDashboardTabForUI,
		SetSelectedUiYear,
		ClearSelectedDashboardYear,
		SetUserOrgCropYear,
		UpdateGrower,
		CreateJdConnectInvitation,
		ClearGrowerJdConnectValues,
	} = props;

	const theme = useTheme();

	// State
	const [selectedTab, setSelectedTab] = useState<TabType>(CurrentCropYear ? getInitialSelectedTab(CurrentCropYear, UiDashboardSelectedTab) : 'Plan');
	const [selectedYears, setSelectedYears] = useState<ITabYears>(CurrentCropYear ? getInitialSelectedYears(CurrentCropYear, UiDashboardSelectedYear, UiDashboardSelectedTab) : () =>
	{
		const thisYear = new Date().getFullYear().toString();
		return ({
			Plan: thisYear,
			Order: thisYear,
			Execute: thisYear,
			Enogen: thisYear,
		});
	});
	const [cropYearOptions, setCropYearOptions] = useState<string[]>([]);
	const [isGrowerSearchActive, setIsGrowerSearchActive] = useState(false);
	const [isGrowerInputCollapsed, setIsGrowerInputCollapsed] = useState(true);
	const [growerSearchText, setGrowerSearchText] = useState<string>('');
	const [visibleGrowers, setVisibleGrowers] = useState(Growers);
	const [isGrowerPlanFilterModalVisible, setIsGrowerPlanFilterModalVisible] = useState(false);
	const [growerFilter, setGrowerFilter] = useState<IGrowerFilter>(null);
	const [isSelectedCropYearOld, setIsSelectedCropYearOld] = useState(false);

	// Growers with Enogen Contracts
	const [growersWithEnogenContracts, setGrowersWithEnogenContracts] = useState<IGrowerWithContractDetails[]>([]);
	const [resendForSigningModalVisible, setResendForSigningModalVisible] = useState<boolean>(false);
	const [resendGrowerId, setResendGrowerId] = useState<string>(undefined);
	const [resendContractId, setResendContractId] = useState<string>(undefined);

	// JdConnect Modal
	const [jdConnectInvitationModalVisible, setJdConnectInvitationModalVisible] = useState<boolean>(false);
	const [jdConnectGrowerId, setJdConnectGrowerId] = useState<string>(undefined);

	const resendContract = useMemo(() =>
	{
		if (resendContractId && EnogenContracts && EnogenContracts.length > 0)
		{
			return EnogenContracts.find(c => c.ContractId === resendContractId);
		}

		return undefined;
	},[resendContractId, EnogenContracts]);

	// Refs
	const growerSearchInputRef = useRef<HTMLInputElement>(null);

	// Hints and text
	const showSalesRepHint = (!IsManagingAccount && CanManageAccounts && !Growers.length && !IsGrowerLoading && !IsGrowerError);
	const showSeedAdvisorHint = (!showSalesRepHint && !Growers.length && !IsGrowerLoading && !IsGrowerError);

	// Filtering
	const isThereGrowerFilter = !!growerFilter && (
		growerFilter.segmentValues?.length || growerFilter.enogenContractDate || growerFilter.acresMin || growerFilter.acresMax
	);

	// Sorting
	const { field, sortDirection } = DashboardSort;

	const onSort = (sort: SortType, header: string) =>
	{
		SetDashboardSort({ field: headersToKeys[header], sortDirection: sort });
	};

	// Fetch all growers after changing the selected seed advisor
	const { fetchGrowers } = useRefetchGrowers(cropYearOptions);

	useEffect(() =>
	{
		// Make sure the enogen frame is closed on start
		HideEnogenFlowFrame();

		GetManageableUsers();
	}, []);

	useEffect(() =>
	{
		if (!CurrentCropYear)
		{
			return;
		}

		setSelectedTab(getInitialSelectedTab(CurrentCropYear, UiDashboardSelectedTab));
		setSelectedYears(getInitialSelectedYears(CurrentCropYear, UiDashboardSelectedYear, UiDashboardSelectedTab));
		// Previous crop year and current
		setCropYearOptions([(Number.parseInt(CurrentCropYear) - 1).toString(), CurrentCropYear]);
		setIsSelectedCropYearOld(Number.parseInt(selectedYears[selectedTab]) < Number.parseInt(CurrentCropYear));
	}, [CurrentCropYear]);

	useEffect(() =>
	{
		if (UserCanSeeEnogenTheme)
		{
			if (CurrentTheme !== ThemeName.Enogen)
			{
				changeTheme(ThemeName.Enogen);
			}
		}
		else
		{
			if (CurrentTheme !== ThemeName.GHX)
			{
				changeTheme(ThemeName.GHX);
			}
		}
		
		if (OrganizationalCropYears && OrganizationalCropYears.length > 0)
		{
			// Default organization if the user does not have one
			let userOrg = 'ZH-US13';
			if (CurrentActingUser.OrganizationalUnitName)
			{
				userOrg = CurrentActingUser.OrganizationalUnitName;
			}

			const userOrgCropYears = OrganizationalCropYears.find(ocy => ocy.Name.toLowerCase() === userOrg.toLowerCase());
			const currentUserOrgCropYear = Object.values(userOrgCropYears.CropYears)[0].toString();
			if (CurrentCropYear !== currentUserOrgCropYear)
			{
				SetUserOrgCropYear(currentUserOrgCropYear);
				setCropYearOptions([(Number.parseInt(currentUserOrgCropYear) - 1).toString(), currentUserOrgCropYear]);
			}
		}
		
		// Enogen theme OR Enogen Dealer can load contracts
		if (UserCanSeeEnogenTheme || UserCanSeeEnogen)
		{
			if (!IsGrowerLoading && cropYearOptions.length)
			{
				// Get Contracts if the user is changed but not the selected tab
				GetEnogenContracts(selectedYears.Enogen);
			}
		}
		
	}, [CurrentActingUser?.UserId, UserId]);

	const onSelectTabYear = (tabYears: ITabYears) =>
	{
		setSelectedYears(tabYears);
	};

	const onClickSearchIcon = () =>
	{
		const isThereGrowerSearchText = growerSearchText.length;

		// Inactive to active
		if (!isGrowerSearchActive)
		{
			setIsGrowerSearchActive(true);
			setIsGrowerInputCollapsed(false);
			growerSearchInputRef.current.focus();
		}
		// Active to collapsed when not collapsed and there's search text
		else if (isGrowerSearchActive && isThereGrowerSearchText && !isGrowerInputCollapsed)
		{
			setIsGrowerInputCollapsed(true);
		}
		// Collapsed to active when collapsed and there's search text
		else if (isGrowerSearchActive && isThereGrowerSearchText && setIsGrowerInputCollapsed)
		{
			setIsGrowerInputCollapsed(false);
		}
		// Active to inactive when there ISN'T search text
		else if (isGrowerSearchActive && !isThereGrowerSearchText)
		{
			setIsGrowerInputCollapsed(true);
			setIsGrowerSearchActive(false);
		}
	};

	const onClickCloseSearchButton = () =>
	{
		setIsGrowerInputCollapsed(true);

		// Deactivate the search if there's no search text when closed
		if (!growerSearchText.length)
		{
			setIsGrowerSearchActive(false);
		}
	};

	const filterGrower = (grower: IGrowerResponse): boolean =>
	{
		if (growerFilter.segmentValues && !growerFilter.segmentValues.some(
			segment => grower.Classification.map(c => c.toLowerCase()).indexOf((GrowerSegmentNames[segment] as string).toLowerCase()) > -1 || 
			(grower.GoldenAdvantage && `GA: ${grower.GoldenAdvantage}` === GrowerSegmentNames[segment])))
		{
			return false;
		}

		// shabel 7/13 removed EnogenContracted from grower response as well so commenting this out until it's added to backend
		// if (growerFilter.enogenContractDate && !grower.SeasonData?.some(seasonData => seasonData.EnogenContracted < growerFilter.enogenContractDate))
		// 	return false;

		if (growerFilter.acresMin && grower.TotalAcresFarmed < growerFilter.acresMin)
		{
			return false;
		}

		if (growerFilter.acresMax && grower.TotalAcresFarmed > growerFilter.acresMax)
		{
			return false;
		}

		return true;
	};

	const growerSearchFilter = useCallback((grower: IGrowerResponse | IGrowerWithContractDetails) =>
		grower.Name?.toLocaleLowerCase().includes(growerSearchText.toLocaleLowerCase())
		|| grower.FoundationId?.toLocaleLowerCase().includes(growerSearchText.toLocaleLowerCase())
		|| (grower as IGrowerWithContractDetails).NewContractId?.toLocaleLowerCase().includes(growerSearchText.toLocaleLowerCase()), 
	[growerSearchText]);

	const growerSort = dynamicSort(field, sortDirection as SortDirection);

	useEffect(() =>
	{
		let growersToSet = [...Growers];
		if (isThereGrowerFilter)
		{
			growersToSet = growersToSet.filter(filterGrower);
		}
		if (sortDirection !== 'none') 
		{
			growersToSet.sort(growerSort);
		}

		// If we switch to a user that does not have the EnogenTheme/Enogen Dealer permission, swap the tab back to Plan
		if (selectedTab === 'Enogen')
		{
			if (!UserCanSeeEnogenTheme && !UserCanSeeEnogen)
			{
				setSelectedTab('Plan');
			}
		}

		setVisibleGrowers(growersToSet);
	}, [Growers, DashboardSort, growerFilter]);

	useEffect(() =>
	{
		if (selectedTab === 'Enogen')
		{
			if (!IsGrowerLoading && cropYearOptions.length)
			{
				GetEnogenContracts(selectedYears.Enogen);
				SetSelectedUiYear(selectedYears.Enogen);
			}
		}

	},[selectedTab, cropYearOptions, selectedYears]);

	const filteredAndSearchedGrowers = visibleGrowers.filter(growerSearchFilter);

	useEffect(() =>
	{
		if (Growers && EnogenContracts)
		{
			// Build a list of Growers with their Enogen Contracts attached, can have duplicate growers in the list as a grower
			// may have more than one contract
			const growersWithAssociatedContracts: IGrowerWithContractDetails[] = [];

			EnogenContracts.forEach(contract =>
			{
				const foundGrower = Growers.find(g => g.FoundationId === contract.GrowerDetails.FoundationId);
				if (foundGrower && contract.ContractState.toLowerCase() !== 'voided')
				{
					growersWithAssociatedContracts.push({...contract, ...foundGrower});
				}
			});

			growersWithAssociatedContracts.sort(dynamicSort('Name', 'ascending', 'NewContractId'));
			setGrowersWithEnogenContracts(growersWithAssociatedContracts.filter(growerSearchFilter));
		}
	},[Growers, EnogenContracts, growerSearchFilter]);

	const displayEnogenFlowOnClick = useCallback((selectedGrowerId: string, selectedContractId?: string, viewDetails?: boolean) =>
	{
		DisplayEnogenFlow({
			accessToken: AuthenticationToken,
			apiBaseUrl: document.location.host,
			enogenFlowBaseUrl: EnogenRootUrl,
			selectedGrowerId: selectedGrowerId,
			userId: CurrentActingUser.UserId,
			contractId: selectedContractId,
			viewDetails: viewDetails,
		});
	}, [AuthenticationToken, document.location.host, UserId, CurrentActingUser]);

	const downloadGrowerCropHealth = (selectedGrowerId: string) =>
	{
		DownloadGrowerCropHealth({ SelectedGrowerId: selectedGrowerId });
	};

	const openResendForSigningModal = (growerId: string, contractId: string) =>
	{
		setResendForSigningModalVisible(true);
		setResendContractId(contractId);
		setResendGrowerId(growerId);
	};

	const cancelResendForSigning = () =>
	{
		setResendForSigningModalVisible(false);
		setResendContractId(undefined);
		setResendGrowerId(undefined);
	};

	const sendResendForSigning = (request: IResendForSigningRequest) =>
	{
		SendContractForResigning({contractId: resendContractId, requestBody: request});
		cancelResendForSigning();
	};

	const selectTab = (tabType: TabType) =>
	{
		ClearExpandedState();
		setSelectedTab(tabType);
		SetSelectedDashboardTabForUI(tabType);
		ClearSelectedDashboardYear();
	};

	const onOpenJdConnectInvitationModal = (growerId: string) =>
	{
		CreateJdConnectInvitation(growerId);
		setJdConnectInvitationModalVisible(true);
		setJdConnectGrowerId(growerId);
	};

	const closeJdConnectInvitationModal = () =>
	{
		ClearGrowerJdConnectValues({growerId: jdConnectGrowerId });
		setJdConnectInvitationModalVisible(false);
		setJdConnectGrowerId(undefined);
	};

	return (
		<StyledGrowerContainer>
			<div style={{ width: '100%', height: '75px', display: 'flex' }}>
				{CanManageAccounts && <ConnectedManageAccountDropdown changeTheme={changeTheme} />}
				<div style={{ display: UserIsGrower ? 'none' : 'flex', flexDirection: 'column', flexGrow: 1 }}>
					<div style={{ display: 'flex', height: '50%', justifyContent: 'flex-end', alignItems: 'center' }}>
						<GrowerInputContainer style={{ display: isGrowerInputCollapsed ? 'none' : 'flex' }}>
							<GrowerSearchInput
								className='GrowerSearch_Input'
								value={growerSearchText}
								onChange={(e) =>
								{
									setGrowerSearchText(e.target.value);
								}}
								ref={growerSearchInputRef}
								placeholder="Search by Grower's Name"
								style={{ fontWeight: growerSearchText.length ? theme.fontWeights.bold : theme.fontWeights.normal }}
							/>
							<ClearGrowerTextButton
								className='GrowerSearch_ClearText'
								onClick={() =>
								{
									setGrowerSearchText('');
									growerSearchInputRef.current.value = '';
									growerSearchInputRef.current.focus();
								}}
							>
								Clear
							</ClearGrowerTextButton>
							<IconButton
								className='GrowerSearch_Close'
								variant='close'
								onClick={onClickCloseSearchButton}
								style={{ flexGrow: 1, paddingTop: 4 }}
							/>
						</GrowerInputContainer>
						{isGrowerSearchActive && isGrowerInputCollapsed && (
							<MinifiedSearchText
								className='GrowerSearch_MiniText'
								onClick={() =>
								{
									setIsGrowerInputCollapsed(false);
									growerSearchInputRef.current.focus();
								}}
							>
								{growerSearchText}
							</MinifiedSearchText>
						)}
						<div style={{ marginRight: 30 }}>
							<StyledIconButton
								id='grower-search-button'
								variant='magnifyingGlass'
								onClick={onClickSearchIcon}
								color={isGrowerSearchActive ? theme.colors.primary : undefined}
							/>
						</div>
						<div>
							<StyledIconButton
								variant='filter'
								onClick={() => setIsGrowerPlanFilterModalVisible(true)}
								color={isThereGrowerFilter ? theme.colors.primary : undefined}
							/>
						</div>
						<a style={{ display: UserCanCreateGrowers ? 'flex' : 'none', marginLeft: 30 }}
							href='https://syngentaconnect.force.com/CropEdgeGHCommunity/login'
							rel='noreferrer'
							target='_blank'
						>
							<StyledIconButton variant='plus' />
						</a>
					</div>
					<div style={{ height: '50%', display: 'flex', justifyContent: 'flex-end' }}>
						{`Displaying ${filteredAndSearchedGrowers.length}/${Growers?.length} Growers`}
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', zIndex: 1 }}>
				<DropdownTab
					selected={selectedTab === 'Plan'}
					label='Plan'
					onClick={() => selectTab('Plan')}
					containerStyle={{ flexGrow: 1, width: '33.33%', marginRight: '15px' }}
				>
					{selectedTab === 'Plan' &&
						<Dropdown
							options={cropYearOptions}
							selected={selectedYears.Plan}
							onSelect={(year: string) => onSelectTabYear({ ...selectedYears, Plan: year })}
						/>
					}
				</DropdownTab>
				<DropdownTab
					selected={selectedTab === 'Execute'}
					containerStyle={{ flexGrow: 1, width: '33.33%', marginRight: '15px' }}
					label='Execute'
					onClick={() => selectTab('Execute')}
				>
					{selectedTab === 'Execute' &&
						<Dropdown
							options={cropYearOptions}
							selected={selectedYears.Execute}
							onSelect={(year: string) => onSelectTabYear({ ...selectedYears, Execute: year })}
						/>
					}
				</DropdownTab>
				{(UserCanSeeEnogenTheme || UserCanSeeEnogen) &&
					<DropdownTab
						selected={selectedTab === 'Enogen'}
						containerStyle={{ flexGrow: 1, width: '33.33%', }}
						label='Enogen'
						onClick={() => selectTab('Enogen')}
					>
						{selectedTab === 'Enogen' &&
							<Dropdown
								options={cropYearOptions}
								selected={selectedYears.Enogen}
								onSelect={(year: string) => onSelectTabYear({ ...selectedYears, Enogen: year })}
							/>
						}
					</DropdownTab>
				}
			</div>
			{IsGrowerLoading || IsCurrentCropYearLoading && <div id='loading-container' style={{ margin: 'auto' }}>
				Loading...
			</div>}
			{!IsGrowerLoading && IsGrowerError && <div id='error-container' style={{ margin: '3% auto' }}>
				<InfoPanel variant='error'>{GrowerError}</InfoPanel>
			</div>}
			{!IsCurrentCropYearLoading && IsCurrentCropYearError && <div id='error-container' style={{ margin: '3% auto' }}>
				<InfoPanel variant='error'>{CurrentCropYearError}</InfoPanel>
			</div>}
			{!showSalesRepHint && !showSeedAdvisorHint && !IsGrowerLoading && !IsGrowerError && <DashboardBody id='dashboard-body'>
				{
					selectedTab === 'Plan' && <PlanDashboardTable
						cropYear={Number.parseInt(selectedYears.Plan)}
						currentCropYear={Number.parseInt(CurrentCropYear)}
						DashboardSort={DashboardSort}
						DashboardTableExpanded={DashboardTableExpanded}
						onSort={onSort}
						ClearSelectedProductPlanId={ClearSelectedProductPlanId}
						SetSelectedGrowerId={SetSelectedGrowerId}
						SetExpanded={SetDashboardExpanded}
						growers={filteredAndSearchedGrowers}
						displayEnogenFlow={displayEnogenFlowOnClick}
						userCanSeeEnogen={UserCanSeeEnogen}
						userIsGrower={UserIsGrower}
					/>
				}
				{
					selectedTab === 'Execute' && <ExecuteDashboardTable
						cropYear={Number.parseInt(selectedYears.Execute)}
						currentCropYear={Number.parseInt(CurrentCropYear)}
						DashboardSort={DashboardSort}
						DashboardTableExpanded={DashboardTableExpanded}
						onSort={onSort}
						ClearSelectedProductPlanId={ClearSelectedProductPlanId}
						SetSelectedGrowerId={SetSelectedGrowerId}
						SetExpanded={SetDashboardExpanded}
						growers={filteredAndSearchedGrowers}
						downloadGrowerCropHealth={downloadGrowerCropHealth} 
						isDownloadEnabled={getFeatureFlag(FeatureFlags, Features.GrowerCropHealthPdfEnabled)}
						UpdateGrower={UpdateGrower}
						userIsGrower={UserIsGrower}
						isJdConnectEnabled={getFeatureFlag(FeatureFlags, Features.JdConnectEnabled)}
						CreateJdConnectInvitation={onOpenJdConnectInvitationModal}
					/>
				}
				{
					selectedTab === 'Enogen' && 
					<EnogenDashboardTable
						cropYear={Number.parseInt(selectedYears.Enogen)}
						currentCropYear={Number.parseInt(CurrentCropYear)}
						displayEnogenFlow={displayEnogenFlowOnClick}
						DashboardSort={DashboardSort}
						DashboardTableExpanded={DashboardTableExpanded}
						onSort={onSort}
						ClearSelectedProductPlanId={ClearSelectedProductPlanId}
						SetSelectedGrowerId={SetSelectedGrowerId}
						SetSelectedEnogenContractId={SetSelectedEnogenContractId}
						SetExpanded={SetDashboardExpanded}
						growers={growersWithEnogenContracts}
						contractsLoading={IsLoadingEnogenContracts}
						openResendForSigningModal={openResendForSigningModal}
						userIsGrower={UserIsGrower}
					/>
				}
			</DashboardBody>}
			{showSalesRepHint && <NoGrowersSection>
				<StyledBoldSections style={{ fontSize: theme.fontSizes.large }}>Hello, {UserName}</StyledBoldSections>
				<div style={{ width: '100%' }}>
					<StyledGrowerLandscape />
				</div>
				<StyledBoldSections>
					As a Sales Rep, you do not have a direct access to grower accounts. You must select a Seed Advisor in your territory
					in order to see grower accounts, recommendations, and quotes.
				</StyledBoldSections>
			</NoGrowersSection>}
			{showSeedAdvisorHint && <NoGrowersSection>
				<StyledBoldSections style={{ fontSize: theme.fontSizes.large }}>Hello, {CurrentActingUser?.UserName}</StyledBoldSections>
				<div style={{ width: '100%' }}>
					<StyledGrowerLandscape />
				</div>
				<StyledBoldSections>
					You have no growers accounts available.  Please add some grower accounts to CropEdge to view them here.
				</StyledBoldSections>
			</NoGrowersSection>}
			<GrowerFilterModal
				open={isGrowerPlanFilterModalVisible}
				onCancel={() => setIsGrowerPlanFilterModalVisible(false)}
				onApply={(filters: IGrowerFilter) => setGrowerFilter(filters)}
			/>
			<IFrameModal title="Enogen Contracting" width={1200} open={!!EnogenFlowUrl}
				onCancel={() => HideEnogenFlowFrame()}
				footer={null}>
				<iframe src={EnogenFlowUrl} />
			</IFrameModal>
			<EnogenContractResendModal
				resendContract={resendContract}
				visible={resendForSigningModalVisible}
				onCancel={cancelResendForSigning}
				onSave={sendResendForSigning}
			/>
			<JdConnectInvitationModal
				qrCode={Growers?.find(g => g.Id === jdConnectGrowerId)?.JdConnectInvitationQrCode}
				link={Growers?.find(g => g.Id === jdConnectGrowerId)?.JdConnectInvitationLink}
				visible={jdConnectInvitationModalVisible}
				onCancel={closeJdConnectInvitationModal}
				isLoading={IsGrowerLoading}
			/>
		</StyledGrowerContainer>
	);
};

const getInitialGrowers = (growers: IGrowerResponse[] = [], sort: UISort<IGrowerResponse>) =>
{
	const { sortDirection, field } = sort;
	if (!growers) 
	{
		return [];
	}
	const shallowCopyGrowers = [...growers];
	return sortDirection === 'none' ? shallowCopyGrowers : shallowCopyGrowers.sort(dynamicSort(field, sortDirection));
};


/**
 * A basic hook to ensure growers are loaded per the current acting user and crop year.
 */
function useRefetchGrowers(cropYears?: string[]) 
{
	const dispatch = useDispatch<AppDispatch>();
	const currentActingUser = useSelector((state: RootState) => getCurrentActingUser(state));
	const isGrowerLoading = useSelector((state: RootState) => state.grower.isLoadingGrowers);
	const organizationalCropYears = useSelector((state: RootState) => state.crops.CropYears);
	const currentCropYear = useSelector((state: RootState) => state.crops.CropYear);
	let years: string[];
	let cropYear: string;

	if (organizationalCropYears && organizationalCropYears.length > 0)
	{
		// Default organization if the user does not have one
		let userOrg = 'ZH-US13';
		if (currentActingUser.OrganizationalUnitName)
		{
			userOrg = currentActingUser.OrganizationalUnitName;
		}

		const userOrgCropYears = organizationalCropYears.find(ocy => ocy.Name.toLowerCase() === userOrg.toLowerCase());
		const currentUserOrgCropYear = Object.values(userOrgCropYears.CropYears)[0].toString();
		years = [(Number.parseInt(currentUserOrgCropYear) - 1).toString(), currentUserOrgCropYear];
	}
	else
	{
		cropYear = currentCropYear;
		years = [(Number.parseInt(currentCropYear) - 1).toString(), currentCropYear];
	}

	// Auto fetch when user or crop year changes.
	useEffect(() => 
	{
		if (!isGrowerLoading && years.length)
		{
			dispatch(getGrowers({
				UpdateFromCropEdge: true,
				GrowerYears: years ?? [cropYear],
				UserId: currentActingUser?.UserId
			}));
		}
	},[currentActingUser?.UserId, currentCropYear, cropYears]);

	const fetchGrowers = useCallback((updateFromCropEdge: boolean, cropYears?: string[]) => 
	{
		if(!cropYear) 
			throw new Error('Crop year is not set.');

		return dispatch(getGrowers({
			UpdateFromCropEdge: updateFromCropEdge,
			GrowerYears: cropYears ?? [cropYear],
			UserId: currentActingUser?.UserId
		}));
	}, [currentActingUser?.UserId, cropYear]);

	return { fetchGrowers };
}

const mapStateToProps = (state: RootState) => ({
	AuthenticationToken: authSelector(state)?.userAuthToken,
	CanManageAccounts: canManageAccounts(state),
	CurrentActingUser: getCurrentActingUser(state),
	CurrentCropYear: state.crops.CropYear,
	OrganizationalCropYears: state.crops.CropYears,
	CurrentCropYearError: state.crops.errorMessage,
	CurrentTheme: state.ui.CurrentTheme,
	EnogenContracts: state.grower.EnogenContracts,
	IsLoadingEnogenContracts: state.grower.isLoadingEnogenContracts,
	DashboardSort: state.ui.DashboardSort,
	DashboardTableExpanded: state.ui.DashboardTableExpanded,
	EnogenFlowUrl: currentEnogenFlowUrl(state),
	EnogenRootUrl: state.config.MapCentricUrl,
	FeatureFlags: featureFlagSelector(state),
	GrowerError: state.grower.errorMessage,
	Growers: getInitialGrowers(state.grower.Growers, state.ui.DashboardSort),
	IsCurrentCropYearError: state.crops.isError,
	IsCurrentCropYearLoading: state.crops.isLoading,
	IsGrowerError: state.grower.isError,
	IsGrowerLoading: state.grower.isLoading,
	IsManagingAccount: isManagingAccount(state),
	UiDashboardSelectedTab: state.ui.SelectedDashboardTab,
	UiDashboardSelectedYear: state.ui.SelectedYear,
	UserCanCreateGrowers: hasEffectivePermission(state, UserPermission.CanCreateGrowers),
	UserCanSeeEnogen: hasEffectivePermission(state, UserPermission.CanAccessEnogen),
	UserCanSeeEnogenTheme: hasEffectivePermission(state, UserPermission.CanSeeEnogenTheme),
	UserId: state.userInfo.UserId,
	UserIsGrower: hasEffectivePermission(state, UserPermission.IsGrower, true),
	UserName: state.userInfo.UserName,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearExpandedState: () => dispatch(clearExpandedState()),
		ClearSelectedProductPlanId: () => dispatch(clearSelectedProductPlanId()),
		DisplayEnogenFlow: makeDispatch(dispatch, displayEnogenFlow),
		DownloadGrowerCropHealth: makeDispatch(dispatch, downloadGrowerFieldHealthPdf),
		GetEnogenContracts: (year: string) => dispatch(getSellerEnogenContracts({ year })),
		GetManageableUsers: makeDispatch(dispatch, getManageableUsers),
		HideEnogenFlowFrame: makeDispatch(dispatch, hideEnogenFlow),
		SetDashboardExpanded: (idEntry: BoolDictionary) => dispatch(setDashboardExpanded(idEntry)),
		SetDashboardSort: (sort: UISort<IGrowerResponse>) => dispatch(setDashboardSort(sort)),
		SetSelectedGrowerId: (growerId: string) => dispatch(selectGrowerAndPullSeeds(growerId)),
		SetSelectedEnogenContractId: makeDispatch(dispatch, setSelectedEnogenContractId),
		SendContractForResigning: makeDispatch(dispatch, resendForSigning),
		SetSelectedDashboardTabForUI: makeDispatch(dispatch, setSelectedDashboardTab),
		SetSelectedUiYear: makeDispatch(dispatch, setSelectedYear),
		ClearSelectedDashboardYear: makeDispatch(dispatch, clearSelectedYearState),
		SetUserOrgCropYear: makeDispatch(dispatch, setCropYear),
		UpdateGrower: makeDispatch(dispatch, updateGrowerInfo),
		CreateJdConnectInvitation:  (growerId: string) => dispatch(createInvitationAndGetQrCodeWithLink({ growerId: growerId })),
		ClearGrowerJdConnectValues: makeDispatch(dispatch, clearJdConnectValues),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const Dashboard = withRouter(connector(React.memo(DashboardComponent)));