export interface IProductGamePlanDetails
{
	config: IProductGamePlanConfig;
	country: string;
	created_on: string;
	currency: string;
	email_id: string;
	id: string;
	name: string;
	organization_address: string;
	organization_id: string;
	organization_name: string;
	recommendations: IProductGamePlanRecommendation[];
	RecommendationSettings: IFieldlessRecommendationSettings;
	AgronomicSettings: IFieldlessAgronomicsSettings[];
	solution_type: string;
	status: string;
	summary_area: string;
	summary_quantity: string;
	type: string;
	updated_on: string;
	year: string;
}

export interface IFieldlessRecommendationSettings
{
	CornAcres?: number,
	CornIrrigated?: boolean,
	CornZipCode?: string,
	CornTargetYield?: number,
	CornRequiredSeeds?: number,
	SoybeanAcres?: number,
	SoybeanIrrigated?: boolean,
	SoybeanZipCode?: string,
	SoybeanTargetYield?: number,
	SoybeanRequiredSeeds?: number,
	EnogenAcres?: number,
	EnogenIrrigated?: boolean,
	EnogenZipCode?: string,
	EnogenTargetYield?: number,
	EnogenRequiredSeeds?: number
}

export interface IFieldlessAgronomicsSettings
{
	CropId: string;
	ProductPlanId: string;
	BrandApplication: string;
	Fungicide: boolean;
	IsFilterApplied: boolean;
	RmMax: number;
	RmMin: number;
	ConsistencyPerf: number;
	TopEndYield: number;
	Traits: string;
}

export interface IProductPlanRequest
{
	Products: IProductGamePlanProductRequest[];
	RecommendationSettings?: IFieldlessRecommendationSettings;
	AgronomicSettings?: IFieldlessAgronomicsSettings[];
}

export interface IProductGamePlanProductRequest
{
	AppliedArea: number;
	CropId: string;
	HybridId: string;
	LocalPositioning: string;
	PriceRate: number;
	Quantity: number;
	QuantityRequired: number;
	SeedingRate: number;
	TotalPrice: number;
	YieldTarget: number;
    BrandAssociation: string;
	Treatment?: {
		Name: string;
		Price: number;
		CustomTreatmentId?: string;
	}
}

export type ProductPlanCropType = 'GH,NK' | 'Enogen';
export type SeedListBrandApplication = 'Any' | 'Enogen';
export function ConvertCropTypeToBrandApplication(cropType: ProductPlanCropType): SeedListBrandApplication
{
	return cropType === 'GH,NK' ? 'Any' : cropType;
}
export function ConvertBrandApplicationToCropType(brandApplication: SeedListBrandApplication): ProductPlanCropType
{
	return brandApplication === 'Any' ? 'GH,NK' : brandApplication;
}

export interface IProductGamePlanRecommendation
{
	crop_id: string;
	crop_name: string;
	crop_type: ProductPlanCropType;
	title: string;
	products: IProductAssignment[];
	custom_catalog: ICustomCatalog;
}

export interface ICustomCatalog
{
	attributes: string[];
}

export interface IProductAssignment
{
	applied_area: number;
	product_id: string;
	product_name: string;
	commercial_name: string;
	seed_rate: number;
	quantity: number;
	total_quantity_required: number;
	quantity_units: string;
	price_rate: number;
	currency: string;
	price_dimension: string;
	price_unit: string;
	total_price: number;
	yield_target: number;
	yield_target_unit: string;
	statement: string;
	treatment?: {
		Name: string;
		Price: number;
	}
}

export interface IProductGamePlanConfig
{
	features: string[];
	low_ink: boolean;
}
