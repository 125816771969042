import { cornId, soyId } from '../store/Seeds/CropsSlice';

/**
 * Standardized list of colors to apply to the zones to differentiate them.
 */
export const ZoneColorList = [
	'#FC039D',
	'#7B03FC',
	'#03BEFC',
	'#9DFC03',
	'#FCDF03',
	'#9664F0',
	'#FF7D96',
	'#E99921',
	'#2B9C92',
	'#EE5B3A',
	// Below this were extended color options not provided by design.  Since our zone limit
	// is 10 or less, these will rarely be used
	'#191D32',
	'#AAFFE5',
	'#D5C9DF',
	'#F18701',
	'#A1EF8B',
	'#DBF4A7',
	'#F9564F'
];


export const MaxscriptProductColorsList = [
	'#F4B066',
	'#E78558',
	'#CC4938',
	'#EF8493',
	'#FCCDF4',
	'#E68CB1',
	'#C35080',
	'#557BB2',
	'#7AA5CA',
	'#97CAEA',
	'#B6E5FA',
	'#A7BFE2',
	'#BCB5F4',
	'#9172AF',
	'#B559AB',
	'#F7DA96',
];

export const MaxscriptExternalProductColorsList = [
	'#A0A0A0',
	'#D5D6D7',
	'#848884',
	'#C1C6C8',
	'#A9A9A9',
];

const cornBrandApplication = `${cornId}_Any`;
const enogenBrandApplication = `${cornId}_Enogen`;
const soybeanBrandApplication = `${soyId}_Any`;

const fieldPlanCropPlanColors = {};
fieldPlanCropPlanColors[cornBrandApplication] = [
	'#C45513',
	'#EA702A',
	'#ED965D'
];
fieldPlanCropPlanColors[soybeanBrandApplication] = [
	'#005693',
	'#0092E4',
	'#31B4F2'
];
fieldPlanCropPlanColors[enogenBrandApplication] = [
	'#14803C'
];
export const FieldPlanCropPlanColorsList: {[key: string]: string[]} = fieldPlanCropPlanColors;

const fieldPlanProductColors = {};
fieldPlanProductColors[cornId] = [
	'#F0C355',
	'#FF785A',
	'#FFB59D',
	'#BB485D',
	'#F98989',
	'#C17E19',
	'#FFD9D9',
	'#C1462B',
	'#FF7D96',
	'#9C2628',
];

fieldPlanProductColors[soyId] = [
	'#A9EFE8',
	'#2B9C92',
	'#82CFFF',
	'#005693',
	'#D28DF9',
	'#9664f0',
	'#5FD2C8',
	'#0071CD',
	'#C6ECFF',
	'#643AAA',
];
export const FieldPlanProductColorsList: {[key: string]: string[]} = fieldPlanProductColors;