import { DefaultTheme } from 'styled-components';

// Zoom levels
export const defaultZoomLevel = 11;

export interface IMapStyle
{
	color: string;
	opacity: number;
	fillColor: string;
	weight: number;
	fillOpacity: number;
}

// Map style opacities
export const unselectedOpacity = 0.5;
export const selectedOpacity = 0.8;
export const baseStyle = (theme: DefaultTheme): IMapStyle =>
{
	const baseMapStyle = {
		color: theme.colors.white,
		opacity: 1,
		fillColor: theme.colors.blueLM,
		weight: 3,
		fillOpacity: unselectedOpacity
	};

	return baseMapStyle;
};

// Continental US bounds
export const maxBoundsContinentalUSParams = [
	[44.499550, -135.275413], // Southwest
	[32.162102, -75.233040] // Northeast
];

// Base Layer configs for the map
export const hybridLayerParams = [
	'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=MAPBOXTOKEN',
	{ attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>' }
];
export const streetsLayerParams = [
	'https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}?access_token=MAPBOXTOKEN',
	{ attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>' }
];
export const satelliteLayerParams = [
	'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=MAPBOXTOKEN',
	{ attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>' }
];