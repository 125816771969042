import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { SortType, TableHeader } from '../../../components/Table/TableHeader';
import { TableRow } from '../../../components/Table/TableRow';
import { TableRowType } from '../../../components/Table/TableRows';
import { UISort } from '../../../logic/store/UI/UISlice';
import { dynamicSort, SortDirection } from '../../../logic/Utility/Utils';
import { FileListingRows, IFileListingRowItem } from './FileListingRow';

const StyledTableHeader = styled(TableHeader)`
	position: sticky;
	padding-left: 16px;
	top: 0;	
`;

const StyledTableRow = styled(TableRow)`
	padding: 0 1.5%;
	height: 42px;
`;

interface IFileListingTableProps
{
	rowData: IFileListingRowItem[];
}

export const FileListingTable = (props: IFileListingTableProps) =>
{
	const { rowData } = props;
	
	const theme = useTheme();

	const [fileListingSort, setFileListingSort] = useState<UISort<IFileListingRowItem>>({ field: 'UploadedDate', sortDirection: 'descending' });
	const [fileListingRows, setFileListingRows] = useState<IFileListingRowItem[]>([]);

	const headers = ['File Name', 'Upload Date', 'Status', 'Uploaded By', 'Download File'];
	const headerSpacing = '39% 15% 15% 15% 27%';
	const rowSpacing = '39% 15% 15% 15% 15%';

	const headersToKeys: { [key: string]: keyof IFileListingRowItem | undefined; } =
	{
		'File Name': 'FileName',
		'Upload Date': 'UploadedDate'
	};
	type FileListingRowItemKeys = keyof IFileListingRowItem;
	const keysToHeaders: Partial<{ [key in FileListingRowItemKeys]: string }> =
	{
		'FileName': 'File Name',
		'UploadedDate': 'Upload Date'
	};

	useEffect(() =>
	{
		if (rowData)
		{
			const sortByField = dynamicSort(fileListingSort.field, fileListingSort.sortDirection as SortDirection);
			const sortedRows = [...rowData];
			if (fileListingSort.sortDirection !== 'none') 
			{
				sortedRows.sort(sortByField);
			}
			setFileListingRows(sortedRows);
		}
		
	}, [rowData, fileListingSort]);

	const onSort = (sort: SortType, header: string) =>
	{
		setFileListingSort({ field: headersToKeys[header], sortDirection: sort });
	};

	return (
		<div
			style={{ 
				overflowY: fileListingRows && fileListingRows.length > 0 ? 'auto' : 'visible',
				flex: fileListingRows && fileListingRows.length > 0 ? 'unset' : 1
			}}
		>
			<StyledTableHeader
				headers={headers}
				columnSpacing={headerSpacing}
				headerHeight={'42px'}
				initialSorts={{ [keysToHeaders[fileListingSort.field]]: fileListingSort.sortDirection }}
				sortActions={{
					'File Name': (order) => onSort(order, 'File Name'),
					'Upload Date': (order) => onSort(order, 'Upload Date')
				}}
				defaultSort={{ headerName: 'Upload Date', direction: 'descending' }}
			/>
			{
				fileListingRows && fileListingRows.length > 0 ?
					<div style={{ paddingTop: 10, paddingLeft: 5, paddingRight: 5 }}>
						{
							FileListingRows({
								data: fileListingRows
							}).map(
								(row: TableRowType<IFileListingRowItem>) =>
									<StyledTableRow
										key={`fileListing-id-${row.data.JobId}`}
										className={`fileListing-id-${row.data.JobId}`}
										Id={row.data.Id}
										items={row.items}
										columnSpacing={rowSpacing}
										hideExpand
									/>
							)
						}
					</div>
					:
					<div
						style={{
							border: '1px dashed ' + theme.colors.darkGrey,
							marginLeft: 10,
							marginRight: 10,
							display: 'flex',
							flexGrow: 1,
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							height: '80%'
						}}>
						<div style={{ fontSize: theme.fontSizes.normal }}>
							{'There aren\'t any files attached to this grower.'}
						</div>
					</div>
			}
		</div>
	);
};