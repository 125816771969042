import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { getCurrentActingUser } from './../User/AuthSlice';


export const updateGrowerMatchStrengths = createTracedAsyncThunk<boolean, null, { dispatch: AppDispatch, state: RootState }>(
	'growerMatchStrengths/update',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const state = thunkAPI.getState();
			const { auth, ui } = state;
			const actingUser = getCurrentActingUser(state);
			const growerId = ui.SelectedGrowerId;

			const api = new Api('/api/4', auth.userAuthToken, context);
			const response = await api.postAsync<object>(`users/${actingUser.UserId}/grower/${growerId}/matchstrength?immediate=false`, 
				undefined
			);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Success;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);