import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Slider, SliderSingleProps } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';

const InputSliderContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const SliderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	margin-top: 15px;
`;

const StyledSlider = styled(Slider)<(SliderSingleProps | SliderRangeProps) & React.RefAttributes<unknown>>`
	padding: 0px;
	margin: 0px 15px;

	.ant-slider-rail {
		height: 5px;
		border-radius: 5px;
	}

	.ant-slider-track {
		height: 5px;
		background-color: ${themeGet('colors.secondary')};
		border-radius: 5px;
	}

	:hover .ant-slider-track {
		background-color: ${themeGet('colors.secondary')};
	}

	.ant-slider-handle:focus {
		box-shadow: 0 0 0 5px ${themeGet('colors.transparentGrey')};
	}
`;

interface IInputSliderProps {
	min: number;
	max: number;
	stepValue?: number;
	sliderValue: [number, number];
	onChange: (value: [number, number]) => void;
}

export const RangeInputSlider = (props: IInputSliderProps) =>
{
	const { min, max, sliderValue, stepValue, onChange } = props;

	const [minimum, setMinimum] = useState<number>(min);
	const [maximum, setMaximum] = useState<number>(max);
	const [step, setStep] = useState<number>(stepValue ?? 1);

	const theme = useTheme();

	const change = (value: [number, number]) =>
	{
		onChange(value);
	};

	return (
		<InputSliderContainer>
			<SliderContainer>
				<StyledSlider
					className='RangeSlider'
					id={'somethingelsegoeshere'}
					range={{ draggableTrack: true }}
					min={minimum}
					max={maximum}
					value={sliderValue}
					onChange={(value: [number, number]) => change(value)}
					step={step}
					style={{ width: '100%' }}
					handleStyle={[
						{ backgroundColor: theme.colors.darkGrey, border: 'none', height: 15, width: 15 },
						{ backgroundColor: theme.colors.darkGrey, border: 'none', height: 15, width: 15 }
					]}
				/>
			</SliderContainer>
		</InputSliderContainer>
	);
};