import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { isJwtValidAndUnexpired, validateToken, clearState } from '../../logic/store/User/AuthSlice';
import { makeDispatch } from '../../logic/Utility/Utils';

interface ILoggedInProps
{
	exact?: boolean;
	path: string | string[];
	children?: JSX.Element;
}


const LoggedInRouteComponent = (props: ILoggedInProps & PropsFromRedux) =>
{
	const { isAuthenticated, userAuthToken, children, performTokenValidation, ClearAuthentication } = props;
	const location = useLocation();

	if(!isAuthenticated)
	{
		// If we don't have a valid token, send them to login
		ClearAuthentication();
		return <Redirect to="/login" />;
	}

	useEffect(() =>
	{
		if (userAuthToken)
		{
			performTokenValidation(userAuthToken);
		}
	}, [userAuthToken]);

	return (
		<Route
			render={(otherProps) =>
				userAuthToken && isAuthenticated && !location.pathname.includes('login') ? (children)
					:
					(
						<Redirect
							to={{ pathname: '/login' + window.location.search, state: { from: location } }}
						/>
					)
			}
		/>
	);
};

const mapStateToProps = (state: RootState) => ({
	userAuthToken: state.auth.userAuthToken,
	isAuthenticated: state.auth.isAuthenticated && isJwtValidAndUnexpired(state.auth.userAuthToken),
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearAuthentication: makeDispatch(dispatch, clearState),
		performTokenValidation: (token: string) => dispatch(validateToken(token))
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const LoggedInRoute = connector(LoggedInRouteComponent);