import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { DatePicker, InputNumber } from 'antd';
import { IStewardshipField } from '../../../logic/Models/Responses/EnogenContractResponse';
import moment from 'moment';

const NumberInput = styled(InputNumber)`
	width: 60px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border-radius: 4px;
	border: thin solid ${themeGet('colors.lightGrey')};
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: left;
		height: auto;
		padding-right: 3px;
	}
`;

const StyledDatePicker = styled(DatePicker)`
	border-radius: 4px;
	width: 88%;
	padding: 2px 5px 2px;
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-picker-focused {
		border-color: black;
	}
`;

const StyledDatePickerPanel = styled.div`
	.ant-picker-today-btn {
		color: ${themeGet('colors.primary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-width: 1px;
		border-style: solid;
		border-color: ${themeGet('colors.secondary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
		background: ${themeGet('colors.primary')};
	}
`;

export interface IPlantingFieldRowProps
{
	field: IStewardshipField;
	editAcres: (fieldId: string, updatedValue: Number) => void;
	editDate: (fieldId: string, updatedValue: string) => void;
	editEstBuAcres: (fieldId: string, updatedValue: Number) => void;
	editRate: (fieldId: string, updatedValue: Number) => void;	
	disabledDates: (currentDate: moment.Moment) => boolean;
	lastItem: boolean;
}

export const PlantingFieldRow = (props: IPlantingFieldRowProps) =>
{
	const { field, lastItem, editAcres, editDate, editEstBuAcres, editRate, disabledDates } = props;

	const theme = useTheme();
	const dateFormat = 'MM/DD/YYYY';

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				fontSize: 12,
				paddingLeft: 10,
				paddingRight: 10,
				height: 40,
				alignItems: 'center',
				borderWidth: 1,
				borderColor: theme.colors.lightestGrey,
				borderBottomStyle: 'solid',
				borderLeftStyle: 'solid',
				borderRightStyle: 'solid',
				borderBottomLeftRadius: lastItem ? 6 : 0,
				WebkitBorderBottomRightRadius: lastItem ? 6 : 0,
			}}
		>
			<div style={{ width: '18%', textAlign: 'left' }}>
				<span>{field.FieldName}</span>
				{field.Replant && <ReplantBadge />}
				{field.FieldLost && <LostBadge />}
			</div>
			<div style={{ width: '20%', textAlign: 'left' }}>
				<NumberInput
					disabled={field.FieldLost}
					style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
					className={'Planting_Acres_Input'}
					type='number'
					placeholder='Add'
					value={field.Activity.Planting.TotalPlantedAcres ?? field.Acres}
					onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
						editAcres(field.FieldId, evt.currentTarget.value ? Number(evt.currentTarget.value) : Number(field.Activity.Planting.TotalPlantedAcres))}
					onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
						editAcres(field.FieldId, evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
							: Number(field.Activity.Planting.TotalPlantedAcres))}
				/>
			</div>
			<div style={{ width: '22%', textAlign: 'left' }}>
				<StyledDatePicker
					disabled={field.FieldLost}
					className={'Planting_Date_Input'}
					placeholder={'mm/dd/yyyy'}
					format={dateFormat}
					value={field.Activity.Planting.Date ? moment(field.Activity.Planting.Date) : undefined}
					disabledDate={disabledDates}
					onChange={(value: moment.Moment) => editDate(field.FieldId, value ? value.format(dateFormat) : '')}
					panelRender={originalPanel =>
					{
						return (
							<StyledDatePickerPanel className='StyledDatePickerPanel'>
								{originalPanel}
							</StyledDatePickerPanel>
						);
					}}
				/>
			</div>
			<div style={{ width: '20%', textAlign: 'left' }}>
				<NumberInput
					disabled={field.FieldLost}
					style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
					className={'Planting_Rate_Input'}
					type='number'
					placeholder='Add'
					value={field.Activity.Planting.PlantingRate}
					onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
						editRate(field.FieldId, evt.currentTarget.value ? Number(evt.currentTarget.value) : Number(field.Activity.Planting.PlantingRate))}
					onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
						editRate(field.FieldId, evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
							: Number(field.Activity.Planting.PlantingRate))}
				/>
			</div>
			<div style={{ width: '20%', textAlign: 'left' }}>
				<NumberInput
					disabled={field.FieldLost}
					style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
					className={'Planting_EstBuAcres_Input'}
					type='number'
					placeholder='Add'
					value={field.Activity.Planting.EstBuAcre}
					onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
						editEstBuAcres(field.FieldId, evt.currentTarget.value ? Number(evt.currentTarget.value) : Number(field.Activity.Planting.EstBuAcre))}
					onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
						editEstBuAcres(field.FieldId, evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
							: Number(field.Activity.Planting.EstBuAcre))}
				/>
			</div>
		</div>
	);
};

const ReplantBadge = styled.span`
	color: ${themeGet('colors.white')};
	background-color: ${themeGet('colors.soyBlue')};
	font-size: ${themeGet('fontSizes.small')};
	padding: 2px calc(${themeGet('fontSizes.small')} / 2);
	border-radius: ${themeGet('fontSizes.small')};
	margin-left: 2px;
	:before {
		content: 'R';
	}
`;

const LostBadge = styled.span`
	color: ${themeGet('colors.white')};
	background-color: ${themeGet('colors.red70')};
	font-size: ${themeGet('fontSizes.small')};
	padding: 2px calc(${themeGet('fontSizes.small')} / 2);
	border-radius: ${themeGet('fontSizes.small')};
	margin-left: 2px;
	:before {
		content: 'L';
	}
`;