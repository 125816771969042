import React from 'react';
import L from 'leaflet';
import { MapCustomControl } from '../MapCustomControl';
import { IconButton } from '../../../components/IconButton/IconButton';
import { ReactComponent as SoilSvg } from '../Icons/Soil.svg';
import { ReactComponent as Layers } from '../Icons/Layers.svg';
import styled, { useTheme } from 'styled-components';
import { IFieldSoilData } from '../PopUp/MapPopUp';
import { SoilDataTable } from '../PopUp/SoilDataTable';
import { themeGet } from '@styled-system/theme-get';

const MapControlButton = styled.div`
	background-color: ${themeGet('colors.secondary')};
	height: 47px;
	width: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	border: 2px solid rgba(0,0,0,0.3);
	box-shadow: 2px 2px 5px #888888;
	&:hover {
		background-color: ${themeGet('colors.primary')};
	}
`;

interface IFieldPlanButtonTrayProps
{
	displayTray: boolean;
	fieldSoilData: IFieldSoilData[];
	mapRef: React.MutableRefObject<L.Map | undefined>;
	showSoilMapButton: boolean;
	showFieldBoundary: () => void;
	showSoilZone: () => Promise<void>;
}

export const FieldPlanButtonTray = (props: IFieldPlanButtonTrayProps) =>
{
	const { displayTray, fieldSoilData, mapRef, showSoilMapButton, showFieldBoundary, showSoilZone } = props;

	const theme = useTheme();

	const addCustomControl = (control: React.ReactElement, position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined) =>
	{
		return (
			<MapCustomControl map={mapRef.current} position={position} show={displayTray}>
				<MapControlButton>
					{control}
				</MapControlButton>
			</MapCustomControl>
		);
	};

	const addCustomLegend = (control: React.ReactElement, position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined) =>
	{
		return (
			<MapCustomControl map={mapRef.current} position={position} show={displayTray} zIndex='799'>
				{control}
			</MapCustomControl>
		);
	};

	return (
		<div>
			{
				showSoilMapButton && addCustomControl(
					<IconButton
						tooltip='Show Soil Zones'
						placement='left'
						onClick={() => showSoilZone()}
						//fillcolor='white' // purposefully incorrect so as to not have a fillcolor
					>
						<SoilSvg height={40} width={40} style={{ position: 'relative' }} />
					</IconButton>,
					'topright'
				)
			}
			{
				!showSoilMapButton && addCustomControl(
					<IconButton
						tooltip='Show Field'
						placement='left'
						onClick={showFieldBoundary}
						//fillcolor='white' // purposefully incorrect so as to not have a fillcolor
					>
						<Layers height={25} width={25} style={{ position: 'relative' }} fill={'white'} />
					</IconButton>,
					'topright'
				)
			}
			{
				!showSoilMapButton && addCustomLegend(
					<div style={{
						width: 400,
						backgroundColor: 'white',
						position: 'relative',
						top: -60,
						right: -2,
						paddingTop: 14,
						paddingBottom: 20,
						paddingRight: 20,
						paddingLeft: 20
					}}>
						<div style={{
							fontSize: theme.fontSizes.large,
							fontFamily: theme.fonts.heading,
							fontWeight: theme.fontWeights.bold,
							position: 'relative',
							top: -5
						}}>
							Soil Information
						</div>
						<div style={{ position: 'relative', top: -4, color: theme.colors.primary, fontSize: theme.fontSizes.normal }}>
							Field Details
						</div>
						<SoilDataTable data={fieldSoilData} isVrs={false} />
					</div>,
					'topright'
				)
			}
		</div>
	);
};