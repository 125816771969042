import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import { ReactComponent as ExportPdf } from '../../../assets/images/PDFIcon.svg';
import { ReactComponent as CornIcon } from '../FieldPlan/Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from '../FieldPlan/Icons/SoybeanIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/PlusIcon.svg';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { getCurrentActingUser } from '../../../logic/store/User/AuthSlice';
import { Button } from '../../../components/Button/Button';
import { Tooltip, Radio, RadioChangeEvent, Divider } from 'antd';
import { IconButton } from '../../../components/IconButton/IconButton';
import { themeGet } from '@styled-system/theme-get';
import { getDiscountReasonCodes, getPlanDiscounts, updatePlanDiscounts } from '../../../logic/store/CompetitiveAllowance/CompetitiveAllowanceThunks';
import { makeDispatch } from '../../../logic/Utility/Utils';
import { cornId, soyId } from '../../../logic/store/Seeds/CropsSlice';
import { CropLevelDiscountRow } from './CropLevelDiscountRow';
import { HybridLevelDiscountRow } from './HybridLevelDiscountRow';
import { IDiscountReasonCode, IDiscountReasonCodeExtended } from '../../../logic/Models/Responses/DiscountReasonCodeResponse';
import { IPlanCropDiscount, IPlanHybridDiscount } from '../../../logic/Models/Responses/CompetitiveAllowanceResponse';
import { updatePlanCropDiscounts, updatePlanHybridDiscount } from '../../../logic/store/Grower/GrowerSlice';
import { downloadPlanPdf } from '../../../logic/store/Grower/PlanThunks';
import _ from 'lodash';
import { RoundAcres } from '../../../logic/Utility/CalculationUtilities';

const HeaderContainer = styled.div`
	display: flex;
	flex: 1 1 auto;
	padding-right: 2%;
	padding-left: 2%;
	flex-direction: column;
`;

const HeaderButton = styled(Button)`
	font-size: 14px;
	padding: 0 16px 0 16px;
	height: 32px;
	border-width: 2px;
`;

const HeaderRadio = styled(Radio)`
	margin-left: 35px;
	margin-right: 2px;
	.ant-radio-inner {
		border-color: ${themeGet('colors.primary')};
	}
	.ant-radio-checked .ant-radio-inner {
		border-color: ${themeGet('colors.primary')};
	}
	.ant-radio-checked::after {
		border-color: ${themeGet('colors.primary')};
	}
	.ant-radio-checked .ant-radio-inner::after {
		background-color: ${themeGet('colors.primary')};
	}
	.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
		border-color: ${themeGet('colors.primary')};
	}
	.ant-radio-input:focus + .ant-radio-inner {
		box-shadow: none;
	}
	span.ant-radio + * {
		font-weight: ${themeGet('fontWeights.bold')};
		padding-right: 0;
	}
	.ant-radio-wrapper {
		margin-right: 0;
	}
`;

const StyledDivider = styled(Divider)`
	&.ant-divider {
		border-top-color: black;
	}
`;

const StyledCornIcon = styled(CornIcon)<{ fillcolor: string }>`
	path {
		fill: ${props => props.fillcolor};
	}
`;

const StyledSoyIcon = styled(SoyIcon)<{ fillcolor: string }>`
	path {
		fill: ${props => props.fillcolor};
	}
`;

type cropDisplay = 'Both' | 'Corn' | 'Soybean';

const mapStateToProps = (state: RootState) => 
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	const SelectedPlan = SelectedGrower?.Plans?.find(p => p.Id === state.ui.SelectedPlanId);
	return {
		SelectedGrower,
		SelectedPlan,
		CurrentActingUser: getCurrentActingUser(state),
		DiscountReasonCodes: state.userInfo.DiscountReasonCodes,
		IsLoadingCA: state.grower.isLoading,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		GetDiscounts: makeDispatch(dispatch, getPlanDiscounts),
		GetReasonCodes: makeDispatch(dispatch, getDiscountReasonCodes),
		DownloadPlanPdf: makeDispatch(dispatch, downloadPlanPdf),
		UpdateHybridDiscount: makeDispatch(dispatch, updatePlanHybridDiscount),
		UpdateCropLevelDiscounts: makeDispatch(dispatch, updatePlanCropDiscounts),
		SaveDiscounts: makeDispatch(dispatch, updatePlanDiscounts),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface ICompetitiveAllowanceProps extends PropsFromRedux
{
}

const CompetitiveAllowanceComponent = (props: ICompetitiveAllowanceProps) =>
{
	const {
		SelectedGrower,
		SelectedPlan,
		CurrentActingUser,
		DiscountReasonCodes,
		IsLoadingCA,
		GetDiscounts,
		GetReasonCodes,
		DownloadPlanPdf,
		UpdateHybridDiscount,
		UpdateCropLevelDiscounts,
		SaveDiscounts,
	} = props;

	const [selectedCropDisplay, setSelectedCropDisplay] = useState<cropDisplay>('Both');
	const [cornLevelDiscounts, setCornLevelDiscounts] = useState<IPlanCropDiscount[]>([]);
	const [soyLevelDiscounts, setSoyLevelDiscounts] = useState<IPlanCropDiscount[]>([]);

	const [cornGhxReasonCodes, setCornGhxReasonCodes] = useState<IDiscountReasonCode[]>([]); // All corn reason codes
	const [soyGhxReasonCodes, setSoyGhxReasonCodes] = useState<IDiscountReasonCode[]>([]); // All soybean reason codes

	// The extended lists will keep track of what crop level codes are currently selected,
	// any that are currently selected cannot be selected again at the crop level
	// unless the line containing it is changed to another value (if another is availableW) or deleted
	const [cropLevelCornCodes, setCropLevelCornCodes] = useState<IDiscountReasonCodeExtended[]>([]); // Crop Level Corn codes
	const [cropLevelSoyCodes, setCropLevelSoyCodes] = useState<IDiscountReasonCodeExtended[]>([]); // Crop Level Soy codes

	const theme = useTheme();

	const isReadOnly: boolean = useMemo(() =>
		SelectedPlan?.Status === 'Accepted'
	,[SelectedPlan]);

	const cornExists: boolean = useMemo(() =>
		SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.findIndex(c => c.CropId === cornId) > -1
	,[SelectedPlan.CompetitiveAllowance]);

	const soyExists: boolean = useMemo(() =>
		SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.findIndex(c => c.CropId === soyId) > -1
	,[SelectedPlan.CompetitiveAllowance]);

	const showCornSection: boolean = useMemo(() => 
		cornExists && (selectedCropDisplay === 'Both' || selectedCropDisplay === 'Corn')
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay]);

	const showSoySection: boolean = useMemo(() => 
		soyExists && (selectedCropDisplay === 'Both' || selectedCropDisplay === 'Soybean')
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay]);

	const totalCornLevelDiscount = useMemo(() =>
	{
		const cornLevelDiscountPerAcreTot = cornLevelDiscounts.filter(d => d.DiscountReasonCode && d.PerAcreDiscount > 0).reduce((sum, d) => sum + d.PerAcreDiscount, 0);
		return cornLevelDiscountPerAcreTot;
	}
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, cornLevelDiscounts]);
	const totalSoyLevelDiscount = useMemo(() =>
	{
		const soyLevelDiscountPerAcreTot = soyLevelDiscounts.filter(d => d.DiscountReasonCode && d.PerAcreDiscount > 0).reduce((sum, d) => sum + d.PerAcreDiscount, 0);
		return soyLevelDiscountPerAcreTot;
	}
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, soyLevelDiscounts]);

	const subTotalCorn: number = useMemo(() =>
	{
		if (cornExists)
		{
			const subtotal = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => d.CropId === cornId)
				.reduce((sum, d) => sum + (RoundAcres(d.Acres) * d.PricePerAcre), 0);
			return subtotal ?? 0;
		}
	
		return 0;
	},[SelectedPlan.CompetitiveAllowance, selectedCropDisplay]);
	
	const subTotalSoy: number = useMemo(() =>
	{
		if (soyExists)
		{
			const subtotal = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => d.CropId === soyId)
				.reduce((sum, d) => sum + (RoundAcres(d.Quantity) * d.PricePerBag), 0);
			return subtotal ?? 0;
		}
	
		return 0;
	},[SelectedPlan.CompetitiveAllowance, selectedCropDisplay]);

	const cornAvgPerAcre: number = useMemo(() =>
	{
		const cornHybridDiscounts = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => d.CropId === cornId);
		if (cornHybridDiscounts && cornHybridDiscounts.length > 0)
		{
			const totalCornAcres = cornHybridDiscounts.reduce((sum, d) => sum + RoundAcres(d.Acres), 0);
			return subTotalCorn / totalCornAcres;
		}

		return 0;
	}
	,[SelectedPlan.CompetitiveAllowance]);

	const soyAvgPerAcre: number = useMemo(() =>
	{
		const soyHybridDiscounts = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => d.CropId === soyId);
		if (soyHybridDiscounts && soyHybridDiscounts.length > 0)
		{
			const totalSoyAcres = soyHybridDiscounts.reduce((sum, d) => sum + RoundAcres(d.Acres), 0);
			return subTotalSoy / totalSoyAcres;
		}
	
		return 0;
	}
	,[SelectedPlan.CompetitiveAllowance]);

	const subtotalAll: number = useMemo(() =>
	{
		// Show just Corn Totals
		if (selectedCropDisplay === 'Corn')
		{
			return subTotalCorn;
		}
		// Show just Soybean totals
		else if (selectedCropDisplay === 'Soybean')
		{
			return subTotalSoy;
		}
		else
		{
			// Total Corn & Soy together
			const subtotal = subTotalCorn + subTotalSoy;
			return subtotal ?? 0;
		}
	}
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay]);

	const cornTotalDiscount: number = useMemo(() =>
	{
		if (cornExists)
		{
			// Discounts must have both a value and a reason code that exists in the list of reason codes
			const cornTotalHybridDiscounts = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => 
				d.CropId === cornId && d.DiscountReasonCode && !cornGhxReasonCodes.every(rc => rc.Id !== d.DiscountReasonCode) && d.PerAcreDiscount > 0)
				.reduce((sum, d) => sum + (RoundAcres(d.Acres) * d.PerAcreDiscount), 0);
			
			const totalCornAcres = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => d.CropId === cornId)
				.reduce((sum, d) => sum + RoundAcres(d.Acres), 0);

			const cropLevelDiscountTotAmt = cornLevelDiscounts.filter(d => d.DiscountReasonCode && d.PerAcreDiscount).reduce((sum, d) => sum + d.PerAcreDiscount, 0);
			const totalCropLevelDiscount = cropLevelDiscountTotAmt * totalCornAcres;

			const cornTotalDiscounts = cornTotalHybridDiscounts + totalCropLevelDiscount > subTotalCorn ? subTotalCorn : cornTotalHybridDiscounts + totalCropLevelDiscount;
			
			return cornTotalDiscounts ?? 0;
		}

		return 0;
	},[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, cornLevelDiscounts]);

	const soyTotalDiscount: number = useMemo(() =>
	{
		if (soyExists)
		{
			const soyTotalHybridDiscount = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d =>
				d.CropId === soyId && d.DiscountReasonCode && !soyGhxReasonCodes.every(rc => rc.Id !== d.DiscountReasonCode) && d.PerAcreDiscount > 0)
				.reduce((sum, d) => sum + (RoundAcres(d.Acres) * d.PerAcreDiscount), 0);
			
			const totalSoyAcres = SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts?.filter(d => d.CropId === soyId)
				.reduce((sum, d) => sum + RoundAcres(d.Acres), 0);

			const cropLevelDiscountTotAmt = soyLevelDiscounts.filter(d => d.DiscountReasonCode && d.PerAcreDiscount).reduce((sum, d) => sum + d.PerAcreDiscount, 0);
			const totalCropLevelDiscount = cropLevelDiscountTotAmt * totalSoyAcres;

			const soyTotalDiscounts = soyTotalHybridDiscount + totalCropLevelDiscount > subTotalSoy ? subTotalSoy : soyTotalHybridDiscount + totalCropLevelDiscount;
			
			return soyTotalDiscounts ?? 0;
		}

		return 0;
	},[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, soyLevelDiscounts]);

	const totalDiscountAll: number = useMemo(() =>
	{
		// TODO: add calculations for crop level values

		// Show just Corn Totals
		if (selectedCropDisplay === 'Corn')
		{
			return cornTotalDiscount;
		}
		// Show just Soybean totals
		else if (selectedCropDisplay === 'Soybean')
		{
			return soyTotalDiscount;
		}
		else
		{
			// Total Corn & Soy together
			const totalDiscountsAll = cornTotalDiscount + soyTotalDiscount;
			return totalDiscountsAll ?? 0;
		}
	}
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, cornLevelDiscounts]);

	const cornTotal: number = useMemo(() =>
	{
		if (cornExists)
		{
			const total = subTotalCorn - cornTotalDiscount;
			return total ?? 0;
		}

		return 0;
	},[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, cornTotalDiscount]);

	const soyTotal: number = useMemo(() =>
	{
		if (soyExists)
		{
			const total = subTotalSoy - soyTotalDiscount;
			return total ?? 0;
		}
	
		return 0;
	},[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, soyTotalDiscount]);

	const totalAll: number = useMemo(() =>
	{
		// TODO: add calculations for crop level values

		// Show just Corn Totals
		if (selectedCropDisplay === 'Corn')
		{
			return cornTotal;
		}
		// Show just Soybean totals
		else if (selectedCropDisplay === 'Soybean')
		{
			return soyTotal;
		}
		else
		{
			// Total Corn & Soy together
			const totalAll = cornTotal + soyTotal;
			return totalAll ?? 0;
		}
	}
	,[SelectedPlan.CompetitiveAllowance, selectedCropDisplay, soyTotal, cornTotal]);

	useEffect(() =>
	{
		GetReasonCodes();
		if (!SelectedPlan.CompetitiveAllowance)
		{
			GetDiscounts();
		}
	},[]);

	// Parse the Reason Code dictionary - get only IsGhxSpecific = true for corn + soybean
	useEffect(() =>
	{
		if (Object.keys(DiscountReasonCodes).length > 0)
		{
			const cornCodes: IDiscountReasonCode[] = [];
			DiscountReasonCodes[cornId].forEach(code =>
			{
				if (code.IsGhxSpecific)
				{
					cornCodes.push(code);
				}
			});
			setCornGhxReasonCodes([...cornCodes]);
			setCropLevelCornCodes([...cornCodes.map(cc => ({...cc, Selected: false })) ]);

			const soyCodes: IDiscountReasonCode[] = [];
			DiscountReasonCodes[soyId].map(code =>
			{
				if (code.IsGhxSpecific)
				{
					soyCodes.push(code);
				}
			});
			setSoyGhxReasonCodes([...soyCodes]);
			setCropLevelSoyCodes([...soyCodes.map(sc => ({...sc, Selected: false })) ]);
		}
	},[DiscountReasonCodes]);

	useEffect(() =>
	{
		if (!SelectedPlan.CompetitiveAllowance && (cornGhxReasonCodes || soyGhxReasonCodes))
		{
			return;
		}
		// Get the Crop Level Discounts
		if (cornExists)
		{
			const cornLevel = _.cloneDeep(SelectedPlan.CompetitiveAllowance.PlanCropDiscounts.filter(d => (d.CropId === cornId && d.Id)
				|| (d.CropId === cornId && d.DiscountReasonCode) || (d.CropId === cornId && d.PerAcreDiscount > 0)
			));
			setCornLevelDiscounts([...cornLevel]);
			cornLevel.forEach(cl =>
			{
				if (cl.DiscountReasonCode)
				{
					const foundIndex = cropLevelCornCodes.findIndex(cc => cc.Id === cl.DiscountReasonCode);
					if (foundIndex > -1)
					{
						const updatedCodes = cropLevelCornCodes;
						updatedCodes[foundIndex].Selected = true;
						setCropLevelCornCodes([...updatedCodes]);
					}
				}
			});
		}
		if (soyExists)
		{
			const soyLevel = _.cloneDeep(SelectedPlan.CompetitiveAllowance.PlanCropDiscounts.filter(d => (d.CropId === soyId && d.Id)
				|| (d.CropId === soyId && d.DiscountReasonCode) || (d.CropId === soyId && d.PerAcreDiscount > 0)
			));
			setSoyLevelDiscounts([...soyLevel]);
			soyLevel.forEach(sl =>
			{
				if (sl.DiscountReasonCode)
				{
					const foundIndex = cropLevelSoyCodes.findIndex(cs => cs.Id === sl.DiscountReasonCode);
					if (foundIndex > -1)
					{
						const updatedCodes = cropLevelSoyCodes;
						updatedCodes[foundIndex].Selected = true;
						setCropLevelSoyCodes([...updatedCodes]);
					}
				}
			});
		}
	}, [SelectedPlan.CompetitiveAllowance, cornGhxReasonCodes, soyGhxReasonCodes]);

	const onSave = () =>
	{
		SaveDiscounts();
	};

	const downloadPdf = () =>
	{
		DownloadPlanPdf({ PlanId: SelectedPlan.Id, CoverConfig: undefined });
	};

	const selectCropDisplay = (e: RadioChangeEvent) =>
	{
		setSelectedCropDisplay(e.target.value);
	};

	const AddCornCropLevelDiscountRow = () =>
	{
		cornLevelDiscounts.push({
			Id: null,
			PlanId: SelectedPlan.Id,
			CropId: cornId,
			PerAcreDiscount: 0,
			DiscountReasonCode: '',
		});
		setCornLevelDiscounts([...cornLevelDiscounts]);
	};

	const AddSoyCropLevelDiscountRow = () =>
	{
		soyLevelDiscounts.push({
			Id: null,
			PlanId: SelectedPlan.Id,
			CropId: soyId,
			PerAcreDiscount: 0,
			DiscountReasonCode: '',
		});
		setSoyLevelDiscounts([...soyLevelDiscounts]);
	};

	const updateCropLevelRow = (cropId: string, index: number, updatedDiscount: number, originalDiscount: number, reasonCode?: string) =>
	{
		if (cropId === cornId)
		{
			cornLevelDiscounts[index].PerAcreDiscount = updatedDiscount;
			cornLevelDiscounts[index].DiscountReasonCode = reasonCode ? reasonCode : '';

			// Loop through the crop level reason code list and set the appropriate selected flags
			if (reasonCode)
			{
				cropLevelCornCodes.forEach(clc =>
				{
					const foundIndex = cornLevelDiscounts.findIndex(cld => cld.DiscountReasonCode === clc.Id);
					if (foundIndex > -1)
					{
						clc.Selected = true;
					}
					else
					{
						clc.Selected = false;
					}
				});
				setCropLevelCornCodes([...cropLevelCornCodes]);
			}

			setCornLevelDiscounts([...cornLevelDiscounts]);
			UpdateCropLevelDiscounts({ planId: SelectedPlan.Id, cropLevelDiscounts: [...cornLevelDiscounts, ...soyLevelDiscounts], growerId: SelectedGrower.Id});
		}
		else
		{
			soyLevelDiscounts[index].PerAcreDiscount = updatedDiscount;
			soyLevelDiscounts[index].DiscountReasonCode = reasonCode ? reasonCode : '';

			// Loop through the crop level reason code list and set the appropriate selected flags
			if (reasonCode)
			{
				cropLevelSoyCodes.forEach(clc =>
				{
					const foundIndex = soyLevelDiscounts.findIndex(sld => sld.DiscountReasonCode === clc.Id);
					if (foundIndex > -1)
					{
						clc.Selected = true;
					}
					else
					{
						clc.Selected = false;
					}
				});
				setCropLevelSoyCodes([...cropLevelSoyCodes]);
			}

			setSoyLevelDiscounts([...soyLevelDiscounts]);
			UpdateCropLevelDiscounts({ planId: SelectedPlan.Id, cropLevelDiscounts: [...soyLevelDiscounts, ...cornLevelDiscounts], growerId: SelectedGrower.Id});
		}
	};

	const deleteCropLevelRow = (cropId: string, index: number) =>
	{
		if (cropId === cornId)
		{
			cornLevelDiscounts.splice(index, 1);
			setCornLevelDiscounts([...cornLevelDiscounts]);

			// Loop through the crop level reason code list and set the appropriate selected flags
			cropLevelCornCodes.forEach(clc =>
			{
				const foundIndex = cornLevelDiscounts.findIndex(cld => cld.DiscountReasonCode === clc.Id);
				if (foundIndex > -1)
				{
					clc.Selected = true;
				}
				else
				{
					clc.Selected = false;
				}
			});
			setCropLevelCornCodes([...cropLevelCornCodes]);

			UpdateCropLevelDiscounts({ planId: SelectedPlan.Id, cropLevelDiscounts: [...cornLevelDiscounts, ...soyLevelDiscounts], growerId: SelectedGrower.Id});
		}
		else
		{
			soyLevelDiscounts.splice(index, 1);
			setSoyLevelDiscounts([...soyLevelDiscounts]);

			// Loop through the crop level reason code list and set the appropriate selected flags
			cropLevelSoyCodes.forEach(clc =>
			{
				const foundIndex = soyLevelDiscounts.findIndex(sld => sld.DiscountReasonCode === clc.Id);
				if (foundIndex > -1)
				{
					clc.Selected = true;
				}
				else
				{
					clc.Selected = false;
				}
			});
			setCropLevelSoyCodes([...cropLevelSoyCodes]);
			

			UpdateCropLevelDiscounts({ planId: SelectedPlan.Id, cropLevelDiscounts: [...cornLevelDiscounts, ...soyLevelDiscounts], growerId: SelectedGrower.Id});
		}
	};

	const updateHybridLevelRow = (hybridDiscountItem: IPlanHybridDiscount) =>
	{
		UpdateHybridDiscount({discountItem: hybridDiscountItem, growerId: SelectedGrower.Id});
	};

	const disableSave = (): boolean =>
	{
		let disableSave = false;

		if (isReadOnly)
		{
			disableSave = true;
			return disableSave;
		}

		if (cornExists && (cornLevelDiscounts.some(d => !d.DiscountReasonCode || d.PerAcreDiscount === 0)))
		{
			disableSave = true;
		}

		if (soyExists && (soyLevelDiscounts.some(d => !d.DiscountReasonCode || d.PerAcreDiscount === 0)))
		{
			disableSave = true;
		}

		if (SelectedPlan.CompetitiveAllowance?.PlanHybridDiscounts.some(d =>
			(d.DiscountReasonCode && d.PerAcreDiscount === 0) || (!d.DiscountReasonCode && d.PerAcreDiscount > 0)))
		{
			disableSave = true;
		}

		return disableSave;
	};

	return (
		<div style={{ width: '100%', height: '100%', paddingLeft: 70, backgroundColor: theme.colors.backgroundLM, display: 'flex', flexDirection: 'column' }}>
			<div className='CompetitiveAllowance_Header'>
				<div
					className='backTo_GrowerList_Container'
					style={{
						display: 'flex',
						height: 52,
						padding: '10px 25px',
						alignItems: 'center',
					}}
				>
					<Link to='/fieldactivities/recall' className='linkTo_PlanList'>
						<LeftCaret style={{ marginRight: '20px' }} />
						<span style={{ color: theme.colors.darkGrey }}>{SelectedGrower?.Name}</span>
					</Link>
					<Ellipse style={{ margin: '0 10px' }} />
					<span style={{ fontWeight: theme.fontWeights.bold }}>Proposal / Discount Review</span>
				</div>
				<HeaderContainer>
					<div style={{ display: 'flex', alignItems: 'start', height: 'auto' }}>
						<div
							className='CompetitiveAllowance_Title'
							style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large, fontFamily: theme.fonts.heading }}
						>
							{`Grower: ${SelectedGrower?.Name} | Associated Seller: ${CurrentActingUser.UserName}`}
						</div>
						<div style={{ marginLeft: 'auto', display: 'flex' }}>
							<IconButton
								tooltip={''}
								placement='bottom'
								onClick={() => downloadPdf()}
								fillcolor='auto' // purposefully incorrect so as to not have a fillcolor
								style={{height: 32, width: 32, marginRight: 1}}
								disabled={false}
								showDisabledTooltip= { true }
							>
								<ExportPdf height={40} width={40} style={{ position: 'relative' }} />
							</IconButton>
							<Tooltip title={''} placement='bottom'>
								<span>
									<HeaderButton style={{ marginLeft: 16 }} variant='dark' disabled={disableSave()} onClick={onSave}>
										Save Proposal
									</HeaderButton>
								</span>
							</Tooltip>
						</div>
					</div>
					<div style={{ display: 'flex', alignItems: 'start', height: 'auto', marginTop: 25 }}>
						<div className='CompetitiveAllowance_SecondaryTitle' style={{ fontSize: theme.fontSizes.normal, fontWeight: theme.fontWeights.bold }}>
							{'Detailed Pricing and Units'}
						</div>
						<div style={{ marginLeft: 'auto', display: 'flex' }}>
							<Radio.Group onChange={selectCropDisplay} value={selectedCropDisplay}>
								<HeaderRadio value={'Both'}>Both</HeaderRadio>
								<HeaderRadio
									value={'Corn'}
									disabled={!cornExists}
								>
									Corn
								</HeaderRadio>
								<HeaderRadio
									value={'Soybean'}
									disabled={!soyExists}
								>
									Soybean
								</HeaderRadio>
							</Radio.Group>
						</div>
					</div>
					<div className='CompetitiveAllowance_SummaryTable' style={{ display: 'flex', alignItems: 'start', height: 'auto', marginTop: 25 }}>
						<div
							style={{
								backgroundColor: theme.colors.primary,
								color: theme.colors.white,
								width: '100%',
								borderRadius: 4,
								paddingTop: 10,
								paddingBottom: 10,
								paddingRight: 15,
								paddingLeft: 15,
								display: 'flex',
								fontWeight: theme.fontWeights.bold
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
								<div style={{ fontSize: theme.fontSizes.extraSmall }}>Name</div>
								<div>{SelectedPlan?.Name}</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', width: '11%', visibility: cornExists ? 'visible' : 'hidden' }}>
								<div style={{ fontSize: theme.fontSizes.extraSmall }}>Corn/Acre</div>
								<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cornAvgPerAcre)}</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', width: '8%', visibility: soyExists ? 'visible' : 'hidden' }}>
								<div style={{ fontSize: theme.fontSizes.extraSmall }}>Soy/Acre</div>
								<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(soyAvgPerAcre)}</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', width: '10%' }}>
								<div style={{ fontSize: theme.fontSizes.extraSmall }}>Subtotal</div>
								<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subtotalAll)}</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', width: '50%' }}>
								<div style={{ fontSize: theme.fontSizes.extraSmall }}>Total Discount</div>
								<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalDiscountAll)}</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', width: '10%' }}>
								<div style={{ fontSize: theme.fontSizes.extraSmall }}>Total</div>
								<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAll)}</div>
							</div>
						</div>
					</div>
				</HeaderContainer>
			</div>
			<div style={{ paddingRight: '2%', paddingLeft: '2%', marginTop: 10 }}>
				<StyledDivider className='Divider' />
			</div>
			{
				!IsLoadingCA && !SelectedPlan.CompetitiveAllowance ?
					<div
						style={{
							paddingRight: '2%',
							paddingLeft: '2%',
							display: 'flex',
							flexDirection: 'column',
							flex: '1 1 auto',
							overflowY: 'auto',
							fontSize: theme.fontSizes.large,
							fontWeight: theme.fontWeights.bold,
							textAlign: 'center'
						}}>
						No Available Hybrids to Apply Discounts on this Plan.
					</div>
					:
					<div style={{ paddingRight: '2%', paddingLeft: '2%', display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflowY: 'auto' }}>
						{ (showCornSection) && 
							<div className='CompetitiveAllowance_CornSection' style={{ marginBottom: 20 }}>
								<div className='CompetitiveAllowance_CornSection_Header' style={{ display: 'flex' }}>
									<StyledCornIcon fillcolor={theme.colors.primary} />
									<div style={{ fontSize: theme.fontSizes.large, fontWeight: theme.fontWeights.bold }}>Corn</div>
									{
										isReadOnly ?
											<></>
											:
											<div style={{ marginLeft: 'auto' }}>
												<Button
													className='CompetitiveAllowance_Corn_AddCropDiscountButton'
													variant='outlined'
													style={{ width: 130, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
													onClick={AddCornCropLevelDiscountRow}
													disabled={cropLevelCornCodes.every(cc => cc.Selected) || cornLevelDiscounts?.length === cornGhxReasonCodes?.length}
												>
													{'Crop Discount'}<PlusIcon fill={theme.colors.darkGrey} width={12} style={{ position: 'relative', top: -1, marginLeft: 'auto' }} />
												</Button>
											</div>
									}
								</div>
								<div className='CompetitiveAllowance_Corn_CropLevelDiscountRowsContainer' style={{ display: 'flex', flexDirection: 'column' }}>
									{
										cornLevelDiscounts && cornLevelDiscounts.length > 0 && cornLevelDiscounts.map((discountItem, index) =>
										{
											return (
												<CropLevelDiscountRow
													key={index}
													index={index}
													isReadOnly={isReadOnly}
													cropLevelDiscountItem={discountItem}
													cropId={cornId}
													updateRow={updateCropLevelRow}
													deleteRow={deleteCropLevelRow}
													reasonCodes={cropLevelCornCodes}
												/>
											);
										})
									}
								</div>
								<div className='CompetitiveAllowance_Corn_HybridLevelContainer' style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
									<div
										className='CompetitiveAllowance_Corn_HybridLevelHeader'
										style={{
											display: 'flex',
											flexDirection: 'row',
											backgroundColor: theme.colors.ghxNeutral60,
											color: theme.colors.white,
											borderColor: theme.colors.ghxNeutral60,
											borderWidth: 1,
											borderStyle: 'solid',
											borderRadius: 6,
											fontWeight: theme.fontWeights.bold,
											fontSize: theme.fontSizes.small,
											paddingLeft: 15,
											paddingRight: 15,
											textAlign: 'right'
										}}
									>
										<div style={{ width: '20%', textAlign: 'left' }}>
											Product Name
										</div>
										<div style={{ width: '5%' }}>
											Acres
										</div>
										<div style={{ width: '10%' }}>
											Price/Acre
										</div>
										<div style={{ width: '5%' }}>
											Units
										</div>
										<div style={{ width: '10%' }}>
											Subtotal
										</div>
										<div style={{ width: '15%' }}>
											Discount/Acre
										</div>
										<div style={{ width: '25%' }}>
											Reason Code
										</div>
										<div style={{ width: '12%' }}>
											Total Discount
										</div>
										<div style={{ width: '10%' }}>
											Total
										</div>
									</div>
									<div className='CompetitiveAllowance_Corn_HybridLevelHybridRowContainer' style={{ marginTop: 5, marginBottom: 5 }}>
										{
											SelectedPlan?.CompetitiveAllowance?.PlanHybridDiscounts.filter(d => d.CropId === cornId).map((phd, index) =>
											{
												return (
													<HybridLevelDiscountRow
														key={index}
														isReadOnly={isReadOnly}
														hybridLevelDiscountItem={_.cloneDeep(phd)}
														updateHybridRow={updateHybridLevelRow}
														reasonCodes={cornGhxReasonCodes}
														totalCropLevelDiscount={totalCornLevelDiscount}
													/>
												);
											})
										}
									</div>
									<div
										className='CompetitiveAllowance_Corn_HybridLevelHybridSummary'
										style={{
											display: 'flex',
											flexDirection: 'row',
											backgroundColor: theme.colors.mediumGrey,
											color: theme.colors.white,
											borderColor: theme.colors.mediumGrey,
											borderWidth: 1,
											borderStyle: 'solid',
											borderRadius: 6,
											fontWeight: theme.fontWeights.bold,
											fontSize: theme.fontSizes.normal,
											paddingLeft: 15,
											paddingRight: 15,
											paddingTop: 10,
											paddingBottom: 10,
											textAlign: 'right',
										}}
									>
										<div style={{ width: '25%', textAlign: 'left' }}>
											Corn Total
										</div>
										<div style={{ width: '10%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cornAvgPerAcre)}
										</div>
										<div style={{ width: '15%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subTotalCorn)}
										</div>
										<div style={{ width: '52%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cornTotalDiscount)}
										</div>
										<div style={{ width: '10%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cornTotal)}
										</div>
									</div>
								</div>
							</div>
						}
						{ (showSoySection) && 
							<div className='CompetitiveAllowance_SoySection' style={{ marginBottom: 20 }}>
								<div className='CompetitiveAllowance_SoySection_Header' style={{ display: 'flex' }}>
									<StyledSoyIcon fillcolor={theme.colors.primary} />
									<div style={{ fontSize: theme.fontSizes.large, fontWeight: theme.fontWeights.bold }}>Soybean</div>
									{
										isReadOnly ?
											<></>
											:
											<div style={{ marginLeft: 'auto' }}>
												<Button
													className='CompetitiveAllowance_Soy_AddCropDiscountButton'
													variant='outlined'
													style={{ width: 130, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
													onClick={AddSoyCropLevelDiscountRow}
													disabled={cropLevelSoyCodes.every(sc => sc.Selected) || soyLevelDiscounts?.length === soyGhxReasonCodes?.length}
												>
													{'Crop Discount'}<PlusIcon fill={theme.colors.darkGrey} width={12} style={{ position: 'relative', top: -1, marginLeft: 'auto' }} />
												</Button>
											</div>
									}
								</div>
								<div className='CompetitiveAllowance_Soy_CropLevelDiscountRowsContainer' style={{ display: 'flex', flexDirection: 'column' }}>
									{
										soyLevelDiscounts && soyLevelDiscounts.length > 0 && soyLevelDiscounts.map((discountItem, index) =>
										{
											return (
												<CropLevelDiscountRow
													key={index}
													index={index}
													isReadOnly={isReadOnly}
													cropLevelDiscountItem={discountItem}
													cropId={soyId}
													updateRow={updateCropLevelRow}
													deleteRow={deleteCropLevelRow}
													reasonCodes={cropLevelSoyCodes}
												/>
											);
										})
									}
								</div>
								<div className='CompetitiveAllowance_Soy_HybridLevelContainer' style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
									<div
										className='CompetitiveAllowance_Soy_HybridLevelHeader'
										style={{
											display: 'flex',
											flexDirection: 'row',
											backgroundColor: theme.colors.ghxNeutral60,
											color: theme.colors.white,
											borderColor: theme.colors.ghxNeutral60,
											borderWidth: 1,
											borderStyle: 'solid',
											borderRadius: 6,
											fontWeight: theme.fontWeights.bold,
											fontSize: theme.fontSizes.small,
											paddingLeft: 15,
											paddingRight: 15,
											textAlign: 'right'
										}}
									>
										<div style={{ width: '20%', textAlign: 'left' }}>
											Product Name
										</div>
										<div style={{ width: '5%' }}>
											Acres
										</div>
										<div style={{ width: '10%' }}>
											Price/Acre
										</div>
										<div style={{ width: '5%' }}>
											Units
										</div>
										<div style={{ width: '10%' }}>
											Subtotal
										</div>
										<div style={{ width: '15%' }}>
											Discount/Acre
										</div>
										<div style={{ width: '25%' }}>
											Reason Code
										</div>
										<div style={{ width: '12%' }}>
											Total Discount
										</div>
										<div style={{ width: '10%' }}>
											Total
										</div>
									</div>
									<div className='CompetitiveAllowance_Soy_HybridLevelHybridRowContainer' style={{ marginTop: 5, marginBottom: 5 }}>
										{
											SelectedPlan?.CompetitiveAllowance?.PlanHybridDiscounts.filter(d => d.CropId === soyId).map((phd, index) =>
											{
												return (
													<HybridLevelDiscountRow
														key={index}
														isReadOnly={isReadOnly}
														hybridLevelDiscountItem={_.cloneDeep(phd)}
														updateHybridRow={updateHybridLevelRow}
														reasonCodes={soyGhxReasonCodes}
														totalCropLevelDiscount={totalSoyLevelDiscount}
													/>
												);
											})
										}
									</div>
									<div
										className='CompetitiveAllowance_Soy_HybridLevelHybridSummary'
										style={{
											display: 'flex',
											flexDirection: 'row',
											backgroundColor: theme.colors.mediumGrey,
											color: theme.colors.white,
											borderColor: theme.colors.mediumGrey,
											borderWidth: 1,
											borderStyle: 'solid',
											borderRadius: 6,
											fontWeight: theme.fontWeights.bold,
											fontSize: theme.fontSizes.normal,
											paddingLeft: 15,
											paddingRight: 15,
											paddingTop: 10,
											paddingBottom: 10,
											textAlign: 'right',
										}}
									>
										<div style={{ width: '25%', textAlign: 'left' }}>
											Soybeans Total
										</div>
										<div style={{ width: '10%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(soyAvgPerAcre)}
										</div>
										<div style={{ width: '15%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subTotalSoy)}
										</div>
										<div style={{ width: '52%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(soyTotalDiscount)}
										</div>
										<div style={{ width: '10%'}}>
											{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(soyTotal)}
										</div>
									</div>
								</div>
							</div>
						}
					</div>
			}
		</div>
	);
};

export const CompetitiveAllowance = connector(CompetitiveAllowanceComponent);