import { themeGet } from '@styled-system/theme-get';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { HybridAvailability, SeriesAvailability } from '../../ProductGamePlan/SeriesAvailability';
import { ReactComponent as GreenAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { ISeriesWithHybrid } from '../../../../logic/store/Seeds/SeedsActions';
import { IOptionItem } from '../../../../components/Dropdown/IOptionItem';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { ICropPlanSelectedProduct } from '../../../../logic/Models/Responses/FieldPlanResponse';
import { useCropPlanTrackingState } from '../useCropPlanTrackingState';
import { useAmplitudePassthrough } from '../../../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../../../tracing/session';
import { EventStructureTags } from '../../../../tracing/EventTagNames';
import { EventFieldPlanNames } from '../../../../tracing/EventNames';

const SeriesRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 50px;
	min-height: 50px;
	background-color: ${themeGet('colors.white')};
	border: 1px solid ${themeGet('colors.lightestGrey')};
	border-radius: 5px;
	margin-bottom: 8px;
	align-items: center;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

const SeriesData = styled.div`
	color: ${themeGet('colors.darkGrey')};
	font-family: ${themeGet('fonts.base')};
	font-size: ${themeGet('fontSizes.extraSmall')};
	font-weight: ${themeGet('fontWeights.bold')};
	padding: 0px 12px;
	padding-left: 15px;
	width: 55%;
`;

export interface ISeriesWithHybridRowProps
{
	selected: boolean;
	series: ISeriesWithHybrid;
	selectedHybrids: ICropPlanSelectedProduct[];
	selectHybridForSeries: (seriesId: string, hybridId: string) => void;
	selectSingleSeries: (selected: boolean, seriesId: string, hybridId: string, treatment?: string) => void;
}

/**
 * The Series With Hybrid/Treatment list dropdown and checkbox 
 */
export const SeriesWithHybridRow = (props: ISeriesWithHybridRowProps) =>
{
	const { selected, series, selectedHybrids, selectHybridForSeries, selectSingleSeries } = props;

	const theme = useTheme();

	const [hybridOptions, setHybridOptions] = useState<IOptionItem[]>([]);
	const [selectedHybridId, setSelectedHybridId] = useState<string>(undefined);

	const trackingData = useCropPlanTrackingState();
	const session = useScopedSession(SeriesWithHybridRow.name, trackingData, {
		[EventStructureTags.PageContext]: 'series_with_hybrid_row',
		[EventStructureTags.PageUrl]: window.location.toString()
	});
	const selectHybrid = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeRequestedTrait, (hybridId: string) =>
	{
		setSelectedHybridId(hybridId);
		selectHybridForSeries(series.seriesId, hybridId);
	}, [series, setSelectedHybridId, selectHybridForSeries]);

	useEffect(() =>
	{
		if (series && series.hybrids && series.hybrids.filter(h => h.Availability !== 'None').length > 0)
		{
			const createHybridOptions = series.hybrids.filter(h => h.Availability !== 'None').map(hybrid =>
			{
				const hybridOption: IOptionItem =
				{
					// Add the availability icon
					icon: <HybridAvailability style={{width: 14, marginRight: 6}}>
						{hybrid.Availability == 'Green' ? <GreenAvailabilityIcon style={{ fill: theme.colors.availabilityGreen }} />
							: hybrid.Availability === 'Yellow' ? <YellowAvailabilityIcon style={{ fill: theme.colors.availabilityYellow }} />
								: <RedAvailabilityIcon style={{ fill: theme.colors.availabilityRed }} />
						}
					</HybridAvailability>,
					label: hybrid.Name,
					value: hybrid.Id
				};
	
				return hybridOption;
			});
	
			setHybridOptions(createHybridOptions);

			// If the series is selected then we're either opening a previously created plan's seed choices or the
			// user has already selected it
			if (selected)
			{
				const newSelectedHybrid = selected && series.hybrids.length > 1 ? 
					series.hybrids.find(h => selectedHybrids.find(sh => sh.HybridId === h.Id)) : undefined;
				if (newSelectedHybrid)
				{
					setSelectedHybridId(newSelectedHybrid.Id);
				}
				else
				{
					setSelectedHybridId(series?.hybrids.filter(h => h.Availability !== 'None')[0].Id);
				}
			}
			else
			{
				// The default selection of a hybrid for a seed
				if (!selectedHybridId)
				{
					setSelectedHybridId(series?.hybrids.filter(h => h.Availability !== 'None')[0].Id);
				}
			}
		}
		
	}, [series]);

	const toggleSeriesSelected = (checked: boolean) =>
	{
		selectSingleSeries(checked, series.seriesId, selectedHybridId ?? hybridOptions[0].value, '');
	};

	const hybrid = series.hybrids.find(h => h.Id === selectedHybridId);

	return (
		<SeriesRow className='SeriesContainer' key={series.seriesId + 'FilteredSeriesList'}>
			<SeriesAvailability className='SeriesAvailability'>
				{hybrid?.Availability == 'Green' ? <GreenAvailabilityIcon />
					: hybrid?.Availability === 'Yellow' ? <YellowAvailabilityIcon />
						: <RedAvailabilityIcon />
				}
			</SeriesAvailability>
			{
				hybridOptions && hybridOptions.length > 1 ?
					<Dropdown
						variant='outlinednarrow'
						options={hybridOptions}
						onSelect={selectHybrid}
						selected={selectedHybridId}
						className='Crop_Dropdown'
						containerWidth={'55%'}
						titleFontSize={theme.fontSizes.extraSmall}
						relativePosition={true}
					/>
					:
					<SeriesData className='HybridName'>
						{series?.hybrids?.find(h => h.Id === selectedHybridId)?.Name}
					</SeriesData>
			}
			<div style={{ display: 'flex', flexDirection: 'row', width: '40%', paddingLeft: 10, paddingRight: 10 }}>
				<div className='SeriesRm' style={{ fontWeight: theme.fontWeights.bold }}>
					{series.relativeMaturity}
				</div>
				<div style={{ marginLeft: 'auto' }}>
					<Checkbox checked={selected} onChange={e => toggleSeriesSelected(e.target.checked)} />
				</div>
			</div>
		</SeriesRow>
	);
};