import React from 'react';
import { IFieldSoilData } from './MapPopUp';
import styled, { useTheme } from 'styled-components';

const ColoredDot = styled.div<{ color: string }>`
	&:before {
		content:"";
		width:10px;
		height:10px;
		border-radius:50%;
		background: ${props => props.color};
		display: flex;
		margin-top: 3px;
	}
`;

export interface ISoilDataProps
{
	data: IFieldSoilData[];
	isVrs: boolean;
}

export const SoilDataTable = (props: ISoilDataProps) =>
{
	const { data, isVrs } = props;

	const theme = useTheme();

	return (
		<div style={{ height: 130, overflowY: 'auto' }}>
			<table style={{ width: '100%' }}>
				<thead style={{
					fontWeight: theme.fontWeights.bold,
					textAlign: 'left',
					position: 'sticky',
					top: 0,
					margin: 0,
					backgroundColor: 'white',
					lineHeight: 2
				}}>
					<tr>
						<th key={'NCCPI'}>{!isVrs ? 'NCCPI' : 'Color'}</th>
						<th key={'Soil Zone'} style={{ paddingLeft: 10 }}>{'Soil Zone'}</th>
						<th key={'AC'} style={{ textAlign: 'right' }}>{'AC'}</th>
						<th key={'% of Total'} style={{ textAlign: 'right' }}>{'% of Total'}</th>
					</tr>
				</thead>
				<tbody style={{height: '100%', padding: 10, overflowY: 'auto' }}>
					{data && data.length > 0 && data.map((item) => (
						<tr key={item.MapUnit} className={`SoilDataRow_${item.MapUnit}`} style={{ marginBottom: 2 }}>
							<td>
								<div style={{ display: 'flex' }}>
									<ColoredDot className='Color' color={item.Color} />
									<div className='Overall' style={{ paddingLeft: 8 }}>{item.OverallNccpi}</div>
								</div>
							</td>
							<td style={{ paddingLeft: 10 }}>{item.SoilType}</td>
							<td style={{ textAlign: 'right' }}>{item.Acres}</td>
							<td style={{ textAlign: 'right' }}>{`${item.PercentOfTotal} %`}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};