import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components/Button/Button';
import { CompetitorSeedRow, ICompetitorSeedItem } from './CompetitorRow';
import { StyledModal } from '../../../../components/StyledModal/StyledModal';
import styled, { useTheme } from 'styled-components';
import { IOrderPlanProducts } from '../../../../logic/Models/Responses/OrderPlanResponse';
import { DeletedCompetitorSeedRow } from './DeletedCompetitorRow';
import { ICompetitorObject } from '../../../../logic/Models/Responses/CompetitorResponse';
import { CropConfig, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { ReactComponent as InfoIcon } from '../../../../assets/images/AvailabilityIcons/Info.svg';
import { Popover } from 'antd';

const CompetitorSeedsContainer = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

export interface ICompetitorSeedsModal
{
	competitorBrandsAndProducts: ICompetitorObject[]; // competitor brands and products from the redux store
	cropId: string;
	productInventory: IOrderPlanProducts[];
	visible: boolean;
	onCancel: () => void;
	saveCompetitors: (newCompetitors: ICompetitorSeedItem[], deletedCompetitors: ICompetitorSeedItem[]) => void;
}

export const CompetitorSeedsModal = (props: ICompetitorSeedsModal) =>
{
	const { competitorBrandsAndProducts, cropId, productInventory, visible, onCancel, saveCompetitors } = props;

	const theme = useTheme();

	const cropConfig = CropConfig()[cropId];

	const [competitorSeeds, setCompetitorSeeds] = useState<ICompetitorSeedItem[]>([]);
	const [deletedSeeds, setDeletedSeeds] = useState<ICompetitorSeedItem[]>([]);

	// List of row references so we can scroll to the last added row.
	const itemRefs: {[key: number]: React.MutableRefObject<HTMLDivElement>} = {};

	const AddNewCompetitorSeedRow = () =>
	{
		competitorSeeds.push({
			Brand: '',
			Product: null,
			RM: null,
			IsNew: true,
			HasError: false,
		});
		setCompetitorSeeds([...competitorSeeds]);
	};

	useEffect(() =>
	{
		if (productInventory)
		{
			const currentCompetitorSeeds = competitorSeeds;
			productInventory.forEach(pItem =>
			{
				if (pItem.IsExternal)
				{
					currentCompetitorSeeds.push(
						{
							Brand: pItem.BrandName,
							Product: pItem.HybridName,
							HybridId: pItem.HybridId,
							RM: pItem.Rm === null ? undefined : Number(pItem.Rm),
							IsNew: false,
							HasError: false,
						}
					);
				}
			});

			if (currentCompetitorSeeds && currentCompetitorSeeds.length === 0)
			{
				AddNewCompetitorSeedRow();
			}
			else
			{
				setCompetitorSeeds([...currentCompetitorSeeds]);
			}
		}
		else
		{
			AddNewCompetitorSeedRow();
		}
	},[]);

	const updateRow = (index: number, brand: string, product: string, rm?: number) =>
	{
		competitorSeeds[index].Brand = brand;
		competitorSeeds[index].Product = product;
		competitorSeeds[index].RM = rm;

		setCompetitorSeeds([...competitorSeeds]);
	};

	const deleteSeedRow = (index: number) =>
	{
		const rowToDelete = competitorSeeds[index];
		if (rowToDelete)
		{
			// If a Competitor seed is not new, we want to alert the user that they are deleting a seed that will actively remove assignments from their fields
			if (!rowToDelete.IsNew)
			{
				setDeletedSeeds([...deletedSeeds, rowToDelete]);
				competitorSeeds.splice(index, 1);
				setCompetitorSeeds([...competitorSeeds]);
			}
			else
			{
				competitorSeeds.splice(index, 1);
				setCompetitorSeeds([...competitorSeeds]);
			}
		}
	};

	const unDeleteSeedRow = (index: number) =>
	{
		const itemToUnDelete = deletedSeeds[index];
		if (itemToUnDelete)
		{
			deletedSeeds.splice(index, 1);
			setDeletedSeeds([...deletedSeeds]);
			setCompetitorSeeds([...competitorSeeds, itemToUnDelete]);
		}
	};

	const cancel = () =>
	{
		// Reset the competitor seeds list
		setCompetitorSeeds([]);
		setDeletedSeeds([]);
		onCancel();
	};

	const isSaveDisabled = (): boolean =>
	{
		let duplicatesExist = false;
		// Check for any duplicate brand/product/rm combinations
		competitorSeeds.forEach((cs, index) =>
		{
			// Keep attempting to assign to this variable until we have a true
			if (!duplicatesExist)
			{
				duplicatesExist = isDuplicate(cs, competitorSeeds, index);
				if (duplicatesExist)
				{
					competitorSeeds[index].HasError = true;
				}
				else
				{
					competitorSeeds[index].HasError = false;
				}
			}
		});

		// Disable save if we have a single duplicate
		if (duplicatesExist)
		{
			return true;
		}

		// Disable if any RM are empty or 0
		if (competitorSeeds.some(cs => !cs.RM))
		{
			return true;
		}

		return competitorSeeds.some(cs => !cs.Brand);
	};

	const isDuplicate = (entry:ICompetitorSeedItem, competitors: ICompetitorSeedItem[], index: number): boolean =>
	{
		const filteredCompetitors = competitors.filter((c, ind) => ind !== index);
		return filteredCompetitors.some(c => 
			(c.Brand?.toLowerCase() === entry.Brand?.toLowerCase()) && (c.RM === entry.RM) && (c.Product?.toLowerCase() === entry.Product?.toLowerCase()));
	};

	/**
	 * Hack to get the list to scroll to the last item
	 * @param id 
	 */
	const scrollTo = (id: number) =>
	{
		if (itemRefs[id] && itemRefs[id].current)
		{
			itemRefs[id].current.scrollIntoView();
		}
	};

	useEffect(() =>
	{
		scrollTo(competitorSeeds.length - 1);
	},[itemRefs]);

	const showDeletedRows = () =>
	{
		if (deletedSeeds && deletedSeeds.length > 0)
		{
			return (
				<div style={{ marginTop: 15 }}>
					<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large }}>
						{'Competitors to Delete'}
						<div style={{ fontSize: theme.fontSizes.normal, fontWeight: theme.fontWeights.normal }}>
							{'On save, these will be permanently removed from any fields they were assigned to.'}
						</div>
					</div>
					{
						deletedSeeds.map((seed, index) =>
						{
							return <DeletedCompetitorSeedRow key={index} competitorSeed={seed} index={index} unDeleteRow={unDeleteSeedRow} />;
						})
					}
				</div>
			);
		}
		else
		{
			return (
				<></>
			);
		}
	};

	const onSave = () =>
	{
		// Save the existing and delete any that need to be deleted
		saveCompetitors(competitorSeeds, deletedSeeds);
	};

	const addRefToParentList = (ref: React.MutableRefObject<HTMLDivElement>, index: number) =>
	{
		itemRefs[index] = ref;
	};

	const popOverContent = (
		<div style={{ fontWeight: theme.fontWeights.bold }}>
			{`${cropConfig.cropName} RM must be between ${cropConfig.competitorRmMin.toFixed(cropId === soyId ? 1 : 0)} ` +
			`and ${cropConfig.competitorRmMax.toFixed(cropId === soyId ? 1 : 0)}.`}
		</div>
	);

	return (
		<StyledModal
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>Add Competitors</div>
			}
			width={750}
			height={400}
			bodyStyle={{ paddingTop: 0 }}
			flexBody={true}
			open={visible}
			closable={true}
			destroyOnClose={true}
			maskClosable={false}
			onCancel={cancel}
		>
			<CompetitorSeedsContainer>
				<div style={{ fontSize: theme.fontSizes.normal, color: 'black' }}>
					Enter a competitor brand (required), and a product or a relative maturity (required).
					<Popover content={popOverContent} trigger='click'>
						<InfoIcon fill={theme.colors.darkGrey} style={{ cursor: 'pointer' }} />
					</Popover>
				</div>
				<div style={{ overflowY: 'auto', marginTop: 10, marginBottom: 15 }}>
					{
						competitorSeeds && competitorSeeds.length > 0 &&
						competitorSeeds.map((seed, index) =>
						{
							return (
								<CompetitorSeedRow
									addRefToParentList={addRefToParentList}
									competitorBrandsAndProducts={competitorBrandsAndProducts}
									cropId={cropId}
									cropRmMax={cropConfig.competitorRmMax}
									cropRmMin={cropConfig.competitorRmMin}
									key={index}
									competitorSeed={seed}
									index={index}
									updateRow={updateRow}
									deleteRow={deleteSeedRow}
								/>
							);
						})
					}
					{showDeletedRows()}
				</div>
				<div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'row' }}>
					<div>
						<Button variant='outlined' style={{ width: 150 }} onClick={() => AddNewCompetitorSeedRow()}>+ Add Another</Button>
					</div>
					<div style={{ display: 'flex', marginLeft: 'auto' }}>
						<Button variant='outlined' onClick={cancel} style={{ width: 100, marginRight: 20 }}>Cancel</Button>
						<Button variant='dark' style={{ width: 100 }} disabled={isSaveDisabled()} onClick={() => onSave()}>Save</Button>
					</div>
				</div>
			</CompetitorSeedsContainer>
		</StyledModal>
	);
};