import React from 'react';
import { Tabs, Divider } from 'antd';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { OrderTroubleshootingToolComponent } from './OrderTroubleshootingTool';
import { SalesHierarchyTroubleshootingToolComponent } from './SalesHierarchyTroubleshootingTool';
import { GrowerContactsTroubleShootingToolComponent } from './GrowerContactsTroubleShootingTool';
import { GrowerAssociationTroubleshootingToolComponent } from './GrowerAssociationTroubleshootingTool';
import { GamePlansTroubleshootingToolComponent } from './GamePlansTroubleShootingTool';
import { ProductAndPricingTroubleshootingToolComponent } from './ProductAndPricingTroubleshootingTool';
import { TreatmentsTroubleshootingToolComponent} from './TreatmentsTroubleshootingTool';
import { ProductAvailabilityTroubleshootingToolComponent } from './ProductAvailabilityTroubelshootingTool';
import { GrowerHierarchyTroubleshootingToolComponent } from './GrowerHierarchyTroubleshootingTool';
import { FieldHealthResponseTroubleShootingToolComponent } from './FieldHealthResponseTroubleShootingToolComponent';
import { SoilDataTroubleShootingToolComponent } from './SoilDataTroubleShootingToolComponent';
import { DataTransferTroubleshootingToolComponent } from './DataTransfer/DataTransferTroubleshootingTool';
import { UserManagementTool } from './UserManagement/UserManagementTool';

const TabContainer = styled.div`
	flex-grow: 1;
	flex-basis: 0;
	overflow: hidden;

	.ant-tabs {
		height: 100%;
	}

	.ant-tabs-content-holder {
		display: flex;
	}

	.ant-tabs-nav {
		font-weight: ${themeGet('fontWeights.bold')};
	}

	.ant-tabs-ink-bar {
		background-color: ${themeGet('colors.secondary')};
	}

	.ant-tabs-tab-active > .ant-tabs-tab-btn {
		color: ${themeGet('colors.white')};
	}

	.ant-tabs-tab:hover {
		color: ${themeGet('colors.primary')};
	}

	.ant-tabs-tab-active
	{
		background-color: ${themeGet('colors.secondary')};
	}
`;

interface ITroubleShootingToolsProps
{

}

export const TroubleShootingTools = (props: ITroubleShootingToolsProps) =>
{
	const theme = useTheme();

	const tabItems : { label: string, key: string, children: React.ReactNode, style: React.CSSProperties }[] =
	[
		{
			label: 'Sales Hierarchy',
			key: 'Sales Hierarchy',
			children: <SalesHierarchyTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Grower Hierarchy',
			key: 'Grower Hierarchy',
			children: <GrowerHierarchyTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Grower Association',
			key: 'Grower Association',
			children: <GrowerAssociationTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Grower Contacts',
			key: 'Grower Contacts',
			children: <GrowerContactsTroubleShootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Products and Pricing',
			key: 'Products and Pricing',
			children: <ProductAndPricingTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Product Availability',
			key: 'Product Availability',
			children: <ProductAvailabilityTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Proposals and Orders',
			key: 'Proposals and Orders',
			children: <OrderTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Plans',
			key: 'Plans',
			children: <GamePlansTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Treatments',
			key: 'Treatments',
			children: <TreatmentsTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Field Health Response',
			key: 'Field Health Response',
			children: <FieldHealthResponseTroubleShootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'NRCS Soil Data',
			key: 'NRCS Soil Data',
			children: <SoilDataTroubleShootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white},
		},
		{
			label: 'Data Transfer',
			key: 'Data Transfer',
			children: <DataTransferTroubleshootingToolComponent />,
			style: {height: '100%', backgroundColor: theme.colors.white, minHeight: 460, minWidth:1024, flexGrow:1, overflow: 'auto', display: 'flex', alignItems: 'stretch'}
		},
		{
			label: 'User Management',
			key: 'User Management',
			children: <UserManagementTool />,
			style: {height: '100%', backgroundColor: theme.colors.white, minHeight: 460, minWidth:1024, width:'100%', flexGrow:1, overflow: 'auto', display: 'flex', alignItems: 'flex-start'}
		}
	];

	return (
		<div
			className='TroubleShootingTools_MainContainer'
			style={{ padding: 20, height: '100%', overflow:'hidden', display: 'flex', flexDirection: 'column', backgroundColor: theme.colors.backgroundLM }}
		>
			<div className='TroubleShootingTools_TitleContainer' style={{ fontWeight: theme.fontWeights.bold, width: '100%' }}>
				<span
					className='TroubleShootingTools_Title'
					style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large }}
				>Trouble Shooting Tools</span>
			</div>
			<Divider className='TroubleShootingTools_Divider' style={{ marginTop: 10, marginBottom: 15 }} />
			<TabContainer className='TroubleShootingTools_TabContainer' >
				<Tabs defaultActiveKey='0' tabPosition='left' className='TroubleShootingTools_Tabs' items={tabItems} />
			</TabContainer>
		</div>
	);
};

