import { themeGet } from '@styled-system/theme-get';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StyledRow } from './TableRow';
import { RowLabel } from './TableRows';

interface IOutlinedProps extends React.HTMLAttributes<HTMLDivElement>
{
	children: ReactNode;
	id?: string;
	label?: string;
}
const OutlinedRowContainer = styled.div`
	border: 1px dashed ${themeGet('colors.lightGrey')};
	border-radius: 6px;
`;

const OutlinedRow = styled(StyledRow)`
	height: 40px;
	&.centered {
		justify-content: center;
	}
`;
const StyledOutlinedRow = styled.div`
	margin-top: 3%;
`;

export const OutlinedTableRow = (props: IOutlinedProps) =>
{
	const { id, children, label, className } = props;
	return <StyledOutlinedRow>
		{label && <RowLabel >
			{label}
		</RowLabel>}
		<OutlinedRowContainer id={id}>
			<OutlinedRow className={className}>
				{children}
			</OutlinedRow>
		</OutlinedRowContainer>
	</StyledOutlinedRow>;
};
