import React, { useEffect } from 'react';
import L from 'leaflet';
import { MapCustomControl } from './MapCustomControl';
import { IconButton } from '../../components/IconButton/IconButton';
import { ReactComponent as CenterAll } from './Icons/Center.svg';
import { ReactComponent as CenterSome } from './Icons/Location.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/MagnifyingGlass.svg';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

interface IZoomTrayProps
{
	mapRef: React.MutableRefObject<L.Map | undefined>;
	displayTools: boolean;
	geocoder?: MapboxGeocoder;
	onCenterAll: () => void;
	onCenterSelected: (() => void) | undefined;
	toggleLocationSearch?: () => void;
}

export const ZoomTray = (props: IZoomTrayProps) =>
{
	const { mapRef, displayTools, geocoder, onCenterAll, onCenterSelected, toggleLocationSearch } = props;

	const addCustomControl = (control: React.ReactElement, position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined) =>
	{
		return (
			<MapCustomControl map={mapRef.current} position={position} show={displayTools}>
				<div
					style={{
						backgroundColor: 'white',
						height: 33,
						width: 34,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '3px',
						border: '2px solid rgba(0,0,0,0.3)'
					}}>
					{control}
				</div>
			</MapCustomControl>
		);
	};

	useEffect(() =>
	{
		if (displayTools)
		{
			// Only attempt to add it if the div is available, if it is not, it will throw an error
			const geocoderElement = document.getElementById('geocoder');
			if (geocoderElement)
			{
				// Only add it once, otherwise we'll end up with many
				const existingMapGeocoder = document.getElementsByClassName('mapboxgl-ctrl-geocoder');
				if (existingMapGeocoder.length === 0)
				{
					geocoder.addTo('#geocoder');
				}
			}
		}
	}, [displayTools, addCustomControl]);

	return (
		<div>
			{
				addCustomControl(
					<IconButton
						tooltip='Center All'
						placement='left'
						onClick={() => onCenterAll()}
					>
						<CenterAll />
					</IconButton>,
					'bottomright'
				)
			}
			{
				onCenterSelected && addCustomControl(
					<IconButton
						tooltip='Center Selected'
						placement='left'
						onClick={() => onCenterSelected()}
					>
						<CenterSome />
					</IconButton>,
					'bottomright'
				)
			}
			{
				geocoder && addCustomControl(
					<IconButton
						tooltip='Toggle Location Search Bar'
						placement='left'
						onClick={() => toggleLocationSearch()}
					>
						<SearchIcon />
					</IconButton>,
					'bottomright'
				)
			}
		</div>
	);
};