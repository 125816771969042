import React, { useCallback } from 'react';
import { Form, Input, Row } from 'antd';
import { useTheme } from 'styled-components';
import { IRoleProjection } from '../../../logic/Models/Responses/UserAdmin/IRoleProjection';
import { IPermissionProjection } from '../../../logic/Models/Responses/UserAdmin/IPermissionProjection';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import styled from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { ICreateRoleRequest } from '../../../logic/store/UserAdmin/UserAdminThunks';

const StyledCheckbox = styled(Checkbox)`
	padding: 8px;
	span {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}
`;

/**
 * Present a UI to allow the editing of a role
 */
export const EditRoleForm = (props: { 
	selectedRole: IRoleProjection, 
	permissions: IPermissionProjection[],
	createOrEditRole: (name: string, description: string, permissions?: string[]) => void;
}) =>
{
	const { selectedRole, permissions, createOrEditRole } = props;
	const theme = useTheme();
	const [form] = Form.useForm();

	const saveEdits = useCallback((update: Partial<ICreateRoleRequest>) =>
	{
		createOrEditRole(update.Name, update.Description, update.Permissions);
	},[createOrEditRole]);

	return(
		<div style={{ display:'flex', flexDirection:'column', height: '100%', flex: '1 1 auto' }}>
			<Form
				labelCol={{ span: 5 }}
				form={form}
				onFinish={saveEdits}
				style={{ display: 'flex', flexDirection: 'column', height: '90%', width: '100%' }}
				initialValues={{
					'Name': selectedRole?.Name,
					'Description': selectedRole?.Description,
					'Permissions': selectedRole?.Permissions?.map(p => p.Id)
				}}
			>
				<Form.Item 
					name='Name'
					label='Name'
					required
				>
					<Input />
				</Form.Item>
				<Form.Item 
					name='Description'
					label='Description'
					required
				>
					<Input />
				</Form.Item>
				<Form.Item name='Permissions' label='Permissions' style={{ overflow: 'hidden', height: '100%' }}>
					<Checkbox.Group className='CheckboxGroup' style={{ height: '44vh', overflowY: 'auto', width: '100%' }}>
						{
							permissions?.map(p =>
							{
								return(
									<Row key={p.Id}>
										<StyledCheckbox
											className={`Checkbox_Permission_${p.Id}`}
											value={p.Id}
										>
											<div style={{ fontWeight: theme.fontWeights.bold }}>{p.Name}</div>
											<div style={{ fontSize: theme.fontSizes.extraSmall }}>{p.Description}</div>
										</StyledCheckbox>
									</Row>
								);
							})
						}
					</Checkbox.Group>
				</Form.Item>
			</Form>
			<div style={{ marginTop: 'auto', marginLeft: 'auto' }}>
				<Button style={{marginTop: 8, width: 150 }} variant='dark' type='submit' onClick={() => form.submit()}>Save</Button>
			</div>
		</div>
	);
};