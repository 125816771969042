import React, { useEffect, useRef, useState } from 'react';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Input } from 'antd';
import { ReactComponent as MagnifyingGlassIcon } from '../../../assets/images/MagnifyingGlass.svg';
import { GrowerListCard } from './GrowerListCard';
import { dynamicSort } from '../../../logic/Utility/Utils';
import { IconButton } from '../../../components/IconButton/IconButton';

const GrowerListContainer = styled.div`
	width: 23vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: fixed;
	z-index: 998;
	background-color: ${themeGet('colors.white')};
`;

const GrowerListHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 18px;
	background-color: ${themeGet('colors.backgroundLM')};
	font-size: ${themeGet('fontSizes.large')};
	font-weight: ${themeGet('fontWeights.bold')};
	color: ${themeGet('colors.darkGrey')};
	border-top: 1px solid ${themeGet('colors.lightestGrey')};
	border-bottom: 1px solid ${themeGet('colors.lightestGrey')};
`;

const GrowerListBody = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 19px 17px;
`;

const GrowerSearchInput = styled(Input)`
	height: 40px;
	margin-bottom: 5%;
	background-color: ${themeGet('colors.disabledGrey')};
	border-style: none;
	border-radius: 4px;
	outline: none;
	box-shadow: none !important;
	padding-left: 15px;

	input.ant-input
	{
		background-color: ${themeGet('colors.disabledGrey')};
		font-size: ${themeGet('fontSizes.normal')};
		color: ${themeGet('colors.mediumGrey')};
		margin-left: 12px;
	}
`;

interface IGrowerListProps
{
	growers: IGrowerResponse[];
	onSelectGrower: (growerId: string) => void;
}

export const GrowerList = (props: IGrowerListProps) =>
{
	const { growers, onSelectGrower } = props;

	const theme = useTheme();

	const growerSort = dynamicSort('Name', 'ascending');
	const [displayGrowers, setDisplayGrowers] = useState<IGrowerResponse[]>([...growers].sort(growerSort));
	const [growerSearchText, setGrowerSearchText] = useState<string>('');

	const growerSearchFilter = (grower: IGrowerResponse) =>
		grower.Name?.toLocaleLowerCase().includes(growerSearchText.toLocaleLowerCase());

	const onClearSearch = () => 
	{
		setGrowerSearchText('');
	};

	useEffect(() => 
	{
		setDisplayGrowers([...growers].filter(growerSearchFilter).sort(growerSort));
	}, [growers, growerSearchText]);

	return (
		<GrowerListContainer className='GrowerList_Outer'>
			<GrowerListHeader className='GrowerList_Header'>
				Grower List
			</GrowerListHeader>
			<GrowerListBody className='GrowerList_BodyContainer'>
				<GrowerSearchInput
					className='GrowerList_Search'
					placeholder="Search by Grower's Name"
					prefix={<MagnifyingGlassIcon fill={theme.colors.darkGrey} />}
					suffix={
						<IconButton
							className='GrowerSearch_Close'
							variant='close'
							onClick={onClearSearch}
							style={{ flexGrow: 1, paddingTop: 4 }}
						/>}
					value={growerSearchText}
					onChange={(e) =>
					{
						setGrowerSearchText(e.target.value);
					}}
					style={{ fontWeight: growerSearchText.length ? theme.fontWeights.bold : theme.fontWeights.normal }}
				/>
				<div className='GrowerList_Inner' style={{ overflowY: 'auto', height: '85%', paddingBottom: '20%' }}>
					{displayGrowers.map((grower: IGrowerResponse) =>
						<GrowerListCard
							key={`grower-${grower.Id}`}
							grower={grower}
							onSelectGrower={onSelectGrower}
						/>
					)}
				</div>
			</GrowerListBody>
		</GrowerListContainer>
	);
};