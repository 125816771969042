import { LineString, Polygon, MultiPolygon, Point } from '@turf/turf';

export enum SupportedGeometryFormats
{
	GeoJSON = 'geojson',
	Wkt = 'wkt'
}

type AllowedPolygonGeoJSON = Polygon | MultiPolygon;
export type AllowedPolygonTypes = AllowedPolygonGeoJSON | string;
type AllowedLineGeoJSON = LineString;
export type AllowedLineTypes = AllowedLineGeoJSON | string;
type AllowedPointGeoJSON = Point;
export type AllowedPointTypes = AllowedPointGeoJSON | string;

