import { createSlice } from '@reduxjs/toolkit';
import { ICustomTreatmentResponse } from '../../Models/Responses/CustomTreatmentResponse';
import { getCustomTreatments, getUserDefaultTreatment, IUserDefaultTreatmentResponse, saveTreatments, setUserDefaultTreatment } from './CustomTreatmentsActions';

export interface ICustomTreatmentsState
{
	errorMessage: string;
	isErrorGetTreatments: boolean;
	isErrorGetUserDefaultTreatments: boolean;
	isErrorSaveTreatments: boolean;
	isErrorSaveUserDefaultTreatments: boolean;
	isLoadingGetTreatments: boolean;
	isLoadingGetUserDefaultTreatments: boolean;
	isLoadingSaveTreatments: boolean;
	isLoadingSaveUserDefaultTreatments: boolean;
	treatments: ICustomTreatmentResponse[];
	userDefaultTreatments: IUserDefaultTreatmentResponse;
}

export const initialState: ICustomTreatmentsState = {
	errorMessage: undefined,
	isErrorGetTreatments: false,
	isErrorGetUserDefaultTreatments: false,
	isErrorSaveTreatments: false,
	isErrorSaveUserDefaultTreatments: false,
	isLoadingGetTreatments: false,
	isLoadingGetUserDefaultTreatments: false,
	isLoadingSaveTreatments: false,
	isLoadingSaveUserDefaultTreatments: false,
	treatments: undefined,
	userDefaultTreatments: undefined,
};

export const customTreatmentsSlice = createSlice({
	name: 'customTreatments',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			state = { ...initialState };

			return state;
		},
		clearSaveTreatmentError: (state) =>
		{
			state.isErrorSaveTreatments = false;
			state.errorMessage = undefined;

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getUserDefaultTreatment.pending, (state) =>
		{
			state.isLoadingGetUserDefaultTreatments = true;
			state.isErrorGetUserDefaultTreatments = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getUserDefaultTreatment.fulfilled, (state, action) =>
		{
			state.userDefaultTreatments = action.payload;
			state.isLoadingGetUserDefaultTreatments = false;
			state.isErrorGetUserDefaultTreatments = false;
		});
		builder.addCase(getUserDefaultTreatment.rejected, (state, action) =>
		{
			state.isLoadingGetUserDefaultTreatments = false;
			state.isErrorGetUserDefaultTreatments = true;
			state.errorMessage = '';
		});
		builder.addCase(setUserDefaultTreatment.pending, (state) =>
		{
			state.isLoadingSaveUserDefaultTreatments = true;
			state.isErrorSaveUserDefaultTreatments = false;
			state.errorMessage = undefined;
		});
		builder.addCase(setUserDefaultTreatment.fulfilled, (state, action) =>
		{
			state.isLoadingSaveUserDefaultTreatments = false;
			state.isErrorSaveUserDefaultTreatments = false;
		});
		builder.addCase(setUserDefaultTreatment.rejected, (state, action) =>
		{
			state.isLoadingSaveUserDefaultTreatments = false;
			state.isErrorSaveUserDefaultTreatments = true;
			state.errorMessage = '';
		});
		builder.addCase(getCustomTreatments.pending, (state) =>
		{
			state.isLoadingGetTreatments = true;
			state.isErrorGetTreatments = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCustomTreatments.fulfilled, (state, action) =>
		{
			state.treatments = action.payload;
			state.isLoadingGetTreatments = false;
		});
		builder.addCase(getCustomTreatments.rejected, (state, action) =>
		{
			state.isLoadingGetTreatments = false;
			state.isErrorGetTreatments = true;

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of custom treatments. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem getting the list of custom treatments. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of custom treatments. Please refresh and try again.';
			}

		});
		builder.addCase(saveTreatments.pending, (state) =>
		{
			state.isLoadingSaveTreatments = true;
			state.isErrorSaveTreatments = false;
			state.errorMessage = undefined;
		});
		builder.addCase(saveTreatments.fulfilled, (state, action) =>
		{
			state.treatments = action.payload;
			state.isLoadingSaveTreatments = false;
		});
		builder.addCase(saveTreatments.rejected, (state, action) =>
		{
			state.isLoadingSaveTreatments = false;
			state.isErrorSaveTreatments = true;

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to save the list of custom treatments. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = action.error.message;
			}
		});
	},
});
export const { clearState, clearSaveTreatmentError } = customTreatmentsSlice.actions;