/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useRef } from 'react';

type PromiseReturnType<T> = T extends Promise<infer Return> ? Return : T;
type AllButFirstArgumentOfAnyFunction<T> = T extends (
  first: any,
  ...args: infer RemainingArguments
) => any
  ? RemainingArguments
  : never;

/**
 *
 */
function createAsyncDebounceTracker<K, T extends (...params: unknown[]) => Promise<K>>(
	queue: IAsyncResolverQueue<K>,
	func: T,
) 
{
	/**
   *
   */
	function startTask(task: () => Promise<K>) 
	{
		queue.inProgressTask = (async () => 
		{
			const result = await task();
			queue.inProgressTask = undefined;

			// if there is a waiting task here, then make it in progress!
			const queuedTask = queue.waitingTask;
			const queuedPromise = queue.overallTask;
			const queuedResolver = queue.overallResolver;
			if (!!queuedTask && !!queuedPromise && !!queuedResolver) 
			{
				queue.waitingTask = undefined;
				queue.overallTask = undefined;
				queue.overallResolver = undefined;

				startTask(async () => 
				{
					const taskResult = await queuedTask();
					queuedResolver(taskResult);
					return taskResult;
				});
			}
			return result;
		})();
		return queue.inProgressTask;
	}

	/**
   *
   */
	function queueTask(task: () => Promise<K>) 
	{
		queue.waitingTask = task;

		// If we don't have an overall task created, create one.
		// This will be resolved by whatever task actually gets executed next
		if (!queue.overallTask) 
		{
			queue.overallTask = new Promise<K>((resolve) => 
			{
				queue.overallResolver = resolve;
			});
		}
		return queue.overallTask;
	}

	return async (...p: unknown[]) => 
	{
		if (!queue.inProgressTask) 
		{
			return startTask(() => func(...p));
		}

		return queueTask(() => func(...p));
	};
}

interface IAsyncResolverQueue<K> 
{
	// At any given moment, there is potentially an in-progress task
	inProgressTask?:Promise<K>,

	// There might be another task waiting to be fired off once the current task completes
	waitingTask?:() => Promise<K>,

	// If there is a waiting task, then the overallTask wraps that and executes whenever the _actual_
	// waiting task completes -- this is returned whenever the waitingTask completes, whatever the
	// waiting task ends up being.
	overallResolver?:(value:K) => unknown,
	overallTask?: Promise<K>;
}

/**
 * A custom hook for executing an asynchronous function, but only one at a given time.
 * Other requests that are made while it is executing will be stored, and the most recent
 * request will finally be executed when the first one finishes.
 *
 * @param func  The function to wrap
 */
export function useAsyncDebounce<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any) => Promise<any>,
  K extends PromiseReturnType<ReturnType<T>>
>(func: T): (...params: Parameters<T>) => Promise<K> 
{
	const queue = useRef<IAsyncResolverQueue<K>>({});

	// if deps have changed, we need to reset all the above.
	useEffect(() => 
	{
		queue.current = {};
	}, [func]);

	return createAsyncDebounceTracker<K, T>(
		queue.current,
		func,
	);
}

// A proxy for the useRef hook to normalize usage both as a hook and standalone
/**
 *
 */
function localRef<T>() 
{
	return { current: undefined } as { current: T | undefined };
}

/**
 *
 */
export function asyncDebounce<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...params: any) => Promise<any>,
  K extends PromiseReturnType<ReturnType<T>>
>(func: T): (...params: Parameters<T>) => Promise<K> 
{
	// At any given moment, there is potentially an in-progress task
	const queue = localRef<IAsyncResolverQueue<K>>();
	return createAsyncDebounceTracker<K, T>(
		queue.current,
		func,
	);
}

/**
 *
 */
export function keyedAsyncDebounce<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (first: string, ...params: any) => Promise<any>
>(
	func: T,
): (
  key: string,
  ...params: AllButFirstArgumentOfAnyFunction<T>
) => Promise<PromiseReturnType<ReturnType<T>>> 
{
	const dictionary: {
    [key: string]: (
      key: string,
      ...params: AllButFirstArgumentOfAnyFunction<T>
    ) => Promise<PromiseReturnType<ReturnType<T>>>;
  } = {};

	return async (key: string, ...params: AllButFirstArgumentOfAnyFunction<T>) => 
	{
		if (!dictionary[key]) 
		{
			dictionary[key] = asyncDebounce(func) as any;
		}
		const method = dictionary[key];
		return method(key, ...params);
	};
}