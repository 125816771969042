
// Password requirements
export const minimumPasswordLength = 8;
export const minimumNumbers = 1;
export const minimumSymbols = 1;
export const minimumLetters = 1;

/**
 * Apply password rules to the entered text
 */
export const validatePasswordRules = ({ getFieldValue }) => ({
	validator(_, value: string) 
	{
		if (!value || !value.length) 
		{
			return Promise.resolve();
		}

		const passwordLength = value.length;
		if (passwordLength < minimumPasswordLength) 
		{
			return Promise.reject(new Error(`The password must be at least ${minimumPasswordLength} characters long`));
		}
		const numberOfLetters = value.match(new RegExp(/([A-Za-z])/gi))?.length ?? 0;
		if (numberOfLetters < minimumLetters) 
		{
			return Promise.reject(new Error(`The password must contain at least ${minimumLetters} letters`));
		}
		const numberOfNumbers = value.match(new RegExp(/[0-9]/gi))?.length ?? 0;
		if (numberOfNumbers < minimumNumbers) 
		{
			return Promise.reject(new Error(`The password must contain at least ${minimumNumbers} numbers`));
		}
		const numberOfSymbols = passwordLength - numberOfNumbers - numberOfLetters;
		if (numberOfSymbols < minimumSymbols) 
		{
			return Promise.reject(new Error(`The password must contain at least ${minimumSymbols} symbols ($#@! etc)`));
		}

		return Promise.resolve();
	},
});
