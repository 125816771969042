import { themeGet } from '@styled-system/theme-get';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as PullTabClosed } from './Icons/PullTabClosed.svg';
import { ReactComponent as PullTabOpen } from './Icons/PullTabOpen.svg';

interface IFieldListContainerProps extends PropsWithChildren<{}>
{
	className?: string;
	expandable: boolean;
	setVisible: (val: boolean) => void;
	visible: boolean;
}

const FieldListWrapper = (props: IFieldListContainerProps) =>
{
	const { className, expandable, setVisible, visible } = props;

	const [containerClass, setContainerClass] = useState<string>('open');
	const [buttonClass, setButtonClass] = useState<string>('buttonToClose');
	const [pullTab, setPullTab] = useState<JSX.Element>(<PullTabOpen />);

	const toggleVisible = useCallback(() =>
	{
		if (expandable)
		{
			setVisible(!visible);
			if (visible)
			{
				setContainerClass('closed');
				setButtonClass('buttonToOpen');
				setPullTab(<PullTabClosed />);
			}
			else
			{
				setContainerClass('open');
				setButtonClass('buttonToClose');
				setPullTab(<PullTabOpen />);
			}
		}
	}, [visible, expandable]);

	return (
		<div className={className + ' ' + containerClass}>
			<div className='inner'>
				{expandable && <div className={buttonClass} onClick={toggleVisible}>
					{pullTab}
				</div>}
				{props.children}
			</div>
		</div>
	);
};

export const FieldListContainer = styled(FieldListWrapper)`
	&.open {
		left: 70px;
		max-width: 557px;
		height: calc(100% - 58px); // subtract the value of the header height, fixed position does not know of it
		position: fixed;
		z-index: 998;
		transition: max-width 0.3s linear;
	}
	&.open .inner .content {
		transition: visibility 0s 0.3s linear;
	}
	&.open .inner .fieldListActionDrawer {
		transition: visibility 0s 0.3s linear;
	}
	&.closed {
		left: 70px;
		max-width: 21px;
		position: fixed;
		z-index: 998;
		height: calc(100% - 58px); // subtract the value of the header height, fixed position does not know of it
		background: ${themeGet('colors.backgroundLM')};
		transition: max-width 0.3s linear;
	}
	&.closed .inner {
		height: 100%;
		position: relative;
		background-color: ${themeGet('colors.backgroundLM')};
	}
	.inner {
		display: flex;
		height: 100%;
		position: relative;
		background-color: ${themeGet('colors.white')};
	}
	.inner .content {
		height: 100%;
		width: 382px;
		display: flex;
		flex-direction: column;
	}
	.buttonToOpen {
		position: absolute;
		left: 100%;
		cursor: pointer;
		transition: left 0.3s linear;
	}
	.buttonToClose {
		position: absolute;
		left: 100%;
		cursor: pointer;
		transition: left 0.3s linear
	}
	.filterButton {
		svg > path {
			fill: ${themeGet('colors.lightestGrey')};
		}
	}
	.section {
		padding: 16px 0;
		border-top: none;
		font-weight: ${themeGet('fontWeights.bold')};
		border-bottom: thin solid ${themeGet('colors.lightestGrey')};
		&.fieldSection {
			display: flex;
			flex-direction: column;
			border-bottom: none;
			flex-grow: 1;
		}
		&.groupSection {
			min-height: 100px;
			display: flex;
			flex-direction: column;
		}
	}
	.header {
		padding: 32px 0;
	}
	.addFieldButton {
		margin: 10px 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		svg > path {
			fill: ${themeGet('colors.primary')};
		}
		&:hover {
			svg > path {
				fill: ${themeGet('colors.white')};
			}
		}
	}
	.addfieldToExisting:disabled {
		svg > path {
			fill: ${themeGet('colors.lightGrey')};
		}
	}
`;

export const Footer = styled.div`
	height: 75px;
	width: 100%;
	margin-top: auto;
	background-color: ${themeGet('colors.backgroundLM')};
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	border-top: thin solid ${themeGet('colors.lightestGrey')};
	.bulkFieldEditButton, .bulkEditNamesButton {
		padding: 0px 27px;
		font-size: ${themeGet('fontSizes.normal')};
	}
`;