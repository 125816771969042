import { Tooltip } from 'antd';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as Ellipse } from '../../assets/images/Ellipse.svg';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { 
	clearSelectedFieldForEdit,
	clearSelectedFields,
	deleteDrawnFeatures,
	IInteractionMode,
	MapInteractionMode,
	setMapInteractionMode,
	toggleDrawingTools
} from '../../logic/store/UI/UISlice';
import { makeDispatch } from '../../logic/Utility/Utils';
import { ReactComponent as LeftCaret } from './Icons/LeftCaret.svg';

const BackToFieldList = (props: PropsFromRedux) =>
{
	const {
		ChangeInteractionMode,
		ClearAllSelectedFields,
		ClearSelectedFieldForEdit,
		DeleteDrawn,
		Growers,
		SelectedGrowerId, 
		SetDisplayDrawingTools,
	} = props;

	const theme = useTheme();

	const selectedGrower = Growers.find(g => g.Id === SelectedGrowerId);

	const backToFieldList = () =>
	{
		ClearAllSelectedFields();
		ClearSelectedFieldForEdit();
		DeleteDrawn();
		SetDisplayDrawingTools(false);
		ChangeInteractionMode({ mode: MapInteractionMode.Selection });
	};

	return (
		<div
			className='backTo_FieldList_Container'
			style={{
				display: 'flex',
				backgroundColor: theme.colors.backgroundLM,
				height: 52,
				border: '1px solid ' + theme.colors.lightestGrey,
				borderRight: 'none', width: '100%',
				padding: '10px 16px',
				alignItems: 'center',
			}}
		>
			<Link to='/fieldactivities' className='linkTo_FieldList' onClick={backToFieldList}>
				<LeftCaret style={{ marginRight: '20px' }} />
				<span style={{ color: theme.colors.lightGrey, }}>Fields List</span>
			</Link>
			<Ellipse style={{ margin: '0 10px' }} />
			<Tooltip title={selectedGrower?.Name} placement='bottom' >
				<span style={{
					fontWeight: theme.fontWeights.bold,
					fontSize: theme.fontSizes.large,
					width: '60%',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}}>
					{selectedGrower?.Name}
				</span>
			</Tooltip>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	Growers: state.grower.Growers,
	SelectedGrowerId: state.ui.SelectedGrowerId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ChangeInteractionMode: (mode: IInteractionMode) => dispatch(setMapInteractionMode(mode)),
		ClearAllSelectedFields: () => dispatch(clearSelectedFields()),
		ClearSelectedFieldForEdit: makeDispatch(dispatch, clearSelectedFieldForEdit),
		DeleteDrawn: () => dispatch(deleteDrawnFeatures()),
		SetDisplayDrawingTools: (display: boolean) => dispatch(toggleDrawingTools(display)),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const ConnectedBackToFieldList = connector(BackToFieldList);
