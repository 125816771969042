import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Slider } from 'antd';

const InputSliderContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const SliderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 120px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	margin-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
`;

const SliderButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	color: ${themeGet('colors.white')};
	font-weight: bold;
	font-size: ${themeGet('fontSizes.xLarge')};
	cursor: pointer;
	user-select: none;
`;

const StyledSlider = styled(Slider)`
	padding: 0px;
	margin: 0px 15px;

	.ant-slider-rail {
		height: 10px;
		background-color: ${themeGet('colors.lightestGrey')};
		border-radius: 5px;
	}

	.ant-slider-handle:focus {
		box-shadow: 0 0 0 5px ${themeGet('colors.secondary')}60;
	}
`;

interface IInputSliderProps {
	min?: number;
	max?: number;
	sliderValue: number;
	onChange: (value: number) => void;
	displayTooltip: boolean;
}

export const InputSlider = (props: IInputSliderProps) =>
{
	const { displayTooltip, min, max, sliderValue, onChange } = props;

	const theme = useTheme();

	const minDefault = -10;
	const maxDefault = 10;
	const step = 1;

	const [minimum, setMinimum] = useState<number>(min ?? minDefault);
	const [maximum, setMaximum] = useState<number>(max ?? maxDefault);

	const getValueTooltip = (value: number): string =>
	{
		// Ignore the actual changed value and display the formatted percent modifier
		return `${sliderValue > 0 ? '+' : ''}${sliderValue ? Math.round(sliderValue) : sliderValue === 0 ? '0' : ''}%`;
	};

	const increment = () =>
	{
		onChange(sliderValue + step > maximum ? maximum : sliderValue + step);
	};

	const decrement = () =>
	{
		onChange(sliderValue - step < minimum ? minimum : sliderValue - step);
	};

	const change = (value: number) =>
	{
		onChange(value);
	};

	return (
		<InputSliderContainer>
			<SliderContainer>
				<SliderButton
					className='Slider_Decrement_Button'
					style={{ backgroundColor: 'black' }}
					onClick={decrement}
				>
					—
				</SliderButton>
				<StyledSlider
					className='Slider'
					min={minimum}
					max={maximum}
					value={sliderValue}
					onChange={(value: number) => change(value)}
					step={step}
					tooltip={{ 
						formatter: (value: number) => getValueTooltip(value),
						open: displayTooltip,
						getPopupContainer: (e) => document.querySelector('.Slider') // Necessary to make the tooltip stay with the parent on a scroll event
					}}
					style={{ width: '70%' }}
					trackStyle={{ backgroundColor: 'transparent' }}
					handleStyle={{ backgroundColor: theme.colors.secondary, border: 'none', height: 20, width: 20 }}
				/>
				<SliderButton
					className='Slider_Increment_Button'
					style={{ backgroundColor: theme.colors.secondary }}
					onClick={increment}
				>
					+
				</SliderButton>
			</SliderContainer>
		</InputSliderContainer>
	);
};