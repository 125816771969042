import React, { useCallback, useEffect, useState } from 'react';
import { themeGet } from '@styled-system/theme-get';
import { InputNumber } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { IAttributeSummary, IOrderPlanFieldAssignment } from '../../../logic/Models/Responses/OrderPlanResponse';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as RightCaret } from '../Icons/RightCaret.svg';
import { FieldInventoryItem } from './FieldInventoryItem';
import { CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { ReactComponent as AlertIcon } from '../Icons/AlertIcon.svg';
import { RoundWithPrecision } from '../../../logic/Utility/CalculationUtilities';
import { Geometry } from '@turf/turf';
import { dynamicSort } from '../../../logic/Utility/Utils';

const FieldInventoryContainer = styled.div`
	min-width: 382px;
	max-width: 382px;
	background-color: ${themeGet('colors.white')};
	display: flex;
	flex-direction: column;
`;

const CrumbContainer = styled.div`
	align-items: center;
	background-color: #F3F4F6;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 35px;
	padding-left: 17px;
	padding-right: 25px;
`;

const FieldInventoryInnerContainer = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	padding-bottom: 10px;
	width: 100%;
`;

const YieldContainer = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
`;

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;

export interface IExtendedOrderPlanFieldItem extends IOrderPlanFieldAssignment
{
	AttributeRatings: {[key: string]: number}; // Attribute Id is the key, value is the rating for the specific attribute
	Color: string;
	CropId: string;
	MaxFieldAcreage: number;
	MaxPopulationRate: number; // This can either be the default max population rate or the +20% to the predicted yield expected rate value, as long as it calculates lower than the actual max
	MinPopulationRate: number; // This can either be the default mmin population rate or the -20% to the predicted yield expected rate value, as long as it calculates higher than the actual min
	Selected: boolean;
	SeriesId: string;
	SeriesName: string;
}

export interface IFieldInventoryDetails
{
	Acres: number;
	Boundary: Geometry;
	CropColor: string; // The color of the current field's crop
	CropId: string;
	HybridColor: string; // The color of the hybrid with the most acreage on the field
	Id: string;
	Inventory: IExtendedOrderPlanFieldItem[];
	Name: string;
	PredictedYield: number;
	TargetYield: number;
}

interface IFieldInventoryViewProps
{
	attributeList: IAttributeSummary[];
	displayView: boolean;
	fieldDetails: IFieldInventoryDetails;
	fieldNumberInfo: string; // Information for the Header to display which position in the array the field is, ie. Field 1 out of 10
	changeHybridSelection: (hybridId: string, checked: boolean) => void;
	close: () => void;
	editTargetYield: (value: number) => void;
	onNext: () => void;
	onPrevious: () => void;
	updateFieldHybridAcres: (hybridId: string, value: number) => void;
	updateFieldHybridRate: (hybridId: string, value: number) => void;
}

export const FieldInventoryView = (props: IFieldInventoryViewProps) =>
{
	const {
		attributeList,
		displayView,
		fieldDetails,
		fieldNumberInfo,
		changeHybridSelection,
		close,
		editTargetYield,
		onNext,
		onPrevious,
		updateFieldHybridAcres,
		updateFieldHybridRate,
	} = props;

	const theme = useTheme();

	const [agronomicOptions, setAgronomicOptions] = useState<IOptionItem[]>([]);
	const [selectedAgronomic, setSelectedAgronomic] = useState<string>('Match Strength');

	useEffect(() =>
	{
		const agronomicOptions:IOptionItem[] = 
		[
			{
				label: <div>Match Strength</div>,
				value: 'Match Strength'
			},
		];

		if (attributeList)
		{
			attributeList.forEach(attribute =>
			{
				agronomicOptions.push(
					{
						label: <div>{attribute.Name}</div>,
						value: attribute.Id
					}
				);
			});
		}

		setAgronomicOptions(agronomicOptions);

	},[attributeList]);

	const remainingAcres = fieldDetails.Inventory.map(item => item.Acres).reduce((prev, current) =>
		RoundWithPrecision(prev - current, 1, 1), RoundWithPrecision(fieldDetails.Acres, 1, 1));

	const onChangeAgronomic = (value: string) =>
	{
		setSelectedAgronomic(value);
	};

	const onChangeFieldSeedSelection = (hybridId: string, checked: boolean) =>
	{
		changeHybridSelection(hybridId, checked);
	};

	const onUpdateFieldTargetYieldOnBlur = useCallback((evt: React.FocusEvent<HTMLInputElement>) =>
	{
		const newValue = evt.currentTarget.value ? Number(evt.currentTarget.value) : Math.round(fieldDetails.TargetYield);
		if (fieldDetails.TargetYield !== newValue)
		{
			editTargetYield(newValue);
		}
	},[fieldDetails, editTargetYield]);

	const onUpdatefieldTargetYieldOnEnter = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) =>
	{
		const newValue = evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
			: Math.round(fieldDetails.TargetYield);
		if (fieldDetails.TargetYield !== newValue)
		{
			editTargetYield(newValue);
		}
	},[fieldDetails, editTargetYield]);

	return (
		displayView && fieldDetails ? 
			<FieldInventoryContainer className='MaxScript_FieldInventoryContainer'>
				<CrumbContainer className='MaxScript_FieldInventory_PreviousNextContainer'>
					<div style={{ cursor: 'pointer' }} onClick={onPrevious}>
						<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
						<span style={{ color: theme.colors.darkGrey }}>{'Previous'}</span>
					</div>
					<div style={{ paddingLeft: '15%' }}>{fieldNumberInfo}</div>
					<div style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={onNext}>
						<span style={{ color: theme.colors.darkGrey }}>{'Next'}</span>
						<RightCaret style={{ marginLeft: '15px', position: 'relative', top: 2 }} />
					</div>
				</CrumbContainer>
				<FieldInventoryInnerContainer>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
						<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.normal, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
							{fieldDetails.Name}
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', fontSize: theme.fontSizes.small }}>
							<div>{RoundWithPrecision(fieldDetails.Acres, 1, 1).toFixed(1) + ' acres'}</div>
							<div style={{ marginLeft: 'auto', color: theme.colors.brightOrange }}>
								{
									Math.abs(fieldDetails.Acres - remainingAcres) <= fieldDetails.Acres ?
										<div>{`(${remainingAcres} remaining)`}</div>
										:
										<div>
											<AlertIcon style={{ position: 'relative', top: 3, marginRight: 5 }} />
											{`${Math.abs(remainingAcres)} acres over`}
										</div>
								}
							</div>
						</div>
					</div>
					<YieldContainer>
						<div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Target Yield:</div>
							<div style={{ fontWeight: 700, paddingLeft: 20, textAlign: 'right' }}>
								<NumberInput
									style={{ width: 60 }}
									type='number'
									className='Target_Yield_Input'
									value={fieldDetails.TargetYield}
									min={CropConfig()[fieldDetails.CropId].minYield}
									onBlur={onUpdateFieldTargetYieldOnBlur}
									onPressEnter={onUpdatefieldTargetYieldOnEnter}
								/>
							</div>
						</div>
						<div style={{ fontSize: theme.fontSizes.extraSmall, paddingTop: 5 }}>{`(Predictive Yield: ${fieldDetails.PredictedYield})`}</div>
					</YieldContainer>
					<div style={{ marginTop: 20, border: '1px solid black' }}>
						<Dropdown
							variant='light'
							options={agronomicOptions}
							onSelect={onChangeAgronomic}
							selected={selectedAgronomic}
							placeholder='Choose an attribute'
							className='Attribute_Dropdown'
						/>
					</div>
					<div className='Field_Inventory_Items' style={{ marginTop: 20, overflowY: 'auto' }}>
						{
							fieldDetails.Inventory.filter(i => !i.IsExternal).sort(dynamicSort('MatchStrength', 'descending')).map(item =>
								<FieldInventoryItem
									key={`FieldInventory_${item.HybridId}`}
									fieldInventoryItem={item}
									agronomicSelection={selectedAgronomic}
									onChangeSeedSelection={onChangeFieldSeedSelection}
									updateFieldHybridAcres={updateFieldHybridAcres}
									updateFieldHybridRate={updateFieldHybridRate}
									remainingAcres={remainingAcres}
								/>
							)
						}
						{
							fieldDetails.Inventory.filter(i => i.IsExternal).sort(dynamicSort('HybridId')).map(item =>
								<FieldInventoryItem
									key={`FieldInventory_${item.HybridBrand}_${item.HybridName}_${item.RelativeMaturity}`}
									fieldInventoryItem={item}
									agronomicSelection={selectedAgronomic}
									onChangeSeedSelection={onChangeFieldSeedSelection}
									updateFieldHybridAcres={updateFieldHybridAcres}
									updateFieldHybridRate={updateFieldHybridRate}
									remainingAcres={remainingAcres}
								/>
							)
						}
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20, marginTop: 'auto' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginLeft: 'auto',
								marginTop: 'auto',
								paddingTop: 10,
								paddingBottom: 10
							}}
						>
							<Button style={{ height: 'auto', width: 75, marginRight: 15 }} variant='outlined' onClick={close}>Close</Button>
						</div>
					</div>
				</FieldInventoryInnerContainer>
			</FieldInventoryContainer>
			:
			<React.Fragment />
	);
};