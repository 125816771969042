import { getCurrentActingUser } from './AuthSlice';
import { sleepOnIt } from '../../Utility/Utils';
import { AppDispatch, RootState } from '../Store';
import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IGrowerFieldHealthRequest
{
	SelectedGrowerId: string;
}

export const downloadGrowerFieldHealthPdf = createTracedAsyncThunk<Blob, IGrowerFieldHealthRequest, { dispatch: AppDispatch, state: RootState }>(
	'growerfieldhealth/download',
	async (context, request: IGrowerFieldHealthRequest, thunkAPI) =>
	{
		try
		{
			const { auth } = thunkAPI.getState();
			const api = new Api('/api/6', auth.userAuthToken, context);

			const currentActingUser = getCurrentActingUser(thunkAPI.getState());
			const pdfSession = await api.getAsync<string>(`growers/${request.SelectedGrowerId}/fieldhealthpdf`);

			let pdfData = undefined;
			do 
			{
				// wait half a second for each look
				await sleepOnIt(500);
				pdfData = await api.getFileAsync(`pdfs/fieldhealthpdf/${pdfSession.Data}`);
			} while(!pdfData);

			const { name, data } = pdfData;

			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the PDF data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/pdf'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Store this file as a hidden 'a' element and then automatically click it.
				const link = document.createElement('a');
				link.href = (window.URL || window['webkitURL']).createObjectURL(data);

				// The renderer always adds ".pdf" to the filename. If there's more than that, use it. Otherwise fall back on the session name.
				link.download = name && name.slice(0, name.indexOf('.pdf'))
					? name : `${currentActingUser.UserName}'s Plan`;

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);