import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { Input } from 'antd';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import TextArea from 'antd/lib/input/TextArea';

export interface IJdConnectInvitationModalProps
{
	qrCode: string;
	link: string;
	visible: boolean;
	isLoading: boolean;
	onCancel: () => void;
}

const InputTextField = styled(Input)`
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
		box-shadow: 0 0 1pt 1pt white;
		outline: none;
	}
	::selection {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
`;

export const JdConnectInvitationModal = (props: IJdConnectInvitationModalProps) =>
{
	const { qrCode, link, visible, isLoading, onCancel } = props;

	const theme = useTheme();

	const copyToClipboard = () =>
	{
		navigator.clipboard.writeText(link);
	};

	return (
		<StyledModal
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>{'Add Grower\'s John Deere Ops Data'}</div>
			}
			open={visible}
			width={750}
			closable={true}
			onCancel={onCancel}
			destroyOnClose={true}
		>
			{
				!isLoading && qrCode && link &&
				<div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: -30 }}>
					<div style={{ marginBottom: 10 }}>
					You can send this link or QR Code to the grower. On scanning the QR code, a login page to John Deere will open on their device's browser.
					</div>
					<TextArea value={link} style={{ marginBottom: 10 }} readOnly autoSize />
					<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, alignItems: 'center' }}>
						<Button variant='main' width={'150px'} onClick={copyToClipboard} style={{ marginBottom: 10 }}>Copy Link</Button>
					</div>
					<img src={`data:image/png;base64,${qrCode}`} alt="QR Code" style={{ width: 200, height: 200, alignSelf: 'center' }} />
					<div style={{ marginTop: 20 }}>
						<Button variant='dark' width={'100%'} onClick={onCancel}>Close</Button>
					</div>
				</div>
			}
		</StyledModal>
	);
};