import React, { useState, ReactNode } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { ReactComponent as CaretDown } from './Icons/CaretDown.svg';
import { BoolDictionary } from '../../logic/store/UI/UISlice';

const StyledRowContainer = styled.div<{ columnSpacing?: string }>`
	flex-grow: 1;
	margin: 0 .3% 15px .3%;
	box-shadow: 0 0 3px 0px ${themeGet('colors.lightGrey')};
	border-radius: 6px;
`;
export const StyledRow = styled.div<{ columnSpacing?: string }>`
	grid-template-columns: ${props => props.columnSpacing};
	grid-auto-flow: column;
	display: grid;
	padding-right: 26px;
	height: 70px;
	padding-left: 36px;
	color: ${themeGet('colors.darkGrey')};
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.extraSmall')};
	align-items: center;
	overflow: auto;
	border-radius: 6px;
	background-color: white;
	::-webkit-scrollbar {
		display: none;
	}
	&.padding-small {
		padding: 0 1.5%;
	}
	&.height-small {
		height: 50px;
	}
	&.disabled {
		background-color: ${themeGet('colors.lightestGrey')};
	}
`;
export const StyledRowCell = styled.div`
	display: flex;
	justify-items: center;
	justify-content: center;
	align-content: center;
	margin: auto;
	position: relative;
    align-items: center;
	svg {
		position: absolute;
	}
`;
const StyledExpandedRow = styled.div<{ columnSpacing?: string }>`
	position: relative;
	width: 100%;
	min-height: 150px;
	flex-flow: column;
`;
export const StyledCaret = styled(CaretDown) <{ expanded: 'true' | 'false' }>`
	transform: ${props => props.expanded === 'true' ? 'rotate(180deg)' : 'none'};
`;
export interface ITableRowProps extends React.HTMLAttributes<HTMLDivElement>
{
	Id: string;
	items: ReactNode[];
	columnSpacing?: string;
	expanded?: boolean;
	expandedSection?: ReactNode;
	hideExpand?: boolean;
	setExpanded?: (idEntry: BoolDictionary) => void;
}

const TableRowComponent = (props: ITableRowProps) =>
{
	const {
		Id,
		className,
		columnSpacing,
		expanded: expandedFromProps,
		expandedSection,
		hideExpand,
		items,
		setExpanded: setExpandedSlice,
		style
	} = props;
	const [expanded, setExpanded] = useState(expandedFromProps);
	const onExpand = () => 
	{
		const toSet = !expanded;
		setExpanded(toSet);
		setExpandedSlice({ [Id]: toSet });
	};

	return <StyledRowContainer columnSpacing={columnSpacing} style={style}>
		<StyledRow columnSpacing={columnSpacing} className={className}>
			{items.map(item => item)}
			{!hideExpand &&
				<div style={{ display: 'grid', justifyItems: 'end', alignItems: 'center', height: '100%' }} onClick={() => onExpand()} >
					<StyledCaret className='RowExpander' expanded={expanded ? 'true' : 'false'} />
				</div>
			}
		</StyledRow>
		{!hideExpand && <StyledExpandedRow className={className} style={{ display: !expanded ? 'none' : 'flex' }}>
			{expandedSection}
		</StyledExpandedRow>}
	</StyledRowContainer>;
};

export const TableRow = React.memo(TableRowComponent);