import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Switch } from 'antd';

const StyledSwitchComponent = styled(Switch)`
	&.ant-switch-checked {
		background-color: ${themeGet('colors.secondary')};
	}
`;

const StyledSwitchMainContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledSwitchInnerContainer = styled.div`
	display: flex;
	align-items: center;
`;

interface IStyledSwitchProps
{
	checked: boolean;
	checkedLabel: string;
	disabled?: boolean;
	flexDirection: 'row' | 'column';
	onChange: () => void;
	title: string;
	uncheckedLabel: string;
}

export const StyledSwitch = (props: IStyledSwitchProps) =>
{
	const { checked, checkedLabel, disabled, onChange, title, uncheckedLabel, flexDirection } = props;

	const theme = useTheme();

	return (
		<StyledSwitchMainContainer style={{ flexDirection: flexDirection }}>
			<div
				style={{
					width: flexDirection === 'row' ? 90 : 140,
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					fontWeight: theme.fontWeights.bold,
					fontSize: theme.fontSizes.normal,
					color: theme.colors.darkGrey
				}}>{title}</div>
			<StyledSwitchInnerContainer style={{ paddingTop: flexDirection === 'row' ? 0 : 5}}>
				<StyledSwitchComponent disabled={disabled} style={{ marginRight: 10 }} className={'StyledSwitch'} onChange={onChange} checked={checked} />
				<span style={{ fontWeight: theme.fontWeights.normal, color: theme.colors.darkGrey }}>{checked ? checkedLabel : uncheckedLabel}</span>
			</StyledSwitchInnerContainer>
		</StyledSwitchMainContainer>
	);
};