import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { IHeaderProps } from './Header';
import { useAmplitudePassthrough, useNavigationEvent } from '../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../tracing/session';
import { EventStructureTags } from '../../tracing/EventTagNames';
import { EventNavigationNames } from '../../tracing/EventNames';

interface IDataHubProps extends IHeaderProps
{
	openPpgModal: () => void;
}

export const DataHubMenu = (props: IDataHubProps) =>
{
	const {
		CanSsoLogistics,
		IsLoadingUserInfo,
		UserIsGrower,
		UserCanSeePPG,
		openPpgModal,
		LaunchProductAnalyzer,
		RedirectToGhxLogistics,
	} = props;

	const session = useScopedSession(DataHubMenu.name, {
		[EventStructureTags.PageContext]: 'data_hub_menu',
		[EventStructureTags.PageUrl]: window.location.toString()
	});
	const trackLogistics = useAmplitudePassthrough(session, EventNavigationNames.To, RedirectToGhxLogistics, [RedirectToGhxLogistics], {
		[EventStructureTags.DestinationName]: 'logistics'
	});

	const launchProductAnalzyerForCurrentUser = () =>
	{
		if (IsLoadingUserInfo) return;

		LaunchProductAnalyzer();
	};
	const trackProductAnalyzer = useAmplitudePassthrough(session,  EventNavigationNames.To, launchProductAnalzyerForCurrentUser, [launchProductAnalzyerForCurrentUser], {
		[EventStructureTags.DestinationName]: 'product_analyzer'
	});

	const trackPlotBook = useNavigationEvent(session, 'plotbook', 'https://dav.syngentadigitalapps.com/plotbook/');
	const trackDroneDeploy = useNavigationEvent(session, 'dronedeploy', 'https://www.dronedeploy.com/app2/projects');

	const menuItems: ItemType[] = [];

	if (CanSsoLogistics && !UserIsGrower)
	{
		menuItems.push(
			{
				key: 'GHXLogistics',
				label: <a
					onClick={trackLogistics}
				>GHXLogistics</a>
			}
		);
	}

	// Common menu items
	menuItems.push(
		{
			key: 'Product Analyzer',
			label: <a 
				onClick={trackProductAnalyzer}
				className='HeaderDataHubProductAnalyzer'
			>Product Analyzer</a>
		},
		{
			key: 'Plot Book',
			label: <a 
				href="https://dav.syngentadigitalapps.com/plotbook/"
				rel="noreferrer"
				target="_blank"
				className='HeaderDataHubPlotBook'
				onClick={() => trackPlotBook()}
			>Plot Book</a>
		},
		{
			key: 'Drone Deploy',
			label: <a 
				href="https://www.dronedeploy.com/app2/projects"
				rel="noreferrer"
				target="_blank"
				className='HeaderDataHubDroneDeploy'
				onClick={() => trackDroneDeploy()}
			>Drone Deploy</a>
		},
	);

	if (UserCanSeePPG)
	{
		menuItems.push(
			{
				key: 'Product Placement Guide (PPG)',
				label: <a
					onClick={openPpgModal}
					className='HeaderDataHubPpg'
				>Product Placement Guide (PPG)</a>
			}
		);
	}

	return menuItems;
};