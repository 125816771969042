import React, { useState } from 'react';
import { Modal, ModalProps, Radio } from 'antd';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { IconButton } from '../../components/IconButton/IconButton';
import { Button } from '../../components/Button/Button';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { CheckboxProps } from 'antd';

export enum GrowerSegment
{
	Upsell,
	Retain,
	CrossSell,
	Reacquire,
	Acquire,
	Unknown,
	TwentyFiveToThrive,
	Agro20,
	GA_Approved, // All GA_ are Golden Advantage
	GA_Pending,
	GA_Rejected,
	GA_Approved_Pending_Repayment,
	GA_Open,
}

export const GrowerSegmentNames: {[key in GrowerSegment] : string } = {
	[GrowerSegment.Upsell] : 'Upsell',
	[GrowerSegment.Retain] : 'Retain',
	[GrowerSegment.CrossSell] : 'Cross Sell',
	[GrowerSegment.Reacquire] : 'Re-Acquire',
	[GrowerSegment.Acquire] : 'Acquire',
	[GrowerSegment.Unknown] : 'Unknown',
	[GrowerSegment.TwentyFiveToThrive] : '25 To Thrive',
	[GrowerSegment.Agro20] : 'Agro 20',
	[GrowerSegment.GA_Approved] : 'GA: Approved',
	[GrowerSegment.GA_Approved_Pending_Repayment] : 'GA: Approved Pending Repayment',
	[GrowerSegment.GA_Pending] : 'GA: Pending',
	[GrowerSegment.GA_Rejected] : 'GA: Rejected',
	[GrowerSegment.GA_Open] : 'GA: Open',
};

export const GrowerSegmentKeys: { [key in string] : GrowerSegment } = {
	'Upsell' : GrowerSegment.Upsell,
	'Retain' : GrowerSegment.Retain,
	'Cross Sell' : GrowerSegment.CrossSell,
	'Re-Acquire' : GrowerSegment.Reacquire,
	'Acquire' : GrowerSegment.Acquire,
	'Unknown' : GrowerSegment.Unknown,
	'25 To Thrive' : GrowerSegment.TwentyFiveToThrive,
	'Agro 20' : GrowerSegment.Agro20,
	'GA: Approved' : GrowerSegment.GA_Approved,
	'GA: Approved Pending Repayment' : GrowerSegment.GA_Approved_Pending_Repayment,
	'GA: Pending' : GrowerSegment.GA_Pending,
	'GA: Rejected' : GrowerSegment.GA_Rejected,
	'GA: Open' : GrowerSegment.GA_Open,
};

export const GrowerSegmentColors = (theme: DefaultTheme) =>
{
	const segmentColors = {
		[GrowerSegment.Upsell]: theme.colors.purpleLM,
		[GrowerSegment.Retain]: theme.colors.pinkLM,
		[GrowerSegment.CrossSell]: theme.colors.greenLM,
		[GrowerSegment.Reacquire]: theme.colors.blueLM,
		[GrowerSegment.Acquire]: theme.colors.ghxOrangeLM,
		[GrowerSegment.Unknown]: theme.colors.redLM,
		[GrowerSegment.TwentyFiveToThrive]: theme.colors.purple,
		[GrowerSegment.Agro20]: theme.colors.blue,
		[GrowerSegment.GA_Approved]: theme.colors.lightBlueGA,
		[GrowerSegment.GA_Approved_Pending_Repayment]: theme.colors.blue,
		[GrowerSegment.GA_Pending]: theme.colors.yellowGA,
		[GrowerSegment.GA_Rejected]: theme.colors.redGA,
		[GrowerSegment.GA_Open]: theme.colors.orderGreen,
	};

	return segmentColors;
};

const SegmentCheckboxContainer = styled.label`
	width: 50%;
	margin: 10px 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const SegmentCheckboxLabel = styled.div<{ color: string }>`
	width: 110px;
	height: 25px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 12px;
	color: ${themeGet('colors.white')};
	background-color: ${props => props.color};
	border-radius: 10px;
`;

interface ISegmentCheckboxProps extends CheckboxProps
{
	color: string;
	label: string;
}

/** @component */
const SegmentCheckbox = (props: ISegmentCheckboxProps) => 
{
	const { label, name, color } = props;
	return (
		<SegmentCheckboxContainer htmlFor={`checkbox_${label}`}>
			<Checkbox id={`checkbox_${label}`} className={`checkbox_${label}`} {...props} />
			<SegmentCheckboxLabel color={color} className={`pill_${label}`}>
				{label}
			</SegmentCheckboxLabel>
		</SegmentCheckboxContainer>
	);
};

const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: ${themeGet('fontSizes.xLarge')};
	font-weight: ${themeGet('fontWeights.bold')};
`;

const Body = styled.div`
	height: 100%;
	padding: 30px 0px;
`;

const Section = styled.div`
	padding: 20px 0px;
	border-top: thin solid ${themeGet('colors.lightGrey')};
`;

const SectionLabel = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-size: ${themeGet('fontSizes.normal')};
	font-weight: ${themeGet('fontWeights.bold')};
`;

const InputRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	font-size: ${themeGet('fontSizes.small')};
	margin: 10px 0px;

	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
`;

const InputRowLabel = styled.label`
	font-weight: ${themeGet('fontWeights.bold')};
	margin-right: 20px;
`;

const OrangeRadio = styled(Radio)`
	.ant-radio-checked .ant-radio-inner {
		border-color: ${themeGet('colors.primary')} !important ;

		:after {
			background-color: ${themeGet('colors.primary')};
		}
	}

	.ant-radio:hover .ant-radio-inner {
		border-color: ${themeGet('colors.primary')};
	}
`;

const InputText = styled.input`
	width: 90px;
	border: none;
	border-bottom: thin solid black;
	outline: none;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

export interface IGrowerFilter
{
	segmentValues?: number[];
	enogenContractDate?: Date;
	acresMin?: number;
	acresMax?: number;
}

interface IModalProps extends ModalProps
{
	onApply: (filters: IGrowerFilter) => void;
	height?: string | number;
	width?: string | number;
}

/** @component */
export const GrowerFilterModal = (props: IModalProps) =>
{
	// Props and input
	const { height, width, onApply, onCancel } = props;

	const theme = useTheme();

	// State
	const [upsellSegmentChecked, setUpsellSegmentChecked] = useState<boolean>(false);
	const [retainSegmentChecked, setRetainSegmentChecked] = useState<boolean>(false);
	const [crosssellSegmentChecked, setCrosssellSegmentChecked] = useState<boolean>(false);
	const [reacquireSegmentChecked, setReacquireSegmentChecked] = useState<boolean>(false);
	const [acquireSegmentChecked, setAcquireSegmentChecked] = useState<boolean>(false);
	const [unknownSegmentChecked, setUnknownSegmentChecked] = useState<boolean>(false);
	const [thriveSegmentChecked, setThriveSegmentChecked] = useState<boolean>(false);
	const [agroSegmentChecked, setAgroSegmentChecked] = useState<boolean>(false);
	const [enogenContractPreviousYear, setEnogenContractPreviousYear] = useState<boolean>(false);
	const [enogenContractCurrentYear, setEnogenContractCurrentYear] = useState<boolean>(false);
	const [gaApprovedChecked, setGAApprovedChecked] = useState<boolean>(false);
	const [gaApprovedPendingChecked, setGAApprovedPendingChecked] = useState<boolean>(false);
	const [gaPendingChecked, setGAPendingChecked] = useState<boolean>(false);
	const [gaRejectedChecked, setGARejectedChecked] = useState<boolean>(false);
	const [gaOpenChecked, setGAOpenChecked] = useState<boolean>(false);
	const [acresMinimum, setAcresMinimum] = useState('');
	const [acresMaximum, setAcresMaximum] = useState('');

	const buildGrowerFilter = (): IGrowerFilter =>
	{
		const filter: IGrowerFilter = {
			segmentValues: undefined,
			enogenContractDate: null,
			acresMin: undefined,
			acresMax: undefined
		};

		// Segments
		if (upsellSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.Upsell);
		}
		if (retainSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.Retain);
		}
		if (crosssellSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.CrossSell);
		}
		if (reacquireSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.Reacquire);
		}
		if (acquireSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.Acquire);
		}
		if (unknownSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.Unknown);
		}
		if (thriveSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.TwentyFiveToThrive);
		}
		if (agroSegmentChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.Agro20);
		}

		// Golden Advantage
		if (gaApprovedChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.GA_Approved);
		}
		if (gaApprovedPendingChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.GA_Approved_Pending_Repayment);
		}
		if (gaPendingChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.GA_Pending);
		}
		if (gaRejectedChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.GA_Rejected);
		}
		if (gaOpenChecked)
		{
			if (!filter.segmentValues) filter.segmentValues = [];
			filter.segmentValues.push(GrowerSegment.GA_Open);
		}

		// Enogen contract date
		if (enogenContractPreviousYear) filter.enogenContractDate = new Date(new Date().getFullYear() - 1, 0);
		if (enogenContractCurrentYear) filter.enogenContractDate = new Date(new Date().getFullYear(), 0);

		// Acres
		if (acresMinimum) filter.acresMin = Number.parseInt(acresMinimum);
		if (acresMaximum) filter.acresMax = Number.parseInt(acresMaximum);

		return filter;
	};

	const clearAllInput = () =>
	{
		// Checkboxes
		setUpsellSegmentChecked(false);
		setRetainSegmentChecked(false);
		setCrosssellSegmentChecked(false);
		setReacquireSegmentChecked(false);
		setAcquireSegmentChecked(false);
		setUnknownSegmentChecked(false);
		setThriveSegmentChecked(false);
		setAgroSegmentChecked(false);

		// Golden Advantage checkboxes
		setGAApprovedChecked(false);
		setGAApprovedPendingChecked(false);
		setGAPendingChecked(false);
		setGARejectedChecked(false);
		setGAOpenChecked(false);

		// Radios
		setEnogenContractPreviousYear(false);
		setEnogenContractCurrentYear(false);

		// Text input
		setAcresMinimum('');
		setAcresMaximum('');
	};

	return (
		<Modal
			{...props}
			footer={null}
			width={width ?? 500}
			bodyStyle={{ height: height ?? 'fit-content' }}
			style={{
				position: 'absolute',
				top: 50, right: 40
			}}
			closable={false}
		>
			<Container>
				<Header>
					Filter - Plan Phase
					<IconButton variant='close' onClick={(e) => onCancel(e)} />
				</Header>
				<Body>
					<div className='section_segment'>
						<SectionLabel>Segment</SectionLabel>
						<div style={{ display: 'flex', flexFlow: 'row wrap', padding: '20px 0px' }}>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.Upsell]}
								color={GrowerSegmentColors(theme)[GrowerSegment.Upsell]}
								checked={upsellSegmentChecked}
								onChange={(e) => setUpsellSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.Upsell}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.Retain]}
								color={GrowerSegmentColors(theme)[GrowerSegment.Retain]}
								checked={retainSegmentChecked}
								onChange={(e) => setRetainSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.Retain}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.CrossSell]}
								color={GrowerSegmentColors(theme)[GrowerSegment.CrossSell]}
								checked={crosssellSegmentChecked}
								onChange={(e) => setCrosssellSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.CrossSell}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.Reacquire]}
								color={GrowerSegmentColors(theme)[GrowerSegment.Reacquire]}
								checked={reacquireSegmentChecked}
								onChange={(e) => setReacquireSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.Reacquire}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.Acquire]}
								color={GrowerSegmentColors(theme)[GrowerSegment.Acquire]}
								checked={acquireSegmentChecked}
								onChange={(e) => setAcquireSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.Acquire}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.Unknown]}
								color={GrowerSegmentColors(theme)[GrowerSegment.Unknown]}
								checked={unknownSegmentChecked}
								onChange={(e) => setUnknownSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.Unknown}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.TwentyFiveToThrive]}
								color={GrowerSegmentColors(theme)[GrowerSegment.TwentyFiveToThrive]}
								checked={thriveSegmentChecked}
								onChange={(e) => setThriveSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.TwentyFiveToThrive}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.Agro20]}
								color={GrowerSegmentColors(theme)[GrowerSegment.Agro20]}
								checked={agroSegmentChecked}
								onChange={(e) => setAgroSegmentChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.Agro20}`}
							/>
						</div>
						<SectionLabel>Golden Advantage</SectionLabel>
						<div style={{ display: 'flex', flexFlow: 'row wrap', padding: '20px 0px' }}>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.GA_Approved].replace('GA:', '')}
								color={GrowerSegmentColors(theme)[GrowerSegment.GA_Approved]}
								checked={gaApprovedChecked}
								onChange={(e) => setGAApprovedChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.GA_Approved}`}
							/>
							<SegmentCheckbox
								label={'Pending Repay'} // Name shortened because Approved Pending Repayment does not fit in the modal
								color={GrowerSegmentColors(theme)[GrowerSegment.GA_Approved_Pending_Repayment]}
								checked={gaApprovedPendingChecked}
								onChange={(e) => setGAApprovedPendingChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.GA_Approved_Pending_Repayment}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.GA_Pending].replace('GA:', '')}
								color={GrowerSegmentColors(theme)[GrowerSegment.GA_Pending]}
								checked={gaPendingChecked}
								onChange={(e) => setGAPendingChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.GA_Pending}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.GA_Open].replace('GA:', '')}
								color={GrowerSegmentColors(theme)[GrowerSegment.GA_Open]}
								checked={gaOpenChecked}
								onChange={(e) => setGAOpenChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.GA_Open}`}
							/>
							<SegmentCheckbox
								label={GrowerSegmentNames[GrowerSegment.GA_Rejected].replace('GA:', '')}
								color={GrowerSegmentColors(theme)[GrowerSegment.GA_Rejected]}
								checked={gaRejectedChecked}
								onChange={(e) => setGARejectedChecked(e.target.checked)}
								className={`checkbox_${GrowerSegment.GA_Rejected}`}
							/>
						</div>
					</div>
					{/* <Section className='section_enogen'>
						<SectionLabel>Enogen Contract</SectionLabel>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<InputRow>
								<InputRowLabel>Previous Year</InputRowLabel>
								<Radio.Group
									value={enogenContractPreviousYear}
									onChange={(e) => setEnogenContractPreviousYear(e.target.value)}
								>
									<OrangeRadio className='radio_previous_year_yes' value={true}>Yes</OrangeRadio>
									<OrangeRadio className='readio_previous_year_no' value={false}>No</OrangeRadio>
								</Radio.Group>
							</InputRow>
							<InputRow>
								<InputRowLabel>Current Year</InputRowLabel>
								<Radio.Group
									value={enogenContractCurrentYear}
									onChange={(e) => setEnogenContractCurrentYear(e.target.value)}
								>
									<OrangeRadio className='radio_current_year_yes' value={true}>Yes</OrangeRadio>
									<OrangeRadio className='radio_current_year_yes' value={false}>No</OrangeRadio>
								</Radio.Group>
							</InputRow>
						</div>
					</Section> */}
					<Section className='section_acres' style={{ paddingBottom: 0 }}>
						<SectionLabel>Mapped Acres</SectionLabel>
						<div style={{ display: 'flex', flexDirection: 'column', padding: '0px 30px' }}>
							<InputRow>
								<InputRowLabel>Acres Minimum</InputRowLabel>
								<InputText
									type='number'
									placeholder='Min'
									value={acresMinimum}
									onChange={(e) => setAcresMinimum(e.target.value)}
									className='input_acres_min'
								/>
							</InputRow>
							<InputRow>
								<InputRowLabel>Acres Maximum</InputRowLabel>
								<InputText
									type='number'
									placeholder='Max'
									value={acresMaximum}
									onChange={(e) => setAcresMaximum(e.target.value)}
									className='input_acres_max'
								/>
							</InputRow>
						</div>
					</Section>
				</Body>
				<Footer>
					<Button
						variant='outlined'
						onClick={() => clearAllInput()}
						style={{
							width: 75,
							height: 40
						}}
					>
						Clear
					</Button>
					<Button
						variant='dark'
						onClick={(e) =>
						{
							onCancel(e);
							onApply(buildGrowerFilter());
						}}
						style={{
							width: 75,
							height: 40,
							marginLeft: 20,
							color: theme.colors.white,
							background: theme.colors.primary,
							borderColor: theme.colors.primary
						}}
					>
						Apply
					</Button>
				</Footer>
			</Container>
		</Modal>
	);
};