import React, { useCallback } from 'react';
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { Button } from '../../../components/Button/Button';
import { IPlanResponse, PlanStatus, PlanStatusLabel } from '../../../logic/Models/Responses/PlanResponse';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { updatePlanStatus } from '../../../logic/store/Grower/PlanThunks';
import { IPlanUpdateRequest } from '../../../logic/Models/Requests/PlanRequest';
import { useDynamicEvent } from '../../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../../tracing/session';
import { EventPlanTags, EventSelectionTags, EventStructureTags } from '../../../tracing/EventTagNames';
import { EventRecallNames } from '../../../tracing/EventNames';

const displayStatuses: PlanStatusLabel[] = ['Accepted', 'Presented', 'Draft'];
interface IRecallStatusProps extends Partial<IPlanResponse>
{
	currentlySelectedId?: string;
	currentlySelectedCropYear: string;
	hasOrderedPlan: boolean;
}
const StatusComponent = (props: IRecallStatusProps & PropsFromRedux) => 
{
	const [isStatusModalOpen, setStatusModalOpen] = useState(false);
	const {
		Id,
		Status,
		Type,
		currentlySelectedId,
		updatePlanStatus,
		isLoadingPlanUpdate,
		currentCropYear,
		currentlySelectedCropYear,
		hasOrderedPlan,
	} = props;

	const isPlanOld = Number.parseInt(currentlySelectedCropYear) < Number.parseInt(currentCropYear);

	const session = useScopedSession(RecallStatus.name, {}, {
		[EventStructureTags.PageContext]: 'recall_status',
		[EventStructureTags.PageUrl]: window.location.toString()
	});
	const trackStatusChange = useDynamicEvent(session);
	const updateStatus = useCallback((status: PlanStatus) =>
	{
		if (isLoadingPlanUpdate) return;
		trackStatusChange(EventRecallNames.ChangePlanStatus, {
			[EventSelectionTags.PlanId]: Id,
			[EventPlanTags.PlanType]: Type,
			[EventPlanTags.PlanStatus]: status
		});

		updatePlanStatus({
			planId: Id,
			status: status,
			planType: Type
		});
		setStatusModalOpen(false);
	}, [isLoadingPlanUpdate, trackStatusChange, Id, Type, updatePlanStatus]);

	const onDropdownSelect = (key) => 
	{
		//Status 'planned' has been renamed to 'presented'. This remaps presented to planned for logical purposes.
		if(key === 'Presented')
		{
			key = 'Planned';
		}

		if (currentlySelectedId && key === 'Accepted' && currentlySelectedId !== Id) 
		{
			setStatusModalOpen(true);
		}
		else
		{
			updateStatus(key);
		}
	};

	//Remap the status to properly use the new name 'Presented' rather than 'Planned' 
	const remapStatus = () =>
	{
		if(Status === 'Planned')
		{
			return 'Presented';
		}
		return Status;
	};

	return <div>
		{hasOrderedPlan ? 
			(Status === 'Order') ? <span>{'Grower Supplied Order'}</span> : <span>{Status}</span> :
			Status !== 'Order' && <Dropdown className='status-dropdown' selected={remapStatus()} variant='grey'
				disabled={isLoadingPlanUpdate || isPlanOld}
				options={displayStatuses}
				onSelect={(key) => onDropdownSelect(key)} />}
		<StyledModal className='accept-modal' title='Accept Draft Proposal' open={isStatusModalOpen} onCancel={() => setStatusModalOpen(false)}>
			<span>
				You can only have one Accepted Proposal at a time. To change the status of this Proposal to Accepted, you are
				acknowledging it will replace the current Accepted Proposal.
			</span>
			<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
				<Button className='cancel-button' variant='outlined' style={{ marginRight: '15px' }} onClick={() => setStatusModalOpen(false)}>Cancel</Button>
				<Button className='accept-button' variant='dark' disabled={isLoadingPlanUpdate} onClick={() => updateStatus('Accepted')}>Accept</Button>
			</div>
		</StyledModal>
	</div>;
};

const mapStateToProps = (state: RootState) => ({
	isLoadingPlanUpdate: state.grower.isLoadingPlanUpdate,
	isError: state.grower.isError,
	errorMessage: state.grower.errorMessage,
	currentCropYear: state.crops.CropYear,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		updatePlanStatus: (request: IPlanUpdateRequest) => dispatch(updatePlanStatus(request)),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const RecallStatus = connector(StatusComponent);