import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { BoolDictionary, clearSelectedFields, setFieldIsSelected } from '../../../logic/store/UI/UISlice';
import { CheckboxExpander, ICheckboxChildOption, ICheckboxSection } from '../../../components/CheckboxExpander/CheckboxExpander';
import { Button } from '../../../components/Button/Button';
import { Footer } from '../FieldListContainer';
import { iconForCrop } from './iconForCrop';
import { runCropPlan, updateCropPlanFields } from '../../../logic/store/Plans/FieldPlan/FieldPlanThunks';
import { Modal } from 'antd';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { useAmplitudePassthrough } from '../../../logic/Utility/useAmplitude';
import { useCropPlanTrackingState } from './useCropPlanTrackingState';
import { useScopedSession } from '../../../tracing/session';
import { EventCropPlanTags, EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames } from '../../../tracing/EventNames';


interface IManageFieldProps
{
	/**
	 * Advance to the next screen
	 */
	onAdvance: () => void;

	/**
	 * Return to the previous screen
	 */
	onCancel: () => void;
}


const mapStateToProps = (state: RootState) =>
{
	return {
		FieldPlan: state.fieldplan.currentFieldPlan,
		SelectedGrower: state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId)!,
		SelectedCropPlanId: state.fieldplan.selectedCropPlanId,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		SetSelectedFields: makeDispatch(dispatch, setFieldIsSelected),
		ClearSelectedFields: makeDispatch(dispatch, clearSelectedFields),
		UpdateCropPlanFields: makeDispatch(dispatch, updateCropPlanFields),
		RunCropPlan: makeDispatch(dispatch, runCropPlan)
	};
};


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


/**
 * The Manage Fields page for creating a new plan or modifying an existing one
 */
const ManageFields = (props: IManageFieldProps & PropsFromRedux) =>
{
	const { 
		SelectedCropPlanId,
		onAdvance, 
		onCancel, 
		SelectedGrower, 
		FieldPlan, 
		SetSelectedFields, 
		ClearSelectedFields,
		UpdateCropPlanFields,
		RunCropPlan,
	} = props;

	const theme = useTheme();

	const trackingData = useCropPlanTrackingState();
	const session = useScopedSession(ManageFields.name, trackingData, {
		[EventStructureTags.PageContext]: 'manage_fields',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	// Start with everything expanded
	const [expandedFarms, setExpandedFarms] = useState<Record<string, boolean>>(Object.fromEntries(SelectedGrower.Farms.map(fm => [fm.Id, true])));
	const [checkedFields, setCheckedFields] = useState<BoolDictionary>(SelectedCropPlanId 
		// If we are editing a crop plan, make sure the fields already assigned start checked
		// TODO: once editing is in place, do we save on _next_ or later? If this doesn't immediately save,
		// this may clear changes if the user navigates away and back again
		? Object.fromEntries(FieldPlan?.CropPlans.find(cp => cp.Id === SelectedCropPlanId)?.FieldIds.map(fid => [fid, true]) ?? [])
		: {});

	/**
	 * List which fields are assigned to other crop plans so we can show an indicator
	 * Include the Crop Id and the Crop Plan color
	 */
	const fieldsAssignedToOtherCropPlans = useMemo(() => 
	{
		return Object.fromEntries(
			FieldPlan?.CropPlans.filter(cp => cp.Id !== SelectedCropPlanId)
				.flatMap(
					cp => cp.FieldIds?.map(
						id => [id, {CropId:cp.CropId, Color: cp.Color}]) 
					?? [] ) ?? []);
	}, [FieldPlan?.CropPlans]);

	/**
	 * Flag true if at least one field has been selected and the user can proceed
	 */
	const areAnyFieldsChecked = useMemo(() => Object.entries(checkedFields).filter(e => e[1]).map(e => e[0]).length > 0, [checkedFields]);
	
	const toggleExpandedFarm = useCallback((headerIndex: number) => 
	{
		const orderedFarms = _.cloneDeep(SelectedGrower.Farms).sort(dynamicSort('Name'));
		setExpandedFarms((prev) => ({
			...prev,
			[orderedFarms[headerIndex].Id]: !prev[orderedFarms[headerIndex].Id]
		}));
	}, [expandedFarms]);

	const applyCheckedFields = useCallback((check: BoolDictionary) => 
	{
		setCheckedFields({
			...checkedFields,
			...check
		});
	}, [checkedFields]);

	const areAnySelectedFieldsAssignedToOtherPlans = Object.entries(checkedFields).some(
		([fieldId, checked]) => checked && fieldsAssignedToOtherCropPlans[fieldId]
	);
	
	useEffect(() => 
	{		
		const selectedFieldsTotal = {
			// Start by assuming every is deselected
			...Object.fromEntries(SelectedGrower.Farms.flatMap(fm => fm.Fields).map(f => [f.Id, false])),
			// Apply any checked field setting them to true
			...checkedFields
		};
		SetSelectedFields(selectedFieldsTotal);
	}, [ SelectedGrower?.Farms, checkedFields ]);
	
	const accept = useCallback(async () => 
	{
		if(!areAnyFieldsChecked)
		{
			return;
		}

		const selectedFieldsTotal = {
			// Start by assuming every is deselected
			...Object.fromEntries(SelectedGrower.Farms.flatMap(fm => fm.Fields).map(f => [f.Id, false])),
			// Apply any checked field setting them to true
			...checkedFields
		};
		SetSelectedFields(selectedFieldsTotal);

		if(SelectedCropPlanId)
		{
			const selectedFieldIds = Object.keys(checkedFields).filter(id => checkedFields[id]);
			await UpdateCropPlanFields({ cropPlanId: SelectedCropPlanId, fieldIds: selectedFieldIds});
			RunCropPlan({ CropPlanId: SelectedCropPlanId });
		}

		setShowFieldTransferConfirmation(false); // Close the modal

		onAdvance();
	}, [areAnyFieldsChecked, onAdvance, SetSelectedFields, SelectedGrower?.Farms, checkedFields]);
	
	const acceptTransfer = useAmplitudePassthrough(session, EventFieldPlanNames.TransferField, accept, [accept]);
	
	const [showFieldTransferConfirmation, setShowFieldTransferConfirmation] = useState(false);
	// Validate if there are any fields about to be removed from a different plan and have
	// the user confirm
	const advance = useCallback(async () => 
	{	
		if(!areAnyFieldsChecked)
		{
			return;
		}

		if(!areAnySelectedFieldsAssignedToOtherPlans)
		{
			accept();
			return;
		}

		setShowFieldTransferConfirmation(true);
	}, [areAnySelectedFieldsAssignedToOtherPlans, accept]);

	const cancel = useCallback(() => 
	{
		ClearSelectedFields();
		onCancel();
	}, [onCancel, ClearSelectedFields]);

	/**
	 * Build our list of items to show in the selection list
	 */
	const checkboxItems = useMemo(() => 
	{
		if (SelectedGrower.Farms?.length)
		{
			const farmsWithFields = SelectedGrower.Farms.filter(farm => farm.Fields.length);
			const farmOptions = farmsWithFields.map((farm) =>
			{
				const fieldOptions: ICheckboxChildOption[] = [];
				farm.Fields.forEach((field) =>
				{
					fieldOptions.push(
						{
							option: field.Name,
							optionId: field.Id,
							isChecked: checkedFields[field.Id],
							enabledForFieldHealth: false,
							extraItems: iconForCrop(fieldsAssignedToOtherCropPlans[field.Id]?.CropId, fieldsAssignedToOtherCropPlans[field.Id]?.Color) 
						}
					);
				});

				const farmOption: ICheckboxSection =
				{
					header: farm.Name,
					headerId: farm.Id,
					options: fieldOptions.sort(dynamicSort('option')),
					isChecked: checkedFields[farm.Id],
					showFieldHealthButton: false,
					isExpanded: expandedFarms[farm.Id],
				};

				return farmOption;
			});

			return farmOptions.sort(dynamicSort('header'));
		}
	}, [SelectedGrower.Farms, expandedFarms, checkedFields]);

	
	return <div className='sectionInner' style={{ padding: '0 16px', height: '100%', display: 'flex', flexDirection: 'column' }}>
		<div className='section header' style={{ marginBottom: 16 }}>
			<span style={{ flexGrow: 1, fontSize: theme.fontSizes.large, fontWeight: theme.fontWeights.bold }}>
				Field List
			</span>
		</div>
		<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingBottom: 60 }}>
			<div style={{ 
				display: 'flex', 
				flexDirection: 'row', 
				alignItems: 'center', 
				borderTop: '1px solid',
				borderColor: theme.colors.lightestGrey,
				paddingTop: 16,
				fontSize: theme.fontSizes.large, 
				fontWeight: theme.fontWeights.bold
			}}>
			Field(s)
			</div>
			<CheckboxExpander
				checkboxSections={checkboxItems}
				includeIconButton={false}
				iconButtonFunction={() => alert('iconButtonFunction')}
				onChecked={applyCheckedFields}
				onExpand={toggleExpandedFarm}
				outerContainerStyle={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}
			/>
		</div>
		<Footer style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
			<Button
				variant='outlined'
				className='bulkFieldEditButton'
				onClick={cancel}
			>
				Cancel
			</Button>
			<Button
				disabled={!areAnyFieldsChecked}
				style={{ marginLeft: 10, marginRight: 10 }}
				variant='dark'
				className='bulkEditNamesButton'
				onClick={advance}
			>
				Next
			</Button>
		</Footer>
		<Modal
			open={showFieldTransferConfirmation}
			onOk={() => setShowFieldTransferConfirmation(false)}
			onCancel={() => setShowFieldTransferConfirmation(false)}
			title={<div style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'flex-start'
			}}>
				<div style={{
					fontSize: 24,
					fontWeight: 700,
					lineHeight: '32px',
					maxWidth: 260
				}}>Confirm Transfer?</div>
			</div>}
			className='ConfirmTransferModal'
			closable={true}
			width={620}
			footer={
				<div>
					<Button
						variant='outlined'
						style={{paddingLeft: 16, paddingRight: 16}}
						onClick={() => setShowFieldTransferConfirmation(false)}
						className='cancelButton'
					>
						Cancel
					</Button>
					<Button
						variant='dark'
						style={{ marginLeft: 8, paddingLeft: 16, paddingRight: 16 }}
						onClick={acceptTransfer}
						className='submitButton'
					>
						Confirm
					</Button>
				</div>}
			centered
		>
			<div style={{ fontSize: 16, lineHeight: '23px', maxWidth: 420 }}>
				Some of these fields are assigned to another crop plan. Proceeding will transfer those fields to this new plan. 
				<br/>
				Would you like to proceed?
			</div>
		</Modal>
	</div>;
};



export const ConnectedManageFields = connector(ManageFields);