import React, { useCallback, useEffect } from 'react';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import _, { uniq } from 'lodash';
import { Button } from '../../../components/Button/Button';
import { cornId, CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { makeDispatch } from '../../../logic/Utility/Utils';
import { getCropPlan, getCropPlanAgronomicAttributes, updateCropPlan } from '../../../logic/store/Plans/FieldPlan/FieldPlanThunks';
import { ReactComponent as CornIcon } from './Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from './Icons/SoybeanIcon.svg';
import { CropPlanCard } from './Cards/CropPlanCard';
import { ConnectedDonutPanel } from './DonutPanel/DonutPanel';
import { getSeedingRateForSeriesAndRates, getPlantingRatesSpecificYield, IGetPlantingRatesSpecificRequest } from '../../../logic/store/Seeds/PlantingRateSlice';
import { RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { useAmplitudePassthrough } from '../../../logic/Utility/useAmplitude';
import { useCropPlanTrackingState } from './useCropPlanTrackingState';
import { useUpdateFieldPlanProductDerivedData } from './useUpdateFieldPlanProductDerivedData';
import { useScopedSession } from '../../../tracing/session';
import { EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames } from '../../../tracing/EventNames';

const OverviewContainer = styled.div`
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 20px;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
`;

interface IProductInventoryProps
{
	returnToCropPlanList: () => void;
	toggleFieldInventory: () => void;
	editFields: () => unknown;
	editProducts: () => unknown;
}

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	const SelectedCropPlan = state.fieldplan.fullCropPlanData.find(cp => cp.Id === state.fieldplan.selectedCropPlanId);
	const SelectedCropPlanAgronomics = state.fieldplan.cropPlanAgronomicAttributes[state.fieldplan.selectedCropPlanId];

	return {
		IsFieldPlanLoading: state.fieldplan.isLoading,
		IsLoadingSeedingRates: state.plantingRate.isLoadingPlantingRates,
		Seeds: state.seeds.seeds,
		SeedingRates: state.plantingRate.rates,
		SelectedGrower,
		SelectedCropPlan: SelectedCropPlan,
		SelectedCropPlanId: state.fieldplan.selectedCropPlanId,
		LoadedCropPlanIds: state.fieldplan.fullCropPlanData.map(plan => plan.Id),
		SelectedCropPlanAgronomics
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		GetCropPlan: makeDispatch(dispatch, getCropPlan),
		GetCropPlanAgronomics: makeDispatch(dispatch, getCropPlanAgronomicAttributes),
		GetPlantingPlanRates: makeDispatch(dispatch, getPlantingRatesSpecificYield),
		UpdateCropPlanName: makeDispatch(dispatch, updateCropPlan),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * The Product Inventory and Donut Chart page 
 */
const ProductInventory = (props: IProductInventoryProps & PropsFromRedux) =>
{
	const {
		editFields,
		editProducts,
		returnToCropPlanList,
		toggleFieldInventory,
		IsFieldPlanLoading,
		IsLoadingSeedingRates,
		Seeds,
		SeedingRates,
		SelectedGrower,
		SelectedCropPlan,
		SelectedCropPlanId,
		LoadedCropPlanIds,
		SelectedCropPlanAgronomics,
		GetCropPlan,
		GetCropPlanAgronomics,
		GetPlantingPlanRates,
		UpdateCropPlanName,
	} = props;
	
	useUpdateFieldPlanProductDerivedData();

	const trackingData = useCropPlanTrackingState();
	const session = useScopedSession(ProductInventory.name, trackingData, {
		[EventStructureTags.PageContext]: 'product_inventory',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	const trackEditFields = useAmplitudePassthrough(session, EventFieldPlanNames.EditFields, editFields, [editFields]);
	const trackEditProducts = useAmplitudePassthrough(session, EventFieldPlanNames.EditProducts, editProducts, [editProducts]);

	useEffect(() =>
	{
		if (SelectedCropPlanId && !IsFieldPlanLoading)
		{
			const blocking = !LoadedCropPlanIds.includes(SelectedCropPlanId);
			if (!SelectedCropPlan)
			{
				GetCropPlan({ cropPlanId: SelectedCropPlanId, blocking });
			}
			
			if (!SelectedCropPlanAgronomics)
			{
				GetCropPlanAgronomics({cropPlanId: SelectedCropPlanId, blocking });
			}
		}
	},[SelectedCropPlanId, SelectedCropPlan]);

	useEffect(() =>
	{
		if (SelectedCropPlan && Seeds)
		{
			if (!IsLoadingSeedingRates)
			{
				const hybrids = Seeds
					.filter(seedLevel => seedLevel.CropId === SelectedCropPlan.CropId)
					.flatMap(seedLevel => seedLevel.BrandApplications)
					.filter(brandLevel => brandLevel.BrandApplication === SelectedCropPlan.Settings.BrandApplication)
					.flatMap(brandLevel => brandLevel.Hybrids);
				
				// Get the seeding rates by yield values for both crops
				const seriesNames = uniq(SelectedCropPlan.SelectedProducts.map(product =>
					hybrids.find(h => h.Id === product.HybridId)?.SeriesName
				).filter(h => h));

				// Get the predictive yields for the field based on the CropPlan's crop
				const allFields = SelectedGrower.Farms.flatMap(fa => fa.Fields);
				const fieldYields = [];
				SelectedCropPlan.Fields?.forEach(field =>
				{
					const foundField = allFields.find(f => f.Id === field.FieldId);
					if (foundField)
					{
						if (SelectedCropPlan.CropId === cornId)
						{
							if (foundField.CornEstimatedYield)
							{
								const estYieldValue = foundField.CornEstimatedYield?.toLocaleString('en-US', {maximumFractionDigits:0});
								if (fieldYields.findIndex(y => y === estYieldValue) === -1)
								{
									fieldYields.push(estYieldValue);
								}
							}
						}
						else
						{
							if (foundField.SoybeanEstimatedYield)
							{
								const estYieldValue = foundField.SoybeanEstimatedYield?.toLocaleString('en-US', {maximumFractionDigits:0});
								if (fieldYields.findIndex(y => y === estYieldValue) === -1)
								{
									fieldYields.push(estYieldValue);
								}
							}
						}
					}
				});

				// If we don't have a value for any intersection of series and yield, call again
				if (seriesNames && seriesNames.length > 0 && seriesNames.some(
					series => fieldYields.some(
						fieldYield => undefined === getSeedingRateForSeriesAndRates(SeedingRates, series, fieldYield, true)
					)
				))
				{
					const plantingRatesRequest: IGetPlantingRatesSpecificRequest = {
						cropId: SelectedCropPlan.CropId,
						seriesNames: seriesNames,
						yields: fieldYields,
					};
					GetPlantingPlanRates(plantingRatesRequest);
				}
			}
		}
	},[SelectedCropPlan?.Id, Seeds, SelectedGrower?.Farms, IsLoadingSeedingRates, SelectedCropPlan?.SelectedProducts, SelectedCropPlan?.Fields, SelectedCropPlan, SeedingRates, GetPlantingPlanRates]);

	const getFieldAcres = (): number =>
	{
		let totalAcres = 0;
		const cropPlanFieldIds = SelectedCropPlan?.Fields.map(f => f.FieldId);

		if (SelectedGrower && SelectedGrower.Farms && SelectedGrower.Farms.length > 0 && cropPlanFieldIds && cropPlanFieldIds.length > 0)
		{
			const allFields = SelectedGrower.Farms.flatMap(fa => fa.Fields);

			totalAcres = allFields.map(field => cropPlanFieldIds.includes(field.Id) ? RoundAcres(field.Area) : 0).reduce((a1, a2) => (a1 ?? 0) + (a2 ?? 0), 0);
		}

		return totalAcres;
	};

	const onSaveCropPlanName = useAmplitudePassthrough(session, EventFieldPlanNames.RenameCropPlan, (value: string) =>
	{
		UpdateCropPlanName(
			{ 
				CropPlanId: SelectedCropPlanId, 
				UpdateRequest: { Name: value },
				StoreResponse: true
			});
	},[]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' }}>
			<OverviewContainer>
				{
					SelectedCropPlan &&
					<CropPlanCard
						disabled={false}
						className={`CropPlanTabButton_${CropConfig()[SelectedCropPlan.CropId].cropName}`}
						title={SelectedCropPlan.Name}
						tabColor={SelectedCropPlan.Color}
						displayNamesAndValues={[
							['Fields', SelectedCropPlan?.Fields.length.toString()],
							['Acres', getFieldAcres().toLocaleString('en-US', {maximumFractionDigits:1})]
						]}
						onClick={() => {}}
						icon={SelectedCropPlan.CropId === cornId ? <CornIcon height={26} width={26} /> : <SoyIcon />}
						saveCropPlanName={onSaveCropPlanName}
					/>
				}
				<ConnectedDonutPanel
					onEditFields={trackEditFields}
					onEditProducts={trackEditProducts}
					toggleFieldInventory={toggleFieldInventory}
				/>
			</OverviewContainer>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',  marginTop: 'auto', padding: '10px 20px 10px 20px' }}>
				<Button
					disabled={false}
					className='BackToProposal_Button'
					style={{ height: 40, minWidth: 160, marginRight: 15 }}
					variant='outlined'
					onClick={returnToCropPlanList}
				>
					Back to Proposal
				</Button>
			</div>
		</div>
	);
};

export const ConnectedProductInventory = connector(ProductInventory);