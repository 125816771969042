import { IGrowerState } from './Grower/GrowerSlice';
import { ICropState } from './Seeds/CropsSlice';
import { ISeedListState } from './Seeds/SeedsSlice';
import { IUIState } from './UI/UISlice';
import { IClientConfig } from './User/ConfigSlice';

export const migrations =
{
	2: state => ({
		...state,
		seeds: {
			...state.seeds,
			seeds: [],
		} as Partial<ISeedListState>
	}),
	3: state => ({
		...state,
		ui: {
			...state.ui,
			DashboardSort: { SortDirection: 'ascending', field: 'Name' },
			RecallSort: { SortDirection: 'descending', field: 'Modified' },
		} as Partial<IUIState>
	}),
	4: state => ({
		...state,
		crops: {
			...state.crops,
			CropYear: undefined
		} as Partial<ICropState>
	}),
	5: state => ({
		...state,
		grower: {
			...state.grower,
			isSavingFarm: false,
			isSavingField: false
		} as Partial<IGrowerState>
	}),
	6: state => ({
		...state,
		ui: {
			...state.ui,
			SelectedFieldDictionary: {}
		} as Partial<IUIState>
	}),
	7: state => ({
		...state,
		grower: {
			...state.grower,
			isFetchingBulkImportStatus: false
		} as Partial<IGrowerState>
	}),
	8: state => ({
		...state,
		config: {
			...state.config,
			MapboxAccessToken: ''
		} as Partial<IClientConfig>,
		ui: {
			...state.ui,
			ShowMapSearch: false
		} as Partial<IUIState>
	}),
	9: state => ({
		...state,
		productGamePlan: {
			...state.productGamePlan,
			productGamePlans: {},
			// clear rates since index changed string -> number
			rates: {},
			isLoadingCreateProductPlan: false,
			isLoadingGetProductPlan: false,
			isSavingProductPlan: false,
			isDownloadingProductPlan: false,
		},
		plantingRate: {
			...state.plantingRate,
			isError: false,
			isLoadingPlantingRates: false,
		},
		seeds: {
			...state.seeds,
			isGettingSeedsForGrower: false,
			isGettingSeedsForCurrentUser: false,
		}
	}),
	10: state => ({
		...state,
		grower: {
			...state.grower,
			isLoadingPlanDelete: false
		} as Partial<IGrowerState>
	}),
	11: state => ({
		...state,
		grower: {
			...state.grower,
			isSuccess: false,
			successMessage: undefined
		} as Partial<IGrowerState>
	}),
	12: state => ({
		...state,
		ui: {
			...state.ui,
			TreatmentSort: { sortDirection: 'ascending', field: 'Name' },
		} as Partial<IUIState>
	}),
	13: state => ({
		...state,
		ui: {
			...state.ui,
			AgriclimeFlowDisplayed: undefined
		} as Partial<IUIState>,
		config: {
			...state.config,
			AgriclimeUrl: ''
		} as Partial<IClientConfig>
	}),
	14: state => ({
		...state,
		ui: {
			...state.ui,
			PlantingPlanEquipment: undefined,
			isLoading: false,
			isError: false,
			errorMessage: undefined,
		} as Partial<IUIState>
	}),
};