import React, { useCallback, useMemo } from 'react';
import { ColorMatchBar } from '../../../components/ColorMatchBar/ColorMatchBar';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { checkContrast } from '../../../logic/Utility/Utils';
import { IncrementNumberInputDebounced } from '../../../components/IncrementNumberInputDebounced/IncrementNumberInputDebounced';
import { CalculateBagsForField, RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { ICropPlanAgronomicAttributes, ICropPlanFieldSeedAssignment } from '../../../logic/Models/Responses/FieldPlanResponse';
import { CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { ReactComponent as StarIcon } from './Icons/Star.svg';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../logic/store/Store';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { sortBy } from 'lodash';
import { aggregateHybridToSeries, aggregateProductToHybrid } from './Logic/aggregateHybridToSeries';
import { HybridAvailability } from '../ProductGamePlan/SeriesAvailability';
import { ReactComponent as GreenAvailabilityIcon } from '../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../assets/images/AvailabilityIcons/Red.svg';
import { useTheme } from 'styled-components';

export interface IFieldInventoryItemProps
{
	cropId: string;
	remainingAcres: number;
	fieldAcreage: number;
	agronomicSelection: string;
	fieldInventoryItem: ICropPlanFieldSeedAssignment;
	agronomicRates: ICropPlanAgronomicAttributes;
	onChangeSeedSelection: (hybridId: string, checked: boolean, newHybridId?: string) => void;
	updateFieldHybridAcres: (hybridId: string, value: number) => void;
	updateFieldHybridRate: (hybridId: string, value: number) => void;
}

export const FieldInventoryItem = (props: IFieldInventoryItemProps) =>
{
	const {
		agronomicSelection,
		cropId,
		agronomicRates,
		fieldAcreage,
		fieldInventoryItem,
		onChangeSeedSelection,
		updateFieldHybridAcres,
		updateFieldHybridRate
	} = props;

	const theme = useTheme();

	const cropPlanDerivedData = useSelector((state:RootState) => state.fieldplan.selectedCropPlanDerivedData)!;

	// Calculate the different trait options available for this series.
	const hybridOptions:IOptionItem[] = useMemo(() => 
	{
		const byHybrid = aggregateProductToHybrid(cropPlanDerivedData.products);
		const bySeries = aggregateHybridToSeries(byHybrid);
		return sortBy(bySeries.find(s => s.SeriesId === fieldInventoryItem.SeriesId).Hybrids.map<IOptionItem>((h) =>
			({
				label: h.TraitFullName,
				value: h.HybridId,
				disabled: h.Availability == 'None',
				icon: <HybridAvailability style={{width: 14, marginRight: 6}}>
					{h.Availability == 'Green' ? <GreenAvailabilityIcon style={{ fill: theme.colors.availabilityGreen }} />
						: h.Availability === 'Yellow' ? <YellowAvailabilityIcon style={{ fill: theme.colors.availabilityYellow }} />
							: h.Availability === 'Red' ? <RedAvailabilityIcon style={{ fill: theme.colors.availabilityRed }} />
								: <></>
					}
				</HybridAvailability>
			})));
	},[cropPlanDerivedData.products, fieldInventoryItem.SeriesId]);

	const getTitle = (): string =>
	{
		let title = '';
		if (fieldInventoryItem.IsExternal)
		{
			title += `${fieldInventoryItem.HybridBrand}`;
			if (fieldInventoryItem.HybridName)
			{
				title += `-${fieldInventoryItem.HybridName}`;
			}
			if (fieldInventoryItem.RelativeMaturity)
			{
				title += `-${fieldInventoryItem.RelativeMaturity}`;
			}
		}
		else
		{
			title = fieldInventoryItem.HybridName;
		}

		return title;
	};

	const changeSeedSelection = (checked: boolean) =>
	{
		onChangeSeedSelection(fieldInventoryItem.HybridId, checked);
	};

	// Handle switching a selected product's trait
	const changeTrait = useCallback((newHybridId: string) =>
	{
		if(newHybridId === fieldInventoryItem.HybridId)
		{
			return;
		}
		onChangeSeedSelection(fieldInventoryItem.HybridId, false, newHybridId);
	}, [fieldInventoryItem.HybridId, onChangeSeedSelection]);

	const changeAcres = (value: number) =>
	{
		if (value === 0 || value < 0)
		{
			return;
		}
		updateFieldHybridAcres(fieldInventoryItem.HybridId, value);
	};

	const changeRate = (value: number) =>
	{
		if (value === 0 || value < 0)
		{
			return;
		}
		updateFieldHybridRate(fieldInventoryItem.HybridId, value);
	};

	const selectedAttribute = agronomicRates?.Attributes.find(a => a.Id === agronomicSelection);
	const productRatings = agronomicRates?.ProductAttributeRatings?.find(ar => ar.Product.HybridId === fieldInventoryItem?.HybridId);
	let ranking = 0;
	if(agronomicSelection === 'Match Strength')
	{
		ranking = fieldInventoryItem.MatchStrength;
	}
	else if(productRatings && selectedAttribute)
	{
		ranking = productRatings.AttributeRankings[selectedAttribute.Id];
	}
	
	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginRight: 5, marginBottom: 10 }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Checkbox checked={fieldInventoryItem.Selected} size={'16px'} onChange={e => changeSeedSelection(e.target.checked)} />
				<div style={{ fontSize: theme.fontSizes.small, fontWeight: theme.fontWeights.bold, marginLeft: 5 }}>{getTitle()}</div>
				{
					fieldInventoryItem.Selected &&
					<div style={{ marginLeft: 'auto'}}>{`Units: ${CalculateBagsForField(fieldInventoryItem.Units).toLocaleString('en-US')}`}</div>
				}
				{
					fieldInventoryItem?.IsRecommended &&
					<StarIcon style={{ position: 'relative', top: -2, marginLeft: 5 }} />
				}
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<div style={{ marginTop: 2.75 }}>
					<ColorMatchBar
						containerClassName={fieldInventoryItem.IsExternal 
							? `FieldInventory_ColorBar-${fieldInventoryItem.HybridBrand}-${fieldInventoryItem.HybridName}-${fieldInventoryItem.RelativeMaturity}`
							: `FieldInventory_ColorBar-${fieldInventoryItem.HybridId}`}
						clickable={true}
						variant='thin'
						containerMargin={'5px'}
						title={''}
						fontColor={checkContrast(fieldInventoryItem.Color)}
						fontWeight={theme.fontWeights.bold}
						height={22}
						outerBorderColor={theme.colors.lightestGrey}
						showOuterBorder={true}
						color={fieldInventoryItem.Color}
						maxValue={1}
						value={ranking ?? 0}
						showDetails={false}
						onClick={() => onChangeSeedSelection(fieldInventoryItem.HybridId, !fieldInventoryItem.Selected)}
					/>
				</div>
				{
					(fieldInventoryItem.Selected && hybridOptions.length > 1) &&	
					<div style={{marginBottom: 4, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<div style={{width: 90, fontWeight: 700, paddingLeft:4}}>Trait</div>
						<div style={{flexGrow: 1}}><Dropdown variant='outlinednarrow' options={hybridOptions} onSelect={changeTrait} selected={fieldInventoryItem.HybridId} /></div>
					</div>
				}
				{
					fieldInventoryItem.Selected && 
						<div style={{ marginBottom: 10 }}>
							<div>
								<IncrementNumberInputDebounced
									interval={500}
									width={'50%'}
									max={CropConfig()[cropId].maxSeedingRate}
									min={CropConfig()[cropId].minSeedingRate}
									onChange={changeRate}
									value={fieldInventoryItem.Rate}
									title={'Planting Rate'}
									orientation={'horizontal'}
								/>
							</div>
							<div style={{ marginTop: 10 }}>
								<IncrementNumberInputDebounced
									interval={5}
									width={'50%'}
									precision={1}
									max={RoundAcres(fieldAcreage)}
									min={0.1}
									onChange={changeAcres}
									roundValue={RoundAcres}
									value={fieldInventoryItem.AppliedAcres}
									title={'Acres'}
									orientation={'horizontal'}
								/>
							</div>
						</div>
				}
			</div>
		</div>
	);
};