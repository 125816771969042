import { ICompetitiveAllowanceResponse } from './CompetitiveAllowanceResponse';

export enum PlanTypeEnum
{
	FieldGamePlan,
	OrderGamePlan,
	ProductGamePlan
}

export type PlanType =
	'FieldGamePlan' |
	'OrderGamePlan' |
	'ProductGamePlan' |
	'Order';

export enum PlanStatusEnum
{
	Draft,
	Planned,
	Accepted
}

export type PlanStatus =
	'Draft' |
	'Planned' |
	'Accepted' |
	'Order';

export type PlanStatusLabel =
	'Draft' |
	'Presented' |
	'Accepted' |
	'Order';

export interface IPlanResponse
{
	Id: string;
	Type: PlanType;
	Name: string;
	Status: PlanStatus;
	Downloaded: boolean;
	Created: string;
	GrowerId: string;
	Modified: string;
	UserId: string;
	CornUnits?: number;
	SoyUnits?: number;
	SalesforceOrderId?: string;

	CompetitiveAllowance?: ICompetitiveAllowanceResponse;

	CostsByCrop: {[key: string]: ICostByCrop} // key is the name of the crop, corn or soybean
}

export interface ICostByCrop
{
	TotalCost: number; // This is the total for the specific crop, will need to be added together to get a "total" of both (if more than one crop exists)
	CostPerAcre: number;
}