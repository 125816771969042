import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { area, difference, feature, Feature, multiPolygon, MultiPolygon, Polygon } from '@turf/turf';
import { mapValues, uniq } from 'lodash';
import { MaxscriptProductColorsList } from '../../Utility/Colors';
import { getBulkImportGeometry, IBulkImportGeometryResult, getBulkImportHeader, BulkImportHeaderResponse, BulkImportFeatureCollection, getBulkImportProfile,
	BulkImportProfileField, BulkImportFeatureProperties, saveBulkImportSession } from './BulkImportThunks';

export interface IBulkImportedField extends BulkImportProfileField {
	skip: boolean;
	clipFieldIds: string[];
	currentConflicts: string[];
	originalGeometry: Feature<Polygon | MultiPolygon, BulkImportFeatureProperties> | undefined;
	geometry: Feature<Polygon | MultiPolygon, BulkImportFeatureProperties> | undefined;
}

export interface IBulkImportedFarm {
	Id: string;
	Name: string;
	Fields: { [fieldId: string]: IBulkImportedField };
	color: string;
}

function rebuildFarm(farm: IBulkImportedFarm) 
{
	rebuildConflictsForFarm(farm);
	if (rebuildGeometryForFarm(farm)) 
	{
		// A change was made due to a clipping issue, we have to rerun
		rebuildConflictsForFarm(farm);
		rebuildGeometryForFarm(farm);
	}
}

const rebuildConflictsForFarm = (farm: IBulkImportedFarm) => 
{
	// Rebuild the intersection and conflict lists
	for(const farmField of Object.values(farm.Fields))
	{
		farmField.geometry = undefined;
		if(farmField.skip)
		{
			farmField.clipFieldIds = [];
			farmField.currentConflicts = [];
			continue;
		}
		
		// Make sure only non-skipped clip ids are included
		farmField.clipFieldIds = farmField.clipFieldIds.filter(fid => !farm.Fields[fid].skip);

		// Conflicts are items that
		farmField.currentConflicts = farmField.Overlaps.map(o => o.OverlappingFieldId).filter(fid => 
			// Are not skipped
			!farm.Fields[fid].skip
			// We haven't clipped
			&& !farmField.clipFieldIds.includes(fid)
			// And aren't clipped against _us_
			&& !farm.Fields[fid].clipFieldIds.includes(farmField.Id)
		);
	}
};

const rebuildGeometryForFarm = (farm: IBulkImportedFarm) => 
{
	let changeMade:boolean = false;

	// Now we know our lists are correct, rebuild the farm geometry
	for(const farmField of Object.values(farm.Fields))
	{
		if(farmField.skip || !farmField.originalGeometry)
		{
			continue;
		}

		farmField.geometry = farmField.originalGeometry;

		// If there are no clips this is an easy case
		if(!farmField.clipFieldIds.length)
		{
			continue;
		}

		try 
		{
			const otherClipGeometries = multiPolygon(farmField.clipFieldIds.map(fid => farm.Fields[fid])
				.map(f => f.geometry ?? f.originalGeometry)
				.filter(g => Boolean(g))
				.flatMap(g => g!.geometry.type === 'MultiPolygon' ? g!.geometry.coordinates : [g!.geometry.coordinates]));
			
			if(!otherClipGeometries?.geometry?.coordinates?.length)
			{
				continue;
			}
			
			const remaining = difference(farmField.originalGeometry, otherClipGeometries)?.geometry;
			if(remaining && area(remaining) > 4000) // roughly 1 acre minimum
			{
				farmField.geometry = feature(remaining, farmField.originalGeometry.properties);
			}
			else
			{
				// This means we clipped the entire field for some reason
				farmField.skip = true;
				farmField.clipFieldIds = [];
				changeMade = true;
			}
		}
		catch(err)
		{
			// Non-fatal error, we just don't display clipped geometry properly
		}
	}

	return changeMade;
};


const buildOriginalGeometry = (farms: {[farmId:string]: IBulkImportedFarm}, geometry: BulkImportFeatureCollection ) => 
{
	for (const farm of Object.values(farms)) 
	{
		for (const field of Object.values(farm.Fields)) 
		{
			const matchingGeometry = geometry.features.filter(f => f.properties['oi:farm-key'] == farm.Id && f.properties['oi:field-key'] === field.Id).flatMap(g => g!.geometry.type === 'MultiPolygon' ? g!.geometry.coordinates : [g!.geometry.coordinates]);
			if (matchingGeometry?.length) 
			{
				field.originalGeometry = multiPolygon(
					matchingGeometry,
					{
						'oi:farm-key': farm.Id,
						'oi:field-key': field.Id
					}
				);
				field.geometry = field.originalGeometry;
			}
		}
	}
};


export interface IBulkImportState
{
	/**
	 * This is a list of farm->fields, and what other fields should _subtract_ from that field to resolve conflicts.
	 * If this field is in the skip list it should neither be in this list nor be listed in other field's clip list.
	 */
	//clips: { [farmId: string]: { [fieldId: string] : string[] } } | undefined;
	farmKey: string | undefined;
	farms: { [farmId: string]: IBulkImportedFarm } | undefined;
	fieldKey: string | undefined;
	header: BulkImportHeaderResponse | undefined;
	geometry: BulkImportFeatureCollection | undefined;
	errorMessage: string | undefined;
	isError: boolean;
	isLoadingGeometry: boolean;
	isLoadingHeader: boolean;
	isLoadingProfile: boolean;
	publishingProgress: number | undefined;
	isSuccess: boolean;
	sessionInProgress: boolean;
	//skips: { [farmId: string]: string[] } | undefined;
}

const initialState: IBulkImportState = {
	farmKey: undefined,
	farms: undefined,
	fieldKey: undefined,
	header: undefined,
	geometry: undefined,
	errorMessage: undefined,
	isError: false,
	isLoadingGeometry: false,
	isLoadingHeader: false,
	isLoadingProfile: false,
	publishingProgress: undefined,
	isSuccess: false,
	sessionInProgress: false,
};

export const bulkImportSlice = createSlice({
	name: 'bulkimport',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
		clearSuccess: (state) =>
		{
			state.isSuccess = false;

			return state;
		},
		setFarmKey: (state, { payload }: PayloadAction<string>) =>
		{
			state.farmKey = payload;
		},
		setFieldKey: (state, { payload }: PayloadAction<string>) =>
		{
			state.fieldKey = payload;
		},
		skipField: (state, { payload }: PayloadAction<{ FarmId: string, FieldId: string}>) =>
		{
			const farm = state.farms?.[payload.FarmId];
			const field = farm?.Fields?.[payload.FieldId];
			if(!farm || !field)
			{
				return;
			}
			field.skip = true;
			field.clipFieldIds = [];

			rebuildFarm(farm);
		},
		unSkipField: (state, { payload }: PayloadAction<{ FarmId: string, FieldId: string}>) =>
		{
			const farm = state.farms?.[payload.FarmId];
			const field = farm?.Fields?.[payload.FieldId];
			if(!farm || !field)
			{
				return;
			}
			field.skip = false;
			field.clipFieldIds = [];

			rebuildFarm(farm);			
		},
		clipField: (state, { payload }: PayloadAction<{ FarmId: string, FieldId: string, ClipFieldId: string}>) =>
		{
			const farm = state.farms?.[payload.FarmId];
			const field = farm?.Fields?.[payload.FieldId];
			const clipField = farm?.Fields?.[payload.ClipFieldId];
			if(!farm || !field || !clipField)
			{
				return;
			}
			field.skip = false;
			// Make sure the other field is in our list
			field.clipFieldIds = uniq( field.clipFieldIds.concat(payload.ClipFieldId) );
			// Make sure this field isn't in the other list
			clipField.clipFieldIds = uniq( clipField.clipFieldIds.filter(fid => fid !== payload.FieldId));
			
			rebuildFarm(farm);	
		},
		unClipField: (state, { payload }: PayloadAction<{ FarmId: string, FieldId: string}>) =>
		{
			const farm = state.farms?.[payload.FarmId];
			const field = farm?.Fields?.[payload.FieldId];
			if(!farm || !field || !clipField)
			{
				return;
			}

			// Make sure the other field is not in our list
			field.clipFieldIds = [];
			field.skip = false;

			rebuildFarm(farm);	
		},
		updatePublishProgress: (state, { payload } : PayloadAction<number | undefined>) => 
		{
			state.publishingProgress = payload;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getBulkImportHeader.pending, (state, action) =>
		{
			state.sessionInProgress = true;
			state.isLoadingHeader = true;
			state.header = undefined;
			// state.geometry = undefined;
			state.fieldKey = undefined;
			state.farmKey = undefined;
		});
		builder.addCase(getBulkImportHeader.fulfilled, (state, { payload }: PayloadAction<BulkImportHeaderResponse>) =>
		{
			state.isLoadingHeader = false;
			state.header = payload;
			state.fieldKey = undefined;
			state.farmKey = undefined;
		});
		builder.addCase(getBulkImportHeader.rejected, (state, action) =>
		{
			state.isLoadingHeader = false;
			state.header = undefined;
			state.isError = true;
			state.errorMessage = action.error.message;
		});
		builder.addCase(getBulkImportGeometry.pending, (state, action) =>
		{
			state.farmKey = action.meta.arg.FarmKey;
			state.fieldKey = action.meta.arg.FieldKey;
			state.isLoadingGeometry = true;
		});
		builder.addCase(getBulkImportGeometry.fulfilled, (state, { payload }: PayloadAction<IBulkImportGeometryResult>) =>
		{
			state.isLoadingGeometry = false;
			// successful geometry reponse but without any features should still count as no geometry
			if (payload.Geometry.features.length > 0)
			{
				state.geometry = {
					...payload.Geometry,
					features: payload.Geometry.features
				};
			}
			else
			{
				state.geometry = undefined;
			}

			if(state.farms && state.geometry)
			{
				buildOriginalGeometry(state.farms, state.geometry);
			}
		});
		builder.addCase(getBulkImportGeometry.rejected, (state, action) =>
		{
			state.isLoadingGeometry = false;
			state.geometry = undefined;
			state.isError = true;
			state.errorMessage = (typeof action.payload === 'string' ? action.payload : action.error.message);
		});
		builder.addCase(getBulkImportProfile.pending, (state, action) =>
		{
			state.isLoadingProfile = true;
		});
		builder.addCase(getBulkImportProfile.fulfilled, (state, { payload }) =>
		{
			state.isLoadingProfile = false;
			const sortedFarmNames = Object.keys(payload).sort();
			state.farms = mapValues(payload, (farm, key) =>
			{
				const farmIndex = sortedFarmNames.indexOf(key);			
				const color = MaxscriptProductColorsList[farmIndex % MaxscriptProductColorsList.length];
				
				return {
					Id: farm.Id,
					Name: farm.Name,
					Fields: mapValues(farm.Fields, (field, key) => 
					{
						return {
							...field,
							clipFieldIds: [],
							currentConflicts: field.Overlaps.map(o => o.OverlappingFieldId),
							geometry: undefined,
							originalGeometry: undefined,
							skip: false,
						} as IBulkImportedField;
					}),
					color,
				} as IBulkImportedFarm;
			});

			
			if(state.farms && state.geometry)
			{
				buildOriginalGeometry(state.farms, state.geometry);
			}
		});
		builder.addCase(getBulkImportProfile.rejected, (state, action) =>
		{
			state.isLoadingProfile = false;
			state.isError = true;
			state.errorMessage = (typeof action.payload === 'string' ? action.payload : action.error.message);
		});
		builder.addCase(saveBulkImportSession.pending, (state, action) =>
		{
			state.sessionInProgress = true;
			state.publishingProgress = 0;
		});
		builder.addCase(saveBulkImportSession.fulfilled, (state, { payload }: PayloadAction<BulkImportHeaderResponse>) =>
		{
			state.publishingProgress = undefined;
			state.header = undefined;
			state.geometry = undefined;
			state.fieldKey = undefined;
			state.farmKey = undefined;
			state.sessionInProgress = false;
		});
		builder.addCase(saveBulkImportSession.rejected, (state, action) =>
		{
			state.publishingProgress = undefined;
			state.isError = true;
			state.errorMessage = (typeof action.payload === 'string' ? action.payload : action.error.message);
		});
	}
});

export const { clearState, clearError, clearSuccess, clipField, unClipField, setFarmKey, setFieldKey, skipField, unSkipField, updatePublishProgress } = bulkImportSlice.actions;

