import React, { useCallback } from 'react';
import { Api } from '../../logic/Api/Api';

/**
 * Claim an invitation and send any updated name and password.
 */
export function useClaimInvitation(
	invitationCode: string, 
	cropwiseUserExists: boolean,
	api: Api, 
	setMessage: (message:React.ReactNode) => unknown, 
	setDestination: (destination: 'login'|'register') => unknown,
	startLoading: () => unknown, 
	endLoading: () => unknown, 
) 
{
	return useCallback((values: { email: string; name: string; password: string; }) => 
	{
		startLoading();
		async function exec() 
		{
			try 
			{
				const response = await api.postUnauthorizedAsync<boolean>(`5/registration/${invitationCode}/accept`, {
					Name: values.name,
					Password: values.password
				});

				if (response.Success) 
				{
					setDestination('login');
					if(cropwiseUserExists)
					{
						setMessage(<div>Your account can now <a href="/login">log in</a> to GHX Fields!</div>);
					}
					else
					{
						setMessage(<div>Your account has been created. You can now <a href="/login">log in</a> to GHX Fields!</div>);
					}
				}
				else if (response.ErrorCode === 'user_already_registered') 
				{
					setDestination('login');
					setMessage(<div>This invitation has already been claimed. You can <a href="/login">login here</a>.If you have forgotten your password, you can reset it on the <a href="/login">login page</a>.</div>);
				}
				else if (response.ErrorCode === 'invalid_credentials') 
				{
					setDestination('register');
					setMessage(<div>The password was not correct. Please try again. If you have forgotten your password, you can reset it on the <a href="/login">login page</a>.</div>);
				}
				else if (response.ErrorCode === 'insufficient_password_complexity') 
				{
					setDestination('register');
					setMessage(<div>{response.ErrorMessage}</div>);
				}
				else 
				{
					setDestination('login');
					setMessage(<div>This invitation is not valid. If you have questions please reach out to your <a href="https://agconnections.zendesk.com/hc/en-us/categories/1500001012341-E-Luminate">support specialist.</a></div>);
				}
			}
			finally 
			{
				endLoading();
			}
		}

		exec();
	}, [api, invitationCode, setMessage, startLoading, endLoading, cropwiseUserExists ]);
}
