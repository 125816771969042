import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _, {  } from 'lodash';
import { IProductIdentifier } from '../../../../pages/FieldActivities/FieldPlan/Logic/IProductReference';
import { ICropPlanAgronomicAttributes, ICropPlanField, ICropPlanFullResponse, IFieldPlanResponse } from '../../../Models/Responses/FieldPlanResponse';
import { assignProductsToCropPlan, convertProductPlanToFieldPlan, createCropPlan, createFieldPlan, createFieldPlanWithCropPlan, deleteCropPlan, getCropPlan, getCropPlanAgronomicAttributes, getFieldPlan, pollGetFieldPlan, runCropPlan, updateCropPlan, updateCropPlanFields, updateFieldPlan } from './FieldPlanThunks';

export interface ICropPlanDerivedData
{
	products: IProductIdentifier[]; // unique to product
	recommendedProducts: _.Dictionary<IProductIdentifier>; // unique to series, map series id to best product
	summary: {
		product: IProductIdentifier;
		color: string;
		area: number;
		quantity: number;
	}[];
}

export interface IFieldPlanState
{
	errorMessage?: string;
	isError: boolean;
	// Reference count the number of items loading.
	isLoading: number;
	isSuccess: boolean;
	pollComplete: boolean;
	pollLoading: boolean;

	assignmentChanges: boolean; // Seed Assignment changes to be saved
	selectedCropPlanId: string;

	// Calculated data combining the above product data with the selected crop plan (if there is one!)
	selectedCropPlanDerivedData?: ICropPlanDerivedData;

	highlightHybridId: string;
	currentFieldPlan: IFieldPlanResponse;
	fullCropPlanData: ICropPlanFullResponse[];
	cropPlanAgronomicAttributes: {
		[cropPlanId: string]: ICropPlanAgronomicAttributes
	}
}

const initialState: IFieldPlanState = {
	assignmentChanges: false,
	errorMessage: undefined,
	highlightHybridId: undefined,
	isError: false,
	isLoading: 0,
	isSuccess: false,
	pollComplete: false,
	pollLoading: false,
	selectedCropPlanId: undefined,
	currentFieldPlan: undefined,
	fullCropPlanData: [],
	cropPlanAgronomicAttributes: {},
	selectedCropPlanDerivedData: undefined
};

export const fieldPlanSlice = createSlice({
	name: 'fieldplan',
	initialState: initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
		clearSuccess: (state) =>
		{
			state.isSuccess = false;

			return state;
		},
		updateFieldPlanProgress: (state, { payload } : PayloadAction<IFieldPlanResponse>) => 
		{
			state.currentFieldPlan = payload;

			return state;
		},
		setSelectedCropPlanId: (state, { payload } : PayloadAction<string>) =>
		{
			state.selectedCropPlanId = payload;

			return state;
		},
		updateCropPlanFieldData: (state, { payload } : PayloadAction<ICropPlanField[]>) =>
		{
			const selectedCropPlanIndex = state.fullCropPlanData.findIndex(cp => cp.Id === state.selectedCropPlanId);

			if (selectedCropPlanIndex < 0)
			{
				return state;
			}

			state.fullCropPlanData[selectedCropPlanIndex].Fields = payload;

			return state;
		},
		setAssignmentChanges: (state, { payload } : PayloadAction<boolean>) =>
		{
			state.assignmentChanges = payload;

			return state;
		},
		setHighlightHybridId: (state, { payload } : PayloadAction<string>) =>
		{
			state.highlightHybridId = payload;

			return state;
		},
		setDerivedData: (state, { payload } : PayloadAction<ICropPlanDerivedData | undefined>) =>
		{
			state.selectedCropPlanDerivedData = payload;
			return state;
		},
		clearFullCropPlanData: (state) =>
		{
			state.fullCropPlanData = [];

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		// Create Field Plan
		builder.addCase(createFieldPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(createFieldPlan.fulfilled, (state, { payload }: PayloadAction<IFieldPlanResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
			state.currentFieldPlan = payload;
		});
		builder.addCase(createFieldPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to create the Field Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem creating the Field Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem creating the Field Proposal. Please refresh and try again.';
			}
		});

		// Get Field Plan
		builder.addCase(getFieldPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(getFieldPlan.fulfilled, (state, { payload }: PayloadAction<IFieldPlanResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
			state.currentFieldPlan = payload;
		});
		builder.addCase(getFieldPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to retrieve the Field Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the Field Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Field Proposal. Please refresh and try again.';
			}
		});

		// Create Crop Plan
		builder.addCase(createCropPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(createCropPlan.fulfilled, (state, { payload }: PayloadAction<ICropPlanFullResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
		});
		builder.addCase(createCropPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to create the a Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem creating a Crop Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem creating a Crop Plan. Please refresh and try again.';
			}
		});

		// Get Crop Plan
		builder.addCase(getCropPlan.pending, (state, action) =>
		{
			if(action.meta.arg.blocking)
			{
				++state.isLoading;
			}
		});
		builder.addCase(getCropPlan.fulfilled, (state, { meta, payload }) =>
		{
			if(meta.arg.blocking)
			{
				--state.isLoading;
			}
			state.isSuccess = true;

			// May be updating the status of an existing crop plan, check to see if it is in the list
			const foundIndex = state.fullCropPlanData.findIndex(cp => cp.Id === payload.Id);
			if (foundIndex > -1)
			{
				state.fullCropPlanData[foundIndex] = payload;
			}
			else
			{
				state.fullCropPlanData.push(payload);
			}
		});
		builder.addCase(getCropPlan.rejected, (state, action) =>
		{
			if(action.meta.arg.blocking)
			{
				--state.isLoading;
			}
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to retrieve the Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the Crop Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Crop Plan. Please refresh and try again.';
			}
		});

		// Crop Plan Agronomic Attributes: getCropPlanAgronomicAttributes
		builder.addCase(getCropPlanAgronomicAttributes.pending, (state, { meta }) =>
		{
			if(meta.arg.blocking)
			{
				++state.isLoading;
			}
		});

		builder.addCase(getCropPlanAgronomicAttributes.fulfilled, (state, {meta, payload}) =>
		{
			if(meta.arg.blocking)
			{
				--state.isLoading;
			}
			state.cropPlanAgronomicAttributes[payload.CropPlanId] = payload;
		});

		builder.addCase(getCropPlanAgronomicAttributes.rejected, (state, {meta, payload}) =>
		{
			if(meta.arg.blocking)
			{
				--state.isLoading;
			}
			
			state.isError = true;
			if (payload)
			{
				if (payload.toString().indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to retrieve the Crop Plan Agronomic Attributes. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem retrieving the Crop Plan Agronomic Attributes. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Crop Plan Agronomic Attributes. Please refresh and try again.';
			}
		});


		// Run Crop Plan
		/*fieldplan/cropplan/run*/
		builder.addCase(runCropPlan.pending, (state, action) =>
		{
			++state.isLoading;
			// we will locally show the processing status as soon as we make the request
			const currentEntry = state.currentFieldPlan.CropPlans.find(cp => cp.Id === action.meta.arg.CropPlanId);
			if(currentEntry)
			{
				currentEntry.Status = 'Processing';
				currentEntry.Modified = new Date().toISOString();
				currentEntry.Message = '';
			}
		});
		builder.addCase(runCropPlan.fulfilled, (state, { payload }: PayloadAction<ICropPlanFullResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
			// we will patch in the updated data here
			const currentEntry = state.currentFieldPlan.CropPlans.find(cp => cp.Id === payload.Id);
			if(currentEntry)
			{
				currentEntry.Status = payload.Status;
				currentEntry.Message = payload.Message;
				currentEntry.Modified = payload.Modified;
			}
		});
		builder.addCase(runCropPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Crop Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Crop Plan. Please refresh and try again.';
			}
		});

		// Edit crop plan
		builder.addCase(updateCropPlan.pending, (state, action) =>
		{
			++state.isLoading;
			if(!action.meta.arg.StoreResponse)
			{
				// Clear the data as we'll be pulling it down again with the poll/getCropPlan
				state.fullCropPlanData = [];
			}
		});
		builder.addCase(updateCropPlan.fulfilled, (state, { payload, meta }) =>
		{
			--state.isLoading;
			state.isSuccess = true;

			if(meta.arg.StoreResponse)
			{
				state.fullCropPlanData = [payload];
			}
			// Otherwise ignore the payload as it will not have the updated data because the plan has to run again first
		});
		builder.addCase(updateCropPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Crop Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Crop Plan. Please refresh and try again.';
			}
		});

		// Edit crop plan products
		builder.addCase(assignProductsToCropPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(assignProductsToCropPlan.fulfilled, (state, { payload }: PayloadAction<ICropPlanFullResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
			// Wipe out any other crop plans because fields may have been stolen from another plan and it will not have been updated
			state.fullCropPlanData = [];
		});
		builder.addCase(assignProductsToCropPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Crop Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Crop Plan. Please refresh and try again.';
			}
		});

		// Edit crop plan fields
		builder.addCase(updateCropPlanFields.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(updateCropPlanFields.fulfilled, (state, { payload }: PayloadAction<ICropPlanFullResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
			// Wipe out any other crop plans because fields may have been stolen from another plan and it will not have been updated
			state.fullCropPlanData = [];
		});
		builder.addCase(updateCropPlanFields.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Crop Plan. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem retrieving the Crop Plan. Please refresh and try again.';
			}
		});
		

		// Create Field & Crop Plan
		builder.addCase(createFieldPlanWithCropPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(createFieldPlanWithCropPlan.fulfilled, (state) =>
		{
			--state.isLoading;
			state.pollComplete = false;
			state.isSuccess = true;
		});
		builder.addCase(createFieldPlanWithCropPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to initialize a Field Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem initializing the Field Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem initializing the Field Proposal. Please refresh and try again.';
			}
		});

		// Convert product to field plan
		
		// Create Field & Crop Plan
		builder.addCase(convertProductPlanToFieldPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(convertProductPlanToFieldPlan.fulfilled, (state) =>
		{
			--state.isLoading;
			state.pollComplete = false;
			state.isSuccess = true;
		});
		builder.addCase(convertProductPlanToFieldPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to convert your Acre Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem converting the Acre Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem converting the Acre Proposal. Please refresh and try again.';
			}
		});

		// Poll Field Plan
		builder.addCase(pollGetFieldPlan.pending, (state, action) =>
		{
			state.pollLoading = true;
		});
		builder.addCase(pollGetFieldPlan.fulfilled, (state, { payload }: PayloadAction<IFieldPlanResponse>) =>
		{
			state.isSuccess = true;
			state.pollComplete = true;
			state.pollLoading = false;
		});
		builder.addCase(pollGetFieldPlan.rejected, (state, action) =>
		{
			state.isError = true;
			state.pollComplete = true;
			state.pollLoading = false;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to initialize a Field Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem initializing the Field Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem initializing the Field Proposal. Please refresh and try again.';
			}
		});

		// Delete Crop Plan
		builder.addCase(deleteCropPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(deleteCropPlan.fulfilled, (state, action) =>
		{
			state.isSuccess = true;
			--state.isLoading;
		});
		builder.addCase(deleteCropPlan.rejected, (state, action) =>
		{
			state.isError = true;
			--state.isLoading;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to delete the Crop Plan. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem delete the Crop Plan. Please refresh and try again.';
			}
		});

		// Update Field Plan
		builder.addCase(updateFieldPlan.pending, (state, action) =>
		{
			++state.isLoading;
		});
		builder.addCase(updateFieldPlan.fulfilled, (state, { payload }: PayloadAction<IFieldPlanResponse>) =>
		{
			--state.isLoading;
			state.isSuccess = true;
			state.currentFieldPlan = payload;
		});
		builder.addCase(updateFieldPlan.rejected, (state, action) =>
		{
			--state.isLoading;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to update the Field Proposal. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = 'There was a problem updating the Field Proposal. Please refresh and try again.';
				}
			}
			else
			{
				state.errorMessage = 'There was a problem updating the Field Proposal. Please refresh and try again.';
			}
		});
	}
});

export const {
	clearState,
	clearError,
	clearSuccess,
	updateFieldPlanProgress,
	setSelectedCropPlanId,
	updateCropPlanFieldData,
	setAssignmentChanges,
	setHighlightHybridId,
	setDerivedData,
	clearFullCropPlanData,
} = fieldPlanSlice.actions;