import React from 'react';
import { PortalPopUp } from './PortalPopUp';
import { ReactComponent as CornIcon } from '../../../pages/FieldActivities/Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from '../../../pages/FieldActivities/Icons/SoyIcon.svg';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

interface IVrsMapPopUpProps
{
	acres: string;
	cropName: string;
	farmName: string;
	fieldName: string;
	reference: HTMLDivElement | undefined;
}

const StyledSoyIcon = styled(SoyIcon)`
	path {
			stroke: ${themeGet('colors.darkGrey')};
		}
`;

export const VrsMapPopUp = (props: IVrsMapPopUpProps) =>
{
	const { acres, cropName, farmName, fieldName, reference } = props;

	const theme = useTheme();

	const determineCropIcon = () =>
	{
		if (cropName)
		{
			switch(cropName.toLocaleLowerCase())
			{
				case 'corn':
					return (
						<CornIcon width={45} height={45} />
					);
				default:
					return (
						<StyledSoyIcon width={45} height={45} />
					);
			}
		}
	};

	return (
		<PortalPopUp className='VrsMapPopUp_Container' reference={reference}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}
			>
				<span
					className='VrsMapPopUp_FieldName'
					style={{
						fontSize: theme.fontSizes.normal,
						fontWeight: theme.fontWeights.bold,
						paddingBottom: '2%'
					}}
				>
					{fieldName}
				</span>
				<span
					className='VrsMapPopUp_FarmName'
					style={{
						fontSize: theme.fontSizes.extraSmall,
						fontWeight: theme.fontWeights.bold,
						paddingBottom: '5%'
					}}
				>
					{farmName}
				</span>
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<span
						className='VrsMapPopUp_Acres'
						style={{
							fontSize: theme.fontSizes.extraSmall,
							fontWeight: theme.fontWeights.bold,
							display: 'flex',
							alignItems: 'flex-end'
						}}
					>
						{acres} ac
					</span>
					<div style={{ display: 'flex', marginLeft: 'auto' }}>
						{determineCropIcon()}
					</div>
				</div>
			</div>
		</PortalPopUp>
	);
};