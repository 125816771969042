import React from 'react';
import L from 'leaflet';
import { MapCustomControl } from './MapCustomControl';
import { IconButton } from '../../components/IconButton/IconButton';
import { ReactComponent as SelectBoundary } from './Icons/SelectBoundary.svg';
import { ReactComponent as Close } from '../../assets/images/Close.svg';
import { ReactComponent as Save } from '../../assets/images/DocumentCheck.svg';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const MapControlButton = styled.div`
	background-color: ${themeGet('colors.white')};
	height: 33px;
	width: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	border: 2px solid rgba(0,0,0,0.3);
	&:hover {
		background-color: ${themeGet('colors.primary')};
	}
`;

interface IDrawingTrayProps
{
	mapRef: React.MutableRefObject<L.Map | undefined>;
	onEnableCLU: () => Promise<void>;
	onCancelCLU: () => void;
	onSaveCLU: () => void;
	isMapModeCLU: boolean;
	displayTools: boolean;
}

export const DrawingTray = (props: IDrawingTrayProps) =>
{
	const { mapRef, displayTools, isMapModeCLU, onEnableCLU, onCancelCLU, onSaveCLU } = props;

	const addCustomControl = (control: React.ReactElement, position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined) =>
	{
		return (
			<MapCustomControl map={mapRef.current} position={position} show={displayTools}>
				<MapControlButton>
					{control}
				</MapControlButton>
			</MapCustomControl>
		);
	};

	return (
		<div>
			{
				isMapModeCLU &&
					addCustomControl(
						<IconButton
							tooltip='Save'
							placement='left'
							onClick={() => onSaveCLU()}
						>
							<Save />
						</IconButton>,
						'topright'
					)
			}
			{
				isMapModeCLU &&
					addCustomControl(
						<IconButton
							tooltip='Cancel'
							placement='left'
							onClick={() => onCancelCLU()}
						>
							<Close />
						</IconButton>,
						'topright'
					)
			}
			{
				!isMapModeCLU &&
					addCustomControl(
						<IconButton
							tooltip='Select Boundary'
							placement='left'
							onClick={async() => await onEnableCLU()}
						>
							<SelectBoundary />
						</IconButton>,
						'topright'
					)
			}
		</div>
	);
};