import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import ReactDOM from 'react-dom';

export type MapCustomControlProps = {
    position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined;
    children: React.ReactNode;
	map: L.Map | undefined;
	show: boolean;
	zIndex?: string;
};

/**
 * Lightweight wrapper to allow widgets to be able to be shown on the map as UI
 */
export const MapCustomControl = (props: MapCustomControlProps ): JSX.Element =>
{
	const { position, children, map, show, zIndex } = props;
	const [widgetElement, setWidgetElement] = useState<HTMLDivElement | undefined>();

	// Make sure this element is positioned on the map when the map exists and show==true
	useEffect(() => 
	{
		if (map && show)
		{
			// Create the class for this control.  It presumes that the instance will be wrapped in a <div>
			const button = L.Control.extend({
				onAdd: function (map: L.Map)
				{
					const item = L.DomUtil.create('div') as HTMLDivElement;
					if (zIndex)
					{
						item.style.zIndex = zIndex;
					}
					setWidgetElement(item);
					return item;
				}
			});

			// Instantiate the control, adding automatically to the map.
			const buttonInstance = new button({ position: position ?? 'topright' }).addTo(map);

			// Return a function that will remove this control from the map.
			return () =>
			{
				buttonInstance.remove();
				setWidgetElement(undefined);
			};
		}
	}, [map, show, setWidgetElement]);

	if (!widgetElement)
	{
		return null;
	}

	// Use a portal to render into the <div> created by the control.
	return ReactDOM.createPortal(children, widgetElement);
};