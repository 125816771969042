import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>
{
	/** Button label */
	children: ReactNode;
	/** Button variation */
	variant: 'outlined' | 'dark' | 'light' | 'dashed' | 'main' | 'reversed';
	fullWidth?: boolean | undefined;
	width?: string | undefined;
	height?: string | undefined;
}

const getColor = (variant: string) => ({ outlined: 'darkGrey', dark: 'white', light: 'darkGrey', dashed: 'primary', main: 'white', reversed: 'white' }[variant]);
const getBgColor = (variant: string) => ({ outlined: 'white', dark: 'darkGrey', light: 'white', main: 'primary', reversed: 'secondary' }[variant]);
const getHoverBgColor = (variant: string) => ({ outlined: 'lightestGrey', dark: 'darkGrey', light: 'lightestGrey', dashed: 'primary', main: 'secondary', reversed: 'primary' })[variant];
const getHoverColor = (variant: string) => ({ dark: 'lightestGrey', dashed: 'white' })[variant];
const getDisabledBgColor = (variant: string) => ({ outlined: 'white', dark: 'lightestGrey', light: 'lightestGrey', main: 'lightestGrey', reversed: 'lightestGrey' })[variant];
const getBorderStyle = (variant: string) => ({ outlined: 'solid', dark: 'solid', dashed: 'dashed' })[variant];
const getBorderColor = (variant: string) => ({ dashed: 'primary', outlined: 'darkGrey', dark: 'darkGrey', light: 'none', main: 'none', reversed: 'none' })[variant];
const getHoverBorderStyle = (variant: string) => ({ dashed: 'solid' })[variant];

/**
 * @component A button.
 */
export const Button = styled.button<IButtonProps>`
	display: ${props => props.fullWidth && 'block'};
	width: ${props => props.fullWidth ? '100%' : props.width ? props.width : ''};
	height: ${props => props.height ? props.height : '40px'};
	text-align: center;
	outline: none;
	border: ${props => props.variant === 'light' ? themeGet('borders.none') : themeGet('borders.thin')};
	border-color: ${props => props.theme.colors[getBorderColor(props.variant)]};
	border-radius: ${themeGet('radii.base')};
	border-style: ${props => getBorderStyle(props.variant) || 'none'};
	font-family: ${themeGet('fonts.base')};
	font-size: ${themeGet('fontSizes.base')};
	font-weight: 700;
	color: ${props => props.theme.colors[getColor(props.variant)]};
	background-color: ${props => props.theme.colors[getBgColor(props.variant)] || 'transparent'};
	text-decoration: none;
	user-select: none;
	box-sizing: border-box;
	/* We can't use :enabled here because it doesn't work with <a> */
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		color: ${props => props.theme.colors[getHoverColor(props.variant)]};
		background-color: ${props => props.theme.colors[getHoverBgColor(props.variant)]};
		border-style: ${props => props.theme.colors[getHoverBorderStyle(props.variant)]};
		cursor: pointer;
	}
	&:hover:not(:disabled) > svg > path {
		fill: ${props => props.theme.colors[getHoverColor(props.variant)]};
	}
	&:disabled {
		background-color: ${props => props.theme.colors[getDisabledBgColor(props.variant)]};
		border-color: ${themeGet('colors.lightestGrey')};
		color: ${themeGet('colors.lightGrey')};
		pointer-events: none;
	}
	&::-moz-focus-inner {
		border: 0;
	}
`;