import React, { useState } from 'react';
import { ReactComponent as ExportPdf } from '../../../assets/images/PDFIcon.svg';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import { IPlanEditState } from '../../../logic/store/Plans/ProductGamePlanSlice';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/Button/Button';
import { newPlanDummyId } from '../../../logic/store/Plans/ProductPlanUtils';
import { createProductGamePlan } from '../../../logic/store/Plans/ProductGamePlanActions';
import { saveProductPlan } from '../../../logic/store/Plans/ProductGamePlanUpdateActions';
import { Tooltip } from 'antd';
import { IconButton } from './../../../components/IconButton/IconButton';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import styled, { useTheme } from 'styled-components';

interface IProductPlanHeaderRowProps
{
	isSavingPlan: boolean;
	isDownloadingProductPlan: boolean;
	localEditState?: IPlanEditState;
	onSave: () => void;
	onConvert: () => void;
	selectedGrower: IGrowerResponse;
	planId: string;
}

const HeaderButton = styled(Button)`
	font-size: 14px;
	padding: 0 16px 0 16px;
	height: 32px;
	border-width: 2px;
`;

/** The top section of a product plan */
export function ProductPlanHeaderRow(props: IProductPlanHeaderRowProps)
{
	const { selectedGrower, planId, localEditState, onSave, onConvert, isSavingPlan, isDownloadingProductPlan } = props;
	const dispatch = useDispatch();

	const theme = useTheme();

	const downloadPDF = () =>
	{
		if (planId === newPlanDummyId)
		{
			// If we have not yet saved the plan, save it and then on the response of a plan Id
			// inside createProductGamePlan, print the PDF if printPDF = true
			dispatch(createProductGamePlan({ coverConfig: { CoverConfig: undefined, PlanId: planId }, printPDF: true }));
		}
		else
		{
			// If neither a plan was passed in nor are there any accepted work orders to default to, bail out
			if (planId == newPlanDummyId)
				return;

			// The plan is already saved, so just use the existing planId
			// but save it first!
			dispatch(saveProductPlan({ request: { CoverConfig: undefined, PlanId: planId }, printPDF: true }));

		}
	};

	const numberOfProductsAdded = localEditState ? Object.values(localEditState.products).length : 0;
	const numberOfFields = selectedGrower?.Farms?.reduce((count, farm) => count + (farm.Fields?.length ?? 0), 0) ?? 0;
	const allProductsApplied = (localEditState && numberOfProductsAdded) ? Object.values(localEditState.products).every(item =>
	{
		const { product } = item;
		return product.AppliedArea && product.Quantity && product.SeedingRate;
	}) : false;

	return(
		<div className='ProductPlanHeaderRow' style={{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			fontFamily: theme.fonts.heading,
			fontSize: theme.fontSizes.large,
			fontWeight: theme.fontWeights.bold,
			paddingLeft: 18,
			paddingRight: 0,
			paddingTop: 16,
			paddingBottom: 1
		}}>
			<div className='PageTitle' style={{fontSize: theme.fontSizes.small}}>
				Acre Proposal
			</div>
			<div style={{ marginRight: 8, marginLeft: 8, marginBottom: 5 }}>
				<Ellipse width={7} height={7} />
			</div>
			<div style={{display: 'flex', flexDirection: 'row', fontSize: theme.fontSizes.large}}>
				<div style={{ marginRight: 8 }}>{selectedGrower.Name}</div>
				<div style={{ marginRight: 8 }}>|</div>
				<div style={{ marginRight: 32 }}>{selectedGrower.Zip}, {selectedGrower.State}</div>
			</div>
			<div className='Spacer' style={{ flexGrow: 1 }} />			
			<div className='ActionArea' style={{
				flexShrink: 0,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center'
			}}>

				<div style={{display: 'flex', flexDirection: 'row'}}>
					<IconButton
						tooltip={isDownloadingProductPlan || !localEditState || !allProductsApplied ? 'You must add a product to your Proposal in order to save then download the PDF' : ''}
						placement='bottom'
						onClick={() => downloadPDF()}
						fillcolor='auto' // purposefully incorrect so as to not have a fillcolor
						style={{height: 32, width: 32, marginRight: 12}}
						disabled={isDownloadingProductPlan || !localEditState || !allProductsApplied}
						showDisabledTooltip= { true }
					>
						<ExportPdf height={40} width={40} style={{ position: 'relative' }} />
					</IconButton>
					<Tooltip title={
						(numberOfFields == 0) 
							? 'This option is only available if fields are uploaded to a grower\'s account'
							: (!localEditState || !numberOfProductsAdded) 
								? 'You must add a product to your Plan in order to place it on fields.' 
								: !allProductsApplied ? 'All products must have valid applied area, quantity, and seeding rate.' : ''} placement='bottom'>
						<span>
							<HeaderButton variant='outlined' disabled={(numberOfFields == 0) || isDownloadingProductPlan || !localEditState || !allProductsApplied} onClick={onConvert}>
							Place on Fields
							</HeaderButton>
						</span>
					</Tooltip>
					<Tooltip title={(!localEditState || !numberOfProductsAdded) ? 'You must add a product to your Proposal in order to save it.' : !allProductsApplied ? 'All products must have valid applied area, quantity, and seeding rate.' : ''} placement='bottom'>
						<span>
							<HeaderButton style={{ marginLeft: 16 }} variant='dark' disabled={isSavingPlan || !localEditState || !allProductsApplied} onClick={onSave}>
							Save Proposal
							</HeaderButton>
						</span>
					</Tooltip>
				</div>
			</div>
		</div>
	);
}
