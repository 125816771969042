import React from 'react';
import { Checkbox as DefaultCheckbox } from '../Checkbox/Checkbox';
import { CheckboxProps } from 'antd';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';

const Container = styled.label`
	display: flex;
	flex-direction: column;
	width: 120px;
	height: 140px;
	position: relative;
	cursor: pointer;
`;

const BottomLabel = styled.div`
	color: ${themeGet('colors.white')};
	background-color: ${themeGet('colors.primary')};
	font-size: ${themeGet('fontSizes.tiny')};
	font-weight: ${themeGet('fontWeights.bold')};
	height: 100%;
	border-radius: 0px 0px 6px 6px;
	border: 1px solid ${themeGet('colors.primary')};
	padding-left: 10px;
	display: flex;
	align-items: center;
	flex-basis: 20%;
`;

const ThumbnailStyledCheckbox = styled(DefaultCheckbox)`
	.ant-checkbox {
		top: 8px;
		background: white;
		border-radius: 4px;
	}
`;

interface IThumbnailCheckboxProps extends CheckboxProps
{
	thumbnail: string;
	label: string;
	containerStyle?: React.CSSProperties;
	checkboxId: string;
}

/** @component */
export const ThumbnailCheckbox = (props: IThumbnailCheckboxProps) =>
{
	const { thumbnail, label, containerStyle, checkboxId, ...checkboxProps } = props;

	const theme = useTheme();

	return (		
		<Container style={{...containerStyle}} itemType='checkbox' htmlFor={checkboxId}>
			{
				checkboxProps.disabled && 
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						backgroundColor: theme.colors.transparentBlack,
						zIndex: 1,
						cursor: 'not-allowed',
						borderRadius: '6px'
					}}
				/>
			}
			<img
				src={thumbnail} alt=''
				style={{ width: '120px', height: '112px', borderRadius: '6px 6px 0px 0px', flexBasis: '80%', border: `1px solid ${theme.colors.primary}`, borderBottom: 0 }}
			/>
			<BottomLabel>{label}</BottomLabel>
			<div 
				style={{
					position: 'absolute',
					right: 1,
					top: 1,
					height: 36,
					width: 36,
					backgroundColor: 'rgba(26, 25, 25, 0.7)',
					borderRadius: '0px 5px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<ThumbnailStyledCheckbox
					id={checkboxId}
					style={{
						borderRadius: '0px 6px',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						justifyContent: 'center'
					}}
					{ ...checkboxProps }
				/>
			</div>
		</Container>		
	);
};