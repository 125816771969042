import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import LogRocket from 'logrocket';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { authSlice } from './User/AuthSlice';
import { userInfoSlice } from './User/UserInfoSlice';
import { growerSlice } from './Grower/GrowerSlice';
import { uiSlice } from './UI/UISlice';
import { attributesSlice } from './Seeds/AttributesSlice';
import { cropsSlice } from './Seeds/CropsSlice';
import { userSeedAttributeSlice } from './User/UserSeedAttributesSlice';
import { customTreatmentsSlice } from './User/CustomTreatmentsSlice';
import { seedsSlice } from './Seeds/SeedsSlice';
import { productGamePlanSlice } from './Plans/ProductGamePlanSlice';
import createMigrate from 'redux-persist/es/createMigrate';
import { configSlice } from './User/ConfigSlice';
import { plantingRateSlice } from './Seeds/PlantingRateSlice';
import { CLUSlice } from './CLUs/CLUSlice';
import { SoilDataSlice } from './SoilData/SoilDataSlice';
import { recordingSlice } from './UI/RecordingSlice';
import { migrations } from './StoreMigrations';
import { managementZoneEditsSlice } from './ManagementZones/ManagementZoneSlice';
import { fileSlice } from './MachineFileData/FileSlice';
import { bulkImportSlice } from './BulkImport/BulkImportSlice';
import { fieldPlanSlice } from './Plans/FieldPlan/FieldPlanSlice';
import { userAdminSlice } from './UserAdmin/UserAdminSlice';
import { appListener } from '../Middleware/AppListener';
import { globalSession } from '../../tracing/session';

const rootPersistConfig = {
	key: 'root',
	storage,
	version: 14,
	blacklist: [
		'auth',
		'grower',
		'seedAttributes',
		'crops',
		'userAttributes',
		'seeds',
		'productGamePlan',
		'plantingRate',
		'orders',
		'ui',
		'managementZoneEdits',
		'bulkImport',
		'fieldplan',
		'userInfo',
		'userAdmin'
	],
	migrate: createMigrate(migrations)
};

const authPersistConfig = {
	key: 'auth',
	storage,
	blacklist: ['isLoading']
};

const userInfoPersistConfig = {
	key: 'userInfo',
	storage,
	blacklist: ['isLoading', 'ShipFromAddresses']
};

const growerPersistConfig = {
	key: 'grower',
	storage,
	blacklist: [
		'isLoading',
		'isLoadingGrowers',
		'LoadingGrowerOrders',
		'isLoadingFarms',
		'isLoadingFields',
		'isLoadingDraftQuotes',
		'isLoadingPlans',
		'isLoadingOrderPlanMaxScript',
		'isLoadingBulkCropUpdateMaxScript',
		'isLoadingPlanUpdate',
		'isLoadingPlanDelete',
		'isLoadingPlanLaunch',
		'isLoadingPlanLaunchExisting',
		'isLoadingPlanPdfDownload',
		'isLoadingStartBulkImport',
		'isLoadingInvitation',
		'isSavingFarm',
		'isSavingField',
		'isDeletingFarm',
		'isDeletingField',
		'loadingCounter',
		'isLoadingPlantingPlansSummaries',
		'isLoadingGetPlantingPlansSummaries',
		'isLoadingFieldPlantingPlan',
		'isLoadingSaveManagementZone',
		'isLoadingUpdateFieldPlantingPlan',
		'isLoadingDownloadFieldPlantingPlan',
		'showSuccessNotification',
		'ExpectingGrowerDataUserId'
	]
};

const seedAttrsPersistConfig = {
	key: 'seedAttributes',
	storage,
	blacklist: ['isLoading']
};

const customTreatmentsPersistConfig = {
	key: 'customTreatments',
	storage,
	blacklist: ['isLoadingGetTreatments', 'isLoadingSaveTreatments', 'isLoadingGetUserDefaultTreatments', 'isLoadingSaveUserDefaultTreatments']
};

const cropsPersistConfig = {
	key: 'crops',
	storage,
	blacklist: ['isLoading']
};

const userAttrsPersistConfig = {
	key: 'userAttributes',
	storage,
	blacklist: [
		'isLoading',
		'isSaving'
	]
};

const seedsPersistConfig = {
	key: 'seeds',
	storage,
	blacklist: [
		'isLoading',
		'isGettingSeedsForGrower',
		'isGettingSeedsForCurrentUser',
		'growerSeeds',
		'competitors'
	],
};

const productGameplanPersistConfig = {
	key: 'productGamePlan',
	storage,
	blacklist: [
		'isLoadingCreateProductPlan',
		'isLoadingGetProductPlan',
		'isSavingProductPlan',
		'isDownloadingProductPlan',
		'isFieldlessLoading',
	]
};

const plantingRatePersistConfig = {
	key: 'plantingRate',
	storage,
	blacklist: ['isLoadingPlantingRates', 'rates']
};

const cluPersistConfig = {
	key: 'clu',
	storage,
	blacklist: ['isLoading']
};

const soilDataPersistConfig = {
	key: 'soilData',
	storage,
	blacklist: ['isLoading']
};

const recordingPersistConfig = {
	key: 'recording',
	storage,
	blacklist: ['isRecording', 'cardId']
};

const uiPersistConfig = {
	key: 'ui',
	storage,
	blacklist: ['isLoading', 'isError', 'errorMessage', 'MapInteractionMode', 'SelectedFieldForEdit', 'ShowDrawingTools', 'DrawnFeatures']
};

const managementZoneEditsPersistConfig = {
	key: 'managementZoneEdits',
	storage,
	blacklist: ['isLoading', 'isError', 'errorMessage', 'undoStack', 'mode', 'selectedZone', 'zoneEditingState']
};

const filePersistConfig = {
	key: 'file',
	storage,
	blacklist: ['isLoadingFileListing', 'isLoadingFileUrl', 'isError', 'errorMessage', 'FileListing', 'FileUploadList']
};

const bulkImportPersistConfig = {
	key: 'bulkImport',
	storage,
	blacklist: [
		'errorMessage',
		'farmKey',
		'farms',
		'fieldKey',
		'geometry',
		'header',
		'isError',
		'isLoadingGeometry',
		'isLoadingHeader',
		'isLoadingProfile',
		'isLoadingPublish',
		'isSuccess',
		'sessionInProgress',
	]
};

const fieldPlanPersistConfig = {
	key: 'fieldplan',
	storage,
	blacklist: [
		'errorMessage',
		'isLoading',
		'isSuccess',
		'isError',
		'pollComplete',
		'pollLoading',
		'assignmentChanges',
		'highlightHybridId',
	]
};

const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authSlice.reducer),
	userInfo: persistReducer(userInfoPersistConfig, userInfoSlice.reducer),
	grower: persistReducer(growerPersistConfig, growerSlice.reducer),
	ui: persistReducer(uiPersistConfig, uiSlice.reducer),
	seedAttributes: persistReducer(seedAttrsPersistConfig, attributesSlice.reducer),
	crops: persistReducer(cropsPersistConfig, cropsSlice.reducer),
	userAttributes: persistReducer(userAttrsPersistConfig, userSeedAttributeSlice.reducer),
	seeds: persistReducer(seedsPersistConfig, seedsSlice.reducer),
	productGamePlan: persistReducer(productGameplanPersistConfig, productGamePlanSlice.reducer),
	config: configSlice.reducer,
	clu: persistReducer(cluPersistConfig, CLUSlice.reducer),
	plantingRate: persistReducer(plantingRatePersistConfig, plantingRateSlice.reducer),
	customTreatments: persistReducer(customTreatmentsPersistConfig, customTreatmentsSlice.reducer),
	soilData: persistReducer(soilDataPersistConfig, SoilDataSlice.reducer),
	recording: persistReducer(recordingPersistConfig, recordingSlice.reducer),
	managementZoneEdits: persistReducer(managementZoneEditsPersistConfig, managementZoneEditsSlice.reducer),
	file: persistReducer(filePersistConfig, fileSlice.reducer),
	bulkImport: persistReducer(bulkImportPersistConfig, bulkImportSlice.reducer),
	fieldplan: persistReducer(fieldPlanPersistConfig, fieldPlanSlice.reducer),
	userAdmin: userAdminSlice.reducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
	{
		const middleware = getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, // redux persist actions
					'file/addFileToList' // ignore this action because it is setting a type into the store that has non-serializable properties...
					// but we're not persisting it, just ignore the message
				],
				// Ignore these paths in the state because they have non-serializable properties
				ignoredPaths: ['file.FileUploadList'],
			}
		});
		middleware.push(LogRocket.reduxMiddleware());
		middleware.push(appListener.middleware);
		return middleware;
	},
});

export const persistor = persistStore(store, {  }, () => 
{
	// After rehydrate, we need to let amplitude know our identity (if we have one)
	const userInfo = store.getState()?.userInfo;
	const accessToken = store.getState()?.auth?.userAuthToken;
	if(accessToken)
	{
		// Apply this access token to global session
		globalSession.initializeSession(accessToken, userInfo.UserId, userInfo.FoundationId, userInfo.UserEmail, userInfo.UserName);
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;