import { createTracedAsyncThunk } from '../../../tracing/trace';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';

export interface IJdConnectInvitationResponse
{
	SellerId: string;
    GrowerId: string;
    Token: string;
    CreatedDate: string;
    Status: string;
}

export const createInvitationAndGetQrCodeWithLink = createTracedAsyncThunk<IJdConnectInvitationResponse, {growerId: string}, { dispatch: AppDispatch, state: RootState }>(
	'jdconnect/createinvitation',
	async (context, request: {growerId: string}, thunkAPI) => 
	{
		try
		{
			const { growerId } = request;
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			
			const response = await api.postAsync<IJdConnectInvitationResponse>(`growers/${growerId}/jd-connect/invitations`, '');

			if (response.ErrorCode === null && response.Success)
			{
				thunkAPI.dispatch(getQrCodeWithLink({token: response.Data.Token, growerId: growerId}));
				return response.Data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface IJdConnectQrResponse
{
	QrCode: string;
	Url: string;
	GrowerId: string;
}

export const getQrCodeWithLink = createTracedAsyncThunk<IJdConnectQrResponse, {token: string, growerId: string}, { dispatch: AppDispatch, state: RootState }>(
	'jdconnect/getqrcodeandlink',
	async (context, request: {token: string, growerId: string}, thunkAPI) => 
	{
		try
		{
			const { token, growerId } = request;
			const api = new Api('/api/6', thunkAPI.getState().auth.userAuthToken, context);
			
			const response = await api.getAsync<IJdConnectQrResponse>(`jd-connect/invitations/${token}/qrwithurl`);

			if (response.ErrorCode === null && response.Success)
			{
				// Attach the grower Id from the request
				const data: IJdConnectQrResponse =
				{
					QrCode: response.Data.QrCode,
					Url: response.Data.Url,
					GrowerId: growerId,
				};

				return data;
			}
			else
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);