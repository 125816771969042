import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Chart from 'react-google-charts';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { IAssignedSeed } from '../../../../logic/Models/Responses/FieldPlanResponse';
import _ from 'lodash';
import { DonutProductRow } from './DonutProductRow';
import { ReactComponent as Pencil } from '../Cards/Icons/Pencil.svg';
import { FieldListActionDrawerButton } from '../../FieldListActionDrawerButton';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ReactComponent as RightCaretIcon } from '../../Icons/RightCaret.svg';
import { Tooltip } from 'antd';
import { setHighlightHybridId } from '../../../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import { ReactComponent as Alert } from '../../Icons/AlertIcon.svg';
import { makeDispatch } from '../../../../logic/Utility/Utils';

/**
 * Donut panel displayed on Crop Plan specific view (product inventory)
 */

const CardContainer = styled.div`
	height: 100%;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	padding-bottom: 20px;
	padding-top: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	padding-right: 20px;
	padding-left: 20px;
	position: relative;
`;

const StyledMoreDetailsButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 20px;
	align-items: center;
`;

const StyledMoreDetailsButton = styled.div`
	color: ${themeGet('colors.primary')};
	font-weight: ${themeGet('fontWeights.bold')};
	margin-left: auto;
	padding: 5px;
	border-radius: 4px;

	/* We can't use :enabled here because it doesn't work with <a> */
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		background-color: ${themeGet('colors.lightestGrey')};
		cursor: pointer;
	}
`;

const StyledRightCaretIcon = styled(RightCaretIcon)`
	path {
		fill: ${themeGet('colors.primary')};
	}
`;

const StyledChart = styled(Chart)`
	// Fix the weird flickering of the tooltip if your cursor manages to stick inside it
	svg > g:last-child > g:last-child { pointer-events: none }
`;

export interface ISeedToAcres {
    Seed: IAssignedSeed;
    Acres: number;
}

interface IDonutPanelProps
{
	toggleFieldInventory: () => void;
	onEditFields: () => unknown;
	onEditProducts: () => unknown;
}

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	const SelectedCropPlan = state.fieldplan.fullCropPlanData.find(cp => cp.Id === state.fieldplan.selectedCropPlanId);

	return {
		HighlightHybridId: state.fieldplan.highlightHybridId,
		FieldPlan: state.fieldplan.currentFieldPlan,
		IsFieldPlanLoading: state.fieldplan.isLoading,
		SelectedGrower,
		SelectedCropPlan: SelectedCropPlan
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		SetHighlightedHybridId: makeDispatch(dispatch, setHighlightHybridId),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const DonutPanel = (props: IDonutPanelProps & PropsFromRedux) =>
{
	const {
		toggleFieldInventory,
		onEditProducts,
		onEditFields,
		HighlightHybridId,
		SelectedCropPlan,
		SetHighlightedHybridId,
	} = props;

	const theme = useTheme();
	
	const productData = useSelector((state: RootState) => state.fieldplan.selectedCropPlanDerivedData);

	// Seems to be required to be a local component variable otherwise the Chart selecthandler function cannot seem to access it,
	// even though it can access seedsToAcres just fine
	let highlighted: string = HighlightHybridId;

	const totalAcres = useMemo(() =>
	{
		if(!SelectedCropPlan)
		{
			return 0;
		}
		const allFieldHybrids = SelectedCropPlan.Fields.flatMap(f => f.SeedAssignments.flatMap(sa => sa));
		const totalAcres = allFieldHybrids.map(h => h.AppliedAcres).reduce((prev, current) => prev + current, 0);
		return totalAcres?.toLocaleString('en-US', {maximumFractionDigits:1});
	}, [SelectedCropPlan]);

	const chartData = useMemo(() =>
	{
		const mapping: (string | number)[][] = [['Seed', 'Acres']];
		if(productData)
		{
			productData.summary.forEach((entry) => 
			{
				mapping.push([entry.product.HybridName, entry.area]);
			});
		}
		return mapping;
	},[productData]);

	const donutOptions = useMemo(() =>
	{
		if (!productData)
		{
			return {};
		}
		const cropProductColors = productData.summary.map(sta => sta.color);
		return {
			//enableInteractivity: false, -- turns off ability to select
			colors: cropProductColors,
			is3D: false,
			legend: 'none',
			pieHole: 0.8,
			pieSliceText: 'none',
			//tooltip: { trigger: 'none' },
		};
	},[productData]);

	const planMenuItems: ItemType[] =
	[
		{
			onClick: onEditProducts,
			className: 'DonutPanel_UpdateProducts',
			key: 'Update Products',
			label: 'Update Products'
		},
		{
			onClick: onEditFields,
			className: 'DonutPanel_UpdateFields',
			key: 'Update Fields',
			label: 'Update Fields'
		},
	];

	const determineView = () =>
	{
		// Don't show anything until we have some sort of plan data
		if (!SelectedCropPlan)
		{
			return (<></>);
		}
		
		if (SelectedCropPlan.Status === 'Error')
		{
			return (
				<div className={'ChartContainer_Error'} style={{ display: 'flex', flexDirection: 'column', marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
					<span><Alert style={{ marginRight: 5, position: 'relative', top: 2 }} />Crop Plan Has an Error</span>
					<span>{SelectedCropPlan.Message}</span>
				</div>
			);
		}

		if (SelectedCropPlan.Fields.some(f => f.SeedAssignments.length !== 0 && f.SeedAssignments.some(sa => sa.AppliedAcres > 0)))
		{
			return (
				<div className={'ChartContainer'} style={{ display: 'flex', justifyContent: 'center', position: 'relative', width: '100%' }}>
					<div className={'ChartInnerContainer'} style={{ width: 323 }}>
						<StyledChart
							chartType='PieChart'
							chartEvents={
								[
									{
										eventName: 'select',
										callback: ({ chartWrapper }) =>
										{
											const selection = chartWrapper.getChart().getSelection()[0];
											if (selection && selection.row !== undefined && selection.row !== null &&
												selection.row >= 0 && selection.row < chartData.length)
											{
												// selectedSeed is the name of the hybrid
												const selectedSeed = chartData[selection.row + 1][0];
												const foundSeed = productData.summary.find(sta => sta.product.HybridName === selectedSeed);
												if (foundSeed)
												{
													if (highlighted === foundSeed.product.HybridId)
													{
														SetHighlightedHybridId(undefined);
														highlighted = undefined;
													}
													else
													{
														// Purposefully set this value twice so that the chart does not attempt to keep the
														// highlight on the bar section
														SetHighlightedHybridId(undefined);
														SetHighlightedHybridId(foundSeed.product.HybridId);
														highlighted = foundSeed.product.HybridId;
													}
												}
											}
											else
											{
												SetHighlightedHybridId(undefined);
											}
										},
									},
								]}
							data={chartData}
							options={donutOptions}
							width={'100%'}
							height={'100%'}
						/>
						<div className='DonutChart_Totals'
							style={{
								display: 'flex',
								flexDirection: 'column',
								position: 'absolute',
								top:'50%',
								left: '50%',
								textAlign: 'center',
								margin: 0,
								transform: 'translateX(-50%) translateY(-50%)',
							}}
						>
							<div className='DonutChart_Acreage' style={{ fontSize: theme.fontSizes.large, fontWeight: theme.fontWeights.bold }}>{totalAcres}</div>
							<div className='DonutChart_Unit' style={{ fontSize: theme.fontSizes.small, fontWeight: theme.fontWeights.bold }}>Acres</div>
						</div>
					</div>
				</div>
			);
		}
		else // There is legitimately no data to display in the chart
		{
			return (
				<div
					className={'ChartContainer_NoData'}
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: 25,
						paddingLeft: 20,
						paddingRight: 25,
						fontSize: theme.fontSizes.small,
						fontWeight: theme.fontWeights.bold,
						marginBottom: 10,
					}}
				>
					<span>
						<Alert style={{ marginRight: 5, position: 'relative', top: 2 }} />
						Fields do not have any applied products. Apply a product to a field with 'More Details' or click on a field.
					</span>
				</div>
			);
		}
	};

	const onSelectProductRow = (hybridId: string) =>
	{
		if (hybridId === highlighted)
		{
			SetHighlightedHybridId(undefined);
		}
		else
		{
			SetHighlightedHybridId(hybridId);
		}
	};

	return (
		<CardContainer className={'DonutPanel_CardContainer'}>
			{
				SelectedCropPlan &&
				<Tooltip title={'Update Plan'} placement='right'>
					<div
						className={'UpdateDrawer_Button'}
						style={{
							display: 'flex',
							flexDirection: 'column',
							position: 'absolute',
							borderRadius: 6,
							right: '4%',
							zIndex: 700,
						}}>
						
						<FieldListActionDrawerButton
							variant={'custom'}
							icon={<Pencil
								height={24}
								width={24}
								style={{ position: 'relative', top: 3 }}
							/>}
							menu={{items: planMenuItems, style: { color: theme.colors.darkGrey }}}
						/>
					</div>
				</Tooltip>
			}
			{
				determineView()
			}
			<div className={'DonutPanel_HybridList'} style={{ paddingLeft: 15, paddingRight: 15, overflowY: 'auto' }}>
				{
					SelectedCropPlan && productData && productData.summary.length > 0 &&
					productData.summary.map(h =>
					{
						return (
							<DonutProductRow
								key={h.product.HybridId}
								acres={h.area}
								units={h.quantity}
								color={h.color}
								hybridId={h.product.HybridId}
								hybridName={h.product.HybridName}
								selected={HighlightHybridId === h.product.HybridId}
								selectRow={onSelectProductRow}
								traitFullName={h.product.TraitFullName}
							/>
						);
					})
				}
			</div>
			{
				SelectedCropPlan &&
				<StyledMoreDetailsButtonContainer>
					<StyledMoreDetailsButton className={'DonutPanel_MoreDetailsButton'} onClick={toggleFieldInventory}>
						<span style={{ marginRight: 5 }}>More Details</span>
						<StyledRightCaretIcon style={{ position: 'relative', top: 1 }} />
					</StyledMoreDetailsButton>
				</StyledMoreDetailsButtonContainer>
			}
		</CardContainer>
	);
};

export const ConnectedDonutPanel = connector(DonutPanel);


