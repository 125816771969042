import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';

const BoldText = styled.div`
	font-weight: ${themeGet('fontWeights.bold')};
	margin-top: 20px;
`;

const UserSettingsContentComponent = (props: PropsFromRedux) =>
{
	const { UserName, UserEmail, FoundationId } = props;

	return (
		<div className='UserProfile_Container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<BoldText style={{ fontSize: 32 }}>Profile</BoldText>
			<BoldText>Email Address</BoldText>
			<span className='UserProfile_EmailAddress'>{UserEmail}</span>
			<BoldText>Name</BoldText>
			<span className='UserProfile_Name'>{UserName}</span>
			{/* shabel [31227] hiding these fields and buttons for now */}
			{/* <BoldText>Company</BoldText>
			<span className='UserProfile_Company'>{company}</span>
			<BoldText>Role</BoldText>
			<span className='UserProfile_Role'>{role}</span> */}
			<BoldText>Foundation ID</BoldText>
			<span className='UserProfile_FoundationId'>{FoundationId}</span>
			{/* <BoldText>E-Luminate Mobile Account</BoldText>
			<span className='UserProfile_MobileAccount'>{mobileAccountName}</span>
			<div style={{ marginTop: 10 }}>
				<Button className='UserProfile_SyncData' variant='outlined' style={{ margin: 10 }}>Synchronize Data</Button>
				<Button className='UserProfile_DisconnectAcct' variant='outlined' style={{ margin: 10 }}>Disconnect Account</Button>
			</div> */}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	UserEmail: state.userInfo.UserEmail,
	UserName: state.userInfo.UserName,
	FoundationId: state.userInfo.FoundationId
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const UserSettingsContent = connector(UserSettingsContentComponent);