import React from 'react';
import styled, { useTheme } from 'styled-components';
import { IFieldListingItem, IFieldPlantingPlanData } from './VRSMain';
import { ReactComponent as CornIcon } from '../Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from '../Icons/SoyIcon.svg';
import { ReactComponent as RecommendedIcon } from './Icons/RecommendedIcon.svg';
import { ColorMatchBar } from '../../../components/ColorMatchBar/ColorMatchBar';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as RightCaret } from '../Icons/RightCaret.svg';

interface IFieldSeedViewProps
{
	fieldInfo: IFieldListingItem;
	fieldInfoNumber: string;
	fieldPlantingPlanData: IFieldPlantingPlanData;
	selectedSeed: string;
	onClickSeed: (seedId: string) => void;
	onPrevious: () => void;
	onNext: () => void;
}

const FieldSeedViewContainer = styled.div`
	padding-top: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	padding-bottom: 20px;
`;

const DisplayTextContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	font-size: 11px;
	height: 50%;
	align-items: flex-start;
	padding-top: 3px;
`;

const DisplayValueText = styled.div`
	font-weight: bold;
`;

const TitleText = styled.div`
	width: 100%;
	height: 50%;
	font-size: 16px;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledSoyIcon = styled(SoyIcon)`
	path {
		stroke: ${themeGet('colors.darkGrey')};
	}
`;

const CrumbContainer = styled.div`
	align-items: center;
	background-color: #F3F4F6;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 35px;
	padding-top: 6.5px;
	padding-bottom: 6.5px;
	padding-left: 17px;
	padding-right: 25px;
	margin-bottom: 10px;
`;

export const FieldSeedView = (props: IFieldSeedViewProps) =>
{
	const {
		fieldInfo,
		fieldInfoNumber,
		fieldPlantingPlanData,
		selectedSeed,
		onClickSeed,
		onPrevious,
		onNext,
	} = props;

	const theme = useTheme();

	const determineCropIcon = () =>
	{
		if (fieldInfo && fieldInfo.CropName.toLocaleLowerCase() === 'corn')
		{
			return (
				<CornIcon width={45} height={45} style={{ marginRight: 10 }} />
			);
		}
		
		return (
			<StyledSoyIcon width={45} height={45} style={{ marginRight: 10 }} />
		);
	};

	const colorsList = ['#961D28', '#CC4938', '#E78558', '#F4B066', '#9172AF', '#6B5581', '#91C8E6', '#7AA5CA', '#557BB2', '#3B4D96'];

	const seedBars = () =>
	{
		if (fieldPlantingPlanData)
		{
			const seedNames: string[] = Object.keys(fieldPlantingPlanData.AveragePopulationBySeed);
			const seedsByRecommended: {name: string, assigned: boolean}[] = seedNames.map(sn =>
			{
				const isAssigned = fieldPlantingPlanData.RecommendedHybrids.some(rh => rh.indexOf(sn) > -1);
				return {name: sn, assigned: isAssigned};
			});
			return seedsByRecommended.sort((a,b,) => Number(b.assigned) - Number(a.assigned)).map((seed: {name: string, assigned: boolean}, index) =>
			{
				return (
					<div key={seed.name} style={{ display: 'flex', flexDirection: 'row', paddingLeft: seed.assigned ? 5 : 47 }}>
						{
							seed.assigned &&
							<div style={{ width: '14%' }}><RecommendedIcon
								width={38}
								height={38}
							/></div>
						}
						<div style={{ width: seed.assigned ? '86%' : '100%' }}>
							<ColorMatchBar
								title={seed.name}
								color={colorsList[index % colorsList.length]}
								clickable={true}
								maxValue={0} value={0}
								detailNamesAndValues={[
									['Avg Population', fieldPlantingPlanData.AveragePopulationBySeed[seed.name].toFixed(0)],
									['Avg Predictive Yield', fieldPlantingPlanData.YieldAverage.toFixed(0)]
								]}
								showDetails={selectedSeed === seed.name}
								onClick={() => onClickSeed(seed.name)}
							/>
						</div>
					</div>
				);
			});
		}
	};

	return (
		<FieldSeedViewContainer className={`FieldSeedViewContainer_${fieldInfo.FieldId}`}>
			<CrumbContainer className='MaxScript_FieldInventory_PreviousNextContainer'>
				<div style={{ cursor: 'pointer' }} onClick={onPrevious}>
					<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
					<span style={{ color: theme.colors.darkGrey }}>{'Previous'}</span>
				</div>
				<div style={{ paddingLeft: '15%' }}>{fieldInfoNumber}</div>
				<div style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={onNext}>
					<span style={{ color: theme.colors.darkGrey }}>{'Next'}</span>
					<RightCaret style={{ marginLeft: '15px', position: 'relative', top: 2 }} />
				</div>
			</CrumbContainer>
			<div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, paddingRight: 25, flex: '1 1 auto', overflow: 'hidden' }}>
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
					{determineCropIcon()}
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'hidden' }}>
						<TitleText className="TitleText">{fieldInfo.FieldName}</TitleText>
						<DisplayTextContainer className="PropertiesContainer">
							<div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
								<DisplayValueText className="FieldSeedView_FieldArea">{fieldInfo.Area.toFixed(1)} acres</DisplayValueText>
							</div>
						</DisplayTextContainer>
					</div>
				</div>
				<div className={'FieldSeedViewSeedList'} style={{ overflowY: 'auto', paddingRight: 10 }}>
					{seedBars()}
				</div>
			</div>
		</FieldSeedViewContainer>
	);
};