'use strict';
import { useMemo } from 'react';
import { ICrossReferencedGrower, SelectionState } from './DataTransferTroubleshootingTool';
import { IGrowerSummary } from './useSelectionState';

/**
 * Validate if the user can use the transfer all function
 * @param transferAll Is transferAll active
 * @param potentialPairings What growers in the two accounts match by foundation id
 * @param crossReferencePairings The two growers (source and dest) that match and if they can be transferred
 * @param source The source grower state to copy from
 * @param dest The destination grower state to copy to
 * @returns 
 */
export function useTransferAllValidation(transferAll: boolean, potentialPairings: (readonly [string, IGrowerSummary])[], crossReferencePairings: Record<string, ICrossReferencedGrower>, source: SelectionState, dest: SelectionState) 
{
	return useMemo(() => 
	{
		if (!transferAll) 
		{
			return undefined;
		}

		// For a 'transfer all'
		// There must be at least one grower in each with a shared FID
		if (!potentialPairings.length) 
		{
			return 'No growers match by FoundationId between those users';
		}

		if (!Object.values(crossReferencePairings).some(p => p.valid)) 
		{
			return 'None of the viable grower accounts have valid unique farms to transfer';
		}

		return undefined;
	}, [transferAll, potentialPairings.length, crossReferencePairings]);
}
