import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { InputSlider } from '../../../components/InputSlider/InputSlider';
import { Alert, InputNumber } from 'antd';
import { Button } from '../../../components/Button/Button';
import { Divider } from 'antd';
import { ReactComponent as Reset } from './Icons/Reset.svg';
import { IFieldPlantingPlanData } from './VRSMain';
import { checkContrast, dynamicSort } from '../../../logic/Utility/Utils';
import { ScaleLoader } from 'react-spinners';
import { CoveringLoader } from '../../../components/LoadingSpinner/CoveringLoader';

const YieldTableViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	min-width: 382px;
	max-width: 382px;
	background-color: ${themeGet('colors.white')};
`;

const YieldTableInnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-Y: auto;
	padding-bottom: 10px;
	width: 100%;
`;

const TitleContainer = styled.div`
	align-items: center;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 46px;
	padding-left: 25px;
	padding-right: 25px;
`;

const SliderContainer = styled.div`
	align-self: center;
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-top: 10px;
	margin-bottom: 20px;
`;

const AverageContainer = styled.div`
	padding-right: 20px;
	padding-left: 20px;
	display: flex;
	flex-direction: column;
`;

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;

const InputContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin-top: 20px;
`;

const RangeInputRow = styled.div`
	display: flex;
	align-items: center;
`;

const RangeInputLabel = styled.div`
	font-weight: bold;
	margin-right: 15px;
`;

const RangeInputNumber = styled(InputNumber)`
	width: 100px;
	height: 30px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	border-radius: 4px;
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: center;
	}
`;

interface IYieldTableViewProps
{
	selectedSeed: string;
	selectedFieldPlanData: IFieldPlantingPlanData;
	disableSaveButton: boolean;
	isLoading: boolean;
	error: string | undefined;
	editTargetYield: (value: number) => void;
	editSeedingRate: (value: number, zoneName: number, seedName: string) => void;
	resetYieldTarget: (seedName: string) => void;
	onChangeSlider: (sliderValue: number, seedName: string) => void;
	onCancel: () => void;
	onSave: () => void;
	updateMinimumRate: (value: number, seedName: string) => void;
	updateMaximumRate: (value: number, seedName: string) => void;
}

export const YieldTableView = (props: IYieldTableViewProps) =>
{
	const {
		selectedFieldPlanData,
		selectedSeed,
		disableSaveButton,
		isLoading,
		error,
		editTargetYield,
		editSeedingRate,
		onCancel,
		onSave,
		onChangeSlider,
		resetYieldTarget,
		updateMaximumRate,
		updateMinimumRate,
	} = props;

	const theme = useTheme();

	const changeSlider = (input: number) =>
	{
		onChangeSlider(input, selectedSeed);
	};

	const onChangeZoneSeedingRate = (value: number, zoneName: number) =>
	{
		editSeedingRate(value, zoneName, selectedSeed);
	};

	const averagePopulation = (): number =>
	{
		const seedingRate = selectedFieldPlanData.AveragePopulationBySeed[selectedSeed];
		return seedingRate;
	};

	const averagePredictiveYield = (): number =>
	{
		const predictiveYield = selectedFieldPlanData.YieldAverage;
		return predictiveYield;
	};

	const onReset = () =>
	{
		resetYieldTarget(selectedSeed);
	};

	return (
		selectedFieldPlanData && selectedSeed ? 
			<YieldTableViewContainer className='VRS_YieldTableContainer' style={{ display: selectedSeed ? 'flex' : 'none', position: 'relative' }}>
				<YieldTableInnerContainer>
					
					<TitleContainer>Planting Rate</TitleContainer>
					<InputContainer>
						<RangeInputRow>
							<RangeInputLabel>Min</RangeInputLabel>
							<RangeInputNumber
								className='Slider_Input_Min'
								type='number'
								value={selectedFieldPlanData.HybridMinMaxSeedingRates[selectedSeed].MinimumSeedingRate}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
									updateMinimumRate(evt.currentTarget.value ?
										Number(evt.currentTarget.value)
										: selectedFieldPlanData.HybridMinMaxSeedingRates[selectedSeed].MinimumSeedingRate, selectedSeed)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
									updateMinimumRate(evt.target && (evt.target as HTMLInputElement).value ?
										Number((evt.target as HTMLInputElement).value)
										: selectedFieldPlanData.HybridMinMaxSeedingRates[selectedSeed].MinimumSeedingRate, selectedSeed)}
							/>
						</RangeInputRow>
						<RangeInputRow>
							<RangeInputLabel>Max</RangeInputLabel>
							<RangeInputNumber
								className='Slider_Input_Max'
								type='number'
								value={selectedFieldPlanData.HybridMinMaxSeedingRates[selectedSeed].MaximumSeedingRate}
								onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
									updateMaximumRate(e.currentTarget.value ?
										Number(e.currentTarget.value)
										: selectedFieldPlanData.HybridMinMaxSeedingRates[selectedSeed].MaximumSeedingRate, selectedSeed)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
									updateMaximumRate(evt.target && (evt.target as HTMLInputElement).value ?
										Number((evt.target as HTMLInputElement).value)
										: selectedFieldPlanData.HybridMinMaxSeedingRates[selectedSeed].MaximumSeedingRate, selectedSeed)}
							/>
						</RangeInputRow>
					</InputContainer>
					<SliderContainer className={'Slider_Container'}>
						<InputSlider
							displayTooltip={!!selectedSeed}
							onChange={changeSlider}
							sliderValue={selectedFieldPlanData.SliderValueBySeed[selectedSeed]}
							min={-20}
							max={20}
						/>
					</SliderContainer>
					<AverageContainer>
						<div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
							<div style={{ width: '70%' }}>Avg Population:</div>
							<div style={{ fontWeight: 700, paddingLeft: 60, textAlign: 'right' }}>{averagePopulation().toFixed(0)}</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
							<div style={{ width: '70%' }}>Avg Predictive Yield:</div>
							<div style={{ fontWeight: 700, paddingLeft: 60, textAlign: 'right' }}>{averagePredictiveYield().toFixed(0)}</div>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
							<div style={{ width: '70%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Target Yield:</div>
							<div style={{ fontWeight: 700, paddingLeft: 60, textAlign: 'right' }}>
								<NumberInput
									style={{ width: 60 }}
									type='number'
									className='Target_Yield_Input'
									value={selectedFieldPlanData.EditedTargetYield || selectedFieldPlanData.EditedTargetYield === 0 ?
										Math.round(selectedFieldPlanData.EditedTargetYield) : Math.round(selectedFieldPlanData.TargetYield)}
									min={0}
									onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
										editTargetYield(evt.currentTarget.value ? Number(evt.currentTarget.value)
											: selectedFieldPlanData.EditedTargetYield ? Math.round(selectedFieldPlanData.EditedTargetYield)
												: Math.round(selectedFieldPlanData.TargetYield))}
									onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
										editTargetYield(evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
											: selectedFieldPlanData.EditedTargetYield ? Math.round(selectedFieldPlanData.EditedTargetYield)
												: Math.round(selectedFieldPlanData.TargetYield))}
								/>
							</div>
						</div>
					</AverageContainer>
					<div style={{ paddingLeft: 20, paddingRight: 20 }} >
						<Divider />
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20, height: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 20 }}>
							<div style={{ fontWeight: 700 }}>Zones by type of soil</div>
							<div style={{ marginLeft: 'auto' }}>
								<Button style={{ height: 'auto' }} variant='outlined' onClick={onReset} disabled={disableSaveButton}>
									<Reset style={{ position: 'relative', top: 1, paddingRight: 2, opacity: disableSaveButton ? 0.6 : 1.0 }} />
									Reset Target Yield
								</Button>
							</div>
						</div>
						{
							error && <Alert type='error' message={error} style={{marginBottom: 10}} />
						}
						<div
							style={{
								backgroundColor: 'black',
								color: 'white',
								fontWeight: theme.fontWeights.bold,
								display: 'flex',
								flexDirection: 'row',
								fontSize: 12,
								borderRadius: 4,
								paddingLeft: 10,
								paddingRight: 10,
								paddingTop: 5,
								paddingBottom: 5,
								marginBottom: 5
							}}>
							<div style={{ width: 55, textAlign: 'center' }}>Soil</div>
							<div style={{ width: 40, textAlign: 'center' }}>Acres</div>
							<div style={{ width: 70, textAlign: 'center' }}>Pred Yield</div>						
							<div style={{ width: 80, textAlign: 'center' }}>Target Yield</div>
							<div style={{ width: 80, textAlign: 'center' }}>Planting Rate</div>
						</div>
						<div style={{ height: '100%', overflowY: 'auto' }}>
							{
								selectedFieldPlanData.ZoneData.sort(dynamicSort('Acres', 'descending')).map(zone =>
									<div
										key={zone.ZoneName}
										style={{
											display: 'flex',
											flexDirection: 'row',
											fontSize: 12,
											paddingLeft: 10,
											paddingRight: 10,
											paddingBottom: 10,
											height: 40,
											alignItems: 'center'
										}}
									>
										<div 
											style={{
												width: 55,
												backgroundColor: zone.Color,
												color: checkContrast(zone.Color),
												borderRadius: 4,
												textAlign: 'center',
												height: 25,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												fontWeight: theme.fontWeights.bold
											}}
										>
											{`Zone ${zone.ZoneName}`}
										</div>										
										<div style={{ width: 40, textAlign: 'center' }}>
											{zone.Acres.toFixed(1)}
										</div>
										<div style={{ width: 70, textAlign: 'center' }}>
											{zone.TargetYield.toFixed(0)}
										</div>
										<div style={{ width: 80, textAlign: 'center' }}>
											{Math.round(zone.TargetYield * selectedFieldPlanData.FieldYieldModifier)}
										</div>
										<div style={{ width: 80, textAlign: 'center' }}>
											<NumberInput
												style={{ fontSize: 12, width: 70, height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 'auto' }}
												className={`PlantingRate_Input_${zone.ZoneName}`}
												type='number'
												value={zone.SeedRates[selectedSeed].DisplayedSeedRate ?
													zone.SeedRates[selectedSeed].DisplayedSeedRate : zone.SeedRates[selectedSeed].CurrentSeedRate}
												onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
													onChangeZoneSeedingRate(evt.currentTarget.value ? Number(evt.currentTarget.value)
														: zone.SeedRates[selectedSeed].DisplayedSeedRate ? zone.SeedRates[selectedSeed].DisplayedSeedRate
															: zone.SeedRates[selectedSeed].CurrentSeedRate, zone.ZoneName)}
												onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
													onChangeZoneSeedingRate(evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
														: zone.SeedRates[selectedSeed].DisplayedSeedRate ? zone.SeedRates[selectedSeed].DisplayedSeedRate
															: zone.SeedRates[selectedSeed].CurrentSeedRate, zone.ZoneName)}
											/>
										</div>
									</div>
								)
							}
						</div>
					</div>
				</YieldTableInnerContainer>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginLeft: 'auto',
						marginTop: 'auto',
						paddingRight: 28,
						paddingTop: 10,
						paddingBottom: 10
					}}
				>
					<Button style={{ width: 75, marginRight: 15 }} variant='light' onClick={onCancel}>Cancel</Button>
					<Button
						style={{ width: 75 }}
						variant='reversed'
						onClick={onSave}
						disabled={disableSaveButton}
					>
						Save
					</Button>
				</div>
				<CoveringLoader className={isLoading ? 'loading' : ''}>
					<ScaleLoader color={theme.colors.primary} loading={true} />
				</CoveringLoader>
				
			</YieldTableViewContainer>
			:
			<React.Fragment />
	);
};

