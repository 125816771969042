import React, { useEffect, useState } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { Input, InputNumber } from 'antd';
import { ICustomTreatmentResponse } from '../../../logic/Models/Responses/CustomTreatmentResponse';
import { ReactComponent as DeleteIcon } from '../../../assets/images/Trashcan.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/PlusIcon.svg';
import { ReactComponent as PencilIcon } from '../../../assets/images/Pencil.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/Checkmark.svg';
import { IconButton } from '../../../components/IconButton/IconButton';
import moment from 'moment';

const InputTextField = styled(Input)`
	height: 25px;
	width: 90%;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: 10px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
		box-shadow: 0 0 1pt 1pt white;
		outline: none;
	}
	::selection {
		border-color: black;
		background-color: ${themeGet('colors.primary')};
	}
`;

const NumberInput = styled(InputNumber)`
	width: 60px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border-radius: 4px;
	border: thin solid ${themeGet('colors.lightGrey')};
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: left;
		height: auto;
		padding-right: 3px;
	}
`;

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
	path {
		fill: ${themeGet('colors.darkGrey')};
	}
`;

export interface ICustomTreatmentRowProps
{
	treatment: ICustomTreatmentResponse;
	index: number;
	onDelete: (index: number) => void;
	onNameChange: (index: number, value: string) => void;
	onPriceChange: (index: number, value: number) => void;
	onNotesChange: (index: number, value: string) => void;
}

export const CustomTreatmentRow = (props: ICustomTreatmentRowProps) =>
{
	const { index, treatment, onDelete, onNameChange, onPriceChange, onNotesChange } = props;

	const theme = useTheme();

	const [editable, setEditable] = useState<boolean>(false);

	const formattedDate = treatment?.Created ? moment.parseZone(treatment?.Created).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');

	useEffect(() =>
	{
		if (treatment && treatment?.IsNew)
		{
			setEditable(true);
		}
	},[]);

	// Check to see if the user has entered both a name and a price and has no errors before allowing them to end the editable state
	const validateEditableState = () =>
	{
		let stateIsValid = true;
		if (treatment?.ErrorMessage)
		{
			stateIsValid = false;
		}

		if (!treatment?.Name || treatment?.Name === '')
		{
			stateIsValid = false;
		}

		if (!treatment.PricePerBag || treatment?.PricePerBag === 0)
		{
			stateIsValid = false;
		}

		return stateIsValid;
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				fontSize: 12,
				paddingLeft: 10,
				paddingRight: 10,
				paddingTop: 5,
				paddingBottom: 5,
				alignItems: 'center',
				borderWidth: 1,
				borderColor: theme.colors.lightestGrey,
				borderBottomStyle: 'solid',
				borderLeftStyle: 'solid',
				borderRightStyle: 'solid',
			}}
		>
			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				{
					editable ?
						<div
							className={`CustomTreatmentRow_Name_${index}`}
							style={{ width: '30%', textAlign: 'left', fontWeight: theme.fontWeights.bold }}
						>
							<InputTextField
								className={`CustomTreatmentRow_Name_Editable_${index}`}
								status={treatment?.Name?.length && !treatment?.ErrorMessage ? '' : 'error'}
								value={treatment?.Name}
								type={'text'}
								maxLength={50}
								onChange={(evt: React.FocusEvent<HTMLInputElement>) =>
									onNameChange(index, evt.currentTarget.value)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
									onNameChange(index, evt.target && (evt.target as HTMLInputElement).value)}
							/>
							{
								treatment?.ErrorMessage &&
								<div style={{ fontWeight: theme.fontWeights.bold, color: theme.colors.red70 }}>{treatment?.ErrorMessage}</div>
							}
						</div>
						:
						<div
							className={`CustomTreatmentRow_Name_${index}`}
							style={{ width: '30%', textAlign: 'left', fontWeight: theme.fontWeights.bold }}
						>
							{treatment?.Name}
						</div>
				}
				{
					editable ?
						<div
							className={`CustomTreatmentRow_PricePerBag_${index}`}
							style={{ width: '15%', textAlign: 'left', fontWeight: theme.fontWeights.bold }}
						>
							<NumberInput
								style={{ fontSize: 12, width: '90%', height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
								className={`CustomTreatmentRow_PricePerBag_Editable_${index}`}
								status={treatment?.PricePerBag ? '' : 'error'}
								type='number'
								value={treatment?.PricePerBag}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
									onPriceChange(index, evt.currentTarget.value ? Number(evt.currentTarget.value) : treatment?.PricePerBag)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
									onPriceChange(index, evt.target && (evt.target as HTMLInputElement).value ? Number((evt.target as HTMLInputElement).value)
										: treatment?.PricePerBag)}
							/>
						</div>
						:
						<div
							className={`CustomTreatmentRow_PricePerBag_${index}`}
							style={{ width: '15%', textAlign: 'left', fontWeight: theme.fontWeights.bold }}
						>
							{treatment?.PricePerBag 
								? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(treatment?.PricePerBag)
								: '$0.00'}
						</div>
				}
				<div 
					className={`CustomTreatmentRow_Created_${index}`}
					style={{ width: '40%', textAlign: 'left', fontWeight: theme.fontWeights.bold }}
				>
					{formattedDate}
				</div>
				<div
					className={`CustomTreatmentRow_ButtonSection_${index}`}
					style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}
				>
					{
						editable ?
							<StyledIconButton className='doneEdit-treatment-button'
								onClick={() => setEditable(false)}
								tooltip={validateEditableState() ? 'Done editing' : 'Please complete all red fields.'}
								showDisabledTooltip={true} placement='bottom' hoverbgcolor={theme.colors.lightestGrey}
								disabled={!validateEditableState()}
								fillcolor={validateEditableState() ? theme.colors.darkGrey : theme.colors.lightGrey}
							>
								<CheckmarkIcon />
							</StyledIconButton>
							:
							<StyledIconButton className='edit-treatment-button'
								onClick={() => setEditable(true)}
								tooltip='Edit treatment'
								showDisabledTooltip={true} placement='bottom' hoverbgcolor={theme.colors.lightestGrey}>
								<PencilIcon />
							</StyledIconButton>
					}
					{
						!treatment?.IsDeleted ?
							<StyledIconButton className='delete-treatment-button'
								onClick={() => onDelete(index)}
								tooltip='Delete treatment'
								showDisabledTooltip={true} placement='bottom' hoverbgcolor={theme.colors.lightestGrey}>
								<DeleteIcon />
							</StyledIconButton> :
							<StyledIconButton className='delete-treatment-button'
								onClick={() => onDelete(index)}
								tooltip='Undo delete'
								showDisabledTooltip={true} placement='bottom' hoverbgcolor={theme.colors.lightestGrey}>
								<PlusIcon />
							</StyledIconButton>
					}
				</div>
			</div>
			{
				editable ?
					<div
						className={`CustomTreatmentRow_Notes_${index}`}
						style={{ width: '100%', textAlign: 'left', fontWeight: theme.fontWeights.bold, marginTop: 5 }}
					>
						<InputTextField.TextArea
							className={`CustomTreatmentRow_Notes_Editable_${index}`}
							value={treatment?.Notes}
							placeholder={'Notes'}
							rows={2}
							maxLength={50}
							onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) =>
								onNotesChange(index, evt.currentTarget.value)}
						/>
					</div>
					:
					<div
						className={`CustomTreatmentRow_Notes_${index}`}
						style={{ width: '100%', textAlign: 'left', marginTop: 5 }}
					>
						{treatment?.Notes}
					</div>
			}
		</div>
	);
};