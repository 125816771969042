import React from 'react';
import { Modal, ModalProps } from 'antd';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import styled from 'styled-components';

interface IStyledModalProps extends ModalProps {
	height?: string | number;
	width?: string | number;
	children?: React.ReactNode;
	isLoading?: boolean;
	loadingText?: string;
	flexBody?: boolean;
}

const EditedModal = styled(Modal)<IStyledModalProps>`
	.ant-modal-header {
		border-bottom: none;
	}
	.ant-modal-body {
		display: ${props => props.flexBody ? 'flex' : 'block'};
	}
`;

/**
 * @component Styled Modal
 */
export const StyledModal = (props: IStyledModalProps) =>
{
	return (
		<EditedModal
			{...props}
			footer={null}
			width={props.width}
			bodyStyle={{...props.bodyStyle, height: props.height}}
			centered
		>
			<div
				id='Modal-Loading-Spinner'
				style={{
					display: props.isLoading ? 'flex' : 'none',
					position: 'absolute',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
					width: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					zIndex: 999,
					top: 0,
					left: 0
				}}
			>
				<LoadingSpinner loading={props.isLoading}>{props.loadingText}...</LoadingSpinner>
			</div>
			{props.children}
		</EditedModal>
	);
};