import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import { DropdownTab } from '../../../components/DropdownTab/DropdownTab';
import { ThumbnailCheckbox } from '../../../components/ThumbnailCheckbox/ThumbnailCheckbox';
import { StyledCollapse, StyledPanel } from '../../../components/StyledCollapse/StyledCollapse';
import { Badge } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { getUserSeedAttributes, saveUserSeedAttributes } from '../../../logic/store/User/UserSeedAttributesSlice';
import { IUserSeedAttributeResponse } from '../../../logic/Models/Responses/UserSeedAttributeResponse';
import { getAttributes } from '../../../logic/store/Seeds/AttributesSlice';
import _ from 'lodash';
import { useTheme } from 'styled-components';

enum TabNames
{
	Corn = 'Corn',
	Soybean = 'Soybean',
	Enogen = 'Enogen'
}

interface IAttributesByCropAndCategory
{
	CropName: string;
	CropId: string;
	Categories: IAttributeByCategory[];
}

interface IAttributeByCategory
{
	Category: string;
	Attributes: IAttribute[];
}

interface IAttribute
{
	Id: string;
	Ordinal: number;
	AttributeName: string;
	ImgSrc: string;
}

const UserAttributesComponent = (props: PropsFromRedux) =>
{
	const {
		getAllAttributes,
		getUserSeedAttributes,
		saveUserSeedAttributes,
		UserSelectedAttributes,
		AllSeedAttributes,
		IsAllLoading,
		IsUserAttributesLoading
	} = props;

	const theme = useTheme();

	const [selectedTab, setSelectedTab] = useState(TabNames.Corn);
	const [attributesByCropAndCategory, setAttributesByCropAndCategory] = useState<IAttributesByCropAndCategory[]>([]);
	const [numProductsSelected, setNumProductsSelected] = useState<{ [crop: string]: number }>({ 'Corn': 0, 'Soybean': 0, 'Enogen': 0 });
	const [checkboxesChecked, setCheckboxesChecked] = useState<{ [key: string]: boolean }>({});
	const [initialCheckboxesChecked, setInitialCheckboxesChecked] = useState<{ [key: string]: boolean }>({});

	const maxNumProducts = 24;

	// Capture first letters of words
	// https://regexr.com/ for explanation
	const convertCategoryCaps = (category: string): string =>
	{
		const expression = new RegExp(/(\b)[a-z](?!s)/g);
		return category.replace(expression, char => char.toLocaleUpperCase());
	};

	// Load All Attributes once on the load of the component
	useEffect(() =>
	{
		getAllAttributes();

		if (!IsAllLoading)
		{
			getUserSeedAttributes();
		}
	}, []);

	// After All Attributes load, get the User Seed Attributes
	useEffect(() =>
	{
		if (!IsAllLoading)
		{
			getUserSeedAttributes();
		}
	}, [IsAllLoading]);

	// After all the other loading, do the logic!
	useEffect(() =>
	{
		if (!IsUserAttributesLoading)
		{
			// Track the values getting updated because current state will not update fast enough to track.
			// Values are set into state at the end of this function
			let trackChecked: { [key: string]: boolean } = {};
			let trackCount: { [crop: string]: number } = { 'Corn': 0, 'Soybean': 0, 'Enogen': 0 };
			const attrByCropAndCategory = AllSeedAttributes.map(cropAttr =>
			{
				const cropName = cropAttr.CropName;
				const cropId = cropAttr.CropId;
				const userSelectedAttr = UserSelectedAttributes?.find(usa => usa.CropId.toLowerCase() === cropId.toLowerCase());
				const categories: IAttributeByCategory[] = [];

				if (userSelectedAttr)
				{
					const brandSelections = userSelectedAttr.BrandApplications;

					brandSelections.map(bs =>
					{
						const cropTabName = bs.BrandApplication === TabNames.Enogen.toString() ? TabNames.Enogen.toString() : cropName;
						bs.SeedAttributeIds.map(sa =>
						{
							// If the attribute ID doesn't exist in the main attributes list, ignore it
							if (cropAttr.Attributes.some(a => a.Id === sa))
							{
								const checkboxKey = `${sa}-${cropTabName}`;
								const currentSelected = trackCount[cropTabName];
								trackChecked = { ...trackChecked, [checkboxKey]: true };
								trackCount = { ...trackCount, [cropTabName]: currentSelected + 1 };
							}
						});
					});
				}

				cropAttr.Attributes.map(attr =>
				{
					if (!(attr.Name.indexOf('Canada') > 0))
					{
						const foundCategory = categories.find(c => c.Category.toLocaleLowerCase() === attr.Category.toLocaleLowerCase());
						if (foundCategory)
						{
							foundCategory.Attributes.push({ Id: attr.Id, AttributeName: attr.Name, Ordinal: attr.Ordinal, ImgSrc: '/AttributeIcons/' + attr.Image });
						}
						else
						{
							categories.push({
								Category: convertCategoryCaps(attr.Category),
								Attributes: [{ Id: attr.Id, AttributeName: attr.Name, Ordinal: attr.Ordinal, ImgSrc: '/AttributeIcons/' + attr.Image }]
							});
						}
					}
				});

				return { CropName: cropName, CropId: cropId, Categories: categories };
			});

			// Set the tracked dictionaries into state
			setInitialCheckboxesChecked(trackChecked);
			setCheckboxesChecked(trackChecked);
			setNumProductsSelected(trackCount);

			if (attributesByCropAndCategory.length > 0)
			{
				const cornAttrByCategory = attrByCropAndCategory.find(acc => acc.CropName.toLocaleLowerCase() === 'Corn'.toLocaleLowerCase());
				// Add Enogen as a copy of Corn
				attrByCropAndCategory.push({ CropName: TabNames.Enogen.toString(), CropId: cornAttrByCategory.CropId, Categories: cornAttrByCategory.Categories });
			}

			setAttributesByCropAndCategory(attrByCropAndCategory);
		}
	}, [IsUserAttributesLoading]);

	const handleCheckbox = (e: CheckboxChangeEvent) =>
	{
		const currentSelected = numProductsSelected[selectedTab];
		const tabName = TabNames[selectedTab].toString();

		if (e.target.checked)
		{
			setNumProductsSelected(prev => ({ ...prev, [tabName]: currentSelected + 1 }));
		}
		else
		{
			setNumProductsSelected(prev => ({ ...prev, [tabName]: currentSelected - 1 }));
		}
	};

	const productLimitReached = numProductsSelected[selectedTab] >= maxNumProducts;

	const onReset = () =>
	{
		// Reset the state and call the inital get function again
		setNumProductsSelected({ 'Corn': 0, 'Soybean': 0, 'Enogen': 0 });
		setCheckboxesChecked({});
		getAllAttributes();
	};

	const onSave = () =>
	{
		// Build the request object - Response and Request are the same object
		const userSeedAttributeRequest: IUserSeedAttributeResponse[] = [];

		Object.entries(checkboxesChecked).forEach(([key, value]) =>
		{
			if (value)
			{
				const splitKey = key.split('-'); // Will split into 0 to 5 array members as first item is a Guid, sixth item is the CropName
				const seedAttributeId = splitKey.slice(0, 5).join('-'); // Put the Guid back together
				const cropName = splitKey[5] === TabNames.Enogen.toString() ? TabNames.Corn.toString() : splitKey[5];
				const brandApplication = splitKey[5] === TabNames.Enogen.toString() ? splitKey[5] : 'Any';
				const cropId = AllSeedAttributes.find(asa => asa.CropName.toLocaleLowerCase() === cropName.toLocaleLowerCase()).CropId;
				const foundCropResponse = userSeedAttributeRequest.find(usar => usar.CropId === cropId);

				if (foundCropResponse)
				{
					// Find Brand Application
					const foundBrandApplication = foundCropResponse.BrandApplications.find(ba => ba.BrandApplication === brandApplication);
					if (foundBrandApplication)
					{
						// Add the attribute Id string
						foundBrandApplication.SeedAttributeIds.push(seedAttributeId);
					}
					else
					{
						// Add the Brand Application
						foundCropResponse.BrandApplications.push({ BrandApplication: brandApplication, SeedAttributeIds: [seedAttributeId] });
					}
				}
				else
				{
					// Add the Crop Id and the Brand Application
					userSeedAttributeRequest.push({
						CropId: cropId,
						BrandApplications: [{
							BrandApplication: brandApplication,
							SeedAttributeIds: [seedAttributeId]
						}]
					});
				}
			}
		});
		saveUserSeedAttributes(userSeedAttributeRequest);
	};

	// Contents of each seed panel
	const displayCollapse = useCallback((cropName: string) =>
	{
		return (
			<StyledCollapse>
				{
					attributesByCropAndCategory.length > 0 &&
					attributesByCropAndCategory.find(acc => acc.CropName === (cropName === TabNames.Enogen ? TabNames.Corn : cropName))
						.Categories.sort((a, b) => a.Category > b.Category ? 1 : -1).map((category: IAttributeByCategory) =>
						{
							const panelKey = `${category.Category}-${cropName}`;
							return (
								<StyledPanel
									className={`${cropName}_AttributePanel_${category.Category}`}
									header={category.Category}
									key={panelKey}
								>
									<div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start' }}>
										{
											category.Attributes.sort((a, b) => a.Ordinal > b.Ordinal ? 1 : -1).map((attr: IAttribute) =>
											{
												const checkboxKey = `${attr.Id}-${cropName}`;
												return (
													<ThumbnailCheckbox
														className={`${cropName}_Attribute_${attr.AttributeName}`}
														key={checkboxKey}
														checkboxId={checkboxKey}
														checked={checkboxesChecked[checkboxKey]}
														disabled={productLimitReached && !checkboxesChecked[checkboxKey]}
														label={attr.AttributeName}
														thumbnail={attr.ImgSrc}
														containerStyle={{ margin: 5 }}
														onChange={(e) =>
														{
															setCheckboxesChecked(prev => ({ ...prev, [checkboxKey]: e.target.checked }));
															handleCheckbox(e);
														}}
													/>
												);
											})
										}
									</div>
								</StyledPanel>
							);
						})
				}
			</StyledCollapse>
		);
	}, [attributesByCropAndCategory, selectedTab, checkboxesChecked, onSave]);

	// Compare the original all-true checkbox object against only the true k/vs in the current one 
	const isChanges = !_.isEqual(initialCheckboxesChecked, Object.keys(checkboxesChecked).filter((key) => checkboxesChecked[key]).reduce((obj, key) => ({
		...obj,
		[key]: checkboxesChecked[key]
	}), {}));
	return (
		<div className='Container' style={{ marginTop: 20, paddingBottom: 20, height: '100%' }}>
			<div style={{ display: 'flex', flex: 1 }}>
				<div style={{ fontSize: `${theme.fontSizes.xLarge}`, fontWeight: theme.fontWeights.bold, flex: .75 }}>
					Product Attributes
				</div>
				<div style={{ display: 'flex', flex: .25, justifyContent: 'flex-end' }}>
					<Button
						className='Reset_Button'
						variant='outlined'
						style={{ paddingLeft: 15, paddingRight: 15 }}
						onClick={onReset}
						disabled={!isChanges}
					>Reset</Button>
					<Button
						className='Save_Button'
						variant='outlined'
						style={{ marginLeft: 10, paddingLeft: 15, paddingRight: 15 }}
						onClick={onSave}
						disabled={!isChanges}
					>Save</Button>
				</div>
			</div>
			<div className='TabContainer' style={{ display: 'flex', flexDirection: 'row', borderBottom: `1px solid ${theme.colors.darkGrey}` }}>
				<DropdownTab
					buttonClassName={`UserAttributes_TabButton_${TabNames.Corn}`}
					selected={selectedTab === TabNames.Corn}
					containerStyle={{ width: '20vw', marginRight: 10 }}
					label={TabNames.Corn}
					onClick={() => setSelectedTab(TabNames.Corn)} />
				<DropdownTab
					buttonClassName={`UserAttributes_TabButton_${TabNames.Soybean}`}
					selected={selectedTab === TabNames.Soybean}
					containerStyle={{ width: '20vw', marginRight: 10 }}
					label={TabNames.Soybean}
					onClick={() => setSelectedTab(TabNames.Soybean)} />
				<DropdownTab
					buttonClassName={`UserAttributes_TabButton_${TabNames.Enogen}`}
					selected={selectedTab === TabNames.Enogen}
					containerStyle={{ width: '20vw' }}
					label={TabNames.Enogen}
					onClick={() => setSelectedTab(TabNames.Enogen)} />
			</div>
			<div
				className={`TabContent_${TabNames.Corn}`}
				style={{
					display: selectedTab === TabNames.Corn ? 'flex' : 'none',
					flexDirection: 'column',
					marginTop: 20,
					overflow: 'hidden',
					height: '85%',
					paddingBottom: 20
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 20 }}>
					<Badge
						className={`Badge_${TabNames.Corn}`}
						count={`${numProductsSelected[selectedTab]}/${maxNumProducts} Attributes`}
						style={{
							backgroundColor: !productLimitReached ? theme.colors.darkGrey : theme.colors.primary,
							fontSize: theme.fontSizes.small,
							transition: 'background-color 0.3s'
						}}
					/>
					{productLimitReached && (
						<div style={{ position: 'absolute', right: '10%', color: theme.colors.primary, fontWeight: theme.fontWeights.bold }}>
							Please remove an attribute for this crop to add another
						</div>
					)}
				</div>
				{displayCollapse(TabNames.Corn)}
			</div>
			<div
				className={`TabContent_${TabNames.Soybean}`}
				style={{
					display: selectedTab === TabNames.Soybean ? 'flex' : 'none',
					flexDirection: 'column',
					marginTop: 20,
					overflow: 'hidden',
					height: '85%',
					paddingBottom: 20
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
					<Badge
						className={`Badge_${TabNames.Soybean}`}
						count={`${numProductsSelected[selectedTab]}/${maxNumProducts} Attributes`}
						style={{
							backgroundColor: !productLimitReached ? theme.colors.darkGrey : theme.colors.primary,
							fontSize: theme.fontSizes.small,
							transition: 'background-color 0.3s'
						}}
					/>
					{productLimitReached && (
						<div style={{ position: 'absolute', right: '10%', color: theme.colors.primary, fontWeight: theme.fontWeights.bold }}>
							Please remove an attribute for this crop to add another
						</div>
					)}
				</div>
				{displayCollapse(TabNames.Soybean)}
			</div>
			<div
				className={`TabContent_${TabNames.Enogen}`}
				style={{
					display: selectedTab === TabNames.Enogen ? 'flex' : 'none',
					flexDirection: 'column',
					marginTop: 20,
					overflow: 'hidden',
					height: '85%',
					paddingBottom: 20
				}}
			>
				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
					<Badge
						className={`Badge_${TabNames.Enogen}`}
						count={`${numProductsSelected[selectedTab]}/${maxNumProducts} Attributes`}
						style={{
							backgroundColor: !productLimitReached ? theme.colors.darkGrey : theme.colors.primary,
							fontSize: theme.fontSizes.small,
							transition: 'background-color 0.3s'
						}}
					/>
					{productLimitReached && (
						<div style={{ position: 'absolute', right: '10%', color: theme.colors.primary, fontWeight: theme.fontWeights.bold }}>
							Please remove an attribute for this crop to add another
						</div>
					)}
				</div>
				{displayCollapse(TabNames.Enogen)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	AllSeedAttributes: state.seedAttributes.Attributes,
	IsAllLoading: state.seedAttributes.isLoading,
	UserSelectedAttributes: state.userAttributes.UserSeedAttributes,
	IsUserAttributesLoading: state.userAttributes.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		getUserSeedAttributes: () => dispatch(getUserSeedAttributes()),
		saveUserSeedAttributes: (request: IUserSeedAttributeResponse[]) => dispatch(saveUserSeedAttributes(request)),
		getAllAttributes: () => dispatch(getAttributes())
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const UserAttributes = connector(UserAttributesComponent);