import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { getCurrentActingUser } from '../User/AuthSlice';
import { sleepOnIt } from '../../Utility/Utils';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import moment from 'moment';

export interface IPpgRequest
{
	HybridIds: string[];
}

export const downloadPpgPdf = createTracedAsyncThunk<Blob, IPpgRequest, { dispatch: AppDispatch, state: RootState }>(
	'ppg/download',
	async (context, request: IPpgRequest, thunkAPI) =>
	{
		try
		{
			const { auth } = thunkAPI.getState();
			const api = new Api('/api/6', auth.userAuthToken, context);

			const pdfSession = await api.postAsync<string>('pdf/ppg/async', request ?? {});

			let pdfData = undefined;
			do 
			{
				// wait half a second for each look
				await sleepOnIt(500);
				pdfData = await api.getFileAsync(`pdf/ppg/${pdfSession.Data}`);
			} while(!pdfData);

			const { name, data } = pdfData;

			if (!data.size)
			{
				return thunkAPI.rejectWithValue('There was an error downloading the PPG PDF data.');
			}

			if (window.navigator.userAgent.toLowerCase().indexOf('crios') > -1)
			{//iOS Chrome
				const typedBlob = new Blob([data], {type: 'application/pdf'});
				const link = (window.URL || window['webkitURL']).createObjectURL(typedBlob);
				window.open(link);
			}
			else
			{
				// Store this file as a hidden 'a' element and then automatically click it.
				const link = document.createElement('a');
				link.href = (window.URL || window['webkitURL']).createObjectURL(data);

				// The renderer always adds ".pdf" to the filename. If there's more than that, use it. Otherwise fall back on the session name.
				link.download = name && name.slice(0, name.indexOf('.pdf'))
					? name : `ProductGuide_${moment().format('MM/DD/YYYY')}`;

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);
