import React, { useEffect, useState } from 'react';
import { ICheckboxSection } from '../../components/CheckboxExpander/CheckboxExpander';
import { Button } from '../../components/Button/Button';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { EditableInputDisplay } from '../../components/EditableInputDisplay/EditableInputDisplay';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { makeDispatch } from '../../logic/Utility/Utils';
import { getFarms, renameFarm } from '../../logic/store/Grower/FarmThunks';
import { renameField } from '../../logic/store/Grower/FieldThunks';

const EditableNamesContainer = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

export interface IEditableName
{
	EditedName?: string;
	Error: boolean;
	ErrorMessage?: string;
	Id: string;
	OriginalName: string;
	SavedSuccessfully: boolean;
}

export interface IEditableTree
{
	Parent: IEditableName;
	Children: IEditableName[];
}

interface IEditNamesModalProps extends PropsFromRedux
{
	farmFieldTree: ICheckboxSection[];
	visible: boolean;
	onCancel: () => void;
	onSave: () => void;
}

const EditNamesModalBase = (props: IEditNamesModalProps) =>
{
	const { farmFieldTree, visible, SelectedGrowerId, onCancel, onSave, PullFarms, RenameFarm, RenameField } = props;

	const theme = useTheme();

	const [editableData, setEditableData] = useState<IEditableTree[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() =>
	{
		if (farmFieldTree && farmFieldTree.length > 0)
		{
			const newEditableData: IEditableTree[] = [];
			farmFieldTree.forEach(farm =>
			{
				if (farm.options.some(f => f.isChecked))
				{
					const parentItem: IEditableName =
					{
						Id: farm.headerId,
						Error: false,
						OriginalName: farm.header,
						SavedSuccessfully: false,
					};
				
					const children: IEditableName[] = [];

					if (farm.options && farm.options.length > 0)
					{
						farm.options.forEach(field =>
						{
							if (field.isChecked)
							{
								children.push(
									{ 
										Id: field.optionId,
										Error: false,
										OriginalName: field.option,
										SavedSuccessfully: false, 
									}
								);
							}
						});
					}

					newEditableData.push({ Parent: parentItem, Children: children });
				}
			});

			setEditableData([...newEditableData]);
		}

	},[farmFieldTree, visible]);

	const validateName = (value: string): boolean =>
	{
		if (value.trim() === '')
		{
			return false;
		}
		return true;
	};

	// Saves the edited value of a farm or field into their respective EditValue property
	const saveValue = (value: string, id?: string) =>
	{
		// Child id will be in this format Parent.Id_child.Id
		if (id.includes('_'))
		{
			const splitId = id.split('_');
			const parentId = splitId[0];
			const childId = splitId[1];
			const parentWithChildToChange = editableData.find(item => item.Parent.Id === parentId);
			const childToChange = parentWithChildToChange.Children.find(item => item.Id === childId);
			if (childToChange.OriginalName.toLocaleLowerCase() !== value.toLocaleLowerCase())
			{
				childToChange.EditedName = value;
				setEditableData([...editableData]);
			}
		}
		else
		{
			// We must have a farm to update (Parent)
			const valueToChange = editableData.find(item => item.Parent.Id === id);
			if (valueToChange.Parent.OriginalName.toLocaleLowerCase() !== value.toLocaleLowerCase())
			{
				valueToChange.Parent.EditedName = value;
				setEditableData([...editableData]);
			}
		}
	};

	// Check if we do have changes to save
	const changesExist = (): boolean =>
	{
		const anyFarmsChanged = editableData.some(item => !!item.Parent.EditedName);
		const anyFieldChanged = editableData.flatMap(item => item.Children).some(child => !!child.EditedName);

		return anyFarmsChanged || anyFieldChanged;
	};

	// Check if changes were made (error or not) - use this to trigger PullFarms on exit of the modal
	const changesMade = (): boolean =>
	{
		const anyFarmsChanged = editableData.some(item => item.Parent.Error || item.Parent.SavedSuccessfully);
		const anyFieldChanged = editableData.flatMap(item => item.Children).some(child => child.Error || child.SavedSuccessfully);

		return anyFarmsChanged || anyFieldChanged;
	};

	// Save all changes
	const save = async() =>
	{
		let errorHappened = false;
		editableData.map(async (farm) =>
		{
			setIsLoading(true);
			if (farm.Parent.EditedName)
			{
				const farmResult = await RenameFarm({ EditedName: farm.Parent.EditedName, Id: farm.Parent.Id });
				if (farmResult.error)
				{
					errorHappened = true;
					farm.Parent.Error = farmResult.error;
					farm.Parent.ErrorMessage = farmResult.payload;
					farm.Parent.SavedSuccessfully = false;
					setEditableData([...editableData]);
				}
				else
				{
					farm.Parent.Error = false;
					farm.Parent.ErrorMessage = '';
					farm.Parent.OriginalName = farm.Parent.EditedName;
					farm.Parent.EditedName = undefined;
					farm.Parent.SavedSuccessfully = true;
					setEditableData([...editableData]);
				}
			}
		});

		const fieldNames = editableData.flatMap(farm => farm.Children);

		// Now check fields
		fieldNames.map(async (field) =>
		{
			if (field.EditedName)
			{
				const fieldResult = await RenameField({ EditedName: field.EditedName, Id: field.Id });
				if (fieldResult.error)
				{
					errorHappened = true;
					field.Error = fieldResult.error;
					field.ErrorMessage = fieldResult.payload;
					field.SavedSuccessfully = false;
					setEditableData([...editableData]);
				}
				else
				{
					field.Error = false;
					field.ErrorMessage = '';
					field.OriginalName = field.EditedName;
					field.EditedName = undefined;
					field.SavedSuccessfully = true;
					setEditableData([...editableData]);
				}
			}
		});

		setIsLoading(false);
	};

	const cancel = () =>
	{
		if (changesMade())
		{
			PullFarms({ growerId: SelectedGrowerId });
			onCancel();
		}
		else
		{
			onCancel();
		}
	};

	return (
		<StyledModal
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>Edit Names</div>
			}
			open={visible}
			closable={true}
			onCancel={cancel}
			className='EditNamesModal'
			height={400}
			flexBody={true}
			destroyOnClose={true}
			isLoading={isLoading}
			loadingText={'Loading...'}
		>
			<EditableNamesContainer>
				{
					editableData ?
						<div style={{ overflowY: 'auto' }}>
							{
								editableData.map(farm =>
								{
									return(
										<div key={farm.Parent.Id}>
											<EditableInputDisplay
												width={'100%'}
												className={`EditableFarmName_${farm.Parent.Id}`}
												inputValue={farm.Parent.EditedName ? farm.Parent.EditedName : farm.Parent.OriginalName}
												inputId={farm.Parent.Id}
												inputType='text'
												inputWidth={'100%'}
												saveInput={saveValue}
												validateInput={validateName}
											/>
											{
												farm.Parent.Error ?
													<div style={{ color: theme.colors.redLM, marginLeft: 20 }}>{farm.Parent.ErrorMessage}</div>
													:
													farm.Parent.SavedSuccessfully ?
														<div style={{ color: theme.colors.availabilityGreen, marginLeft: 20 }}>{'Updated Successfully.'}</div>
														:
														<></>
											}
											{
												farm.Children.map(child =>
												{
													return(
														<div key={child.Id} style={{ marginLeft: 20 }}>
															<EditableInputDisplay
																width={'100%'}
																className={`EditableFieldName_${child.Id}`}
																inputValue={child.EditedName ? child.EditedName : child.OriginalName}
																inputId={`${farm.Parent.Id}_${child.Id}`}
																inputType='text'
																inputWidth={'100%'}
																saveInput={saveValue}
																validateInput={validateName}
															/>
															{
																child.Error ?
																	<div style={{ color: theme.colors.redLM, marginLeft: 20 }}>{child.ErrorMessage}</div>
																	:
																	child.SavedSuccessfully ?
																		<div style={{ color: theme.colors.availabilityGreen, marginLeft: 20 }}>{'Updated Successfully.'}</div>
																		:
																		<></>
															}
														</div>
													);
												})
											}
										</div>
									);
								})
							}
						</div>
						:
						<></>

				}
				<div style={{ marginTop: 'auto', paddingTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						variant='outlined'
						width={'100px'}
						onClick={cancel}
						className='EditNamesModal_CancelButton'
					>
						Cancel
					</Button>
					<Button
						variant='dark'
						width={'100px'}
						style={{ marginLeft: 24 }}
						onClick={save}
						className='EditNamesModal_SaveButton'
						disabled={!changesExist()}
					>
						Save
					</Button>
				</div>
			</EditableNamesContainer>
		</StyledModal>
	);
};

const mapStateToProps = (state: RootState) => ({
	SelectedGrowerId: state.ui.SelectedGrowerId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		PullFarms: makeDispatch(dispatch, getFarms),
		RenameFarm: makeDispatch(dispatch, renameFarm),
		RenameField: makeDispatch(dispatch, renameField),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const EditNamesModal = connector(EditNamesModalBase);