import React, { ReactNode, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { ThemeName } from '../../logic/store/UI/UISlice';
import { useUrlTheme } from '../../logic/Utility/useUrlTheme';
import { Form, Input } from 'antd';
import { Button } from '../../components/Button/Button';
import { ReactComponent as CoreLogo } from '../../assets/images/GH-GHX-Logos-Orange.svg';
import { ReactComponent as EnogenCoreLogo } from '../../assets/images/EluminateEnogenLogos.svg';
import { useParams } from 'react-router-dom';
import { CoveringLoader } from '../../components/LoadingSpinner/CoveringLoader';
import { ScaleLoader } from 'react-spinners';
import { useScopedSession } from '../../tracing/session';
import { Api } from '../../logic/Api/Api';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { useGetInvitation } from './useGetInvitation';
import { useClaimInvitation } from './useClaimInvitation';
import { useLoadingCounter } from '../../logic/Utility/useLoadingCounter';
import { validatePasswordRules } from './validatePasswordRules';


export interface IRegistrationQueryResponse {
	Username: string;
	Name: string;
	CropwiseUserExists: boolean;
}

// Render component for the registration form
export const Register = (props: {
	changeTheme: (name: ThemeName) => void;
}) => 
{
	// Make sure our themeing is correct per the url
	const [isEnogen, setIsEnogen] = useState(false);
	useUrlTheme(setIsEnogen, props.changeTheme);
	const theme = useTheme();
	const session = useScopedSession('register');
	const [api] = useState(new Api('api', null, session));

	const [message, setMessage] = useState<ReactNode>();
	const [destination, setDestination] = useState<'login'|'register'>('login');

	// Grab the invitation from the url
	const { invitationCode } = useParams<{ invitationCode: string }>();

	// When loading > 0 a blocking spinner will show
	const { isLoading, startLoading, endLoading } = useLoadingCounter();

	
	// Set up our form data; it will ultimately be loaded from the invitation endpoint
	const [form] = Form.useForm();
	
	// Simulating loading the data
	const cropwiseUserExists = useGetInvitation(
		invitationCode,
		form,
		api,
		setMessage, 
		startLoading,
		endLoading);

	// Handle a completed submission
	const submit = useClaimInvitation(
		invitationCode, 
		cropwiseUserExists,
		api, 
		setMessage,
		setDestination,
		startLoading, 
		endLoading);

	const onClose = useCallback(()=>
	{
		setMessage(undefined);
		if(destination === 'login')
		{
			window.location.href = '/login';
		}
	}, [destination, setMessage]);

	return <>
		<div style={{ display: 'flex', flexDirection: 'column', color: theme.colors.primary }}>
			<div style={{width:'100%', display: 'flex', flexDirection:'column', alignItems: 'center', marginTop: 96 }}>
				{
					isEnogen ?
						<EnogenCoreLogo  width={768} height={'12vh'} />
						:
						<CoreLogo width={768} height={'8vh'} />
				}
			</div>
			<div style={{width:'100%', marginTop: 20, marginBottom: 20, textAlign: 'center'}}>
				Please complete registration in order to enter {isEnogen ? 'E-Luminate' : 'GHX Fields'}.  
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
				<Form form={form} 
					name="basic"
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 16 }}
					autoComplete="off"
					onFinish={submit}
					style={{ maxWidth: 1024, width: '100%' }}
				>
					<Form.Item
						label="Email Address"
						name="email"
					>
						<Input 
							style={{backgroundColor: theme.colors.disabledGrey }}
							readOnly />
					</Form.Item>
					<Form.Item
						label="Name"
						name="name"
						rules={[{ 
							type:'string',
							required: true, 
							message: 'Please input your name',
							whitespace: true,
						}]}
					>
						<Input />
					</Form.Item>
					{
						(cropwiseUserExists === true) && <Form.Item label="Note:">
							You already have an account in Cropwise or another GHX application.  Please enter your password here to activate your account for {isEnogen ? 'E-Luminate' : 'GHX Fields'}. If you have forgotten your password, you can reset it on the <a href="/login">login</a> page.
						</Form.Item>
					}
					<Form.Item
						label="Password"
						name="password"
						hasFeedback
						rules={[
							{ required: true, message: cropwiseUserExists ? 'Please enter your Cropwise password' : 'Please create your password' },
							(cropwiseUserExists === true) ? null : validatePasswordRules
						]}
					>
						<Input.Password />
					</Form.Item>
					{
						(cropwiseUserExists === false) && <Form.Item
							label="Confirm Password"
							name="confirm"
							dependencies={['password']}
							hasFeedback
							rules={[
								{ required: true, message: 'Please enter the same password again' },
								({ getFieldValue }) => ({
									validator(_, value) 
									{
										if (!value || getFieldValue('password') === value) 
										{
											return Promise.resolve();
										}
										return Promise.reject(new Error('The two passwords that you entered do not match'));
									},
								})
							]}
						>
							<Input.Password />
						</Form.Item>
					}
					<Form.Item wrapperCol={{ offset: 5, span: 16 }}>
						<Button fullWidth variant='main'>
						Submit
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
		<CoveringLoader duration={10} className={isLoading ? 'loading' : ''}>
			<ScaleLoader color={theme.colors.primary} loading={true} />
		</CoveringLoader>
		<StyledModal open={!!message} onCancel={onClose}>
			<div style={{display:'flex',flexDirection:'column',alignItems:'center', paddingTop:32,paddingBottom:16}}>
				{message}
				<Button style={{marginTop: 32}} variant='main' fullWidth onClick={onClose}>OK</Button>
			</div>
		</StyledModal>
	</>;
};


