import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import Modal from 'antd/lib/modal/Modal';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Api } from '../../../logic/Api/Api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../logic/store/Store';
import { ReactComponent as ConflictIcon } from '../../../assets/images/Conflict.svg';
import { cornId, soyId } from '../../../logic/store/Seeds/CropsSlice';
import { Alert, Collapse } from 'antd';
import { useScopedSession } from '../../../tracing/session';
import Tooltip from 'antd/es/tooltip';
import moment from 'moment';

const { Panel } = Collapse;

const getErrorMessage = (e: any, defaultMessage: string): string => 
{
	if (e?.response?.Data?.ErrorMessage) 
	{
		return e.response.Data.ErrorMessage;
	}
	if (e?.response?.data?.message) 
	{
		return e.response.data.message;
	}
	if (e?.message) 
	{
		return e.message;
	}
	return defaultMessage;
};

const environments = ['BALANCED', 'VLOW', 'LOW', 'MED', 'HIGH', 'VHIGH'] as const;

/**
 * Helper function to render table cells for both irrigated and non-irrigated environments
 */
const TableCells = React.memo(({ data }: { 
	data: Record<'YES'|'NO', Record<string, number>> 
}) => 
{
	return <>
		{environments.map((env) => 
			<td key={`YES-${env}`} style={{fontWeight: env === 'BALANCED' ? 'bold' : undefined}}>
				{data.YES?.[env]?.toFixed(2) ?? '---'}
			</td>
		)}
		{environments.map((env) => 
			<td key={`NO-${env}`} style={{fontWeight: env === 'BALANCED' ? 'bold' : undefined}}>
				{data.NO?.[env]?.toFixed(2) ?? '---'}
			</td>
		)}
	</>;
});
TableCells.displayName = 'TableCells';

/**
 * This component is displayed in a modal that shows diagnostic information for field match strengths.
 * It fetches data for a given field and year, then groups that data by irrigation and environment.
 * It renders the results in tables and includes a "Fetch new Match Strengths" button to force a refresh.
 * @param {object} props
 * @param {boolean} props.open - Whether the modal is visible.
 * @param {() => unknown} props.onClose - Callback to close the modal.
 * @param {string} props.fieldId - The field ID for which to load data.
 * @param {string} props.year - The year for which to load data.
 * @returns {JSX.Element} The FieldMatchStrengthsDiagnosticModal component.
 */
export const FieldMatchStrengthsDiagnosticModal = (props: {
	onClose: () => unknown;
	open: boolean;
	fieldId: string;
	year: string;
}) => 
{
	const userAuthToken = useSelector((state: RootState) => state.auth.userAuthToken);
	const { open, onClose, fieldId, year } = props;

	const [hybridData, setHybridData] = useState<IMatchStrengthData>();
	const [loading, setLoading] = useState(1);
	const [error, setError] = useState<string>();

	const scopedSession = useScopedSession('FieldMatchStrengthsDiagnosticModal');

	/**
     * useEffect to query and load the match strength data from the server.
     * If successful, it stores the results in hybridData; otherwise sets an error.
     */
	useEffect(() => 
	{
		const abortController = new AbortController();
		setLoading(prev => prev + 1);

		async function execute() 
		{
			try 
			{
				if (!fieldId || !year) 
				{
					throw new Error('Field ID and Year are required');
				}

				const api = new Api('/api/6', userAuthToken, scopedSession);
				const response = await api.getAsync<IMatchStrengthData>(
					`fields/${fieldId}/years/${year}/matchstrengths`,
					{ signal: abortController.signal }
				);

				if (!response?.Success) 
				{
					throw new Error(response?.ErrorMessage || 'Failed to fetch match strength data');
				}

				if (!response?.Data?.Strengths) 
				{
					throw new Error('Invalid match strength data received');
				}

				setHybridData(response.Data);
				setError(undefined);
			}
			catch (e) 
			{
				if (!abortController.signal.aborted) 
				{
					console.error('Error fetching match strengths:', e);
					setError(getErrorMessage(e, 'Error loading match strength data'));
					setHybridData(undefined);
				}
			}
			finally 
			{
				if (!abortController.signal.aborted) 
				{
					setLoading(prev => prev - 1);
				}
			}
		}

		execute();

		return () => 
		{
			abortController.abort();
		};
	}, [fieldId, year, userAuthToken, scopedSession]);

	/**
     * onDelete is triggered when the user clicks "Fetch new Match Strengths."
     * It makes a request to delete existing data, forcing a re-fetch from the server.
     */
	const onDelete = useCallback(() => 
	{
		setLoading(prev => prev + 1);
		async function execute() 
		{
			try 
			{
				if (!fieldId || !year) 
				{
					throw new Error('Field ID and Year are required');
				}

				const api = new Api('/api/6', userAuthToken, undefined);
				const response = await api.deleteAsync<IMatchStrengthData>(
					`fields/${fieldId}/years/${year}/matchstrengths`
				);

				if (!response.Success) 
				{
					throw new Error(response.ErrorMessage || 'Failed to refresh match strength data');
				}

				if (!response.Data) 
				{
					throw new Error('No match strength data received after refresh');
				}

				setError(undefined);
				setHybridData(response.Data);
			}
			catch (e) 
			{
				console.error('Error refreshing match strengths:', e);
				setError(getErrorMessage(e, 'Error refreshing match strength data'));
			}

			finally 
			{
				setLoading(prev => prev - 1);
			}
		}
		execute();
	}, [fieldId, year, userAuthToken]);

	useEffect(() => setLoading(prev => prev - 1), []);

	/**
     * Groups the retrieved match strength data in a structure suitable for
     * rendering side-by-side irrigation vs. environment tables.
     */
	const groupedData = useMemo(() => 
	{
		if(!hybridData)
		{
			return undefined;
		}

		// Map from crop => hybrid => irrigation => environment => strength
		const data: Record<string, Record<string, Record<'YES'|'NO', Record<string, number>>>> = {};
		for(const curr of hybridData.Strengths)
		{
			if(!data[curr.CropId])
			{
				data[curr.CropId] = {};
			}

			for(const hybrid of curr.MatchStrengths)
			{
				if(!data[curr.CropId][hybrid.SeriesName])
				{
					data[curr.CropId][hybrid.SeriesName] = {
						'YES': {},
						'NO': {}
					};
				}
				
				if(!data[curr.CropId][hybrid.SeriesName][curr.Irrigated ? 'YES' : 'NO'][curr.Environment])
				{
					data[curr.CropId][hybrid.SeriesName][curr.Irrigated ? 'YES' : 'NO'][curr.Environment] = hybrid.Strength;
				}
			}
		}

		return data;
	}, [hybridData]);

	const sortedCornList = useMemo(() => Object.entries(groupedData?.[cornId] ?? {}).sort(([a],[b]) => a.localeCompare(b)), [groupedData]);
	const sortedSoyList = useMemo(() => Object.entries(groupedData?.[soyId] ?? {}).sort(([a],[b]) => a.localeCompare(b)), [groupedData]);

	// Memoize snapshot formatting
	const formattedSnapshots = useMemo(() => 
		hybridData?.Snapshots.map(snapshot => ({
			...snapshot,
			formattedDate: `${moment.parseZone(snapshot.Modified).format('MM/DD/YYYY')} ${moment.parseZone(snapshot.Modified).format('MM/DD/YYYY')}`,
			cropType: snapshot.CropId === cornId ? 'Corn' : snapshot.CropId === soyId ? 'Soy' : 'Unknown',
			irrigationType: snapshot.Irrigated ? 'Irrigated' : 'Non-Irrigated',
			request : snapshot.Request,
			parsedSnapshot: JSON.stringify(JSON.parse(snapshot.Snapshot), undefined, 2)
		})),
	[hybridData?.Snapshots]
	);

	// Disable interactions while loading
	const isInteractionDisabled = loading > 0;

	return <Modal
		open={open}
		width='80%'
		onOk={onClose}
		onCancel={onClose}
		title='Match Strength Diagnostics'
		centered
		style={{ position: 'relative' }}
		footer={<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
			<Tooltip 
				key='fetch' 
				title='This will destroy existing match strengths and fetch new ones from the Ranking API' 
				placement='top'>
				<Button 
					variant='outlined'
					disabled={isInteractionDisabled}
					style={{ height: 40, width: 260, marginRight: 15 }}
					onClick={onDelete}
				>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<ConflictIcon style={{marginRight: 16}} /> Fetch new Match Strengths
					</div>
				</Button>
			</Tooltip>
			<Button 
				key='OK' 
				disabled={isInteractionDisabled}
				style={{ height: 40, width: 160, marginRight: 15 }}
				variant='main'
				onClick={onClose}>
                Close
			</Button>
		</div>}
	>
		{error && <Alert showIcon icon={<ConflictIcon/>} type='error' description={error} />}
		<div style={{ width: '100%', height: '60vh', overflow: 'auto' }}>
			<h1>Corn</h1>
			<table style={{ width: '100%' }} border={1}>
				<thead>
					<tr>
						<th>Hybrid</th>
						<th colSpan={6}>Irrigated</th>
						<th colSpan={6}>Non-Irrigated</th>
					</tr>
					<tr>
						<th />
						<th>BALANCED</th>
						<th>VLOW</th>
						<th>LOW</th>
						<th>MED</th>
						<th>HIGH</th>
						<th>VHIGH</th>
						<th>BALANCED</th>
						<th>VLOW</th>
						<th>LOW</th>
						<th>MED</th>
						<th>HIGH</th>
						<th>VHIGH</th>
					</tr>
				</thead>
				<tbody>
					{sortedCornList.map(([hybrid, environments]) =>
						<tr key={hybrid}>
							<td>{hybrid}</td>
							<TableCells data={environments} />
						</tr>
					)}
				</tbody>
			</table>
			<h1>Soybean</h1>
			<table style={{ width: '100%' }} border={1}>
				<thead>
					<tr>
						<th>Hybrid</th>
						<th colSpan={6}>Irrigated</th>
						<th colSpan={6}>Non-Irrigated</th>
					</tr>
					<tr>
						<th />
						<th>BALANCED</th>
						<th>VLOW</th>
						<th>LOW</th>
						<th>MED</th>
						<th>HIGH</th>
						<th>VHIGH</th>
						<th>BALANCED</th>
						<th>VLOW</th>
						<th>LOW</th>
						<th>MED</th>
						<th>HIGH</th>
						<th>VHIGH</th>
					</tr>
				</thead>
				<tbody>
					{sortedSoyList.map(([hybrid, environments]) =>
						<tr key={hybrid}>
							<td>{hybrid}</td>
							<TableCells data={environments} />
						</tr>
					)}
				</tbody>
			</table>
			<h1>Diagnostics</h1>
			<Collapse style={{ width: '100%' }}>
				{
					formattedSnapshots?.map((snapshot) => 
					{
						return <Panel key={`${snapshot.BrandId}-${snapshot.CropId}-${snapshot.Irrigated}`} header={<tr style={{width:'100%'}}>
							<td style={{width:'5%'}}>{snapshot.cropType}</td>	
							<td style={{width:'15%'}}>{snapshot.irrigationType}</td>			
							<td style={{width:'5%'}}>{snapshot.Spi}</td>			
							<td style={{width:'30%'}}>{snapshot.BrandId}</td>
							<td style={{width:'20%'}}>{snapshot.AgroCoreZoneName}</td>
							<td style={{width:'15%', textAlign:'right'}}>{snapshot.formattedDate}</td>
						</tr>}>
							<div style={{marginLeft:-8, marginRight: -8, padding: 4, border:'1px solid black'}}>
								{snapshot.request}
							</div>
							<pre style={{marginLeft:-8, marginRight: -8, padding: 4, border:'1px solid black'}}>
								{snapshot.parsedSnapshot}
							</pre>
						</Panel>;
					})
				}
			</Collapse>
		</div>
		<LoadingSpinner
			style={{ position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)' }}
			loading={loading > 0}>Loading</LoadingSpinner>
	</Modal>;
};

export interface IMatchStrengthData {
	Strengths: IMatchStrengthEnvironment[];
	Snapshots: IMatchStrengthSnapshot[];
}

export interface IMatchStrengthSnapshot {
	CropId:           string;
	BrandId:          string;
	Irrigated:        boolean;
	County:           string;
	State:            string;
	AgroCoreZoneCode: string;
	AgroCoreZoneName: string;
	Spi:              number;
	BalancedEnv:      string;
	Request:		  string;
	Snapshot:         string;
	Created:          string;
	Modified:         string;
}

export interface IMatchStrengthEnvironment {
	CropId:         string;
	Environment:    string;
	Irrigated:      boolean;
	MatchStrengths: IMatchStrength[];
}

export interface IMatchStrength {
	SeriesId:   string;
	SeriesName: string;
	Strength:   number;
	Created:    string;
	Modified:   string;
}