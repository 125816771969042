import styled from 'styled-components';
import { Modal } from 'antd';

/**
 * @component Modal with iFrame content
 */
export const IFrameModal = styled(Modal)`
.ant-modal-body {
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: stretch;
}

iframe {
	flex-grow: 1;
	min-height: 600px;
}
`;
