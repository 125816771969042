import { sortBy } from 'lodash';
import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Button } from '../../../components/Button/Button';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { IOrderResponse } from '../../../logic/Models/Responses/OrderResponse';
import { CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { CalculateBags, RoundAcres, RoundSeedingRate } from '../../../logic/Utility/CalculationUtilities';
import { dynamicSort } from '../../../logic/Utility/Utils';
import { IMaxScriptOrderData } from './MaxScriptMain';

export interface IOverageDetailsModalProps
{
	maxScriptOrderDatas: Record<string, IMaxScriptOrderData>;
	orderData: IOrderResponse[];
	visible: boolean;
	openOrder: () => void | undefined;
	onCancel: () => void;
}

interface IOrderAndMaxScriptTableData
{
	HybridId: string;
	HybridName: string;
	MaxScriptAcres: number;
	MaxScriptRate: number;
	MaxScriptUnits: number;
	OrderAcres: number;
	OrderRate: number;
	OrderUnits: number;
}

interface IProductUsage
{
	AppliedBags: number;
	AppliedAcres: number;
	AverageRate: number;
}

export const OverageDetailsModal = (props: IOverageDetailsModalProps) =>
{
	const { maxScriptOrderDatas, orderData, visible, openOrder, onCancel } = props;

	const theme = useTheme();

	const getOrderAndMaxScriptData = useCallback(()  =>
	{
		if (!orderData || !orderData[0])
		{
			return [];
		}

		const dataByCrop: {name: string, table:IOrderAndMaxScriptTableData[]}[] = [];
		// Iterate by crop
		for(const cropId in maxScriptOrderDatas)
		{
			const crop = CropConfig()[cropId];
			// Check if this is a supported crop, otherwise skip it (eg Unknown)
			if(!crop)
			{
				continue;
			}
			const tableData = orderData[0].Seeds.reduce((prev, orderItem) => 
			{
				let hybrid = prev[orderItem.HybridId];
				if(!hybrid)
				{
					// Get the applied product data
					const maxScriptAcresRateBags = calcProductUsage(orderItem.HybridId, cropId);

					// If this isn't applied (or the correct crop) skip it
					if (maxScriptAcresRateBags.AppliedAcres === 0)
					{
						return prev;
					}

					hybrid = {
						HybridId: orderItem.HybridId,
						HybridName: orderItem.HybridName,
						MaxScriptAcres: maxScriptAcresRateBags.AppliedAcres,
						MaxScriptRate: maxScriptAcresRateBags.AverageRate,
						MaxScriptUnits: maxScriptAcresRateBags.AppliedBags,
						OrderAcres: orderItem.TotalAcres,
						OrderRate: orderItem.SeedingRate,
						OrderUnits: orderItem.Quantity,
					};
				}
				else
				{
					// Aggregate this data with the other product lines of the same hybrid
					hybrid.OrderAcres += orderItem.TotalAcres;
					hybrid.OrderUnits += orderItem.Quantity;
					if(orderItem.TotalAcres > 0)
					{
						// Take a weighted average
						hybrid.OrderRate = Math.ceil( 
							((hybrid.OrderRate * hybrid.OrderAcres ) + ( orderItem.SeedingRate * orderItem.TotalAcres))
						/ (hybrid.OrderAcres + orderItem.TotalAcres));
					}
				}

				return {
					...prev,
					[orderItem.HybridId]: hybrid
				};
			}, 
			{} as Record<string, IOrderAndMaxScriptTableData>);

			dataByCrop.push({name: crop.cropName, table: Object.values(tableData) });
		}

		return sortBy(dataByCrop, (i) => i.name);
	}, [maxScriptOrderDatas, orderData]);

	const calcProductUsage = (hybridId: string, cropId: string): IProductUsage =>
	{
		let totalAppliedBagsForHybrid = 0;
		let totalAppliedAcresForHybrid = 0;
		let totalWeightedRate = 0;

		// Determine how many bags are being used for the specified Hybrid
		const maxScriptOrderData = maxScriptOrderDatas[cropId];
		if (maxScriptOrderData && maxScriptOrderData.CropPlanFields)
		{
			// Bags per field = Acres * seeding rate / seeds per bag
			const specifiedHybridFields = maxScriptOrderData.CropPlanFields.flatMap(f => f.Inventory);
			const seedsPerBag = CropConfig()[cropId].seedsPerBag;
			specifiedHybridFields.forEach(hybridField =>
			{
				if (hybridField && hybridField.HybridId === hybridId && hybridField.Acres > 0)
				{
					// Acres
					totalAppliedAcresForHybrid += hybridField.Acres;

					// Weighted Rate
					totalWeightedRate += hybridField.Rate * hybridField.Acres;
				}
			});

			totalAppliedBagsForHybrid = CalculateBags(totalAppliedAcresForHybrid, 
				totalWeightedRate > 0 && totalAppliedAcresForHybrid > 0 ? 
					RoundSeedingRate(totalWeightedRate/totalAppliedAcresForHybrid) : 
					0, seedsPerBag);
		}

		return {
			AppliedBags : totalAppliedBagsForHybrid,
			AppliedAcres: RoundAcres(totalAppliedAcresForHybrid),
			AverageRate: totalWeightedRate > 0 && totalAppliedAcresForHybrid > 0 ?
				RoundSeedingRate(totalWeightedRate/totalAppliedAcresForHybrid) :
				0,
		};
	};

	return (
		<StyledModal
			title={
				<div style={{
					fontWeight: theme.fontWeights.bold,
					fontFamily: theme.fonts.heading,
					fontSize: theme.fontSizes.xLarge,
					paddingTop: 10,
				}}>Order Update</div>
			}
			width={750}
			height={'auto'}
			open={visible}
			closable={true}
			onCancel={onCancel}
		>
			<div >
				<div style={{ fontSize: theme.fontSizes.normal, marginBottom: 10, marginTop: -10 }}>
					There was a change on your <span style={{ fontWeight: theme.fontWeights.bold }}>Crop Plan.</span> These are the listed product(s)
					updates you will need to perform to your order to complete your current <span style={{ fontWeight: theme.fontWeights.bold }}>Crop Plan:</span>
				</div>
				<div style={{ maxHeight: '50vh', overflow: 'auto' }}>
					<table style={{ width: '100%' }}>
						<thead style={{
							fontWeight: theme.fontWeights.bold,
							textAlign: 'left',
							position: 'sticky',
							top: 0,
							margin: 0,
							backgroundColor: 'white',
							lineHeight: 2
						}}>
							<tr>
								<th
									key={'CropEdge_Order'}
									className={'CropEdge_Order'}
									colSpan={4}
								>
									<div 
										style={{
											backgroundColor: theme.colors.darkGrey,
											color: theme.colors.white,
											paddingLeft: 16,
											paddingTop: 5,
											paddingBottom: 5,
											marginBottom: 8,
										}}>
										{'CropEdge Order'}
									</div>
								</th>
								<th
									key={'Updated_MaxScript'}
									className={'Updated_MaxScript'}
									colSpan={3}
								>
									<div 
										style={{
											backgroundColor: theme.colors.secondary,
											color: theme.colors.darkGrey,
											paddingLeft: 16,
											marginLeft: 3,
											paddingTop: 5,
											paddingBottom: 5,
											marginBottom: 8,
										}}>
										{'Updated MaxScript'}
									</div>
								</th>
							</tr>
							<tr>
								<th key={'Hybrid'} className={'Hybrid'}>
									<div style={{ backgroundColor: theme.colors.lightestGrey, paddingLeft: 16 }}>{'Hybrid'}</div>
								</th>
								<th key={'CropEdge_Order_Units'} className={'CropEdge_Order_Units'} style={{ textAlign: 'right'}}>
									<div style={{ backgroundColor: theme.colors.lightestGrey, paddingLeft: 10, marginLeft: -2 }}>{'Units'}</div>
								</th>
								<th key={'CropEdge_Order_Acres'} className={'CropEdge_Order_Acres'} style={{ textAlign: 'right'}}>
									<div style={{ backgroundColor: theme.colors.lightestGrey, paddingLeft: 10, marginLeft: -2 }}>{'Acres'}</div>
								</th>
								<th key={'CropEdge_Order_Rate'} className={'CropEdge_Order_Rate'} style={{ textAlign: 'right'}}>
									<div style={{
										backgroundColor: theme.colors.lightestGrey,
										paddingLeft: 10,
										marginLeft: -2,
										paddingRight: 16
									}}>{'Rate'}</div>
								</th>
								<th key={'MaxScript_Units'} className={'MaxScript_Units'} style={{ textAlign: 'right'}}>
									<div style={{ backgroundColor: theme.colors.lightestGrey, paddingLeft: 16, marginLeft: 3, paddingRight: 16 }}>{'Units'}</div>
								</th>
								<th key={'MaxScript_Acres'} className={'MaxScript_Acres'} style={{ textAlign: 'right'}}>
									<div style={{ backgroundColor: theme.colors.lightestGrey, paddingLeft: 16, marginLeft: -2, paddingRight: 16 }}>{'Acres'}</div>
								</th>
								<th key={'MaxScript_Rate'} className={'MaxScript_Rate'} style={{ textAlign: 'right'}}>
									<div style={{
										backgroundColor: theme.colors.lightestGrey,
										paddingLeft: 16,
										marginLeft: -2,
										paddingRight: 16
									}}>{'Avg Rate'}</div>
								</th>
							</tr>
						</thead>
						{
							getOrderAndMaxScriptData().map(entry => 
								<tbody key={entry.name} style={{height: '100%', padding: 10, overflowY: 'auto' }}>
									<tr style={{
										fontWeight: theme.fontWeights.bold,
										textAlign: 'left',
										top: 0,
										margin: 0,
										backgroundColor: 'white',
										lineHeight: 2
									}}><td>{entry.name}</td></tr>
									{entry.table.sort(dynamicSort('OrderUnits', 'descending')).map((item) => (
										<tr key={item.HybridId} className={`HybridDataRow_${item.HybridId}`} style={{ marginBottom: 2 }}>
											<td style={{ paddingLeft: 16 }}>{item.HybridName}</td>
											<td style={{ textAlign: 'right'}}>{item.OrderUnits}</td>
											<td style={{ textAlign: 'right'}}>{item.OrderAcres}</td>
											<td style={{ textAlign: 'right', paddingRight: 16 }}>{item.OrderRate}</td>
											<td 
												style={{
													textAlign: 'right',
													paddingRight: 16,
													color: item.MaxScriptUnits > item.OrderUnits ? theme.colors.redLM : theme.colors.darkGrey
												}}
											>
												{item.MaxScriptUnits}
											</td>
											<td
												style={{
													textAlign: 'right',
													paddingRight: 16,
													color: item.MaxScriptUnits > item.OrderUnits ? theme.colors.redLM : theme.colors.darkGrey
												}}
											>
												{item.MaxScriptAcres}
											</td>
											<td
												style={{
													textAlign: 'right',
													paddingRight: 16,
													color: item.MaxScriptUnits > item.OrderUnits ? theme.colors.redLM : theme.colors.darkGrey
												}}
											>
												{item.MaxScriptRate}
											</td>
										</tr>
									))}
								</tbody>)
						}
					</table>
				</div>
				<div style={{ fontSize: theme.fontSizes.normal, marginTop: 20 }}>
					You will need to perform the <span style={{ fontWeight: theme.fontWeights.bold }}>marked updates</span> for the 
					<span style={{ fontWeight: theme.fontWeights.bold }}> indicated products</span> in order to complete the current 
					<span style={{ fontWeight: theme.fontWeights.bold }}> Crop Plan.</span>
				</div>
				<div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						className='overage-modal-cancel-button'
						variant='outlined'
						style={{ marginRight: 15, width: 150 }}
						onClick={onCancel}
					>
						{(openOrder !== undefined) ? 'Cancel' : 'Close'}
					</Button>
					{
						(openOrder !== undefined) && <Button
							style={{ width: 150 }}
							className='overage-modal-continue-button'
							variant='dark'
							onClick={openOrder}
						>
							Open Order
						</Button>
					}
				</div>
			</div>
		</StyledModal>
	);
};